import React, { Fragment } from 'react';
import uuid from 'react-uuid';

import { Menu, Transition } from '@headlessui/react';
import { Tooltip } from '@mui/material';

import concatClassNames from 'utils/classNames';
import getIcon from 'utils/getIcon';

import { type IconName, type IconSize } from 'icons/Icon.props';

import { Text } from '../Text/Text';
import { type ButtonMenuProps } from './Settings.types';

type SettingsPosition = 'left' | 'right';

// TODO : Check why the base sizes don't work
const settingsPositionStyle: Record<SettingsPosition, string> = {
  left: 'right-0',
  right: 'left-0',
};

interface SettingsProps {
  buttonsMenu: ButtonMenuProps[];
  position?: 'left' | 'right';
  iconSize?: IconSize;
  settingsIconName?: IconName;
  disabled?: boolean;
}

export function Settings({
  buttonsMenu,
  position = 'right',
  iconSize = 'lg',
  settingsIconName,
  disabled = false,
}: SettingsProps): JSX.Element {
  function preventDefault(event: any): void {
    event.stopPropagation();
  }

  return (
    <Menu as="div" className="relative inline-block h-fit">
      <Menu.Button
        className={concatClassNames('flex w-full items-center h-fit', disabled ? 'cursor-not-allowed' : '')}
        onClick={preventDefault}
        disabled={disabled}
      >
        {settingsIconName !== undefined && getIcon(settingsIconName, 'black', iconSize)}
      </Menu.Button>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items
          className={concatClassNames(
            'absolute',
            settingsPositionStyle[position],
            'mt-2',
            'divide-y',
            'divide-gray-25',
            'rounded-md',
            'bg-white shadow-lg z-10',
          )}
        >
          {buttonsMenu.map((buttonMenu) => (
            <Menu.Item key={uuid()}>
              {({ active }) => {
                const res: JSX.Element = (
                  <button
                    className={concatClassNames(
                      active && (buttonMenu.isEnabled ?? true) ? 'bg-branding-25 hover:bg-branding-25' : '',
                      'group flex w-full items-center rounded-md px-3.5 py-2',
                      'gap-2',
                      buttonMenu.isEnabled === false ? 'cursor-not-allowed' : '',
                    )}
                    onClick={buttonMenu.isEnabled === false ? undefined : buttonMenu.onClick}
                  >
                    {buttonMenu.iconName !== undefined &&
                      getIcon(buttonMenu.iconName, buttonMenu.isEnabled ?? true ? 'black' : 'gray', 'sm')}
                    <Text
                      content={buttonMenu.name}
                      color={buttonMenu.isEnabled ?? true ? buttonMenu.textColor : 'gray-200'}
                      size="sm"
                      position="left"
                      weight="medium"
                    />
                  </button>
                );
                if (!(buttonMenu.isEnabled ?? true))
                  return (
                    <Tooltip title="Cette fonctionnalité n'est pas encore présente" placement="right">
                      {res}
                    </Tooltip>
                  );
                return res;
              }}
            </Menu.Item>
          ))}
        </Menu.Items>
      </Transition>
    </Menu>
  );
}
