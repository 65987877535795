/* tslint:disable */
/* eslint-disable */
/**
 * Specks API
 * APIs for specks SaaS
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  CreateAssetDto,
  ErrorBadRequest,
  GetAssetDto,
  GetAssetsDto,
  GetGenesisDto,
} from '../models';
import {
    CreateAssetDtoFromJSON,
    CreateAssetDtoToJSON,
    ErrorBadRequestFromJSON,
    ErrorBadRequestToJSON,
    GetAssetDtoFromJSON,
    GetAssetDtoToJSON,
    GetAssetsDtoFromJSON,
    GetAssetsDtoToJSON,
    GetGenesisDtoFromJSON,
    GetGenesisDtoToJSON,
} from '../models';

export interface GenesisControllerCreateAssetRequest {
    id: string;
    createAssetDto: CreateAssetDto;
}

export interface GenesisControllerFindByIdRequest {
    id: string;
}

export interface GenesisControllerFindGenesisAssetsByIdRequest {
    id: string;
}

/**
 * 
 */
export class GenesesApi extends runtime.BaseAPI {

    /**
     */
    async genesisControllerCreateAssetRaw(requestParameters: GenesisControllerCreateAssetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetAssetDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling genesisControllerCreateAsset.');
        }

        if (requestParameters.createAssetDto === null || requestParameters.createAssetDto === undefined) {
            throw new runtime.RequiredError('createAssetDto','Required parameter requestParameters.createAssetDto was null or undefined when calling genesisControllerCreateAsset.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/geneses/{id}/assets`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateAssetDtoToJSON(requestParameters.createAssetDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetAssetDtoFromJSON(jsonValue));
    }

    /**
     */
    async genesisControllerCreateAsset(requestParameters: GenesisControllerCreateAssetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetAssetDto> {
        const response = await this.genesisControllerCreateAssetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async genesisControllerFindByIdRaw(requestParameters: GenesisControllerFindByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetGenesisDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling genesisControllerFindById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/geneses/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetGenesisDtoFromJSON(jsonValue));
    }

    /**
     */
    async genesisControllerFindById(requestParameters: GenesisControllerFindByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetGenesisDto> {
        const response = await this.genesisControllerFindByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async genesisControllerFindGenesisAssetsByIdRaw(requestParameters: GenesisControllerFindGenesisAssetsByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetAssetsDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling genesisControllerFindGenesisAssetsById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/geneses/{id}/assets`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetAssetsDtoFromJSON(jsonValue));
    }

    /**
     */
    async genesisControllerFindGenesisAssetsById(requestParameters: GenesisControllerFindGenesisAssetsByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetAssetsDto> {
        const response = await this.genesisControllerFindGenesisAssetsByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
