import React from 'react';

import concatClassNames from 'utils/classNames';

import { type TableDataProps, elementPositionStyle, elementWidthStyle } from './Table.props';

export function TableData({ children, position = 'left', width = 'full' }: TableDataProps): JSX.Element {
  return (
    <div
      className={concatClassNames(
        'flex',
        elementWidthStyle[width],
        'flex-wrap',
        'px-4',
        'overflow-hidden',
        'items-center',
        elementPositionStyle[position],
      )}
    >
      {children}
    </div>
  );
}
