/* tslint:disable */
/* eslint-disable */
/**
 * Specks API
 * APIs for specks SaaS
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { GetMethodDto } from './GetMethodDto';
import {
    GetMethodDtoFromJSON,
    GetMethodDtoFromJSONTyped,
    GetMethodDtoToJSON,
} from './GetMethodDto';

/**
 * 
 * @export
 * @interface GetResourceDto
 */
export interface GetResourceDto {
    /**
     * 
     * @type {string}
     * @memberof GetResourceDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof GetResourceDto
     */
    name: string;
    /**
     * 
     * @type {boolean}
     * @memberof GetResourceDto
     */
    latest: boolean;
    /**
     * 
     * @type {Date}
     * @memberof GetResourceDto
     */
    createdAt: Date;
    /**
     * 
     * @type {Array<GetMethodDto>}
     * @memberof GetResourceDto
     */
    methods: Array<GetMethodDto>;
}

/**
 * Check if a given object implements the GetResourceDto interface.
 */
export function instanceOfGetResourceDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "latest" in value;
    isInstance = isInstance && "createdAt" in value;
    isInstance = isInstance && "methods" in value;

    return isInstance;
}

export function GetResourceDtoFromJSON(json: any): GetResourceDto {
    return GetResourceDtoFromJSONTyped(json, false);
}

export function GetResourceDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetResourceDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'latest': json['latest'],
        'createdAt': (new Date(json['createdAt'])),
        'methods': ((json['methods'] as Array<any>).map(GetMethodDtoFromJSON)),
    };
}

export function GetResourceDtoToJSON(value?: GetResourceDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'latest': value.latest,
        'createdAt': (value.createdAt.toISOString()),
        'methods': ((value.methods as Array<any>).map(GetMethodDtoToJSON)),
    };
}

