/* eslint-disable max-len */
import React from 'react';

import concatClassNames from 'utils/classNames';

import { type IconProps, iconColorStyle, iconSizeStyle } from './Icon.props';

export default function ImageIcon({ color, size }: IconProps): JSX.Element {
  return (
    <svg
      id="Layer_3"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 128 128"
      className={concatClassNames(iconColorStyle[color], iconSizeStyle[size])}
    >
      <path
        stroke={iconColorStyle[color]}
        strokeWidth={1}
        className="cls-1"
        d="m44.67,26.92l-6.6.42c-.8.06-1.6.18-2.4.24-2.83.2-5.65.43-8.46.76-1.25.14-2.49.37-3.71.61l-2.33.52c-.38.09-.78.14-1.15.23-.36.12-.82-.02-1.2,0-.53-.04-.49,0-.2-.41.14-.21.36-.6.65-.94.31-.32.73-.54,1.18-.68,1.62-.52,3.27-.74,4.9-1.02,1.64-.24,3.29-.42,4.95-.53,1.99-.12,4.02-.49,6.04-.7,2.22-.22,4.44-.42,6.67-.57l4.11-.27c2.07-.14,4.16-.23,6.22-.4,1.6-.13,3.16-.37,4.74-.55.35-.04.69-.09,1.04-.12,1.31-.06,1.73.13,2.03.63.07.11.16.2.2.29.14.25-.37.77-1.31.99-2.79.58-5.75.8-8.73,1.04-2.21.17-4.43.33-6.64.5v-.05Z"
      />
      <polygon className="cls-1" points="59.75 21.65 63.39 15.05 64.19 17.99 60.63 24.41 59.75 21.65" />
      <path
        stroke={iconColorStyle[color]}
        strokeWidth={1}
        className="cls-1"
        d="m62.86,12.43l.11-1.3c.03-.42.06-.95.08-1.02.01-.1-.01-.14-.01-.21,0-.01,0-.03,0-.03-.05-.04-.11-.04-.19-.06l-.13-.02s-.25.01-.37.01c-.56.04-1.12.08-1.68.12-.5.04-.99.13-1.48.22-.31.07-.64.11-.96.12-.33.02-.66.01-1.01-.18-.21-.1-.17-.27-.02-.55.11-.19.22-.38.3-.53.12-.18.27-.29.44-.35.59-.23,1.22-.3,1.86-.37.64-.07,1.29-.11,1.96-.11.45.1,2.18-.48,2.82.16l.1.32c.07.3.17.65.16.98v.49s0,.36,0,.36l-.03,1.33c-.01.54-.03,1.09-.04,1.63,0,.82-.05,1.64,0,2.45.01.63.26,1.25.27,1.87,0,.14.03.27.03.41-.07.51-.34.68-.7.8-.09.03-.16.06-.24.08-.22.06-.53-.15-.82-.52-.69-1.11-.62-2.29-.62-3.47.01-.88.08-1.76.11-2.63h.05Z"
      />
      <path
        stroke={iconColorStyle[color]}
        strokeWidth={1}
        className="cls-1"
        d="m59.81,17.28c.15.79.29,1.58.44,2.37.06.29.17.57.22.87.17,1.04.31,2.1.4,3.17.04.47.13.94.21,1.41.11.6.27,1.21.09,1.82-.11.37-1.93-.08-1.97-.69-.1-1.12-.19-2.24-.18-3.44.01-.72-.25-1.42-.4-2.13-.16-.8-.32-1.6-.49-2.39-.1-.5-.19-1-.29-1.49-.13-.76-.26-1.53-.38-2.28-.11-.59-.3-1.16-.43-1.75-.03-.13-.07-.26-.08-.39-.05-.49.07-.66.56-.77.11-.03.22-.06.34-.07.38-.05.71.14.84.46.36.97.53,1.97.73,2.99.14.76.28,1.54.45,2.32h-.04Z"
      />
      <path
        stroke={iconColorStyle[color]}
        strokeWidth={1}
        className="cls-1"
        d="m70.7,88.53s4.5-10,6.61-10.57c4-1.09,14.25,15.87,15.07,18.11.05.14,1.56.16.14.87l-16.72.51-5.1-8.92Z"
      />
      <path
        stroke={iconColorStyle[color]}
        strokeWidth={1}
        className="cls-1"
        d="m80.44,77.29c1.83,1.3,3.07,2.8,4.37,4.3.46.55.96,1.06,1.39,1.63,1.52,2,2.9,4.08,4.26,6.16.59.93,1.23,1.84,1.85,2.74.8,1.15,1.69,2.27,2.09,3.68.07.47-.18.54-.74.45-.54-.11-1.38-.37-1.87-1-1.59-2.31-3.21-4.61-4.66-7.08-.88-1.46-2.06-2.79-3.16-4.12-1.21-1.46-2.46-2.9-3.84-4.1-.42-.37-.87-.69-1.31-.95-.22-.13-.44-.22-.65-.29-.2-.05-.32-.09-.48-.04-.04.01-.08,0-.11.03-.11.05-.17.1-.29.21-.14.12-.28.27-.42.41-.28.3-.55.62-.82.95-.53.66-1.04,1.34-1.52,2.05-.78,1.14-1.4,2.36-2.08,3.54-.16.26-.3.52-.46.77-.62.94-.93,1.16-1.45,1.13-.12,0-.24,0-.35-.02-.35-.08-.37-.64-.07-1.43.22-.59.46-1.18.72-1.77.28-.57.59-1.14.91-1.71.64-1.13,1.35-2.24,2.12-3.34.33-.46.66-.92,1.05-1.38.18-.23.4-.47.62-.7.1-.12.25-.24.47-.44.21-.16.45-.29.68-.44.2-.04.4-.11.6-.16.2-.06.41-.03.61-.04.21-.02.41,0,.6.05.17.03.35.07.49.13.6.2,1.05.47,1.48.74l-.03.05Z"
      />
      <path
        stroke={iconColorStyle[color]}
        strokeWidth={1}
        className="cls-1"
        d="m82.95,33.31c-1.17-.15-2.34-.3-3.51-.41-.43-.03-.85-.06-1.29-.1-1.51-.15-3.02-.27-4.53-.4-.67-.06-1.35-.08-2.02-.1-.86-.06-1.72-.16-2.58-.25-.27-.03-.23-.07,0-.14.23-.07.65-.18,1.14-.27l1.32-.18c.44-.04.88-.02,1.32-.02.88,0,1.77.04,2.65.16,1.06.13,2.16.14,3.25.24,1.19.1,2.38.23,3.57.36l2.19.27c1.1.14,2.21.29,3.31.43.85.1,1.7.14,2.54.21l.56.05c.69.09.9.21,1.01.5.02.07.06.13.07.19.04.21-.27.34-.79.34-1.52-.03-3.1-.23-4.68-.43-1.17-.15-2.36-.29-3.53-.44v-.03Z"
      />
      <path
        stroke={iconColorStyle[color]}
        strokeWidth={1}
        className="cls-1"
        d="m34.15,33.78c1.45-.18,2.91-.34,4.37-.48.53-.06,1.06-.13,1.59-.18,1.87-.17,3.76-.23,5.63-.32.83-.05,1.67-.1,2.49-.15,1.06-.03,2.13-.02,3.2.06.33.02.29.05.01.14-.28.08-.78.26-1.39.38l-1.61.26c-.54.07-1.08.1-1.61.14-1.08.08-2.16.15-3.25.2-1.3.06-2.64.25-3.97.39-1.46.15-2.91.31-4.36.5-.89.11-1.79.24-2.68.37-1.35.21-2.71.42-4.03.69-1.03.22-2.03.51-3.03.77l-.66.17c-.84.16-1.14.07-1.37-.1-.05-.04-.12-.08-.15-.12-.12-.13.19-.36.77-.65.87-.39,1.81-.62,2.76-.85.95-.22,1.92-.42,2.9-.59,1.45-.26,2.92-.48,4.38-.68v.03Z"
      />
      <path
        stroke={iconColorStyle[color]}
        strokeWidth={1}
        className="cls-1"
        d="m62.18,29.53l2.62-5.29c.33-.64.6-1.3.93-1.94,1.2-2.23,2.36-4.47,3.77-6.58.6-.95,1.2-1.9,1.81-2.83.42-.58.78-1.2,1.26-1.74l.7-.82c.26-.25.54-.48.81-.72.34-.29.31-.22.09.09-.23.31-.65.83-1.06,1.54-.41.58-.75,1.22-1.09,1.84-.36.61-.66,1.27-.96,1.93-.61,1.3-1.16,2.64-1.83,3.92-.84,1.52-1.49,3.21-2.19,4.85-.78,1.79-1.55,3.59-2.29,5.4l-1.38,3.34c-.67,1.69-1.38,3.39-1.92,5.11-.1.32-.19.64-.27.96l-.12.48c-.05.17-.11.47-.16.7l-.27.74c-.12.23-.37.36-.61.46-.49.17-1.07.18-1.65.13-.37-.03-.67-.03-1-.09-.6-.09-.97-.22-1.2-.43-.23-.18-.32-.45-.37-.78-.02-.15-.06-.29-.07-.41-.04-.37.58-.8,1.41-.79l.91.05.44.02c.19.01.2,0,.22-.02.09-.05.11-.13.07-.2v-.02s-.01,0-.02,0c-.01.03,0-.01.01-.06l.14-.49c.09-.33.19-.65.3-.97.21-.63.42-1.27.66-1.9.69-1.87,1.44-3.7,2.25-5.52l.05.02Z"
      />
      <path
        stroke={iconColorStyle[color]}
        strokeWidth={1}
        className="cls-1"
        d="m17.77,105.79c-5.44-20.21-5.44-59.75-5.44-66.18,0-1.63,2.81-5.13,2.81-5.13,0,0,1.57,43.7,1.66,44.92.09,1.22.96,26.39.96,26.39Z"
      />
      <path
        stroke={iconColorStyle[color]}
        strokeWidth={1}
        className="cls-1"
        d="m24.88,117.67c-.2.44,2.42,2.44,3.41,2.67,3.03.71,25.64-.63,27.36-.45s31.42-1.65,35.16-2.97c3.74-1.31-7.44.66-8.1,0s-40.75.41-40.75.41l-17.08.34Z"
      />
      <path
        stroke={iconColorStyle[color]}
        strokeWidth={1}
        className="cls-1"
        d="m71.28,53.37c-1.67-2.65.52-6.55,3.09-7.05,4.61-.9,6.52,1.82,6.48,3.33s.81,4.45-2.75,5.46c-2.12.6-5.58.21-6.82-1.75Z"
      />
      <path
        stroke={iconColorStyle[color]}
        strokeWidth={1}
        className="cls-1"
        d="m74.37,97.25c-4.9-5.69-19.04-30.39-24.54-27.02-9.4,5.78-21.55,28.96-21.55,28.96l46.09-1.94Z"
      />
      <path
        stroke={iconColorStyle[color]}
        strokeWidth={1}
        className="cls-1"
        d="m15.75,84.25c-.27-4.31-.5-8.63-.71-12.95-.08-1.58-.21-3.15-.27-4.72-.21-5.56-.33-11.11-.34-16.68,0-2.47-.01-4.95-.02-7.41.04-1.58.07-3.16.2-4.75l.11-1.19.2-1.19c.12-.79.33-1.58.73-2.32.49-.91.43-.75.51.12.14.88.47,2.4.41,4.16-.17,6.29-.22,12.69-.23,19.13-.02,3.86.27,7.87.46,11.81.21,4.34.45,8.67.72,13.01.18,2.67.37,5.34.55,8,.31,4.04.63,8.09,1.05,12.09.33,3.11.79,6.14,1.25,9.18.1.67.23,1.32.29,2,.26,2.52.29,3.32-.08,3.94-.07.14-.13.32-.19.4-.16.28-.79-.67-1.2-2.51-.71-2.71-1.07-5.54-1.47-8.4-.37-2.86-.69-5.77-.97-8.69-.41-4.33-.73-8.69-1.05-13.04h.05Z"
      />
      <path
        stroke={iconColorStyle[color]}
        strokeWidth={1}
        className="cls-1"
        d="m87.96,26.16l-4.74-.3c-.58-.03-1.16,0-1.74-.03-2.04-.1-4.09-.2-6.14-.24-.91-.02-1.82.02-2.72.06-.58.03-1.16.09-1.74.09-.29,0-.58,0-.87-.02-.29.01-.59-.19-.88-.32-.37-.21-.32-.16-.02-.42.15-.13.36-.39.61-.64.26-.21.56-.31.89-.36,2.37-.22,4.74-.2,7.14-.04,1.43.11,2.92-.01,4.39,0,1.61.02,3.23.06,4.84.17l2.98.21c1.51.12,3.02.3,4.52.48.59.07,1.17.13,1.75.2.59.05,1.18.16,1.77.29.26.04.53.11.79.21.5.15.77.4.94.63.17.21.18.44.14.68-.02.11-.02.21-.05.29-.08.23-.66.4-1.2.27-1.79-.34-3.83-.61-5.92-.81-1.55-.15-3.14-.27-4.72-.35v-.05Z"
      />
      <path
        stroke={iconColorStyle[color]}
        strokeWidth={1}
        className="cls-1"
        d="m100.43,79.83c.04-4.23.05-8.46.03-12.7l-.09-4.63-.09-16.34c-.02-2.42-.09-4.85-.16-7.25-.03-1.54-.06-3.09-.06-4.64-.02-.77.03-1.55.11-2.33l.11-1.16c.04-.39.21-.79.31-1.18.31-.95.29-.79.49.05.22.82.79,2.31.86,4.06.27,6.25.26,12.54.2,18.89-.06,3.8.13,7.74.18,11.62.05,4.27.08,8.54.07,12.81-.01,2.63-.02,5.26-.03,7.89,0,1.99-.04,3.99-.07,5.98-.02,1.99-.07,3.98-.12,5.96-.02,1.54-.02,3.06-.07,4.59-.04,1.52-.01,3.04-.14,4.58-.04.68-.08,1.34-.12,2.02-.03.32-.05.6-.08.87-.04.26-.1.51-.15.73-.1.44-.2.79-.31,1.07-.2.57-.48.86-.81,1.06-.15.09-.29.22-.4.27-.35.15-.71-.97-.51-2.71.34-5.29.48-10.99.64-16.7.09-4.25.18-8.52.17-12.79h.05Z"
      />
      <path
        stroke={iconColorStyle[color]}
        strokeWidth={1}
        className="cls-1"
        d="m69.16,118.4c-4,.12-7.99.2-11.98.26l-4.37.1c-5.13.02-10.27-.03-15.4-.09-2.28-.02-4.56,0-6.82.02-1.45,0-2.91,0-4.36,0-1.45,0-2.91-.08-4.35-.39-.9-.18-.78-.22-.02-.48.38-.12.93-.37,1.59-.55.33-.09.69-.21,1.07-.28.38-.05.78-.08,1.2-.09,5.92-.18,11.82-.05,17.79.08,3.57.09,7.28-.06,10.92-.14,4.01-.07,8.02-.17,12.03-.32,2.47-.08,4.94-.19,7.4-.32,1.87-.09,3.74-.21,5.6-.34,1.87-.12,3.73-.27,5.57-.48,2.87-.27,5.65-.78,8.46-1.22.62-.09,1.22-.24,1.84-.34,2.31-.36,3.07-.27,3.66.22.13.11.3.21.39.31.28.34-.6.91-2.29,1.32-5.02,1.19-10.43,1.69-15.83,2.11-4.02.29-8.06.49-12.09.66v-.05Z"
      />
      <path
        stroke={iconColorStyle[color]}
        strokeWidth={1}
        className="cls-1"
        d="m22.33,61.83c-.18-4.82-.3-9.64-.22-14.49.02-1.77.05-3.54.16-5.31.15-1.57.28-3.13.62-4.72l.11-.59c.05-.21.14-.52.26-.75.24-.49.58-.88.93-1.2.72-.64,1.51-1,2.28-1.3,1.56-.58,3.11-.89,4.67-1.14,1.55-.25,3.11-.41,4.66-.55,2.75-.25,5.51-.41,8.23-.57,3.5-.2,7-.51,10.51-.13,1.09.1.95.23.04.55-.53.19-1.17.41-1.88.66-.78.24-1.7.43-2.7.55-3.55.23-7.09.46-10.65.7-3.53.26-7.09.46-10.46,1.19-.99.23-1.99.53-2.79,1-.41.23-.74.51-.96.8-.11.15-.19.29-.23.45-.03.05-.05.2-.07.33l-.06.39-.26,1.57-.14,1.62c-.12,1.07-.13,2.17-.2,3.26-.2,4.79-.11,9.62.05,14.45.1,2.97.24,5.94.42,8.91.27,4.5.56,9.02.98,13.47.3,3.47.82,6.84,1.16,10.27.08.75.16,1.49.25,2.25.25,2.82.22,3.73-.24,4.38-.09.15-.18.34-.26.43-.26.3-.8-.81-1.12-2.86-1.09-6.08-1.72-12.54-2.24-19.05-.38-4.83-.69-9.69-.92-14.55h.05Z"
      />
      <path
        stroke={iconColorStyle[color]}
        strokeWidth={1}
        className="cls-1"
        d="m94.94,58.82c.02,3.19.06,6.39.13,9.59l.13,3.5c.08,4.11.11,8.23.13,12.35.02,1.83.06,3.66.13,5.48.03,1.17.06,2.33.08,3.5,0,.58-.05,1.17-.08,1.75-.03.58-.09,1.17-.22,1.75-.16.72-.22.63-.47.01-.3-.62-.73-1.74-.86-3.07-.21-4.73-.43-9.46-.46-14.25,0-2.86-.23-5.84-.34-8.77l-.29-9.67-.11-5.96c-.04-3.01-.03-6.03-.03-9.03,0-2.33-.12-4.62.02-6.93.03-.51.05-1.01.09-1.53.02-.24.04-.46.07-.66.05-.2.1-.38.14-.54.1-.33.18-.59.28-.8.19-.42.37-.65.6-.82.1-.08.2-.18.29-.22.27-.13.47.68.56,2.05.05,1.01.06,2.04.07,3.07.02,1.03.08,2.09.05,3.13,0,2.12.01,4.26.02,6.41.02,3.2.06,6.42.14,9.64h-.05Z"
      />
      <path
        stroke={iconColorStyle[color]}
        strokeWidth={1}
        className="cls-1"
        d="m58.56,72.93c-.64-.71-1.3-1.41-2.01-2-.69-.61-1.47-1.12-2.22-1.38-.77-.27-1.38-.28-2.16,0-.75.29-1.52.79-2.24,1.36-1.07.84-2.01,1.89-2.96,2.94-3.33,3.76-6.21,8.03-9.08,12.27-1.25,1.91-2.5,3.83-3.74,5.72-1.6,2.42-3.09,4.97-5.25,7.01-.66.64-.63.51-.38-.26.13-.38.29-.93.48-1.6.22-.64.56-1.34.97-2.07,1.52-2.53,3.11-5.01,4.69-7.53,1.64-2.48,3.32-4.95,5.11-7.37,2.15-2.89,4.22-6.03,6.82-8.77.72-.74,1.48-1.47,2.34-2.12.85-.65,1.8-1.24,2.94-1.55.56-.17,1.19-.22,1.8-.15.61.07,1.17.26,1.68.5,1.01.49,1.87,1.14,2.66,1.82,1.92,1.71,3.49,3.69,4.97,5.7,2.24,3.05,4.22,6.27,6.21,9.43,1.54,2.45,3.12,4.82,4.72,7.18.36.51.71,1.01,1.07,1.53.63.98,1.03,1.61,1.25,2.1.18.51.24.86.26,1.17,0,.15.04.33.02.44-.03.2-.25.17-.69-.06-.4-.24-1.05-.64-1.59-1.35-1.71-2.01-3.08-4.26-4.53-6.5-1.41-2.25-2.83-4.53-4.26-6.82-2.14-3.35-4.33-6.72-6.91-9.63l.03-.04Z"
      />
      <path
        stroke={iconColorStyle[color]}
        strokeWidth={1}
        className="cls-1"
        d="m77.4,56.08c-2.08.5-4.25.14-6.07-.97-.31-.24-.63-.46-.94-.71-.27-.29-.56-.57-.79-.89l-.51-.92c-.12-.33-.21-.66-.31-.99-.17-.67-.18-1.36-.12-2.03.17-1.35.79-2.56,1.64-3.51.75-.85,1.65-1.56,2.66-2.04,1.27-.64,2.94-.81,4.17,0,.38.22.29.24-.09.33-.19.06-.45.09-.74.25-.29.15-.58.28-.89.35-.27.04-.54.19-.79.27l-.37.2c-.13.04-.26.09-.38.15-.51.22-.97.54-1.41.89-.85.72-1.52,1.65-1.91,2.7-.22.63-.22,1.34-.05,2,.13.32.19.66.4.93.09.14.16.3.26.43l.34.38c.52.53,1.19.98,1.96,1.22.37.16.78.2,1.17.29.4,0,.82.09,1.22.03,1-.06,2.02-.33,2.81-.92,1.2-.86,1.84-2.42,1.58-3.92-.19-1.19-.8-2.3-1.54-3.15l-.49-.54c-.31-.31-.5-.51-.62-.69-.1-.22-.15-.39-.18-.63-.01-.11-.04-.22-.05-.32,0-.16.11-.26.38-.31.26-.06.69-.04,1.1.2,1.3.65,2.16,1.84,2.69,3.11.27.64.47,1.31.58,2.04.12.71.1,1.53-.08,2.28-.06.57-.37,1.11-.6,1.64-.34.48-.65,1-1.11,1.36-.41.42-.91.69-1.39.99-.51.21-1.01.47-1.54.57v-.04Z"
      />
      <path
        stroke={iconColorStyle[color]}
        strokeWidth={1}
        className="cls-1"
        d="m57.34,36.49l-.55,1.5-.26.76-.13.38-.06.17s.01.02-.03.03c.03.12-.12.12.74.35.66.16,1.39.26,2.12.34l1.11.11.25.02h.06s.09.03.11.02l.04-.11.1-.37c.1-.37.19-.74.3-1.1.42-1.45.85-2.89,1.34-4.31,1.74-4.99,3.7-9.89,5.89-14.69.96-2.13,1.94-4.27,3-6.35.3-.62.64-1.23.96-1.85l.86-1.53.13-.2c0-.07-.12-.1-.18-.13l-.58-.24-1.09-.43-.28-.1c-.08-.06-.18-.09-.26-.14-.18-.09-.36-.17-.54-.25-.82-.35-.75-.38.1-.54.42-.05,1.05-.2,1.76-.13.37.03.71.06,1.18.16.67.17,1.73.44,1.95,1.01.05.09.08.18.11.29.04.11-.03.22-.04.33l-.14.66-.55.93-.26.45c-.17.3-.35.6-.51.91-.33.62-.67,1.24-.99,1.88-.66,1.26-1.25,2.56-1.92,3.83-1.31,2.55-2.58,5.15-3.81,7.77-1.53,3.31-2.89,6.86-4,10.4-.31.92-.56,1.86-.85,2.79l-.4,1.54-.15.58-.07.29s-.02.1-.04.14h-.15s-1.19-.12-1.19-.12c-.98-.09-1.97-.17-2.98-.34-.5-.09-1.01-.19-1.54-.39-.17-.07-.36-.14-.5-.25l-.23-.16-.18-.2c-.23-.28-.27-.68-.15-1.03.01-.13.09-.3.11-.39l.09-.27c.77-2.43,1.65-4.82,2.57-7.19,1.41-3.58,2.97-7.12,4.54-10.6,1.21-2.71,2.33-5.39,3.49-8.07.25-.59.5-1.17.76-1.77.49-1.1.84-1.81,1.16-2.27.34-.46.61-.68.92-.83.14-.06.3-.16.41-.19.2-.04.25.19.2.65-.05.46-.2,1.15-.53,1.96-1.91,4.89-4.24,9.88-6.46,14.93-1.69,3.73-3.33,7.49-4.72,11.33h-.04Z"
      />
      <path
        stroke={iconColorStyle[color]}
        strokeWidth={1}
        className="cls-1"
        d="m82.7,32.93c-1.36-.15-2.73-.29-4.1-.37-.5-.03-1-.01-1.5-.04-1.76-.08-3.53-.31-5.31-.35-.79-.03-1.58,0-2.36.01-1,.02-2.01.03-3.02,0-.31-.02-.28-.1-.03-.23.25-.13.73-.36,1.3-.49,2.03-.47,4.1-.64,6.18-.61.62.02,1.26-.01,1.9-.04.64,0,1.28-.02,1.92-.01,1.4.04,2.8.11,4.2.21l2.58.21c1.3.1,2.6.33,3.89.46,1,.13,2,.11,2.99.3.22.04.43.05.65.11.81.2,1.04.36,1.14.73.02.08.06.17.06.25.02.26-.35.42-.95.44-1.79.11-3.6-.04-5.45-.15-1.37-.05-2.73-.22-4.11-.35v-.05Z"
      />
      <path
        stroke={iconColorStyle[color]}
        strokeWidth={1}
        className="cls-1"
        d="m99.42,17.85c.13.14.03.36-.12.61-.05.08-.25.19-.32.16-.12-.05-.25-.11-.37-.17-.07-.03-.94-.82-1.19-1.13-.28-.28-.47-.58-.66-.89-.3-.66-.61-1.31-.88-1.96-.06-.14-.1-.3-.11-.46-.03-.55-.22-1.04-.44-1.51-.04-.08-.05-.17-.16-.22-.18-.09-.67-1.38-.67-1.56.02-.47-.08-.89-.06-1.37,0-.07-.05-.12.03-.21.18-.22.09-.33.06-.46-.04-.19-.04-.39-.03-.61.03-.12.12-.28.17-.41.04-.12.24-.19.29-.18.11.04.18-.08.26-.14.03-.02.06-.04.09-.05.02,0,.11.03.26.07.17.04.33.13.51.22.37.17.64.42.69.48,0,.02.31.38.52.8.22.42.44.85.49.94.06.09.03.21.03.32,0,.05.62,1.28.62,1.33.06.28.21.51.07.85-.03.07,0,.12.02.17.15.45.19.93.26,1.39.03.24.13.46.19.68.01.04,0,.09,0,.14,0,.06-.01.12,0,.17.19.52.21,1.1.41,1.6.03.12.04.77.08.91.02.08-.11.44-.05.49Z"
      />
      <path
        stroke={iconColorStyle[color]}
        strokeWidth={1}
        className="cls-1"
        d="m106.85,22.76c-.1.15-.32.09-.58-.06-.08-.05-.23-.26-.21-.33.03-.12.07-.24.11-.35.03-.06.67-.83.87-1.12.2-.28.42-.52.68-.71.52-.4,1.03-.81,1.55-1.2.12-.09.25-.15.39-.2.49-.15.89-.43,1.3-.72.07-.05.15-.08.18-.19.05-.19,1.21-.87,1.4-.89.45-.08.9-.13,1.35-.19.06,0,.11-.06.21,0,.22.15.32.06.46.04.19-.03.38-.05.59-.05.1,0,.23.07.35.12.11.04.17.29.16.33-.04.11.07.2.13.3.02.03.04.08.05.11,0,.03-.16.29-.32.58-.16.3-.33.59-.39.6-.01,0-.32.24-.64.48-.31.26-.68.45-.73.52-.07.06-.17.05-.27.06-.05,0-1.02.7-1.06.72-.23.09-.41.28-.73.21-.06-.01-.1.02-.15.04-.37.21-.79.33-1.19.49-.21.08-.39.2-.58.3-.03.02-.08.01-.12.02-.05.01-.11.01-.15.04-.44.26-.94.45-1.38.72-.1.06-.7.18-.81.27-.06.04-.4,0-.44.06Z"
      />
      <path
        stroke={iconColorStyle[color]}
        strokeWidth={1}
        className="cls-1"
        d="m68.68,99.15l-10.35.51c-1.26.07-2.52.2-3.77.26-4.44.2-8.89.37-13.35.47-1.98.06-3.97.12-5.93.18l-1.9.04c-.64-.02-1.27-.03-1.91-.07-1.27-.07-2.58-.25-3.8-.82-.76-.38-.58-.43.12-.55.34-.04.85-.24,1.4-.36.29-.08.6-.18.91-.22.31-.03.66-.07,1.01-.08,2.5-.11,5.04-.08,7.59-.17,2.55-.07,5.12-.13,7.7-.14,3.09-.02,6.3-.34,9.45-.53l10.43-.6,6.42-.35c3.25-.15,6.51-.31,9.73-.46,2.51-.12,4.97-.35,7.48-.4.55,0,1.09-.05,1.66-.02,1.04.06,1.73.19,2.2.36.47.21.72.45.9.74.08.13.19.27.22.39.1.38-.74.62-2.18.71-4.4.2-8.98.41-13.59.62-3.46.18-6.94.36-10.42.55v-.06Z"
      />
    </svg>
  );
}
