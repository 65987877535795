import React from 'react';
import uuid from 'react-uuid';

import concatClassNames from 'utils/classNames';

import {
  type LetterSpacing,
  type TextBreak,
  type TextColor,
  type TextDecoration,
  type TextMarginBottom,
  type TextMarginLeft,
  type TextMarginRight,
  type TextMarginTop,
  type TextPosition,
  type TextSize,
  type TextWeight,
  type TextWhiteSpace,
  letterSpacingStyle,
  textBreakStyle,
  textColorStyle,
  textDecorationStyle,
  textMarginBottom,
  textMarginLeft,
  textMarginRight,
  textMarginTop,
  type textOverflow,
  textOverflowStyle,
  textPositionStyle,
  textSizeStyle,
  textWeightStyle,
  textWhiteSpaceStyle,
} from '../Text/Text';

/*
 ** p : new Line
 ** span : inline
 ** complex : an array of TextProps encapsulated in a p
 */
type TextType = 'p' | 'span' | 'complex';

type TextProps =
  | {
      content: string | JSX.Element | TextProps[];
      contentType: TextType;
      size?: TextSize;
      weight?: TextWeight;
      color?: TextColor;
      tracking?: LetterSpacing;
      position?: TextPosition;
      whitespace?: TextWhiteSpace;
      textBreak?: TextBreak;
      textDecoration?: TextDecoration;
      marginTop?: TextMarginTop;
      marginRight?: TextMarginRight;
      marginBottom?: TextMarginBottom;
      marginLeft?: TextMarginLeft;
      overFlow?: textOverflow;
      hidden?: boolean;
    }
  | {
      content?: never;
      contentType?: never;
      size?: never;
      weight?: never;
      color?: never;
      tracking?: never;
      position?: never;
      whitespace?: never;
      textBreak?: never;
      textDecoration?: never;
      marginTop?: never;
      marginRight?: never;
      marginBottom?: never;
      marginLeft?: never;
      overFlow?: never;
      hidden?: boolean;
    };

type RichTextProps =
  | {
      fragments: TextProps[];
      loading?: false;
    }
  | {
      fragments?: never;
      loading: true;
    };

function getComplexTextFragment(fragment: TextProps): string | JSX.Element | JSX.Element[] {
  if (fragment.contentType === 'p') return fragment.content as string;
  if (fragment.contentType === 'span')
    return (
      <span
        className={concatClassNames(
          textSizeStyle[fragment.size ?? 'base'],
          textWeightStyle[fragment.weight ?? 'normal'],
          textColorStyle[fragment.color ?? 'black'],
          letterSpacingStyle[fragment.tracking ?? 'normal'],
          textPositionStyle[fragment.position ?? 'left'],
          textWhiteSpaceStyle[fragment.whitespace ?? 'pre-line'],
          textBreakStyle[fragment.textBreak ?? 'normal'],
          textDecorationStyle[fragment.textDecoration ?? 'no-underline'],
          textMarginTop[fragment.marginTop ?? 'none'],
          textMarginRight[fragment.marginRight ?? 'none'],
          textMarginBottom[fragment.marginBottom ?? 'none'],
          textMarginLeft[fragment.marginLeft ?? 'none'],
          textOverflowStyle[fragment.overFlow ?? 'none'],
          'w-fit',
          'max-w-full',
        )}
      >
        {fragment.content as string}
      </span>
    );
  else return (fragment.content as TextProps[]).map((fragment) => getRichTextFragment(fragment));
}

function getRichTextFragment(fragment: TextProps): JSX.Element {
  if (fragment.contentType === 'p') {
    return (
      <p
        key={uuid()}
        className={concatClassNames(
          textSizeStyle[fragment.size ?? 'base'],
          textWeightStyle[fragment.weight ?? 'normal'],
          textColorStyle[fragment.color ?? 'black'],
          letterSpacingStyle[fragment.tracking ?? 'normal'],
          textPositionStyle[fragment.position ?? 'left'],
          textWhiteSpaceStyle[fragment.whitespace ?? 'pre-line'],
          textBreakStyle[fragment.textBreak ?? 'normal'],
          textDecorationStyle[fragment.textDecoration ?? 'no-underline'],
          textMarginTop[fragment.marginTop ?? 'none'],
          textMarginRight[fragment.marginRight ?? 'none'],
          textMarginBottom[fragment.marginBottom ?? 'none'],
          textMarginLeft[fragment.marginLeft ?? 'none'],
          textOverflowStyle[fragment.overFlow ?? 'none'],
          'w-fit',
          'max-w-full',
        )}
        hidden={fragment.hidden ?? false}
      >
        {fragment.content as string}
      </p>
    );
  } else if (fragment.contentType === 'span') {
    return (
      <span
        key={uuid()}
        className={concatClassNames(
          textSizeStyle[fragment.size ?? 'base'],
          textWeightStyle[fragment.weight ?? 'normal'],
          textColorStyle[fragment.color ?? 'black'],
          letterSpacingStyle[fragment.tracking ?? 'normal'],
          textPositionStyle[fragment.position ?? 'left'],
          textWhiteSpaceStyle[fragment.whitespace ?? 'pre-line'],
          textBreakStyle[fragment.textBreak ?? 'normal'],
          textDecorationStyle[fragment.textDecoration ?? 'no-underline'],
          textMarginTop[fragment.marginTop ?? 'none'],
          textMarginRight[fragment.marginRight ?? 'none'],
          textMarginBottom[fragment.marginBottom ?? 'none'],
          textMarginLeft[fragment.marginLeft ?? 'none'],
          textOverflowStyle[fragment.overFlow ?? 'none'],
          'w-fit',
          'max-w-full',
        )}
        hidden={fragment.hidden ?? false}
      >
        {fragment.content as string}
      </span>
    );
  } else {
    return (
      <p
        key={uuid()}
        className={concatClassNames(
          textSizeStyle[fragment.size ?? 'base'],
          textWeightStyle[fragment.weight ?? 'normal'],
          textColorStyle[fragment.color ?? 'black'],
          letterSpacingStyle[fragment.tracking ?? 'normal'],
          textPositionStyle[fragment.position ?? 'left'],
          textWhiteSpaceStyle[fragment.whitespace ?? 'pre-line'],
          textBreakStyle[fragment.textBreak ?? 'normal'],
          textDecorationStyle[fragment.textDecoration ?? 'no-underline'],
          textMarginTop[fragment.marginTop ?? 'none'],
          textMarginRight[fragment.marginRight ?? 'none'],
          textMarginBottom[fragment.marginBottom ?? 'none'],
          textMarginLeft[fragment.marginLeft ?? 'none'],
          textOverflowStyle[fragment.overFlow ?? 'none'],
          'w-fit',
          'max-w-full',
        )}
        hidden={fragment.hidden ?? false}
      >
        {(fragment.content as TextProps[]).map((fragment) => getComplexTextFragment(fragment))}
      </p>
    );
  }
}
export function RichText({ fragments, loading = false }: RichTextProps): JSX.Element {
  // TODO : Loading with the correct size
  if (loading) {
    return (
      <div className={concatClassNames('w-40', 'h-5', 'my-0.5', 'bg-gray-50', 'rounded-md', 'animate-pulse')}></div>
    );
  } else {
    return <div>{fragments?.map((frag) => getRichTextFragment(frag))}</div>;
  }
}
