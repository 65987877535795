import { SpecksApisContext } from 'App';
import { UiComponentTypeEnum } from 'api';

import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { type QueryClient, useMutation, useQuery, useQueryClient } from 'react-query';
import { type NavigateFunction, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { useAuth0 } from '@auth0/auth0-react';
import {
  DndContext,
  type DragEndEvent,
  DragOverlay,
  KeyboardSensor,
  PointerSensor,
  type SensorDescriptor,
  type SensorOptions,
  type UniqueIdentifier,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { sortableKeyboardCoordinates } from '@dnd-kit/sortable';
import { Tooltip } from '@mui/material';

import { ComponentBackEndApiDetail } from 'pages/Component/BackEndApi/Api/Detail/Detail';
import { BaseEditPage } from 'pages/core/BaseEditPage/BaseEditPage';

import { CreateOrEditPageForm } from 'forms/CreatePageForm/CreateOrEditPageForm';
import { UnsavedChangesForm } from 'forms/UnsavedChangesForm/UnsavedChangesForm';

import { Button } from 'components/core/Button/Button';
import { type BreadCrumbsProps, Header } from 'components/core/Header/Header';
import { IsEditingChip } from 'components/core/IsEditingChip/IsEditingChip';
import { Modal } from 'components/core/Modal/Modal';
import { MasterZone, PageCanvaFrame } from 'components/core/PageCanvaFrame/PageCanvaFrame';
import { RichText } from 'components/core/RichText/RichText';
import { SelectMethodMenu } from 'components/core/SelectMethodMenu/SelectMethodMenu';
import { type ButtonMenuProps } from 'components/core/Settings/Settings.types';
import { Text } from 'components/core/Text/Text';
import { UiComponentInfosMenu } from 'components/core/UiComponentInfosMenu/UiComponentInfosMenu';
import { UiComponentListMenu } from 'components/core/UiComponentListMenu/UiComponentListMenu';
import { snapToCursor } from 'components/specks/DragnDrop/SnapToCursor';
import { Empty } from 'components/specks/Empty/Empty';
import { RevisionPanel } from 'components/specks/RevisionPanel/RevisionPanel';
import { UiComponentCard } from 'components/specks/UiComponentCard/UiComponentCard';
import { type UiComponentCardType } from 'components/specks/UiComponentCard/UiComponentCard.props';

import { type Component } from 'models/component.entity';
import { Page } from 'models/page.entity';
import { type PageRevision } from 'models/pageRevision.entity';
import type { Revision } from 'models/revision.entity';
import { type UiComponentRevision } from 'models/ui-component-revisions.entity';
import { UiComponent } from 'models/uiComponent.entity';

import ComponentUtils from 'utils/ComponentUtils';
import { localCollisionsAlgo } from 'utils/canvaCollisionDetectionAlgo';
import concatClassNames from 'utils/classNames';
import getIcon from 'utils/getIcon';
import { tooltipDescriptionsStyle } from 'utils/tooltipsDescriptions';

interface PageProviderProps {
  children: JSX.Element[] | JSX.Element;
  handler: PageHandler;
}

export interface PageHandler {
  selectedUiComponent: SortedUIC | undefined;
  setSelectedUiComponent: (sortedUic: SortedUIC | undefined) => void;
  isEditable: boolean;
}

export const PageContext: React.Context<PageHandler> = createContext({} as PageHandler);

export function PageProvider({ children, handler }: PageProviderProps): JSX.Element {
  return <PageContext.Provider value={handler}>{children}</PageContext.Provider>;
}

interface ComponentPageParams {
  componentId?: string;
  pageId?: string;
}

export interface SortedUIC {
  id: string;
  uiCompnent: UiComponent;
  index: number;
  children: SortedUIC[];
}

export function ComponentFrontEndPagePage(): JSX.Element {
  /* --------------------------------------------------- contexts --------------------------------------------------- */

  const { pageService, pageRevisionService, uiComponentService, componentService } = useContext(SpecksApisContext);
  const { componentId = '', pageId = '' }: ComponentPageParams = useParams();
  const navigate: NavigateFunction = useNavigate();
  const [searchParams] = useSearchParams();
  const queryClient: QueryClient = useQueryClient();
  const { getAccessTokenSilently } = useAuth0();

  /* --------------------------------------------------- variables -------------------------------------------------- */

  const isEditionPage: boolean = searchParams.get('edition') === 'true';
  const selectedUiComponentId: string | undefined = searchParams.get('uicid') ?? undefined;
  const revisionDate: string | undefined = searchParams.get('revision_timestamp') ?? undefined;

  /* ---------------------------------------------------- states ---------------------------------------------------- */

  const [unsortedUics, setUnsortedUics] = useState<SortedUIC[]>([]);
  const [unsortedUicsUnsaved, setUnsortedUicsUnsaved] = useState<SortedUIC[]>([]);
  // Previous page Data
  const [previousPageData, setPreviousPageData] = useState<Page | undefined>(undefined);
  const [selectedSortedUic, setSelectedSortedUic] = useState<SortedUIC>();
  const [isNewUIComponent, setIsNewUIComponent] = useState<boolean>(false);
  const [functionRemoveUiComponent, setFunctionRemoveUiComponent] = useState<() => void>();
  const [isAlertUnsavedChangesOpen, setIsAlertUnsavedChangesOpen] = useState<boolean>(false);
  const [isDeleteUIComponentModalOpened, setIsDeleteUIComponentModalOpened] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSelectingMethod, setIsSelectingMethod] = useState<boolean>(false);
  const [selectedMethodId, setSelectedMethodId] = useState<string>();
  const [fnSetMethodId, setFnSetMethodId] = useState<(methodId: string | undefined) => void>();
  const [isUiCOmponentUpdateLoading, setIsUiCOmponentUpdateLoading] = useState<boolean>(false);
  const [filteredComponents, setFilteredComponents] = useState<SortedUIC[]>([]);
  const [forceUpdate, setForceUpdate] = useState<boolean>(false);

  // open the versions side menu
  const [isVersionsSideMenuOpen, setIsVersionsSideMenuOpen] = useState<boolean>(false);

  // Query params
  const [selectedUiComponentIdState, setSelectedUiComponentIdState] = useState<string | undefined>(
    selectedUiComponentId,
  );
  const [isEditionPageState, setIsEditionPageState] = useState<boolean>(isEditionPage);
  const [revisionDateState, setRevisionDateState] = useState<Date | undefined>(
    revisionDate === undefined ? undefined : new Date(+revisionDate),
  );

  const [revisionDateString, setRevisionDateString] = useState<string | undefined>(undefined);

  const [overUicId, setOverUicId] = useState<UniqueIdentifier | null>(null);
  const [activeDraggedUicId, setActiveDraggedUicId] = useState<UniqueIdentifier | null>(null);

  let isFormDirty: boolean = false;

  function setIsFormDirty(isDirty: boolean): void {
    isFormDirty = isDirty;
  }

  /* ---------------------------------------------------- Queries --------------------------------------------------- */

  async function getPage(): Promise<Page> {
    const accessToken: string = await getAccessTokenSilently();
    return await pageService.getPage(
      pageId,
      revisionDateState === undefined
        ? Date.parse(new Date().toString()).toString()
        : (Date.parse(revisionDateState?.toString() ?? '') + revisionDateState.getMilliseconds()).toString(),
      accessToken,
    );
  }

  const { data: pageData, status: pageStatus } = useQuery<Page, Error>(['front', 'page', pageId], getPage);

  async function getComponent(): Promise<Component> {
    const accessToken: string = await getAccessTokenSilently();
    return await componentService.findById(componentId, accessToken);
  }

  const { data: componentData } = useQuery<Component, Error>(['component', componentId], getComponent);

  async function getSubPageRevisions(): Promise<Revision[]> {
    const accessToken: string = await getAccessTokenSilently();
    return await pageRevisionService.getPageRevisions(pageId, accessToken);
  }

  const {
    data: pageSubRevisionsData,
    status: pageSubRevisionsStatus,
    refetch: refetchPageSubRevisionsData,
  } = useQuery<Revision[], Error>(['page', 'subRevisions', pageId], getSubPageRevisions, {
    cacheTime: 0,
  });

  /* ----------------------------------------------------- Drop ----------------------------------------------------- */

  function removeNewUicFromUnsortedUics(): void {
    setUnsortedUics(unsortedUics.filter((uic: SortedUIC) => uic.id !== '' && uic.id !== 'new'));
  }

  function transformSortedUICsToUiComponents(sortedUICs: SortedUIC[], parentId?: string): SortedUIC[] {
    const uics: SortedUIC[] = [];
    sortedUICs.forEach((sortedUIC: SortedUIC) => {
      sortedUIC.uiCompnent.uiComponentRevisions[0].parentId = parentId;
      sortedUIC.uiCompnent.uiComponentRevisions[0].index = sortedUIC.index;
      uics.push(sortedUIC);
      uics.push(...transformSortedUICsToUiComponents(sortedUIC.children, sortedUIC.id));
    });
    return uics;
  }

  function getActiveUicAndRemoveItFromSortedUics(
    activeId: string,
    filteredComponents: SortedUIC[],
  ): { uic: SortedUIC | undefined; filteredComponents: SortedUIC[] } {
    if (filteredComponents.length === 0) return { uic: undefined, filteredComponents };
    const res: { uic: SortedUIC | undefined; filteredComponents: SortedUIC[] } = {
      uic: undefined,
      filteredComponents,
    };
    // find the uic and substract the next uics
    filteredComponents.forEach((uic: SortedUIC, index) => {
      uic.index = index;
      if (res.uic !== undefined) {
        uic.index -= 1;
      }
      if (uic.id === activeId) {
        res.uic = uic;
      }
    });

    // if uic has been found, remove it from the list
    if (res.uic !== undefined) {
      res.filteredComponents = filteredComponents.filter((uic) => uic.id !== activeId);
      return res;
    }

    // If not found, redo on the childs
    filteredComponents.forEach((uic: SortedUIC) => {
      const tmp: any = getActiveUicAndRemoveItFromSortedUics(activeId, uic.children);
      uic.children = tmp.filteredComponents;
      if (tmp.uic !== undefined) {
        res.uic = tmp.uic;
      }
    });
    return res;
  }

  function _isDepthGood(depth: number, depthActiveUic: number, activeUICType: UiComponentCardType): boolean {
    const maxDepth = 4;
    if (depth + depthActiveUic > maxDepth && (activeUICType === 'container' || activeUICType === 'list')) return false;
    return true;
  }

  function placeActiveUicInSortedUics(
    overId: string,
    filteredComponents: SortedUIC[],
    activeUIC: SortedUIC,
    isFirst: boolean,
    parentId?: string,
    parentType?: UiComponentCardType,
    depth: number = 1,
    depthActiveUic: number = 0,
  ): { found: boolean; filteredComponents: SortedUIC[]; error?: string } {
    if (parentType === 'list' && filteredComponents.length > 1) {
      return {
        found: false,
        filteredComponents,
        error: 'Vous ne pouvez pas ajouter plusieurs composants dans une liste',
      };
    }
    if (filteredComponents.length === 0) {
      if (isFirst && parentId === activeUIC.uiCompnent.uiComponentRevisions[0].parentId) {
        if (!_isDepthGood(depth, depthActiveUic, activeUIC.uiCompnent.type)) {
          return {
            found: false,
            filteredComponents,
            error: 'Vous avez atteint le niveau maximal de profondeur pour les conteneurs (4 niveaux).',
          };
        }
        return { found: true, filteredComponents: [activeUIC, ...filteredComponents] };
      }
      return { found: false, filteredComponents };
    }
    const res: { found: boolean; filteredComponents: SortedUIC[]; error?: string } = {
      found: false,
      filteredComponents,
    };
    // If the over uic is the first one of the actual view (page or container)
    if (overId === 'undefined' && activeUIC.uiCompnent.uiComponentRevisions[0].parentId === parentId) {
      if (!_isDepthGood(depth, depthActiveUic, activeUIC.uiCompnent.type)) {
        return {
          found: false,
          filteredComponents,
          error: 'Vous avez atteint le niveau maximal de profondeur pour les conteneurs (4 niveaux).',
        };
      }
      activeUIC.index = 0;
      filteredComponents.splice(0, 0, activeUIC);
      filteredComponents.forEach((uic: SortedUIC, index) => {
        if (index > 0) {
          uic.index += 1;
        }
      });
      res.found = true;
      return res;
    }
    // Else
    const tataIndex: number = filteredComponents.findIndex((uic: SortedUIC) => uic.id === overId);
    if (tataIndex !== -1) {
      // place the uic at the correct place
      if (filteredComponents[tataIndex] !== undefined) {
        if (!_isDepthGood(depth, depthActiveUic, activeUIC.uiCompnent.type)) {
          return {
            found: false,
            filteredComponents,
            error: 'Vous avez atteint le niveau maximal de profondeur pour les conteneurs (4 niveaux).',
          };
        }
        if (isFirst) {
          activeUIC.index = 0;
          filteredComponents[tataIndex].children.splice(0, 0, activeUIC);
          // Update the indexes of the uics after the over uic
          filteredComponents[tataIndex].children.forEach((uic: SortedUIC, index) => {
            if (index > 0) {
              uic.index += 1;
            }
          });
        } else {
          activeUIC.index = filteredComponents[tataIndex].index + 1;
          filteredComponents.splice(tataIndex + 1, 0, activeUIC);
          // Update the indexes of the uics after the over uic
          filteredComponents.forEach((uic: SortedUIC, index) => {
            if (tataIndex + 1 < index) {
              uic.index += 1;
            }
          });
        }
        res.found = true;
        return res;
      }
    }
    filteredComponents.some((uic: SortedUIC) => {
      if (res.found) return true;
      const tmp: any = placeActiveUicInSortedUics(
        overId,
        uic.children,
        activeUIC,
        isFirst,
        uic.id,
        uic.uiCompnent.type,
        depth + 1,
        depthActiveUic,
      );
      if (tmp.error !== undefined) {
        res.error = tmp.error;
        uic.children = tmp.filteredComponents;
        res.found = false;
        return true;
      }
      if (tmp.found === true) {
        uic.children = tmp.filteredComponents;
        res.found = true;
        return true;
      }
      return false;
    });
    return res;
  }

  function getDepthUic(uic: SortedUIC, depth: number = 0): number {
    let maxDepth: number = depth;
    uic.children.forEach((child: SortedUIC) => {
      const tmp: number = getDepthUic(child, depth + 1);
      if (tmp > maxDepth) {
        maxDepth = tmp;
      }
    });
    if (uic.uiCompnent.type === 'container' && uic.children.length === 0) {
      maxDepth += 1;
    }
    return maxDepth;
  }

  // Move move active cui after the over cui in the filterredComponents
  function moveUIC(
    activeId: string,
    overId: string,
    isFirst: boolean,
    newUiComponent?: SortedUIC,
    parentId?: string,
  ): SortedUIC[] | undefined {
    // if the active uic is the same as the over uic, return the filteredComponents
    if (activeId === overId) return undefined;
    let activeUIC: SortedUIC = {
      id: 'new',
      index: 0,
      uiCompnent: new UiComponent(),
      children: [],
    };
    let updatedFilteredUics: SortedUIC[] = [];
    if (newUiComponent === undefined) {
      // Get the uic which is moved
      const { uic, filteredComponents: filterredComponents } = getActiveUicAndRemoveItFromSortedUics(
        activeId,
        filteredComponents,
      );
      updatedFilteredUics = filterredComponents;
      if (uic === undefined) return undefined;
      activeUIC = uic;
    } else {
      activeUIC = newUiComponent;
    }
    const depthActiveUic: number = getDepthUic(activeUIC);
    const parentIdSavedIfError: string | undefined = activeUIC.uiCompnent.uiComponentRevisions[0].parentId;
    activeUIC.uiCompnent.uiComponentRevisions[0].parentId = parentId;
    // Place it after the over uic
    const { filteredComponents: updatedFilteredUics2, error } = placeActiveUicInSortedUics(
      overId,
      newUiComponent === undefined ? updatedFilteredUics : filteredComponents,
      newUiComponent === undefined ? activeUIC : newUiComponent,
      isFirst,
      undefined,
      undefined,
      undefined,
      depthActiveUic,
    );

    if (error !== undefined) {
      toast.error(error);
      activeUIC.uiCompnent.uiComponentRevisions[0].parentId = parentIdSavedIfError;
      return undefined;
    }

    return updatedFilteredUics2;
  }

  async function onDragEnd(event: DragEndEvent): Promise<void> {
    setActiveDraggedUicId(null);
    const { active, over } = event;

    if (over === null) return;
    // If the user drops the uic on the left panel
    if (over.id === 'undroppable-zone') return;
    if (String(over.id).startsWith('didntmove-zone')) return;
    if (
      Object.values(UiComponentTypeEnum).includes(over.id as UiComponentTypeEnum) ||
      over.id === 'component-list-menu'
    ) {
      return;
    }

    const draggedComponent: SortedUIC | undefined = unsortedUics.find(
      (component: SortedUIC) => component.id === active.id,
    );
    if (draggedComponent !== undefined) {
      selectingUiComponent(draggedComponent);
    }

    if (active.id !== over.id) {
      let overId: string = 'toto active.id !== over.id';
      let parentId: string | undefined;
      let isFirst: boolean = false;
      isFirst = over.id.toString().startsWith('first');
      const splitOverId: string[] = over.id.toString().split('/');
      splitOverId.forEach((param: string) => {
        if (param.startsWith('droppable-zone-')) {
          overId = param.split('droppable-zone-')[1];
        }
        if (param.startsWith('parentId-')) {
          parentId = param.split('parentId-')[1];
        }
      });
      let newSortedUic: SortedUIC | undefined;
      if (Object.values(UiComponentTypeEnum).includes(active.id as UiComponentTypeEnum)) {
        const newUiComponent: UiComponent = new UiComponent(active.id as UiComponentCardType);
        newUiComponent.id = 'new';
        newUiComponent.uiComponentRevisions[0].parentId = parentId;
        setIsNewUIComponent(true);
        const foundOverUic: SortedUIC | undefined = unsortedUics.find(
          (unsortedUic: SortedUIC) => unsortedUic.id === overId,
        );
        newSortedUic = {
          id: '',
          index: isFirst ? 0 : foundOverUic !== undefined ? foundOverUic.index + 1 : 0,
          uiCompnent: newUiComponent,
          children: [],
        };
        selectingUiComponent(newSortedUic);
      }

      const updatedSortedUics: SortedUIC[] | undefined = moveUIC(
        active.id as string,
        overId,
        isFirst,
        newSortedUic,
        parentId,
      );
      if (updatedSortedUics === undefined) {
        setForceUpdate(!forceUpdate);
        if (newSortedUic !== undefined) {
          closeLeftPanel();
        }
        return;
      }
      const updatedUics: SortedUIC[] = transformSortedUICsToUiComponents(updatedSortedUics);
      setUnsortedUics(updatedUics);
      setUnsortedUicsUnsaved(updatedUics);

      if (newSortedUic === undefined) {
        mutateSaveUiComponent({
          update: true,
          uiComponentsToSave: updatedUics,
          isFake: true,
          susccessToastMessage: "La nouvelle organisation des composants d'interface a bien été enregistré",
        });
      }
    }

    setOverUicId(null);
  }

  /* --------------------------------------------------- EditPage --------------------------------------------------- */
  const [isEditPageModalOpened, setIsEditPageModalOpened] = useState<boolean>(false);

  async function editPage(page: Page): Promise<Page> {
    const accessToken: string = await getAccessTokenSilently();
    return await pageService.updatePage(pageId, page, accessToken);
  }

  const { mutate: editPageMutate } = useMutation(editPage, {
    onSuccess: (getPage: Page) => {
      toast.success('Page modifiée avec succès');
      setIsEditPageModalOpened(false);
      queryClient.setQueryData(['front', 'page', pageId], (oldData: Page | undefined) => {
        oldData = getPage;
        return oldData;
      });
    },
    onError: () => {
      toast.error('Erreur lors de la modification de la page');
    },
  });

  function handlePageEditionSubmission(pageToUpdate: Page): void {
    editPageMutate(pageToUpdate);
  }

  /* -------------------------------------------------- Delete Page ------------------------------------------------- */
  const [isDeletePageModalOpened, setIsDeletePageModalOpened] = useState<boolean>(false);

  async function deletePage(): Promise<void> {
    const accessToken: string = await getAccessTokenSilently();
    await pageService.delete(pageId, accessToken);
  }

  const { mutate: mutateDeletePage } = useMutation(deletePage, {
    onSuccess: () => {
      toast.success('La page à bien été supprimée');
      navigate(`/component/${componentId}`);
    },
    onError: () => {
      toast.error('Erreur lors de la suppression de cette page');
    },
  });

  function handleOnSubmitDeletePage(event: any): void {
    event.preventDefault();
    setIsLoading(true);
    setTimeout(() => {
      handlePageDeletionConfirmation();
    }, 1);
  }

  function handlePageDeletionConfirmation(): void {
    setIsDeletePageModalOpened(false);
    try {
      mutateDeletePage();
    } catch (error) {
      console.error(error);
    }
  }

  /* ----------------------------------------------- Buttons Menu ------------------------------------------------ */

  const buttonsMenu: ButtonMenuProps[] = [
    {
      name: 'Modifier',
      iconName: 'edit',
      textColor: 'gray-500',
      onClick: () => {
        setIsEditPageModalOpened(true);
      },
    },
    {
      name: 'Supprimer',
      iconName: 'trash',
      textColor: 'gray-500',
      onClick: () => {
        setIsDeletePageModalOpened(true);
      },
    },
  ];

  /* ------------------------------------------------- Setup Data -------------------------------------------------- */

  // Update the url with the query params
  useEffect(() => {
    let path: string = `/component/${componentId}/front/pages/${pageId}`;
    let isQueryParamAlreadySet: boolean = false;
    if (isEditionPageState) {
      if (!isQueryParamAlreadySet) path = path.concat('?');
      else path = path.concat('&');
      path = path.concat('edition=true');
      isQueryParamAlreadySet = true;
    }
    if (selectedUiComponentIdState !== undefined) {
      if (!isQueryParamAlreadySet) path = path.concat('?');
      else path = path.concat('&');
      path = path.concat(`uicid=${selectedUiComponentIdState}`);
      isQueryParamAlreadySet = true;
    }
    if (revisionDateState !== undefined) {
      if (!isQueryParamAlreadySet) path = path.concat('?');
      else path = path.concat('&');
      path = path.concat(
        `revision_timestamp=${Date.parse(revisionDateState?.toString() ?? '') + revisionDateState.getMilliseconds()}`,
      );
      isQueryParamAlreadySet = true;
    }
    navigate(path);
  }, [componentId, isEditionPageState, navigate, pageId, revisionDateState, selectedUiComponentIdState]);

  useEffect(() => {
    if (pageStatus === 'success') {
      const sortedUics: SortedUIC[] = [];
      pageData?.pageRevisions[0].uiComponents.forEach((uic: UiComponent) => {
        // if (uic.uiComponentRevisions[0].parent === undefined) {
        sortedUics.push({
          id: uic.id as string,
          uiCompnent: uic,
          index: ComponentUtils.lastRevision(uic)?.index as number,
          children: [],
        });
        // }
      });
      setUnsortedUics(sortedUics);
      setPreviousPageData(pageData);
    }
  }, [pageData, pageStatus]);

  function sortUICsByParent(uics: UiComponent[], parentId?: string): SortedUIC[] {
    const uicsByParent: SortedUIC[] = [];

    uics.forEach((uic: UiComponent) => {
      if (uic.uiComponentRevisions[0].parentId === parentId) {
        uicsByParent.push({
          id: uic.id as string,
          uiCompnent: uic,
          index: ComponentUtils.lastRevision(uic)?.index as number,
          // type: uic.type,
          children: sortUICsByParent(uics, uic.id as string),
        });
      }
    });
    uicsByParent.sort((a: SortedUIC, b: SortedUIC) => a.index - b.index);

    return uicsByParent;
  }

  useEffect(() => {
    const res: SortedUIC[] = sortUICsByParent(unsortedUics.map((uic: SortedUIC) => uic.uiCompnent));
    setFilteredComponents(res);
  }, [unsortedUics, forceUpdate]);

  const selectingUiComponent: (sortedUic?: SortedUIC) => void = useCallback((sortedUic?: SortedUIC) => {
    setSelectedSortedUic(sortedUic);
    if (sortedUic?.uiCompnent !== undefined) {
      setSelectedUiComponentIdState(sortedUic.uiCompnent.id);
    }
  }, []);

  const openSelectingSideMenu: (sortedUic: SortedUIC | undefined) => void = useCallback(
    (sortedUic: SortedUIC | undefined) => {
      const newUiComponent: SortedUIC = JSON.parse(JSON.stringify(sortedUic));
      selectingUiComponent(newUiComponent);
    },
    [selectingUiComponent],
  );

  useEffect(() => {
    if (selectedSortedUic !== undefined) return;
    if (selectedUiComponentIdState !== undefined) {
      const selectedSortedUic: SortedUIC | undefined = unsortedUics.find(
        (sortedUic: SortedUIC) => sortedUic.id === selectedUiComponentIdState,
      );
      if (selectedSortedUic !== undefined) {
        openSelectingSideMenu(selectedSortedUic);
      }
    }
  }, [selectedUiComponentIdState, unsortedUics]);

  // Get the date of the actual revision
  useEffect(() => {
    if (pageSubRevisionsStatus === 'success') {
      if (
        pageSubRevisionsData[0] !== undefined &&
        revisionDateState !== undefined &&
        (pageSubRevisionsData[0].createdAt > revisionDateState || pageSubRevisionsData[0].createdAt < revisionDateState)
      ) {
        const day: string = ('0' + String(revisionDateState.getDate())).slice(-2);
        const monthNumber: string = ('0' + String(revisionDateState.getMonth() + 1)).slice(-2);
        const fullYear: string = String(revisionDateState.getFullYear());
        const hour: string = ('0' + String(revisionDateState.getHours())).slice(-2);
        const minutes: string = ('0' + String(revisionDateState.getMinutes())).slice(-2);
        const dateAndTime: string = `${day}/${monthNumber}/${fullYear} à ${hour}:${minutes}`;
        setRevisionDateString(dateAndTime);
      } else setRevisionDateString(undefined);
    }
  }, [pageSubRevisionsData, pageSubRevisionsStatus, revisionDateState]);

  /* --------------------------------------------------- functions -------------------------------------------------- */

  function closeLeftPanel(): void {
    removeNewUicFromUnsortedUics();
    setIsNewUIComponent(false);
    setIsAlertUnsavedChangesOpen(false);
    setSelectedSortedUic(undefined);
    setSelectedMethodId(undefined);
    setSelectedUiComponentIdState(undefined);
  }

  // Change page
  async function _handleClickBack(): Promise<void> {
    if (isEditionPage) {
      setSelectedMethodId(undefined);
      setIsSelectingMethod(false);
      setIsVersionsSideMenuOpen(false);
      setIsEditionPageState(false);
      if (selectedSortedUic?.id === '') {
        closeLeftPanel();
      }
      await refetchPageSubRevisionsData();
    } else {
      navigate(`/component/${componentId}`);
    }
  }

  async function handleClickBack(): Promise<void> {
    if (isFormDirty) {
      setIsAlertUnsavedChangesOpen(true);
      setFunctionRemoveUiComponent(() => async () => {
        await _handleClickBack();
        setIsAlertUnsavedChangesOpen(false);
      });
    } else {
      await _handleClickBack();
    }
  }

  // CLose panel
  function handleClickBackSideMenu(): void {
    if (isFormDirty) {
      setIsAlertUnsavedChangesOpen(true);
      setFunctionRemoveUiComponent(() => closeLeftPanel);
    } else {
      closeLeftPanel();
    }
  }

  // Click UiComponent
  function _handleClickUiComponentCard(componentId: string): void {
    removeNewUicFromUnsortedUics();
    setIsAlertUnsavedChangesOpen(false);
    setIsNewUIComponent(false);
    openSelectingSideMenu(unsortedUics.find((unsortedUic: SortedUIC) => unsortedUic.id === componentId));
  }

  function handleClickUiComponentCard(componentId: string): void {
    if (isFormDirty) {
      setIsAlertUnsavedChangesOpen(true);
      setFunctionRemoveUiComponent(() => {
        return () => {
          _handleClickUiComponentCard(componentId);
        };
      });
    } else {
      _handleClickUiComponentCard(componentId);
    }
  }

  function checkIfNameIsAvailable(name: string, id: string): boolean {
    return (
      unsortedUics.find((unsortedUic) => {
        if (id !== unsortedUic.id && unsortedUic.uiCompnent.uiComponentRevisions[0].name === name) return true;
        return false;
      }) === undefined
    );
  }

  function handleClickEdit(): void {
    isVersionsSideMenuOpen && setIsVersionsSideMenuOpen(false);
    setRevisionDateState(undefined);
    setIsEditionPageState(true);
  }

  function handleClickHistory(): void {
    setIsVersionsSideMenuOpen(!isVersionsSideMenuOpen);
  }

  /* ----------------------------------------------- Save UiComponent ----------------------------------------------- */

  interface IUpdateOrCreateUiComponentRevision {
    update?: boolean;
    uiComponentsToSave?: SortedUIC[];
    isFake?: boolean;
    susccessToastMessage?: string;
  }

  function sortedUicToUic(sortedUics: SortedUIC[], isFake: boolean = false): UiComponent[] {
    const uics: UiComponent[] = [];
    sortedUics.forEach((sortedUic: SortedUIC, index) => {
      const uic: UiComponent = sortedUic.uiCompnent;
      if (!isFake) uic.uiComponentRevisions[0].index = index;
      uics.push(uic);
      if (!isFake) uics.push(...sortedUicToUic(sortedUic.children));
    });
    return uics;
  }

  async function updatePositionsOrCreateUiComponent({
    update = false,
    uiComponentsToSave,
    isFake = false,
  }: IUpdateOrCreateUiComponentRevision): Promise<PageRevision> {
    if (pageData === undefined) throw new Error('Missing data');
    const accessToken: string = await getAccessTokenSilently();
    setIsUiCOmponentUpdateLoading(true);
    // TODO : get the previous uicomponents
    let sortedUicsToSave: UiComponent[];
    if (uiComponentsToSave === undefined) {
      sortedUicsToSave = unsortedUicsUnsaved
        .filter((uic: SortedUIC) => uic.id !== '')
        .map((uic: SortedUIC) => {
          return uic.uiCompnent;
        });
    } else {
      sortedUicsToSave = sortedUicToUic(
        uiComponentsToSave.filter((uic: SortedUIC) => uic.id !== ''),
        isFake,
      );
    }
    if (update) {
      // DO update
      return await pageService.moveUic(pageData.id, sortedUicsToSave, accessToken);
    } else {
      return await pageService.createUiComponent(
        pageData.id,
        sortedUicsToSave,
        accessToken,
        selectedSortedUic?.uiCompnent,
      );
    }
  }

  const { mutate: mutateSaveUiComponent } = useMutation(updatePositionsOrCreateUiComponent, {
    onSuccess: async (pageRevision: PageRevision, { susccessToastMessage }) => {
      return onSuccessUpdatePositionOrCreateUiComponent(pageRevision, undefined, susccessToastMessage);
    },
    onError: () => {
      setIsUiCOmponentUpdateLoading(false);
      toast.error("Une erreur est survenue lors de l'enregistrement de ce composant d'interface");
      queryClient.setQueryData(['front', 'page', pageId], (oldData: Page | undefined) => {
        if (oldData === undefined) {
          return new Page();
        }
        setUnsortedUics(
          oldData.pageRevisions[0].uiComponents.map((uic: UiComponent) => {
            return { id: uic.id as string, uiCompnent: uic, index: uic.uiComponentRevisions[0].index, children: [] };
          }),
        );
        return oldData;
      });
    },
  });

  async function onSuccessUpdatePositionOrCreateUiComponent(
    newPageRevision: PageRevision,
    showUpdateInterface: boolean = true,
    toastMessage?: string,
  ): Promise<void> {
    if (showUpdateInterface) {
      closeLeftPanel();
      toast.success(toastMessage ?? "Le composant d'interface a bien été enregistré");
      setIsUiCOmponentUpdateLoading(false);
      setIsLoading(false);
    }

    queryClient.setQueryData(['front', 'page', pageId], (oldData: Page | undefined) => {
      if (oldData === undefined) {
        return new Page();
      }
      oldData.pageRevisions[0] = newPageRevision;
      setUnsortedUics(
        oldData.pageRevisions[0].uiComponents.map((uic: UiComponent) => {
          return { id: uic.id as string, uiCompnent: uic, index: uic.uiComponentRevisions[0].index, children: [] };
        }),
      );
      setPreviousPageData(oldData);
      setSelectedUiComponentIdState(undefined);
      return oldData;
    });
  }

  async function updateUiComponent(uiComponent: UiComponent): Promise<UiComponent> {
    setIsUiCOmponentUpdateLoading(true);
    const accessToken: string = await getAccessTokenSilently();
    return await uiComponentService.updateUiComponent(uiComponent.id ?? '', uiComponent, accessToken);
  }

  const { mutate: mutateUpdateUiComponent } = useMutation(updateUiComponent, {
    onSuccess: async (updatedUiComponent: UiComponent) => {
      queryClient.setQueryData(['front', 'page', pageId], (oldData: Page | undefined) => {
        if (oldData === undefined) {
          return new Page();
        }
        const uicomponentToUpdate: UiComponent | undefined = oldData.pageRevisions[0].uiComponents.find(
          (uic: UiComponent) => uic.id === updatedUiComponent.id,
        );
        if (uicomponentToUpdate === undefined) {
          return oldData;
        }
        updatedUiComponent.uiComponentRevisions[0].parentId = uicomponentToUpdate.uiComponentRevisions[0].parentId;
        updatedUiComponent.uiComponentRevisions[0].index = uicomponentToUpdate.uiComponentRevisions[0].index;
        uicomponentToUpdate.uiComponentRevisions = updatedUiComponent.uiComponentRevisions;
        setUnsortedUics(
          oldData.pageRevisions[0].uiComponents.map((uic: UiComponent) => {
            return { id: uic.id as string, uiCompnent: uic, index: uic.uiComponentRevisions[0].index, children: [] };
          }),
        );
        setPreviousPageData(oldData);
        setSelectedUiComponentIdState(undefined);

        closeLeftPanel();
        toast.success("Le composant d'interface a bien été mis à jour");
        setIsUiCOmponentUpdateLoading(false);

        return oldData;
      });
    },
    onError: () => {
      setIsUiCOmponentUpdateLoading(false);
      toast.error("Une erreur est survenue lors de l'enregistrement de ce composant d'interface");
    },
  });

  async function handleSaveClick(): Promise<void> {
    if (selectedSortedUic === undefined) throw new Error('Missing data');
    const uiComponentRevision: UiComponentRevision = selectedSortedUic.uiCompnent.uiComponentRevisions[0];
    if (uiComponentRevision.contentType !== 'LOGICAL') uiComponentRevision.sourceLogicalRule = '';
    if (uiComponentRevision.contentType !== 'CONSTANT') uiComponentRevision.sourceConstant = '';

    if (uiComponentRevision.hasPattern !== 'LOGICAL') uiComponentRevision.hasPatternLogicalRule = '';
    if (uiComponentRevision.hasPattern !== 'YES') uiComponentRevision.hasPatternConstant = '';

    if (uiComponentRevision.hasPlaceholder !== 'LOGICAL') uiComponentRevision.hasPlaceholderLogicalRule = '';
    if (uiComponentRevision.hasPlaceholder !== 'YES') uiComponentRevision.hasPlaceholderConstant = '';

    if (uiComponentRevision.isEditable !== 'LOGICAL') uiComponentRevision.isEditableLogicalRule = '';

    if (uiComponentRevision.isMandatory !== 'LOGICAL') uiComponentRevision.isMandatoryLogicalRule = '';

    if (uiComponentRevision.isPreFilled !== 'LOGICAL') uiComponentRevision.isPreFilledLogicalRule = '';
    if (uiComponentRevision.isPreFilled !== 'YES') uiComponentRevision.isPreFilledConstant = '';

    if (uiComponentRevision.isVisible !== 'LOGICAL') uiComponentRevision.isVisibleLogicalRule = '';

    if (uiComponentRevision.dateFormat !== 'CUSTOM') uiComponentRevision.dateCustom = '';

    if (selectedSortedUic?.id === '') {
      mutateSaveUiComponent({ update: false });
    } else {
      mutateUpdateUiComponent(selectedSortedUic.uiCompnent);
    }
  }

  /* ---------------------------------------------- Delete UI Component --------------------------------------------- */

  function removeUicFromSortedUicsAndputchildrenIntoBrother(
    activeId: string,
    filteredComponents: SortedUIC[],
  ): { uic: SortedUIC | undefined; filteredComponents: SortedUIC[] } {
    if (filteredComponents.length === 0) return { uic: undefined, filteredComponents };
    const res: { uic: SortedUIC | undefined; filteredComponents: SortedUIC[] } = {
      uic: undefined,
      filteredComponents,
    };
    // find the uic
    const uicIndex = filteredComponents.findIndex((uic: SortedUIC) => {
      if (uic.id === activeId) {
        return true;
      }
      return false;
    });
    // If found, remove the uic and put the children into the parent
    if (uicIndex !== -1) {
      const uic: SortedUIC = filteredComponents[uicIndex];
      res.uic = uic;
      uic.children.forEach((child: SortedUIC) => {
        child.uiCompnent.uiComponentRevisions[0].parentId = uic.uiCompnent.uiComponentRevisions[0].parentId;
      });
      filteredComponents.splice(uicIndex, 1, ...uic.children);
      filteredComponents.forEach((uic: SortedUIC, index) => {
        uic.index = index;
        uic.uiCompnent.uiComponentRevisions[0].index = index;
      });
      res.filteredComponents = filteredComponents;
      return res;
    }

    // If not found, redo on the childs
    filteredComponents.forEach((uic: SortedUIC) => {
      const tmp: any = removeUicFromSortedUicsAndputchildrenIntoBrother(activeId, uic.children);
      uic.children = tmp.filteredComponents;
      if (tmp.uic !== undefined) {
        res.uic = tmp.uic;
      }
    });
    return res;
  }

  function handleUIComponentDeletionConfirmation(): void {
    setIsDeleteUIComponentModalOpened(false);
    if (selectedSortedUic === undefined) return;
    const { filteredComponents: updatedComponents } = removeUicFromSortedUicsAndputchildrenIntoBrother(
      selectedSortedUic.id,
      filteredComponents,
    );
    toast.update('Suppression de ce composant interface en cours...');
    try {
      mutateSaveUiComponent({
        update: true,
        uiComponentsToSave: updatedComponents,
        susccessToastMessage: "Le composant d'interface a bien été supprimé",
      });

      // mutateDeleteUIComponent();
    } catch (error) {
      console.error(error);
    }
  }

  function handleUIComponentDeletionClick(): void {
    setIsDeleteUIComponentModalOpened(true);
  }

  function handleOnSubmitDeleteUIComponent(event: any): void {
    event.preventDefault();
    setIsLoading(true);
    setTimeout(() => {
      handleUIComponentDeletionConfirmation();
    }, 1);
  }

  function handleValidateSelectedMethod(): void {
    fnSetMethodId?.(selectedMethodId);
    setIsSelectingMethod(false);
    setSelectedMethodId(undefined);
  }

  function isUIComponentsEmpty(): boolean {
    return unsortedUics.length === 0;
  }

  /* ------------------------------------------------ set up handler ------------------------------------------------ */

  const pageHandler: PageHandler = {
    selectedUiComponent: selectedSortedUic,
    setSelectedUiComponent: setSelectedSortedUic,
    isEditable: isEditionPage,
  };

  /* ------------------------------------------------------ JSX ----------------------------------------------------- */

  const breadcrumbs: BreadCrumbsProps = {
    paths: [
      { name: 'Composants applicatifs', href: '/components' },
      { name: componentData?.name ?? 'Error', href: '/component/' + componentId },
    ],
    current:
      pageStatus === 'success'
        ? pageData?.pageRevisions[0].name ?? 'Error'
        : previousPageData !== undefined
        ? previousPageData.pageRevisions[0].name
        : 'Error',
  };

  const headerDescription: JSX.Element = (
    <div className="flex flex-row items-center gap-2">
      {getIcon('feather', 'cyan', 'sm')}
      <Text content="Page" color="cyan" size="sm" position="center" />
    </div>
  );

  const sensors: Array<SensorDescriptor<SensorOptions>> = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 0,
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  function _ClickDragStart(activeId: UniqueIdentifier): void {
    removeNewUicFromUnsortedUics();
    setActiveDraggedUicId(activeId);
    setIsAlertUnsavedChangesOpen(false);
    setSelectedUiComponentIdState(unsortedUics.find((uiComponent) => uiComponent.id === activeId)?.id);
    setSelectedSortedUic(unsortedUics.find((uiComponent) => uiComponent.id === activeId));
  }

  function onDragStart(event: DragEndEvent): void {
    const { active } = event;
    if (isFormDirty) {
      setIsAlertUnsavedChangesOpen(true);
      setFunctionRemoveUiComponent(() => {
        return () => {
          _ClickDragStart(active.id);
        };
      });
    } else {
      _ClickDragStart(active.id);
    }
  }

  function onDragOver(event: DragEndEvent): void {
    const { over } = event;

    if (over === null) return;
    setOverUicId(over.id);
  }

  function onDragCancel(event: DragEndEvent): void {
    setOverUicId(null);
  }

  interface WrappedUiCCardProps {
    id: UniqueIdentifier;
    format: 'panel' | 'canva';
  }

  function WrappedUiCCard({ id, format }: WrappedUiCCardProps): JSX.Element {
    const [uic, setUiC] = useState<UiComponent | null>(null);

    useEffect(() => {
      const unsortedUic: SortedUIC | undefined = unsortedUics.find((component) => component.id === id);

      if (unsortedUic != null) {
        setUiC(unsortedUic.uiCompnent);
      }
    }, [id]);

    if (Object.values(UiComponentTypeEnum).includes(id as UiComponentCardType)) {
      return <UiComponentCard type={id as UiComponentCardType} />;
    }

    if (uic === null) {
      return <></>;
    }

    return (
      <UiComponentCard
        type={uic.type}
        uiComponentId={uic.id}
        name={uic.uiComponentRevisions[0].name}
        format={format}
        isSelected={uic.id === selectedSortedUic?.id}
        onClick={handleClickUiComponentCard}
        isDragged
      />
    );
  }

  return (
    <>
      <BaseEditPage menu={!isEditionPage}>
        <PageProvider handler={pageHandler}>
          <DndContext
            sensors={sensors}
            collisionDetection={localCollisionsAlgo}
            onDragStart={onDragStart}
            onDragOver={onDragOver}
            onDragCancel={onDragCancel}
            onDragEnd={onDragEnd}
            modifiers={[snapToCursor]}
          >
            <DragOverlay
              dropAnimation={null}
              // dropAnimation={{
              //   duration: 500,
              //   easing: 'cubic-bezier(0.18, 0.67, 0.6, 1.22)',
              // }}
            >
              {activeDraggedUicId !== null ? <WrappedUiCCard id={activeDraggedUicId} format="canva" /> : null}
            </DragOverlay>
            <Modal
              title="Vous êtes sur le point de perdre vos modifications. Êtes vous sûrs ?"
              isOpen={isAlertUnsavedChangesOpen && functionRemoveUiComponent !== undefined}
              setIsOpen={setIsAlertUnsavedChangesOpen}
            >
              <UnsavedChangesForm onSubmit={functionRemoveUiComponent} />
            </Modal>

            <Modal
              title="Attention"
              isOpen={isDeleteUIComponentModalOpened}
              setIsOpen={setIsDeleteUIComponentModalOpened}
            >
              <Text
                whitespace="pre-line"
                position="justify"
                content="Vous allez supprimer ce composant d'interface. Êtes-vous certain(e) de vouloir poursuivre ?"
              />
              <div className="flex flex-row gap-2 justify-end">
                {!isLoading && (
                  <Button
                    onClick={handleOnSubmitDeleteUIComponent}
                    content="Supprimer"
                    width="1/2"
                    type="submit"
                    height="sm"
                  />
                )}
                {isLoading && <Button iconName="spinCircle" width="1/2" height="sm" iconAnimation="spin" />}
              </div>
            </Modal>
            <>
              {pageStatus === 'success' && (
                <Modal
                  title="Modifier le nom de la page"
                  isOpen={isEditPageModalOpened}
                  setIsOpen={setIsEditPageModalOpened}
                >
                  <CreateOrEditPageForm onSubmit={handlePageEditionSubmission} isEditing page={pageData} />
                </Modal>
              )}
            </>
            <Modal title="Attention" isOpen={isDeletePageModalOpened} setIsOpen={setIsDeletePageModalOpened}>
              <>
                <Text
                  whitespace="pre-line"
                  position="justify"
                  content="Vous allez supprimer cette page ainsi que tous les items qui y sont spécifiés. Êtes-vous certain(e) de vouloir poursuivre ?"
                />
                <div className="flex flex-row gap-2 justify-end">
                  {!isLoading && (
                    <Button
                      onClick={handleOnSubmitDeletePage}
                      content="Supprimer"
                      width="1/2"
                      type="submit"
                      height="sm"
                    />
                  )}
                  {isLoading && <Button iconName="spinCircle" width="1/2" height="sm" iconAnimation="spin" />}
                </div>
              </>
            </Modal>
            <Header
              queryStatus={
                pageStatus === 'success' ? pageStatus : previousPageData !== undefined ? 'success' : pageStatus
              }
              buttonsMenu={buttonsMenu}
              breadCrumbs={isEditionPage ? undefined : breadcrumbs}
              handleClickBack={handleClickBack}
              description={headerDescription}
              title={
                pageStatus === 'success' ? pageData?.pageRevisions[0].name : previousPageData?.pageRevisions[0].name
              }
              marginX
              marginY
              thumbnail={true}
              thumbnailIcon={'component'}
              thumbnailTooltipMessage={tooltipDescriptionsStyle.component}
              rightExtra={
                !isEditionPage ? (
                  <div className="flex flex-row gap-2 items-center">
                    {revisionDateString !== undefined && (
                      <div className={concatClassNames('flex flex-row bg-branding-25 p-2 px-3 rounded-md gap-2')}>
                        {getIcon('alertTriangle', 'red', 'md')}
                        <RichText
                          fragments={[
                            {
                              contentType: 'span',
                              content: 'Lecture seule : ',
                              weight: 'bold',
                              size: 'sm',
                            },
                            {
                              contentType: 'span',
                              content: `Ancienne révision du ${revisionDateString}`,
                              size: 'sm',
                            },
                          ]}
                        />
                      </div>
                    )}
                    <Tooltip title="Accéder aux révisions précédentes" placement="bottom">
                      <div
                        className={concatClassNames(
                          'bg-gradient-to-r from-gradient2-from to-gradient2-to',
                          'p-2',
                          'm-1',
                          'rounded-md',
                          'cursor-pointer',
                          'h-fill w-fill',
                        )}
                        onClick={handleClickHistory}
                      >
                        {getIcon('clock', 'white', 'md')}
                      </div>
                    </Tooltip>

                    {revisionDateString === undefined && (
                      <Button
                        content="Editer"
                        width="lg"
                        bgColor="gradient2"
                        textColor="white"
                        borderColor="none"
                        borderWidth="xs"
                        iconName="edit"
                        iconPosition="left"
                        iconColor="white"
                        onClick={handleClickEdit}
                        height="sm"
                      />
                    )}
                  </div>
                ) : (
                  <></>
                )
              }
              leftExtra={<>{isEditionPage && <IsEditingChip />}</>}
            />
            <div id="content" className="relative flex flex-row flex-grow items-stretch overflow-auto">
              {isSelectingMethod && (
                <SelectMethodMenu
                  componentId={componentId}
                  onClickBackButton={() => {
                    setIsSelectingMethod(false);
                  }}
                  setSelectedMethod={setSelectedMethodId}
                  validateSelectedMethod={handleValidateSelectedMethod}
                />
              )}
              {isEditionPage && selectedSortedUic === undefined && (
                <UiComponentListMenu name="Choisir un composant d'interface" />
              )}
              {selectedSortedUic !== undefined && (
                <UiComponentInfosMenu
                  uiComponent={selectedSortedUic?.uiCompnent}
                  checkIfNameIsUnique={checkIfNameIsAvailable}
                  onClickBackButton={handleClickBackSideMenu}
                  onClickSave={isEditionPage ? handleSaveClick : undefined}
                  setIsFormDirty={setIsFormDirty}
                  handleUIComponentDeletion={handleUIComponentDeletionClick}
                  isEditable={isEditionPage}
                  onClickAddTarget={() => {
                    setIsSelectingMethod(true);
                  }}
                  hidden={isSelectingMethod}
                  setFnSetMethodId={setFnSetMethodId}
                  copyToClipboard={
                    revisionDateString === undefined
                      ? async () => {
                          await navigator.clipboard.writeText(
                            `${window.location.host}/component/${componentId}/front/pages/${pageId}?edition=true${
                              selectedUiComponentId !== undefined ? `&uicid=${selectedUiComponentId}` : ''
                            }`,
                          );
                          toast.success('Le lien vers ce composant a bien été copié.');
                        }
                      : undefined
                  }
                  isLoading={isUiCOmponentUpdateLoading}
                />
              )}
              {pageStatus === 'success' &&
                isUIComponentsEmpty() &&
                !isEditionPage &&
                revisionDateString === undefined && (
                  <div
                    className={concatClassNames(
                      'absolute',
                      'flex flex-col',
                      'h-full',
                      'w-full',
                      'justify-center',
                      'items-center',
                      'z-10',
                    )}
                  >
                    <Empty title="Aucun composant UI défini" width="2/5" height="fit" icon="Ui">
                      <Button content="Commencez !" width="fit" height="sm" onClick={handleClickEdit} iconName="plus" />
                    </Empty>
                  </div>
                )}
              {selectedMethodId === undefined && (
                <PageCanvaFrame>
                  <MasterZone
                    uics={filteredComponents}
                    parentId={undefined}
                    activeDraggedUicId={activeDraggedUicId}
                    overUicId={overUicId}
                    selectedUiComponentId={selectedSortedUic?.id}
                    handleClickUiComponentCard={handleClickUiComponentCard}
                  />
                </PageCanvaFrame>
              )}
              {selectedMethodId !== undefined && (
                <ComponentBackEndApiDetail selectedMethodId={selectedMethodId} isEditable={false} />
              )}
              <div>
                <RevisionPanel
                  isVersionsSideMenuOpen={isVersionsSideMenuOpen}
                  subRevisionsData={pageSubRevisionsData ?? []}
                  subRevisionsStatus={pageSubRevisionsStatus}
                  onClickRevision={(revisionTimestamp: Date) => {
                    setRevisionDateState(revisionTimestamp);
                    closeLeftPanel();
                  }}
                  actualRevisionTimestamp={revisionDateState}
                />
              </div>
            </div>
          </DndContext>
        </PageProvider>
      </BaseEditPage>
    </>
  );
}
