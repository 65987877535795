/* tslint:disable */
/* eslint-disable */
/**
 * Specks API
 * APIs for specks SaaS
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Dictionary } from './Dictionary';
import {
    DictionaryFromJSON,
    DictionaryFromJSONTyped,
    DictionaryToJSON,
} from './Dictionary';

/**
 * 
 * @export
 * @interface DictionaryEntry
 */
export interface DictionaryEntry {
    /**
     * 
     * @type {string}
     * @memberof DictionaryEntry
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof DictionaryEntry
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof DictionaryEntry
     */
    definition: string;
    /**
     * 
     * @type {Dictionary}
     * @memberof DictionaryEntry
     */
    dictionary: Dictionary;
}

/**
 * Check if a given object implements the DictionaryEntry interface.
 */
export function instanceOfDictionaryEntry(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "definition" in value;
    isInstance = isInstance && "dictionary" in value;

    return isInstance;
}

export function DictionaryEntryFromJSON(json: any): DictionaryEntry {
    return DictionaryEntryFromJSONTyped(json, false);
}

export function DictionaryEntryFromJSONTyped(json: any, ignoreDiscriminator: boolean): DictionaryEntry {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'definition': json['definition'],
        'dictionary': DictionaryFromJSON(json['dictionary']),
    };
}

export function DictionaryEntryToJSON(value?: DictionaryEntry | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'definition': value.definition,
        'dictionary': DictionaryToJSON(value.dictionary),
    };
}

