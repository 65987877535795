import { type CreateFaqEntryDto, FaqsApi, FaqsEntriesApi, type GetFaqDto, type GetFaqEntryDto } from 'api';

import { type FaqEntry } from 'models/faq-entry.entity';
import { Faq } from 'models/faq.entity';

export class FaqService {
  private readonly faqClient: FaqsApi;
  private readonly faqEntriesClient: FaqsEntriesApi;

  constructor() {
    this.faqClient = new FaqsApi();
    this.faqEntriesClient = new FaqsEntriesApi();
  }

  public async findById(id: string, accessToken: string): Promise<Faq> {
    const getFaqDto: GetFaqDto = await this.faqClient.faqControllerFindById(
      { id },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
      },
    );
    const faq: Faq = new Faq();
    faq.id = getFaqDto.id;
    faq.entries = getFaqDto.entries;
    return faq;
  }

  public async createEntry(id: string, entry: CreateFaqEntryDto, accessToken: string): Promise<FaqEntry> {
    const getFaqEntryDto: GetFaqEntryDto = await this.faqClient.faqControllerCreateEntry(
      {
        id,
        createFaqEntryDto: entry,
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
      },
    );
    return getFaqEntryDto as FaqEntry;
  }

  public async deleteEntry(id: string, accessToken: string): Promise<void> {
    await this.faqEntriesClient.faqEntriesControllerDelete(
      { id },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
      },
    );
  }
}
