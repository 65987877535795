import React from 'react';

import concatClassNames from 'utils/classNames';
import getIcon from 'utils/getIcon';

import { type IconColor, type IconName, type IconSize } from 'icons/Icon.props';

type AbsoluteVerticalPosition = 'top' | 'center' | 'bottom';

const absoluteVerticalPositionStyle = {
  top: 'top-0',
  center: 'top-1/2 transform -translate-y-1/2',
  bottom: 'bottom-0',
};

type AbsoluteHorizontalPosition = 'left' | 'center' | 'right';

const absoluteHorizontalPositionStyle = {
  left: 'left-0',
  center: 'left-1/2 transform -translate-x-1/2',
  right: 'right-0',
};

type BadgeShadow = 'none' | 'sm' | 'md' | 'lg';

export const badgeShadowStyle: Record<BadgeShadow, string> = {
  none: 'drop-shadow-none',
  sm: 'drop-shadow-sm',
  md: 'drop-shadow-md',
  lg: 'drop-shadow-lg',
};

export type BadgeProps =
  | {
      icon: IconName;
      color: IconColor;
      size: IconSize;
      absolute?: boolean;
      shadow?: BadgeShadow;
      absoluteVerticalPosition?: AbsoluteVerticalPosition;
      absoluteHorizontalPosition?: AbsoluteHorizontalPosition;
      hasBackground?: boolean;
      iconWidth?: number;
      loading?: false;
    }
  | {
      icon?: never;
      color?: never;
      size?: never;
      absolute?: never;
      shadow?: never;
      absoluteVerticalPosition?: never;
      absoluteHorizontalPosition?: never;
      hasBackground?: never;
      iconWidth?: never;
      loading: true;
    };

export function IconBadge({
  icon,
  color,
  size,
  absolute = false,
  shadow = 'none',
  absoluteVerticalPosition = 'center',
  absoluteHorizontalPosition = 'center',
  hasBackground = false,
  iconWidth,
  loading = false,
}: BadgeProps): JSX.Element {
  const className: string = 'w-20 h-20 rounded-lg';

  if (loading) return <div className={concatClassNames(className, 'bg-gray-50 animate-pulse')} />;
  return (
    <div
      className={concatClassNames(
        className,
        absolute
          ? concatClassNames(
              'absolute',
              absoluteVerticalPositionStyle[absoluteVerticalPosition],
              absoluteHorizontalPositionStyle[absoluteHorizontalPosition],
            )
          : '',
        'flex justify-center items-center',
        hasBackground ? 'border-solid border-1 border-gray-50 bg-gray-15' : '',

        badgeShadowStyle[shadow],
      )}
    >
      {icon !== undefined && getIcon(icon, color, size, iconWidth)}
    </div>
  );
}
