import { SpecksApisContext } from 'App';

import React, { useContext, useState } from 'react';
import { type QueryClient, useMutation, useQuery, useQueryClient } from 'react-query';
import { type NavigateFunction, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { useAuth0 } from '@auth0/auth0-react';

import { BasePage } from 'pages/core/BasePage/BasePage';

import { CreateorEditProductForm } from 'forms/CreateorEditProductForm/CreateorEditProductForm';

import { Button } from 'components/core/Button/Button';
import { CardContainer } from 'components/core/CardContainer/CardContainer';
import { Modal } from 'components/core/Modal/Modal';
import { Section } from 'components/core/Section/Section';
import { type ButtonMenuProps } from 'components/core/Settings/Settings.types';
import { SubTitle } from 'components/core/SubTitle/SubTitle';
import { Table } from 'components/core/Table/Table';
import { TableBody } from 'components/core/Table/TableBody';
import { Title } from 'components/core/Title/Title';
import { ContextMenu } from 'components/specks/ContextMenu/ContextMenu';
import { DeleteProduct } from 'components/specks/DeleteProduct/DeleteProduct';
import { Empty } from 'components/specks/Empty/Empty';
import { NetworkError } from 'components/specks/Error/Error';
import { ProductCard } from 'components/specks/ProductCard/ProductCard';
import { ProductRow } from 'components/specks/ProductRow/ProductRow';
import { Thumbnail } from 'components/specks/Thumbnail/Thumbnail';

import type { Product } from 'models/product.entity';

import concatClassNames from 'utils/classNames';
import getIcon from 'utils/getIcon';

export function ProductsPage(): JSX.Element {
  /* --------------------------------------------------- contexts --------------------------------------------------- */

  const { productService } = useContext(SpecksApisContext);
  const navigate: NavigateFunction = useNavigate();
  const { getAccessTokenSilently } = useAuth0();
  const queryClient: QueryClient = useQueryClient();

  /* ---------------------------------------------------- states ---------------------------------------------------- */

  const [isModalOpened, setIsModalOpened] = useState<boolean>(false);
  const [isLoading] = useState<boolean>(false);

  const [productToDelete, setProductToDelete] = useState<string>();
  const [rightClickedProduct, setRightClickedProduct] = useState<string>();

  const [points, setPoints] = useState({ x: 0, y: 0 });

  const [isCardView, setIsCardView] = useState<boolean>(false);

  /* ---------------------------------------------------- Queries --------------------------------------------------- */

  async function getProducts(): Promise<Product[]> {
    const token: string = await getAccessTokenSilently();
    return await productService.findAll(token);
  }

  const { data, status } = useQuery<Product[], Error>('products', getProducts);

  /* ------------------------------------------------ Create Product ------------------------------------------------ */

  async function postProduct(product: Product): Promise<Product> {
    const token: string = await getAccessTokenSilently();
    return await productService.create(product, token);
  }

  const { mutate } = useMutation(postProduct, {
    onSuccess: (product) => {
      toast.success('Produit créé avec succès');
      navigate(`/product/${product.id}`);
    },
    onError: () => {
      alert('there was an error');
    },
  });

  function handleProductFormSubmission(product: Product): void {
    try {
      mutate(product);
    } catch (error) {
      console.error(error);
    }
  }

  /* ------------------------------------------------ Delete Product ------------------------------------------------ */

  function productDeletionOnSuccess(): void {
    queryClient.setQueryData('products', (oldData: Product[] | undefined) => {
      if (oldData === undefined) {
        return new Array<Product>();
      } else {
        oldData = oldData.filter((product: Product) => product.id !== productToDelete);
        return oldData;
      }
    });
    setProductToDelete(undefined);
  }

  const productButtonsMenu: ButtonMenuProps[] = [
    {
      name: 'Supprimer ce produit',
      textColor: 'black',
      isEnabled: true,
      onClick: (event: any) => {
        event.stopPropagation();
        setProductToDelete(rightClickedProduct);
      },
    },
  ];

  /* -------------------------------------------------- functions; -------------------------------------------------- */

  function handleClickEmptyState(): void {
    setIsModalOpened(true);
  }

  function handleClickOpenModalCreateProduct(): void {
    setIsModalOpened(true);
  }

  /* ------------------------------------------------------ JSX ----------------------------------------------------- */

  return (
    <BasePage>
      <DeleteProduct
        setProductId={setProductToDelete}
        productId={productToDelete}
        behaviourAfterDeletion={productDeletionOnSuccess}
      />
      <Modal title="Définissez les paramètres de votre produit" isOpen={isModalOpened} setIsOpen={setIsModalOpened}>
        <CreateorEditProductForm onSubmit={handleProductFormSubmission} isLoading={isLoading} />
      </Modal>
      <Section isFullHeight gap="10">
        <div className={concatClassNames('flex flex-row justify-between')}>
          <div className={concatClassNames('flex flex-row justify-between gap-4')}>
            <Thumbnail
              thumbnailIcon="product"
              thumbnailTooltipMessage="Un produit est un site web ou une application mobile qui répond à un besoin. Il est construit à partir de briques logicielles (aussi appelées composants applicatifs)."
            />
            <div className={concatClassNames('flex flex-col')}>
              <Title content={'Liste des produits'} />
              <SubTitle content={"Retrouvez ici l'ensemble de vos produits"} />
            </div>
          </div>
          <div className="flex flex-row justify-end items-center gap-6 px-10">
            <button
              onClick={() => {
                setIsCardView(false);
              }}
            >
              {isCardView ? <>{getIcon('list', 'gray-100', 'lg')}</> : <>{getIcon('list', 'gray-500', 'lg')}</>}
            </button>
            <button
              onClick={() => {
                setIsCardView(true);
              }}
            >
              {isCardView ? <>{getIcon('cards', 'gray-500', 'lg')}</> : <>{getIcon('cards', 'gray-100', 'lg')}</>}
            </button>
            <Button
              content="Nouveau produit"
              iconName="plus"
              width="fit"
              height="sm"
              onClick={handleClickOpenModalCreateProduct}
            />
          </div>
        </div>
        <>
          {status === 'error' && <NetworkError message="Un problème est survenu lors de l'affichage des produits." />}
          {status === 'loading' && (
            <CardContainer paddingX="2" paddingY="2">
              <ProductCard loading />
              <ProductCard loading />
            </CardContainer>
          )}
          {status === 'success' && data.length === 0 && (
            <Empty
              icon="product"
              title="Il n'existe encore aucun produit dans votre espace Specks"
              subtitle="Créez le premier produit!"
            >
              <Button
                content="Nouveau produit"
                onClick={handleClickEmptyState}
                iconName="plus"
                height="sm"
                width="fit"
              />
            </Empty>
          )}
          {status === 'success' && data.length !== 0 && isCardView && (
            <CardContainer paddingX="2" paddingY="2">
              {data.map((product) => {
                return (
                  <ProductCard
                    key={product.id}
                    id={product.id}
                    name={product.name}
                    status={product.status}
                    setPoints={setPoints}
                    setRightClickedProduct={setRightClickedProduct}
                    components={product.components}
                  ></ProductCard>
                );
              })}
            </CardContainer>
          )}
          {status === 'success' && data.length !== 0 && !isCardView && (
            <Table>
              <TableBody overflow="hidden">
                {data.map((product) => {
                  return (
                    <ProductRow
                      key={product.id}
                      id={product.id}
                      name={product.name}
                      status={product.status}
                      components={product.components}
                    />
                  );
                })}
              </TableBody>
            </Table>
          )}
        </>
      </Section>
      <>
        {rightClickedProduct !== undefined && (
          <ContextMenu x={points?.x - 50} y={points?.y} buttonsMenu={productButtonsMenu} />
        )}
      </>
    </BasePage>
  );
}
