/* tslint:disable */
/* eslint-disable */
/**
 * Specks API
 * APIs for specks SaaS
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdateUicPositionDto
 */
export interface UpdateUicPositionDto {
    /**
     * 
     * @type {number}
     * @memberof UpdateUicPositionDto
     */
    index: number;
    /**
     * 
     * @type {string}
     * @memberof UpdateUicPositionDto
     */
    parentId?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateUicPositionDto
     */
    uiComponentId: string;
}

/**
 * Check if a given object implements the UpdateUicPositionDto interface.
 */
export function instanceOfUpdateUicPositionDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "index" in value;
    isInstance = isInstance && "uiComponentId" in value;

    return isInstance;
}

export function UpdateUicPositionDtoFromJSON(json: any): UpdateUicPositionDto {
    return UpdateUicPositionDtoFromJSONTyped(json, false);
}

export function UpdateUicPositionDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateUicPositionDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'index': json['index'],
        'parentId': !exists(json, 'parentId') ? undefined : json['parentId'],
        'uiComponentId': json['uiComponentId'],
    };
}

export function UpdateUicPositionDtoToJSON(value?: UpdateUicPositionDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'index': value.index,
        'parentId': value.parentId,
        'uiComponentId': value.uiComponentId,
    };
}

