import React from 'react';

import concatClassNames from 'utils/classNames';

import { type TextSize, textSizeStyle } from '../Text/Text';

interface SubTitleProps {
  content: string;
  textSize?: TextSize;
}

export function SubTitle({ content, textSize = 'base' }: SubTitleProps): JSX.Element {
  return <h2 className={concatClassNames('font-medium', textSizeStyle[textSize], 'text-gray-300')}>{content}</h2>;
}
