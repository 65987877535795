import React from 'react';

import { BasePage } from 'pages/core/BasePage/BasePage';

export function NotFoundPage(): JSX.Element {
  return (
    <BasePage>
      <div>Page not found</div>
    </BasePage>
  );
}
