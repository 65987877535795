/* tslint:disable */
/* eslint-disable */
/**
 * Specks API
 * APIs for specks SaaS
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AssetDto
 */
export interface AssetDto {
    /**
     * 
     * @type {string}
     * @memberof AssetDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof AssetDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof AssetDto
     */
    url: string;
    /**
     * 
     * @type {string}
     * @memberof AssetDto
     */
    img: string;
    /**
     * 
     * @type {Date}
     * @memberof AssetDto
     */
    createdAt: Date;
}

/**
 * Check if a given object implements the AssetDto interface.
 */
export function instanceOfAssetDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "url" in value;
    isInstance = isInstance && "img" in value;
    isInstance = isInstance && "createdAt" in value;

    return isInstance;
}

export function AssetDtoFromJSON(json: any): AssetDto {
    return AssetDtoFromJSONTyped(json, false);
}

export function AssetDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): AssetDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'url': json['url'],
        'img': json['img'],
        'createdAt': (new Date(json['createdAt'])),
    };
}

export function AssetDtoToJSON(value?: AssetDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'url': value.url,
        'img': value.img,
        'createdAt': (value.createdAt.toISOString()),
    };
}

