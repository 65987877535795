import React from 'react';

import { Card } from 'components/core/Card/Card';
import { CardBody } from 'components/core/Card/CardBody';
import { IconBadge } from 'components/core/IconBadge/IconBadge';
import { Settings } from 'components/core/Settings/Settings';
import { type ButtonMenuProps } from 'components/core/Settings/Settings.types';
import { Text } from 'components/core/Text/Text';

import concatClassNames from 'utils/classNames';
import getIcon from 'utils/getIcon';

import { type IconName } from 'icons/Icon.props';

type PersonaCardProps =
  | {
      id?: string;
      name: string | undefined;
      age: string | undefined;
      description: string | undefined;
      likes: string | undefined;
      dislikes: string | undefined;

      icon: IconName;
      loading?: false;
      hasMenu?: boolean;
      onDeletionClick?: (id: string) => void;
      onModificationClick?: (id: string) => void;
    }
  | {
      id?: never;
      name?: never;
      age?: never;
      description?: never;
      likes?: never;
      dislikes?: never;

      icon?: never;
      href?: never;
      loading: true;
      hasMenu?: false;
      onDeletionClick?: never;
      onModificationClick?: never;
    };

export function PersonaCard({
  id = '',
  name = '',
  age = '',
  description = '',
  likes = '',
  dislikes = '',
  icon = 'home',
  loading = false,
  hasMenu = true,
  onDeletionClick,
  onModificationClick,
}: PersonaCardProps): JSX.Element {
  function handleClick(): void {
    console.log('Clicked');
  }
  /* --------------------------------------------------- functions -------------------------------------------------- */

  function handleDeletionClick(event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void {
    event.stopPropagation();
    if (onDeletionClick !== undefined) onDeletionClick(id);
  }

  function handleModificationClick(event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void {
    event.stopPropagation();
    if (onModificationClick !== undefined) onModificationClick(id);
  }

  /* ------------------------------------------------- menu options ------------------------------------------------- */

  const buttonsMenu: ButtonMenuProps[] = [
    {
      name: 'Modifier',
      iconName: 'edit',
      textColor: 'black',
      onClick: handleModificationClick,
    },
    {
      name: 'Supprimer',
      iconName: 'trash',
      textColor: 'red',
      onClick: handleDeletionClick,
    },
  ];
  return (
    <Card onClick={handleClick} loading={loading} shadow="md" height="full" cursor="pointer">
      <CardBody orientation="horizontal" justify="start" horizontalPadding="lg" gap="md">
        {loading ? (
          <>
            <IconBadge loading />
            <Text loading />
          </>
        ) : (
          <>
            {hasMenu && (
              <div className="absolute flex  top-2 right-4 bottom-2 items-center  ">
                <Settings settingsIconName="more" buttonsMenu={buttonsMenu} />
              </div>
            )}
            {getIcon('persona', 'cyan', 'xxl', 2)}
            <div className={concatClassNames('flex flex-col w-fit gap-3')}>
              <Text content={name} color="black" weight="bold" size="base" />
              {description}
              <p>Age : {age}</p>
              <p>❤️ {likes}</p>
              <p>😡 {dislikes}</p>
            </div>
          </>
        )}
      </CardBody>
    </Card>
  );
}
