/* tslint:disable */
/* eslint-disable */
/**
 * Specks API
 * APIs for specks SaaS
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  CreateFaqEntryDto,
  ErrorBadRequest,
  GetFaqDto,
  GetFaqEntryDto,
} from '../models';
import {
    CreateFaqEntryDtoFromJSON,
    CreateFaqEntryDtoToJSON,
    ErrorBadRequestFromJSON,
    ErrorBadRequestToJSON,
    GetFaqDtoFromJSON,
    GetFaqDtoToJSON,
    GetFaqEntryDtoFromJSON,
    GetFaqEntryDtoToJSON,
} from '../models';

export interface FaqControllerCreateEntryRequest {
    id: string;
    createFaqEntryDto: CreateFaqEntryDto;
}

export interface FaqControllerFindByIdRequest {
    id: string;
}

/**
 * 
 */
export class FaqsApi extends runtime.BaseAPI {

    /**
     */
    async faqControllerCreateEntryRaw(requestParameters: FaqControllerCreateEntryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetFaqEntryDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling faqControllerCreateEntry.');
        }

        if (requestParameters.createFaqEntryDto === null || requestParameters.createFaqEntryDto === undefined) {
            throw new runtime.RequiredError('createFaqEntryDto','Required parameter requestParameters.createFaqEntryDto was null or undefined when calling faqControllerCreateEntry.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/faqs/{id}/faq-entries`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateFaqEntryDtoToJSON(requestParameters.createFaqEntryDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetFaqEntryDtoFromJSON(jsonValue));
    }

    /**
     */
    async faqControllerCreateEntry(requestParameters: FaqControllerCreateEntryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetFaqEntryDto> {
        const response = await this.faqControllerCreateEntryRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async faqControllerFindByIdRaw(requestParameters: FaqControllerFindByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetFaqDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling faqControllerFindById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/faqs/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetFaqDtoFromJSON(jsonValue));
    }

    /**
     */
    async faqControllerFindById(requestParameters: FaqControllerFindByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetFaqDto> {
        const response = await this.faqControllerFindByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
