import React from 'react';

import { Chip } from 'components/core/Chip/Chip';

import { type StatusChipProps } from '../StatusChipProps';

export function InDevelopmentStatusChip({
  iconSize = 'xs',
  textSize = 'base',
  paddingHorizontal = 'md',
}: StatusChipProps): JSX.Element {
  return (
    <Chip
      icon="status"
      iconColor="cyan"
      iconSize={iconSize}
      textSize={textSize}
      label={'En développement'}
      paddingTop="sm"
      paddingBottom="sm"
      paddingHorizontal={paddingHorizontal}
    />
  );
}
