/* tslint:disable */
/* eslint-disable */
/**
 * Specks API
 * APIs for specks SaaS
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Method } from './Method';
import {
    MethodFromJSON,
    MethodFromJSONTyped,
    MethodToJSON,
} from './Method';
import type { Resource } from './Resource';
import {
    ResourceFromJSON,
    ResourceFromJSONTyped,
    ResourceToJSON,
} from './Resource';

/**
 * 
 * @export
 * @interface ResourceRevision
 */
export interface ResourceRevision {
    /**
     * 
     * @type {string}
     * @memberof ResourceRevision
     */
    id: string;
    /**
     * 
     * @type {Date}
     * @memberof ResourceRevision
     */
    createdAt: Date;
    /**
     * 
     * @type {boolean}
     * @memberof ResourceRevision
     */
    latest: boolean;
    /**
     * 
     * @type {string}
     * @memberof ResourceRevision
     */
    name: string;
    /**
     * 
     * @type {Array<Method>}
     * @memberof ResourceRevision
     */
    methods: Array<Method>;
    /**
     * 
     * @type {Resource}
     * @memberof ResourceRevision
     */
    resource: Resource;
}

/**
 * Check if a given object implements the ResourceRevision interface.
 */
export function instanceOfResourceRevision(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "createdAt" in value;
    isInstance = isInstance && "latest" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "methods" in value;
    isInstance = isInstance && "resource" in value;

    return isInstance;
}

export function ResourceRevisionFromJSON(json: any): ResourceRevision {
    return ResourceRevisionFromJSONTyped(json, false);
}

export function ResourceRevisionFromJSONTyped(json: any, ignoreDiscriminator: boolean): ResourceRevision {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'createdAt': (new Date(json['createdAt'])),
        'latest': json['latest'],
        'name': json['name'],
        'methods': ((json['methods'] as Array<any>).map(MethodFromJSON)),
        'resource': ResourceFromJSON(json['resource']),
    };
}

export function ResourceRevisionToJSON(value?: ResourceRevision | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'createdAt': (value.createdAt.toISOString()),
        'latest': value.latest,
        'name': value.name,
        'methods': ((value.methods as Array<any>).map(MethodToJSON)),
        'resource': ResourceToJSON(value.resource),
    };
}

