import {
  type CreateDictionaryEntryDto,
  DictionariesApi,
  DictionaryEntriesApi,
  type GetDictionaryDto,
  type GetDictionaryEntryDto,
} from 'api';

import { Dictionary } from 'models/dictionary.entity';
import { type DictionaryEntry } from 'models/dictionaryEntry.entity';

export class DictionaryService {
  private readonly dictionaryClient: DictionariesApi;
  private readonly dictionaryEntriesClient: DictionaryEntriesApi;

  constructor() {
    this.dictionaryClient = new DictionariesApi();
    this.dictionaryEntriesClient = new DictionaryEntriesApi();
  }

  public async findById(id: string, accessToken: string): Promise<Dictionary> {
    const getDictionaryDto: GetDictionaryDto = await this.dictionaryClient.dictionaryControllerFindById(
      { id },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
      },
    );
    const dictionary: Dictionary = new Dictionary();
    dictionary.id = getDictionaryDto.id;
    dictionary.entries = getDictionaryDto.entries;
    return dictionary;
  }

  public async createEntry(id: string, entry: DictionaryEntry, accessToken: string): Promise<DictionaryEntry> {
    const getDictionaryEntryDto: GetDictionaryEntryDto = await this.dictionaryClient.dictionaryControllerCreateEntry(
      {
        id,
        createDictionaryEntryDto: entry as CreateDictionaryEntryDto,
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
      },
    );
    return getDictionaryEntryDto as DictionaryEntry;
  }

  public async deleteEntry(id: string, accessToken: string): Promise<void> {
    await this.dictionaryEntriesClient.dictionaryEntriesControllerDelete(
      { id },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
      },
    );
  }
}
