import React, { useEffect, useReducer, useState } from 'react';
import uuid from 'react-uuid';

import { ApiResponseForm } from 'forms/ApiResponseForm/ApiResponseForm';

import { Text } from 'components/core/Text/Text';
import { Title } from 'components/core/Title/Title';
import { type ResourceHandler, useResourceHandler } from 'components/specks/Widgets/ApiWidget';

import { type Body } from 'models/API/Body.entity';
import { type ApiResponse } from 'models/ApiResponse.entity';

import concatClassNames from 'utils/classNames';

import { ComponentBackEndApiDetailResponseRow } from './ResponseRow';
import { ComponentBackEndApiDetailSection } from './Section';

interface ComponentBackEndApiDetailResponsesProps {
  responses?: ApiResponse[];
  createNewResponse?: (responses: ApiResponse, update?: boolean) => void;
  updateResponseBody?: (responseId: string, responseBody: Body[]) => void;
  isEditable?: boolean;
}

export function ComponentBackEndApiDetailResponses({
  responses = [],
  createNewResponse,
  updateResponseBody,
  isEditable = false,
}: ComponentBackEndApiDetailResponsesProps): JSX.Element {
  /* --------------------------------------------------- contexts --------------------------------------------------- */

  const resourceHandler: ResourceHandler = useResourceHandler();

  /* ---------------------------------------------------- states ---------------------------------------------------- */

  const [bodyParametersPathIds, setBodyParametersPathIds] = useState(new Map<string, string[]>());
  const [openedResponses, setOpenedResponses] = useState(new Map<string, boolean>());
  // Force Update
  const [, forceUpdate] = useReducer((x: number) => x + 1, 0);

  /* ----------------------------------------------------- setup ---------------------------------------------------- */

  useEffect(() => {
    if (resourceHandler.details === undefined) return;
    if (resourceHandler.details.mutateStatus !== 'success') return;
    if (resourceHandler.details.newlyCreatedResponseRow === undefined) return;
    openedResponses.set(resourceHandler.details.newlyCreatedResponseRow, false);
    resourceHandler.details.closeAllFormsExcept(resourceHandler.details.newlyCreatedResponseRow);
    resourceHandler.details.setNewResponseBodyIdFormOpened(resourceHandler.details.newlyCreatedResponseRow);
  }, [resourceHandler.details, openedResponses, responses]);

  /* --------------------------------------------------- variables -------------------------------------------------- */

  const responsesByType: {
    SUCCESS: ApiResponse[];
    ERROR: ApiResponse[];
  } = {
    SUCCESS: [],
    ERROR: [],
  };

  responses.forEach((response: ApiResponse) => {
    responsesByType[response.responseType.toUpperCase() as keyof typeof responsesByType].push(response);
  });

  const isSuccessResponseNewFormOpened: boolean = resourceHandler.details?.isSuccessResponseNewFormOpened ?? false;
  const successResponseUpdateFormOpened: string | undefined =
    resourceHandler.details?.successResponseIdUpdateFormOpened;
  const isErrorResponseNewFormOpened: boolean = resourceHandler.details?.isErrorResponseNewFormOpened ?? false;
  const errorResponseUpdateFormOpened: string | undefined = resourceHandler.details?.errorResponseIdUpdateFormOpened;

  /* ------------------------------------------------- Move to forms ------------------------------------------------ */

  useEffect(() => {
    if (isSuccessResponseNewFormOpened || isErrorResponseNewFormOpened) {
      const formElement: HTMLElement | null = document.getElementById('api-response-form');
      if (formElement != null) formElement.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
    }
  }, [isSuccessResponseNewFormOpened, isErrorResponseNewFormOpened]);

  useEffect(() => {
    if (resourceHandler.details?.newResponseBodyIdFormOpened === undefined) return;
    const formElement: HTMLElement | null = document.getElementById('api-body-form');
    if (formElement != null) formElement.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
  }, [resourceHandler]);

  /* --------------------------------------------------- functions -------------------------------------------------- */

  function handleOnClickAddSuccessResponse(): void {
    resourceHandler.details.closeAllFormsExcept();
    resourceHandler.details.setIsSuccessResponseNewFormOpened(true);
  }

  function handleOnClickAddErrorResponse(): void {
    resourceHandler.details.closeAllFormsExcept();
    resourceHandler.details.setIsErrorResponseNewFormOpened(true);
  }

  function handleOnClickConfirmNewResponse(newApiResponse: ApiResponse): void {
    newApiResponse.responseType = isSuccessResponseNewFormOpened ? 'SUCCESS' : 'ERROR';
    createNewResponse?.(newApiResponse);
    resourceHandler.details.errorMutateMessage = 'Une erreur est survenue lors de la création de la réponse.';
    resourceHandler.details.successMutateMessage = 'La réponse a bien été créée.';
  }

  function _handleOnClickConfirmUpdateResponse(updatedApiResponse: ApiResponse): void {
    createNewResponse?.(updatedApiResponse, true);
    resourceHandler.details.errorMutateMessage = 'Une erreur est survenue lors de la mise à jour de la réponse.';
    resourceHandler.details.successMutateMessage = 'La réponse a bien été mise à jour.';
  }

  function handleOnClickConfirmUpdateSucessResponse(updateApiResponse: ApiResponse): void {
    updateApiResponse.responseType = 'SUCCESS';
    _handleOnClickConfirmUpdateResponse(updateApiResponse);
  }

  function handleOnClickConfirmUpdateErrorResponse(updateApiResponse: ApiResponse): void {
    updateApiResponse.responseType = 'ERROR';
    _handleOnClickConfirmUpdateResponse(updateApiResponse);
  }

  function handleOnClickCancelNewResponse(): void {
    resourceHandler.details.setIsErrorResponseNewFormOpened(false);
    resourceHandler.details.setIsSuccessResponseNewFormOpened(false);
  }

  function handleOnClickCancelUpdateResponse(): void {
    resourceHandler.details.setSuccessResponseIdUpdateFormOpened(undefined);
    resourceHandler.details.setErrorResponseIdUpdateFormOpened(undefined);
  }

  /* ------------------------------------------------------ JSX ----------------------------------------------------- */

  return (
    <div className={concatClassNames('flex flex-col', 'w-full', 'gap-1')}>
      <Title content="Réponses" size="h2" />
      {/* Success */}
      <ComponentBackEndApiDetailSection
        resourceId=""
        title="Succès"
        titleColor="green-500"
        onClickAddInSection={isEditable ? handleOnClickAddSuccessResponse : null}
      >
        <div className={concatClassNames('flex flex-col', 'gap-1', 'w-full')}>
          {responsesByType.SUCCESS.length === 0 && !isSuccessResponseNewFormOpened ? (
            <div className={concatClassNames('flex flex-row', 'gap-6')}>
              <Text
                size="api"
                weight="light"
                color="gray-200"
                content="Il n'y a pas de réponse de type succès pour cette méthode."
                position="center"
              />
              {isEditable && (
                <div onClick={handleOnClickAddSuccessResponse} className="cursor-pointer">
                  <Text
                    size="api"
                    content="En ajouter une !"
                    color="purple-500"
                    weight="light"
                    textDecoration="underline"
                    position="center"
                  />
                </div>
              )}
            </div>
          ) : (
            <ComponentBackEndApiDetailResponseRow title />
          )}
          {responsesByType.SUCCESS.length > 0 && (
            <div className={concatClassNames('flex flex-col', 'gap-1', 'w-full')}>
              {responsesByType.SUCCESS.map((response: ApiResponse) => {
                if (
                  successResponseUpdateFormOpened !== undefined &&
                  successResponseUpdateFormOpened === response.fonctionalId
                ) {
                  return (
                    <ApiResponseForm
                      key={uuid()}
                      onSubmit={handleOnClickConfirmUpdateSucessResponse}
                      onCancel={handleOnClickCancelUpdateResponse}
                      bgColor="success"
                      oldApiResponse={resourceHandler.details.apiResponseData ?? response}
                    />
                  );
                }
                return (
                  <ComponentBackEndApiDetailResponseRow
                    key={uuid()}
                    id={response.fonctionalId}
                    statusCode={response.statusCode}
                    message={response.message}
                    apiResponseBody={response.apiResponseBody}
                    bodyResponsesPathIds={bodyParametersPathIds.get(response.fonctionalId) ?? []}
                    setBodyResponsesPathIds={(pathIds) => {
                      bodyParametersPathIds.set(response.fonctionalId, pathIds);
                      setBodyParametersPathIds(bodyParametersPathIds);
                      forceUpdate();
                    }}
                    isCollapsed={openedResponses.get(response.fonctionalId) ?? true}
                    setIsCollapsed={(isCollapsed) => {
                      openedResponses.set(response.fonctionalId, isCollapsed);
                      setOpenedResponses(openedResponses);
                      forceUpdate();
                    }}
                    isEditable={isEditable}
                    createNewBody={(body: Body[], update: boolean = false) => {
                      updateResponseBody?.(response.id, body);
                    }}
                    bgColor="success"
                    onRowUpdate={(id: string) => {
                      resourceHandler.details.closeAllFormsExcept(id);
                      resourceHandler.details.setSuccessResponseIdUpdateFormOpened(id);
                    }}
                  />
                );
              })}
            </div>
          )}
          {isSuccessResponseNewFormOpened && (
            <ApiResponseForm
              onSubmit={handleOnClickConfirmNewResponse}
              onCancel={handleOnClickCancelNewResponse}
              bgColor="success"
            />
          )}
        </div>
      </ComponentBackEndApiDetailSection>
      {/* Error */}
      <ComponentBackEndApiDetailSection
        resourceId=""
        title="Erreur"
        titleColor="red-500"
        onClickAddInSection={isEditable ? handleOnClickAddErrorResponse : null}
      >
        <div className={concatClassNames('flex flex-col', 'gap-1', 'w-full')}>
          {responsesByType.ERROR.length === 0 && !isErrorResponseNewFormOpened ? (
            <div className={concatClassNames('flex flex-row', 'gap-6')}>
              <Text
                size="api"
                weight="light"
                color="gray-200"
                content="Il n'y a pas de réponse de type erreur pour cette méthode."
                position="center"
              />
              {isEditable && (
                <div onClick={handleOnClickAddErrorResponse} className="cursor-pointer">
                  <Text
                    size="api"
                    content="En ajouter une !"
                    color="purple-500"
                    weight="light"
                    textDecoration="underline"
                    position="center"
                  />
                </div>
              )}
            </div>
          ) : (
            <ComponentBackEndApiDetailResponseRow title />
          )}
          {responsesByType.ERROR.length > 0 && (
            <div className={concatClassNames('flex flex-col', 'gap-1', 'w-full')}>
              {responsesByType.ERROR.map((response: ApiResponse) => {
                if (
                  errorResponseUpdateFormOpened !== undefined &&
                  errorResponseUpdateFormOpened === response.fonctionalId
                )
                  return (
                    <ApiResponseForm
                      key={uuid()}
                      onSubmit={handleOnClickConfirmUpdateErrorResponse}
                      onCancel={handleOnClickCancelUpdateResponse}
                      bgColor="error"
                      oldApiResponse={response}
                    />
                  );
                return (
                  <ComponentBackEndApiDetailResponseRow
                    key={uuid()}
                    id={response.fonctionalId}
                    statusCode={response.statusCode}
                    message={response.message}
                    apiResponseBody={response.apiResponseBody}
                    bodyResponsesPathIds={bodyParametersPathIds.get(response.fonctionalId) ?? []}
                    setBodyResponsesPathIds={(pathIds) => {
                      bodyParametersPathIds.set(response.fonctionalId, pathIds);
                      setBodyParametersPathIds(bodyParametersPathIds);
                    }}
                    isCollapsed={openedResponses.get(response.fonctionalId) ?? true}
                    setIsCollapsed={(isCollapsed) => {
                      openedResponses.set(response.fonctionalId, isCollapsed);
                      setOpenedResponses(openedResponses);
                      forceUpdate();
                    }}
                    isEditable={isEditable}
                    createNewBody={(body: Body[], update: boolean = false) => {
                      updateResponseBody?.(response.id, body);
                    }}
                    bgColor="error"
                    onRowUpdate={(id: string) => {
                      resourceHandler.details.closeAllFormsExcept(id);
                      resourceHandler.details.setErrorResponseIdUpdateFormOpened(id);
                    }}
                  />
                );
              })}
            </div>
          )}
          {isErrorResponseNewFormOpened && (
            <ApiResponseForm
              onSubmit={handleOnClickConfirmNewResponse}
              onCancel={handleOnClickCancelNewResponse}
              bgColor="error"
            />
          )}
        </div>
      </ComponentBackEndApiDetailSection>
    </div>
  );
}
