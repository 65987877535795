/* tslint:disable */
/* eslint-disable */
/**
 * Specks API
 * APIs for specks SaaS
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Asset } from './Asset';
import {
    AssetFromJSON,
    AssetFromJSONTyped,
    AssetToJSON,
} from './Asset';
import type { Product } from './Product';
import {
    ProductFromJSON,
    ProductFromJSONTyped,
    ProductToJSON,
} from './Product';

/**
 * 
 * @export
 * @interface Feature
 */
export interface Feature {
    /**
     * 
     * @type {string}
     * @memberof Feature
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof Feature
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof Feature
     */
    status: FeatureStatusEnum;
    /**
     * 
     * @type {Date}
     * @memberof Feature
     */
    createdAt: Date;
    /**
     * 
     * @type {Array<Asset>}
     * @memberof Feature
     */
    assets: Array<Asset>;
    /**
     * 
     * @type {Product}
     * @memberof Feature
     */
    product: Product;
}


/**
 * @export
 */
export const FeatureStatusEnum = {
    Production: 'production',
    InExploration: 'inExploration',
    Planed: 'planed',
    InDevelopment: 'inDevelopment',
    InDecommissionning: 'inDecommissionning',
    Decommissioned: 'decommissioned',
    Evolving: 'evolving'
} as const;
export type FeatureStatusEnum = typeof FeatureStatusEnum[keyof typeof FeatureStatusEnum];


/**
 * Check if a given object implements the Feature interface.
 */
export function instanceOfFeature(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "status" in value;
    isInstance = isInstance && "createdAt" in value;
    isInstance = isInstance && "assets" in value;
    isInstance = isInstance && "product" in value;

    return isInstance;
}

export function FeatureFromJSON(json: any): Feature {
    return FeatureFromJSONTyped(json, false);
}

export function FeatureFromJSONTyped(json: any, ignoreDiscriminator: boolean): Feature {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'status': json['status'],
        'createdAt': (new Date(json['createdAt'])),
        'assets': ((json['assets'] as Array<any>).map(AssetFromJSON)),
        'product': ProductFromJSON(json['product']),
    };
}

export function FeatureToJSON(value?: Feature | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'status': value.status,
        'createdAt': (value.createdAt.toISOString()),
        'assets': ((value.assets as Array<any>).map(AssetToJSON)),
        'product': ProductToJSON(value.product),
    };
}

