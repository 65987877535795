/* tslint:disable */
/* eslint-disable */
/**
 * Specks API
 * APIs for specks SaaS
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { GetOptionDto } from './GetOptionDto';
import {
    GetOptionDtoFromJSON,
    GetOptionDtoFromJSONTyped,
    GetOptionDtoToJSON,
} from './GetOptionDto';

/**
 * 
 * @export
 * @interface GetUiComponentRevisionDto
 */
export interface GetUiComponentRevisionDto {
    /**
     * 
     * @type {string}
     * @memberof GetUiComponentRevisionDto
     */
    id: string;
    /**
     * 
     * @type {boolean}
     * @memberof GetUiComponentRevisionDto
     */
    isFormValid: boolean;
    /**
     * 
     * @type {string}
     * @memberof GetUiComponentRevisionDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof GetUiComponentRevisionDto
     */
    notes: string;
    /**
     * A quoi sert le composant
     * @type {string}
     * @memberof GetUiComponentRevisionDto
     */
    usedFor: string;
    /**
     * 
     * @type {Date}
     * @memberof GetUiComponentRevisionDto
     */
    createdAt: Date;
    /**
     * 
     * @type {boolean}
     * @memberof GetUiComponentRevisionDto
     */
    latest: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetUiComponentRevisionDto
     */
    bearerLinks: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof GetUiComponentRevisionDto
     */
    contentType: GetUiComponentRevisionDtoContentTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof GetUiComponentRevisionDto
     */
    optionsSource: GetUiComponentRevisionDtoOptionsSourceEnum;
    /**
     * 
     * @type {Array<GetOptionDto>}
     * @memberof GetUiComponentRevisionDto
     */
    options: Array<GetOptionDto>;
    /**
     * 
     * @type {string}
     * @memberof GetUiComponentRevisionDto
     */
    sourceMethodId: string;
    /**
     * 
     * @type {string}
     * @memberof GetUiComponentRevisionDto
     */
    sourceConstant: string;
    /**
     * 
     * @type {string}
     * @memberof GetUiComponentRevisionDto
     */
    sourceLogicalRule: string;
    /**
     * 
     * @type {string}
     * @memberof GetUiComponentRevisionDto
     */
    targetType: GetUiComponentRevisionDtoTargetTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof GetUiComponentRevisionDto
     */
    targetMethodId: string;
    /**
     * 
     * @type {string}
     * @memberof GetUiComponentRevisionDto
     */
    targetFree: string;
    /**
     * 
     * @type {string}
     * @memberof GetUiComponentRevisionDto
     */
    isVisible: GetUiComponentRevisionDtoIsVisibleEnum;
    /**
     * 
     * @type {string}
     * @memberof GetUiComponentRevisionDto
     */
    isVisibleLogicalRule: string;
    /**
     * 
     * @type {string}
     * @memberof GetUiComponentRevisionDto
     */
    isEditable: GetUiComponentRevisionDtoIsEditableEnum;
    /**
     * 
     * @type {string}
     * @memberof GetUiComponentRevisionDto
     */
    isEditableLogicalRule: string;
    /**
     * 
     * @type {string}
     * @memberof GetUiComponentRevisionDto
     */
    isPreFilled: GetUiComponentRevisionDtoIsPreFilledEnum;
    /**
     * 
     * @type {string}
     * @memberof GetUiComponentRevisionDto
     */
    isPreFilledConstant: string;
    /**
     * 
     * @type {string}
     * @memberof GetUiComponentRevisionDto
     */
    isPreFilledLogicalRule: string;
    /**
     * 
     * @type {string}
     * @memberof GetUiComponentRevisionDto
     */
    hasPlaceholder: GetUiComponentRevisionDtoHasPlaceholderEnum;
    /**
     * 
     * @type {string}
     * @memberof GetUiComponentRevisionDto
     */
    hasPlaceholderConstant: string;
    /**
     * 
     * @type {string}
     * @memberof GetUiComponentRevisionDto
     */
    hasPlaceholderLogicalRule: string;
    /**
     * 
     * @type {string}
     * @memberof GetUiComponentRevisionDto
     */
    isMandatory: GetUiComponentRevisionDtoIsMandatoryEnum;
    /**
     * 
     * @type {string}
     * @memberof GetUiComponentRevisionDto
     */
    isMandatoryLogicalRule: string;
    /**
     * 
     * @type {string}
     * @memberof GetUiComponentRevisionDto
     */
    hasPattern: GetUiComponentRevisionDtoHasPatternEnum;
    /**
     * 
     * @type {string}
     * @memberof GetUiComponentRevisionDto
     */
    hasPatternConstant: string;
    /**
     * 
     * @type {string}
     * @memberof GetUiComponentRevisionDto
     */
    hasPatternLogicalRule: string;
    /**
     * 
     * @type {string}
     * @memberof GetUiComponentRevisionDto
     */
    label: string;
    /**
     * 
     * @type {string}
     * @memberof GetUiComponentRevisionDto
     */
    isMultiChoiceAllowed: GetUiComponentRevisionDtoIsMultiChoiceAllowedEnum;
    /**
     * 
     * @type {string}
     * @memberof GetUiComponentRevisionDto
     */
    isMultiChoiceAllowedLogicalRule: string;
    /**
     * 
     * @type {string}
     * @memberof GetUiComponentRevisionDto
     */
    dateFormat: GetUiComponentRevisionDtoDateFormatEnum;
    /**
     * 
     * @type {string}
     * @memberof GetUiComponentRevisionDto
     */
    dateCustom: string;
    /**
     * La position du composant sur sa ligne
     * @type {number}
     * @memberof GetUiComponentRevisionDto
     */
    index: number;
    /**
     * 
     * @type {string}
     * @memberof GetUiComponentRevisionDto
     */
    parent?: string;
    /**
     * L'ensemble des composants que contient ce composant
     * @type {number}
     * @memberof GetUiComponentRevisionDto
     */
    components: number;
    /**
     * 
     * @type {string}
     * @memberof GetUiComponentRevisionDto
     */
    titleImportancy: GetUiComponentRevisionDtoTitleImportancyEnum;
    /**
     * 
     * @type {string}
     * @memberof GetUiComponentRevisionDto
     */
    textImportancy: GetUiComponentRevisionDtoTextImportancyEnum;
}


/**
 * @export
 */
export const GetUiComponentRevisionDtoContentTypeEnum = {
    Constant: 'CONSTANT',
    Logical: 'LOGICAL'
} as const;
export type GetUiComponentRevisionDtoContentTypeEnum = typeof GetUiComponentRevisionDtoContentTypeEnum[keyof typeof GetUiComponentRevisionDtoContentTypeEnum];

/**
 * @export
 */
export const GetUiComponentRevisionDtoOptionsSourceEnum = {
    Method: 'METHOD',
    Free: 'FREE'
} as const;
export type GetUiComponentRevisionDtoOptionsSourceEnum = typeof GetUiComponentRevisionDtoOptionsSourceEnum[keyof typeof GetUiComponentRevisionDtoOptionsSourceEnum];

/**
 * @export
 */
export const GetUiComponentRevisionDtoTargetTypeEnum = {
    Free: 'FREE',
    Method: 'METHOD',
    Nothing: 'NOTHING'
} as const;
export type GetUiComponentRevisionDtoTargetTypeEnum = typeof GetUiComponentRevisionDtoTargetTypeEnum[keyof typeof GetUiComponentRevisionDtoTargetTypeEnum];

/**
 * @export
 */
export const GetUiComponentRevisionDtoIsVisibleEnum = {
    Yes: 'YES',
    Logical: 'LOGICAL'
} as const;
export type GetUiComponentRevisionDtoIsVisibleEnum = typeof GetUiComponentRevisionDtoIsVisibleEnum[keyof typeof GetUiComponentRevisionDtoIsVisibleEnum];

/**
 * @export
 */
export const GetUiComponentRevisionDtoIsEditableEnum = {
    Yes: 'YES',
    No: 'NO',
    Logical: 'LOGICAL'
} as const;
export type GetUiComponentRevisionDtoIsEditableEnum = typeof GetUiComponentRevisionDtoIsEditableEnum[keyof typeof GetUiComponentRevisionDtoIsEditableEnum];

/**
 * @export
 */
export const GetUiComponentRevisionDtoIsPreFilledEnum = {
    Yes: 'YES',
    No: 'NO',
    Logical: 'LOGICAL'
} as const;
export type GetUiComponentRevisionDtoIsPreFilledEnum = typeof GetUiComponentRevisionDtoIsPreFilledEnum[keyof typeof GetUiComponentRevisionDtoIsPreFilledEnum];

/**
 * @export
 */
export const GetUiComponentRevisionDtoHasPlaceholderEnum = {
    Yes: 'YES',
    No: 'NO',
    Logical: 'LOGICAL'
} as const;
export type GetUiComponentRevisionDtoHasPlaceholderEnum = typeof GetUiComponentRevisionDtoHasPlaceholderEnum[keyof typeof GetUiComponentRevisionDtoHasPlaceholderEnum];

/**
 * @export
 */
export const GetUiComponentRevisionDtoIsMandatoryEnum = {
    Yes: 'YES',
    No: 'NO',
    Logical: 'LOGICAL'
} as const;
export type GetUiComponentRevisionDtoIsMandatoryEnum = typeof GetUiComponentRevisionDtoIsMandatoryEnum[keyof typeof GetUiComponentRevisionDtoIsMandatoryEnum];

/**
 * @export
 */
export const GetUiComponentRevisionDtoHasPatternEnum = {
    Yes: 'YES',
    No: 'NO',
    Logical: 'LOGICAL'
} as const;
export type GetUiComponentRevisionDtoHasPatternEnum = typeof GetUiComponentRevisionDtoHasPatternEnum[keyof typeof GetUiComponentRevisionDtoHasPatternEnum];

/**
 * @export
 */
export const GetUiComponentRevisionDtoIsMultiChoiceAllowedEnum = {
    Yes: 'YES',
    No: 'NO',
    Logical: 'LOGICAL'
} as const;
export type GetUiComponentRevisionDtoIsMultiChoiceAllowedEnum = typeof GetUiComponentRevisionDtoIsMultiChoiceAllowedEnum[keyof typeof GetUiComponentRevisionDtoIsMultiChoiceAllowedEnum];

/**
 * @export
 */
export const GetUiComponentRevisionDtoDateFormatEnum = {
    Empty: 'EMPTY',
    DmyWithYear: 'DMY_WITH_YEAR',
    DmyWithDateTime: 'DMY_WITH_DATE_TIME',
    Dm: 'DM',
    Md: 'MD',
    Mmm: 'MMM',
    Mmmm: 'MMMM',
    Custom: 'CUSTOM'
} as const;
export type GetUiComponentRevisionDtoDateFormatEnum = typeof GetUiComponentRevisionDtoDateFormatEnum[keyof typeof GetUiComponentRevisionDtoDateFormatEnum];

/**
 * @export
 */
export const GetUiComponentRevisionDtoTitleImportancyEnum = {
    Undefined: 'UNDEFINED',
    H1: 'H1',
    H2: 'H2',
    H3: 'H3',
    H4: 'H4'
} as const;
export type GetUiComponentRevisionDtoTitleImportancyEnum = typeof GetUiComponentRevisionDtoTitleImportancyEnum[keyof typeof GetUiComponentRevisionDtoTitleImportancyEnum];

/**
 * @export
 */
export const GetUiComponentRevisionDtoTextImportancyEnum = {
    Undefined: 'UNDEFINED',
    VeryImportant: 'VERY_IMPORTANT',
    Important: 'IMPORTANT',
    LessImportant: 'LESS_IMPORTANT'
} as const;
export type GetUiComponentRevisionDtoTextImportancyEnum = typeof GetUiComponentRevisionDtoTextImportancyEnum[keyof typeof GetUiComponentRevisionDtoTextImportancyEnum];


/**
 * Check if a given object implements the GetUiComponentRevisionDto interface.
 */
export function instanceOfGetUiComponentRevisionDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "isFormValid" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "notes" in value;
    isInstance = isInstance && "usedFor" in value;
    isInstance = isInstance && "createdAt" in value;
    isInstance = isInstance && "latest" in value;
    isInstance = isInstance && "bearerLinks" in value;
    isInstance = isInstance && "contentType" in value;
    isInstance = isInstance && "optionsSource" in value;
    isInstance = isInstance && "options" in value;
    isInstance = isInstance && "sourceMethodId" in value;
    isInstance = isInstance && "sourceConstant" in value;
    isInstance = isInstance && "sourceLogicalRule" in value;
    isInstance = isInstance && "targetType" in value;
    isInstance = isInstance && "targetMethodId" in value;
    isInstance = isInstance && "targetFree" in value;
    isInstance = isInstance && "isVisible" in value;
    isInstance = isInstance && "isVisibleLogicalRule" in value;
    isInstance = isInstance && "isEditable" in value;
    isInstance = isInstance && "isEditableLogicalRule" in value;
    isInstance = isInstance && "isPreFilled" in value;
    isInstance = isInstance && "isPreFilledConstant" in value;
    isInstance = isInstance && "isPreFilledLogicalRule" in value;
    isInstance = isInstance && "hasPlaceholder" in value;
    isInstance = isInstance && "hasPlaceholderConstant" in value;
    isInstance = isInstance && "hasPlaceholderLogicalRule" in value;
    isInstance = isInstance && "isMandatory" in value;
    isInstance = isInstance && "isMandatoryLogicalRule" in value;
    isInstance = isInstance && "hasPattern" in value;
    isInstance = isInstance && "hasPatternConstant" in value;
    isInstance = isInstance && "hasPatternLogicalRule" in value;
    isInstance = isInstance && "label" in value;
    isInstance = isInstance && "isMultiChoiceAllowed" in value;
    isInstance = isInstance && "isMultiChoiceAllowedLogicalRule" in value;
    isInstance = isInstance && "dateFormat" in value;
    isInstance = isInstance && "dateCustom" in value;
    isInstance = isInstance && "index" in value;
    isInstance = isInstance && "components" in value;
    isInstance = isInstance && "titleImportancy" in value;
    isInstance = isInstance && "textImportancy" in value;

    return isInstance;
}

export function GetUiComponentRevisionDtoFromJSON(json: any): GetUiComponentRevisionDto {
    return GetUiComponentRevisionDtoFromJSONTyped(json, false);
}

export function GetUiComponentRevisionDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetUiComponentRevisionDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'isFormValid': json['isFormValid'],
        'name': json['name'],
        'notes': json['notes'],
        'usedFor': json['usedFor'],
        'createdAt': (new Date(json['createdAt'])),
        'latest': json['latest'],
        'bearerLinks': json['bearerLinks'],
        'contentType': json['contentType'],
        'optionsSource': json['optionsSource'],
        'options': ((json['options'] as Array<any>).map(GetOptionDtoFromJSON)),
        'sourceMethodId': json['sourceMethodId'],
        'sourceConstant': json['sourceConstant'],
        'sourceLogicalRule': json['sourceLogicalRule'],
        'targetType': json['targetType'],
        'targetMethodId': json['targetMethodId'],
        'targetFree': json['targetFree'],
        'isVisible': json['isVisible'],
        'isVisibleLogicalRule': json['isVisibleLogicalRule'],
        'isEditable': json['isEditable'],
        'isEditableLogicalRule': json['isEditableLogicalRule'],
        'isPreFilled': json['isPreFilled'],
        'isPreFilledConstant': json['isPreFilledConstant'],
        'isPreFilledLogicalRule': json['isPreFilledLogicalRule'],
        'hasPlaceholder': json['hasPlaceholder'],
        'hasPlaceholderConstant': json['hasPlaceholderConstant'],
        'hasPlaceholderLogicalRule': json['hasPlaceholderLogicalRule'],
        'isMandatory': json['isMandatory'],
        'isMandatoryLogicalRule': json['isMandatoryLogicalRule'],
        'hasPattern': json['hasPattern'],
        'hasPatternConstant': json['hasPatternConstant'],
        'hasPatternLogicalRule': json['hasPatternLogicalRule'],
        'label': json['label'],
        'isMultiChoiceAllowed': json['isMultiChoiceAllowed'],
        'isMultiChoiceAllowedLogicalRule': json['isMultiChoiceAllowedLogicalRule'],
        'dateFormat': json['dateFormat'],
        'dateCustom': json['dateCustom'],
        'index': json['index'],
        'parent': !exists(json, 'parent') ? undefined : json['parent'],
        'components': json['components'],
        'titleImportancy': json['titleImportancy'],
        'textImportancy': json['textImportancy'],
    };
}

export function GetUiComponentRevisionDtoToJSON(value?: GetUiComponentRevisionDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'isFormValid': value.isFormValid,
        'name': value.name,
        'notes': value.notes,
        'usedFor': value.usedFor,
        'createdAt': (value.createdAt.toISOString()),
        'latest': value.latest,
        'bearerLinks': value.bearerLinks,
        'contentType': value.contentType,
        'optionsSource': value.optionsSource,
        'options': ((value.options as Array<any>).map(GetOptionDtoToJSON)),
        'sourceMethodId': value.sourceMethodId,
        'sourceConstant': value.sourceConstant,
        'sourceLogicalRule': value.sourceLogicalRule,
        'targetType': value.targetType,
        'targetMethodId': value.targetMethodId,
        'targetFree': value.targetFree,
        'isVisible': value.isVisible,
        'isVisibleLogicalRule': value.isVisibleLogicalRule,
        'isEditable': value.isEditable,
        'isEditableLogicalRule': value.isEditableLogicalRule,
        'isPreFilled': value.isPreFilled,
        'isPreFilledConstant': value.isPreFilledConstant,
        'isPreFilledLogicalRule': value.isPreFilledLogicalRule,
        'hasPlaceholder': value.hasPlaceholder,
        'hasPlaceholderConstant': value.hasPlaceholderConstant,
        'hasPlaceholderLogicalRule': value.hasPlaceholderLogicalRule,
        'isMandatory': value.isMandatory,
        'isMandatoryLogicalRule': value.isMandatoryLogicalRule,
        'hasPattern': value.hasPattern,
        'hasPatternConstant': value.hasPatternConstant,
        'hasPatternLogicalRule': value.hasPatternLogicalRule,
        'label': value.label,
        'isMultiChoiceAllowed': value.isMultiChoiceAllowed,
        'isMultiChoiceAllowedLogicalRule': value.isMultiChoiceAllowedLogicalRule,
        'dateFormat': value.dateFormat,
        'dateCustom': value.dateCustom,
        'index': value.index,
        'parent': value.parent,
        'components': value.components,
        'titleImportancy': value.titleImportancy,
        'textImportancy': value.textImportancy,
    };
}

