import React from 'react';

import { Text } from 'components/core/Text/Text';

import concatClassNames from 'utils/classNames';
import getIcon from 'utils/getIcon';
import { tooltipDescriptionsStyle } from 'utils/tooltipsDescriptions';

import { type IconName, type IconSize } from 'icons/Icon.props';

import { Thumbnail } from '../Thumbnail/Thumbnail';

type EmptyWidth = 'fit' | 'full' | '2/5' | '2/6';
const emptyWidthStyle: Record<EmptyWidth, string> = {
  fit: 'w-fit',
  full: 'w-full',
  '2/5': 'w-2/5',
  '2/6': 'w-2/6',
};

type EmptyHeight = 'fit' | 'full';
const emptyHeightStyle: Record<EmptyHeight, string> = {
  fit: 'h-fit',
  full: 'h-full',
};

type EmptyHorizontalPadding = 'none' | '1' | '8';
const emptyHorizontalPaddingStyle: Record<EmptyHorizontalPadding, string> = {
  none: 'px-0',
  '1': 'px-1',
  '8': 'px-8',
};

type EmptyVerticalPadding = 'none' | '12';
const emptyVerticalPaddingStyle: Record<EmptyVerticalPadding, string> = {
  none: 'py-0',
  '12': 'py-12',
};

type EmptyBackgroundColor = 'transparent' | 'gray-15';
const emptyBackgroundColorStyle: Record<EmptyBackgroundColor, string> = {
  transparent: 'bg-transparent',
  'gray-15': 'bg-gray-15',
};

export interface EmptyProps {
  title: string;
  subtitle?: string;
  icon: IconName;
  iconSize?: IconSize;
  width?: EmptyWidth;
  height?: EmptyHeight;
  horizontalPadding?: EmptyHorizontalPadding;
  verticalPadding?: EmptyVerticalPadding;
  backgroundColor?: EmptyBackgroundColor;
  children?: JSX.Element | JSX.Element[];
}

export function Empty({
  title,
  subtitle,
  icon,
  iconSize = 'xxxl',
  width = 'full',
  height = 'full',
  horizontalPadding = '1',
  verticalPadding = '12',
  backgroundColor = 'gray-15',
  children,
}: EmptyProps): JSX.Element {
  return (
    <div
      className={concatClassNames(
        'flex flex-col justify-center items-center',
        'gap-3',
        emptyWidthStyle[width],
        emptyHeightStyle[height],
        'rounded-2xl',
        emptyHorizontalPaddingStyle[horizontalPadding],
        emptyVerticalPaddingStyle[verticalPadding],
        emptyBackgroundColorStyle[backgroundColor],
      )}
    >
      <div
        className={concatClassNames(
          'flex flex-col justify-center items-center',
          'p-3 gap-3',
          'w-fit h-fit',
          'bg-gray-25',
          'rounded-[46px]',
        )}
      >
        {icon === 'product' && (
          <Thumbnail thumbnailIcon="product" thumbnailTooltipMessage={tooltipDescriptionsStyle.product}></Thumbnail>
        )}
        {icon === 'component' && (
          <Thumbnail thumbnailIcon="component" thumbnailTooltipMessage={tooltipDescriptionsStyle.component}></Thumbnail>
        )}
        {icon !== 'product' && icon !== 'component' && getIcon(icon, 'gray', iconSize)}
      </div>
      <Text content={title} size="base" weight="bold" color="black" position="center" />
      {subtitle !== undefined && (
        <Text content={subtitle} size="sm" weight="bold" color="gray-200" whitespace="pre-wrap" position="center" />
      )}
      {children}
    </div>
  );
}
