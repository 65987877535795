import { SpecksApisContext } from 'App';

import React, { useContext, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { type NavigateFunction, useNavigate } from 'react-router-dom';
import uuid from 'react-uuid';

import { useAuth0 } from '@auth0/auth0-react';
import { Tooltip } from '@mui/material';

import { ResourceFront } from 'pages/Component/BackEndApi/Api/Resource.front';

import { ApiCollapse } from 'components/specks/Api/ApiCollapse/ApiCollapse';
import { ApiMethodsWrapper } from 'components/specks/Api/Method/ApiMethodsWrapper';
import { ComponentSmallCard } from 'components/specks/ComponentSmallCard/ComponentSmallCard';
import { Empty } from 'components/specks/Empty/Empty';
import { NetworkError } from 'components/specks/Error/Error';

import { type BackendApiSpecificationService } from 'services/apiSpecification.service';
import { type ProductService } from 'services/product.service';

import { type Component } from 'models/component.entity';
import { type Method } from 'models/method.entity';
import { type Product } from 'models/product.entity';
import { type Resource } from 'models/resource.entity';

import concatClassNames from 'utils/classNames';
import getIcon from 'utils/getIcon';

import { BackButton } from '../BackButton/BackButton';
import { Button } from '../Button/Button';
import SideMenu from '../SideMenu/SideMenu';
import { Title } from '../Title/Title';

interface SelectMethodMenuProps {
  componentId: string;
  onClickBackButton: () => void;
  setSelectedMethod: (methodId: string | undefined) => void;
  validateSelectedMethod: () => void;
}

export function SelectMethodMenu({
  componentId,
  onClickBackButton,
  setSelectedMethod,
  validateSelectedMethod,
}: SelectMethodMenuProps): JSX.Element {
  /* --------------------------------------------------- contexts --------------------------------------------------- */
  const { productService, componentService } = useContext(SpecksApisContext);
  const BackApiSpecificationService: BackendApiSpecificationService =
    useContext(SpecksApisContext).backApiSpecificationService;
  const { getAccessTokenSilently } = useAuth0();
  const navigate: NavigateFunction = useNavigate();

  /* ---------------------------------------------------- states ---------------------------------------------------- */

  const [selectedComponent, setSelectedComponent] = useState<Component | undefined>(undefined);
  const [resources, setResources] = useState<ResourceFront[]>([]);
  const [selectedMethodId, setSelectedMethodId] = useState<string>();

  /* ---------------------------------------------------- queries --------------------------------------------------- */

  async function getBackComponents(): Promise<Component[]> {
    const accessToken: string = await getAccessTokenSilently();
    return productService.findAppSchemaLinkedComponents(componentId, accessToken);
  }

  const { data: backComponents, status: backComponentStatus } = useQuery<Component[], Error>(
    ['backComponents'],
    getBackComponents,
  );

  const BackApiSpecificationId: string | undefined = selectedComponent?.BackApiSpecification?.id;
  const { data: resourcesData, status: resourcesStatus } = useQuery<Resource[], Error>({
    queryKey: ['resources', BackApiSpecificationId],
    queryFn: async ({ queryKey }) => {
      const [, BackApiSpecificationId] = queryKey;
      const accessToken: string = await getAccessTokenSilently();
      return await BackApiSpecificationService.getResources(BackApiSpecificationId as string, accessToken);
    },
    enabled: BackApiSpecificationId !== undefined,
  });

  useEffect(() => {
    if (resourcesStatus === 'success') {
      const resourcesFront: ResourceFront[] = resourcesData?.map(
        (resource: Resource) => new ResourceFront(resource, false),
      );
      setResources(resourcesFront);
    }
  }, [resourcesData, resourcesStatus]);

  const { data: relatedProducts, status: relatedProductsStatus } = useQuery<Product[], Error>({
    queryKey: ['relatedProducts', componentId],
    queryFn: async ({ queryKey }) => {
      const [, componentId] = queryKey;
      const accessToken: string = await getAccessTokenSilently();
      return await componentService.findRelatedProducts(componentId as string, accessToken);
    },
    enabled: componentId !== undefined,
  });

  /* --------------------------------------------------- functions -------------------------------------------------- */

  function handleResourceClick(resourceId: string): void {
    resources.forEach((resource: ResourceFront) => {
      if (resource.id === resourceId) {
        resource.isCollapsed = !resource.isCollapsed;
      }
    });
    setResources([...resources]);
  }

  function handleMethodClick(methodId: string): void {
    setSelectedMethodId(methodId);
    setSelectedMethod(methodId);
  }

  function handleClickBackButtonResources(): void {
    setSelectedComponent(undefined);
    setSelectedMethodId(undefined);
    setSelectedMethod(undefined);
  }

  function handleClickGoToComponent(): void {
    if (selectedComponent === undefined) return;
    navigate(`/component/${selectedComponent.id}/specifications-api/`, {});
  }

  function onClickEmpty(): void {
    if (relatedProductsStatus === 'success' && relatedProducts.length === 1) {
      navigate(`/product/${relatedProducts[0].id}/app/`, {});
    }
  }

  /* ------------------------------------------------------ JSX ----------------------------------------------------- */

  return (
    <>
      <SideMenu width="sm">
        <div className={concatClassNames('flex flex-col flex-grow justify-between overflow-auto gap-3')}>
          <div className="flex flex-col gap-3 flex-grow overflow-auto">
            {selectedComponent === undefined && (
              <>
                <div className="flex flex-row gap-6 items-center">
                  <BackButton onClick={onClickBackButton} />
                  <Title content="Choisir un composant" size="h4" />
                </div>

                {backComponentStatus === 'success' && backComponents.length === 0 && (
                  <Empty
                    title="Aucun composant disponible"
                    subtitle="Il n'existe actuellement aucun composant back-end relié à ce composant au sein du schéma applicatif"
                    icon="plug"
                  >
                    <Button content="Accéder au schéma applicatif" onClick={onClickEmpty} height="fit" width="fit" />
                  </Empty>
                )}
                {(backComponentStatus === 'success' || backComponentStatus === 'loading') && (
                  <div className="flex flex-col gap-6 justify-between flex-grow overflow-auto">
                    <div className="flex flex-col gap-6 overflow-auto">
                      {backComponentStatus === 'success' &&
                        backComponents.length !== 0 &&
                        backComponents.map((component: Component) => (
                          <ComponentSmallCard
                            key={component.id}
                            name={component.name}
                            componentType={component.componentType}
                            cursor="pointer"
                            onClick={() => {
                              setSelectedComponent(component);
                            }}
                          />
                        ))}
                      {backComponentStatus === 'loading' && (
                        <>
                          <ComponentSmallCard loading />
                          <ComponentSmallCard loading />
                          <ComponentSmallCard loading />
                        </>
                      )}
                    </div>
                  </div>
                )}
                {backComponentStatus === 'error' && (
                  <NetworkError message="Une erreur est survenue lors de la récupération des composants." />
                )}
              </>
            )}
            {selectedComponent !== undefined && (
              <>
                <div className={concatClassNames('flex flex-row', 'gap-6', 'justify-between')}>
                  <div className={concatClassNames('flex flex-row', 'gap-6', 'items-center')}>
                    <BackButton onClick={handleClickBackButtonResources} />
                    <Title content="Choisir la méthode" size="h4" />
                  </div>
                  {selectedComponent !== undefined && (
                    <Tooltip title={'Accéder au composant'} placement="bottom">
                      <div className={concatClassNames('flex items-center align-middle', 'cursor-pointer')}>
                        <a
                          href={`/component/${selectedComponent.id}/specifications-api/`}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {getIcon('upRight', 'black', 'md')}
                        </a>
                      </div>
                    </Tooltip>
                  )}
                </div>
                {resourcesStatus === 'success' && (
                  <>
                    {resources?.map((resource: ResourceFront) => (
                      <ApiCollapse
                        key={uuid()}
                        resourceId={resource.id}
                        title={resource.resourceRevisions[0].name}
                        arrowPosition="left"
                        isCollapsed={resource.isCollapsed}
                        onClick={handleResourceClick}
                      >
                        <ApiMethodsWrapper
                          methods={resource.resourceRevisions[0].methods.map((method: Method) => {
                            return {
                              id: method.id,
                              type: method.methodRevisions[0].methodType,
                              path: method.methodRevisions[0].name,
                              description: method.methodRevisions[0].description,
                            };
                          })}
                          onMethodClick={handleMethodClick}
                          selectedMethodId={selectedMethodId}
                        />
                      </ApiCollapse>
                    ))}
                    {resources.length === 0 && (
                      <Empty title="Ce composant n'a pas encore de ressource." icon="component">
                        <Button
                          content={`Accéder au composant ${selectedComponent.name}`}
                          height="fit"
                          width="fit"
                          onClick={handleClickGoToComponent}
                        />
                      </Empty>
                    )}
                  </>
                )}

                {resourcesStatus === 'loading' && (
                  <>
                    <ApiCollapse loading />
                    <ApiCollapse loading />
                    <ApiCollapse loading />
                  </>
                )}
                {resourcesStatus === 'error' && (
                  <NetworkError message="Une erreur est survenue lors de la récupération des ressources." />
                )}
              </>
            )}
          </div>
          {resourcesStatus === 'success' && (
            <div className="flex flex-row gap-2 justify-end pt-8 border-t-1 border-gray-50">
              <Button
                content="Définir comme source de données"
                iconPosition="right"
                type="submit"
                disabled={selectedMethodId === undefined}
                onClick={validateSelectedMethod}
              />
            </div>
          )}
        </div>
      </SideMenu>
    </>
  );
}
