/* tslint:disable */
/* eslint-disable */
/**
 * Specks API
 * APIs for specks SaaS
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ExternalLinkDto } from './ExternalLinkDto';
import {
    ExternalLinkDtoFromJSON,
    ExternalLinkDtoFromJSONTyped,
    ExternalLinkDtoToJSON,
} from './ExternalLinkDto';

/**
 * 
 * @export
 * @interface GetSpecificationExternalLinksDto
 */
export interface GetSpecificationExternalLinksDto {
    /**
     * 
     * @type {Array<ExternalLinkDto>}
     * @memberof GetSpecificationExternalLinksDto
     */
    externalLinks: Array<ExternalLinkDto>;
}

/**
 * Check if a given object implements the GetSpecificationExternalLinksDto interface.
 */
export function instanceOfGetSpecificationExternalLinksDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "externalLinks" in value;

    return isInstance;
}

export function GetSpecificationExternalLinksDtoFromJSON(json: any): GetSpecificationExternalLinksDto {
    return GetSpecificationExternalLinksDtoFromJSONTyped(json, false);
}

export function GetSpecificationExternalLinksDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetSpecificationExternalLinksDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'externalLinks': ((json['externalLinks'] as Array<any>).map(ExternalLinkDtoFromJSON)),
    };
}

export function GetSpecificationExternalLinksDtoToJSON(value?: GetSpecificationExternalLinksDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'externalLinks': ((value.externalLinks as Array<any>).map(ExternalLinkDtoToJSON)),
    };
}

