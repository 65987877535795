import { type HistoryHandler, SpecksApisContext, useHistoryHandler } from 'App';
import { type GetExternalLinkDto } from 'api';

import React, { useContext, useState } from 'react';
import { type QueryClient, useMutation, useQuery, useQueryClient } from 'react-query';
import { type NavigateFunction, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { useAuth0 } from '@auth0/auth0-react';

import { BasePage } from 'pages/core/BasePage/BasePage';

import { CreateOrEditExternalLinkForm } from 'forms/CreateExternalLinkForm/CreateExternalLinkForm';
import { CreateorEditProductForm } from 'forms/CreateorEditProductForm/CreateorEditProductForm';

import { Button } from 'components/core/Button/Button';
import { type BreadCrumbsProps, Header } from 'components/core/Header/Header';
import { Modal } from 'components/core/Modal/Modal';
import { type ButtonMenuProps } from 'components/core/Settings/Settings.types';
import { Table } from 'components/core/Table/Table';
import { TableBody } from 'components/core/Table/TableBody';
import { Text } from 'components/core/Text/Text';
import { Title } from 'components/core/Title/Title';
import { Widget } from 'components/core/Widget/Widget';
import { AppSchemaComponent } from 'components/specks/AppSchemaComponent/AppSchemaComponent';
import { CockpitRow } from 'components/specks/CockpitRow/CockpitRow';
import { DeleteProduct } from 'components/specks/DeleteProduct/DeleteProduct';
import { NetworkError } from 'components/specks/Error/Error';

import { type ExternalLink } from 'models/externalLink.entity';
import { type Feature } from 'models/feature.entity';
import { Product } from 'models/product.entity';

import { tooltipDescriptionsStyle } from 'utils/tooltipsDescriptions';

interface ProductPageParams {
  id?: string;
}

export function ProductPage(): JSX.Element {
  /* --------------------------------------------------- contexts --------------------------------------------------- */

  const { productService, externalLinkService } = useContext(SpecksApisContext);
  const { id = '' }: ProductPageParams = useParams();
  const navigate: NavigateFunction = useNavigate();
  const { getAccessTokenSilently } = useAuth0();
  const historyHandler: HistoryHandler = useHistoryHandler();

  /* ---------------------------------------------------- states ---------------------------------------------------- */

  const [isCreateExternalLinkModalOpened, setIsCreateExternalLinkModalOpened] = useState<boolean>(false);
  const [isEditExternalLinkModalOpened, setIsEditExternalLinkModalOpened] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isDeleteExternalLinkModalOpened, setIsDeleteExternalLinkModalOpened] = useState<boolean>(false);
  const [selectedExternalLinkId, setSelectedExternalLinkId] = useState<string>('');
  const [isEditProductModalOpened, setIsEditProductModalOpened] = useState<boolean>(false);
  const [productToRemove, setProductToRemove] = useState<string>();

  /* -------------------------------------------------- Queries --------------------------------------------------- */

  async function getProduct(): Promise<Product> {
    const accessToken: string = await getAccessTokenSilently();
    return await productService.findById(id, accessToken);
  }

  const { data, status } = useQuery<Product, Error>(['product', id], getProduct);
  const queryClient: QueryClient = useQueryClient();

  const { data: featureData, status: featureStatus } = useQuery<Feature[], Error>('features', async () => {
    const accessToken: string = await getAccessTokenSilently();
    return await productService.getProductFeatures(id, accessToken);
  });

  /* ---------------------------------------------- Create ExternalLink --------------------------------------------- */

  async function createExternalLink(externalLink: ExternalLink): Promise<GetExternalLinkDto> {
    const accessToken: string = await getAccessTokenSilently();
    return await productService.createExternalLink(id, externalLink, accessToken);
  }

  async function handleExternalLinksFormSubmission(externalLink: ExternalLink): Promise<void> {
    try {
      mutateAddExternalLink(externalLink);
    } catch (error) {
      console.error(error);
    }
  }

  const { mutate: mutateAddExternalLink } = useMutation(createExternalLink, {
    onSuccess: (getExternalLink: GetExternalLinkDto) => {
      setIsCreateExternalLinkModalOpened(false);
      queryClient.setQueryData(['product', id], (oldData: Product | undefined) => {
        if (oldData === undefined) {
          return new Product();
        }
        oldData.externalLinks.push(getExternalLink);
        return oldData;
      });
    },
    onError: () => {
      alert('there was an error');
    },
  });

  /* ------------------------------------------------- Edit Product ------------------------------------------------- */

  async function editProduct(updatedProduct: Product): Promise<Product> {
    const accessToken: string = await getAccessTokenSilently();
    return await productService.update(id, updatedProduct, accessToken);
  }

  function handleEditProductFormSubmission(updatedProduct: Product): void {
    setIsLoading(true);
    setTimeout(() => {
      handleProductEditionConfirmation(updatedProduct);
    }, 1);
  }

  // TODO : delete catch error in this function (error already caught in mutateEditProduct)
  function handleProductEditionConfirmation(updatedProduct: Product): void {
    toast.update('Le Produit est en cours de modification, vous allez être redirigé vers la liste des produits');
    try {
      mutateEditProduct(updatedProduct);
    } catch (error) {
      toast.error('Erreur lors de la modification du produit.');
      setIsLoading(false);
    }
  }

  const { mutate: mutateEditProduct } = useMutation(editProduct, {
    onSuccess: (product: Product) => {
      queryClient.setQueryData(['product', id], (oldData: Product | undefined) => {
        if (oldData === undefined) {
          return new Product();
        } else {
          oldData.name = product.name;
          oldData.status = product.status;
          return oldData;
        }
      });
      toast.success('Le produit a bien été mis jour');
      setIsEditProductModalOpened(false);
      setIsLoading(false);
    },
    onError: () => {
      toast.error('Une erreur est survenue lors de la modification du produit');
      setIsLoading(false);
    },
  });

  /* ------------------------------------------------ render content ------------------------------------------------ */

  // AMU : attention au loading

  function renderCockpitCards(status: string, data: Product | undefined): JSX.Element {
    return (
      <div className="flex flex-col gap-6 py-4 px-4 h-full">
        <div className="flex flex-row justify-between items-center">
          <Title content="Ressources et liens utiles" />
          <div className="flex flex-row gap-6">
            <Button
              width="fit"
              height="sm"
              content="Ajouter"
              iconName="plus"
              rounded="rounded-full"
              onClick={handleClickAddExternalLink}
              disabled={status !== 'success'}
              tooltip={'Centralisez vos informations en ajoutant un lien URL vers un document ou un outil externe'}
            />
          </div>
        </div>
        <div className="overflow-y-auto flex flex-grow">
          {status === 'error' && <NetworkError message="Problème lors de l'import du produit" />}

          {status === 'loading' && (
            <Table padding="">
              <TableBody margin="">
                <CockpitRow loading={true} />
                <CockpitRow loading={true} />
                <CockpitRow loading={true} />
                <CockpitRow loading={true} />
              </TableBody>
            </Table>
          )}

          {status === 'success' && (
            <Table padding="" flexType="grow">
              <TableBody margin="px-4 py-2" flexType="grow">
                <CockpitRow
                  id={id}
                  hasMenu={false}
                  name="ELEMENTS DE CADRAGE"
                  icon="lightbulbOn"
                  href={'/product/' + id + '/' + 'genesis'}
                  tooltip={"Retracez l'origine de votre produit"}
                />

                <CockpitRow
                  id={id}
                  hasMenu={false}
                  name="LEXIQUE & QUESTIONS/REPONSES"
                  icon="books"
                  href={'/product/' + id + '/' + 'dico-faq'}
                  tooltip="Définitions & questions fréquentes sur le produit"
                />

                <>
                  {featureStatus === 'success' && featureData.length > 0 && (
                    <CockpitRow
                      id={id}
                      hasMenu={false}
                      name="FONCTIONNALITÉS"
                      icon="feature"
                      href={'/product/' + id + '/' + 'features'}
                      tooltip="Grandes fonctionnalités (EPICs) de votre produit"
                    />
                  )}
                </>
                <>
                  {data?.externalLinks?.map((externalLink: ExternalLink) => (
                    <CockpitRow
                      key={externalLink.id}
                      id={externalLink.id}
                      name={externalLink.name.toUpperCase()}
                      icon="externalLink"
                      href={externalLink.url}
                      openInNewTab
                      onDeletionClick={handleExternalLinkDeletionClick}
                      onEditClick={handleEditExternalLinkClick}
                    />
                  ))}
                </>
              </TableBody>
            </Table>
          )}
        </div>
      </div>
    );
  }

  /* ------------------------------------------------ page functions ------------------------------------------------ */

  function handleClickAddExternalLink(): void {
    setIsCreateExternalLinkModalOpened(true);
  }

  function handleExternalLinkDeletionClick(externalLinkId: string): void {
    setSelectedExternalLinkId(externalLinkId);
    setIsDeleteExternalLinkModalOpened(true);
  }

  function handleOnSubmitDeleteExternalLink(event: any): void {
    event.preventDefault();
    setIsLoading(true);
    setTimeout(() => {
      handleExternalLinkDeletionConfirmation();
    }, 1);
  }

  function handleEditAppSchema(): void {
    navigate(`/product/${id}/app`);
  }

  /* ----------------------------------------------- Buttons Menu ------------------------------------------------ */

  const buttonsMenu: ButtonMenuProps[] = [
    {
      name: 'Modifier',
      iconName: 'edit',
      textColor: 'gray-500',
      onClick: () => {
        setIsEditProductModalOpened(true);
      },
    },
    {
      name: 'Supprimer',
      iconName: 'trash',
      textColor: 'gray-500',
      onClick: () => {
        setProductToRemove(id);
      },
    },
  ];

  /* ----------------------------------------------- Edit ExternalLink ---------------------------------------------- */

  function handleEditExternalLinkClick(externalLinkId: string): void {
    setSelectedExternalLinkId(externalLinkId);
    setIsEditExternalLinkModalOpened(true);
  }

  async function editExternalLink(updatedExternalLink: ExternalLink): Promise<ExternalLink> {
    const accessToken: string = await getAccessTokenSilently();
    return await externalLinkService.update(selectedExternalLinkId, updatedExternalLink, accessToken);
  }

  const { mutate: mutateEditExternalLink } = useMutation(editExternalLink, {
    onSuccess: (externalLink: ExternalLink) => {
      queryClient.setQueryData(['product', id], (oldData: Product | undefined) => {
        if (oldData === undefined) {
          return new Product();
        } else {
          oldData.externalLinks = oldData.externalLinks.map((link) => {
            if (link.id === externalLink.id) {
              return externalLink;
            }
            return link;
          });
          return oldData;
        }
      });
      toast.success('Le lien externe a bien été mis jour');
      setIsEditExternalLinkModalOpened(false);
      setIsLoading(false);
    },
    onError: () => {
      toast.error('Une erreur est survenue lors de la modification du produit');
      setIsLoading(false);
    },
  });

  // TODO : delete catch error in this function (error already caught in mutateEditExternalLink)
  function handleExternalLinkEditionConfirmation(updatedExternalLink: ExternalLink): void {
    toast.update('Le lien externe est en cours de modification.');
    try {
      mutateEditExternalLink(updatedExternalLink);
    } catch (error) {
      toast.error('Erreur lors de la modification du lien externe.');
      setIsLoading(false);
    }
  }

  function handleEditExternalLinkFormSubmission(updatedExternalLink: ExternalLink): void {
    setIsLoading(true);
    setTimeout(() => {
      handleExternalLinkEditionConfirmation(updatedExternalLink);
    }, 1);
  }

  /* ------------------------------------------ Delete External Link ------------------------------------------------ */

  async function deleteExternalLink(): Promise<void> {
    const accessToken: string = await getAccessTokenSilently();
    await externalLinkService.deleteExternalLink(selectedExternalLinkId, accessToken);
  }

  const { mutate: mutateDeleteExternalLink } = useMutation(deleteExternalLink, {
    onSuccess: () => {
      queryClient.setQueryData(['product', id], (oldData: Product | undefined) => {
        if (oldData === undefined) {
          return new Product();
        } else {
          oldData.externalLinks = oldData.externalLinks.filter((link) => link.id !== selectedExternalLinkId);
          return oldData;
        }
      });
      toast.success('Le lien externe a bien été supprimé');
    },
    onError: () => {
      toast.error('Erreur lors de la suppression du lien externe');
    },
    onSettled: () => {
      setIsLoading(false);
      setIsDeleteExternalLinkModalOpened(false);
    },
  });

  function handleExternalLinkDeletionConfirmation(): void {
    try {
      mutateDeleteExternalLink();
    } catch (error) {
      console.error(error);
    }
  }
  function handleClickBack(): void {
    if (historyHandler?.oldPath === undefined) {
      navigate(-1);
      return;
    }
    // if oldPath strts with /product, then navigate to /products
    // else, navigate to oldPath
    if (historyHandler.oldPath.startsWith('/product')) {
      navigate('/products');
    } else {
      navigate(historyHandler.oldPath);
    }
  }

  /* ------------------------------------------------------ JSX ----------------------------------------------------- */

  const breadCrumbs: BreadCrumbsProps = {
    paths: [{ name: 'Produits', href: '/products' }],
    current: data?.name ?? 'Error',
  };

  return (
    <BasePage>
      <DeleteProduct
        productId={productToRemove}
        setProductId={setProductToRemove}
        behaviourAfterDeletion={() => {
          navigate('/products');
        }}
      />
      <Modal
        title="Ajouter un lien externe"
        isOpen={isCreateExternalLinkModalOpened}
        setIsOpen={setIsCreateExternalLinkModalOpened}
      >
        <CreateOrEditExternalLinkForm onSubmit={handleExternalLinksFormSubmission} />
      </Modal>
      <Modal
        title="Modifier ce lien externe"
        isOpen={isEditExternalLinkModalOpened}
        setIsOpen={setIsEditExternalLinkModalOpened}
      >
        <CreateOrEditExternalLinkForm
          onSubmit={handleEditExternalLinkFormSubmission}
          externalLink={data?.externalLinks.find((externalLink) => externalLink.id === selectedExternalLinkId)}
        />
      </Modal>

      <Modal
        title="Modifiez les paramètres de votre produit"
        isOpen={isEditProductModalOpened}
        setIsOpen={setIsEditProductModalOpened}
      >
        <CreateorEditProductForm
          isEditing
          product={data}
          onSubmit={handleEditProductFormSubmission}
          isLoading={isLoading}
        />
      </Modal>
      <Modal title="Attention" isOpen={isDeleteExternalLinkModalOpened} setIsOpen={setIsDeleteExternalLinkModalOpened}>
        <>
          <Text
            whitespace="pre-line"
            position="justify"
            content="Vous allez supprimer le lien vers cet outil tiers. Êtes-vous certain(e) de vouloir poursuivre ?"
          />
          <div className="flex flex-row gap-2 justify-end">
            {!isLoading && (
              <Button
                onClick={handleOnSubmitDeleteExternalLink}
                content="Supprimer"
                width="1/2"
                type="submit"
                height="sm"
              />
            )}
            {isLoading && <Button iconName="spinCircle" width="1/2" height="sm" iconAnimation="spin" />}
          </div>
        </>
      </Modal>
      <Header
        queryStatus={status}
        buttonsMenu={buttonsMenu}
        breadCrumbs={breadCrumbs}
        handleClickBack={handleClickBack}
        title={data?.name}
        status={data?.status}
        thumbnail={true}
        thumbnailIcon={'product'}
        thumbnailTooltipMessage={tooltipDescriptionsStyle.product}
      />
      <div className="flex flex-row flex-grow overflow-auto gap-2">
        <Widget type="2/3">
          <>
            <div className="flex flex-row gap-2 items-stretch h-full">
              {status === 'success' && <AppSchemaComponent isEditable={false} productId={data?.id} />}
            </div>
            <div className="absolute bottom-6 left-6">
              <Button content="Editer le schéma" rounded="rounded-full" onClick={handleEditAppSchema}></Button>
            </div>
          </>
        </Widget>
        <Widget type="1/3">{renderCockpitCards(status, data)}</Widget>
      </div>
    </BasePage>
  );
}
