import React from 'react';
import { type NavigateFunction, useNavigate } from 'react-router-dom';

import { Card } from 'components/core/Card/Card';
import { CardBody } from 'components/core/Card/CardBody';
import { Chip } from 'components/core/Chip/Chip';
import { IconBadge } from 'components/core/IconBadge/IconBadge';
import { Settings } from 'components/core/Settings/Settings';
import { type ButtonMenuProps } from 'components/core/Settings/Settings.types';
import { Text } from 'components/core/Text/Text';

import getStatusChip from 'utils/getStatusChip';

import { type FeatureCardProps } from './FeatureCard.props';

export function FeatureCard({
  id = '',
  name = '',
  status,
  loading = false,
  href,
  hasMenu = true,
  onDeletionClick,
}: FeatureCardProps): JSX.Element {
  const navigate: NavigateFunction = useNavigate();

  /* ------------------------------------------------- menu options ------------------------------------------------- */

  const buttonsMenu: ButtonMenuProps[] = [
    {
      name: 'Modifier',
      iconName: 'edit',
      textColor: 'black',
      isEnabled: false,
      onClick: () => {
        console.log('Modifier');
      },
    },
    {
      name: 'Supprimer',
      iconName: 'trash',
      textColor: 'red',
      onClick: handleDeletionClick,
    },
  ];
  function handleClick(): void {
    if (loading) return;
    if (href === undefined) console.error('href is null or undefined');
    else navigate(href);
  }

  function handleDeletionClick(event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void {
    event.stopPropagation();
    if (onDeletionClick !== undefined) onDeletionClick(id);
  }

  return (
    <Card onClick={handleClick} loading={loading} hasAnimation cursor={loading ? 'auto' : 'pointer'}>
      {loading ? (
        <CardBody>
          <>
            <IconBadge loading />
            <Text loading />
            <Chip loading />
          </>
        </CardBody>
      ) : (
        <CardBody>
          <>
            {hasMenu !== undefined && (
              <div className="absolute top-2 right-4">
                <Settings settingsIconName="more" buttonsMenu={buttonsMenu} />
              </div>
            )}
            <IconBadge icon="feature" color="gray" size="xxl" />
            <Text
              content={name}
              color="black"
              size="base"
              weight="bold"
              tracking="wide"
              whitespace="normal"
              position="center"
            />
            {status !== undefined && getStatusChip({ name: status })}
          </>
        </CardBody>
      )}
    </Card>
  );
}
