import React from 'react';

import { Settings } from 'components/core/Settings/Settings';
import type { ButtonMenuProps } from 'components/core/Settings/Settings.types';
import { Text } from 'components/core/Text/Text';
import { DeleteChip } from 'components/specks/Chips/Api/DeleteChip';
import { GetChip } from 'components/specks/Chips/Api/GetChip';
import { LoadingChip } from 'components/specks/Chips/Api/LoadingChip';
import { PatchChip } from 'components/specks/Chips/Api/PatchChip';
import { PostChip } from 'components/specks/Chips/Api/PostChip';
import { PutChip } from 'components/specks/Chips/Api/PutChip';
import { type ResourceHandler, useResourceHandler } from 'components/specks/Widgets/ApiWidget';

import concatClassNames from 'utils/classNames';

type ComponentBackEndApiDetailHeaderProps =
  | {
      name: string;
      description: string;
      methodType?: string;
      isEditable?: boolean;
      onDelete?: () => void;
      onEdit?: () => void;
      loading?: false;
    }
  | {
      name?: never;
      description?: never;
      methodType?: never;
      isEditable?: never;
      onDelete?: never;
      onEdit?: never;
      loading: true;
    };

export function ComponentBackEndApiDetailHeader({
  name = '',
  description = '',
  methodType,
  isEditable = false,
  onDelete,
  onEdit,
  loading = false,
}: ComponentBackEndApiDetailHeaderProps): JSX.Element {
  // TODO : add button onClick

  /* --------------------------------------------------- contexts --------------------------------------------------- */

  const resourceHandler: ResourceHandler = useResourceHandler();

  const buttonsMenu: ButtonMenuProps[] = [
    {
      name: 'Modifier',
      iconName: 'edit',
      textColor: 'black',
      isEnabled: true,
      onClick: (e) => {
        e.preventDefault();
        e.stopPropagation();
        onEdit?.();
      },
    },
    {
      name: 'Supprimer',
      iconName: 'trash',
      textColor: 'red',
      onClick: () => {
        onDelete?.();
      },
    },
  ];

  /* ---------------------------------------------------- Values ---------------------------------------------------- */

  const finalMethodType: string | undefined =
    methodType === undefined ? resourceHandler.selectedMethod?.methodRevisions[0].methodType : methodType;

  /* ------------------------------------------------------ JSX ----------------------------------------------------- */

  return (
    <div id="api-header" className="flex flex-col gap-1">
      <div className={concatClassNames('flex flex-row gap-2')}>
        {loading && (
          <>
            <LoadingChip />
            <Text loading />
          </>
        )}
        {!loading && (
          <>
            <div className="flex my-auto">
              {finalMethodType === 'GET' && <GetChip size="2xl" />}
              {finalMethodType === 'POST' && <PostChip size="2xl" />}
              {finalMethodType === 'PUT' && <PutChip size="2xl" />}
              {finalMethodType === 'PATCH' && <PatchChip size="2xl" />}
              {finalMethodType === 'DELETE' && <DeleteChip size="2xl" />}
            </div>
            <div className={concatClassNames('flex justify-start max-h-16 overflow-auto items-center')}>
              <Text
                content={resourceHandler.selectedMethod?.methodRevisions[0].name.replaceAll('/', '/\u200b') ?? name}
                size="2xl"
                weight="medium"
                marginBottom="auto"
                marginTop="auto"
                overFlow="ellipsis"
                position="left"
              />
            </div>
            {isEditable && <Settings settingsIconName="more" buttonsMenu={buttonsMenu} />}
          </>
        )}
      </div>
      <>
        {loading ? (
          <Text loading />
        ) : (
          <Text
            content={resourceHandler.selectedMethod?.methodRevisions[0].description ?? description}
            position="left"
            size="base"
            color="gray-200"
            overFlow="ellipsis"
          />
        )}
      </>
    </div>
  );
}
