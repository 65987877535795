/* tslint:disable */
/* eslint-disable */
/**
 * Specks API
 * APIs for specks SaaS
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateUiComponentListDto
 */
export interface CreateUiComponentListDto {
    /**
     * 
     * @type {string}
     * @memberof CreateUiComponentListDto
     */
    functionalId?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUiComponentListDto
     */
    type: CreateUiComponentListDtoTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateUiComponentListDto
     */
    name: string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateUiComponentListDto
     */
    isFormValid: boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateUiComponentListDto
     */
    notes: string;
    /**
     * 
     * @type {number}
     * @memberof CreateUiComponentListDto
     */
    index: number;
    /**
     * 
     * @type {string}
     * @memberof CreateUiComponentListDto
     */
    parentId?: string;
}


/**
 * @export
 */
export const CreateUiComponentListDtoTypeEnum = {
    Title: 'title',
    Icon: 'icon',
    Text: 'text',
    Container: 'container',
    Input: 'input',
    Button: 'button',
    List: 'list',
    Media: 'media',
    Date: 'date',
    Select: 'select'
} as const;
export type CreateUiComponentListDtoTypeEnum = typeof CreateUiComponentListDtoTypeEnum[keyof typeof CreateUiComponentListDtoTypeEnum];


/**
 * Check if a given object implements the CreateUiComponentListDto interface.
 */
export function instanceOfCreateUiComponentListDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "isFormValid" in value;
    isInstance = isInstance && "notes" in value;
    isInstance = isInstance && "index" in value;

    return isInstance;
}

export function CreateUiComponentListDtoFromJSON(json: any): CreateUiComponentListDto {
    return CreateUiComponentListDtoFromJSONTyped(json, false);
}

export function CreateUiComponentListDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateUiComponentListDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'functionalId': !exists(json, 'functionalId') ? undefined : json['functionalId'],
        'type': json['type'],
        'name': json['name'],
        'isFormValid': json['isFormValid'],
        'notes': json['notes'],
        'index': json['index'],
        'parentId': !exists(json, 'parentId') ? undefined : json['parentId'],
    };
}

export function CreateUiComponentListDtoToJSON(value?: CreateUiComponentListDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'functionalId': value.functionalId,
        'type': value.type,
        'name': value.name,
        'isFormValid': value.isFormValid,
        'notes': value.notes,
        'index': value.index,
        'parentId': value.parentId,
    };
}

