/* tslint:disable */
/* eslint-disable */
/**
 * Specks API
 * APIs for specks SaaS
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CreateUiComponentButtonDto } from './CreateUiComponentButtonDto';
import {
    CreateUiComponentButtonDtoFromJSON,
    CreateUiComponentButtonDtoFromJSONTyped,
    CreateUiComponentButtonDtoToJSON,
} from './CreateUiComponentButtonDto';
import type { UpdateUicPositionDto } from './UpdateUicPositionDto';
import {
    UpdateUicPositionDtoFromJSON,
    UpdateUicPositionDtoFromJSONTyped,
    UpdateUicPositionDtoToJSON,
} from './UpdateUicPositionDto';

/**
 * 
 * @export
 * @interface CreateUicPositionButtonDto
 */
export interface CreateUicPositionButtonDto {
    /**
     * 
     * @type {CreateUiComponentButtonDto}
     * @memberof CreateUicPositionButtonDto
     */
    uiComponent: CreateUiComponentButtonDto;
    /**
     * 
     * @type {Array<UpdateUicPositionDto>}
     * @memberof CreateUicPositionButtonDto
     */
    positions: Array<UpdateUicPositionDto>;
}

/**
 * Check if a given object implements the CreateUicPositionButtonDto interface.
 */
export function instanceOfCreateUicPositionButtonDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "uiComponent" in value;
    isInstance = isInstance && "positions" in value;

    return isInstance;
}

export function CreateUicPositionButtonDtoFromJSON(json: any): CreateUicPositionButtonDto {
    return CreateUicPositionButtonDtoFromJSONTyped(json, false);
}

export function CreateUicPositionButtonDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateUicPositionButtonDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'uiComponent': CreateUiComponentButtonDtoFromJSON(json['uiComponent']),
        'positions': ((json['positions'] as Array<any>).map(UpdateUicPositionDtoFromJSON)),
    };
}

export function CreateUicPositionButtonDtoToJSON(value?: CreateUicPositionButtonDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'uiComponent': CreateUiComponentButtonDtoToJSON(value.uiComponent),
        'positions': ((value.positions as Array<any>).map(UpdateUicPositionDtoToJSON)),
    };
}

