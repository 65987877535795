import { isExternal } from 'util/types';

import React from 'react';
import { type ConnectDragSource } from 'react-dnd';

import { Tooltip } from '@mui/material';

import { Card } from 'components/core/Card/Card';
import { type CardBackgroundColor, type CardBorderColor } from 'components/core/Card/Card.props';
import { type CursorTypes } from 'components/core/Core.style';
import { Text, type TextColor } from 'components/core/Text/Text';

import { ComponentType, type IAppComponentType, componentTypes } from 'models/ComponentTypes.type';

import concatClassNames from 'utils/classNames';
import getIcon from 'utils/getIcon';

import { type IconColor, type IconName } from 'icons/Icon.props';

type ComponentSmallCardProps =
  | {
      name: string;
      componentType: ComponentType;
      badge?: JSX.Element;
      customRef?: ConnectDragSource;
      backgroundColor?: CardBackgroundColor;
      borderColor?: CardBorderColor;
      textColor?: TextColor;
      extra?: JSX.Element;
      cursor?: CursorTypes;
      onClick?: () => void;
      loading?: boolean;
      tooltip?: string;
    }
  | {
      name?: never;
      componentType?: never;
      badge?: never;
      customRef?: never;
      backgroundColor?: never;
      borderColor?: never;
      textColor?: never;
      extra?: never;
      cursor?: never;
      onClick?: never;
      loading?: true;
      tooltip?: never;
    };

export function ComponentSmallCard({
  name = '',
  componentType = ComponentType.Front,
  badge,
  customRef,
  backgroundColor = 'white',
  borderColor = 'gray',
  textColor = 'black',
  extra,
  cursor,
  onClick,
  loading = false,
  tooltip,
}: ComponentSmallCardProps): JSX.Element {
  const type: IAppComponentType | undefined = componentTypes.get(componentType);
  if (type == null) throw new Error(`Unknown component type: ${componentType}`);

  return (
    <Card
      customRef={customRef}
      orientation="horizontal"
      verticalPadding="sm"
      horizontalPadding="md"
      background={backgroundColor}
      borderColor={borderColor}
      round="md"
      loading={loading}
      cursor={cursor}
      onClick={onClick}
    >
      <div className="items-center">
        {loading ? (
          <div className={concatClassNames('w-6 h-6 rounded-lg', 'bg-gray-50 animate-pulse')} />
        ) : (
          getIcon(type.icon as IconName, type.color as IconColor, 'md')
        )}
      </div>
      <div className="flex flex-col px-2 w-full">
        {loading ? <Text loading /> : <Text content={name} color={textColor} position="left" />}
        <div className="flex flex-row gap-2 items-center">
          {loading ? (
            <Text loading />
          ) : (
            <>
              <Text content={type.fullName} color={type.color as TextColor} position="left" />

              <Tooltip title={type.description} placement="right">
                <div className="bg-black border-1 rounded-full">{getIcon('information', 'white', 'smd')}</div>
              </Tooltip>
            </>
          )}
          {badge}
        </div>
      </div>
      <>
        <Tooltip title={tooltip}>
          <div>{extra}</div>
        </Tooltip>
      </>
    </Card>
  );
}
