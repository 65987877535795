import React from 'react';

import concatClassNames from 'utils/classNames';

interface TooltipProps {
  message: string;
  children: JSX.Element;
}

export function Tooltip({ message, children }: TooltipProps): JSX.Element {
  return (
    <div className="group relative flex">
      {children}
      <div
        className={concatClassNames(
          'scale-0 flex flex-row',
          'items-center absolute',
          'transition duration-50',
          'top-0 bottom-0 left-12',
          'group-hover:scale-100',
          'z-10 w-max',
        )}
      >
        <svg
          className="absolute left-0 -ml-2 bottom-0 top-0 h-full"
          width="9px"
          height="16px"
          viewBox="0 0 6 16"
          version="1.1"
        >
          <polygon
            fill="#FFFFFF"
            id="Triangle"
            transform="translate(4.500000, 8.000000) rotate(-90.000000) translate(-4.500000, -62.000000) "
            points="4.5 57.5 12.5 66.5 -3.5 66.5"
          ></polygon>
        </svg>
        <p
          className={concatClassNames(
            'flex flex-col',
            'items-start',
            'p-2',
            'rounded-lg',
            'shadow-tooltip',
            'bg-white',
            'font-medium text-xs text-gray-600',
          )}
        >
          {message}
        </p>
      </div>
    </div>
  );
}
