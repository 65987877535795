import { type Method } from './method.entity';

export class ResourceRevision {
  id: string;

  createdAt: Date;

  lastest: boolean;

  name: string;

  methods: Method[];
}
