import { SpecksApisContext } from 'App';

import React, { useContext } from 'react';
import { useQuery } from 'react-query';
import { type NavigateFunction, useNavigate, useParams } from 'react-router-dom';

import { useAuth0 } from '@auth0/auth0-react';

import { BasePage } from 'pages/core/BasePage/BasePage';

import { CardContainer } from 'components/core/CardContainer/CardContainer';
import { type BreadCrumbsProps, Header } from 'components/core/Header/Header';
import { Section } from 'components/core/Section/Section';
import { SubTitle } from 'components/core/SubTitle/SubTitle';
import { Title } from 'components/core/Title/Title';
import { Empty } from 'components/specks/Empty/Empty';
import { NetworkError } from 'components/specks/Error/Error';
import { ProductCard } from 'components/specks/ProductCard/ProductCard';
import { UserAvatar } from 'components/specks/UserAvatar/UserAvatar';

import { type Component } from 'models/component.entity';
import type { Product } from 'models/product.entity';

import concatClassNames from 'utils/classNames';

interface ComponentRelatedProductsPageParams {
  componentId?: string;
}

export function ComponentRelatedProductsPage(): JSX.Element {
  /* --------------------------------------------------- contexts --------------------------------------------------- */

  const { componentService } = useContext(SpecksApisContext);
  const navigate: NavigateFunction = useNavigate();
  const { componentId = '' }: ComponentRelatedProductsPageParams = useParams();
  const { getAccessTokenSilently, user } = useAuth0();

  /* ---------------------------------------------------- Queries --------------------------------------------------- */

  async function getComponent(): Promise<Component> {
    const accessToken: string = await getAccessTokenSilently();
    return await componentService.findById(componentId, accessToken);
  }
  const { data: componentData, status: componentStatus } = useQuery<Component, Error>(
    ['component', componentId],
    getComponent,
  );

  async function getRelatedProducts(): Promise<Product[]> {
    const accessToken: string = await getAccessTokenSilently();
    return await componentService.findRelatedProducts(componentId, accessToken);
  }

  const { data: relatedProductsData, status: relatedProductsStatus } = useQuery<Product[], Error>(
    'products',
    getRelatedProducts,
  );

  function handleClickBack(): void {
    navigate(`/component/${componentId}`);
  }

  /* ------------------------------------------------------ JSX ----------------------------------------------------- */

  const breadCrumbs: BreadCrumbsProps = {
    paths: [
      { name: 'Composants applicatifs', href: '/components' },
      { name: componentData?.name ?? 'Error', href: '/component/' + componentId },
    ],
    current: 'Produits consommateurs',
  };

  return (
    <BasePage>
      <Header
        breadCrumbs={breadCrumbs}
        handleClickBack={handleClickBack}
        title={componentData?.name}
        status={componentData?.status}
        queryStatus={componentStatus}
      />
      <Section>
        <div className={concatClassNames('flex flex-row justify-between')}>
          <div className={concatClassNames('flex flex-col')}>
            <Title
              content={
                componentData?.componentType === 'front' ? 'Produit consommateur' : 'Liste des produits consommateurs'
              }
            />
            <SubTitle
              content={
                componentData?.componentType === 'front'
                  ? 'Retrouvez ici le produit qui utilise ce composant'
                  : "Retrouvez ici l'ensemble des produits qui utilisent ce composant"
              }
            />
          </div>
        </div>
        <>
          {relatedProductsStatus === 'error' && (
            <NetworkError
              message={
                componentData?.componentType === 'front'
                  ? "Un problème est survenu lors de l'affichage du produit"
                  : "Un problème est survenu lors de l'affichage des produits."
              }
            />
          )}
          {relatedProductsStatus === 'loading' && (
            <CardContainer>
              <ProductCard loading />
              <ProductCard loading />
            </CardContainer>
          )}
          {relatedProductsStatus === 'success' && relatedProductsData.length === 0 && (
            <Empty icon="product" title="Aucun produit n'utilise ce composant"></Empty>
          )}
          {relatedProductsStatus === 'success' && relatedProductsData.length !== 0 && (
            <CardContainer>
              {relatedProductsData.map((product) => {
                return (
                  <ProductCard
                    key={product.id}
                    id={product.id}
                    name={product.name}
                    status={product.status}
                    components={product.components}
                  ></ProductCard>
                );
              })}
            </CardContainer>
          )}
        </>
      </Section>
    </BasePage>
  );
}
