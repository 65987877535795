/* tslint:disable */
/* eslint-disable */
/**
 * Specks API
 * APIs for specks SaaS
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AppSchemaRevision } from './AppSchemaRevision';
import {
    AppSchemaRevisionFromJSON,
    AppSchemaRevisionFromJSONTyped,
    AppSchemaRevisionToJSON,
} from './AppSchemaRevision';
import type { ComponentCompany } from './ComponentCompany';
import {
    ComponentCompanyFromJSON,
    ComponentCompanyFromJSONTyped,
    ComponentCompanyToJSON,
} from './ComponentCompany';
import type { ComponentDictionary } from './ComponentDictionary';
import {
    ComponentDictionaryFromJSON,
    ComponentDictionaryFromJSONTyped,
    ComponentDictionaryToJSON,
} from './ComponentDictionary';
import type { ComponentFaq } from './ComponentFaq';
import {
    ComponentFaqFromJSON,
    ComponentFaqFromJSONTyped,
    ComponentFaqToJSON,
} from './ComponentFaq';
import type { ExternalLink } from './ExternalLink';
import {
    ExternalLinkFromJSON,
    ExternalLinkFromJSONTyped,
    ExternalLinkToJSON,
} from './ExternalLink';
import type { Feature } from './Feature';
import {
    FeatureFromJSON,
    FeatureFromJSONTyped,
    FeatureToJSON,
} from './Feature';
import type { Link } from './Link';
import {
    LinkFromJSON,
    LinkFromJSONTyped,
    LinkToJSON,
} from './Link';
import type { Persona } from './Persona';
import {
    PersonaFromJSON,
    PersonaFromJSONTyped,
    PersonaToJSON,
} from './Persona';
import type { ProductGenesis } from './ProductGenesis';
import {
    ProductGenesisFromJSON,
    ProductGenesisFromJSONTyped,
    ProductGenesisToJSON,
} from './ProductGenesis';

/**
 * 
 * @export
 * @interface Product
 */
export interface Product {
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    status: ProductStatusEnum;
    /**
     * 
     * @type {Date}
     * @memberof Product
     */
    createdAt: Date;
    /**
     * 
     * @type {ProductGenesis}
     * @memberof Product
     */
    genesis: ProductGenesis;
    /**
     * 
     * @type {ComponentDictionary}
     * @memberof Product
     */
    dictionary: ComponentDictionary;
    /**
     * 
     * @type {ComponentFaq}
     * @memberof Product
     */
    faq: ComponentFaq;
    /**
     * 
     * @type {Array<ExternalLink>}
     * @memberof Product
     */
    externalLinks: Array<ExternalLink>;
    /**
     * 
     * @type {Array<Persona>}
     * @memberof Product
     */
    personae: Array<Persona>;
    /**
     * 
     * @type {Array<AppSchemaRevision>}
     * @memberof Product
     */
    appSchemaRevisions: Array<AppSchemaRevision>;
    /**
     * 
     * @type {Array<Feature>}
     * @memberof Product
     */
    features: Array<Feature>;
    /**
     * 
     * @type {ComponentCompany}
     * @memberof Product
     */
    company: ComponentCompany;
    /**
     * 
     * @type {Array<Link>}
     * @memberof Product
     */
    links: Array<Link>;
}


/**
 * @export
 */
export const ProductStatusEnum = {
    Production: 'production',
    Planed: 'planed',
    InDevelopment: 'inDevelopment',
    InDecommissionning: 'inDecommissionning',
    Decommissioned: 'decommissioned',
    InExploration: 'inExploration',
    Evolving: 'evolving'
} as const;
export type ProductStatusEnum = typeof ProductStatusEnum[keyof typeof ProductStatusEnum];


/**
 * Check if a given object implements the Product interface.
 */
export function instanceOfProduct(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "status" in value;
    isInstance = isInstance && "createdAt" in value;
    isInstance = isInstance && "genesis" in value;
    isInstance = isInstance && "dictionary" in value;
    isInstance = isInstance && "faq" in value;
    isInstance = isInstance && "externalLinks" in value;
    isInstance = isInstance && "personae" in value;
    isInstance = isInstance && "appSchemaRevisions" in value;
    isInstance = isInstance && "features" in value;
    isInstance = isInstance && "company" in value;
    isInstance = isInstance && "links" in value;

    return isInstance;
}

export function ProductFromJSON(json: any): Product {
    return ProductFromJSONTyped(json, false);
}

export function ProductFromJSONTyped(json: any, ignoreDiscriminator: boolean): Product {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'status': json['status'],
        'createdAt': (new Date(json['createdAt'])),
        'genesis': ProductGenesisFromJSON(json['genesis']),
        'dictionary': ComponentDictionaryFromJSON(json['dictionary']),
        'faq': ComponentFaqFromJSON(json['faq']),
        'externalLinks': ((json['externalLinks'] as Array<any>).map(ExternalLinkFromJSON)),
        'personae': ((json['personae'] as Array<any>).map(PersonaFromJSON)),
        'appSchemaRevisions': ((json['appSchemaRevisions'] as Array<any>).map(AppSchemaRevisionFromJSON)),
        'features': ((json['features'] as Array<any>).map(FeatureFromJSON)),
        'company': ComponentCompanyFromJSON(json['company']),
        'links': ((json['links'] as Array<any>).map(LinkFromJSON)),
    };
}

export function ProductToJSON(value?: Product | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'status': value.status,
        'createdAt': (value.createdAt.toISOString()),
        'genesis': ProductGenesisToJSON(value.genesis),
        'dictionary': ComponentDictionaryToJSON(value.dictionary),
        'faq': ComponentFaqToJSON(value.faq),
        'externalLinks': ((value.externalLinks as Array<any>).map(ExternalLinkToJSON)),
        'personae': ((value.personae as Array<any>).map(PersonaToJSON)),
        'appSchemaRevisions': ((value.appSchemaRevisions as Array<any>).map(AppSchemaRevisionToJSON)),
        'features': ((value.features as Array<any>).map(FeatureToJSON)),
        'company': ComponentCompanyToJSON(value.company),
        'links': ((value.links as Array<any>).map(LinkToJSON)),
    };
}

