import React from 'react';

import { Tooltip } from '@mui/material';

import concatClassNames from 'utils/classNames';
import getIcon from 'utils/getIcon';

import { type IconName } from 'icons/Icon.props';

interface ThumbnailProps {
  thumbnailTooltipMessage: string;
  thumbnailIcon: IconName;
}

export function Thumbnail({ thumbnailTooltipMessage, thumbnailIcon }: ThumbnailProps): JSX.Element {
  return (
    <Tooltip title={thumbnailTooltipMessage}>
      <div
        className={concatClassNames(
          'flex align-middle justify-center items-center h-16 w-16',
          'bg-white rounded-full',
          ' border-gray-500 border-[4px]',
        )}
      >
        {getIcon(thumbnailIcon, 'gray-500', 'lg')}
      </div>
    </Tooltip>
  );
}
