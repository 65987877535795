/* tslint:disable */
/* eslint-disable */
/**
 * Specks API
 * APIs for specks SaaS
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UserIdNameDto
 */
export interface UserIdNameDto {
    /**
     * 
     * @type {string}
     * @memberof UserIdNameDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof UserIdNameDto
     */
    name: string;
}

/**
 * Check if a given object implements the UserIdNameDto interface.
 */
export function instanceOfUserIdNameDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "name" in value;

    return isInstance;
}

export function UserIdNameDtoFromJSON(json: any): UserIdNameDto {
    return UserIdNameDtoFromJSONTyped(json, false);
}

export function UserIdNameDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserIdNameDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
    };
}

export function UserIdNameDtoToJSON(value?: UserIdNameDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
    };
}

