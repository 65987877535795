import { type HistoryHandler, SpecksApisContext, useHistoryHandler } from 'App';
import { type GetExternalLinkDto } from 'api';

import React, { createContext, useContext, useState } from 'react';
import { type QueryClient, useMutation, useQuery, useQueryClient } from 'react-query';
import { type NavigateFunction, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { useAuth0 } from '@auth0/auth0-react';

import { BasePage } from 'pages/core/BasePage/BasePage';

import { CreateOrEditExternalLinkForm } from 'forms/CreateExternalLinkForm/CreateExternalLinkForm';
import { CreateOrEditComponentForm } from 'forms/CreateOrEditComponentForm/CreateOrEditComponentForm';
import { CreateOrEditPageForm } from 'forms/CreatePageForm/CreateOrEditPageForm';

import { Button } from 'components/core/Button/Button';
import { type BreadCrumbsProps, Header } from 'components/core/Header/Header';
import { Modal } from 'components/core/Modal/Modal';
import { type ButtonMenuProps } from 'components/core/Settings/Settings.types';
import { Table } from 'components/core/Table/Table';
import { TableBody } from 'components/core/Table/TableBody';
import { Text } from 'components/core/Text/Text';
import { Title } from 'components/core/Title/Title';
import { Widget } from 'components/core/Widget/Widget';
import { CockpitRow } from 'components/specks/CockpitRow/CockpitRow';
import { DeleteComponent } from 'components/specks/DeleteComponent/DeleteComponent';
import { Empty } from 'components/specks/Empty/Empty';
import { NetworkError } from 'components/specks/Error/Error';
import { ApiWidget } from 'components/specks/Widgets/ApiWidget';
import { PagesWidget } from 'components/specks/Widgets/PagesWidget';

import { ComponentType } from 'models/ComponentTypes.type';
import { Component } from 'models/component.entity';
import { type ExternalLink } from 'models/externalLink.entity';
import { type Page } from 'models/page.entity';
import { type Product } from 'models/product.entity';

import getTypeChip from 'utils/getTypeChip';
import { tooltipDescriptionsStyle } from 'utils/tooltipsDescriptions';

/* ---------------------------------------------------- Context --------------------------------------------------- */
interface PageProviderProps {
  children: JSX.Element[] | JSX.Element;
  handler: PageHandler;
}
export interface PageHandler {
  pagesStatus: string;
  setPagesStatus: (status: string) => void;
  pagesData: Page[];
  setPagesData: (pages: Page[]) => void;
  handlePagesFormSubmission: any;
  setHandlePagesFormSubmission: (handlePagesFormSubmission: (page: Page) => void) => void;
  isModalOpened: boolean;
  setIsModalOpened: (isModalOpened: boolean) => void;
  isDeletePageModalOpened: boolean;
  setIsDeletePageModalOpened: (isDeletePageModalOpened: boolean) => void;
  selectedPageId: string | undefined;
  setSelectedPageId: (selectedPageId: string) => void;
  handleOnSubmitDeletePage: any;
  setHandleOnSubmitDeletePage: (handleOnSubmitDeletePage: () => void) => void;
}

export const PageContext: React.Context<PageHandler> = createContext({} as PageHandler);

export function usePageHandler(): PageHandler {
  return useContext(PageContext);
}

export function PageProvider({ children, handler }: PageProviderProps): JSX.Element {
  return <PageContext.Provider value={handler}>{children}</PageContext.Provider>;
}

/* ------------------------------------------------- ComponentPage ------------------------------------------------ */

interface ComponentPageParams {
  componentId?: string;
}

export function ComponentPage(): JSX.Element {
  /* --------------------------------------------------- contexts --------------------------------------------------- */

  const { componentService, externalLinkService } = useContext(SpecksApisContext);
  const { componentId = '' }: ComponentPageParams = useParams();
  const [searchParams] = useSearchParams();
  const queryClient: QueryClient = useQueryClient();
  const navigate: NavigateFunction = useNavigate();
  const { getAccessTokenSilently } = useAuth0();
  const historyHandler: HistoryHandler = useHistoryHandler();
  const selectedPathMethodId: string | undefined = searchParams.get('methodid') ?? undefined;

  /* ---------------------------------------------------- states ---------------------------------------------------- */

  const [isAddExternalLinkModalOpened, setIsAddExternalLinkModalOpened] = useState<boolean>(false);
  const [isEditExternalLinkModalOpened, setIsEditExternalLinkModalOpened] = useState<boolean>(false);
  const [isAddPageModalOpened, setIsAddPageModalOpened] = useState<boolean>(false);
  const [isConsumingProductsModalOpened, setIsConsumingProductsModalOpened] = useState<boolean>(false);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isEditComponentModalOpened, setIsEditComponentModalOpened] = useState<boolean>(false);
  const [componentToRemove, setComponentToRemove] = useState<string>();
  const [pagesData, setPagesData] = useState<Page[]>([]);
  const [pagesStatus, setPagesStatus] = useState<string>('loading');
  const [handlePagesFormSubmission, setHandlePagesFormSubmission] = useState<any>();
  const [isDeletePageModalOpened, setIsDeletePageModalOpened] = useState<boolean>(false);
  const [selectedPageId, setSelectedPageId] = useState<string | undefined>();
  const [handleOnSubmitDeletePage, setHandleOnSubmitDeletePage] = useState<any>();

  /* ------------------------------------------------- setUp Context ------------------------------------------------ */

  const pageHandler: PageHandler = {
    pagesData,
    setPagesData,
    pagesStatus,
    setPagesStatus,
    handlePagesFormSubmission,
    setHandlePagesFormSubmission,
    isModalOpened: isAddPageModalOpened,
    setIsModalOpened: setIsAddPageModalOpened,
    isDeletePageModalOpened,
    setIsDeletePageModalOpened,
    selectedPageId,
    setSelectedPageId,
    handleOnSubmitDeletePage,
    setHandleOnSubmitDeletePage,
  };

  /* --------------------------------------------------- variable --------------------------------------------------- */

  const buttonsMenu: ButtonMenuProps[] = [
    {
      name: 'Modifier',
      iconName: 'edit',
      textColor: 'black',
      onClick: () => {
        setIsEditComponentModalOpened(true);
      },
    },
    {
      name: 'Supprimer',
      iconName: 'trash',
      textColor: 'gray-500',
      onClick: () => {
        setComponentToRemove(componentId);
      },
    },
  ];

  /* ---------------------------------------------------- Queries --------------------------------------------------- */

  async function getComponent(): Promise<Component> {
    const accessToken: string = await getAccessTokenSilently();
    return await componentService.findById(componentId, accessToken);
  }

  const { data: componentData, status: componentStatus } = useQuery<Component, Error>(
    ['component', componentId],
    getComponent,
  );

  async function getRelatedProducts(): Promise<Product[]> {
    const accessToken: string = await getAccessTokenSilently();
    return await componentService.findRelatedProducts(componentId, accessToken);
  }

  const { data: relatedProductsData, status: relatedProductsStatus } = useQuery<Product[], Error>(
    ['products', componentId],
    getRelatedProducts,
  );

  /* --------------------------------------------- Create External Link --------------------------------------------- */

  async function createExternalLink(externalLink: ExternalLink): Promise<GetExternalLinkDto> {
    const accessToken: string = await getAccessTokenSilently();
    return await componentService.createExternalLink(componentId, externalLink, accessToken);
  }

  const { mutate } = useMutation(createExternalLink, {
    onSuccess: (getExternalLink: GetExternalLinkDto) => {
      toast.success('Lien externe créé avec succès');
      queryClient.setQueryData(['component', componentId], (oldData: Component | undefined) => {
        if (oldData === undefined) {
          return new Component();
        }
        oldData.externalLinks.push(getExternalLink);
        return oldData;
      });
      setIsAddExternalLinkModalOpened(false);
    },
    onError: () => {
      alert('there was an error');
    },
  });

  async function handleExternalLinksFormSubmission(externalLink: ExternalLink): Promise<void> {
    try {
      mutate(externalLink);
    } catch (error) {
      console.error(error);
    }
  }

  /* ----------------------------------------------- Edit ExternalLink ---------------------------------------------- */

  function handleEditExternalLinkClick(externalLinkId: string): void {
    setSelectedExternalLinkId(externalLinkId);
    setIsEditExternalLinkModalOpened(true);
  }

  async function editExternalLink(updatedExternalLink: ExternalLink): Promise<ExternalLink> {
    const accessToken: string = await getAccessTokenSilently();
    return await externalLinkService.update(selectedExternalLinkId, updatedExternalLink, accessToken);
  }

  const { mutate: mutateEditExternalLink } = useMutation(editExternalLink, {
    onSuccess: (externalLink: ExternalLink) => {
      queryClient.setQueryData(['component', componentId], (oldData: Component | undefined) => {
        if (oldData === undefined) {
          return new Component();
        } else {
          oldData.externalLinks = oldData.externalLinks.map((link) => {
            if (link.id === externalLink.id) {
              return externalLink;
            }
            return link;
          });
          return oldData;
        }
      });
      toast.success('Le lien externe a bien été mis jour');
      setIsEditExternalLinkModalOpened(false);
      setIsLoading(false);
    },
    onError: () => {
      toast.error('Une erreur est survenue lors de la modification du produit');
      setIsLoading(false);
    },
  });

  // TODO : delete catch error in this function (error already caught in mutateEditExternalLink)
  function handleExternalLinkEditionConfirmation(updatedExternalLink: ExternalLink): void {
    toast.update('Le lien externe est en cours de modification.');
    try {
      mutateEditExternalLink(updatedExternalLink);
    } catch (error) {
      toast.error('Erreur lors de la modification du lien externe.');
      setIsLoading(false);
    }
  }

  function handleEditExternalLinkFormSubmission(updatedExternalLink: ExternalLink): void {
    setIsLoading(true);
    setTimeout(() => {
      handleExternalLinkEditionConfirmation(updatedExternalLink);
    }, 1);
  }

  /* ------------------------------------------- Delete External Link ----------------------------------------------- */

  const [isDeleteExternalLinkModalOpened, setIsDeleteExternalLinkModalOpened] = useState<boolean>(false);
  const [selectedExternalLinkId, setSelectedExternalLinkId] = useState<string>('');

  function handleExternalLinkDeletionClick(externalLinkId: string): void {
    setSelectedExternalLinkId(externalLinkId);
    setIsDeleteExternalLinkModalOpened(true);
  }

  function handleOnSubmitDeleteExternalLink(event: any): void {
    event.preventDefault();
    setIsLoading(true);
    setTimeout(() => {
      handleExternalLinkDeletionConfirmation();
    }, 1);
  }

  function handleExternalLinkDeletionConfirmation(): void {
    setIsDeleteExternalLinkModalOpened(false);
    try {
      mutateDeleteExternalLink();
    } catch (error) {
      console.error(error);
    }
  }

  const { mutate: mutateDeleteExternalLink } = useMutation(deleteExternalLink, {
    onSuccess: () => {
      queryClient.setQueryData(['component', componentId], (oldData: Component | undefined) => {
        if (oldData === undefined) {
          return new Component();
        } else {
          oldData.externalLinks = oldData.externalLinks.filter((link) => link.id !== selectedExternalLinkId);
          return oldData;
        }
      });
      toast.success('Le lien externe a bien été supprimé');
      setIsLoading(false);
    },
    onError: () => {
      toast.error('Erreur lors de la suppression du lien externe');
    },
  });

  async function deleteExternalLink(): Promise<void> {
    const accessToken: string = await getAccessTokenSilently();
    await externalLinkService.deleteExternalLink(selectedExternalLinkId, accessToken);
  }

  /* ---------------------------------------------------- Render ---------------------------------------------------- */

  function renderCockpitCards(status: string, data: Component | undefined): JSX.Element {
    return (
      <div className="flex flex-col gap-6 py-4 px-4 h-full">
        <div className="flex flex-row justify-between items-center">
          <Title content="Ressources et liens utiles" />
          <div className="flex flex-row gap-6">
            <Button
              width="fit"
              height="sm"
              content="Ajouter"
              iconName="plus"
              rounded="rounded-full"
              onClick={handleClickAddExternalLink}
              disabled={status !== 'success'}
              tooltip={'Centralisez vos informations en ajoutant un lien URL vers un document ou un outil externe'}
            />
          </div>
        </div>
        <div className="overflow-y-auto flex flex-grow">
          {status === 'error' && <NetworkError message="Problème lors de l'import du produit" />}

          {status === 'loading' && (
            <Table padding="">
              <TableBody margin="">
                <CockpitRow loading={true} />
                <CockpitRow loading={true} />
                <CockpitRow loading={true} />
                <CockpitRow loading={true} />
              </TableBody>
            </Table>
          )}

          {status === 'success' && (
            <Table padding="" flexType="grow">
              <TableBody margin="px-4 py-2" flexType="grow">
                <CockpitRow
                  id={componentId}
                  hasMenu={false}
                  name="LEXIQUE & QUESTIONS/REPONSES"
                  icon="books"
                  href={'/component/' + componentId + '/' + 'dico-faq'}
                  tooltip="Définitions & questions fréquentes sur le produit"
                />

                <>
                  {data?.externalLinks?.map((externalLink: ExternalLink) => (
                    <CockpitRow
                      key={externalLink.id}
                      id={externalLink.id}
                      name={externalLink.name.toUpperCase()}
                      icon="externalLink"
                      href={externalLink.url}
                      openInNewTab
                      onDeletionClick={handleExternalLinkDeletionClick}
                      onEditClick={handleEditExternalLinkClick}
                    />
                  ))}
                </>
              </TableBody>
            </Table>
          )}
        </div>
      </div>
    );
  }

  /* --------------------------------------------------- functions -------------------------------------------------- */

  function handleClickAddExternalLink(): void {
    setIsAddExternalLinkModalOpened(true);
  }

  function handleClickBack(): void {
    if (historyHandler?.oldPath === undefined) {
      navigate(-1);
      return;
    }
    // if oldPath strts with /component, then navigate to /components
    // else, navigate to oldPath
    if (historyHandler.oldPath.startsWith('/component')) {
      navigate('/components');
    } else {
      navigate(historyHandler.oldPath);
    }
  }

  // redirect to the API edition page
  function handleClickEditApi(): void {
    let path: string = `/component/${componentId}/specifications-api?edition=true`;
    if (selectedPathMethodId !== undefined) {
      path += `&methodid=${selectedPathMethodId}`;
    }
    navigate(path);
  }

  /* ------------------------------------------------ Edit Component ------------------------------------------------ */

  async function editComponent(updatedComponent: Component): Promise<Component> {
    const accessToken: string = await getAccessTokenSilently();
    return await componentService.update(componentId, updatedComponent, accessToken);
  }

  function handleEditComponentFormSubmission(updatedComponent: Component): void {
    setIsLoading(true);
    setTimeout(() => {
      handleComponentEditionConfirmation(updatedComponent);
    }, 1);
  }

  function handleComponentEditionConfirmation(updatedComponent: Component): void {
    toast.update('Le Produit est en cours de modification, vous allez être redirigé vers la liste des produits');
    try {
      mutateEditComponent(updatedComponent);
    } catch (error) {
      toast.error('Erreur lors de la modification du produit.');
      setIsLoading(false);
    }
  }

  const { mutate: mutateEditComponent } = useMutation(editComponent, {
    onSuccess: (component: Component) => {
      queryClient.setQueryData(['component', componentId], (oldData: Component | undefined) => {
        if (oldData === undefined) {
          return new Component();
        } else {
          oldData.name = component.name;
          oldData.status = component.status;
          oldData.internal = component.internal;
          return oldData;
        }
      });
      toast.success('Le produit a bien été mis  jour');
      setIsEditComponentModalOpened(false);
      setIsLoading(false);
    },
    onError: () => {
      toast.error('Une erreur est survenue lors de la modification du produit');
      setIsLoading(false);
    },
  });

  /* ------------------------------------------------------ JSX ----------------------------------------------------- */

  const breadCrumbs: BreadCrumbsProps = {
    paths: [{ name: 'Composants applicatifs', href: '/components' }],
    current: componentData?.name ?? 'error',
  };

  return (
    <BasePage>
      <DeleteComponent
        componentId={componentToRemove}
        setComponentId={setComponentToRemove}
        behaviourAfterDeletion={() => {
          navigate('/components');
        }}
      />
      <Modal
        title="Ajouter un lien externe"
        isOpen={isAddExternalLinkModalOpened}
        setIsOpen={setIsAddExternalLinkModalOpened}
      >
        <CreateOrEditExternalLinkForm onSubmit={handleExternalLinksFormSubmission} />
      </Modal>
      <Modal
        title="Modifier ce lien externe"
        isOpen={isEditExternalLinkModalOpened}
        setIsOpen={setIsEditExternalLinkModalOpened}
      >
        <CreateOrEditExternalLinkForm
          onSubmit={handleEditExternalLinkFormSubmission}
          externalLink={componentData?.externalLinks.find((externalLink) => externalLink.id === selectedExternalLinkId)}
        />
      </Modal>
      <>
        {pagesStatus === 'success' && (
          <Modal title="Ajouter une nouvelle page" isOpen={isAddPageModalOpened} setIsOpen={setIsAddPageModalOpened}>
            <CreateOrEditPageForm onSubmit={handlePagesFormSubmission} currentPages={pagesData} />
          </Modal>
        )}
      </>
      <Modal title="Attention" isOpen={isDeletePageModalOpened} setIsOpen={setIsDeletePageModalOpened}>
        <>
          <Text
            whitespace="pre-line"
            position="justify"
            content="Vous allez supprimer cette page ainsi que tous les items qui y sont spécifiés. Êtes-vous certain(e) de vouloir poursuivre ?"
          />
          <div className="flex flex-row gap-2 justify-end">
            {!isLoading && (
              <Button onClick={handleOnSubmitDeletePage} content="Supprimer" width="1/2" type="submit" height="sm" />
            )}
            {isLoading && <Button iconName="spinCircle" width="1/2" height="sm" iconAnimation="spin" />}
          </div>
        </>
      </Modal>

      <Modal
        title="Modifiez les paramètres de votre produit"
        isOpen={isEditComponentModalOpened}
        setIsOpen={setIsEditComponentModalOpened}
      >
        <CreateOrEditComponentForm
          isEditing
          component={componentData}
          onSubmit={handleEditComponentFormSubmission}
          isLoading={isLoading}
        />
      </Modal>

      <Modal
        title="Produits consommateurs"
        isOpen={isConsumingProductsModalOpened}
        setIsOpen={setIsConsumingProductsModalOpened}
      >
        <>
          <Text
            whitespace="pre-line"
            content={`Retrouvez ici l'ensemble des produits qui utilisent ce composant :`}
            position="justify"
          />
          {relatedProductsStatus === 'success' && relatedProductsData.length === 0 && (
            <Empty icon="product" title="Aucun produit n'utilise ce composant"></Empty>
          )}
          {relatedProductsStatus === 'success' && relatedProductsData.length !== 0 && (
            <>
              {relatedProductsData.map((product) => (
                <li key={product.id}>
                  <a className="text-purple-600" href={`/product/${product.id}`}>
                    PRODUIT : {product.name}
                  </a>
                </li>
              ))}
            </>
          )}
        </>
      </Modal>
      <Modal title="Attention" isOpen={isDeleteExternalLinkModalOpened} setIsOpen={setIsDeleteExternalLinkModalOpened}>
        <>
          <Text
            whitespace="pre-line"
            position="justify"
            content="Vous allez supprimer le lien vers cet outil tiers. Êtes-vous certain(e) de vouloir poursuivre ?"
          />
          <div className="flex flex-row gap-2 justify-end">
            {!isLoading && (
              <Button
                onClick={handleOnSubmitDeleteExternalLink}
                content="Supprimer"
                width="1/2"
                type="submit"
                height="sm"
              />
            )}
            {isLoading && <Button iconName="spinCircle" width="1/2" height="sm" iconAnimation="spin" />}
          </div>
        </>
      </Modal>
      <PageProvider handler={pageHandler}>
        <Header
          queryStatus={componentStatus}
          buttonsMenu={buttonsMenu}
          breadCrumbs={breadCrumbs}
          handleClickBack={handleClickBack}
          title={breadCrumbs.current}
          status={componentData?.status}
          description={getTypeChip(componentData?.componentType, 'transparent', 'none')}
          thumbnail={true}
          thumbnailIcon={'api'}
          thumbnailTooltipMessage={tooltipDescriptionsStyle.component}
          relatedProductsLength={relatedProductsStatus === 'success' ? relatedProductsData.length : undefined}
          setIsConsumingProductsModalOpened={setIsConsumingProductsModalOpened}
        />

        <div className="flex flex-row flex-grow overflow-auto gap-2">
          <Widget type="2/3">
            <>
              <div className="flex flex-row gap-2 items-stretch h-full">
                {componentStatus === 'success' && componentData.componentType === ComponentType.BackApi && (
                  <>
                    <ApiWidget componentId={componentData.id} isSideMenuCollapsable />
                    <div className="absolute bottom-6 left-6">
                      <Button
                        width="fit"
                        height="sm"
                        content="Editer l'API"
                        iconName="plus"
                        rounded="rounded-full"
                        onClick={handleClickEditApi}
                        tooltip={"Se rendre sur la page d'édition de l'API"}
                      />
                    </div>
                  </>
                )}
                {componentStatus === 'success' && componentData.componentType === ComponentType.Front && (
                  <>
                    <PagesWidget componentId={componentData.id} />
                    <div className="absolute bottom-6 left-6">
                      <Button
                        width="fit"
                        height="sm"
                        content="Ajouter"
                        iconName="plus"
                        rounded="rounded-full"
                        onClick={() => {
                          setIsAddPageModalOpened(true);
                        }}
                        tooltip={'Créer une nouvelle page'}
                      />
                    </div>
                  </>
                )}
              </div>
            </>
          </Widget>
          <Widget type="1/3">{renderCockpitCards(componentStatus, componentData)}</Widget>
        </div>
      </PageProvider>
    </BasePage>
  );
}
