/* tslint:disable */
/* eslint-disable */
/**
 * Specks API
 * APIs for specks SaaS
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ApiBodyParameter } from './ApiBodyParameter';
import {
    ApiBodyParameterFromJSON,
    ApiBodyParameterFromJSONTyped,
    ApiBodyParameterToJSON,
} from './ApiBodyParameter';
import type { ApiHeaderParameter } from './ApiHeaderParameter';
import {
    ApiHeaderParameterFromJSON,
    ApiHeaderParameterFromJSONTyped,
    ApiHeaderParameterToJSON,
} from './ApiHeaderParameter';
import type { ApiPathParameter } from './ApiPathParameter';
import {
    ApiPathParameterFromJSON,
    ApiPathParameterFromJSONTyped,
    ApiPathParameterToJSON,
} from './ApiPathParameter';
import type { ApiQueryParameter } from './ApiQueryParameter';
import {
    ApiQueryParameterFromJSON,
    ApiQueryParameterFromJSONTyped,
    ApiQueryParameterToJSON,
} from './ApiQueryParameter';
import type { Link } from './Link';
import {
    LinkFromJSON,
    LinkFromJSONTyped,
    LinkToJSON,
} from './Link';
import type { Method } from './Method';
import {
    MethodFromJSON,
    MethodFromJSONTyped,
    MethodToJSON,
} from './Method';
import type { ModelApiResponse } from './ModelApiResponse';
import {
    ModelApiResponseFromJSON,
    ModelApiResponseFromJSONTyped,
    ModelApiResponseToJSON,
} from './ModelApiResponse';

/**
 * 
 * @export
 * @interface MethodRevision
 */
export interface MethodRevision {
    /**
     * 
     * @type {string}
     * @memberof MethodRevision
     */
    id: string;
    /**
     * 
     * @type {Date}
     * @memberof MethodRevision
     */
    createdAt: Date;
    /**
     * 
     * @type {boolean}
     * @memberof MethodRevision
     */
    latest: boolean;
    /**
     * 
     * @type {string}
     * @memberof MethodRevision
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof MethodRevision
     */
    description: string;
    /**
     * 
     * @type {string}
     * @memberof MethodRevision
     */
    methodType: MethodRevisionMethodTypeEnum;
    /**
     * 
     * @type {Array<ApiPathParameter>}
     * @memberof MethodRevision
     */
    apiPathParameters: Array<ApiPathParameter>;
    /**
     * 
     * @type {Array<ApiQueryParameter>}
     * @memberof MethodRevision
     */
    apiQueryParameters: Array<ApiQueryParameter>;
    /**
     * 
     * @type {Array<ApiHeaderParameter>}
     * @memberof MethodRevision
     */
    apiHeaderParameters: Array<ApiHeaderParameter>;
    /**
     * 
     * @type {ApiBodyParameter}
     * @memberof MethodRevision
     */
    apiBodyParameter: ApiBodyParameter;
    /**
     * 
     * @type {Array<ModelApiResponse>}
     * @memberof MethodRevision
     */
    apiResponses: Array<ModelApiResponse>;
    /**
     * 
     * @type {Method}
     * @memberof MethodRevision
     */
    method: Method;
    /**
     * 
     * @type {Array<Link>}
     * @memberof MethodRevision
     */
    bearerLinks: Array<Link>;
}


/**
 * @export
 */
export const MethodRevisionMethodTypeEnum = {
    Get: 'GET',
    Post: 'POST',
    Put: 'PUT',
    Patch: 'PATCH',
    Delete: 'DELETE'
} as const;
export type MethodRevisionMethodTypeEnum = typeof MethodRevisionMethodTypeEnum[keyof typeof MethodRevisionMethodTypeEnum];


/**
 * Check if a given object implements the MethodRevision interface.
 */
export function instanceOfMethodRevision(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "createdAt" in value;
    isInstance = isInstance && "latest" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "description" in value;
    isInstance = isInstance && "methodType" in value;
    isInstance = isInstance && "apiPathParameters" in value;
    isInstance = isInstance && "apiQueryParameters" in value;
    isInstance = isInstance && "apiHeaderParameters" in value;
    isInstance = isInstance && "apiBodyParameter" in value;
    isInstance = isInstance && "apiResponses" in value;
    isInstance = isInstance && "method" in value;
    isInstance = isInstance && "bearerLinks" in value;

    return isInstance;
}

export function MethodRevisionFromJSON(json: any): MethodRevision {
    return MethodRevisionFromJSONTyped(json, false);
}

export function MethodRevisionFromJSONTyped(json: any, ignoreDiscriminator: boolean): MethodRevision {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'createdAt': (new Date(json['createdAt'])),
        'latest': json['latest'],
        'name': json['name'],
        'description': json['description'],
        'methodType': json['methodType'],
        'apiPathParameters': ((json['apiPathParameters'] as Array<any>).map(ApiPathParameterFromJSON)),
        'apiQueryParameters': ((json['apiQueryParameters'] as Array<any>).map(ApiQueryParameterFromJSON)),
        'apiHeaderParameters': ((json['apiHeaderParameters'] as Array<any>).map(ApiHeaderParameterFromJSON)),
        'apiBodyParameter': ApiBodyParameterFromJSON(json['apiBodyParameter']),
        'apiResponses': ((json['apiResponses'] as Array<any>).map(ModelApiResponseFromJSON)),
        'method': MethodFromJSON(json['method']),
        'bearerLinks': ((json['bearerLinks'] as Array<any>).map(LinkFromJSON)),
    };
}

export function MethodRevisionToJSON(value?: MethodRevision | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'createdAt': (value.createdAt.toISOString()),
        'latest': value.latest,
        'name': value.name,
        'description': value.description,
        'methodType': value.methodType,
        'apiPathParameters': ((value.apiPathParameters as Array<any>).map(ApiPathParameterToJSON)),
        'apiQueryParameters': ((value.apiQueryParameters as Array<any>).map(ApiQueryParameterToJSON)),
        'apiHeaderParameters': ((value.apiHeaderParameters as Array<any>).map(ApiHeaderParameterToJSON)),
        'apiBodyParameter': ApiBodyParameterToJSON(value.apiBodyParameter),
        'apiResponses': ((value.apiResponses as Array<any>).map(ModelApiResponseToJSON)),
        'method': MethodToJSON(value.method),
        'bearerLinks': ((value.bearerLinks as Array<any>).map(LinkToJSON)),
    };
}

