import React, { Fragment } from 'react';
import uuid from 'react-uuid';

import { Menu, MenuButtonProps, Transition } from '@headlessui/react';

import { Settings } from 'components/core/Settings/Settings';
import { type ButtonMenuProps } from 'components/core/Settings/Settings.types';
import { Text } from 'components/core/Text/Text';

import concatClassNames from 'utils/classNames';
import getIcon from 'utils/getIcon';

interface ContextMenuProps {
  x: number;
  y: number;
  buttonsMenu: ButtonMenuProps[];
}

export function ContextMenu({ x, y, buttonsMenu }: ContextMenuProps): JSX.Element {
  return (
    <div
      style={{ top: `${y}px`, left: `${x}px` }}
      id="app-schema-context-menu"
      className="absolute w-fit h-fit flex flex-col gap-2 justify-center items-center p-0"
    >
      <div className="mt-2 divide-y divide-gray-25 rounded-md bg-white shadow-lg w-fit z-10">
        {buttonsMenu.map((buttonMenu, index) => (
          <div key={uuid()}>
            <button
              className={concatClassNames(
                'text-gray-900 hover:bg-branding-25',
                'group flex w-full items-center px-3.5 py-2 text-sm',
                index === 0 ? 'rounded-t-md' : '',
                index === buttonsMenu.length - 1 ? 'rounded-b-md' : '',
                'gap-2 items-end',
              )}
              onClick={buttonMenu.onClick}
              disabled={!(buttonMenu.isEnabled ?? true)}
            >
              <Text
                content={buttonMenu.name}
                color={buttonMenu.isEnabled ?? true ? buttonMenu.textColor : 'gray-200'}
                size="sm"
                position="left"
                weight="medium"
                whitespace="nowrap"
              />
            </button>
          </div>
        ))}
      </div>
    </div>
  );
}
