import { SpecksApisContext } from 'App';
import { type GetAssetDto } from 'api';

import React, { useContext, useState } from 'react';
import { type QueryClient, useMutation, useQuery, useQueryClient } from 'react-query';
import { type NavigateFunction, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import uuid from 'react-uuid';

import { useAuth0 } from '@auth0/auth0-react';

import { BasePage } from 'pages/core/BasePage/BasePage';

import { CreateAssetForm } from 'forms/CreateAssetForm/CreateAssetForm';

import { Button } from 'components/core/Button/Button';
import { CardContainer } from 'components/core/CardContainer/CardContainer';
import { type BreadCrumbsProps, Header } from 'components/core/Header/Header';
import { Modal } from 'components/core/Modal/Modal';
import { Section } from 'components/core/Section/Section';
import { Text } from 'components/core/Text/Text';
import { Title } from 'components/core/Title/Title';
import { AssetCard } from 'components/specks/AssetCard/AssetCard';
import { Empty } from 'components/specks/Empty/Empty';
import { NetworkError } from 'components/specks/Error/Error';
import { UserAvatar } from 'components/specks/UserAvatar/UserAvatar';

import { type Asset } from 'models/asset.entity';
import { type Feature } from 'models/feature.entity';
import { type Product } from 'models/product.entity';

interface FeaturePageParams {
  productId?: string;
  featureId?: string;
}

export function FeaturePage(): JSX.Element {
  /* --------------------------------------------------- contexts --------------------------------------------------- */

  const { featureService, productService, assetService } = useContext(SpecksApisContext);
  const { productId = '', featureId = '' }: FeaturePageParams = useParams();
  const navigate: NavigateFunction = useNavigate();
  const queryClient: QueryClient = useQueryClient();
  const { getAccessTokenSilently, user } = useAuth0();

  /* ---------------------------------------------------- states --------------------------------------------------- */

  const [isModalOpened, setIsModalOpened] = React.useState<boolean>(false);
  const [selectedAssetId, setSelectedAssetId] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isDeleteAssetModalOpened, setIsDeleteAssetModalOpened] = useState<boolean>(false);

  /* ---------------------------------------------------- Queries --------------------------------------------------- */

  const { data: featureData, status: featureStatus } = useQuery<Feature, Error>(['feature', featureId], async () => {
    const accessToken: string = await getAccessTokenSilently();
    return await featureService.findById(featureId, accessToken);
  });

  const { data: productData, status: productStatus } = useQuery<Product, Error>(['product', productId], async () => {
    const accessToken: string = await getAccessTokenSilently();
    return await productService.findById(productId, accessToken);
  });

  const { data: assetsData, status: assetsStatus } = useQuery<Asset[], Error>(['assets', featureId], async () => {
    const accessToken: string = await getAccessTokenSilently();
    return await featureService.getFeatureAssets(featureId, accessToken);
  });

  /* --------------------------------------------------- functions -------------------------------------------------- */

  function handleClickAddAsset(): void {
    setIsModalOpened(true);
  }

  function handleClickBack(): void {
    navigate(`/product/${productId}/features`);
  }

  /* ------------------------------------------------- Create Asset ------------------------------------------------- */

  async function createAsset(externalLink: Asset): Promise<GetAssetDto> {
    const accessToken: string = await getAccessTokenSilently();
    return await featureService.createAsset(featureId, externalLink, accessToken);
  }

  const { mutate } = useMutation(createAsset, {
    onSuccess: (getAsset: GetAssetDto) => {
      toast.success('Le lien vers le document a été créé avec succès.');
      queryClient.setQueryData(['assets', featureId], (oldData: Asset[] | undefined) => {
        if (oldData === undefined) {
          return [];
        }
        oldData.push(getAsset);
        return oldData;
      });
      setIsModalOpened(false);
    },
    onError: () => {
      toast.error('Une erreur est survenue lors de la création du lien vers le document.');
    },
  });

  async function handleAssetsFormSubmission(externalLink: Asset): Promise<void> {
    try {
      mutate(externalLink);
    } catch (error) {
      console.error(error);
    }
  }

  /* ------------------------------------------------- Delete Asset ------------------------------------------------- */

  async function deleteAsset(): Promise<void> {
    const accessToken: string = await getAccessTokenSilently();
    await assetService.deleteAsset(selectedAssetId, accessToken);
  }

  const { mutate: mutateDeleteAsset } = useMutation(deleteAsset, {
    onSuccess: () => {
      queryClient.setQueryData(['assets', featureId], (oldData: Asset[] | undefined) => {
        if (oldData === undefined) {
          return [];
        } else {
          oldData = oldData.filter((asset) => asset.id !== selectedAssetId);
          return oldData;
        }
      });
      toast.success('Le lien vers ce document a bien été supprimé');
    },
    onError: () => {
      toast.error('Erreur lors de la suppression du lien vers le document.');
    },
    onSettled: () => {
      setIsDeleteAssetModalOpened(false);
      setIsLoading(false);
    },
  });

  function handleAssetDeletionClick(assetId: string): void {
    setSelectedAssetId(assetId);
    setIsDeleteAssetModalOpened(true);
  }

  function handleOnSubmitDeleteAsset(event: any): void {
    event.preventDefault();
    setIsLoading(true);
    setTimeout(() => {
      mutateDeleteAsset();
    }, 1);
  }

  /* ------------------------------------------------------ JSX ----------------------------------------------------- */

  const breadCrumbs: BreadCrumbsProps = {
    paths: [
      {
        name: 'Produits',
        href: '/products',
      },
      {
        name: productData?.name ?? '',
        href: `/product/${productData?.id ?? ''}`,
      },
      {
        name: 'Fonctionnalités',
        href: `/product/${productData?.id ?? ''}/features`,
      },
    ],
    current: featureData?.name ?? '',
  };

  return (
    <BasePage>
      <Modal title="Ajouter un lien vers un document" isOpen={isModalOpened} setIsOpen={setIsModalOpened}>
        <CreateAssetForm onSubmit={handleAssetsFormSubmission} />
      </Modal>
      <Modal title="Attention" isOpen={isDeleteAssetModalOpened} setIsOpen={setIsDeleteAssetModalOpened}>
        <>
          <Text
            whitespace="pre-line"
            position="justify"
            content="Vous allez supprimer le lien vers ce document. Êtes-vous certain(e) de vouloir poursuivre ?"
          />
          <div className="flex flex-row gap-2 justify-end">
            {!isLoading && (
              <Button onClick={handleOnSubmitDeleteAsset} content="Supprimer" width="1/2" type="submit" height="sm" />
            )}
            {isLoading && <Button iconName="spinCircle" width="1/2" height="sm" iconAnimation="spin" />}
          </div>
        </>
      </Modal>
      <Header
        breadCrumbs={breadCrumbs}
        queryStatus={productStatus === 'success' && featureStatus === 'success' ? 'success' : 'loading'}
        handleClickBack={handleClickBack}
        title={featureStatus === 'success' ? `Fonctionnalité : ${featureData?.name}` : featureData?.name}
        status={featureData?.status}
      />
      <Section isFullHeight>
        <div className="flex flex-row justify-between items-center">
          <Title content="Boîte à outils" />
          <Button
            content="Ajouter un lien vers un document"
            width="fit"
            height="sm"
            onClick={handleClickAddAsset}
            iconName="plus"
            disabled={assetsStatus !== 'success'}
          />
        </div>
        <>
          {assetsStatus === 'error' && <NetworkError message="Une erreur est survenue lors du chargement des liens." />}
          {assetsStatus === 'loading' && (
            <CardContainer cardSize="sm">
              <AssetCard loading />
              <AssetCard loading />
              <AssetCard loading />
              <AssetCard loading />
            </CardContainer>
          )}
          {assetsStatus === 'success' &&
            (assetsData.length === 0 ? (
              <Empty
                icon="image"
                title="Votre boîte à outils est vide!"
                subtitle="Organisez votre travail en centralisant ici les éléments qui précisent le besoin : personae, parcours utilisateurs, exemples de l'existant, indicateurs de succès, etc."
              >
                <Button
                  content="Ajouter un lien vers un document"
                  iconName="plus"
                  width="fit"
                  height="sm"
                  onClick={handleClickAddAsset}
                />
              </Empty>
            ) : (
              <CardContainer cardSize="sm">
                {assetsData.map((asset) => (
                  <AssetCard
                    id={asset.id}
                    key={uuid()}
                    openInNewTab
                    href={asset.url}
                    icon={'externalLink'}
                    name={asset.name}
                    date={asset.createdAt}
                    onDeletionClick={handleAssetDeletionClick}
                  />
                ))}
              </CardContainer>
            ))}
        </>
      </Section>
    </BasePage>
  );
}
