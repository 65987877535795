/* tslint:disable */
/* eslint-disable */
/**
 * Specks API
 * APIs for specks SaaS
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Component } from './Component';
import {
    ComponentFromJSON,
    ComponentFromJSONTyped,
    ComponentToJSON,
} from './Component';
import type { MethodRevision } from './MethodRevision';
import {
    MethodRevisionFromJSON,
    MethodRevisionFromJSONTyped,
    MethodRevisionToJSON,
} from './MethodRevision';
import type { Product } from './Product';
import {
    ProductFromJSON,
    ProductFromJSONTyped,
    ProductToJSON,
} from './Product';
import type { UiComponentRevision } from './UiComponentRevision';
import {
    UiComponentRevisionFromJSON,
    UiComponentRevisionFromJSONTyped,
    UiComponentRevisionToJSON,
} from './UiComponentRevision';

/**
 * 
 * @export
 * @interface Link
 */
export interface Link {
    /**
     * 
     * @type {string}
     * @memberof Link
     */
    id: string;
    /**
     * 
     * @type {UiComponentRevision}
     * @memberof Link
     */
    bearerForCaller: UiComponentRevision;
    /**
     * 
     * @type {MethodRevision}
     * @memberof Link
     */
    bearerForReceiver: MethodRevision;
    /**
     * 
     * @type {Component}
     * @memberof Link
     */
    caller: Component;
    /**
     * 
     * @type {Component}
     * @memberof Link
     */
    receiver: Component;
    /**
     * 
     * @type {Product}
     * @memberof Link
     */
    product: Product;
}

/**
 * Check if a given object implements the Link interface.
 */
export function instanceOfLink(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "bearerForCaller" in value;
    isInstance = isInstance && "bearerForReceiver" in value;
    isInstance = isInstance && "caller" in value;
    isInstance = isInstance && "receiver" in value;
    isInstance = isInstance && "product" in value;

    return isInstance;
}

export function LinkFromJSON(json: any): Link {
    return LinkFromJSONTyped(json, false);
}

export function LinkFromJSONTyped(json: any, ignoreDiscriminator: boolean): Link {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'bearerForCaller': UiComponentRevisionFromJSON(json['bearerForCaller']),
        'bearerForReceiver': MethodRevisionFromJSON(json['bearerForReceiver']),
        'caller': ComponentFromJSON(json['caller']),
        'receiver': ComponentFromJSON(json['receiver']),
        'product': ProductFromJSON(json['product']),
    };
}

export function LinkToJSON(value?: Link | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'bearerForCaller': UiComponentRevisionToJSON(value.bearerForCaller),
        'bearerForReceiver': MethodRevisionToJSON(value.bearerForReceiver),
        'caller': ComponentToJSON(value.caller),
        'receiver': ComponentToJSON(value.receiver),
        'product': ProductToJSON(value.product),
    };
}

