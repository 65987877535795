import React, { type PropsWithChildren } from 'react';
import { type NavigateFunction, useNavigate } from 'react-router-dom';

import { type AppState, Auth0Provider } from '@auth0/auth0-react';

interface Auth0ProviderWithNavigateProps {
  children: React.ReactNode;
}

export function Auth0ProviderWithNavigate({
  children,
}: PropsWithChildren<Auth0ProviderWithNavigateProps>): JSX.Element | null {
  const navigate: NavigateFunction = useNavigate();

  const domain: string = process.env.REACT_APP_OAUTH2_DOMAIN ?? '';
  const clientId: string = process.env.REACT_APP_OAUTH2_CLIENT_ID ?? '';
  const redirectUri: string = process.env.REACT_APP_OAUTH2_REDIRECT_URI ?? '';
  const audience: string = process.env.REACT_APP_OAUTH2_AUDIENCE ?? '';

  function onRedirectCallback(appState?: AppState): void {
    navigate(appState?.returnTo ?? window.location.pathname);
  }

  if (domain === '' || clientId === '' || redirectUri === '' || audience === '') {
    return null;
  }

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      authorizationParams={{
        audience,
        redirect_uri: redirectUri,
      }}
      onRedirectCallback={onRedirectCallback}
    >
      {children}
    </Auth0Provider>
  );
}
