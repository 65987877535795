/* tslint:disable */
/* eslint-disable */
/**
 * Specks API
 * APIs for specks SaaS
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { FrontSpecification } from './FrontSpecification';
import {
    FrontSpecificationFromJSON,
    FrontSpecificationFromJSONTyped,
    FrontSpecificationToJSON,
} from './FrontSpecification';
import type { SitemapNode } from './SitemapNode';
import {
    SitemapNodeFromJSON,
    SitemapNodeFromJSONTyped,
    SitemapNodeToJSON,
} from './SitemapNode';

/**
 * 
 * @export
 * @interface Sitemap
 */
export interface Sitemap {
    /**
     * 
     * @type {string}
     * @memberof Sitemap
     */
    id: string;
    /**
     * 
     * @type {Date}
     * @memberof Sitemap
     */
    createdAt: Date;
    /**
     * 
     * @type {Array<SitemapNode>}
     * @memberof Sitemap
     */
    sitemapNodes: Array<SitemapNode>;
    /**
     * 
     * @type {FrontSpecification}
     * @memberof Sitemap
     */
    frontSpecification: FrontSpecification;
}

/**
 * Check if a given object implements the Sitemap interface.
 */
export function instanceOfSitemap(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "createdAt" in value;
    isInstance = isInstance && "sitemapNodes" in value;
    isInstance = isInstance && "frontSpecification" in value;

    return isInstance;
}

export function SitemapFromJSON(json: any): Sitemap {
    return SitemapFromJSONTyped(json, false);
}

export function SitemapFromJSONTyped(json: any, ignoreDiscriminator: boolean): Sitemap {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'createdAt': (new Date(json['createdAt'])),
        'sitemapNodes': ((json['sitemapNodes'] as Array<any>).map(SitemapNodeFromJSON)),
        'frontSpecification': FrontSpecificationFromJSON(json['frontSpecification']),
    };
}

export function SitemapToJSON(value?: Sitemap | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'createdAt': (value.createdAt.toISOString()),
        'sitemapNodes': ((value.sitemapNodes as Array<any>).map(SitemapNodeToJSON)),
        'frontSpecification': FrontSpecificationToJSON(value.frontSpecification),
    };
}

