/* tslint:disable */
/* eslint-disable */
/**
 * Specks API
 * APIs for specks SaaS
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Feature } from './Feature';
import {
    FeatureFromJSON,
    FeatureFromJSONTyped,
    FeatureToJSON,
} from './Feature';
import type { Genesis } from './Genesis';
import {
    GenesisFromJSON,
    GenesisFromJSONTyped,
    GenesisToJSON,
} from './Genesis';

/**
 * 
 * @export
 * @interface Asset
 */
export interface Asset {
    /**
     * 
     * @type {string}
     * @memberof Asset
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof Asset
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof Asset
     */
    url: string;
    /**
     * 
     * @type {string}
     * @memberof Asset
     */
    img: string;
    /**
     * 
     * @type {Date}
     * @memberof Asset
     */
    createdAt: Date;
    /**
     * 
     * @type {Feature}
     * @memberof Asset
     */
    feature: Feature;
    /**
     * 
     * @type {Genesis}
     * @memberof Asset
     */
    genesis: Genesis;
}

/**
 * Check if a given object implements the Asset interface.
 */
export function instanceOfAsset(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "url" in value;
    isInstance = isInstance && "img" in value;
    isInstance = isInstance && "createdAt" in value;
    isInstance = isInstance && "feature" in value;
    isInstance = isInstance && "genesis" in value;

    return isInstance;
}

export function AssetFromJSON(json: any): Asset {
    return AssetFromJSONTyped(json, false);
}

export function AssetFromJSONTyped(json: any, ignoreDiscriminator: boolean): Asset {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'url': json['url'],
        'img': json['img'],
        'createdAt': (new Date(json['createdAt'])),
        'feature': FeatureFromJSON(json['feature']),
        'genesis': GenesisFromJSON(json['genesis']),
    };
}

export function AssetToJSON(value?: Asset | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'url': value.url,
        'img': value.img,
        'createdAt': (value.createdAt.toISOString()),
        'feature': FeatureToJSON(value.feature),
        'genesis': GenesisToJSON(value.genesis),
    };
}

