/* tslint:disable */
/* eslint-disable */
/**
 * Specks API
 * APIs for specks SaaS
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdateApiPathParameterDto
 */
export interface UpdateApiPathParameterDto {
    /**
     * 
     * @type {number}
     * @memberof UpdateApiPathParameterDto
     */
    index: number;
    /**
     * 
     * @type {string}
     * @memberof UpdateApiPathParameterDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateApiPathParameterDto
     */
    isMandatory: UpdateApiPathParameterDtoIsMandatoryEnum;
    /**
     * 
     * @type {string}
     * @memberof UpdateApiPathParameterDto
     */
    valueType: UpdateApiPathParameterDtoValueTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof UpdateApiPathParameterDto
     */
    defaultValue: string;
}


/**
 * @export
 */
export const UpdateApiPathParameterDtoIsMandatoryEnum = {
    True: 'true',
    False: 'false',
    Null: 'null'
} as const;
export type UpdateApiPathParameterDtoIsMandatoryEnum = typeof UpdateApiPathParameterDtoIsMandatoryEnum[keyof typeof UpdateApiPathParameterDtoIsMandatoryEnum];

/**
 * @export
 */
export const UpdateApiPathParameterDtoValueTypeEnum = {
    String: 'STRING',
    Number: 'NUMBER',
    Boolean: 'BOOLEAN',
    Object: 'OBJECT',
    Enum: 'ENUM',
    List: 'LIST',
    Date: 'DATE'
} as const;
export type UpdateApiPathParameterDtoValueTypeEnum = typeof UpdateApiPathParameterDtoValueTypeEnum[keyof typeof UpdateApiPathParameterDtoValueTypeEnum];


/**
 * Check if a given object implements the UpdateApiPathParameterDto interface.
 */
export function instanceOfUpdateApiPathParameterDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "index" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "isMandatory" in value;
    isInstance = isInstance && "valueType" in value;
    isInstance = isInstance && "defaultValue" in value;

    return isInstance;
}

export function UpdateApiPathParameterDtoFromJSON(json: any): UpdateApiPathParameterDto {
    return UpdateApiPathParameterDtoFromJSONTyped(json, false);
}

export function UpdateApiPathParameterDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateApiPathParameterDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'index': json['index'],
        'name': json['name'],
        'isMandatory': json['isMandatory'],
        'valueType': json['valueType'],
        'defaultValue': json['defaultValue'],
    };
}

export function UpdateApiPathParameterDtoToJSON(value?: UpdateApiPathParameterDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'index': value.index,
        'name': value.name,
        'isMandatory': value.isMandatory,
        'valueType': value.valueType,
        'defaultValue': value.defaultValue,
    };
}

