import React from 'react';

import { Tooltip } from '@mui/material';

import { Text } from 'components/core/Text/Text';

import concatClassNames from 'utils/classNames';
import getIcon from 'utils/getIcon';

import { PelletInformation } from '../Pellet/Pellet';

interface UICFieldProps {
  label?: string;
  information?: string;
  content?: string;
  description?: string;
  isComplete?: boolean;
  error?: string;
}

export default function UICFieldReadOnly({
  label,
  information,
  content,
  description,
  isComplete = true,
  error = '',
}: UICFieldProps): JSX.Element {
  return (
    <div className={concatClassNames('flex flex-col', 'gap-1', 'w-full')}>
      {(label !== undefined || information !== undefined || !isComplete) && (
        <div className={concatClassNames('flex flex-row', 'gap-2', 'items-center')}>
          {label !== undefined && <Text position="left" size="lg" color="gray-700" weight="medium" content={label} />}
          {information !== undefined && (
            <Tooltip title={information} placement="right">
              <div>{getIcon('information', 'gradient2-to', 'smd')}</div>
            </Tooltip>
          )}
          {!isComplete && <PelletInformation text={error} />}
        </div>
      )}

      {description !== undefined && (
        <Text content={description} color="gray-700" position="left" weight={'normal'} size={'sm'} />
      )}
      {content !== undefined && (
        <div className={concatClassNames('border-l-1 border-gradient2-to', 'pl-3.5', 'overflow-auto')}>
          <Text
            position="left"
            size="base"
            color="gradient2-to"
            weight="bold"
            content={content}
            textBreak="normal"
            overFlow="ellipsis"
          />
        </div>
      )}
    </div>
  );
}
