import { type Body } from './API/Body.entity';

export type ResponseType = 'SUCCESS' | 'ERROR';

export class ApiResponse {
  constructor() {
    this.apiResponseBody = [];
  }

  id: string;

  fonctionalId: string;

  statusCode: number;

  message: string;

  responseType: ResponseType;

  apiResponseBody: Body[];
}
