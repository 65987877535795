import React from 'react';

import concatClassNames from 'utils/classNames';

import { type TableBodyProps, flexTypeStyle, overflowStyle } from './Table.props';

export function TableBody({
  children,
  margin = 'my-1',
  overflow = 'auto',
  flexType = 'wrap',
}: TableBodyProps): JSX.Element {
  return (
    <ul
      className={concatClassNames(
        'flex flex-col',
        flexTypeStyle[flexType],
        'w-full gap-3',
        overflowStyle[overflow],
        margin,
      )}
    >
      {children}
    </ul>
  );
}
