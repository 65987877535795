import sampleImage from 'assets/sample-frame.png';

import React from 'react';

import { SubTitle } from 'components/core/SubTitle/SubTitle';
import { Title } from 'components/core/Title/Title';
import { LoginButton } from 'components/specks/LoginButton/LoginButton';

import concatClassNames from 'utils/classNames';

import Specks from 'icons/Specks';
import SpecksText from 'icons/SpecksText';

export function PublicPage(): JSX.Element {
  return (
    <div className={concatClassNames('h-screen', 'w-screen', 'flex')}>
      <div
        className={concatClassNames(
          'w-44',
          'h-20',
          'm-3',
          'absolute',
          'flex',
          'flex-row',
          'justify-between',
          'items-center',
        )}
      >
        <Specks color="black" size="xxl" />
        <SpecksText color="black" size="xxxl" />
      </div>
      <div className={concatClassNames('h-full', 'w-1/2', 'flex', 'flex-row', 'justify-center', 'items-center')}>
        <div className={concatClassNames('px-24', 'flex', 'flex-col', 'gap-4')}>
          <Title size="h1" content="Révélez le pouvoir d'une spécification efficace." />
          <SubTitle textSize="2xl" content="Un produit maîtrisé. Un langage commun. Du temps retrouvé." />
          <br />
          <LoginButton />
        </div>
      </div>
      <div
        className={concatClassNames(
          'w-1/2',
          'flex',
          'flex-row',
          'justify-end',
          'items-center',
          'bg-gradient-to-r from-gradient5-from to-gradient5-to',
        )}
      >
        <img className={concatClassNames('h-2/3', 'w-2/3', 'rounded-2xl')} src={sampleImage} alt="sample" />
      </div>
    </div>
  );
}
