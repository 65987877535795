/* tslint:disable */
/* eslint-disable */
/**
 * Specks API
 * APIs for specks SaaS
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ErrorBadRequest,
  GetMethodDetailsDto,
  PartialUpdateMethodDto,
  UpdateMethodDto,
} from '../models';
import {
    ErrorBadRequestFromJSON,
    ErrorBadRequestToJSON,
    GetMethodDetailsDtoFromJSON,
    GetMethodDetailsDtoToJSON,
    PartialUpdateMethodDtoFromJSON,
    PartialUpdateMethodDtoToJSON,
    UpdateMethodDtoFromJSON,
    UpdateMethodDtoToJSON,
} from '../models';

export interface MethodControllerDeleteByIdRequest {
    id: string;
}

export interface MethodControllerFindByIdRequest {
    id: string;
}

export interface MethodControllerPartialUpdateRequest {
    id: string;
    partialUpdateMethodDto: PartialUpdateMethodDto;
}

export interface MethodControllerUpdateRequest {
    id: string;
    updateMethodDto: UpdateMethodDto;
}

/**
 * 
 */
export class MethodsApi extends runtime.BaseAPI {

    /**
     */
    async methodControllerDeleteByIdRaw(requestParameters: MethodControllerDeleteByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling methodControllerDeleteById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/methods/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async methodControllerDeleteById(requestParameters: MethodControllerDeleteByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.methodControllerDeleteByIdRaw(requestParameters, initOverrides);
    }

    /**
     */
    async methodControllerFindByIdRaw(requestParameters: MethodControllerFindByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetMethodDetailsDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling methodControllerFindById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/methods/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetMethodDetailsDtoFromJSON(jsonValue));
    }

    /**
     */
    async methodControllerFindById(requestParameters: MethodControllerFindByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetMethodDetailsDto> {
        const response = await this.methodControllerFindByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async methodControllerPartialUpdateRaw(requestParameters: MethodControllerPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetMethodDetailsDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling methodControllerPartialUpdate.');
        }

        if (requestParameters.partialUpdateMethodDto === null || requestParameters.partialUpdateMethodDto === undefined) {
            throw new runtime.RequiredError('partialUpdateMethodDto','Required parameter requestParameters.partialUpdateMethodDto was null or undefined when calling methodControllerPartialUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/methods/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: PartialUpdateMethodDtoToJSON(requestParameters.partialUpdateMethodDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetMethodDetailsDtoFromJSON(jsonValue));
    }

    /**
     */
    async methodControllerPartialUpdate(requestParameters: MethodControllerPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetMethodDetailsDto> {
        const response = await this.methodControllerPartialUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async methodControllerUpdateRaw(requestParameters: MethodControllerUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetMethodDetailsDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling methodControllerUpdate.');
        }

        if (requestParameters.updateMethodDto === null || requestParameters.updateMethodDto === undefined) {
            throw new runtime.RequiredError('updateMethodDto','Required parameter requestParameters.updateMethodDto was null or undefined when calling methodControllerUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/methods/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateMethodDtoToJSON(requestParameters.updateMethodDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetMethodDetailsDtoFromJSON(jsonValue));
    }

    /**
     */
    async methodControllerUpdate(requestParameters: MethodControllerUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetMethodDetailsDto> {
        const response = await this.methodControllerUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
