/* tslint:disable */
/* eslint-disable */
/**
 * Specks API
 * APIs for specks SaaS
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  CreateAssetDto,
  ErrorBadRequest,
  GetAssetDto,
  GetAssetsDto,
  GetFeatureDto,
} from '../models';
import {
    CreateAssetDtoFromJSON,
    CreateAssetDtoToJSON,
    ErrorBadRequestFromJSON,
    ErrorBadRequestToJSON,
    GetAssetDtoFromJSON,
    GetAssetDtoToJSON,
    GetAssetsDtoFromJSON,
    GetAssetsDtoToJSON,
    GetFeatureDtoFromJSON,
    GetFeatureDtoToJSON,
} from '../models';

export interface FeatureControllerCreateAssetRequest {
    id: string;
    createAssetDto: CreateAssetDto;
}

export interface FeatureControllerDeleteRequest {
    id: string;
}

export interface FeatureControllerFindByIdRequest {
    id: string;
}

export interface FeatureControllerFindFeatureAssetsByIdRequest {
    id: string;
}

/**
 * 
 */
export class FeaturesApi extends runtime.BaseAPI {

    /**
     */
    async featureControllerCreateAssetRaw(requestParameters: FeatureControllerCreateAssetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetAssetDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling featureControllerCreateAsset.');
        }

        if (requestParameters.createAssetDto === null || requestParameters.createAssetDto === undefined) {
            throw new runtime.RequiredError('createAssetDto','Required parameter requestParameters.createAssetDto was null or undefined when calling featureControllerCreateAsset.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/features/{id}/assets`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateAssetDtoToJSON(requestParameters.createAssetDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetAssetDtoFromJSON(jsonValue));
    }

    /**
     */
    async featureControllerCreateAsset(requestParameters: FeatureControllerCreateAssetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetAssetDto> {
        const response = await this.featureControllerCreateAssetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete Feature by ID
     * Supprimer une fonctionnalité
     */
    async featureControllerDeleteRaw(requestParameters: FeatureControllerDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling featureControllerDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/features/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete Feature by ID
     * Supprimer une fonctionnalité
     */
    async featureControllerDelete(requestParameters: FeatureControllerDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.featureControllerDeleteRaw(requestParameters, initOverrides);
    }

    /**
     */
    async featureControllerFindByIdRaw(requestParameters: FeatureControllerFindByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetFeatureDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling featureControllerFindById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/features/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetFeatureDtoFromJSON(jsonValue));
    }

    /**
     */
    async featureControllerFindById(requestParameters: FeatureControllerFindByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetFeatureDto> {
        const response = await this.featureControllerFindByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async featureControllerFindFeatureAssetsByIdRaw(requestParameters: FeatureControllerFindFeatureAssetsByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetAssetsDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling featureControllerFindFeatureAssetsById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/features/{id}/assets`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetAssetsDtoFromJSON(jsonValue));
    }

    /**
     */
    async featureControllerFindFeatureAssetsById(requestParameters: FeatureControllerFindFeatureAssetsByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetAssetsDto> {
        const response = await this.featureControllerFindFeatureAssetsByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
