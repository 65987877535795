/* tslint:disable */
/* eslint-disable */
/**
 * Specks API
 * APIs for specks SaaS
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { GetApiBodyParameterDto } from './GetApiBodyParameterDto';
import {
    GetApiBodyParameterDtoFromJSON,
    GetApiBodyParameterDtoFromJSONTyped,
    GetApiBodyParameterDtoToJSON,
} from './GetApiBodyParameterDto';
import type { GetApiHeaderParameterDto } from './GetApiHeaderParameterDto';
import {
    GetApiHeaderParameterDtoFromJSON,
    GetApiHeaderParameterDtoFromJSONTyped,
    GetApiHeaderParameterDtoToJSON,
} from './GetApiHeaderParameterDto';
import type { GetApiPathParameterDto } from './GetApiPathParameterDto';
import {
    GetApiPathParameterDtoFromJSON,
    GetApiPathParameterDtoFromJSONTyped,
    GetApiPathParameterDtoToJSON,
} from './GetApiPathParameterDto';
import type { GetApiQueryParameterDto } from './GetApiQueryParameterDto';
import {
    GetApiQueryParameterDtoFromJSON,
    GetApiQueryParameterDtoFromJSONTyped,
    GetApiQueryParameterDtoToJSON,
} from './GetApiQueryParameterDto';
import type { GetApiResponseDto } from './GetApiResponseDto';
import {
    GetApiResponseDtoFromJSON,
    GetApiResponseDtoFromJSONTyped,
    GetApiResponseDtoToJSON,
} from './GetApiResponseDto';

/**
 * 
 * @export
 * @interface GetMethodDetailsDto
 */
export interface GetMethodDetailsDto {
    /**
     * 
     * @type {string}
     * @memberof GetMethodDetailsDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof GetMethodDetailsDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof GetMethodDetailsDto
     */
    description: string;
    /**
     * 
     * @type {string}
     * @memberof GetMethodDetailsDto
     */
    methodType: GetMethodDetailsDtoMethodTypeEnum;
    /**
     * 
     * @type {boolean}
     * @memberof GetMethodDetailsDto
     */
    latest: boolean;
    /**
     * 
     * @type {Array<GetApiPathParameterDto>}
     * @memberof GetMethodDetailsDto
     */
    apiPathParameters: Array<GetApiPathParameterDto>;
    /**
     * 
     * @type {Array<GetApiQueryParameterDto>}
     * @memberof GetMethodDetailsDto
     */
    apiQueryParameters: Array<GetApiQueryParameterDto>;
    /**
     * 
     * @type {Array<GetApiHeaderParameterDto>}
     * @memberof GetMethodDetailsDto
     */
    apiHeaderParameters: Array<GetApiHeaderParameterDto>;
    /**
     * 
     * @type {GetApiBodyParameterDto}
     * @memberof GetMethodDetailsDto
     */
    apiBodyParameter: GetApiBodyParameterDto;
    /**
     * 
     * @type {Array<GetApiResponseDto>}
     * @memberof GetMethodDetailsDto
     */
    apiResponses: Array<GetApiResponseDto>;
}


/**
 * @export
 */
export const GetMethodDetailsDtoMethodTypeEnum = {
    Get: 'GET',
    Post: 'POST',
    Put: 'PUT',
    Patch: 'PATCH',
    Delete: 'DELETE'
} as const;
export type GetMethodDetailsDtoMethodTypeEnum = typeof GetMethodDetailsDtoMethodTypeEnum[keyof typeof GetMethodDetailsDtoMethodTypeEnum];


/**
 * Check if a given object implements the GetMethodDetailsDto interface.
 */
export function instanceOfGetMethodDetailsDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "description" in value;
    isInstance = isInstance && "methodType" in value;
    isInstance = isInstance && "latest" in value;
    isInstance = isInstance && "apiPathParameters" in value;
    isInstance = isInstance && "apiQueryParameters" in value;
    isInstance = isInstance && "apiHeaderParameters" in value;
    isInstance = isInstance && "apiBodyParameter" in value;
    isInstance = isInstance && "apiResponses" in value;

    return isInstance;
}

export function GetMethodDetailsDtoFromJSON(json: any): GetMethodDetailsDto {
    return GetMethodDetailsDtoFromJSONTyped(json, false);
}

export function GetMethodDetailsDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetMethodDetailsDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'description': json['description'],
        'methodType': json['methodType'],
        'latest': json['latest'],
        'apiPathParameters': ((json['apiPathParameters'] as Array<any>).map(GetApiPathParameterDtoFromJSON)),
        'apiQueryParameters': ((json['apiQueryParameters'] as Array<any>).map(GetApiQueryParameterDtoFromJSON)),
        'apiHeaderParameters': ((json['apiHeaderParameters'] as Array<any>).map(GetApiHeaderParameterDtoFromJSON)),
        'apiBodyParameter': GetApiBodyParameterDtoFromJSON(json['apiBodyParameter']),
        'apiResponses': ((json['apiResponses'] as Array<any>).map(GetApiResponseDtoFromJSON)),
    };
}

export function GetMethodDetailsDtoToJSON(value?: GetMethodDetailsDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'description': value.description,
        'methodType': value.methodType,
        'latest': value.latest,
        'apiPathParameters': ((value.apiPathParameters as Array<any>).map(GetApiPathParameterDtoToJSON)),
        'apiQueryParameters': ((value.apiQueryParameters as Array<any>).map(GetApiQueryParameterDtoToJSON)),
        'apiHeaderParameters': ((value.apiHeaderParameters as Array<any>).map(GetApiHeaderParameterDtoToJSON)),
        'apiBodyParameter': GetApiBodyParameterDtoToJSON(value.apiBodyParameter),
        'apiResponses': ((value.apiResponses as Array<any>).map(GetApiResponseDtoToJSON)),
    };
}

