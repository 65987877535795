import React from 'react';

import { Tooltip } from '@mui/material';

import concatClassNames from 'utils/classNames';

import { cursorStyle } from '../Core.style';
import {
  type CardProps,
  cardBackgroundColorStyle,
  cardBorderColorStyle,
  cardDeepnessStyle,
  cardHeightStyle,
  cardHorizontalMarginStyle,
  cardHorizontalPaddingStyle,
  cardOrientationStyle,
  cardRoundStyle,
  cardShadowStyle,
  cardVerticalMarginStyle,
  cardVerticalPaddingStyle,
  cardWidthStyle,
} from './Card.props';

export function Card({
  _identifier = '',
  width,
  height,
  shadow = 'none',
  background = 'white',
  borderColor = 'lightGray',
  children,
  onClick,
  onRightClick,
  loading,
  customRef,
  orientation = 'vertical',
  verticalPadding = 'none',
  horizontalPadding = 'none',
  vertialMargin = 'none',
  horizontalMargin = 'none',
  round = '3xl',
  deepness = 'none',
  cursor = 'auto',
  hasAnimation = false,
  tooltip,
  alignItems = 'items-center',
}: CardProps): JSX.Element {
  /* ------------------------------------------------------ JSX ----------------------------------------------------- */

  function handleClick(e: any): void {
    e.stopPropagation();
    if (onClick !== undefined) onClick();
  }

  // TODO : remove the 'xxx !== undefined'
  return (
    <Tooltip title={tooltip} followCursor placement="bottom-start" enterDelay={800}>
      <div
        onClick={handleClick}
        onContextMenu={(e) => {
          e.preventDefault();
          onRightClick?.(e);
        }}
        className={concatClassNames(
          'flex',
          cardOrientationStyle[orientation],
          alignItems,
          cardVerticalPaddingStyle[verticalPadding],
          cardHorizontalPaddingStyle[horizontalPadding],
          cursorStyle[cursor],
          cardRoundStyle[round],
          'border-1',
          cardBorderColorStyle[borderColor],
          cardShadowStyle[shadow],
          width !== undefined ? cardWidthStyle[width] : '',
          height !== undefined ? cardHeightStyle[height] : '',
          cardBackgroundColorStyle[background],
          cardVerticalMarginStyle[vertialMargin],
          cardHorizontalMarginStyle[horizontalMargin],
          cardDeepnessStyle[deepness],
          hasAnimation ? 'hover:-translate-y-3 duration-700 ease-in-out' : '',
          'relative',
        )}
        ref={customRef}
      >
        {children}
      </div>
    </Tooltip>
  );
}
