/* tslint:disable */
/* eslint-disable */
/**
 * Specks API
 * APIs for specks SaaS
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GetAppSchemaRevisionInformationsDto
 */
export interface GetAppSchemaRevisionInformationsDto {
    /**
     * 
     * @type {string}
     * @memberof GetAppSchemaRevisionInformationsDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof GetAppSchemaRevisionInformationsDto
     */
    revision: string;
    /**
     * 
     * @type {Date}
     * @memberof GetAppSchemaRevisionInformationsDto
     */
    createdAt: Date;
}

/**
 * Check if a given object implements the GetAppSchemaRevisionInformationsDto interface.
 */
export function instanceOfGetAppSchemaRevisionInformationsDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "revision" in value;
    isInstance = isInstance && "createdAt" in value;

    return isInstance;
}

export function GetAppSchemaRevisionInformationsDtoFromJSON(json: any): GetAppSchemaRevisionInformationsDto {
    return GetAppSchemaRevisionInformationsDtoFromJSONTyped(json, false);
}

export function GetAppSchemaRevisionInformationsDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetAppSchemaRevisionInformationsDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'revision': json['revision'],
        'createdAt': (new Date(json['createdAt'])),
    };
}

export function GetAppSchemaRevisionInformationsDtoToJSON(value?: GetAppSchemaRevisionInformationsDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'revision': value.revision,
        'createdAt': (value.createdAt.toISOString()),
    };
}

