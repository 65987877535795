import React, { forwardRef, useState } from 'react';

import concatClassNames from 'utils/classNames';
import getIcon from 'utils/getIcon';

interface SideMenuProps {
  children: JSX.Element | JSX.Element[];
  isOpen?: boolean;
  width?: SideMenuWidth;
  hidden?: boolean;
  absolute?: boolean;
  horizontalPosition?: SideMenuHorizontalPosition;
  isCollapsable?: boolean;
}

export type SideMenuWidth = 'sm' | 'smd' | 'md' | 'half';

export const sideMenuWidthStyle: Record<SideMenuWidth, string> = {
  sm: 'w-[300px]',
  smd: 'w-[400px]',
  md: 'w-[500px]',
  half: 'w-1/2',
};

export type SideMenuHorizontalPosition = 'left' | 'right';

export const sideMenuHorizontalPositionStyle: Record<SideMenuHorizontalPosition, string> = {
  left: '',
  right: 'right-0 top-0 bottom-0',
};
function SideMenu(
  {
    children,
    width = 'md',
    hidden = false,
    absolute = false,
    horizontalPosition = 'left',
    isCollapsable = false,
  }: SideMenuProps,
  ref: React.Ref<HTMLInputElement>,
): JSX.Element {
  const [isOpen, setIsOpen] = useState<boolean>(true);
  const [isHover, setIsHover] = useState<boolean>(false);

  return (
    <div
      id="SideMenu"
      className={concatClassNames(
        absolute ? 'absolute z-10' : '',
        absolute ? 'overflow-auto' : 'overflow-y-auto overflow-x-hidden',
        absolute ? sideMenuHorizontalPositionStyle[horizontalPosition] : '',
        'border-t-1 border-r-1 border-gray-50',
        hidden ? 'hidden' : 'flex flex-row',
        isCollapsable ? (isOpen ? 'animate-openSideMenu' : 'animate-closeSideMenu') : '',
        isOpen ? sideMenuWidthStyle[width] : 'delay-500 w-0',
        // 'w-full',
        // isOpen ? '' : 'translate-x-[-94%]',
      )}
    >
      <div
        ref={ref}
        className={concatClassNames(
          isOpen ? 'p-4' : '',
          'flex flex-col',
          'gap-4',
          'bg-white',
          // isOpen ? sideMenuWidthStyle[width] : 'delay-500 w-0',
          'w-full',
        )}
      >
        {children}
      </div>
      {isCollapsable && (
        <div
          className={concatClassNames('flex', 'h-full', 'w-3', 'bg-gray-50', 'items-center', 'cursor-pointer')}
          onClick={() => {
            setIsOpen(!isOpen);
          }}
          onMouseEnter={() => {
            setIsHover(true);
          }}
          onMouseLeave={() => {
            setIsHover(false);
          }}
        >
          <div className={concatClassNames('h-full', isHover ? 'bg-gray-50' : 'bg-white', 'w-0.5')}></div>
          <div
            className={concatClassNames(
              'absolute',
              isHover ? '-translate-x-6' : '-translate-x-4',
              'p-0.5 py-2 pl-6',
              'top-9',
              'bg-gray-50',
              'rounded-md',
              'scale-y-125',
              isOpen ? 'rotate-180' : '',
              'cursor-pointer',
              // 'border-1 border-gray-500',
            )}
          >
            {getIcon('angleRight', 'gray-500', 'sm')}
          </div>
        </div>
      )}
    </div>
  );
}

export default forwardRef<HTMLInputElement, SideMenuProps>(SideMenu);
