import { SpecksApisContext, TutorialContext } from 'App';

import React, { useContext, useEffect, useState } from 'react';
import { type QueryClient, useMutation, useQuery, useQueryClient } from 'react-query';
import { type NavigateFunction, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { useAuth0 } from '@auth0/auth0-react';

import { BasePage } from 'pages/core/BasePage/BasePage';

import { CreateOrEditComponentForm } from 'forms/CreateOrEditComponentForm/CreateOrEditComponentForm';
import { CreateorEditProductForm } from 'forms/CreateorEditProductForm/CreateorEditProductForm';

import { Button } from 'components/core/Button/Button';
import { CardContainer } from 'components/core/CardContainer/CardContainer';
import { Modal } from 'components/core/Modal/Modal';
import { Section } from 'components/core/Section/Section';
import { type ButtonMenuProps } from 'components/core/Settings/Settings.types';
import { SubTitle } from 'components/core/SubTitle/SubTitle';
import { Table } from 'components/core/Table/Table';
import { TableBody } from 'components/core/Table/TableBody';
import { TabElement } from 'components/core/Tabs/TabElement';
import { TabList } from 'components/core/Tabs/TabList';
import { TabPanel } from 'components/core/Tabs/TabPanel';
import { TabPanels } from 'components/core/Tabs/TabPanels';
import { Tabs } from 'components/core/Tabs/Tabs';
import { Title } from 'components/core/Title/Title';
import { ComponentCard } from 'components/specks/ComponentCard/ComponentCard';
import { ComponentRow } from 'components/specks/ComponentRow/ComponentRow';
import { ContextMenu } from 'components/specks/ContextMenu/ContextMenu';
import { DeleteComponent } from 'components/specks/DeleteComponent/DeleteComponent';
import { DeleteProduct } from 'components/specks/DeleteProduct/DeleteProduct';
import { Empty } from 'components/specks/Empty/Empty';
import { NetworkError } from 'components/specks/Error/Error';
import { ProductCard } from 'components/specks/ProductCard/ProductCard';
import { ProductRow } from 'components/specks/ProductRow/ProductRow';

import { type Component } from 'models/component.entity';
import { type Product } from 'models/product.entity';

import concatClassNames from 'utils/classNames';
import getIcon from 'utils/getIcon';

export function HomePage(): JSX.Element {
  /* --------------------------------------------------- contexts --------------------------------------------------- */

  const navigate: NavigateFunction = useNavigate();
  const { productService, componentService } = useContext(SpecksApisContext);
  const { setProductId, setComponentId, setPageId, setUicId } = useContext(TutorialContext);
  const { getAccessTokenSilently, user } = useAuth0();
  const queryClient: QueryClient = useQueryClient();

  /* ---------------------------------------------------- states ---------------------------------------------------- */

  const [isProductModalOpened, setIsProductModalOpened] = useState<boolean>(false);
  const [isComponentModalOpened, setIsComponentModalOpened] = useState<boolean>(false);
  const [selectedIndexTab, setSelectedIndexTab] = useState(0);
  const [isCreateComponentLoading, setIsCreateComponentLoading] = useState<boolean>(false);
  const [isLoading] = useState<boolean>(false);

  const [componentToDelete, setComponentToDelete] = useState<string>();
  const [rightClickedComponent, setRightClickedComponent] = useState<string>();

  const [productToDelete, setProductToDelete] = useState<string>();
  const [rightClickedProduct, setRightClickedProduct] = useState<string>();

  const [points, setPoints] = useState({ x: 0, y: 0 });
  const [isCardView, setIsCardView] = useState<boolean>(false);

  /* ---------------------------------------------------- Queries --------------------------------------------------- */

  async function getTutorialIds(): Promise<string[]> {
    const accessToken: string = await getAccessTokenSilently();
    return await productService.getIdsTutorial(accessToken);
  }

  const { data: tutorialData, status: tutorialStatus } = useQuery<string[], Error>('tutorial', getTutorialIds);

  useEffect(() => {
    if (tutorialStatus === 'success') {
      if (tutorialData !== undefined) {
        if (tutorialData.length !== 4) {
          console.error('tutorialData should have a length of 4');
        } else {
          setProductId?.(tutorialData[0]);
          setComponentId?.(tutorialData[1]);
          setPageId?.(tutorialData[2]);
          setUicId?.(tutorialData[3]);
        }
      }
    }
  }, [tutorialData, tutorialStatus, setProductId, setComponentId, setPageId, setUicId]);

  async function getProducts(): Promise<Product[]> {
    const accessToken: string = await getAccessTokenSilently();
    return await productService.findAll(accessToken);
  }

  const { data: productData, status: productStatus } = useQuery<Product[], Error>('products', getProducts);

  async function getComponents(): Promise<Component[]> {
    const accessToken: string = await getAccessTokenSilently();
    return await componentService.findAll(accessToken);
  }

  const { data: componentData, status: componentStatus } = useQuery<Component[], Error>('components', getComponents);

  /* ----------------------------------------------------- Jimo ----------------------------------------------------- */

  // (window as any).jimo.push([
  //   'do',
  //   'identify',
  //   [
  //     user?.sub,
  //     () => {
  //       (window as any).jimo.push(['set', 'user:name', [user?.email]]);
  //     },
  //   ],
  // ]);

  /* --------------------------------------------------- functions -------------------------------------------------- */

  function handleClickProductEmptyState(): void {
    setIsProductModalOpened(true);
  }

  function handleClickComponentEmptyState(): void {
    setIsComponentModalOpened(true);
  }

  function handleClickOpenModalCreateProduct(): void {
    setIsProductModalOpened(true);
  }
  function handleClickOpenModalCreateComponent(): void {
    setIsComponentModalOpened(false);
    setIsComponentModalOpened(true);
  }

  function handleClickTab(index: number): void {
    setSelectedIndexTab(index);
  }

  /* ------------------------------------------------ Create Product ------------------------------------------------ */

  async function postProduct(product: Product): Promise<Product> {
    const token: string = await getAccessTokenSilently();
    return await productService.create(product, token);
  }

  const { mutate: productMutate } = useMutation(postProduct, {
    onSuccess: (product) => {
      toast.success('Le produit a été créé avec succès');
      navigate(`/product/${product.id}`);
    },
    onError: () => {
      toast.error('Une erreur est survenue lors de la création du produit');
    },
  });

  function handleProductFormSubmission(product: Product): void {
    try {
      productMutate(product);
    } catch (error) {
      console.error(error);
    }
  }

  /* ------------------------------------------------ Create Component ---------------------------------------------- */

  async function postComponent(component: Component): Promise<Component> {
    const accessToken: string = await getAccessTokenSilently();
    return await componentService.create(component, accessToken);
  }

  const { mutate: componentMutate } = useMutation(postComponent, {
    onSuccess: (component: Component) => {
      toast.success('Component créé avec succès');
      navigate(`/component/${component.id}`);
    },
    onError: () => {
      alert('there was an error');
    },
  });

  function handleComponentFormSubmission(component: Component): void {
    try {
      setIsCreateComponentLoading(true);
      componentMutate(component);
    } catch (error) {
      console.error(error);
    }
  }

  /* ----------------------------------------------- Delete Component ----------------------------------------------- */

  function componentDeletionOnSuccess(): void {
    queryClient.setQueryData('components', (oldData: Component[] | undefined) => {
      if (oldData === undefined) {
        return new Array<Component>();
      } else {
        oldData = oldData.filter((component: Component) => component.id !== componentToDelete);
        return oldData;
      }
    });
    setComponentToDelete(undefined);
  }

  const componentButtonsMenu: ButtonMenuProps[] = [
    {
      name: 'Supprimer ce composant',
      textColor: 'black',
      isEnabled: true,
      onClick: (event: any) => {
        event.stopPropagation();
        setComponentToDelete(rightClickedComponent);
      },
    },
  ];

  /* ------------------------------------------------ Delete Product ------------------------------------------------ */

  function productDeletionOnSuccess(): void {
    queryClient.setQueryData('products', (oldData: Product[] | undefined) => {
      if (oldData === undefined) {
        return new Array<Product>();
      } else {
        oldData = oldData.filter((product: Product) => product.id !== productToDelete);
        return oldData;
      }
    });
    setProductToDelete(undefined);
  }

  const productButtonsMenu: ButtonMenuProps[] = [
    {
      name: 'Supprimer ce produit',
      textColor: 'black',
      isEnabled: true,
      onClick: (event: any) => {
        event.stopPropagation();
        setProductToDelete(rightClickedProduct);
      },
    },
    // {
    //   name: 'Modifier ce produit',
    //   textColor: 'black',
    //   isEnabled: true,
    //   onClick: (event: any) => {
    //     event.stopPropagation();
    //     setProductToDelete(rightClickedProduct);
    //   },
    // },
  ];

  useEffect(() => {
    window.addEventListener('click', handleClick);
    return () => {
      window.removeEventListener('click', handleClick);
    };
  }, []);

  function handleClick(): void {
    setRightClickedComponent(undefined);
    setRightClickedProduct(undefined);
  }

  /* ------------------------------------------------------ JSX ----------------------------------------------------- */

  return (
    <BasePage>
      <DeleteComponent
        setComponentId={setComponentToDelete}
        componentId={componentToDelete}
        behaviourAfterDeletion={componentDeletionOnSuccess}
      />
      <DeleteProduct
        setProductId={setProductToDelete}
        productId={productToDelete}
        behaviourAfterDeletion={productDeletionOnSuccess}
      />
      <Modal
        title="Définissez les paramètres de votre produit"
        isOpen={isProductModalOpened}
        setIsOpen={setIsProductModalOpened}
      >
        <CreateorEditProductForm onSubmit={handleProductFormSubmission} isLoading={isLoading} />
      </Modal>
      <Modal
        title="Définissez les paramètres de votre composant"
        isOpen={isComponentModalOpened}
        setIsOpen={setIsComponentModalOpened}
      >
        <CreateOrEditComponentForm onSubmit={handleComponentFormSubmission} isLoading={isCreateComponentLoading} />
      </Modal>
      {/* <Header title="Accueil" /> */}
      <Section isFullHeight>
        <div className={concatClassNames('flex flex-row justify-between')}>
          <div className={concatClassNames('flex flex-col')}>
            <Title
              content={
                user?.name !== undefined
                  ? `Bienvenue ${user?.name?.slice(user?.name?.indexOf(']') + 2, user?.name?.length)}!`
                  : 'Bienvenue sur votre espace Specks!'
              }
            />
            <SubTitle content={'Retrouvez ici vos différents produits et composants applicatifs'} />
          </div>
        </div>
        <Tabs onChange={handleClickTab}>
          <TabList
            jsxElement={
              <>
                <div className="flex flex-row justify-end items-center gap-6 px-10">
                  <button
                    onClick={() => {
                      setIsCardView(false);
                    }}
                  >
                    {isCardView ? <>{getIcon('list', 'gray-100', 'lg')}</> : <>{getIcon('list', 'gray-500', 'lg')}</>}
                  </button>
                  <button
                    onClick={() => {
                      setIsCardView(true);
                    }}
                  >
                    {isCardView ? <>{getIcon('cards', 'gray-500', 'lg')}</> : <>{getIcon('cards', 'gray-100', 'lg')}</>}
                  </button>
                </div>
                <Button
                  content={selectedIndexTab === 0 ? 'Nouveau' : 'Nouveau'}
                  width="fit"
                  height="sm"
                  whitespace="pre"
                  onClick={
                    selectedIndexTab === 0 ? handleClickOpenModalCreateProduct : handleClickOpenModalCreateComponent
                  }
                  iconName="plus"
                />
              </>
            }
          >
            <TabElement title={'Produits'}></TabElement>
            <TabElement title={'Composants'}></TabElement>
          </TabList>
          <TabPanels>
            <TabPanel>
              {productStatus === 'error' && (
                <NetworkError message="Un problème est survenu lors de l'affichage des produits." />
              )}
              {productStatus === 'loading' && (
                <CardContainer>
                  <ProductCard loading />
                  <ProductCard loading />
                </CardContainer>
              )}
              {productStatus === 'success' && productData.length === 0 && (
                <Empty icon="product" title="Créez votre premier produit !">
                  <Button
                    content="Nouveau produit"
                    onClick={handleClickProductEmptyState}
                    iconName="plus"
                    height="sm"
                    width="fit"
                  />
                </Empty>
              )}
              {productStatus === 'success' && productData.length !== 0 && isCardView && (
                <CardContainer cardSize="sm">
                  {productData.map((product) => {
                    return (
                      <ProductCard
                        key={product.id}
                        id={product.id}
                        name={product.name}
                        status={product.status}
                        setPoints={setPoints}
                        setRightClickedProduct={setRightClickedProduct}
                        components={product.components}
                      ></ProductCard>
                    );
                  })}
                </CardContainer>
              )}
              {productStatus === 'success' && productData.length !== 0 && !isCardView && (
                <Table>
                  <TableBody overflow="hidden">
                    {productData.map((product) => {
                      return (
                        <ProductRow
                          key={product.id}
                          id={product.id}
                          name={product.name}
                          status={product.status}
                          components={product.components}
                        ></ProductRow>
                      );
                    })}
                  </TableBody>
                </Table>
              )}
            </TabPanel>
            <TabPanel>
              {componentStatus === 'error' && (
                <NetworkError message="Un problème est survenu lors de l'affichage de vos composants." />
              )}
              {componentStatus === 'loading' && (
                <CardContainer>
                  <ComponentCard loading />
                  <ComponentCard loading />
                </CardContainer>
              )}
              {componentStatus === 'success' && componentData.length === 0 && (
                <Empty icon="component" title="Créez votre premier composant!">
                  <Button
                    content="Nouveau composant applicatif"
                    onClick={handleClickComponentEmptyState}
                    iconName="plus"
                    height="sm"
                    width="fit"
                  />
                </Empty>
              )}
              {componentStatus === 'success' && componentData.length !== 0 && isCardView && (
                <CardContainer cardSize="sm">
                  {componentData.map((component) => {
                    return (
                      <ComponentCard
                        key={component.id}
                        id={component.id}
                        name={component.name}
                        status={component.status}
                        type={component.componentType}
                      />
                    );
                  })}
                </CardContainer>
              )}
              {componentStatus === 'success' && componentData.length !== 0 && !isCardView && (
                <Table>
                  <TableBody overflow="hidden">
                    {componentData.map((component) => {
                      return (
                        <ComponentRow
                          key={component.id}
                          id={component.id}
                          name={component.name}
                          status={component.status}
                          type={component.componentType}
                        />
                      );
                    })}
                  </TableBody>
                </Table>
              )}
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Section>
      <>
        {rightClickedComponent !== undefined && (
          <ContextMenu x={points?.x - 50} y={points?.y} buttonsMenu={componentButtonsMenu} />
        )}
        {rightClickedProduct !== undefined && (
          <ContextMenu x={points?.x - 50} y={points?.y} buttonsMenu={productButtonsMenu} />
        )}
      </>
    </BasePage>
  );
}
