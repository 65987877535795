/* tslint:disable */
/* eslint-disable */
/**
 * Specks API
 * APIs for specks SaaS
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CreateUiComponentTitleDto } from './CreateUiComponentTitleDto';
import {
    CreateUiComponentTitleDtoFromJSON,
    CreateUiComponentTitleDtoFromJSONTyped,
    CreateUiComponentTitleDtoToJSON,
} from './CreateUiComponentTitleDto';
import type { UpdateUicPositionDto } from './UpdateUicPositionDto';
import {
    UpdateUicPositionDtoFromJSON,
    UpdateUicPositionDtoFromJSONTyped,
    UpdateUicPositionDtoToJSON,
} from './UpdateUicPositionDto';

/**
 * 
 * @export
 * @interface CreateUicPositionTitleDto
 */
export interface CreateUicPositionTitleDto {
    /**
     * 
     * @type {CreateUiComponentTitleDto}
     * @memberof CreateUicPositionTitleDto
     */
    uiComponent: CreateUiComponentTitleDto;
    /**
     * 
     * @type {Array<UpdateUicPositionDto>}
     * @memberof CreateUicPositionTitleDto
     */
    positions: Array<UpdateUicPositionDto>;
}

/**
 * Check if a given object implements the CreateUicPositionTitleDto interface.
 */
export function instanceOfCreateUicPositionTitleDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "uiComponent" in value;
    isInstance = isInstance && "positions" in value;

    return isInstance;
}

export function CreateUicPositionTitleDtoFromJSON(json: any): CreateUicPositionTitleDto {
    return CreateUicPositionTitleDtoFromJSONTyped(json, false);
}

export function CreateUicPositionTitleDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateUicPositionTitleDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'uiComponent': CreateUiComponentTitleDtoFromJSON(json['uiComponent']),
        'positions': ((json['positions'] as Array<any>).map(UpdateUicPositionDtoFromJSON)),
    };
}

export function CreateUicPositionTitleDtoToJSON(value?: CreateUicPositionTitleDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'uiComponent': CreateUiComponentTitleDtoToJSON(value.uiComponent),
        'positions': ((value.positions as Array<any>).map(UpdateUicPositionDtoToJSON)),
    };
}

