/* eslint-disable max-len */
import React from 'react';

import concatClassNames from 'utils/classNames';

import { type IconProps, iconSizeStyle } from './Icon.props';

export default function Specks({ color, size }: IconProps): JSX.Element {
  return (
    <svg
      className={concatClassNames(iconSizeStyle[size])}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 75.09 68.84"
    >
      <defs>
        <linearGradient id="Dégradé_sans_nom_23" y1="44.52" x2="70.38" y2="44.52" gradientUnits="userSpaceOnUse">
          <stop offset="0" stopColor="#774098" />
          <stop offset="1" stopColor="#2e2f8d" />
        </linearGradient>
        <linearGradient
          id="Dégradé_sans_nom_2"
          x1="20.53"
          y1="44.49"
          x2="70.38"
          y2="44.49"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#feeae4" />
          <stop offset="1" stopColor="#fff" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="Dégradé_sans_nom_19"
          x1="12.32"
          y1="39.03"
          x2="64.13"
          y2="39.03"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#ea4d77" />
          <stop offset="1" stopColor="#ffc840" />
        </linearGradient>
        <linearGradient
          id="Dégradé_sans_nom_83"
          x1="4.71"
          y1="24.32"
          x2="75.09"
          y2="24.32"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#eb088a" />
          <stop offset="1" stopColor="#ffd20d" />
        </linearGradient>
        <linearGradient
          id="Dégradé_sans_nom_2-2"
          x1="22.5"
          y1="16.45"
          x2="75.09"
          y2="16.45"
          xlinkHref="#Dégradé_sans_nom_2"
        />
      </defs>
      <g style={{ isolation: 'isolate' }}>
        <g id="Layer_2" data-name="Layer 2">
          <g id="Calque_1" data-name="Calque 1">
            <path
              d="M6.09,68.69a6.19,6.19,0,0,1-4.8-10c9.15-11.89,18.49-23,29.45-32.86,7.54-6.74,20.88-7.36,28.32-2.4A25.33,25.33,0,0,1,70,49.11c-1.86,10.26-9.77,18.75-20.8,19.28C35,69.06,20.74,68.82,6.09,68.69Z"
              fill="url(#Dégradé_sans_nom_23)"
              style={{ mixBlendMode: 'multiply' }}
            />
            <path
              d="M70,49.11A25.33,25.33,0,0,0,59.06,23.47c-7.44-5-20.78-4.34-28.32,2.4q-3.93,3.52-7.6,7.26A26.12,26.12,0,0,0,37,68.76q6.12-.09,12.22-.37C60.2,67.86,68.11,59.37,70,49.11Z"
              opacity="0.1"
              fill="url(#Dégradé_sans_nom_2)"
            />
            <path
              d="M61.48,25.32A191.45,191.45,0,0,1,44.36,43c-7.54,6.74-20.88,7.36-28.33,2.4a26,26,0,0,1-2.41-1.84c-.43.5-.87,1-1.3,1.49a25.55,25.55,0,0,0,5.07,4.47c7.45,5,20.79,4.34,28.33-2.41A194.67,194.67,0,0,0,64.13,27.94,25.22,25.22,0,0,0,61.48,25.32Z"
              opacity="0.2"
              fill="url(#Dégradé_sans_nom_19)"
              style={{ mixBlendMode: 'color-burn' }}
            />
            <g style={{ mixBlendMode: 'multiply' }}>
              <path d="M30.4,48.53l-.34,0Z" fill="#6287c5" />
            </g>
            <path
              d="M69,.15a6.19,6.19,0,0,1,4.8,10C64.65,22,55.32,33.16,44.36,43c-7.54,6.74-20.88,7.36-28.33,2.4A25.33,25.33,0,0,1,5.12,19.73C7,9.47,14.89,1,25.92.45,40.12-.22,54.35,0,69,.15Z"
              fill="url(#Dégradé_sans_nom_83)"
              style={{ mixBlendMode: 'overlay' }}
            />
            <path
              d="M50.45,32.89A27.68,27.68,0,0,0,55,32.48c6.61-7,12.73-14.53,18.76-22.37A6.19,6.19,0,0,0,69,.15C54.35,0,40.11-.22,25.92.45a21.25,21.25,0,0,0-3.13.4A28.71,28.71,0,0,0,22.5,4.7,28.07,28.07,0,0,0,50.45,32.89Z"
              opacity="0.1"
              fill="url(#Dégradé_sans_nom_2-2)"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}
