/* tslint:disable */
/* eslint-disable */
/**
 * Specks API
 * APIs for specks SaaS
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GetUiComponentLightInfoDto
 */
export interface GetUiComponentLightInfoDto {
    /**
     * 
     * @type {string}
     * @memberof GetUiComponentLightInfoDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof GetUiComponentLightInfoDto
     */
    type: GetUiComponentLightInfoDtoTypeEnum;
    /**
     * 
     * @type {Date}
     * @memberof GetUiComponentLightInfoDto
     */
    createdAt: Date;
}


/**
 * @export
 */
export const GetUiComponentLightInfoDtoTypeEnum = {
    Title: 'title',
    Icon: 'icon',
    Text: 'text',
    Container: 'container',
    Input: 'input',
    Button: 'button',
    List: 'list',
    Media: 'media',
    Date: 'date',
    Select: 'select'
} as const;
export type GetUiComponentLightInfoDtoTypeEnum = typeof GetUiComponentLightInfoDtoTypeEnum[keyof typeof GetUiComponentLightInfoDtoTypeEnum];


/**
 * Check if a given object implements the GetUiComponentLightInfoDto interface.
 */
export function instanceOfGetUiComponentLightInfoDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "createdAt" in value;

    return isInstance;
}

export function GetUiComponentLightInfoDtoFromJSON(json: any): GetUiComponentLightInfoDto {
    return GetUiComponentLightInfoDtoFromJSONTyped(json, false);
}

export function GetUiComponentLightInfoDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetUiComponentLightInfoDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'type': json['type'],
        'createdAt': (new Date(json['createdAt'])),
    };
}

export function GetUiComponentLightInfoDtoToJSON(value?: GetUiComponentLightInfoDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'type': value.type,
        'createdAt': (value.createdAt.toISOString()),
    };
}

