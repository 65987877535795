import { type BodyDto, type GetMethodDetailsDto, type GetMethodDto } from 'api';

import { type InputTypeHeader } from 'forms/ApiHeaderParameterForm/ApiHeaderParameterForm';
import { type InputTypePath } from 'forms/ApiPathParameterForm/ApiPathParameterForm';
import { type InputTypeQuery } from 'forms/ApiQueryParameterForm/ApiQueryParameterForm';

import { type Body } from 'models/API/Body.entity';
import { type ResponseType } from 'models/ApiResponse.entity';
import { Method } from 'models/method.entity';
import { MethodRevision, type MethodType } from 'models/methodRevision.entity';

export function fromGetMethodDto(getMethodDto: GetMethodDto): Method {
  const method: Method = new Method();
  method.id = getMethodDto.id;
  method.methodRevisions = [];
  const methodRevision: MethodRevision = new MethodRevision();
  methodRevision.description = getMethodDto.description;
  methodRevision.methodType = getMethodDto.methodType as MethodType;
  methodRevision.name = getMethodDto.name;
  method.methodRevisions.push(methodRevision);
  return method;
}

function fromBodyDto(bodyDtos: BodyDto[]): Body[] {
  return bodyDtos.map((bodyDto) => ({
    id: bodyDto.id,
    name: bodyDto.name,
    defaultValue: bodyDto.defaultValue,
    valueType: bodyDto.valueType as InputTypeHeader,
    isMandatory: bodyDto.isMandatory,
    enum: bodyDto.enumDto,
    children: fromBodyDto(bodyDto.children),
  }));
}

export function fromGetMethodDetailsDto(getMethodDetailsDto: GetMethodDetailsDto): Method {
  const method: Method = new Method();
  method.id = getMethodDetailsDto.id;
  method.methodRevisions = [];
  const methodRevision: MethodRevision = new MethodRevision();
  methodRevision.description = getMethodDetailsDto.description;
  methodRevision.methodType = getMethodDetailsDto.methodType as MethodType;
  methodRevision.name = getMethodDetailsDto.name;
  methodRevision.apiPathParameters = [];
  getMethodDetailsDto.apiPathParameters.forEach((apiPathParameter) => {
    methodRevision.apiPathParameters.push({
      id: apiPathParameter.id,
      name: apiPathParameter.name,
      valueType: apiPathParameter.valueType as InputTypePath,
      isMandatory: apiPathParameter.isMandatory,
      defaultValue: apiPathParameter.defaultValue,
    });
  });
  methodRevision.apiQueryParameters = [];
  getMethodDetailsDto.apiQueryParameters.forEach((apiQueryParameter) => {
    methodRevision.apiQueryParameters.push({
      id: apiQueryParameter.id,
      name: apiQueryParameter.name,
      valueType: apiQueryParameter.valueType as InputTypeQuery,
      isMandatory: apiQueryParameter.isMandatory,
      defaultValue: apiQueryParameter.defaultValue,
    });
  });
  methodRevision.apiHeaderParameters = [];
  getMethodDetailsDto.apiHeaderParameters.forEach((apiHeaderParameter) => {
    methodRevision.apiHeaderParameters.push({
      id: apiHeaderParameter.id,
      name: apiHeaderParameter.name,
      valueType: apiHeaderParameter.valueType as InputTypeHeader,
      isMandatory: apiHeaderParameter.isMandatory,
      defaultValue: apiHeaderParameter.defaultValue,
    });
  });
  methodRevision.apiBodyParameter = [];

  methodRevision.apiBodyParameter = fromBodyDto(getMethodDetailsDto.apiBodyParameter.values);
  methodRevision.apiResponses = [];
  getMethodDetailsDto.apiResponses.forEach((apiResponse) => {
    methodRevision.apiResponses.push({
      id: apiResponse.id,
      fonctionalId: apiResponse.fonctionalId,
      responseType: apiResponse.responseType as ResponseType,
      statusCode: apiResponse.statusCode,
      message: apiResponse.message,
      apiResponseBody: fromBodyDto(apiResponse.apiResponseBody.values),
    });
  });
  method.methodRevisions.push(methodRevision);
  return method;
}
