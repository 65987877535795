/* tslint:disable */
/* eslint-disable */
/**
 * Specks API
 * APIs for specks SaaS
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ErrorBadRequest,
  GetPageRevisionDto,
  GetSubRevisionsDto,
} from '../models';
import {
    ErrorBadRequestFromJSON,
    ErrorBadRequestToJSON,
    GetPageRevisionDtoFromJSON,
    GetPageRevisionDtoToJSON,
    GetSubRevisionsDtoFromJSON,
    GetSubRevisionsDtoToJSON,
} from '../models';

export interface PageRevisionControllerFindByIdRequest {
    id: string;
}

export interface PageRevisionControllerFindRevisionsByIdRequest {
    id: string;
}

/**
 * 
 */
export class PageRevisionsApi extends runtime.BaseAPI {

    /**
     */
    async pageRevisionControllerFindByIdRaw(requestParameters: PageRevisionControllerFindByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetPageRevisionDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling pageRevisionControllerFindById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/page-revisions/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetPageRevisionDtoFromJSON(jsonValue));
    }

    /**
     */
    async pageRevisionControllerFindById(requestParameters: PageRevisionControllerFindByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetPageRevisionDto> {
        const response = await this.pageRevisionControllerFindByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async pageRevisionControllerFindRevisionsByIdRaw(requestParameters: PageRevisionControllerFindRevisionsByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetSubRevisionsDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling pageRevisionControllerFindRevisionsById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/page-revisions/pages/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetSubRevisionsDtoFromJSON(jsonValue));
    }

    /**
     */
    async pageRevisionControllerFindRevisionsById(requestParameters: PageRevisionControllerFindRevisionsByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetSubRevisionsDto> {
        const response = await this.pageRevisionControllerFindRevisionsByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
