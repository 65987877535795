import { type GetUiComponentRevisionDtoDateFormatEnum } from 'api';

import uuid from 'react-uuid';

import { type Option, type OptionsSource } from 'components/specks/SelectInfosForm/SelectInfosForm';
import { type UiComponentCardType } from 'components/specks/UiComponentCard/UiComponentCard.props';

import {
  type ConstantLogical,
  type DateFormat,
  TargetType,
  type TextImportancy,
  type TitleImportancy,
  UiComponentRevision,
  type YesLogical,
  type YesNoLogical,
} from './ui-component-revisions.entity';

export class UiComponent {
  constructor(
    type?: UiComponentCardType,
    name?: string,
    notes?: string,
    usedFor?: string,
    label?: string,
    id?: string,
    createdAt?: Date,
    targetType: TargetType = TargetType.NOTHING,
    targetFree?: string,
    targetMethodId?: string,
    contentType: ConstantLogical = 'CONSTANT',
    sourceConstant?: string,
    sourceLogicalRule?: string,
    sourceMethodId?: string,
    isVisible?: YesLogical,
    isVisibleLogicalRule?: string,
    titleImportancy?: TitleImportancy,
    isEditable?: YesNoLogical,
    isEditableLogicalRule?: string,
    isPreFilled?: YesNoLogical,
    isPreFilledConstant?: string,
    isPreFilledLogicalRule?: string,
    hasPlaceholder?: YesNoLogical,
    hasPlaceholderConstant?: string,
    hasPlaceholderLogicalRule?: string,
    isMandatory?: YesNoLogical,
    isMandatoryLogicalRule?: string,
    hasPattern?: YesNoLogical,
    hasPatternConstant?: string,
    hasPatternLogicalRule?: string,
    isMultiChoiceAllowed?: YesNoLogical,
    isMultiChoiceAllowedLogicalRule?: string,
    optionsSource?: OptionsSource,
    options?: Option[],
    parent?: string | undefined,
    index?: number,
    dateFormat?: GetUiComponentRevisionDtoDateFormatEnum,
    dateCustom?: string,
    textImportancy?: TextImportancy,
    isComplete?: boolean,
  ) {
    this.id = id;
    this.type = type ?? 'title';
    this.createdAt = createdAt ?? new Date();
    this.uiComponentRevisions = [];
    const newUiComponentRevision: UiComponentRevision = new UiComponentRevision();
    newUiComponentRevision.name = name ?? '';
    newUiComponentRevision.notes = notes ?? '';
    newUiComponentRevision.usedFor = usedFor ?? '';
    newUiComponentRevision.label = label ?? '';
    newUiComponentRevision.targetType = targetType;
    newUiComponentRevision.targetFree = targetFree ?? '';
    newUiComponentRevision.targetMethodId = targetMethodId;
    newUiComponentRevision.sourceMethodId = sourceMethodId;
    newUiComponentRevision.contentType = contentType;
    newUiComponentRevision.sourceConstant = sourceConstant ?? '';
    newUiComponentRevision.sourceLogicalRule = sourceLogicalRule ?? '';
    newUiComponentRevision.isVisible = isVisible ?? 'YES';
    newUiComponentRevision.isVisibleLogicalRule = isVisibleLogicalRule ?? '';
    newUiComponentRevision.titleImportancy = titleImportancy ?? 'UNDEFINED';
    newUiComponentRevision.isEditable = isEditable ?? 'YES';
    newUiComponentRevision.isEditableLogicalRule = isEditableLogicalRule ?? '';
    newUiComponentRevision.isPreFilled = isPreFilled ?? 'NO';
    newUiComponentRevision.isPreFilledConstant = isPreFilledConstant ?? '';
    newUiComponentRevision.isPreFilledLogicalRule = isPreFilledLogicalRule ?? '';
    newUiComponentRevision.hasPlaceholder = hasPlaceholder ?? 'NO';
    newUiComponentRevision.hasPlaceholderConstant = hasPlaceholderConstant ?? '';
    newUiComponentRevision.hasPlaceholderLogicalRule = hasPlaceholderLogicalRule ?? '';
    newUiComponentRevision.isMandatory = isMandatory ?? 'NO';
    newUiComponentRevision.isMandatoryLogicalRule = isMandatoryLogicalRule ?? '';
    newUiComponentRevision.hasPattern = hasPattern ?? 'NO';
    newUiComponentRevision.hasPatternConstant = hasPatternConstant ?? '';
    newUiComponentRevision.hasPatternLogicalRule = hasPatternLogicalRule ?? '';
    newUiComponentRevision.isMultiChoiceAllowed = isMultiChoiceAllowed ?? 'NO';
    newUiComponentRevision.isMultiChoiceAllowedLogicalRule = isMultiChoiceAllowedLogicalRule ?? '';
    newUiComponentRevision.optionsSource = optionsSource ?? 'FREE';
    newUiComponentRevision.dateFormat = dateFormat as DateFormat;
    newUiComponentRevision.dateCustom = dateCustom ?? '';
    newUiComponentRevision.textImportancy = textImportancy ?? 'UNDEFINED';
    newUiComponentRevision.isFormValid = isComplete ?? true;

    newUiComponentRevision.options = options ?? new Array<Option>();

    newUiComponentRevision.index = index !== undefined ? index : 0;
    newUiComponentRevision.parentId = parent;

    this.uiComponentRevisions.push(newUiComponentRevision);
  }

  id: string | undefined;

  type: UiComponentCardType;

  createdAt: Date;

  uiComponentRevisions: UiComponentRevision[];
}
