import React from 'react';

import AnimatedLogo from '../../assets/animated-logo.gif';

export function LoadingPage(): JSX.Element {
  return (
    <div className="h-screen flex items-center justify-center">
      <img src={AnimatedLogo} />
    </div>
  );
}
