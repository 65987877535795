/* tslint:disable */
/* eslint-disable */
/**
 * Specks API
 * APIs for specks SaaS
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CreateApiResponseBodyDto } from './CreateApiResponseBodyDto';
import {
    CreateApiResponseBodyDtoFromJSON,
    CreateApiResponseBodyDtoFromJSONTyped,
    CreateApiResponseBodyDtoToJSON,
} from './CreateApiResponseBodyDto';

/**
 * 
 * @export
 * @interface UpdateApiResponseDto
 */
export interface UpdateApiResponseDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateApiResponseDto
     */
    fonctionalId: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateApiResponseDto
     */
    responseType: UpdateApiResponseDtoResponseTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof UpdateApiResponseDto
     */
    statusCode: number;
    /**
     * 
     * @type {string}
     * @memberof UpdateApiResponseDto
     */
    message: string;
    /**
     * 
     * @type {CreateApiResponseBodyDto}
     * @memberof UpdateApiResponseDto
     */
    apiResponseBody: CreateApiResponseBodyDto;
}


/**
 * @export
 */
export const UpdateApiResponseDtoResponseTypeEnum = {
    Success: 'SUCCESS',
    Error: 'ERROR'
} as const;
export type UpdateApiResponseDtoResponseTypeEnum = typeof UpdateApiResponseDtoResponseTypeEnum[keyof typeof UpdateApiResponseDtoResponseTypeEnum];


/**
 * Check if a given object implements the UpdateApiResponseDto interface.
 */
export function instanceOfUpdateApiResponseDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "fonctionalId" in value;
    isInstance = isInstance && "responseType" in value;
    isInstance = isInstance && "statusCode" in value;
    isInstance = isInstance && "message" in value;
    isInstance = isInstance && "apiResponseBody" in value;

    return isInstance;
}

export function UpdateApiResponseDtoFromJSON(json: any): UpdateApiResponseDto {
    return UpdateApiResponseDtoFromJSONTyped(json, false);
}

export function UpdateApiResponseDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateApiResponseDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'fonctionalId': json['fonctionalId'],
        'responseType': json['responseType'],
        'statusCode': json['statusCode'],
        'message': json['message'],
        'apiResponseBody': CreateApiResponseBodyDtoFromJSON(json['apiResponseBody']),
    };
}

export function UpdateApiResponseDtoToJSON(value?: UpdateApiResponseDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'fonctionalId': value.fonctionalId,
        'responseType': value.responseType,
        'statusCode': value.statusCode,
        'message': value.message,
        'apiResponseBody': CreateApiResponseBodyDtoToJSON(value.apiResponseBody),
    };
}

