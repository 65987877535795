/* tslint:disable */
/* eslint-disable */
/**
 * Specks API
 * APIs for specks SaaS
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CreateBodyDto } from './CreateBodyDto';
import {
    CreateBodyDtoFromJSON,
    CreateBodyDtoFromJSONTyped,
    CreateBodyDtoToJSON,
} from './CreateBodyDto';

/**
 * 
 * @export
 * @interface CreateApiResponseBodyDto
 */
export interface CreateApiResponseBodyDto {
    /**
     * 
     * @type {Array<CreateBodyDto>}
     * @memberof CreateApiResponseBodyDto
     */
    values: Array<CreateBodyDto>;
}

/**
 * Check if a given object implements the CreateApiResponseBodyDto interface.
 */
export function instanceOfCreateApiResponseBodyDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "values" in value;

    return isInstance;
}

export function CreateApiResponseBodyDtoFromJSON(json: any): CreateApiResponseBodyDto {
    return CreateApiResponseBodyDtoFromJSONTyped(json, false);
}

export function CreateApiResponseBodyDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateApiResponseBodyDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'values': ((json['values'] as Array<any>).map(CreateBodyDtoFromJSON)),
    };
}

export function CreateApiResponseBodyDtoToJSON(value?: CreateApiResponseBodyDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'values': ((value.values as Array<any>).map(CreateBodyDtoToJSON)),
    };
}

