/* tslint:disable */
/* eslint-disable */
/**
 * Specks API
 * APIs for specks SaaS
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CreateUiComponentInputDto } from './CreateUiComponentInputDto';
import {
    CreateUiComponentInputDtoFromJSON,
    CreateUiComponentInputDtoFromJSONTyped,
    CreateUiComponentInputDtoToJSON,
} from './CreateUiComponentInputDto';
import type { UpdateUicPositionDto } from './UpdateUicPositionDto';
import {
    UpdateUicPositionDtoFromJSON,
    UpdateUicPositionDtoFromJSONTyped,
    UpdateUicPositionDtoToJSON,
} from './UpdateUicPositionDto';

/**
 * 
 * @export
 * @interface CreateUicPositionInputDto
 */
export interface CreateUicPositionInputDto {
    /**
     * 
     * @type {CreateUiComponentInputDto}
     * @memberof CreateUicPositionInputDto
     */
    uiComponent: CreateUiComponentInputDto;
    /**
     * 
     * @type {Array<UpdateUicPositionDto>}
     * @memberof CreateUicPositionInputDto
     */
    positions: Array<UpdateUicPositionDto>;
}

/**
 * Check if a given object implements the CreateUicPositionInputDto interface.
 */
export function instanceOfCreateUicPositionInputDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "uiComponent" in value;
    isInstance = isInstance && "positions" in value;

    return isInstance;
}

export function CreateUicPositionInputDtoFromJSON(json: any): CreateUicPositionInputDto {
    return CreateUicPositionInputDtoFromJSONTyped(json, false);
}

export function CreateUicPositionInputDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateUicPositionInputDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'uiComponent': CreateUiComponentInputDtoFromJSON(json['uiComponent']),
        'positions': ((json['positions'] as Array<any>).map(UpdateUicPositionDtoFromJSON)),
    };
}

export function CreateUicPositionInputDtoToJSON(value?: CreateUicPositionInputDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'uiComponent': CreateUiComponentInputDtoToJSON(value.uiComponent),
        'positions': ((value.positions as Array<any>).map(UpdateUicPositionDtoToJSON)),
    };
}

