import { type UiComponent } from './uiComponent.entity';

export class PageRevision {
  id: string;

  name: string;

  notes: string;

  createdAt: Date;

  uiComponents: UiComponent[];
}
