import React from 'react';
import { useDrag } from 'react-dnd';

import { ExternalApiBadge } from 'components/core/ExternalApiBadge/ExternalApiBadge';

import { ComponentType } from 'models/ComponentTypes.type';
import { type Status } from 'models/Status.types';

import getIcon from 'utils/getIcon';

import { ComponentSmallCard } from '../ComponentSmallCard/ComponentSmallCard';

type AppSchemaCardProps =
  | {
      name: string;
      componentType: ComponentType;
      status: Status;
      componentId: string;
      isInSchema?: boolean;
      isInternal?: boolean;
      isDraggable?: boolean;
      disabled?: boolean;
      loading?: false;
    }
  | {
      name?: never;
      componentType?: never;
      status?: never;
      componentId?: never;
      isInSchema?: never;
      isInternal?: never;
      isDraggable?: never;
      disabled?: never;
      loading: true;
    };

export function AppSchemaCard({
  name = '',
  componentType = ComponentType.Front,
  status,
  componentId = '',
  isInSchema = false,
  isInternal = false,
  isDraggable = true,
  disabled = false,
  loading = false,
}: AppSchemaCardProps): JSX.Element {
  const [, dragRef] = useDrag(
    () => ({
      type: 'AppSchemaRevisionCard',
      item: { name, componentType, status, id: componentId },
    }),
    [],
  );

  function renderCard(): JSX.Element {
    return (
      <ComponentSmallCard
        name={name}
        componentType={componentType}
        badge={!isInternal && !loading ? <ExternalApiBadge label="API EXTERNE" borderColor="yellow" /> : undefined}
        customRef={disabled || isInSchema || !isDraggable ? undefined : dragRef}
        backgroundColor={isInSchema || disabled ? 'lightGray' : undefined}
        borderColor={isInSchema || disabled ? 'lightGray' : undefined}
        textColor={isInSchema || disabled ? 'gray-200' : undefined}
        extra={
          isInSchema && !loading
            ? getIcon('checkCircle', 'green', 'md')
            : disabled
            ? getIcon('slash', 'gray-200', 'md')
            : getIcon('gripDotsVertical', 'gray-200', 'md')
        }
        tooltip={
          isInSchema && !loading
            ? 'Ce composant est déjà dans le schéma :-)'
            : disabled
            ? `Ce composant est déjà utilisé dans un autre schéma applicatif et un composant front-end ne peut appartenir qu'à un seul produit.`
            : `Ce composant ${componentType} est disponible pour être ajouté au schéma.`
        }
        cursor={!isInSchema && !disabled ? 'grab' : undefined}
        loading={loading}
      />
    );
  }

  return disabled ? <div>{renderCard()}</div> : renderCard();
}
