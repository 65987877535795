/* tslint:disable */
/* eslint-disable */
/**
 * Specks API
 * APIs for specks SaaS
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreatePersonaDto
 */
export interface CreatePersonaDto {
    /**
     * 
     * @type {string}
     * @memberof CreatePersonaDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof CreatePersonaDto
     */
    description: string;
    /**
     * 
     * @type {string}
     * @memberof CreatePersonaDto
     */
    age: string;
    /**
     * 
     * @type {string}
     * @memberof CreatePersonaDto
     */
    likes: string;
    /**
     * 
     * @type {string}
     * @memberof CreatePersonaDto
     */
    dislikes: string;
}

/**
 * Check if a given object implements the CreatePersonaDto interface.
 */
export function instanceOfCreatePersonaDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "description" in value;
    isInstance = isInstance && "age" in value;
    isInstance = isInstance && "likes" in value;
    isInstance = isInstance && "dislikes" in value;

    return isInstance;
}

export function CreatePersonaDtoFromJSON(json: any): CreatePersonaDto {
    return CreatePersonaDtoFromJSONTyped(json, false);
}

export function CreatePersonaDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreatePersonaDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'description': json['description'],
        'age': json['age'],
        'likes': json['likes'],
        'dislikes': json['dislikes'],
    };
}

export function CreatePersonaDtoToJSON(value?: CreatePersonaDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'description': value.description,
        'age': value.age,
        'likes': value.likes,
        'dislikes': value.dislikes,
    };
}

