import {
  type GetPageDto,
  type GetPageRevisionDto,
  type PageControllerCreateUiComponentRequest,
  PagesApi,
  type UpdateUicPositionDto,
  type UpdateUicPositionsDto,
} from 'api';
import { fromGetPageDto } from 'factory/PageFactory';
import { fromGetPageRevisionDto } from 'factory/PageRevisionFactory';

import { type Page } from 'models/page.entity';
import { type PageRevision } from 'models/pageRevision.entity';
import { type UiComponent } from 'models/uiComponent.entity';

import { UiComponentService } from './uiComponent.service';

export class PageService {
  private readonly pageClient: PagesApi;
  private readonly uiComponentService: UiComponentService = new UiComponentService();

  constructor() {
    this.pageClient = new PagesApi();
  }

  public async getPage(pageId: string, revisionTimestamp: string, accessToken: string): Promise<Page> {
    const getPageDto: GetPageDto = await this.pageClient.pageControllerFindByIdByDate(
      {
        id: pageId,
        revisionTimestamp,
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
      },
    );
    return fromGetPageDto(getPageDto);
  }

  public async createUiComponent(
    pageId: string,
    uiComponents: UiComponent[],
    accessToken: string,
    newUiComponent?: UiComponent,
  ): Promise<PageRevision> {
    // Check if the newUiComponent is undefined
    if (newUiComponent === undefined) {
      return Promise.reject(new Error('something bad happened'));
    }
    const res: PageControllerCreateUiComponentRequest =
      this.uiComponentService.convertUiComponentToPageControllerCreateUiComponentRequest(uiComponents, newUiComponent);

    const getPageRevisionDto: GetPageRevisionDto = await this.pageClient.pageControllerCreateUiComponent(
      {
        id: pageId,
        pageControllerCreateUiComponentRequest: res,
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
      },
    );
    const newPageRevision: PageRevision = fromGetPageRevisionDto(getPageRevisionDto);
    return newPageRevision;
  }

  public async updatePage(pageId: string, page: Page, accessToken: string): Promise<Page> {
    const getPageRevisionDto: GetPageDto = await this.pageClient.pageControllerUpdate(
      {
        id: pageId,
        updatePageDto: {
          name: page.pageRevisions[0].name,
        },
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
      },
    );
    const updatedPage: Page = fromGetPageDto(getPageRevisionDto);
    return updatedPage;
  }

  public async moveUic(pageId: string, uiComponents: UiComponent[], accessToken: string): Promise<PageRevision> {
    const res: UpdateUicPositionsDto = this.convertUiComponentToUpdateUicPositionsDto(uiComponents);
    const getPageRevisionDto: GetPageRevisionDto = await this.pageClient.pageControllerMoveUiComponent(
      {
        id: pageId,
        updateUicPositionsDto: res,
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
      },
    );
    const newPageRevision: PageRevision = fromGetPageRevisionDto(getPageRevisionDto);
    return newPageRevision;
  }

  public async delete(id: string, accessToken: string): Promise<void> {
    await this.pageClient.pageControllerDelete(
      { id },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
      },
    );
  }

  convertUiComponentToUpdateUicPositionsDto(uiComponents: UiComponent[]): UpdateUicPositionsDto {
    const res: UpdateUicPositionDto[] = uiComponents.map((uic) => {
      if (uic.id === undefined) {
        return {
          uiComponentId: '',
          index: 0,
          parentId: undefined,
        };
      }
      return {
        uiComponentId: uic.id,
        index: uic.uiComponentRevisions[0].index,
        parentId: uic.uiComponentRevisions[0].parentId,
      };
    });
    return { positions: res };
  }
}
