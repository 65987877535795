import { type FeatureIdDto, type GetProductDto } from 'api';

import { type Status } from 'models/Status.types';
import { Feature } from 'models/feature.entity';
import { Product } from 'models/product.entity';

export function fromGetProductDto(getProductDto: GetProductDto): Product {
  const product: Product = new Product();
  product.id = getProductDto.id;
  product.name = getProductDto.name;
  product.status = getProductDto.status as Status;
  product.externalLinks = getProductDto.externalLinks;
  product.dictionary.id = getProductDto.dictionary.id;
  product.genesis.id = getProductDto.genesis.id;
  product.faq.id = getProductDto.faq.id;
  product.components = getProductDto.components;
  product.features = getProductDto.features.map((featureIdDto: FeatureIdDto): Feature => {
    const feature: Feature = new Feature();
    feature.id = featureIdDto.id;
    return feature;
  });
  return product;
}
