/* tslint:disable */
/* eslint-disable */
/**
 * Specks API
 * APIs for specks SaaS
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { GetApiResponseBodyDto } from './GetApiResponseBodyDto';
import {
    GetApiResponseBodyDtoFromJSON,
    GetApiResponseBodyDtoFromJSONTyped,
    GetApiResponseBodyDtoToJSON,
} from './GetApiResponseBodyDto';

/**
 * 
 * @export
 * @interface GetApiResponseDto
 */
export interface GetApiResponseDto {
    /**
     * 
     * @type {string}
     * @memberof GetApiResponseDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof GetApiResponseDto
     */
    fonctionalId: string;
    /**
     * 
     * @type {string}
     * @memberof GetApiResponseDto
     */
    responseType: GetApiResponseDtoResponseTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof GetApiResponseDto
     */
    statusCode: number;
    /**
     * 
     * @type {string}
     * @memberof GetApiResponseDto
     */
    message: string;
    /**
     * 
     * @type {GetApiResponseBodyDto}
     * @memberof GetApiResponseDto
     */
    apiResponseBody: GetApiResponseBodyDto;
}


/**
 * @export
 */
export const GetApiResponseDtoResponseTypeEnum = {
    Success: 'SUCCESS',
    Error: 'ERROR'
} as const;
export type GetApiResponseDtoResponseTypeEnum = typeof GetApiResponseDtoResponseTypeEnum[keyof typeof GetApiResponseDtoResponseTypeEnum];


/**
 * Check if a given object implements the GetApiResponseDto interface.
 */
export function instanceOfGetApiResponseDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "fonctionalId" in value;
    isInstance = isInstance && "responseType" in value;
    isInstance = isInstance && "statusCode" in value;
    isInstance = isInstance && "message" in value;
    isInstance = isInstance && "apiResponseBody" in value;

    return isInstance;
}

export function GetApiResponseDtoFromJSON(json: any): GetApiResponseDto {
    return GetApiResponseDtoFromJSONTyped(json, false);
}

export function GetApiResponseDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetApiResponseDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'fonctionalId': json['fonctionalId'],
        'responseType': json['responseType'],
        'statusCode': json['statusCode'],
        'message': json['message'],
        'apiResponseBody': GetApiResponseBodyDtoFromJSON(json['apiResponseBody']),
    };
}

export function GetApiResponseDtoToJSON(value?: GetApiResponseDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'fonctionalId': value.fonctionalId,
        'responseType': value.responseType,
        'statusCode': value.statusCode,
        'message': value.message,
        'apiResponseBody': GetApiResponseBodyDtoToJSON(value.apiResponseBody),
    };
}

