/* tslint:disable */
/* eslint-disable */
/**
 * Specks API
 * APIs for specks SaaS
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GetOptionDto
 */
export interface GetOptionDto {
    /**
     * 
     * @type {string}
     * @memberof GetOptionDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof GetOptionDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof GetOptionDto
     */
    description: string;
}

/**
 * Check if a given object implements the GetOptionDto interface.
 */
export function instanceOfGetOptionDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "description" in value;

    return isInstance;
}

export function GetOptionDtoFromJSON(json: any): GetOptionDto {
    return GetOptionDtoFromJSONTyped(json, false);
}

export function GetOptionDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetOptionDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'description': json['description'],
    };
}

export function GetOptionDtoToJSON(value?: GetOptionDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'description': value.description,
    };
}

