import React from 'react';

import { type TextSize } from 'components/core/Text/Text';

import { ApiTypeChip } from './ApiTypeChip';

interface DeleteChipProps {
  isFonctional?: boolean;
  size?: TextSize;
}

export function DeleteChip({ isFonctional = false, size = 'api' }: DeleteChipProps): JSX.Element {
  return <ApiTypeChip type="DELETE" isFunctional={isFonctional} size={size} />;
}
