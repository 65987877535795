import React from 'react';

import concatClassNames from 'utils/classNames';

interface CardContainerProps {
  children: JSX.Element[] | JSX.Element;
  cardSize?: CardSize;
  paddingX?: PaddingSize;
  paddingY?: PaddingSize;
}

type CardSize = 'sm' | 'md' | 'lg';
export type PaddingSize = '2' | '4' | '8';

export const paddingXSizeStyle: Record<PaddingSize, string> = {
  '2': 'px-2 ',
  '4': 'px-4',
  '8': 'px-8',
};
export const paddingYSizeStyle: Record<PaddingSize, string> = {
  '2': 'py-2 ',
  '4': 'py-4',
  '8': 'py-8',
};

const cardSizeStyle: Record<CardSize, string> = {
  sm: 'lg:grid-cols-5 md:grid-cols-4 sm:grid-cols-3',
  md: 'lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-3',
  lg: 'lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-2',
};

export function CardContainer({
  children,
  cardSize = 'md',
  paddingX = '8',
  paddingY = '8',
}: CardContainerProps): JSX.Element {
  return (
    <div
      className={concatClassNames(
        'grid gap-12',
        cardSizeStyle[cardSize],
        paddingXSizeStyle[paddingX],
        paddingYSizeStyle[paddingY],
      )}
    >
      {children}
    </div>
  );
}
