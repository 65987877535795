/* tslint:disable */
/* eslint-disable */
/**
 * Specks API
 * APIs for specks SaaS
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { GetRevisionsDto } from './GetRevisionsDto';
import {
    GetRevisionsDtoFromJSON,
    GetRevisionsDtoFromJSONTyped,
    GetRevisionsDtoToJSON,
} from './GetRevisionsDto';

/**
 * 
 * @export
 * @interface GetSubRevisionsDto
 */
export interface GetSubRevisionsDto {
    /**
     * 
     * @type {Array<GetRevisionsDto>}
     * @memberof GetSubRevisionsDto
     */
    subRevisions: Array<GetRevisionsDto>;
}

/**
 * Check if a given object implements the GetSubRevisionsDto interface.
 */
export function instanceOfGetSubRevisionsDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "subRevisions" in value;

    return isInstance;
}

export function GetSubRevisionsDtoFromJSON(json: any): GetSubRevisionsDto {
    return GetSubRevisionsDtoFromJSONTyped(json, false);
}

export function GetSubRevisionsDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetSubRevisionsDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'subRevisions': ((json['subRevisions'] as Array<any>).map(GetRevisionsDtoFromJSON)),
    };
}

export function GetSubRevisionsDtoToJSON(value?: GetSubRevisionsDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'subRevisions': ((value.subRevisions as Array<any>).map(GetRevisionsDtoToJSON)),
    };
}

