/* tslint:disable */
/* eslint-disable */
/**
 * Specks API
 * APIs for specks SaaS
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Component } from './Component';
import {
    ComponentFromJSON,
    ComponentFromJSONTyped,
    ComponentToJSON,
} from './Component';
import type { FaqEntry } from './FaqEntry';
import {
    FaqEntryFromJSON,
    FaqEntryFromJSONTyped,
    FaqEntryToJSON,
} from './FaqEntry';
import type { Product } from './Product';
import {
    ProductFromJSON,
    ProductFromJSONTyped,
    ProductToJSON,
} from './Product';

/**
 * 
 * @export
 * @interface ComponentFaq
 */
export interface ComponentFaq {
    /**
     * 
     * @type {string}
     * @memberof ComponentFaq
     */
    id: string;
    /**
     * 
     * @type {Product}
     * @memberof ComponentFaq
     */
    product: Product;
    /**
     * 
     * @type {Component}
     * @memberof ComponentFaq
     */
    component: Component;
    /**
     * 
     * @type {Array<FaqEntry>}
     * @memberof ComponentFaq
     */
    entries: Array<FaqEntry>;
}

/**
 * Check if a given object implements the ComponentFaq interface.
 */
export function instanceOfComponentFaq(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "product" in value;
    isInstance = isInstance && "component" in value;
    isInstance = isInstance && "entries" in value;

    return isInstance;
}

export function ComponentFaqFromJSON(json: any): ComponentFaq {
    return ComponentFaqFromJSONTyped(json, false);
}

export function ComponentFaqFromJSONTyped(json: any, ignoreDiscriminator: boolean): ComponentFaq {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'product': ProductFromJSON(json['product']),
        'component': ComponentFromJSON(json['component']),
        'entries': ((json['entries'] as Array<any>).map(FaqEntryFromJSON)),
    };
}

export function ComponentFaqToJSON(value?: ComponentFaq | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'product': ProductToJSON(value.product),
        'component': ComponentToJSON(value.component),
        'entries': ((value.entries as Array<any>).map(FaqEntryToJSON)),
    };
}

