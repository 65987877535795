import React from 'react';

import concatClassNames from 'utils/classNames';
import getIcon from 'utils/getIcon';

interface BackButtonProps {
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
}

export function BackButton({ onClick }: BackButtonProps): JSX.Element {
  return (
    <button
      className={concatClassNames(
        'flex flex-col',
        'justify-center items-center',
        'bg-white',
        'h-fit',
        'w-fit',
        'shadow-button',
        'p-[3px]',
        'border box-border rounded-full border-gray-25',
      )}
      onClick={onClick}
    >
      {getIcon('chevronLeft', 'gray', 'md')}
    </button>
  );
}
