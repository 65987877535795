/* tslint:disable */
/* eslint-disable */
/**
 * Specks API
 * APIs for specks SaaS
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Component } from './Component';
import {
    ComponentFromJSON,
    ComponentFromJSONTyped,
    ComponentToJSON,
} from './Component';

/**
 * 
 * @export
 * @interface LogicalRule
 */
export interface LogicalRule {
    /**
     * 
     * @type {string}
     * @memberof LogicalRule
     */
    id: string;
    /**
     * 
     * @type {number}
     * @memberof LogicalRule
     */
    index: number;
    /**
     * 
     * @type {string}
     * @memberof LogicalRule
     */
    type: LogicalRuleTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof LogicalRule
     */
    text: string;
    /**
     * 
     * @type {Component}
     * @memberof LogicalRule
     */
    component: Component;
}


/**
 * @export
 */
export const LogicalRuleTypeEnum = {
    Content: 'CONTENT',
    Placeholder: 'PLACEHOLDER',
    Mandatory: 'MANDATORY',
    Pattern: 'PATTERN',
    Editable: 'EDITABLE',
    Visible: 'VISIBLE',
    PreFilled: 'PRE_FILLED',
    IsMultichoiceAllowed: 'IS_MULTICHOICE_ALLOWED'
} as const;
export type LogicalRuleTypeEnum = typeof LogicalRuleTypeEnum[keyof typeof LogicalRuleTypeEnum];


/**
 * Check if a given object implements the LogicalRule interface.
 */
export function instanceOfLogicalRule(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "index" in value;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "text" in value;
    isInstance = isInstance && "component" in value;

    return isInstance;
}

export function LogicalRuleFromJSON(json: any): LogicalRule {
    return LogicalRuleFromJSONTyped(json, false);
}

export function LogicalRuleFromJSONTyped(json: any, ignoreDiscriminator: boolean): LogicalRule {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'index': json['index'],
        'type': json['type'],
        'text': json['text'],
        'component': ComponentFromJSON(json['component']),
    };
}

export function LogicalRuleToJSON(value?: LogicalRule | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'index': value.index,
        'type': value.type,
        'text': value.text,
        'component': ComponentToJSON(value.component),
    };
}

