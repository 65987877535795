import { SpecksApisContext } from 'App';
import * as Yup from 'yup';

import React, { useContext, useEffect, useState } from 'react';
import { Controller, type SubmitHandler, useFieldArray, useForm } from 'react-hook-form';
import { useQuery } from 'react-query';

import { useAuth0 } from '@auth0/auth0-react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Tooltip } from '@mui/material';

import { statusStyleOptions } from 'forms/CreateOrEditMethodForm/CreateOrEditMethodForm';

import { Button } from 'components/core/Button/Button';
import Input from 'components/core/Input/Input';
import { ListBox } from 'components/core/ListBox/ListBox';
import RadioButtons from 'components/core/RadioButtons/RadioButtons';
import { TabElement } from 'components/core/Tabs/TabElement';
import { TabList } from 'components/core/Tabs/TabList';
import { TabPanel } from 'components/core/Tabs/TabPanel';
import { TabPanels } from 'components/core/Tabs/TabPanels';
import { Tabs } from 'components/core/Tabs/Tabs';
import { Text } from 'components/core/Text/Text';
import TextArea from 'components/core/TextArea/TextArea';

import { type MethodService } from 'services/method.service';

import { type Method } from 'models/method.entity';
import { type YesLogical, type YesNoLogical } from 'models/ui-component-revisions.entity';
import { type UiComponent } from 'models/uiComponent.entity';

import concatClassNames from 'utils/classNames';
import getIcon from 'utils/getIcon';

import { ApiMethodRow } from '../Api/Method/ApiMethodRow';
import UICFieldReadOnly from '../UICFieldReadonly/UICFieldReadonly';

interface SelectInfosFormProps {
  uiComponent: UiComponent;
  checkIfNameIsUnique?: (name: string, id: string) => boolean;
  onClickSave?: () => void;
  setIsFormDirty?: (isDirty: boolean) => void;
  onClickAddTarget?: () => void;
  setFnSetMethodId?: (fn: (methodId: string | undefined) => void) => void;
  isLoading: boolean;
}
const MultiChoicesOptions: YesNoLogical[] = ['YES', 'NO', 'LOGICAL'];

export const MultiChoicesStyleOptions: string[] = ['Oui', 'Non', 'Règle logique'];

const paramsTypeOptions: YesNoLogical[] = ['YES', 'NO', 'LOGICAL'];

export const paramsTypeStyleOptions: string[] = ['Oui, toujours', 'Non', 'Règle logique'];

export interface Option {
  id?: string;
  name: string;
  description: string;
}

interface SelectFormModel {
  name: string;
  notes?: string;
  label?: string;
  optionsSource: OptionsSource;
  options?: Array<{
    name: string;
    description: string;
  }>;

  isMultiChoiceAllowed: YesNoLogical;
  isMultiChoiceAllowedLogicalRule?: string;

  isVisible: YesLogical;
  isVisibleLogicalRule?: string;

  isPreFilled: YesNoLogical;
  isPreFilledConstant?: string;
  isPreFilledLogicalRule?: string;

  hasPlaceholder: YesNoLogical;
  hasPlaceholderConstant?: string;
  hasPlaceholderLogicalRule?: string;

  isMandatory: YesNoLogical;
  isMandatoryLogicalRule?: string | undefined;

  sourceMethodId: string | undefined;
}

const isVisibleOptions: YesLogical[] = ['YES', 'LOGICAL'];

export const isVisibleStyleOptions: string[] = ['Oui, toujours', 'Règle logique'];

export type OptionsSource = 'METHOD' | 'FREE';

const optionsSourceOptions: OptionsSource[] = ['METHOD', 'FREE'];

const schema: Yup.ObjectSchema<SelectFormModel> = Yup.object().shape({
  name: Yup.string()
    .required('Champ obligatoire')
    .max(28, 'Le nom ne doit pas contenir plus de 28 caractères')
    .test('isUnique', "Un composant d'interface avec ce nom existe déjà", (value, context) => {
      const uiComponent: UiComponent | undefined = context?.options?.context?.uiComponent as UiComponent;
      const isNameUnique: boolean | undefined = context?.options?.context?.checkIfNameIsUnique(value, uiComponent.id);
      return value != null && value.length > 0 && isNameUnique;
    }),
  notes: Yup.string(),
  label: Yup.string(),

  optionsSource: Yup.mixed<OptionsSource>().required('Champ obligatoire').default('FREE'),
  options: Yup.array()
    .of(
      Yup.object().shape({
        name: Yup.string().required('Champ obligatoire'),
        description: Yup.string().default(''),
      }),
    )
    .required('Champ obligatoire'),

  sourceMethodId: Yup.string(),

  isMultiChoiceAllowed: Yup.mixed<YesNoLogical>().required('Champ obligatoire').default('NO'),
  isMultiChoiceAllowedLogicalRule: Yup.string(),

  isVisible: Yup.mixed<YesLogical>().required('Champ obligatoire').default('YES'),
  isVisibleLogicalRule: Yup.string(),

  isPreFilled: Yup.mixed<YesNoLogical>().required('Champ obligatoire').default('NO'),
  isPreFilledConstant: Yup.string(),
  isPreFilledLogicalRule: Yup.string(),

  hasPlaceholder: Yup.mixed<YesNoLogical>().required('Champ obligatoire').default('NO'),
  hasPlaceholderConstant: Yup.string(),
  hasPlaceholderLogicalRule: Yup.string(),

  isMandatory: Yup.mixed<YesNoLogical>().required('Champ obligatoire').default('YES'),
  isMandatoryLogicalRule: Yup.string(),
});

export function SelectInfosForm({
  uiComponent,
  checkIfNameIsUnique,
  onClickSave,
  setIsFormDirty,
  onClickAddTarget,
  setFnSetMethodId,
  isLoading,
}: SelectInfosFormProps): JSX.Element {
  /* --------------------------------------------------- Contexts --------------------------------------------------- */

  const methodService: MethodService = useContext(SpecksApisContext).methodService;
  const { getAccessTokenSilently } = useAuth0();

  /* --------------------------------------------------- States --------------------------------------------------- */

  const [originalName, setOriginalName] = useState<string>();
  const [originalNotes, setOriginalNotes] = useState<string>();
  const [originalLabel, setOriginalLabel] = useState<string>();
  const [originalOptionsSource, setOriginalOptionsSource] = useState<OptionsSource>();
  const [originalOptions, setOriginalOptions] = useState<Option[]>();

  const [originalIsVisible, setOriginalIsVisible] = useState<YesLogical>();
  const [originalIsVisibleLogicalRule, setOriginalIsVisibleLogicalRule] = useState<string | undefined>();
  const [originalSourceMethodId, setOriginalSourceMethodId] = useState<string | undefined>();
  const [originalIsMultiChoiceAllowed, setOriginalIsMultiChoiceAllowed] = useState<string | undefined>();
  const [originalIsMultiChoiceAllowedLogicalRule, setOriginalIsMultiChoiceAllowedLogicalRule] = useState<
    string | undefined
  >();

  const [originalIsPreFilled, setOriginalIsPreFilled] = useState<YesNoLogical>();
  const [originalIsPreFilledConstant, setOriginalIsPreFilledConstant] = useState<string>();
  const [originalIsPreFilledLogicalRule, setOriginalIsPreFilledLogicalRule] = useState<string>();
  const [originalHasPlaceholder, setOriginalHasPlaceholder] = useState<YesNoLogical>();
  const [originalHasPlaceholderConstant, setOriginalHasPlaceholderConstant] = useState<string>();
  const [originalHasPlaceholderLogicalRule, setOriginalHasPlaceholderLogicalRule] = useState<string>();
  const [originalIsMandatory, setOriginalIsMandatory] = useState<YesNoLogical>();
  const [originalIsMandatoryLogicalRule, setOriginalIsMandatoryLogicalRule] = useState<string>();

  /* ----------------------------------------------------- Form ----------------------------------------------------- */

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    control,
    formState: { errors, isSubmitted },
  } = useForm<SelectFormModel>({
    resolver: yupResolver(schema),
    context: {
      checkIfNameIsUnique,
      uiComponent,
    },
    mode: 'all',

    defaultValues: {
      options:
        uiComponent.uiComponentRevisions[0].options === undefined
          ? [{ name: '', description: '' }]
          : uiComponent.uiComponentRevisions[0].options.map((option) => ({
              name: option.name,
              description: option.description,
            })),
    },
  });

  /* --------------------------------------------------- Watchers --------------------------------------------------- */

  const watchName: string = watch('name');
  const watchNotes: string | undefined = watch('notes');
  const watchLabel: string | undefined = watch('label');
  const watchOptions: Option[] | undefined = watch('options');
  const watchOptionsSource: OptionsSource = watch('optionsSource');

  const watchIsVisible: YesLogical | undefined = watch('isVisible');
  const watchIsVisibleLogicalRule: string | undefined = watch('isVisibleLogicalRule');
  const watchSourceMethodId: string | undefined = watch('sourceMethodId');
  const watchIsMultiChoiceAllowed: YesNoLogical = watch('isMultiChoiceAllowed');
  const watchIsMultiChoiceAllowedLogicalRule: string | undefined = watch('isMultiChoiceAllowedLogicalRule');

  const watchIsPreFilled: YesNoLogical = watch('isPreFilled');
  const watchIsPreFilledConstant: string | undefined = watch('isPreFilledConstant');
  const watchIsPreFilledLogicalRule: string | undefined = watch('isPreFilledLogicalRule');
  const watchHasPlaceholder: YesNoLogical = watch('hasPlaceholder');
  const watchHasPlaceholderConstant: string | undefined = watch('hasPlaceholderConstant');
  const watchHasPlaceholderLogicalRule: string | undefined = watch('hasPlaceholderLogicalRule');
  const watchIsMandatory: YesNoLogical = watch('isMandatory');
  const watchIsMandatoryLogicalRule: string | undefined = watch('isMandatoryLogicalRule');
  const isEditable: boolean = onClickSave != null;

  /* ----------------------------------------------- alerts management ---------------------------------------------- */

  const isCompleteName: boolean = watchName !== undefined && watchName.length !== 0;

  const isCompleteIsVisibleLogicalRule: boolean =
    watchIsVisible !== 'LOGICAL' || (watchIsVisibleLogicalRule !== undefined && watchIsVisibleLogicalRule.length !== 0);

  const isCompleteIsMultiChoiceAllowedLogicalRule: boolean =
    watchIsMultiChoiceAllowed !== 'LOGICAL' ||
    (watchIsMultiChoiceAllowedLogicalRule !== undefined && watchIsMultiChoiceAllowedLogicalRule.length !== 0);

  const isCompleteIsPreFilledConstant: boolean =
    watchIsPreFilled !== 'YES' || (watchIsPreFilledConstant !== undefined && watchIsPreFilledConstant.length !== 0);

  const isCompleteIsPreFilledLogicalRule: boolean =
    watchIsPreFilled !== 'LOGICAL' ||
    (watchIsPreFilledLogicalRule !== undefined && watchIsPreFilledLogicalRule.length !== 0);

  const isCompleteHasPlaceholderConstant: boolean =
    watchHasPlaceholder !== 'YES' ||
    (watchHasPlaceholderConstant !== undefined && watchHasPlaceholderConstant.length !== 0);

  const isCompleteHasPlaceholderLogicalRule: boolean =
    watchHasPlaceholder !== 'LOGICAL' ||
    (watchHasPlaceholderLogicalRule !== undefined && watchHasPlaceholderLogicalRule.length !== 0);

  const isCompleteIsMandatoryLogicalRule: boolean =
    watchIsMandatory !== 'LOGICAL' ||
    (watchIsMandatoryLogicalRule !== undefined && watchIsMandatoryLogicalRule.length !== 0);

  const isCompleteOptions: boolean =
    watchOptionsSource !== 'FREE' || (watchOptions !== undefined && watchOptions.length > 0);

  const isCompleteSourceMethodId: boolean = watchOptionsSource !== 'METHOD' || watchSourceMethodId !== undefined;

  /* -------------------------------------------------- Setup Data -------------------------------------------------- */

  // Setup the original values of the form (from the database)
  useEffect(() => {
    setOriginalName(uiComponent.uiComponentRevisions[0].name);
    setOriginalNotes(uiComponent.uiComponentRevisions[0].notes);
    setOriginalLabel(uiComponent.uiComponentRevisions[0].label);
    setOriginalOptionsSource(uiComponent.uiComponentRevisions[0].optionsSource);
    setOriginalOptions(uiComponent.uiComponentRevisions[0].options);

    setOriginalIsVisible(uiComponent.uiComponentRevisions[0].isVisible);
    setOriginalIsVisibleLogicalRule(uiComponent.uiComponentRevisions[0].isVisibleLogicalRule);
    setOriginalSourceMethodId(uiComponent.uiComponentRevisions[0].sourceMethodId);
    setOriginalIsMultiChoiceAllowed(uiComponent.uiComponentRevisions[0].isMultiChoiceAllowed);
    setOriginalIsMultiChoiceAllowedLogicalRule(uiComponent.uiComponentRevisions[0].isMultiChoiceAllowedLogicalRule);

    setOriginalIsPreFilled(uiComponent.uiComponentRevisions[0].isPreFilled);
    setOriginalIsPreFilledConstant(uiComponent.uiComponentRevisions[0].isPreFilledConstant);
    setOriginalIsPreFilledLogicalRule(uiComponent.uiComponentRevisions[0].isPreFilledLogicalRule);
    setOriginalHasPlaceholder(uiComponent.uiComponentRevisions[0].hasPlaceholder);
    setOriginalHasPlaceholderConstant(uiComponent.uiComponentRevisions[0].hasPlaceholderConstant);
    setOriginalHasPlaceholderLogicalRule(uiComponent.uiComponentRevisions[0].hasPlaceholderLogicalRule);
    setOriginalIsMandatory(uiComponent.uiComponentRevisions[0].isMandatory);
    setOriginalIsMandatoryLogicalRule(uiComponent.uiComponentRevisions[0].isMandatoryLogicalRule);

    setValue('name', uiComponent.uiComponentRevisions[0].name);
    setValue('notes', uiComponent.uiComponentRevisions[0].notes);
    setValue('label', uiComponent.uiComponentRevisions[0].label);
    setValue('isMultiChoiceAllowed', uiComponent.uiComponentRevisions[0].isMultiChoiceAllowed);
    setValue('isMultiChoiceAllowedLogicalRule', uiComponent.uiComponentRevisions[0].isMultiChoiceAllowedLogicalRule);
    setValue('optionsSource', uiComponent.uiComponentRevisions[0].optionsSource);
    setValue('options', uiComponent.uiComponentRevisions[0].options);

    setValue('isVisible', uiComponent.uiComponentRevisions[0].isVisible);
    setValue('isVisibleLogicalRule', uiComponent.uiComponentRevisions[0].isVisibleLogicalRule);
    setValue('sourceMethodId', uiComponent.uiComponentRevisions[0].sourceMethodId);
    setValue('isPreFilled', uiComponent.uiComponentRevisions[0].isPreFilled);
    setValue('isPreFilledConstant', uiComponent.uiComponentRevisions[0].isPreFilledConstant);
    setValue('isPreFilledLogicalRule', uiComponent.uiComponentRevisions[0].isPreFilledLogicalRule);
    setValue('hasPlaceholder', uiComponent.uiComponentRevisions[0].hasPlaceholder);
    setValue('hasPlaceholderConstant', uiComponent.uiComponentRevisions[0].hasPlaceholderConstant);
    setValue('hasPlaceholderLogicalRule', uiComponent.uiComponentRevisions[0].hasPlaceholderLogicalRule);
    setValue('isMandatory', uiComponent.uiComponentRevisions[0].isMandatory);
    setValue('isMandatoryLogicalRule', uiComponent.uiComponentRevisions[0].isMandatoryLogicalRule);
  }, [setValue, uiComponent]);

  /* ---------------------------------------------------- queries --------------------------------------------------- */

  async function getMethod(methodId: string): Promise<Method> {
    const accessToken: string = await getAccessTokenSilently();
    return await methodService.findById(methodId, accessToken);
  }

  const { data: sourceMethod, status: sourceMethodStatus } = useQuery<Method, Error>({
    queryKey: ['method', watchSourceMethodId],
    queryFn: async ({ queryKey }) => {
      const [, methodId] = queryKey;
      return await getMethod(methodId as string);
    },
    enabled: watchSourceMethodId !== undefined,
  });

  /* --------------------------------------------------- Functions -------------------------------------------------- */

  function handleOnClickSave(): void {
    if (onClickSave == null) return;
    uiComponent.uiComponentRevisions[0].name = watchName;
    uiComponent.uiComponentRevisions[0].notes = watchNotes ?? '';
    uiComponent.uiComponentRevisions[0].label = watchLabel ?? '';
    uiComponent.uiComponentRevisions[0].optionsSource = watchOptionsSource ?? '';
    uiComponent.uiComponentRevisions[0].options = watch('options') ?? [];

    uiComponent.uiComponentRevisions[0].sourceMethodId =
      watchOptionsSource !== 'METHOD' ? undefined : watchSourceMethodId;
    uiComponent.uiComponentRevisions[0].isMultiChoiceAllowed = watchIsMultiChoiceAllowed ?? 'NO';

    uiComponent.uiComponentRevisions[0].isMultiChoiceAllowedLogicalRule = watchIsMultiChoiceAllowedLogicalRule ?? '';

    uiComponent.uiComponentRevisions[0].isVisible = watchIsVisible ?? 'YES';
    uiComponent.uiComponentRevisions[0].isVisibleLogicalRule = watchIsVisibleLogicalRule ?? '';
    uiComponent.uiComponentRevisions[0].isPreFilled = watchIsPreFilled;
    uiComponent.uiComponentRevisions[0].isPreFilledConstant = watchIsPreFilledConstant ?? '';
    uiComponent.uiComponentRevisions[0].isPreFilledLogicalRule = watchIsPreFilledLogicalRule ?? '';
    uiComponent.uiComponentRevisions[0].hasPlaceholder = watchHasPlaceholder;
    uiComponent.uiComponentRevisions[0].hasPlaceholderConstant = watchHasPlaceholderConstant ?? '';
    uiComponent.uiComponentRevisions[0].hasPlaceholderLogicalRule = watchHasPlaceholderLogicalRule ?? '';
    uiComponent.uiComponentRevisions[0].isMandatory = watchIsMandatory;
    uiComponent.uiComponentRevisions[0].isMandatoryLogicalRule = watchIsMandatoryLogicalRule ?? '';
    uiComponent.uiComponentRevisions[0].isFormValid = checkIfFormIsComplete;

    onClickSave();
  }

  function setContentMethodId(methodId: string | undefined): void {
    setValue('sourceMethodId', methodId);
  }

  function removeSourceMethodId(): void {
    setContentMethodId(undefined);
    setValue('sourceMethodId', undefined);
  }

  const onSubmitForm: SubmitHandler<SelectFormModel> = (data: SelectFormModel) => {
    handleOnClickSave();
  };

  /* --------------------------------------------------- Watchers --------------------------------------------------- */

  const checkIfFormIsComplete: boolean =
    isCompleteIsVisibleLogicalRule &&
    isCompleteName &&
    isCompleteHasPlaceholderConstant &&
    isCompleteHasPlaceholderLogicalRule &&
    isCompleteIsMultiChoiceAllowedLogicalRule &&
    isCompleteIsPreFilledConstant &&
    isCompleteIsMandatoryLogicalRule &&
    isCompleteOptions &&
    isCompleteSourceMethodId &&
    isCompleteIsPreFilledLogicalRule;

  let isFormValid: boolean = true;
  if (
    watchName === undefined ||
    watchName.length === 0 ||
    (watchName === originalName &&
      watchNotes === originalNotes &&
      watchLabel === originalLabel &&
      watchOptionsSource === originalOptionsSource &&
      originalOptions !== undefined &&
      watchOptions !== undefined &&
      watchOptions.length === originalOptions.length &&
      watchOptions.every((option, index) => {
        return option.name === originalOptions[index].name && option.description === originalOptions[index].description;
      }) &&
      watchIsVisible === originalIsVisible &&
      watchIsVisibleLogicalRule === originalIsVisibleLogicalRule &&
      watchSourceMethodId === originalSourceMethodId &&
      watchIsMultiChoiceAllowed === originalIsMultiChoiceAllowed &&
      watchIsMultiChoiceAllowedLogicalRule === originalIsMultiChoiceAllowedLogicalRule &&
      watchIsPreFilled === originalIsPreFilled &&
      watchIsPreFilledConstant === originalIsPreFilledConstant &&
      watchIsPreFilledLogicalRule === originalIsPreFilledLogicalRule &&
      watchHasPlaceholder === originalHasPlaceholder &&
      watchHasPlaceholderConstant === originalHasPlaceholderConstant &&
      watchHasPlaceholderLogicalRule === originalHasPlaceholderLogicalRule &&
      watchIsMandatory === originalIsMandatory &&
      watchIsMandatoryLogicalRule === originalIsMandatoryLogicalRule)
  ) {
    isFormValid = false;
  }

  if (
    watchName !== originalName ||
    watchNotes !== originalNotes ||
    watchLabel !== originalLabel ||
    watchIsVisible !== originalIsVisible ||
    watchIsVisibleLogicalRule !== originalIsVisibleLogicalRule ||
    watchSourceMethodId !== originalSourceMethodId ||
    watchIsMultiChoiceAllowed !== originalIsMultiChoiceAllowed ||
    watchIsMultiChoiceAllowedLogicalRule !== originalIsMultiChoiceAllowedLogicalRule ||
    watchIsPreFilled !== originalIsPreFilled ||
    watchIsPreFilledConstant !== originalIsPreFilledConstant ||
    watchIsPreFilledLogicalRule !== originalIsPreFilledLogicalRule ||
    watchHasPlaceholder !== originalHasPlaceholder ||
    watchHasPlaceholderConstant !== originalHasPlaceholderConstant ||
    watchHasPlaceholderLogicalRule !== originalHasPlaceholderLogicalRule ||
    watchIsMandatory !== originalIsMandatory ||
    watchIsMandatoryLogicalRule !== originalIsMandatoryLogicalRule ||
    watchOptionsSource !== originalOptionsSource ||
    watchOptions?.length !== originalOptions?.length ||
    !(
      watchOptions?.every((option, index) => {
        if (originalOptions?.[index] === undefined) return false;
        return (
          option.name === originalOptions?.[index].name && option.description === originalOptions?.[index].description
        );
      }) ?? false
    )
  ) {
    setIsFormDirty?.(true);
  } else setIsFormDirty?.(false);

  /* ----------------------------------------------------- Options -------------------------------------------------- */

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'options',
  });

  const gridTemplateColumns: string = 'grid-cols-[repeat(16,minmax(0,0.1fr))]';
  const gridTitleSpan: string = 'col-span-7';
  const gridDescriptionSpan: string = 'col-span-8';

  /* --------------------------------------------------- Rendering -------------------------------------------------- */

  return (
    <Tabs>
      <TabList>
        <TabElement textSize="base" title={'Spécification'}></TabElement>
        <TabElement textSize="base" title={'Règles logiques'}></TabElement>
      </TabList>
      <TabPanels>
        <TabPanel>
          <form
            className="flex flex-col gap-6 justify-between flex-grow overflow-auto mt-2"
            onSubmit={handleSubmit(onSubmitForm)}
          >
            <div className="flex flex-col gap-6 overflow-auto">
              {/* <div className={concatClassNames('flex flex-col', 'gap-5', 'items-start')}>
                <Text content="Type de composant d'interface" weight="bold" size="base" />
                <UiComponentCard type="select" horizontalMargin={'none'} isDraggable={false} />
              </div> */}

              <div className={concatClassNames('flex flex-col', 'gap-1')}>
                <UICFieldReadOnly
                  label="Identifiant Specks"
                  information="Cet identifiant vous permettra de le retrouver plus facilement dans Specks."
                  content={isEditable ? undefined : watchName}
                />
                {isEditable && (
                  <Input
                    labelWeight="medium"
                    placeholder="L'identifiant Specks de votre composant d'interface"
                    textSize="base"
                    {...register('name')}
                    maxLength={28}
                    error={errors.name != null && isSubmitted ? errors.name.message : undefined}
                  />
                )}
                {watchName !== undefined && watchName.length === 0 && (
                  <Text content="Le nom est obligatoire" color="red-500" size="sm" position="center" />
                )}
              </div>

              <UICFieldReadOnly
                label="Label"
                information="Quel label doit inviter l'utilisateur à faire ce choix d'options?"
                content={
                  isEditable ? undefined : watchLabel === undefined || watchLabel.length === 0 ? 'N/A' : watchLabel
                }
                error="Préconisation : ce champ ne devrait pas être vide"
              />
              {isEditable && (
                <Input
                  labelWeight="medium"
                  placeholder={`Ex : "Sélectionnez une couleur"`}
                  {...register('label')}
                  textSize="base"
                  error={errors.label != null && isSubmitted ? errors.label.message : undefined}
                />
              )}

              <div
                className={concatClassNames(
                  'flex flex-col',
                  'drop-shadow-md bg-white rounded-md',
                  'border-x-1 border-gray-25',
                  'p-4',
                  'gap-2',
                )}
              >
                <UICFieldReadOnly
                  label="Options possibles"
                  information="Définir les options possibles"
                  isComplete={isCompleteOptions && isCompleteSourceMethodId}
                  error="Préconisation : des valeurs devraient être renseignées"
                />
                {isEditable && (
                  <Controller
                    name="optionsSource"
                    control={control}
                    defaultValue={'FREE'}
                    render={({ field: { value, onChange } }) => (
                      <ListBox
                        selected={value}
                        onChange={onChange}
                        options={optionsSourceOptions}
                        styleOptions={statusStyleOptions}
                        itemsBorder
                        readonly={!isEditable}
                      />
                    )}
                  />
                )}

                {watchOptionsSource === 'METHOD' && (
                  <>
                    <div className={concatClassNames('flex flex-row', 'gap-4')}>
                      {isEditable && watchSourceMethodId === undefined && (
                        <Button
                          content={'Ajouter une méthode'}
                          type="button"
                          iconName="plus"
                          iconPosition="right"
                          bgColor="white"
                          textColor="black"
                          borderWidth="xs"
                          borderColor="grey"
                          textSize="sm"
                          iconColor="black"
                          width="fit"
                          onClick={() => {
                            setFnSetMethodId?.(() => (methodId: string | undefined): void => {
                              setValue('sourceMethodId', methodId);
                            });
                            onClickAddTarget?.();
                          }}
                          error={!isCompleteSourceMethodId}
                        />
                      )}
                      <div id="selected_methods" className={concatClassNames('flex', 'items-center', 'flex-grow')}>
                        {watchSourceMethodId === undefined && (
                          <Text content="Aucune méthode sélectionnée" color="gray-400" size="sm" />
                        )}
                        {watchSourceMethodId !== undefined && sourceMethodStatus === 'success' && (
                          <ApiMethodRow
                            type={sourceMethod?.methodRevisions[0].methodType}
                            methodId={sourceMethod?.id ?? ''}
                            path={sourceMethod?.methodRevisions[0].name ?? ''}
                            description={sourceMethod?.methodRevisions[0].description ?? ''}
                            borderColored
                            clickable={false}
                            onDelete={isEditable ? removeSourceMethodId : undefined}
                          />
                        )}
                      </div>
                    </div>
                  </>
                )}
                {watchOptionsSource === 'FREE' && (
                  <>
                    {isEditable && <Text content="Valeurs" position="left" size="base" color="black" weight="bold" />}
                    <div className={concatClassNames('flex flex-col', 'gap-1.5')}>
                      {fields.length === 0 && (
                        <div className="flex flex-row justify-center">
                          <Text content="Aucune valeur" position="left" size="sm" color="gray-200" />
                        </div>
                      )}
                      {fields.map((field, index) => {
                        return (
                          <fieldset key={field.id}>
                            <div
                              id="options-list"
                              className={concatClassNames('grid', gridTemplateColumns, 'gap-1', 'gap-y-1.5')}
                            >
                              {isEditable && (
                                <>
                                  <Tooltip title={errors.options?.[index]?.name?.message} arrow followCursor>
                                    <div className={concatClassNames(gridTitleSpan, 'flex items-center')}>
                                      <Input
                                        placeholder="Valeur"
                                        {...register(`options.${index}.name` as const, {
                                          required: true,
                                        })}
                                        value={
                                          !isEditable &&
                                          (watch(`options.${index}.name`) === undefined ||
                                            watch(`options.${index}.name`).length === 0)
                                            ? 'N/A'
                                            : watch(`options.${index}.name`)
                                        }
                                        width="full"
                                        borderColor={
                                          errors.options?.[index]?.name?.message !== undefined ? 'error' : 'gray-50'
                                        }
                                        readonly={!isEditable}
                                      />
                                    </div>
                                  </Tooltip>

                                  <div className={concatClassNames(gridDescriptionSpan, 'flex items-center')}>
                                    <Input
                                      placeholder={isEditable ? 'Description' : ''}
                                      {...register(`options.${index}.description` as const, {
                                        required: true,
                                      })}
                                      value={
                                        !isEditable &&
                                        (watch(`options.${index}.description`) === undefined ||
                                          watch(`options.${index}.description`).length === 0)
                                          ? 'N/A'
                                          : watch(`options.${index}.description`)
                                      }
                                      width="full"
                                      borderColor={
                                        errors.options?.[index]?.description?.message !== undefined
                                          ? 'error'
                                          : 'gray-50'
                                      }
                                      readonly={!isEditable}
                                    />
                                  </div>
                                </>
                              )}
                              {!isEditable && (
                                <>
                                  <div
                                    className={concatClassNames(
                                      'col-span-full',
                                      'flex items-start',
                                      'gap-2',
                                      'items-center',
                                    )}
                                  >
                                    <div className={concatClassNames('flex', 'max-w-[49%]')}>
                                      <UICFieldReadOnly content={watch(`options.${index}.name`)} />
                                    </div>
                                    <span
                                      className={concatClassNames('whitespace-pre-wrap', 'text-gray-100')}
                                    >{`-`}</span>
                                    <span
                                      className={concatClassNames(
                                        'whitespace-pre-wrap',
                                        'text-gray-100 text-sm',
                                        'shrink',
                                        'max-w-[49%]',
                                        'break-words',
                                      )}
                                    >{`${watch(`options.${index}.description`)}`}</span>
                                  </div>
                                </>
                              )}

                              {isEditable && (
                                <div
                                  className={concatClassNames(
                                    'flex items-center col-end-[17] justify-center',
                                    'cursor-pointer',
                                  )}
                                  onClick={() => {
                                    remove(index);
                                  }}
                                >
                                  {getIcon('trash', 'red', 'md')}
                                </div>
                              )}
                            </div>
                          </fieldset>
                        );
                      })}
                    </div>

                    {isEditable && (
                      <>
                        <div className="flex flex-row justify-start">
                          <Button
                            content="Ajouter une valeur"
                            width="fit"
                            type="button"
                            bgColor="grey"
                            borderColor="grey-500"
                            borderWidth="xs"
                            textColor="black"
                            onClick={() => {
                              append({
                                name: '',
                                description: '',
                              });
                            }}
                            error={!isCompleteOptions}
                          />
                        </div>
                      </>
                    )}
                  </>
                )}
              </div>

              <div className={concatClassNames('flex flex-col', 'gap-2')}>
                <UICFieldReadOnly
                  label="Visible ?"
                  information="Ce champ est-il systématiquement affiché à l'utilisateur ?"
                  isComplete={isCompleteIsVisibleLogicalRule}
                  error="Préconisation : ce champ ne devrait pas être vide"
                />
                <RadioButtons
                  {...register('isVisible')}
                  values={isVisibleOptions}
                  valuesDisplay={isVisibleStyleOptions}
                  selected={watchIsVisible}
                  isEditable={isEditable}
                  isComplete={isCompleteIsVisibleLogicalRule}
                  error="Préconisation : ce champ ne devrait pas être vide"
                />

                {watchIsVisible === 'LOGICAL' && (
                  <>
                    {isEditable && (
                      <TextArea
                        {...register('isVisibleLogicalRule')}
                        value={watchIsVisibleLogicalRule}
                        placeholder={`Etant données [les hypothèses suivantes], lorsque [tel événement se produit] alors [voici le comportement attendu] `}
                        rows={4}
                        error={
                          errors.isVisibleLogicalRule != null && isSubmitted
                            ? errors.isVisibleLogicalRule.message
                            : undefined
                        }
                        disabled={!isEditable}
                        isComplete={isCompleteIsVisibleLogicalRule}
                      />
                    )}
                    {!isEditable && <UICFieldReadOnly content={watchIsVisibleLogicalRule ?? ''} />}
                  </>
                )}
              </div>

              <div className={concatClassNames('flex flex-col', 'gap-2')}>
                <UICFieldReadOnly
                  label="Multisélection ?"
                  information="L'utilisateur peut-il sélectionner plusieurs valeurs ?"
                  isComplete={isCompleteIsMultiChoiceAllowedLogicalRule}
                  error="Préconisation : ce champ ne devrait pas être vide"
                />
                <RadioButtons
                  values={MultiChoicesOptions}
                  valuesDisplay={MultiChoicesStyleOptions}
                  selected={watchIsMultiChoiceAllowed}
                  {...register('isMultiChoiceAllowed')}
                  isEditable={isEditable}
                  isComplete={isCompleteIsMultiChoiceAllowedLogicalRule}
                  error="Préconisation : ce champ ne devrait pas être vide"
                />

                {watchIsMultiChoiceAllowed === 'LOGICAL' && (
                  <>
                    {isEditable && (
                      <TextArea
                        {...register('isMultiChoiceAllowedLogicalRule')}
                        value={watchIsMultiChoiceAllowedLogicalRule}
                        error={
                          errors.isMultiChoiceAllowedLogicalRule != null && isSubmitted
                            ? errors.isMultiChoiceAllowedLogicalRule.message
                            : undefined
                        }
                        rows={4}
                        placeholder={`Etant données [les hypothèses suivantes], lorsque [tel événement se produit] alors [voici le comportement attendu] `}
                        disabled={!isEditable}
                        isComplete={isCompleteIsMultiChoiceAllowedLogicalRule}
                      />
                    )}
                    {!isEditable && <UICFieldReadOnly content={watchIsMultiChoiceAllowedLogicalRule ?? ''} />}
                  </>
                )}
              </div>

              <div className={concatClassNames('flex flex-col', 'gap-2')}>
                <UICFieldReadOnly
                  label="Prérempli ?"
                  information="Ce choix doit-il être prérempli lorsque l'utilisateur accède à la page ?"
                  isComplete={isCompleteIsPreFilledConstant && isCompleteIsPreFilledLogicalRule}
                  error="Préconisation : ce champ ne devrait pas être vide"
                />
                <RadioButtons
                  values={paramsTypeOptions}
                  valuesDisplay={paramsTypeStyleOptions}
                  selected={watchIsPreFilled}
                  {...register('isPreFilled')}
                  isEditable={isEditable}
                  error="Préconisation : ce champ ne devrait pas être vide"
                />

                {watchIsPreFilled === 'YES' && (
                  <>
                    {isEditable && (
                      <>
                        <TextArea
                          {...register('isPreFilledConstant')}
                          error={
                            errors.isPreFilledConstant != null && isSubmitted
                              ? errors.isPreFilledConstant.message
                              : undefined
                          }
                          value={watchIsPreFilledConstant}
                          rows={2}
                          placeholder="Saisissez le choix qui sera pré-rempli lorsque l'utilisateur accèdera à la page"
                          disabled={!isEditable}
                          isComplete={isCompleteIsPreFilledConstant}
                        />
                      </>
                    )}
                    {!isEditable && (
                      <UICFieldReadOnly
                        content={
                          watchIsPreFilledConstant === undefined || watchIsPreFilledConstant.length === 0
                            ? 'N/A'
                            : watchIsPreFilledConstant
                        }
                      />
                    )}
                  </>
                )}
                {watchIsPreFilled === 'LOGICAL' && (
                  <>
                    {isEditable && (
                      <TextArea
                        {...register('isPreFilledLogicalRule')}
                        value={watchIsPreFilledLogicalRule}
                        error={
                          errors.isPreFilledLogicalRule != null && isSubmitted
                            ? errors.isPreFilledLogicalRule.message
                            : undefined
                        }
                        rows={4}
                        placeholder={`Etant données [les hypothèses suivantes], lorsque [tel événement se produit] alors [voici le comportement attendu] `}
                        isComplete={isCompleteIsPreFilledLogicalRule}
                      />
                    )}
                    {!isEditable && (
                      <UICFieldReadOnly
                        content={
                          watchIsPreFilledLogicalRule === undefined || watchIsPreFilledLogicalRule.length === 0
                            ? 'N/A'
                            : watchIsPreFilledLogicalRule
                        }
                      />
                    )}
                  </>
                )}
              </div>
              <div className={concatClassNames('flex flex-col', 'gap-2')}>
                <UICFieldReadOnly
                  label="Placeholder ?"
                  information="Souhaitez-vous afficher un texte d'exemple (placeholder) à l'intérieur de la zone de saisie pour guider l'utilisateur dans sa saisie ?"
                  isComplete={isCompleteHasPlaceholderConstant && isCompleteHasPlaceholderLogicalRule}
                  error="Préconisation : ce champ ne devrait pas être vide"
                />
                <RadioButtons
                  values={paramsTypeOptions}
                  valuesDisplay={paramsTypeStyleOptions}
                  selected={watchHasPlaceholder}
                  {...register('hasPlaceholder')}
                  isEditable={isEditable}
                  isComplete={isCompleteHasPlaceholderConstant && isCompleteHasPlaceholderLogicalRule}
                />

                {watchHasPlaceholder === 'YES' && (
                  <>
                    {isEditable && (
                      <TextArea
                        {...register('hasPlaceholderConstant')}
                        value={
                          !isEditable &&
                          (watchHasPlaceholderConstant === undefined || watchHasPlaceholderConstant.length === 0)
                            ? 'N/A'
                            : watchHasPlaceholderConstant
                        }
                        error={
                          errors.hasPlaceholderConstant != null && isSubmitted
                            ? errors.hasPlaceholderConstant.message
                            : undefined
                        }
                        rows={2}
                        placeholder="Le placeholder qui guidera l'utilisateur (-> ceci est un placeholder ! 😉)"
                        disabled={!isEditable}
                        isComplete={isCompleteHasPlaceholderConstant}
                      />
                    )}

                    {!isEditable && (
                      <UICFieldReadOnly
                        content={
                          watchHasPlaceholderConstant === undefined || watchHasPlaceholderConstant.length === 0
                            ? 'N/A'
                            : watchHasPlaceholderConstant
                        }
                      />
                    )}
                  </>
                )}
                {watchHasPlaceholder === 'LOGICAL' && (
                  <>
                    {isEditable && (
                      <TextArea
                        {...register('hasPlaceholderLogicalRule')}
                        value={watchHasPlaceholderLogicalRule}
                        error={
                          errors.hasPlaceholderLogicalRule != null && isSubmitted
                            ? errors.hasPlaceholderLogicalRule.message
                            : undefined
                        }
                        rows={4}
                        placeholder={`Etant données [les hypothèses suivantes], lorsque [tel événement se produit] alors [voici le comportement attendu] `}
                        isComplete={isCompleteHasPlaceholderLogicalRule}
                      />
                    )}

                    {!isEditable && (
                      <UICFieldReadOnly
                        content={
                          watchHasPlaceholderLogicalRule === undefined || watchHasPlaceholderLogicalRule.length === 0
                            ? 'N/A'
                            : watchHasPlaceholderLogicalRule
                        }
                      />
                    )}
                  </>
                )}
              </div>

              <div className={concatClassNames('flex flex-col', 'gap-2')}>
                <UICFieldReadOnly
                  label="Obligatoire ?"
                  information="Faire un choix est-il obligatoire pour l'utilisateur?"
                  isComplete={isCompleteIsMandatoryLogicalRule}
                  error="Préconisation : ce champ ne devrait pas être vide"
                />
                <RadioButtons
                  values={paramsTypeOptions}
                  valuesDisplay={paramsTypeStyleOptions}
                  selected={watchIsMandatory}
                  {...register('isMandatory')}
                  isEditable={isEditable}
                  isComplete={isCompleteIsMandatoryLogicalRule}
                  error="Préconisation : ce champ ne devrait pas être vide"
                />

                {watchIsMandatory === 'LOGICAL' && (
                  <>
                    {isEditable && (
                      <TextArea
                        {...register('isMandatoryLogicalRule')}
                        error={
                          errors.isMandatoryLogicalRule != null && isSubmitted
                            ? errors.isMandatoryLogicalRule.message
                            : undefined
                        }
                        rows={4}
                        value={watchIsMandatoryLogicalRule}
                        placeholder={`Etant données [les hypothèses suivantes], lorsque [tel événement se produit] alors [voici le comportement attendu] `}
                        disabled={!isEditable}
                        isComplete={isCompleteIsMandatoryLogicalRule}
                      />
                    )}

                    {!isEditable && (
                      <UICFieldReadOnly
                        content={
                          watchIsMandatoryLogicalRule === undefined || watchIsMandatoryLogicalRule.length === 0
                            ? 'N/A'
                            : watchIsMandatoryLogicalRule
                        }
                      />
                    )}
                  </>
                )}
              </div>

              {isEditable && (
                <TextArea
                  label="Notes"
                  labelWeight="medium"
                  labelColor="gray-700"
                  placeholder="Ajouter une note"
                  {...register('notes')}
                  value={watchNotes}
                  rows={6}
                  error={errors.notes != null && isSubmitted ? errors.notes.message : undefined}
                />
              )}
              {!isEditable && (
                <UICFieldReadOnly
                  label="Notes"
                  content={watchNotes === undefined || watchNotes.length === 0 ? 'N/A' : watchNotes}
                />
              )}
            </div>

            {onClickSave != null && (
              <div className="flex flex-row gap-2 justify-end">
                {!isLoading && <Button content="Sauvegarder" type="submit" disabled={!isFormValid} />}
                {isLoading && <Button iconName="spinCircle" type="button" height="sm" iconAnimation="spin" />}
              </div>
            )}
          </form>
        </TabPanel>
        <TabPanel>
          <div className={concatClassNames('flex flex-col flex-grow justify-between')}>
            <div className={concatClassNames('flex flex-col gap-4')}>
              <div className={concatClassNames('flex flex-col gap-1')}>
                <Text content="Règles du Composant d'interface" weight="bold" position="left" />
                <Text
                  content="Aucune règle n'a été définie pour ce composant. "
                  color="gray-100"
                  position="left"
                  size="sm"
                />
              </div>
              <div className={concatClassNames('flex flex-col gap-1')}>
                <Text content="Règles héritées (non modifiables)" weight="bold" position="left" />
                <Text
                  content="Ce composant ne reçoit aucune règle en héritage"
                  color="gray-100"
                  position="left"
                  size="sm"
                />
              </div>
            </div>
            {onClickSave != null && (
              <div className={concatClassNames('flex gap-2 pt-8 border-t-1 border-gray-50')}>
                <Button content="Ajouter une règle" iconName="plus" cursor="not-allowed" />
                <Button content="Sauvegarder" cursor="not-allowed" />
              </div>
            )}
          </div>
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
}
