import React, { useState } from 'react';

import { Settings } from 'components/core/Settings/Settings';
import { type ButtonMenuProps } from 'components/core/Settings/Settings.types';
import { Text } from 'components/core/Text/Text';

import concatClassNames from 'utils/classNames';

import ChevronDownIcon from 'icons/ChevronDownIcon';
import ChevronRightIcon from 'icons/ChevronRightIcon';
import EditIcon from 'icons/EditIcon';

type FaqCollapseProps =
  | {
      id: string;
      title: string;
      content: string;
      loading?: false;
      onDeletionClick: (id: string) => void;
    }
  | {
      id?: never;
      title?: never;
      content?: never;
      loading: true;
      onDeletionClick?: never;
    };

export function FaqCollapse({
  id = '',
  title = '',
  content = '',
  loading = false,
  onDeletionClick,
}: FaqCollapseProps): JSX.Element {
  /* ---------------------------------------------------- states ---------------------------------------------------- */

  const [isCollapsed, setIsCollapsed] = useState<boolean>(false);

  /* ------------------------------------------------- menu options ------------------------------------------------- */

  const buttonsMenu: ButtonMenuProps[] = [
    {
      name: 'Modifier',
      iconName: 'edit',
      textColor: 'black',
      isEnabled: false,
      onClick: () => {
        console.log('Modifier');
      },
    },
    {
      name: 'Supprimer',
      iconName: 'trash',
      textColor: 'red',
      onClick: () => {
        if (onDeletionClick !== undefined) onDeletionClick(id);
      },
    },
  ];

  /* --------------------------------------------------- functions -------------------------------------------------- */

  function handleOnClick(): void {
    setIsCollapsed(!isCollapsed);
  }

  /* ------------------------------------------------------ JSX ----------------------------------------------------- */

  return (
    <div
      className={concatClassNames(
        'flex flex-col',
        'w-full',
        'p-3',
        isCollapsed ? 'gap-4' : '',
        'border-1 border-gray-25 bg-gray-15 rounded-lg',
      )}
    >
      <div
        className={concatClassNames('flex flex-row', 'w-full', 'justify-between', 'items-center')}
        onClick={handleOnClick}
      >
        {loading ? (
          <>
            <Text loading />
            <ChevronRightIcon color="gray" size="md" />
            <EditIcon color="gray" size="md" />
          </>
        ) : (
          <>
            <Text content={title.toUpperCase()} color="black" weight="bold" size="sm" position="left" />
            <div className="flex flex-row gap-5 items-center">
              {isCollapsed ? <ChevronDownIcon color="gray" size="md" /> : <ChevronRightIcon color="gray" size="md" />}
              {buttonsMenu?.length > 0 && (
                <Settings position="left" settingsIconName="more" buttonsMenu={buttonsMenu} />
              )}
            </div>
          </>
        )}
      </div>
      {!loading && (
        <div className={concatClassNames('flex flex-row', 'w-full', isCollapsed ? '' : 'invisible')}>
          <Text
            content={content}
            color="black"
            weight="light"
            size="base"
            position="left"
            hidden={!isCollapsed}
            textBreak="words"
          />
        </div>
      )}
    </div>
  );
}
