import { type Node } from './Node.entity';

export class Edge {
  id: string;

  sourceNode: Node;

  sourceStringId: string;

  targetNode: Node;

  targetStringId: string;
}
