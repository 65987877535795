import React from 'react';

import { Tooltip } from '@mui/material';

import { type LetterSpacing, Text, type TextColor, type TextSize } from 'components/core/Text/Text';

import concatClassNames from 'utils/classNames';
import getIcon from 'utils/getIcon';

import { type IconColor, type IconName, type IconSize } from 'icons/Icon.props';

import { type ChipBgColor } from './Chip.props';

const bgColorStyle: Record<ChipBgColor, string> = {
  white: 'bg-white',
  transparent: 'bg-transparent',
  cyan: 'bg-blue-400',
  red: 'bg-red-500',
  orange: 'bg-orange-400',
  green: 'bg-green-500',
  yellow: 'bg-yellow-400',
  'miscelleanous-blue': 'bg-miscelleanous-blue',
  'yellow-400': 'bg-warning-400',
  'green-500': 'bg-success-500',
  'red-500': 'bg-error-500',
};

export type ChipPaddingTop = 'none' | 'sm' | 'md';

export const paddingTopStyle: Record<ChipPaddingTop, string> = {
  none: 'pt-0',
  sm: 'pt-0.5',
  md: 'pt-1',
};

export type ChipPaddingBottom = 'none' | 'sm' | 'md';

export const paddingBottomStyle: Record<ChipPaddingBottom, string> = {
  none: 'pb-0',
  sm: 'pb-0.5',
  md: 'pb-1',
};

export type ChipPaddingHorizontal = 'xs' | 'sm' | 'md' | 'lg';

export const paddingHorizontalStyle: Record<ChipPaddingHorizontal, string> = {
  xs: 'px-0.5',
  sm: 'px-1',
  md: 'px-2',
  lg: 'px-2.5',
};

export type ChipBorderColor =
  | 'none'
  | 'yellow'
  | 'gray-50'
  | 'miscelleanous-blue'
  | 'red-500'
  | 'green-500'
  | 'yellow-400'
  | 'black'
  | 'blue'
  | 'cyan'
  | 'gray-100'
  | 'gray-200'
  | 'gray'
  | 'green'
  | 'orange'
  | 'purple'
  | 'red'
  | 'white'
  | 'yellow'
  | 'gradient2-to'
  | 'pink';

const borderColorStyle: Record<ChipBorderColor, string> = {
  none: '',
  'gray-50': 'border border-gray-50',
  'miscelleanous-blue': 'border border-miscelleanous-blue',
  'red-500': 'border border-error-500',
  'green-500': 'border border-success-500',
  'yellow-400': 'border border-warning-400',
  black: 'border border-[#000000]',
  blue: 'border border-[#2D3389]',
  cyan: 'border border-[#61AFFE]',
  'gray-100': 'border border-[#E5E5E5]',
  'gray-200': 'border border-[#929292]',
  gray: 'border border-[#6D6D6D]',
  green: 'border border-[#12B76A]',
  orange: 'border border-[#F79009]',
  purple: 'border border-[#462689]',
  red: 'border border-[#D92D20]',
  white: 'border border-[#FFFFFF]',
  yellow: 'border border-[#FBD34B]',
  'gradient2-to': 'border border-[#6D3A8A]',
  pink: 'border border-[#D83389]',
};

export type ChipProps =
  | {
      icon?: IconName;
      iconColor?: IconColor;
      textColor?: TextColor;
      textTracking?: LetterSpacing;
      label: string;
      iconSize?: IconSize;
      textSize?: TextSize;
      borderColor?: ChipBorderColor;
      bgColor?: ChipBgColor;
      paddingTop?: ChipPaddingTop;
      paddingBottom?: ChipPaddingBottom;
      paddingHorizontal?: ChipPaddingHorizontal;
      loading?: false;
      tooltipMessage?: string;
    }
  | {
      icon?: never;
      iconColor?: never;
      textColor?: never;
      textTracking?: never;
      label?: never;
      iconSize?: never;
      textSize?: never;
      borderColor?: never;
      bgColor?: never;
      paddingTop?: never;
      paddingBottom?: never;
      paddingHorizontal?: never;
      loading: true;
      tooltipMessage?: never;
    };

export function Chip({
  icon,
  iconColor = 'red',
  iconSize = 'lg',
  textColor = 'black',
  textTracking = 'normal',
  textSize = 'base',
  label = '',
  loading = false,
  borderColor = 'gray-50',
  bgColor = 'white',
  paddingTop = 'none',
  paddingBottom = 'none',
  paddingHorizontal = 'sm',
  tooltipMessage,
}: ChipProps): JSX.Element {
  if (loading) return <div className="w-28 h-6 rounded-lg bg-gray-100 animate-pulse" />;
  return (
    <div
      className={concatClassNames(
        'flex flex-row w-fit',
        bgColorStyle[bgColor],
        borderColorStyle[borderColor],
        'items-center gap-1 rounded-md content-center',
        paddingTopStyle[paddingTop],
        paddingBottomStyle[paddingBottom],
        paddingHorizontalStyle[paddingHorizontal],
        'align-middle',
        'h-fit',
        'gap-2',
      )}
    >
      {icon !== undefined && getIcon(icon, iconColor, iconSize)}
      <Text content={label} size={textSize} color={textColor} tracking={textTracking} position="center" />
      {tooltipMessage !== undefined && (
        <Tooltip title={tooltipMessage} placement="right">
          <div className="bg-black border-1 rounded-full">{getIcon('information', 'white', 'smd')}</div>
        </Tooltip>
      )}
    </div>
  );
}
