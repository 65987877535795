import React, { useCallback } from 'react';
import { type NavigateFunction, useNavigate } from 'react-router-dom';

import { Card } from 'components/core/Card/Card';
import { CardBody } from 'components/core/Card/CardBody';
import { CardHeader } from 'components/core/Card/CardHeader';
import { Chip } from 'components/core/Chip/Chip';
import { IconBadge } from 'components/core/IconBadge/IconBadge';
import { Text } from 'components/core/Text/Text';

import { type ComponentType } from 'models/ComponentTypes.type';
import { Status } from 'models/Status.types';

import concatClassNames from 'utils/classNames';
import getComponentSymbol from 'utils/getComponentSymbol';
import getIcon from 'utils/getIcon';
import getStatusChip from 'utils/getStatusChip';

import { type ProductCardProps } from './ProductCard.types';

export function ProductCard({
  id = '',
  name = '',
  status = Status.Indevelopment,
  loading = false,
  setRightClickedProduct,
  setPoints,
  components,
}: ProductCardProps): JSX.Element {
  const navigate: NavigateFunction = useNavigate();

  /* ----------------------------------------------- Right Click Menu ----------------------------------------------- */

  const handleRightClickOnCard: (event: any) => void = useCallback((event: any) => {
    setPoints?.({ x: event.pageX, y: event.pageY });

    setRightClickedProduct?.(id);
  }, []);
  function handleClick(): void {
    if (loading) return;
    if (id === undefined) console.error('id or product is null or undefined');
    else navigate('/product/' + id);
  }

  return (
    <Card
      shadow="lg"
      onClick={handleClick}
      loading={loading}
      hasAnimation
      cursor="pointer"
      onRightClick={handleRightClickOnCard}
      height="full"
      background="sand"
    >
      {loading ? (
        <>
          <CardHeader loading>
            <Chip loading />
          </CardHeader>
          <CardBody>
            <IconBadge loading />
            <Text loading />
            <Text loading />
          </CardBody>
        </>
      ) : (
        <>
          <CardHeader bgColor="bg-gradient-to-r from-gradient3-from to-gradient3-to">
            <div className={concatClassNames('flex flex-row gap-2 items-center')}>
              {getIcon('product', 'white', 'sm')}

              <Text content="PRODUIT" size="xs" weight="bold" color="white" tracking="wide" position="center" />
            </div>
          </CardHeader>
          <CardBody margin="md" justify="start">
            <div className={concatClassNames('flex flex-col gap-3', 'items-center')}>
              <div className="flex flex-row gap-3 items-center">
                <Text content={name} size="base" weight="bold" color="black" tracking="wide" position="center" />
              </div>
              {getStatusChip({ name: status, textSize: 'sm' })}
              <div className="flex flex-row gap-1 items-center">
                {components?.map((component) => {
                  return getComponentSymbol({
                    type: component.componentType as ComponentType,
                    tooltipMessage: component.name,
                  });
                })}
              </div>
            </div>
          </CardBody>
        </>
      )}
    </Card>
  );
}
