/* tslint:disable */
/* eslint-disable */
/**
 * Specks API
 * APIs for specks SaaS
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdateUiComponentDateDto
 */
export interface UpdateUiComponentDateDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateUiComponentDateDto
     */
    functionalId?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateUiComponentDateDto
     */
    type: UpdateUiComponentDateDtoTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof UpdateUiComponentDateDto
     */
    name: string;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateUiComponentDateDto
     */
    isFormValid: boolean;
    /**
     * 
     * @type {string}
     * @memberof UpdateUiComponentDateDto
     */
    notes: string;
    /**
     * 
     * @type {number}
     * @memberof UpdateUiComponentDateDto
     */
    index: number;
    /**
     * 
     * @type {string}
     * @memberof UpdateUiComponentDateDto
     */
    parentId?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateUiComponentDateDto
     */
    label: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateUiComponentDateDto
     */
    isPreFilled: UpdateUiComponentDateDtoIsPreFilledEnum;
    /**
     * 
     * @type {string}
     * @memberof UpdateUiComponentDateDto
     */
    isPreFilledConstant: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateUiComponentDateDto
     */
    isPreFilledLogicalRule: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateUiComponentDateDto
     */
    hasPlaceholder: UpdateUiComponentDateDtoHasPlaceholderEnum;
    /**
     * 
     * @type {string}
     * @memberof UpdateUiComponentDateDto
     */
    hasPlaceholderConstant: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateUiComponentDateDto
     */
    hasPlaceholderLogicalRule: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateUiComponentDateDto
     */
    isMandatory: UpdateUiComponentDateDtoIsMandatoryEnum;
    /**
     * 
     * @type {string}
     * @memberof UpdateUiComponentDateDto
     */
    isMandatoryLogicalRule: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateUiComponentDateDto
     */
    dateFormat: UpdateUiComponentDateDtoDateFormatEnum;
    /**
     * 
     * @type {string}
     * @memberof UpdateUiComponentDateDto
     */
    dateCustom: string;
}


/**
 * @export
 */
export const UpdateUiComponentDateDtoTypeEnum = {
    Title: 'title',
    Icon: 'icon',
    Text: 'text',
    Container: 'container',
    Input: 'input',
    Button: 'button',
    List: 'list',
    Media: 'media',
    Date: 'date',
    Select: 'select'
} as const;
export type UpdateUiComponentDateDtoTypeEnum = typeof UpdateUiComponentDateDtoTypeEnum[keyof typeof UpdateUiComponentDateDtoTypeEnum];

/**
 * @export
 */
export const UpdateUiComponentDateDtoIsPreFilledEnum = {
    Yes: 'YES',
    No: 'NO',
    Logical: 'LOGICAL'
} as const;
export type UpdateUiComponentDateDtoIsPreFilledEnum = typeof UpdateUiComponentDateDtoIsPreFilledEnum[keyof typeof UpdateUiComponentDateDtoIsPreFilledEnum];

/**
 * @export
 */
export const UpdateUiComponentDateDtoHasPlaceholderEnum = {
    Yes: 'YES',
    No: 'NO',
    Logical: 'LOGICAL'
} as const;
export type UpdateUiComponentDateDtoHasPlaceholderEnum = typeof UpdateUiComponentDateDtoHasPlaceholderEnum[keyof typeof UpdateUiComponentDateDtoHasPlaceholderEnum];

/**
 * @export
 */
export const UpdateUiComponentDateDtoIsMandatoryEnum = {
    Yes: 'YES',
    No: 'NO',
    Logical: 'LOGICAL'
} as const;
export type UpdateUiComponentDateDtoIsMandatoryEnum = typeof UpdateUiComponentDateDtoIsMandatoryEnum[keyof typeof UpdateUiComponentDateDtoIsMandatoryEnum];

/**
 * @export
 */
export const UpdateUiComponentDateDtoDateFormatEnum = {
    Empty: 'EMPTY',
    DmyWithYear: 'DMY_WITH_YEAR',
    DmyWithDateTime: 'DMY_WITH_DATE_TIME',
    Dm: 'DM',
    Md: 'MD',
    Mmm: 'MMM',
    Mmmm: 'MMMM',
    Custom: 'CUSTOM'
} as const;
export type UpdateUiComponentDateDtoDateFormatEnum = typeof UpdateUiComponentDateDtoDateFormatEnum[keyof typeof UpdateUiComponentDateDtoDateFormatEnum];


/**
 * Check if a given object implements the UpdateUiComponentDateDto interface.
 */
export function instanceOfUpdateUiComponentDateDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "isFormValid" in value;
    isInstance = isInstance && "notes" in value;
    isInstance = isInstance && "index" in value;
    isInstance = isInstance && "label" in value;
    isInstance = isInstance && "isPreFilled" in value;
    isInstance = isInstance && "isPreFilledConstant" in value;
    isInstance = isInstance && "isPreFilledLogicalRule" in value;
    isInstance = isInstance && "hasPlaceholder" in value;
    isInstance = isInstance && "hasPlaceholderConstant" in value;
    isInstance = isInstance && "hasPlaceholderLogicalRule" in value;
    isInstance = isInstance && "isMandatory" in value;
    isInstance = isInstance && "isMandatoryLogicalRule" in value;
    isInstance = isInstance && "dateFormat" in value;
    isInstance = isInstance && "dateCustom" in value;

    return isInstance;
}

export function UpdateUiComponentDateDtoFromJSON(json: any): UpdateUiComponentDateDto {
    return UpdateUiComponentDateDtoFromJSONTyped(json, false);
}

export function UpdateUiComponentDateDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateUiComponentDateDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'functionalId': !exists(json, 'functionalId') ? undefined : json['functionalId'],
        'type': json['type'],
        'name': json['name'],
        'isFormValid': json['isFormValid'],
        'notes': json['notes'],
        'index': json['index'],
        'parentId': !exists(json, 'parentId') ? undefined : json['parentId'],
        'label': json['label'],
        'isPreFilled': json['isPreFilled'],
        'isPreFilledConstant': json['isPreFilledConstant'],
        'isPreFilledLogicalRule': json['isPreFilledLogicalRule'],
        'hasPlaceholder': json['hasPlaceholder'],
        'hasPlaceholderConstant': json['hasPlaceholderConstant'],
        'hasPlaceholderLogicalRule': json['hasPlaceholderLogicalRule'],
        'isMandatory': json['isMandatory'],
        'isMandatoryLogicalRule': json['isMandatoryLogicalRule'],
        'dateFormat': json['dateFormat'],
        'dateCustom': json['dateCustom'],
    };
}

export function UpdateUiComponentDateDtoToJSON(value?: UpdateUiComponentDateDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'functionalId': value.functionalId,
        'type': value.type,
        'name': value.name,
        'isFormValid': value.isFormValid,
        'notes': value.notes,
        'index': value.index,
        'parentId': value.parentId,
        'label': value.label,
        'isPreFilled': value.isPreFilled,
        'isPreFilledConstant': value.isPreFilledConstant,
        'isPreFilledLogicalRule': value.isPreFilledLogicalRule,
        'hasPlaceholder': value.hasPlaceholder,
        'hasPlaceholderConstant': value.hasPlaceholderConstant,
        'hasPlaceholderLogicalRule': value.hasPlaceholderLogicalRule,
        'isMandatory': value.isMandatory,
        'isMandatoryLogicalRule': value.isMandatoryLogicalRule,
        'dateFormat': value.dateFormat,
        'dateCustom': value.dateCustom,
    };
}

