/* tslint:disable */
/* eslint-disable */
/**
 * Specks API
 * APIs for specks SaaS
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  CreateUicPositionBatchDto,
  ErrorBadRequest,
  GetPageDto,
  GetPageRevisionDto,
  PageControllerCreateUiComponentRequest,
  UpdatePageDto,
  UpdateUicPositionsDto,
} from '../models';
import {
    CreateUicPositionBatchDtoFromJSON,
    CreateUicPositionBatchDtoToJSON,
    ErrorBadRequestFromJSON,
    ErrorBadRequestToJSON,
    GetPageDtoFromJSON,
    GetPageDtoToJSON,
    GetPageRevisionDtoFromJSON,
    GetPageRevisionDtoToJSON,
    PageControllerCreateUiComponentRequestFromJSON,
    PageControllerCreateUiComponentRequestToJSON,
    UpdatePageDtoFromJSON,
    UpdatePageDtoToJSON,
    UpdateUicPositionsDtoFromJSON,
    UpdateUicPositionsDtoToJSON,
} from '../models';

export interface PageControllerCreateBatchUiComponentRequest {
    id: string;
    createUicPositionBatchDto: CreateUicPositionBatchDto;
}

export interface PageControllerCreateUiComponentOperationRequest {
    id: string;
    pageControllerCreateUiComponentRequest: PageControllerCreateUiComponentRequest;
}

export interface PageControllerDeleteRequest {
    id: string;
}

export interface PageControllerFindByIdByDateRequest {
    revisionTimestamp: string;
    id: string;
}

export interface PageControllerMoveUiComponentRequest {
    id: string;
    updateUicPositionsDto: UpdateUicPositionsDto;
}

export interface PageControllerUpdateRequest {
    id: string;
    updatePageDto: UpdatePageDto;
}

/**
 * 
 */
export class PagesApi extends runtime.BaseAPI {

    /**
     */
    async pageControllerCreateBatchUiComponentRaw(requestParameters: PageControllerCreateBatchUiComponentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetPageRevisionDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling pageControllerCreateBatchUiComponent.');
        }

        if (requestParameters.createUicPositionBatchDto === null || requestParameters.createUicPositionBatchDto === undefined) {
            throw new runtime.RequiredError('createUicPositionBatchDto','Required parameter requestParameters.createUicPositionBatchDto was null or undefined when calling pageControllerCreateBatchUiComponent.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/pages/{id}/batchUiComponents`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateUicPositionBatchDtoToJSON(requestParameters.createUicPositionBatchDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetPageRevisionDtoFromJSON(jsonValue));
    }

    /**
     */
    async pageControllerCreateBatchUiComponent(requestParameters: PageControllerCreateBatchUiComponentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetPageRevisionDto> {
        const response = await this.pageControllerCreateBatchUiComponentRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async pageControllerCreateUiComponentRaw(requestParameters: PageControllerCreateUiComponentOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetPageRevisionDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling pageControllerCreateUiComponent.');
        }

        if (requestParameters.pageControllerCreateUiComponentRequest === null || requestParameters.pageControllerCreateUiComponentRequest === undefined) {
            throw new runtime.RequiredError('pageControllerCreateUiComponentRequest','Required parameter requestParameters.pageControllerCreateUiComponentRequest was null or undefined when calling pageControllerCreateUiComponent.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/pages/{id}/uiComponents`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PageControllerCreateUiComponentRequestToJSON(requestParameters.pageControllerCreateUiComponentRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetPageRevisionDtoFromJSON(jsonValue));
    }

    /**
     */
    async pageControllerCreateUiComponent(requestParameters: PageControllerCreateUiComponentOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetPageRevisionDto> {
        const response = await this.pageControllerCreateUiComponentRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete Page by ID
     * Supprimer une page
     */
    async pageControllerDeleteRaw(requestParameters: PageControllerDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling pageControllerDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/pages/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete Page by ID
     * Supprimer une page
     */
    async pageControllerDelete(requestParameters: PageControllerDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.pageControllerDeleteRaw(requestParameters, initOverrides);
    }

    /**
     */
    async pageControllerFindByIdByDateRaw(requestParameters: PageControllerFindByIdByDateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetPageDto>> {
        if (requestParameters.revisionTimestamp === null || requestParameters.revisionTimestamp === undefined) {
            throw new runtime.RequiredError('revisionTimestamp','Required parameter requestParameters.revisionTimestamp was null or undefined when calling pageControllerFindByIdByDate.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling pageControllerFindByIdByDate.');
        }

        const queryParameters: any = {};

        if (requestParameters.revisionTimestamp !== undefined) {
            queryParameters['revision_timestamp'] = requestParameters.revisionTimestamp;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/pages/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetPageDtoFromJSON(jsonValue));
    }

    /**
     */
    async pageControllerFindByIdByDate(requestParameters: PageControllerFindByIdByDateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetPageDto> {
        const response = await this.pageControllerFindByIdByDateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async pageControllerMoveUiComponentRaw(requestParameters: PageControllerMoveUiComponentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetPageRevisionDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling pageControllerMoveUiComponent.');
        }

        if (requestParameters.updateUicPositionsDto === null || requestParameters.updateUicPositionsDto === undefined) {
            throw new runtime.RequiredError('updateUicPositionsDto','Required parameter requestParameters.updateUicPositionsDto was null or undefined when calling pageControllerMoveUiComponent.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/pages/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateUicPositionsDtoToJSON(requestParameters.updateUicPositionsDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetPageRevisionDtoFromJSON(jsonValue));
    }

    /**
     */
    async pageControllerMoveUiComponent(requestParameters: PageControllerMoveUiComponentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetPageRevisionDto> {
        const response = await this.pageControllerMoveUiComponentRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async pageControllerUpdateRaw(requestParameters: PageControllerUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetPageDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling pageControllerUpdate.');
        }

        if (requestParameters.updatePageDto === null || requestParameters.updatePageDto === undefined) {
            throw new runtime.RequiredError('updatePageDto','Required parameter requestParameters.updatePageDto was null or undefined when calling pageControllerUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/pages/name/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdatePageDtoToJSON(requestParameters.updatePageDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetPageDtoFromJSON(jsonValue));
    }

    /**
     */
    async pageControllerUpdate(requestParameters: PageControllerUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetPageDto> {
        const response = await this.pageControllerUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
