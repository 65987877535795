/* tslint:disable */
/* eslint-disable */
/**
 * Specks API
 * APIs for specks SaaS
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CompanyIdDto } from './CompanyIdDto';
import {
    CompanyIdDtoFromJSON,
    CompanyIdDtoFromJSONTyped,
    CompanyIdDtoToJSON,
} from './CompanyIdDto';
import type { DictionaryIdDto } from './DictionaryIdDto';
import {
    DictionaryIdDtoFromJSON,
    DictionaryIdDtoFromJSONTyped,
    DictionaryIdDtoToJSON,
} from './DictionaryIdDto';
import type { ExternalLinkDto } from './ExternalLinkDto';
import {
    ExternalLinkDtoFromJSON,
    ExternalLinkDtoFromJSONTyped,
    ExternalLinkDtoToJSON,
} from './ExternalLinkDto';
import type { FaqIdDto } from './FaqIdDto';
import {
    FaqIdDtoFromJSON,
    FaqIdDtoFromJSONTyped,
    FaqIdDtoToJSON,
} from './FaqIdDto';
import type { FeatureIdDto } from './FeatureIdDto';
import {
    FeatureIdDtoFromJSON,
    FeatureIdDtoFromJSONTyped,
    FeatureIdDtoToJSON,
} from './FeatureIdDto';
import type { GenesisIdDto } from './GenesisIdDto';
import {
    GenesisIdDtoFromJSON,
    GenesisIdDtoFromJSONTyped,
    GenesisIdDtoToJSON,
} from './GenesisIdDto';
import type { GetComponentDto } from './GetComponentDto';
import {
    GetComponentDtoFromJSON,
    GetComponentDtoFromJSONTyped,
    GetComponentDtoToJSON,
} from './GetComponentDto';

/**
 * 
 * @export
 * @interface GetProductDto
 */
export interface GetProductDto {
    /**
     * 
     * @type {string}
     * @memberof GetProductDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof GetProductDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof GetProductDto
     */
    status: GetProductDtoStatusEnum;
    /**
     * 
     * @type {GenesisIdDto}
     * @memberof GetProductDto
     */
    genesis: GenesisIdDto;
    /**
     * 
     * @type {DictionaryIdDto}
     * @memberof GetProductDto
     */
    dictionary: DictionaryIdDto;
    /**
     * 
     * @type {FaqIdDto}
     * @memberof GetProductDto
     */
    faq: FaqIdDto;
    /**
     * 
     * @type {Array<ExternalLinkDto>}
     * @memberof GetProductDto
     */
    externalLinks: Array<ExternalLinkDto>;
    /**
     * 
     * @type {Array<FeatureIdDto>}
     * @memberof GetProductDto
     */
    features: Array<FeatureIdDto>;
    /**
     * 
     * @type {CompanyIdDto}
     * @memberof GetProductDto
     */
    company: CompanyIdDto;
    /**
     * 
     * @type {Array<GetComponentDto>}
     * @memberof GetProductDto
     */
    components: Array<GetComponentDto>;
}


/**
 * @export
 */
export const GetProductDtoStatusEnum = {
    Production: 'production',
    Planed: 'planed',
    InDevelopment: 'inDevelopment',
    InDecommissionning: 'inDecommissionning',
    Decommissioned: 'decommissioned',
    InExploration: 'inExploration',
    Evolving: 'evolving'
} as const;
export type GetProductDtoStatusEnum = typeof GetProductDtoStatusEnum[keyof typeof GetProductDtoStatusEnum];


/**
 * Check if a given object implements the GetProductDto interface.
 */
export function instanceOfGetProductDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "status" in value;
    isInstance = isInstance && "genesis" in value;
    isInstance = isInstance && "dictionary" in value;
    isInstance = isInstance && "faq" in value;
    isInstance = isInstance && "externalLinks" in value;
    isInstance = isInstance && "features" in value;
    isInstance = isInstance && "company" in value;
    isInstance = isInstance && "components" in value;

    return isInstance;
}

export function GetProductDtoFromJSON(json: any): GetProductDto {
    return GetProductDtoFromJSONTyped(json, false);
}

export function GetProductDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetProductDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'status': json['status'],
        'genesis': GenesisIdDtoFromJSON(json['genesis']),
        'dictionary': DictionaryIdDtoFromJSON(json['dictionary']),
        'faq': FaqIdDtoFromJSON(json['faq']),
        'externalLinks': ((json['externalLinks'] as Array<any>).map(ExternalLinkDtoFromJSON)),
        'features': ((json['features'] as Array<any>).map(FeatureIdDtoFromJSON)),
        'company': CompanyIdDtoFromJSON(json['company']),
        'components': ((json['components'] as Array<any>).map(GetComponentDtoFromJSON)),
    };
}

export function GetProductDtoToJSON(value?: GetProductDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'status': value.status,
        'genesis': GenesisIdDtoToJSON(value.genesis),
        'dictionary': DictionaryIdDtoToJSON(value.dictionary),
        'faq': FaqIdDtoToJSON(value.faq),
        'externalLinks': ((value.externalLinks as Array<any>).map(ExternalLinkDtoToJSON)),
        'features': ((value.features as Array<any>).map(FeatureIdDtoToJSON)),
        'company': CompanyIdDtoToJSON(value.company),
        'components': ((value.components as Array<any>).map(GetComponentDtoToJSON)),
    };
}

