import { SpecksApisContext } from 'App';
import { type CreateAppSchemaRevisionDto } from 'api';
import { type XYCoord } from 'dnd-core';

import React, { type SetStateAction, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useDrop } from 'react-dnd';
import { type QueryClient, useMutation, useQuery, useQueryClient } from 'react-query';
import { type NavigateFunction, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import uuid from 'react-uuid';
import {
  type Connection,
  type Edge,
  type EdgeChange,
  type HandleType,
  type Node,
  type NodeChange,
  type NodeRemoveChange,
  type OnConnectStartParams,
  type ReactFlowInstance,
  type XYPosition,
  addEdge,
  useEdgesState,
  useNodesState,
} from 'reactflow';

import { useAuth0 } from '@auth0/auth0-react';

import { CreateOrEditComponentForm } from 'forms/CreateOrEditComponentForm/CreateOrEditComponentForm';

import { Button } from 'components/core/Button/Button';
import { CanvaFrame } from 'components/core/CanvaFrame/CanvaFrame';
import 'components/core/CanvaFrame/styles.css';
import { Modal } from 'components/core/Modal/Modal';
import { type ButtonMenuProps } from 'components/core/Settings/Settings.types';
import { Text } from 'components/core/Text/Text';
import { AppSchemaCard } from 'components/specks/AppSchemaCard/AppSchemaCard';
import { ContextMenu } from 'components/specks/ContextMenu/ContextMenu';
import { Empty } from 'components/specks/Empty/Empty';
import { type INodeData } from 'components/specks/ReactFlowCustom/AppSchemaNode/AppSchemaNode';
import { uiComponentCardType } from 'components/specks/UiComponentCard/UiComponentCard.props';

import { ComponentType } from 'models/ComponentTypes.type';
import { type Edge as EdgeEntity } from 'models/Edge.entity';
import { type Node as NodeEntity } from 'models/Node.entity';
import { type AppSchemaRevision } from 'models/appSchema.entity';
import { type Component } from 'models/component.entity';
import { type Link } from 'models/link.entity';

import concatClassNames from 'utils/classNames';
import delay from 'utils/delay';

export type onEdgeUpdate = (oldEdge: Edge, newConnection: Connection) => void;
export type onEdgeUpdateStart = (event: React.MouseEvent, edge: Edge, handleType: HandleType) => void;
export type onEdgeUpdateEnd = (event: React.MouseEvent, edge: Edge, handleType: HandleType) => void;

interface IComponent {
  component: Component;
}

interface IAppSchemaRevision {
  productId: string;
  createAppSchemaRevisionDto: CreateAppSchemaRevisionDto;
}

const snapGridStep: number = 20;

interface AppSchemaComponentProps {
  isEditable: boolean;
  productId: string;
  setIsCanvaModified?: (isCanvaModified: boolean) => void;
  setIsAppSchemaSaving?: (isAppSchemaSaving: boolean) => void;
  isAppSchemaSaving?: boolean;
}
export function AppSchemaComponent({
  isEditable,
  productId,
  setIsCanvaModified,
  setIsAppSchemaSaving,
  isAppSchemaSaving,
}: AppSchemaComponentProps): JSX.Element {
  /* --------------------------------------------------- contexts --------------------------------------------------- */

  const { componentService, productService, appSchemaService } = useContext(SpecksApisContext);

  const navigate: NavigateFunction = useNavigate();
  const { getAccessTokenSilently } = useAuth0();

  /* --------------------------------------------------- variables -------------------------------------------------- */

  // AMU : dupliquer les states
  /* --------------------------------------------------- states ----------------------------------------------------- */
  const [isDataFetched, setIsDataFetched] = useState<boolean>(false);
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges] = useEdgesState([]);
  const [reactFlowInstance, setReactFlowInstance] = useState<ReactFlowInstance | null>(null);
  const [canva, setCanva] = useState<HTMLDivElement | null>(null);
  const [isModalOpened, setIsModalOpened] = useState<boolean>(false);
  // Quit without saving
  const [isAlertUnsavedModalOpened, setIsAlertUnsavedModalOpened] = useState<boolean>(false);
  // Click on node
  const [isAlertLeavingModalOpened, setIsAlertLeavingModalOpened] = useState<boolean>(false);
  const [componentToNavigate, setComponentToNavigate] = useState<INodeData>();

  const [isCreateComponentLoading, setIsCreateComponentLoading] = useState<boolean>(false);
  const [isNotAllowedToDeleteNodeModalOpened, setIsNotAllowedToDeleteNodeModalOpened] = useState<boolean>(false);
  const [isNotAllowedToRemoveEdgeModalOpened, setIsNotAllowedToRemoveEdgeModalOpened] = useState<boolean>(false);

  const [componentCreated, setComponentCreated] = useState<string>();

  /* --------------------------------------------------- Queries ----------- --------------------------------------- */

  const queryClient: QueryClient = useQueryClient();

  const { data: appSchemasRevisions, status: appSchemasRevisionsStatus } = useQuery<AppSchemaRevision[], Error>(
    ['appSchemasData', productId, 'edit'],
    async () => {
      const accessToken: string = await getAccessTokenSilently();
      return await productService.getAppSchemaRevisionsRevisions(productId, accessToken);
    },
    { cacheTime: 0 },
  );

  const { data: components, status: componentsStatus } = useQuery<Component[], Error>(
    ['components', productId],
    async () => {
      const accessToken: string = await getAccessTokenSilently();
      return await productService.findAppSchemaComponents(productId, accessToken);
    },
  );

  const appSchemaId: string | undefined = appSchemasRevisions?.[0]?.id;
  const { data: appSchema, status: appSchemaStatus } = useQuery<AppSchemaRevision, Error>(
    ['appSchema', appSchemaId],
    async () => {
      const accessToken: string = await getAccessTokenSilently();
      return await appSchemaService.getAppSchemaRevision(appSchemaId ?? '', accessToken);
    },
    {
      enabled: appSchemaId !== undefined,
    },
  );

  const [componentToRemove, setComponentToRemove] = useState<string>();
  const { data: linksData, status: linksStatus } = useQuery<Link[], Error>({
    queryKey: ['links', 'nodes', componentToRemove],
    queryFn: async ({ queryKey }) => {
      const [, , componentToRemove] = queryKey;
      const accessToken: string = await getAccessTokenSilently();
      return await componentService.findLinks(componentToRemove as string, accessToken, true, productId);
    },
    enabled: componentToRemove !== undefined,
  });

  /* -------------------------------------------------- Setup Data -------------------------------------------------- */

  useEffect(() => {
    window.addEventListener('click', handleClick);
    return () => {
      window.removeEventListener('click', handleClick);
    };
  }, []);

  const handleRightClickOnNode: (event: any, componentId: string) => void = useCallback(
    (event: any, componentId: string) => {
      if (isEditable) {
        setIsCustomContextMenuOpened(true);
        setRightClickedComponent(componentId);
        setPoints({ x: event.pageX, y: event.pageY - 80 });
      }
    },
    [isEditable],
  );

  useEffect(() => {
    if (isAppSchemaSaving === true) {
      handleSaveClick();
    }
  }, [isAppSchemaSaving]);

  useEffect(() => {
    const initialNodes: Array<Node<INodeData>> = [];
    const initialEdges: Edge[] = [];
    if (appSchemaStatus === 'success') {
      appSchema.nodes.forEach((node: NodeEntity) => {
        return initialNodes.push({
          id: node.id,
          type: 'appSchema',
          data: {
            name: node.component.name,
            status: node.component.status,
            type: node.component.componentType,
            componentId: node.component.id,
            isDragging: false,
            isEditable,
            onRightClickOnNode: handleRightClickOnNode,
            isInternal: node.component.internal,
          },
          position: { x: node.positionX, y: node.positionY },
        });
      });
      appSchema.edges.forEach((edge: EdgeEntity) => {
        return initialEdges.push({
          id: edge.id,
          source: edge.sourceNode.id,
          sourceHandle: edge.sourceStringId,
          target: edge.targetNode.id,
          targetHandle: edge.targetStringId,
          type: 'appSchemaEdge',
        });
      });
      setEdges(initialEdges);
      setNodes(initialNodes);
    }
    if ((appSchemasRevisionsStatus === 'success' && appSchemasRevisions?.length === 0) || appSchemaStatus === 'success')
      setIsDataFetched(true);
  }, [
    setEdges,
    setNodes,
    setIsDataFetched,
    appSchemaStatus,
    appSchema,
    appSchemasRevisionsStatus,
    appSchemasRevisions,
    isDataFetched,
    isEditable,
    handleRightClickOnNode,
  ]);

  /* ---------------------------------------------- ReactFlow Fucntions --------------------------------------------- */

  function onInit(): void {
    setIsCanvaModified?.(false);
  }

  function handleNodeChange(changes: NodeChange[]): void {
    // Fix to avoid save button to be enabled when nothing changed
    if (changes.length === 0) return;
    if (changes[0].type === 'dimensions') return;
    if (changes[0].type === 'remove') {
      const nodeToRemove: Node | undefined = nodes.find((node) => node.data.id === (changes[0] as NodeRemoveChange));

      if (nodeToRemove !== undefined) {
        setComponentToRemove(nodeToRemove.data.id);
      }
    }
    if (changes[0].type === 'position') {
      setIsCanvaModified?.(true);
      onNodesChange(changes);
    }
  }

  function handleEdgeChange(changes: EdgeChange[]): void {
    if (changes[0].type === 'remove') {
      const edge: Edge | undefined = edges.find((edge) => edge.id === (changes[0] as NodeRemoveChange).id);
      if (edge !== undefined) {
        onEdgeUpdateEnd(edge, nodes);
      }
    }
  }

  const onConnect: (params: Edge | Connection) => void = useCallback(
    (params: Edge | Connection) => {
      setEdges((eds) => addEdge({ ...params, type: 'appSchemaEdge' }, eds));
      setIsCanvaModified?.(true);
    },
    [setEdges],
  );
  function handleConnectStart(event: unknown, params: OnConnectStartParams): void {
    setNodes(
      nodes.map((node) => ({
        ...node,
        data: { ...node.data, isDragging: true, isEditable },
      })),
    );
  }
  function handleConnectStop(): void {
    setNodes(nodes.map((node) => ({ ...node, data: { ...node.data, handleType: 'none', isEditable } })));
  }

  /* ------------------------------------------------ Page Functions ------------------------------------------------ */

  function handleClickEdit(): void {
    navigate(`/product/${productId}/app?edition=true`);
  }

  function handleOnNodeClick(event: React.MouseEvent, node: Node): void {
    if (isEditable) return;
    if (node.data.type === ComponentType.Database) return;
    navigate(`/component/${(node.data as INodeData)?.componentId ?? ''}`);
  }

  function isAppSchemaEmpty(): boolean {
    return nodes.length === 0 && edges.length === 0;
  }

  /* -------------------------------------------------- Delete Edge ------------------------------------------------- */

  const onEdgeUpdate: any = useCallback((oldEdge: Edge, newConnection: Connection) => {
    // TODO : Do something, use it or delete it
    console.log('onEdgeUpdate');
  }, []);

  const edgeUpdateSuccessful: React.MutableRefObject<boolean> = useRef(true);

  const onEdgeUpdateStart: any = useCallback(() => {
    setIsCanvaModified?.(true);
    edgeUpdateSuccessful.current = false;
  }, []);

  // TODO : Refactor

  const [edgeBearerLinks, setEdgeBearerLinks] = useState<Link[]>();
  const [edgeToRemove, setEdgeToRemove] = useState<Edge>();

  const onEdgeUpdateEnd: any = useCallback((edge: Edge, nodes: Node[]) => {
    setEdgeToRemove(edge);
    const sourceNode: Node | undefined = nodes.find((node) => node.id === edge.source);
    const sourceComponentId: string = sourceNode?.data.componentId;
    setSourceComponentofEdgeToRemove(sourceComponentId);
  }, []);

  const [sourceComponentofEdgeToRemove, setSourceComponentofEdgeToRemove] = useState<string>();
  const { data: edgeLinksData, status: edgeLinksStatus } = useQuery<Link[], Error>({
    queryKey: ['links', 'edge', sourceComponentofEdgeToRemove],
    queryFn: async ({ queryKey }) => {
      const [, , sourceComponentofEdgeToRemove] = queryKey;
      const accessToken: string = await getAccessTokenSilently();
      return await componentService.findLinks(sourceComponentofEdgeToRemove as string, accessToken, true, productId);
    },
    enabled: sourceComponentofEdgeToRemove !== undefined,
  });

  useEffect(() => {
    if (edgeLinksStatus === 'success') {
      const targetComponentId: string = nodes.find((node) => node.id === edgeToRemove?.target)?.data.componentId;

      const bearerLinks: Link[] = edgeLinksData.filter(
        (link) => link.receiver.id === targetComponentId || link.caller.id === targetComponentId,
      );
      const edgesCount: number = edges.filter(
        (edge) => edge.source === edgeToRemove?.source && edge.target === edgeToRemove?.target,
      ).length;
      if (bearerLinks.length !== 0 && edgesCount === 1) {
        setEdgeBearerLinks(bearerLinks);
        setIsNotAllowedToRemoveEdgeModalOpened(true);
      } else if (!edgeUpdateSuccessful.current) {
        setIsCanvaModified?.(true);
        setEdgeToRemove(undefined);
        setSourceComponentofEdgeToRemove(undefined);
        setEdges((eds) => eds.filter((e) => e.id !== edgeToRemove?.id));
      }
    }
  }, [edgeLinksData, edgeLinksStatus, edgeToRemove?.id, edgeToRemove?.target, nodes, setEdges]);

  /* ----------------------------------------- remove Component from Schema ----------------------------------------- */

  const [isCustomContextMenuOpened, setIsCustomContextMenuOpened] = useState<boolean>(false);

  const [points, setPoints] = useState({ x: 0, y: 0 });
  const [rightClickedComponent, setRightClickedComponent] = useState<string>('');

  const buttonsMenu: ButtonMenuProps[] = [
    {
      name: 'Retirer ce composant du schéma',
      textColor: 'black',
      isEnabled: true,
      onClick: (event: any) => {
        event.stopPropagation();

        setComponentToRemove(rightClickedComponent);
      },
    },
  ];

  useEffect(() => {
    if (linksStatus === 'success' && linksData?.length > 0) {
      setIsCustomContextMenuOpened(false);
      setIsNotAllowedToDeleteNodeModalOpened(true);
      // setComponentToRemove(undefined);
    } else if (linksStatus === 'success' && linksData?.length === 0) {
      if (componentToRemove === undefined) return;
      setIsCustomContextMenuOpened(false);

      setIsCanvaModified?.(true);
      const nodeToRemove: Node | undefined = nodes?.find((node) => node.data.componentId === componentToRemove);

      setNodes(nodes.filter((node) => node.data.componentId !== componentToRemove));
      setEdges(edges.filter((edge) => edge.source !== nodeToRemove?.id && edge.target !== nodeToRemove?.id));
      setComponentToRemove(undefined);
    }
  }, [componentToRemove, linksStatus, linksData, setNodes, nodes, setEdges, edges]);

  function handleClick(): void {
    setIsCustomContextMenuOpened(false);
  }

  /* ----------------------------------------------- Create Component ----------------------------------------------- */

  async function createComponent({ component }: IComponent): Promise<Component> {
    const accessToken: string = await getAccessTokenSilently();
    return await componentService.create(component, accessToken);
  }

  const { mutate: mutateCreateComponent } = useMutation(createComponent, {
    onSuccess: (component: Component) => {
      queryClient.setQueryData<Component[]>(['components', productId], (oldData: Component[] | undefined) => {
        if (oldData === undefined) {
          return new Array<Component>();
        }
        oldData.push(component);
        oldData.sort((a: Component, b: Component) => {
          return a.name.localeCompare(b.name);
        });

        return oldData;
      });
      setIsModalOpened(false);
      setComponentCreated(component.id);
      toast.success('Composant créé avec succès.');
    },
    onError: () => {
      toast.error('Une erreur est survenue lors de la création du composant.');
    },
  });

  function handleComponentFormSubmission(component: Component): void {
    setIsCreateComponentLoading(true);
    mutateCreateComponent({
      component,
    });
  }

  useEffect(() => {
    const formElement: HTMLElement | null = document.getElementById(componentCreated ?? '');
    if (formElement != null) {
      formElement.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
      formElement.focus({ preventScroll: true });
    }
  }, [componentCreated]);

  /* -------------------------------------------- Save AppSchemaRevision -------------------------------------------- */

  async function createAppSchemaRevision({
    productId,
    createAppSchemaRevisionDto,
  }: IAppSchemaRevision): Promise<AppSchemaRevision> {
    // setIsAppSchemaSaving(true);
    const accessToken: string = await getAccessTokenSilently();
    return await productService.createAppSchemaRevision(productId, createAppSchemaRevisionDto, accessToken);
  }

  const { mutate: mutateSaveAppSchemaRevision } = useMutation(createAppSchemaRevision, {
    onSuccess: (appSchema: AppSchemaRevision) => {
      queryClient.setQueryData<AppSchemaRevision[]>(
        ['appSchemasData', productId, 'edit'],
        (oldData: AppSchemaRevision[] | undefined) => {
          if (oldData === undefined) {
            return new Array<AppSchemaRevision>();
          }
          oldData.unshift(appSchema);
          return oldData;
        },
      );
      toast.success('Schéma applicatif sauvegardé avec succès.');
      setIsCanvaModified?.(false);
      navigate(`/product/${productId}/app`);
      setIsAppSchemaSaving?.(false);
    },
    onError: () => {
      toast.error('Une erreur est survenue lors de la sauvegarde du schéma applicatif.');
      setIsAppSchemaSaving?.(false);
    },
    onSettled: () => {
      // setIsAppSchemaSaving(false);
      console.log('Look');
    },
  });

  function handleSaveClick(): void {
    const createdAppSchemaRevisionDto: CreateAppSchemaRevisionDto = {
      nodes: nodes.map((node: Node) => {
        return {
          reactFlowId: node.id,
          positionX: node.position.x,
          positionY: node.position.y,
          component: {
            id: node.data.componentId,
          },
        };
      }),
      edges: edges.map((edge: Edge) => {
        return {
          sourceNode: edge.source,
          sourceHandleId: edge.sourceHandle ?? '',
          targetNode: edge.target,
          targetHandleId: edge.targetHandle ?? '',
        };
      }),
    };

    mutateSaveAppSchemaRevision({
      productId,
      createAppSchemaRevisionDto: createdAppSchemaRevisionDto,
    });
  }
  /* ---------------------------------------------------- Modals ---------------------------------------------------- */

  function handleClickAddComponent(): void {
    setIsCreateComponentLoading(false);
    setIsModalOpened(true);
  }

  /* ----------------------------------------------------- Drop ----------------------------------------------------- */

  function round(number: number, increment: number, offset: number): number {
    return Math.ceil((number - offset) / increment) * increment + offset;
  }

  const [, drop] = useDrop({
    accept: 'AppSchemaRevisionCard',
    drop(item, monitor) {
      const component: Component = item as Component;
      const mousePosition: XYCoord | null = monitor.getClientOffset();
      if (canva != null && mousePosition != null) {
        const canvaPosition: DOMRect = canva.getBoundingClientRect();

        if (reactFlowInstance == null) return;
        const mouseCanvaPosition: XYPosition = reactFlowInstance.project({
          x: mousePosition.x - canvaPosition.left,
          y: mousePosition.y - canvaPosition.top,
        });
        setNodes([
          ...nodes,
          {
            id: uuid(),
            type: 'appSchema',
            data: {
              name: component.name,
              status: component.status,
              type: component.componentType,
              componentId: component.id,
              handleType: 'none',
              onlyView: false,
              onRightClickOnNode: handleRightClickOnNode,
            },
            // TODO : Width and height based on acual component size
            position: {
              x: round(mouseCanvaPosition.x - 72, snapGridStep, 0),
              y: round(mouseCanvaPosition.y - 72, snapGridStep, 0),
            },
          },
        ]);
      }
      setIsCanvaModified?.(true);
    },
  });

  const refCallback: (reactFlowDiv: HTMLDivElement | null) => void = useCallback(
    (reactFlowDiv: HTMLDivElement | null) => {
      setCanva(reactFlowDiv);
      drop(reactFlowDiv);
    },
    [drop],
  );

  function handleOnInit(initReactFlowInstance: ReactFlowInstance): void {
    setReactFlowInstance(initReactFlowInstance);
    if (onInit != null) onInit();
  }

  return (
    <>
      <Modal title="Création d'un composant" isOpen={isModalOpened} setIsOpen={setIsModalOpened}>
        <CreateOrEditComponentForm onSubmit={handleComponentFormSubmission} isLoading={isCreateComponentLoading} />
      </Modal>

      <Modal
        title="Attention"
        isOpen={isNotAllowedToDeleteNodeModalOpened}
        setIsOpen={(b: SetStateAction<boolean>) => {
          setIsNotAllowedToDeleteNodeModalOpened(b);
          if (b === false) setComponentToRemove(undefined);
        }}
      >
        <>
          {linksStatus === 'success' && linksData.length !== 0 && (
            <>
              <Text
                whitespace="pre-line"
                content={`Vous ne pouvez pas retirer ce composant du schéma car les spécifications indiquent les dépendances suivantes :`}
                position="justify"
              />
              {linksData.map((link: Link) => (
                <li key={link.id}>
                  <a
                    className="text-purple-600"
                    href={`/component/${link.caller.id}/front/pages/${link.bearerForCallerPage.id}`}
                    target="blank"
                  >
                    {`${uiComponentCardType[link.bearerForCaller.type].canvaLabel} "${
                      link.bearerForCallerPage.pageRevisions[0].uiComponents[0].uiComponentRevisions[0].name
                    }"`}
                  </a>
                </li>
              ))}
            </>
          )}
          {linksStatus === 'loading' && <Button iconName="spinCircle" width="1/2" height="sm" iconAnimation="spin" />}
          {linksStatus === 'error' && (
            <Button iconName="spinCircle" width="1/2" height="sm" iconAnimation="spin" disabled />
          )}
        </>
      </Modal>
      <Modal
        title="Attention"
        isOpen={isNotAllowedToRemoveEdgeModalOpened}
        setIsOpen={(b: SetStateAction<boolean>) => {
          setIsNotAllowedToRemoveEdgeModalOpened(b);
          if (b === false) setSourceComponentofEdgeToRemove(undefined);
        }}
      >
        <>
          {edgeLinksStatus === 'success' && edgeLinksData.length !== 0 && (
            <>
              <Text
                whitespace="pre-line"
                content={`Vous ne pouvez pas retirer le lien entre ces deux composants car les spécifications indiquent les dépendances suivantes :`}
                position="justify"
              />
              {edgeBearerLinks?.map((link: Link) => (
                <li key={link.id}>
                  <a
                    className="text-purple-600"
                    href={`/component/${link.caller.id}/front/pages/${link.bearerForCallerPage.id}`}
                    target="blank"
                  >
                    {`${uiComponentCardType[link.bearerForCaller.type].canvaLabel} "${
                      link.bearerForCallerPage.pageRevisions[0].uiComponents[0].uiComponentRevisions[0].name
                    }"`}
                  </a>
                </li>
              ))}
            </>
          )}
          {linksStatus === 'loading' && <Button iconName="spinCircle" width="1/2" height="sm" iconAnimation="spin" />}
          {linksStatus === 'error' && (
            <Button iconName="spinCircle" width="1/2" height="sm" iconAnimation="spin" disabled />
          )}
        </>
      </Modal>
      <Modal title="Attention" isOpen={isAlertUnsavedModalOpened} setIsOpen={setIsAlertUnsavedModalOpened}>
        <>
          <Text
            whitespace="pre-line"
            content={`Vous allez perdre définitivement les éléments non sauvegardés.
            Êtes-vous certain de vouloir poursuivre ?`}
            position="justify"
          />
          <div className="flex flex-row gap-2 justify-end">
            <Button
              onClick={async () => {
                setIsAlertUnsavedModalOpened(false);
                await delay(150);
                setIsCanvaModified?.(false);
                navigate(`/product/${productId}/app`);
              }}
              content="Quitter"
              width="1/2"
              type="submit"
              height="sm"
            />
          </div>
        </>
      </Modal>

      <Modal title="Accéder au composant" isOpen={isAlertLeavingModalOpened} setIsOpen={setIsAlertLeavingModalOpened}>
        <>
          <Text
            whitespace="pre-line"
            content={`Vous allez être redirigé vers la page du composant : ${componentToNavigate?.name ?? ''}.
            Êtes-vous certain de vouloir poursuivre ?`}
            position="justify"
          />
          <div className="flex flex-row gap-2 justify-end">
            <Button
              onClick={async () => {
                setIsAlertLeavingModalOpened(false);
                navigate(`/component/${componentToNavigate?.componentId ?? ''}`);
              }}
              content="Poursuivre vers le composant"
              width="1/2"
              type="submit"
              height="sm"
            />
          </div>
        </>
      </Modal>

      <div id="content" className={concatClassNames('relative flex flex-row flex-grow overflow-auto')}>
        {isEditable && (
          <div
            id="sidePanel"
            className={concatClassNames(
              'flex flex-col',
              'gap-8 p-8',
              'w-[40%]',
              'border-t-2 border-r-2 border-gray-50',
              'justify-between',
            )}
          >
            <div className="flex flex-col gap-8 overflow-auto flex-grow">
              <Text content="Ajouter un composant existant" size="lg" weight="bold" />
              <ul className="flex flex-col gap-4 overflow-auto flex-grow">
                {componentsStatus === 'success' &&
                  components.length > 0 &&
                  components.map((component: Component) => {
                    return (
                      <li key={component.id} id={component.id}>
                        <AppSchemaCard
                          name={component.name}
                          componentType={component.componentType}
                          status={component.status}
                          componentId={component.id}
                          isInSchema={nodes.find((node: Node) => node.data.componentId === component.id) !== undefined}
                          isInternal={component.internal}
                          disabled={
                            component.isAlreadyUsedInAnotherAppSchema && component.componentType === ComponentType.Front
                          }
                        />
                      </li>
                    );
                  })}
                {componentsStatus === 'success' && components.length === 0 && (
                  <Empty
                    title="Aucun composant disponible"
                    subtitle="Commencez par ajouter un composant avec le bouton en dessous"
                    icon="component"
                  />
                )}
                {componentsStatus === 'loading' && (
                  <>
                    <li>
                      <AppSchemaCard loading />
                    </li>
                    <li>
                      <AppSchemaCard loading />
                    </li>
                    <li>
                      <AppSchemaCard loading />
                    </li>
                  </>
                )}
              </ul>
            </div>

            <div className={concatClassNames('border-t-1 border-gray-25', 'pt-6')}>
              <Button
                content="Nouveau composant applicatif"
                height="fit"
                iconName="plus"
                iconColor="white"
                onClick={handleClickAddComponent}
              />
            </div>
          </div>
        )}
        {isDataFetched ? (
          <div id="canva_wrapper" className={concatClassNames('relative', 'flex', 'w-full')}>
            {isAppSchemaEmpty() && !isEditable && (
              <div
                className={concatClassNames(
                  'absolute',
                  'flex flex-col',
                  'h-full',
                  'w-full',
                  'justify-center',
                  'items-center',
                  'z-10',
                )}
              >
                <Empty title="Le schéma applicatif est vide" width="2/6" height="fit" icon="schema">
                  <Button
                    content="Construire le schéma applicatif du produit"
                    height="fit"
                    width="fit"
                    iconName="plus"
                    iconColor="white"
                    onClick={handleClickEdit}
                  />
                </Empty>
              </div>
            )}
            {nodes.length === 0 && isEditable && (
              <div className={concatClassNames('absolute', 'z-10', 'flex', 'w-full', 'justify-center', 'top-4')}>
                <p>Vous pouvez glisser et deposer vos composants applicatifs dans le schéma applicatif</p>
                {/* <Empty title="Glissez-déposez un composant pour commencer" width="2/6" height="fit" icon="schema" /> */}
              </div>
            )}
            <CanvaFrame
              edges={edges}
              nodes={nodes}
              onNodesChange={handleNodeChange}
              onEdgesChange={handleEdgeChange}
              onEdgeUpdate={onEdgeUpdate}
              onEdgeUpdateStart={onEdgeUpdateStart}
              onEdgeUpdateEnd={onEdgeUpdateEnd}
              isEditable={isEditable}
              onInit={handleOnInit}
              onClick={handleOnNodeClick}
              onConnect={onConnect}
              onConnectStart={handleConnectStart}
              onConnectStop={handleConnectStop}
              snapGridStep={snapGridStep}
              refCallBack={refCallback}
            />
          </div>
        ) : (
          <div className={concatClassNames('h-full', 'w-full', 'bg-gray-25', 'animate-pulse')}></div>
        )}
        {isCustomContextMenuOpened && <ContextMenu x={points?.x ?? 2} y={points?.y ?? 3} buttonsMenu={buttonsMenu} />}
      </div>
    </>
  );
}
