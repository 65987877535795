/* tslint:disable */
/* eslint-disable */
/**
 * Specks API
 * APIs for specks SaaS
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Component } from './Component';
import {
    ComponentFromJSON,
    ComponentFromJSONTyped,
    ComponentToJSON,
} from './Component';
import type { ExternalLink } from './ExternalLink';
import {
    ExternalLinkFromJSON,
    ExternalLinkFromJSONTyped,
    ExternalLinkToJSON,
} from './ExternalLink';
import type { Resource } from './Resource';
import {
    ResourceFromJSON,
    ResourceFromJSONTyped,
    ResourceToJSON,
} from './Resource';

/**
 * 
 * @export
 * @interface BackApiSpecification
 */
export interface BackApiSpecification {
    /**
     * 
     * @type {string}
     * @memberof BackApiSpecification
     */
    id: string;
    /**
     * 
     * @type {Array<Resource>}
     * @memberof BackApiSpecification
     */
    resources: Array<Resource>;
    /**
     * 
     * @type {Array<ExternalLink>}
     * @memberof BackApiSpecification
     */
    externalLinks: Array<ExternalLink>;
    /**
     * 
     * @type {Component}
     * @memberof BackApiSpecification
     */
    component: Component;
}

/**
 * Check if a given object implements the BackApiSpecification interface.
 */
export function instanceOfBackApiSpecification(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "resources" in value;
    isInstance = isInstance && "externalLinks" in value;
    isInstance = isInstance && "component" in value;

    return isInstance;
}

export function BackApiSpecificationFromJSON(json: any): BackApiSpecification {
    return BackApiSpecificationFromJSONTyped(json, false);
}

export function BackApiSpecificationFromJSONTyped(json: any, ignoreDiscriminator: boolean): BackApiSpecification {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'resources': ((json['resources'] as Array<any>).map(ResourceFromJSON)),
        'externalLinks': ((json['externalLinks'] as Array<any>).map(ExternalLinkFromJSON)),
        'component': ComponentFromJSON(json['component']),
    };
}

export function BackApiSpecificationToJSON(value?: BackApiSpecification | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'resources': ((value.resources as Array<any>).map(ResourceToJSON)),
        'externalLinks': ((value.externalLinks as Array<any>).map(ExternalLinkToJSON)),
        'component': ComponentToJSON(value.component),
    };
}

