import { type IconName } from 'icons/Icon.props';

interface UiComponentCardTypeProps {
  icon: IconName;
  canvaLabel: string;
  panelLabel: string;
}

export type UiComponentCardType =
  | 'title'
  | 'icon'
  | 'text'
  | 'container'
  | 'input'
  | 'button'
  | 'list'
  | 'media'
  | 'date'
  | 'select';

export const uiComponentCardType: Record<UiComponentCardType, UiComponentCardTypeProps> = {
  title: { icon: 'title', canvaLabel: 'Titre', panelLabel: 'un titre' },
  text: { icon: 'text', canvaLabel: 'Texte', panelLabel: 'un texte' },
  button: { icon: 'button', canvaLabel: 'Bouton', panelLabel: 'un bouton' },
  media: { icon: 'image2', canvaLabel: 'Média', panelLabel: 'un média' },
  container: { icon: 'zone', canvaLabel: 'Conteneur', panelLabel: 'un conteneur' },
  list: { icon: 'list', canvaLabel: "Liste d'objets", panelLabel: `une liste d'objets` },
  icon: { icon: 'icons', canvaLabel: 'Icône', panelLabel: 'une icône' },
  input: { icon: 'input', canvaLabel: 'Saisie de texte', panelLabel: 'saisir un texte' },
  date: { icon: 'dateInput', canvaLabel: 'Saisie de date', panelLabel: `saisir une date` },
  select: { icon: 'select', canvaLabel: "Choix d'options", panelLabel: `faire un choix` },
};
