/* tslint:disable */
/* eslint-disable */
/**
 * Specks API
 * APIs for specks SaaS
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GetAppSchemaComponentDto
 */
export interface GetAppSchemaComponentDto {
    /**
     * 
     * @type {string}
     * @memberof GetAppSchemaComponentDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof GetAppSchemaComponentDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof GetAppSchemaComponentDto
     */
    status: GetAppSchemaComponentDtoStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof GetAppSchemaComponentDto
     */
    componentType: GetAppSchemaComponentDtoComponentTypeEnum;
    /**
     * 
     * @type {boolean}
     * @memberof GetAppSchemaComponentDto
     */
    internal: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetAppSchemaComponentDto
     */
    isAlreadyinAppSchema: boolean;
    /**
     * 
     * @type {string}
     * @memberof GetAppSchemaComponentDto
     */
    backSpecificationId: string;
}


/**
 * @export
 */
export const GetAppSchemaComponentDtoStatusEnum = {
    Production: 'production',
    Planed: 'planed',
    InDevelopment: 'inDevelopment',
    InDecommissionning: 'inDecommissionning',
    Decommissioned: 'decommissioned',
    InExploration: 'inExploration',
    Evolving: 'evolving'
} as const;
export type GetAppSchemaComponentDtoStatusEnum = typeof GetAppSchemaComponentDtoStatusEnum[keyof typeof GetAppSchemaComponentDtoStatusEnum];

/**
 * @export
 */
export const GetAppSchemaComponentDtoComponentTypeEnum = {
    Front: 'front',
    Batch: 'batch',
    Api: 'api',
    Database: 'database'
} as const;
export type GetAppSchemaComponentDtoComponentTypeEnum = typeof GetAppSchemaComponentDtoComponentTypeEnum[keyof typeof GetAppSchemaComponentDtoComponentTypeEnum];


/**
 * Check if a given object implements the GetAppSchemaComponentDto interface.
 */
export function instanceOfGetAppSchemaComponentDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "status" in value;
    isInstance = isInstance && "componentType" in value;
    isInstance = isInstance && "internal" in value;
    isInstance = isInstance && "isAlreadyinAppSchema" in value;
    isInstance = isInstance && "backSpecificationId" in value;

    return isInstance;
}

export function GetAppSchemaComponentDtoFromJSON(json: any): GetAppSchemaComponentDto {
    return GetAppSchemaComponentDtoFromJSONTyped(json, false);
}

export function GetAppSchemaComponentDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetAppSchemaComponentDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'status': json['status'],
        'componentType': json['componentType'],
        'internal': json['internal'],
        'isAlreadyinAppSchema': json['isAlreadyinAppSchema'],
        'backSpecificationId': json['backSpecificationId'],
    };
}

export function GetAppSchemaComponentDtoToJSON(value?: GetAppSchemaComponentDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'status': value.status,
        'componentType': value.componentType,
        'internal': value.internal,
        'isAlreadyinAppSchema': value.isAlreadyinAppSchema,
        'backSpecificationId': value.backSpecificationId,
    };
}

