/* tslint:disable */
/* eslint-disable */
/**
 * Specks API
 * APIs for specks SaaS
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateEdgeDto
 */
export interface CreateEdgeDto {
    /**
     * 
     * @type {string}
     * @memberof CreateEdgeDto
     */
    sourceHandleId: string;
    /**
     * 
     * @type {string}
     * @memberof CreateEdgeDto
     */
    targetHandleId: string;
    /**
     * 
     * @type {string}
     * @memberof CreateEdgeDto
     */
    sourceNode: string;
    /**
     * 
     * @type {string}
     * @memberof CreateEdgeDto
     */
    targetNode: string;
}

/**
 * Check if a given object implements the CreateEdgeDto interface.
 */
export function instanceOfCreateEdgeDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "sourceHandleId" in value;
    isInstance = isInstance && "targetHandleId" in value;
    isInstance = isInstance && "sourceNode" in value;
    isInstance = isInstance && "targetNode" in value;

    return isInstance;
}

export function CreateEdgeDtoFromJSON(json: any): CreateEdgeDto {
    return CreateEdgeDtoFromJSONTyped(json, false);
}

export function CreateEdgeDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateEdgeDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'sourceHandleId': json['sourceHandleId'],
        'targetHandleId': json['targetHandleId'],
        'sourceNode': json['sourceNode'],
        'targetNode': json['targetNode'],
    };
}

export function CreateEdgeDtoToJSON(value?: CreateEdgeDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'sourceHandleId': value.sourceHandleId,
        'targetHandleId': value.targetHandleId,
        'sourceNode': value.sourceNode,
        'targetNode': value.targetNode,
    };
}

