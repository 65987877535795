import { SpecksApisContext } from 'App';
import { type GetAssetDto, type GetPersonaDto } from 'api';

import React, { useContext, useState } from 'react';
import { type QueryClient, useMutation, useQuery, useQueryClient } from 'react-query';
import { type NavigateFunction, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import uuid from 'react-uuid';

import { useAuth0 } from '@auth0/auth0-react';

import { BasePage } from 'pages/core/BasePage/BasePage';

import { CreateAssetForm } from 'forms/CreateAssetForm/CreateAssetForm';
import { CreateorEditPersonaForm, type Persona } from 'forms/CreateOrEditPersonaForm/CreateOrEditPersonaForm';

import { Button } from 'components/core/Button/Button';
import { CardContainer } from 'components/core/CardContainer/CardContainer';
import { type BreadCrumbsProps, Header } from 'components/core/Header/Header';
import { Modal } from 'components/core/Modal/Modal';
import { Section } from 'components/core/Section/Section';
import { Text } from 'components/core/Text/Text';
import { Title } from 'components/core/Title/Title';
import { AssetCard } from 'components/specks/AssetCard/AssetCard';
import { Empty } from 'components/specks/Empty/Empty';
import { NetworkError } from 'components/specks/Error/Error';
import { PersonaCard } from 'components/specks/PersonaCard/PersonaCard';
import { UserAvatar } from 'components/specks/UserAvatar/UserAvatar';

import { type Asset } from 'models/asset.entity';
import { Genesis } from 'models/genesis.entity';
import { type Product } from 'models/product.entity';

import { tooltipDescriptionsStyle } from 'utils/tooltipsDescriptions';

interface GenesisPageParams {
  productId?: string;
}

export function ProductGenesisPage(): JSX.Element {
  /* --------------------------------------------------- contexts --------------------------------------------------- */

  const { genesisService, productService, assetService, personaService } = useContext(SpecksApisContext);

  const { productId = '' }: GenesisPageParams = useParams();
  const navigate: NavigateFunction = useNavigate();
  const queryClient: QueryClient = useQueryClient();
  const { getAccessTokenSilently, user } = useAuth0();

  /* ---------------------------------------------------- states ---------------------------------------------------- */

  const [isCreateAssetModalOpened, setIsCreateAssetModalOpened] = React.useState<boolean>(false);
  const [isCreatePersonaModalOpened, setIsCreatePersonaModalOpened] = React.useState<boolean>(false);
  const [isEditPersonaModalOpened, setIsEditPersonaModalOpened] = React.useState<boolean>(false);

  const [selectedAssetId, setSelectedAssetId] = useState<string>('');
  const [selectedPersonaId, setSelectedPersonaId] = useState<string>('');
  const [selectedPersona, setSelectedPersona] = useState<Persona>();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isDeleteAssetModalOpened, setIsDeleteAssetModalOpened] = useState<boolean>(false);
  const [isDeletePersonaModalOpened, setIsDeletePersonaModalOpened] = useState<boolean>(false);

  /* ---------------------------------------------------- Queries --------------------------------------------------- */

  const { data: productData } = useQuery<Product, Error>(['product', productId], async () => {
    const accessToken: string = await getAccessTokenSilently();
    return await productService.findById(productId, accessToken);
  });

  const genesisId: string | undefined = productData?.genesis?.id;
  const { data: genesisData, status: genesisStatus } = useQuery<Genesis, Error>({
    queryKey: ['genesis', genesisId],
    queryFn: async ({ queryKey }) => {
      const [, genesisId] = queryKey;
      const accessToken: string = await getAccessTokenSilently();
      return await genesisService.findById(genesisId as string, accessToken);
    },
    enabled: genesisId !== undefined,
  });
  const { data: personaeData, status: personaeStatus } = useQuery<Persona[], Error>({
    queryKey: ['personae', productId],
    queryFn: async ({ queryKey }) => {
      const [, productId] = queryKey;
      const accessToken: string = await getAccessTokenSilently();
      return await productService.findPersonaeById(productId as string, accessToken);
    },
    enabled: genesisId !== undefined,
  });

  /* --------------------------------------------------- functions -------------------------------------------------- */

  function handleClickAddAsset(): void {
    setIsCreateAssetModalOpened(true);
  }

  function handleClickAddPersona(): void {
    setIsCreatePersonaModalOpened(true);
  }

  function handleClickBack(): void {
    navigate(`/product/${productId}`);
  }

  /* ------------------------------------------------- Create Asset ------------------------------------------------- */

  async function createAsset(externalLink: Asset): Promise<GetAssetDto | undefined> {
    const accessToken: string = await getAccessTokenSilently();
    if (genesisStatus === 'success') return await genesisService.createAsset(genesisData.id, externalLink, accessToken);
  }

  const { mutate } = useMutation(createAsset, {
    onSuccess: (getAsset: GetAssetDto | undefined) => {
      if (getAsset === undefined) {
        return;
      }
      toast.success('Le lien vers le document a été créé avec succès');
      queryClient.setQueryData(['genesis', genesisId], (oldData: Genesis | undefined) => {
        if (oldData === undefined) {
          return new Genesis();
        }
        oldData.assets.push(getAsset);
        return oldData;
      });
      setIsCreateAssetModalOpened(false);
    },
    onError: () => {
      toast.error('Une erreur est survenue lors de la création du lien');
    },
  });

  async function handleAssetsFormSubmission(externalLink: Asset): Promise<void> {
    try {
      mutate(externalLink);
    } catch (error) {
      console.error(error);
    }
  }

  /* ------------------------------------------------ Create Persona ------------------------------------------------ */

  async function createPersona(persona: Persona): Promise<GetPersonaDto | undefined> {
    const accessToken: string = await getAccessTokenSilently();
    if (personaeStatus === 'success') return await productService.createPersona(productId, persona, accessToken);
  }

  const { mutate: mutateAddPersona } = useMutation(createPersona, {
    onSuccess: (getPersona: GetPersonaDto | undefined) => {
      if (getPersona === undefined) {
        return;
      }
      toast.success('Le persona a été créé avec succès');
      queryClient.setQueryData(['personae', productId], (oldData: Persona[] | undefined) => {
        if (oldData === undefined) {
          return new Array<Persona>();
        }
        oldData.push(getPersona);
        return oldData;
      });
      setIsCreatePersonaModalOpened(false);
    },
    onError: () => {
      toast.error('Une erreur est survenue lors de la création du persona');
    },
  });

  async function handlePersonaFormSubmission(persona: Persona): Promise<void> {
    try {
      mutateAddPersona(persona);
    } catch (error) {
      console.error(error);
    }
  }

  /* ------------------------------------------------- edit persona ------------------------------------------------- */

  async function editPersona(updatedPersona: Persona): Promise<Persona> {
    const accessToken: string = await getAccessTokenSilently();
    return await personaService.updatePersona(selectedPersonaId, updatedPersona, accessToken);
  }

  function handleEditPersonaFormSubmission(updatedPersona: Persona): void {
    setIsLoading(true);
    setTimeout(() => {
      handlePersonaEditionConfirmation(updatedPersona);
    }, 1);
  }

  function handlePersonaEditionConfirmation(updatedPersona: Persona): void {
    toast.update('Le Persona est en cours de modification, vous allez être redirigé vers la liste des personae');
    try {
      mutateEditPersona(updatedPersona);
    } catch (error) {
      toast.error('Erreur lors de la modification du produit.');
      setIsLoading(false);
    }
  }

  const { mutate: mutateEditPersona } = useMutation(editPersona, {
    onSuccess: (editedPersona: Persona) => {
      queryClient.setQueryData(['personae', productId], (oldData: Persona[] | undefined) => {
        if (oldData === undefined) {
          return new Array<Persona>();
        } else {
          oldData = oldData.map((persona: Persona) => {
            if (persona.id === editedPersona.id) {
              return editedPersona;
            }
            return persona;
          });
          return oldData;
        }
      });
      toast.success('Le persona a bien été mis  jour');
      setIsEditPersonaModalOpened(false);
      setIsLoading(false);
    },
    onError: () => {
      toast.error('Une erreur est survenue lors de la modification du persona');
      setIsLoading(false);
    },
  });

  function handlePersonaModificationClick(id: string): void {
    const selectedPersona: Persona | undefined = personaeData?.find((persona) => persona.id === id);
    if (selectedPersona === undefined) return;
    setSelectedPersona(selectedPersona);

    setSelectedPersonaId(id);
    setIsEditPersonaModalOpened(true);
  }

  /* ------------------------------------------------ delete persona ------------------------------------------------ */
  async function deletePersona(): Promise<void> {
    const accessToken: string = await getAccessTokenSilently();
    await personaService.deletePersona(selectedPersonaId, accessToken);
  }

  const { mutate: mutateDeletePersona } = useMutation(deletePersona, {
    onSuccess: () => {
      queryClient.setQueryData(['personae', productId], (oldData: Persona[] | undefined) => {
        if (oldData === undefined) {
          return new Array<Persona>();
        } else {
          oldData = oldData.filter((persona) => persona.id !== selectedPersonaId);
          return oldData;
        }
      });
      toast.success('Le persona a bien été supprimé');
      setIsLoading(false);
    },
    onError: () => {
      toast.error('Erreur lors de la suppression du persona.');
    },
    onSettled: async () => {
      setIsDeletePersonaModalOpened(false);
      setIsLoading(false);
    },
  });

  function handlePersonaDeletionClick(personaId: string): void {
    setSelectedPersonaId(personaId);
    setIsDeletePersonaModalOpened(true);
  }

  function handleOnSubmitDeletePersona(event: any): void {
    event.preventDefault();
    setIsLoading(true);
    setTimeout(() => {
      mutateDeletePersona();
    }, 1);
  }

  /* ------------------------------------------------- Delete Asset ------------------------------------------------- */

  async function deleteAsset(): Promise<void> {
    const accessToken: string = await getAccessTokenSilently();
    await assetService.deleteAsset(selectedAssetId, accessToken);
  }

  const { mutate: mutateDeleteAsset } = useMutation(deleteAsset, {
    onSuccess: () => {
      queryClient.setQueryData(['genesis', genesisId], (oldData: Genesis | undefined) => {
        if (oldData === undefined) {
          return new Genesis();
        } else {
          oldData.assets = oldData.assets.filter((asset) => asset.id !== selectedAssetId);
          return oldData;
        }
      });
      toast.success('Le lien vers ce document a bien été supprimé');
      setIsLoading(false);
    },
    onError: () => {
      toast.error('Erreur lors de la suppression du lien vers le document.');
    },
    onSettled: async () => {
      setIsDeleteAssetModalOpened(false);
      setIsLoading(false);
    },
  });

  function handleAssetDeletionClick(assetId: string): void {
    setSelectedAssetId(assetId);
    setIsDeleteAssetModalOpened(true);
  }

  function handleOnSubmitDeleteAsset(event: any): void {
    event.preventDefault();
    setIsLoading(true);
    setTimeout(() => {
      mutateDeleteAsset();
    }, 1);
  }
  /* ------------------------------------------------------ JSX ----------------------------------------------------- */

  const breadCrumbs: BreadCrumbsProps = {
    paths: [
      {
        name: 'Produits',
        href: '/products',
      },
      {
        name: productData?.name ?? '',
        href: `/product/${productData?.id ?? ''}`,
      },
    ],
    current: 'Cadrage du produit',
  };

  return (
    <BasePage>
      <Modal
        title="Ajouter un lien vers un document"
        isOpen={isCreateAssetModalOpened}
        setIsOpen={setIsCreateAssetModalOpened}
      >
        <CreateAssetForm onSubmit={handleAssetsFormSubmission} />
      </Modal>

      <Modal title="Attention" isOpen={isDeleteAssetModalOpened} setIsOpen={setIsDeleteAssetModalOpened}>
        <>
          <Text
            whitespace="pre-line"
            position="justify"
            content="Vous allez supprimer le lien vers ce document. Êtes-vous certain(e) de vouloir poursuivre ?"
          />
          <div className="flex flex-row gap-2 justify-end">
            {!isLoading && (
              <Button onClick={handleOnSubmitDeleteAsset} content="Supprimer" width="1/2" type="submit" height="sm" />
            )}
            {isLoading && <Button iconName="spinCircle" width="1/2" height="sm" iconAnimation="spin" />}
          </div>
        </>
      </Modal>
      <>
        {personaeStatus === 'success' && (
          <Modal
            maxWidth="xxxl"
            title="Nouveau persona"
            isOpen={isCreatePersonaModalOpened}
            setIsOpen={setIsCreatePersonaModalOpened}
          >
            <CreateorEditPersonaForm onSubmit={handlePersonaFormSubmission} currentPersonae={personaeData} />
          </Modal>
        )}
        <Modal title="Modifiez votre persona" isOpen={isEditPersonaModalOpened} setIsOpen={setIsEditPersonaModalOpened}>
          <CreateorEditPersonaForm
            isEditing
            persona={selectedPersona}
            onSubmit={handleEditPersonaFormSubmission}
            isLoading={isLoading}
            currentPersonae={personaeData ?? []}
          />
        </Modal>
      </>
      <Modal title="Attention" isOpen={isDeletePersonaModalOpened} setIsOpen={setIsDeletePersonaModalOpened}>
        <>
          <Text
            whitespace="pre-line"
            position="justify"
            content="Vous allez supprimer définitivement ce persona. Êtes-vous certain(e) de vouloir poursuivre ?"
          />
          <div className="flex flex-row gap-2 justify-end">
            {!isLoading && (
              <Button onClick={handleOnSubmitDeletePersona} content="Supprimer" width="1/2" type="submit" height="sm" />
            )}
            {isLoading && <Button iconName="spinCircle" width="1/2" height="sm" iconAnimation="spin" />}
          </div>
        </>
      </Modal>
      <Header
        breadCrumbs={breadCrumbs}
        status={productData?.status}
        handleClickBack={handleClickBack}
        title={productData?.name}
        thumbnail={true}
        thumbnailIcon={'product'}
        thumbnailTooltipMessage={tooltipDescriptionsStyle.product}
      />

      <Section isFullHeight>
        <div className="flex flex-row justify-between items-center">
          <Title content="Personae" />
          <Button
            content="Nouveau persona"
            width="fit"
            height="sm"
            onClick={handleClickAddPersona}
            iconName="plus"
            disabled={genesisStatus !== 'success'}
          />
        </div>
        <>
          {personaeStatus === 'error' && (
            <NetworkError message="Une erreur est survenue lors du chargement des personae." />
          )}
          {personaeStatus === 'loading' && (
            <CardContainer cardSize="sm">
              <PersonaCard loading />
              <PersonaCard loading />
            </CardContainer>
          )}
          {personaeStatus === 'success' &&
            (personaeData.length === 0 ? (
              <Empty
                icon="personae"
                iconSize="xxxl"
                title="Vous n'avez pas encore de persona"
                subtitle="Un persona est un profil représentatif des utilisateurs cibles. "
              >
                <Button
                  content="Créer un nouveau persona"
                  iconName="plus"
                  width="fit"
                  height="sm"
                  onClick={handleClickAddPersona}
                />
              </Empty>
            ) : (
              <CardContainer cardSize="lg">
                {personaeData.map((persona) => (
                  <PersonaCard
                    id={persona.id}
                    key={uuid()}
                    icon={'image'}
                    name={persona.name}
                    description={persona.description}
                    age={persona.age}
                    likes={persona.likes}
                    dislikes={persona.dislikes}
                    onDeletionClick={handlePersonaDeletionClick}
                    onModificationClick={handlePersonaModificationClick}
                  />
                ))}
              </CardContainer>
            ))}
        </>
      </Section>

      <Section isFullHeight>
        <div className="flex flex-row justify-between items-center">
          <Title content="Documents externes" />
          <Button
            content="Ajouter un lien vers un document"
            width="fit"
            height="sm"
            onClick={handleClickAddAsset}
            iconName="plus"
            disabled={genesisStatus !== 'success'}
          />
        </div>
        <>
          {genesisStatus === 'error' && (
            <NetworkError message="Une erreur est survenue lors du chargement des liens." />
          )}
          {genesisStatus === 'loading' && (
            <CardContainer cardSize="sm">
              <AssetCard loading />
              <AssetCard loading />
              <AssetCard loading />
              <AssetCard loading />
            </CardContainer>
          )}
          {genesisStatus === 'success' &&
            (genesisData.assets.length === 0 ? (
              <Empty
                icon="externalLink"
                title="Votre boîte à outils est vide!"
                subtitle="Organisez votre travail en centralisant d'autres éléments qui précisent le besoin : brief, expression de besoin, cahier des charges, parcours utilisateurs, exemples de l'existant, indicateurs de succès, etc."
              >
                <Button
                  content="Ajouter un lien vers un document"
                  iconName="plus"
                  width="fit"
                  height="sm"
                  onClick={handleClickAddAsset}
                />
              </Empty>
            ) : (
              <CardContainer cardSize="sm">
                {genesisData.assets.map((asset) => (
                  <AssetCard
                    id={asset.id}
                    key={uuid()}
                    openInNewTab
                    href={asset.url}
                    icon={'image'}
                    name={asset.name}
                    date={asset.createdAt}
                    onDeletionClick={handleAssetDeletionClick}
                  />
                ))}
              </CardContainer>
            ))}
        </>
      </Section>
    </BasePage>
  );
}
