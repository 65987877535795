import { type GetPagesDto, type GetResourcesSimpleDto } from 'api';

import { type ComponentType } from './ComponentTypes.type';
import { type Status } from './Status.types';
import { BackApiSpecification } from './backApiSpecification.entity';
import { Dictionary } from './dictionary.entity';
import { type ExternalLink } from './externalLink.entity';
import { Faq } from './faq.entity';
import { FrontSpecification } from './frontSpecification.entity';

export class Component {
  constructor() {
    this.externalLinks = [];
    this.dictionary = new Dictionary();
    this.faq = new Faq();
    this.frontSpecification = new FrontSpecification();
    this.BackApiSpecification = new BackApiSpecification();
  }

  id: string;

  name: string;

  componentType: ComponentType;

  internal: boolean;

  status: Status;

  dictionary: Dictionary;

  faq: Faq;

  frontSpecification: FrontSpecification;

  BackApiSpecification: BackApiSpecification;

  externalLinks: ExternalLink[];

  isAlreadyUsedInAnotherAppSchema: boolean;

  pages: GetPagesDto;

  resources: GetResourcesSimpleDto;
}
