import * as Yup from 'yup';

import React from 'react';
import { type SubmitHandler, useForm } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';

import { Button } from 'components/core/Button/Button';
import Input from 'components/core/Input/Input';

import { Page } from 'models/page.entity';
import { PageRevision } from 'models/pageRevision.entity';

interface CreatePageFormModel {
  name: string;
}

const schema: Yup.ObjectSchema<CreatePageFormModel> = Yup.object().shape({
  name: Yup.string()
    .required('Champ obligatoire')
    .min(3, 'Le nom doit contenir au moins 3 caractères')
    .max(28, 'Le nom doit contenir au maximum 28 caractères')
    .test('isUnique', 'Une page avec ce nom existe déjà', (value, context) => {
      const currentPages: Page[] | undefined = context?.options?.context?.currentPages as Page[];
      return value != null && value.length > 0 && !currentPages.some((page) => page.pageRevisions[0].name === value);
    }),
});

interface CreateOrEditPageFormProps {
  onSubmit: (page: Page) => void;
  currentPages?: Page[];
  isEditing?: boolean;
  page?: Page;
}

export function CreateOrEditPageForm({
  onSubmit,
  currentPages = [],
  isEditing = false,
  page,
}: CreateOrEditPageFormProps): JSX.Element {
  const {
    register,
    watch,
    handleSubmit,
    formState: { errors, isSubmitted },
  } = useForm<CreatePageFormModel>({
    resolver: yupResolver(schema),
    context: { currentPages },
    defaultValues: {
      name: isEditing && page !== undefined ? page.pageRevisions[0].name : '',
    },
  });

  const watchName: string = watch('name');

  let isFormValid: boolean = true;

  if (watchName === undefined || watchName.length < 3) {
    isFormValid = false;
  }

  const onSubmitForm: SubmitHandler<CreatePageFormModel> = (data) => {
    const page: Page = new Page();
    const pageRevision: PageRevision = new PageRevision();
    pageRevision.name = data.name;
    page.pageRevisions.push(pageRevision);
    onSubmit(page);
  };

  return (
    <form className="flex flex-col gap-6" onSubmit={handleSubmit(onSubmitForm)}>
      <Input
        label="Nom"
        placeholder="Saisissez le nom de votre page..."
        {...register('name')}
        maxLength={28}
        error={errors.name != null && isSubmitted ? errors.name.message : undefined}
      />
      <div className="flex flex-row">
        <Button content="Ajouter" width="full" type="submit" disabled={!isFormValid} />
      </div>
    </form>
  );
}
