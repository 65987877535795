/* tslint:disable */
/* eslint-disable */
/**
 * Specks API
 * APIs for specks SaaS
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Component } from './Component';
import {
    ComponentFromJSON,
    ComponentFromJSONTyped,
    ComponentToJSON,
} from './Component';
import type { Product } from './Product';
import {
    ProductFromJSON,
    ProductFromJSONTyped,
    ProductToJSON,
} from './Product';
import type { User } from './User';
import {
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
} from './User';

/**
 * 
 * @export
 * @interface ComponentCompany
 */
export interface ComponentCompany {
    /**
     * 
     * @type {string}
     * @memberof ComponentCompany
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof ComponentCompany
     */
    name: string;
    /**
     * 
     * @type {Array<User>}
     * @memberof ComponentCompany
     */
    users: Array<User>;
    /**
     * 
     * @type {Array<Product>}
     * @memberof ComponentCompany
     */
    products: Array<Product>;
    /**
     * 
     * @type {Array<Component>}
     * @memberof ComponentCompany
     */
    components: Array<Component>;
    /**
     * 
     * @type {string}
     * @memberof ComponentCompany
     */
    companyStatus: ComponentCompanyCompanyStatusEnum;
}


/**
 * @export
 */
export const ComponentCompanyCompanyStatusEnum = {
    Trial: 'TRIAL',
    ExpiredTrial: 'EXPIRED_TRIAL',
    Active: 'ACTIVE',
    Inactive: 'INACTIVE'
} as const;
export type ComponentCompanyCompanyStatusEnum = typeof ComponentCompanyCompanyStatusEnum[keyof typeof ComponentCompanyCompanyStatusEnum];


/**
 * Check if a given object implements the ComponentCompany interface.
 */
export function instanceOfComponentCompany(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "users" in value;
    isInstance = isInstance && "products" in value;
    isInstance = isInstance && "components" in value;
    isInstance = isInstance && "companyStatus" in value;

    return isInstance;
}

export function ComponentCompanyFromJSON(json: any): ComponentCompany {
    return ComponentCompanyFromJSONTyped(json, false);
}

export function ComponentCompanyFromJSONTyped(json: any, ignoreDiscriminator: boolean): ComponentCompany {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'users': ((json['users'] as Array<any>).map(UserFromJSON)),
        'products': ((json['products'] as Array<any>).map(ProductFromJSON)),
        'components': ((json['components'] as Array<any>).map(ComponentFromJSON)),
        'companyStatus': json['companyStatus'],
    };
}

export function ComponentCompanyToJSON(value?: ComponentCompany | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'users': ((value.users as Array<any>).map(UserToJSON)),
        'products': ((value.products as Array<any>).map(ProductToJSON)),
        'components': ((value.components as Array<any>).map(ComponentToJSON)),
        'companyStatus': value.companyStatus,
    };
}

