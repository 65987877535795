import { SpecksApisContext } from 'App';

import React, { useContext, useEffect } from 'react';
import {
  type QueryClient,
  type QueryFunctionContext,
  type QueryKey,
  useMutation,
  useQuery,
  useQueryClient,
} from 'react-query';
import { type NavigateFunction, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import uuid from 'react-uuid';

import { useAuth0 } from '@auth0/auth0-react';

import { type PageHandler, usePageHandler } from 'pages/Component/ComponentPage/ComponentPage';

import { Button } from 'components/core/Button/Button';
import { CardContainer } from 'components/core/CardContainer/CardContainer';
import { Title } from 'components/core/Title/Title';
import { Empty } from 'components/specks/Empty/Empty';
import { NetworkError } from 'components/specks/Error/Error';

import { type Component } from 'models/component.entity';
import { type Page } from 'models/page.entity';

import concatClassNames from 'utils/classNames';

import { PageCard } from '../PageCard/PageCard';

/* ----------------------------------------------- resource Provider ---------------------------------------------- */

interface ApiWidgetProps {
  componentId: string;
}

export function PagesWidget({ componentId }: ApiWidgetProps): JSX.Element {
  /* --------------------------------------------------- contexts --------------------------------------------------- */

  const { componentService, frontSpecificationService, pageService } = useContext(SpecksApisContext);
  const { getAccessTokenSilently } = useAuth0();
  const navigate: NavigateFunction = useNavigate();
  const queryClient: QueryClient = useQueryClient();
  const pageHandler: PageHandler = usePageHandler();

  /* ---------------------------------------------------- states ---------------------------------------------------- */

  /* ---------------------------------------------------- queries --------------------------------------------------- */

  async function getComponent(): Promise<Component> {
    const accessToken: string = await getAccessTokenSilently();
    return await componentService.findById(componentId, accessToken);
  }

  const { data: componentData, status: componentStatus } = useQuery<Component, Error>(
    ['component', componentId],
    getComponent,
    {
      cacheTime: 0,
    },
  );

  async function getPages(frontSpecificationId: string): Promise<Page[]> {
    const accessToken: string = await getAccessTokenSilently();
    return await frontSpecificationService.getPages(frontSpecificationId, accessToken);
  }

  const frontSpecificationId: string | undefined = componentData?.frontSpecification?.id;
  const { data: pagesData, status: pagesStatus } = useQuery<Page[], Error>(
    ['pages', frontSpecificationId],
    async (keys: QueryFunctionContext<QueryKey, string>) => getPages(keys.queryKey[1] as string),
    {
      enabled: frontSpecificationId !== undefined,
    },
  );

  /* --------------------------------------------------- effects --------------------------------------------------- */

  useEffect(() => {
    if (pagesData !== undefined) {
      pageHandler.setPagesData(pagesData);
    }
  }, [pageHandler, pagesData]);

  useEffect(() => {
    if (pagesStatus !== undefined) {
      pageHandler.setPagesStatus(pagesStatus);
    }
  }, [pagesStatus, pageHandler]);

  /* ---------------------------------------------- Create Page --------------------------------------------- */

  async function createPage(page: Page): Promise<Page> {
    const accessToken: string = await getAccessTokenSilently();
    return await frontSpecificationService.createPage(frontSpecificationId ?? '', page, accessToken);
  }

  const { mutate } = useMutation(createPage, {
    onSuccess: (getPage: Page) => {
      toast.success('Page créée avec succès');
      pageHandler.setIsModalOpened(false);
      queryClient.setQueryData(['pages', frontSpecificationId], (oldData: Page[] | undefined) => {
        if (oldData === undefined) {
          return [];
        }
        oldData.push(getPage);
        return oldData;
      });
      navigate('/component/' + componentId + '/front/pages/' + getPage.id);
    },
    onError: () => {
      toast.error('Erreur lors de la création de la page');
    },
  });

  function handlePagesFormSubmission(newPage: Page): void {
    pagesData?.forEach((page: Page) => {
      if (page.pageRevisions[0].name === newPage.pageRevisions[0].name) {
        throw new Error('Page already exists');
      }
    });
    mutate(newPage);
  }

  useEffect(() => {
    pageHandler.setHandlePagesFormSubmission(() => handlePagesFormSubmission);
  }, []);

  /* ------------------------------------------- Delete Page ----------------------------------------------- */

  function handlePageDeletionConfirmation(): void {
    pageHandler.setIsDeletePageModalOpened(false);
    try {
      mutateDeletePage();
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    pageHandler.setHandleOnSubmitDeletePage(() => handlePageDeletionConfirmation);
  }, []);

  const { mutate: mutateDeletePage } = useMutation(deletePage, {
    onSuccess: () => {
      queryClient.setQueryData(['pages', frontSpecificationId], (oldData: Page[] | undefined) => {
        if (oldData === undefined) {
          return new Array<Page>();
        } else {
          oldData = oldData.filter((page) => page.id !== pageHandler.selectedPageId);
          return oldData;
        }
      });
      toast.success('La page à bien été supprimée');
      // setIsLoading(false);
    },
    onError: () => {
      toast.error('Erreur lors de la suppression de cette page');
    },
  });

  async function deletePage(): Promise<void> {
    if (pageHandler.selectedPageId === undefined) return;
    const accessToken: string = await getAccessTokenSilently();
    await pageService.delete(pageHandler.selectedPageId, accessToken);
  }

  /* --------------------------------------------------- functions -------------------------------------------------- */

  function handleClickEmptyState(): void {
    pageHandler.setIsModalOpened(true);
  }

  function handlePageDeletionClick(pageId: string): void {
    pageHandler.setSelectedPageId(pageId);
    pageHandler.setIsDeletePageModalOpened(true);
  }

  /* ------------------------------------------------------ JSX ----------------------------------------------------- */

  return (
    <div
      id="widget_pages"
      className={concatClassNames(
        'flex',
        'flex-col',
        'bg-gray-25',
        'rounded-md',
        'py-4',
        'flex-grow',
        'relative',
        'overflow-auto',
        'items-center',
      )}
    >
      <div
        className={concatClassNames(
          'flex flex-row',
          'justify-start items-center',
          'sticky top-0 z-10',
          'w-[65%]',
          'bg-white',
          'p-2',
          'rounded-full',
          'justify-center',
          'border-1 border-gray-25',
        )}
      >
        <Title content="Spécifications des pages" />
      </div>
      <>
        {(pagesStatus === 'error' || componentStatus === 'error') && (
          <NetworkError message="Problème lors de l'import  des pages" />
        )}
        {pagesStatus === 'loading' && (
          <CardContainer paddingX="4" paddingY="4">
            <PageCard loading={true} />
            <PageCard loading={true} />
            <PageCard loading={true} />
            <PageCard loading={true} />
          </CardContainer>
        )}
        {pagesStatus === 'success' && pagesData?.length === 0 && (
          <Empty
            icon="feather"
            title="Il n'existe encore aucune page dans ce composant applicatif"
            subtitle="Créez la premiere page!"
            height="fit"
            width="fit"
            backgroundColor="transparent"
          >
            <Button content="Créer une page" onClick={handleClickEmptyState} iconName="plus" height="sm" width="fit" />
          </Empty>
        )}
        {pagesStatus === 'success' && pagesData?.length !== 0 && (
          <div
            className={concatClassNames(
              'flex flex-wrap',
              'relative',
              'items-center justify-center',
              'px-5 py-5',
              'gap-6',
            )}
          >
            <>
              {pagesData?.map((page: Page) => (
                <>
                  <PageCard
                    id={page.id}
                    key={uuid()}
                    name={page.pageRevisions[0].name}
                    href={`/component/${componentId}/front/pages/${page.id}`}
                    onDeletionClick={handlePageDeletionClick}
                  />
                </>
              ))}
            </>
          </div>
        )}
      </>
    </div>
  );
}
