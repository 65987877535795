import React, { type ChangeEventHandler, forwardRef } from 'react';

import { Text } from 'components/core/Text/Text';

import concatClassNames from 'utils/classNames';
import getIcon from 'utils/getIcon';

import { type IconName, type IconSize } from 'icons/Icon.props';

interface AvatarProps {
  iconName?: IconName;
  name?: string;
  label?: string;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  error?: string;
  iconSize?: IconSize;
}

function Avatar(
  { iconName = 'check', name, label, onChange, error, iconSize = 'xl' }: AvatarProps,
  ref: React.Ref<HTMLInputElement>,
): JSX.Element {
  return (
    <div className="flex flex-col w-full gap-1">
      {label !== undefined && <Text content={label} size="base" weight="bold" color="black" position="center" />}
      <div className="flex flex-row w-full justify-between ">
        <div className="flex w-32 h-32 bg-gray-25 rounded-[84px] items-center justify-center">
          {getIcon(iconName, 'gray', iconSize)}
        </div>
      </div>
      {error !== undefined && <p className={concatClassNames('text-error-500', 'text-xs')}>{error}</p>}
    </div>
  );
}

export default forwardRef<HTMLInputElement, AvatarProps>(Avatar);
