/* eslint-disable max-len */
import React from 'react';

import concatClassNames from 'utils/classNames';

import { type IconProps, iconColorStyle, iconSizeStyle } from './Icon.props';

export default function StarIcon({ color, size }: IconProps): JSX.Element {
  return (
    <svg
      id="Layer_3"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 128 128"
      className={concatClassNames(iconSizeStyle[size])}
    >
      <path
        className="cls-2"
        d="m37.43,47.82c5.1-14.44,18.59-30.42,24.46-26.88,0,0-2.97-4.72-5.11-3.71-2.14,1.01-9.65,10.68-9.65,10.68l-7.98,14.74-1.72,5.17Z"
      />
      <path
        className="cls-2"
        d="m17.25,49.76c-2.32.69-9.9,4.61-9.43,8.26.73,5.68,6.64,9.21,9.98,11.87s-12.56-9.76-13.17-13.11c-.61-3.34,5.61-4.93,5.61-4.93l7.02-2.09Z"
      />
      <path
        className="cls-2"
        d="m24.08,90.12c-.3,1.03-3.83,17.69-1.22,19.52,3.34,2.34,9.29-1.3,12.78-2.59,3.49-1.29-12.78,9.45-15.44,6.2s.2-14.32.2-14.32l3.68-8.81Z"
      />
      <path
        className="cls-2"
        d="m100.24,58.03s5.02-6.1-11.42-8.35c0,0,13.36,2.26,13.72,3.92.36,1.66-2.3,4.43-2.3,4.43Z"
      />
      <path
        className="cls-2"
        d="m85.86,92.27c-6.06-4.07,7.37-18.74,8.16-18.74l-4.86-1.89-11.89,19.11s2.6,1.32,4.07,2.16c1.47.84,3.43,2.06,3.43,2.06l1.1-2.7Z"
      />
      <path
        className="cls-1"
        d="m69.61,103.64c-2.05-1.58-3.84-3.49-5.13-5.73-1.3-2.22-2.14-4.71-2.51-7.27-.72-5.13.24-10.53,2.77-15.04,2.55-4.51,6.44-8.38,11.29-10.12,2.41-.87,5-1.22,7.59-1.05,1.29.12,2.59.19,3.87.52,1.3.22,2.52.69,3.84.95.72.14,1.08-.69.4-1.04-.62-.28-1.24-.62-1.89-.84-.64-.27-1.33-.39-1.99-.59-1.32-.42-2.72-.57-4.1-.77-2.8-.21-5.66.15-8.32,1.08-2.67.93-5.11,2.46-7.18,4.35-2.07,1.91-3.77,4.16-5.13,6.58-2.73,4.9-3.73,10.68-2.93,16.22.79,5.53,3.8,10.87,8.43,14.08.49.37,1.44-1,1.01-1.36Z"
      />
      <path
        className="cls-2"
        d="m76.79,99.12c-.06-.14-.13-.29-.2-.42-.03-.05-.08-.06-.1-.12-.07-.2-.12-.42-.21-.6-.04-.08-.1-.12-.17-.15-.09-.04-.22-.03-.2-.22,0-.12.88-1.17,1.01-1.15.21.06.37.18.42.45.03.17.19.16.28.25.1.1.17.23.25.34.05.07.1.15.16.21.09.1.16.22.28.27.09.04.26-.02.39-.04.03,0,.07-.02.09-.02.09.02.03.14-.28.59l-.21.31c-.24.35-.48.61-.59.67-.35.19-.52.11-.66-.01-.1-.09-.18-.22-.27-.33l.03-.04Z"
      />
      <path
        className="cls-2"
        d="m81.38,94.58c-.3-.3-.66-.57-1.05-.79-.14-.09-.31-.08-.46-.17-.53-.26-1.1-.51-1.72-.6-.27-.07-.55-.07-.82-.05-.36.02-.66.19-1.12-.16-.13-.05-.16-.21-.11-.46.02-.14.07-.24.1-.52.05-.21.14-.35.24-.45.81-.38,1.75-.56,2.61-.16.51.2,1.06.2,1.57.37.56.19,1.1.46,1.6.77.31.2.6.41.88.64.44.34.79.76,1.19,1.13.3.3.71.52,1.02.84.08.07.16.13.21.21.2.31.1.49-.48.83-.13.08-.26.16-.4.23-.46.23-.91.31-1.13.22-.67-.25-.96-.6-1.32-.99-.26-.28-.53-.59-.86-.84l.03-.05Z"
      />
      <path
        className="cls-2"
        d="m8.95,64.52c-1.23-1.27-2.44-2.59-3.5-4.17-.53-.78-1.02-1.64-1.37-2.7-.09-.26-.17-.53-.21-.85-.06-.29-.09-.6-.07-.96,0-.17.01-.34.03-.51.04-.17.08-.35.13-.52.08-.36.26-.64.42-.95,1.1-1.6,2.44-2.26,3.69-2.8,2.25-.89,4.46-1.31,6.67-1.72,2.2-.39,4.4-.7,6.58-1.02,1.94-.27,3.86-.65,5.76-1.01,2.45-.43,4.81-1.09,7.25-1.4.82.05.77.15.29.82-.23.35-.62.71-1.11,1.11-.25.19-.52.39-.82.56-.31.16-.64.29-.99.4-4.99,1.6-10.06,2.13-15.07,2.86-1.5.21-3,.54-4.46.97-1.44.44-2.88.96-4.03,1.64-.63.36-1.12.81-1.31,1.18-.21.3-.21.83.03,1.51.48,1.33,1.5,2.7,2.58,3.95,1.34,1.56,2.84,3.03,4.4,4.43,2.37,2.13,4.83,4.21,7.35,6.16,1.96,1.52,4,2.85,6,4.34.44.33.89.63,1.31.99,1.59,1.32,1.94,2.06,1.65,2.9-.07.19-.12.4-.22.55-.38.49-1.16.24-2.31-.58-3.47-2.45-7.24-5.17-10.85-8.12-2.7-2.19-5.34-4.5-7.87-7.02l.05-.05Z"
      />
      <path
        className="cls-2"
        d="m33.37,110.6c-1.75.99-3.54,1.92-5.44,2.72-1.92.74-3.88,1.57-6.48,1.34-.95-.11-2.1-.62-2.81-1.51-.68-.86-.93-1.78-1.06-2.61-.22-1.48-.12-2.83.04-4.15.17-1.31.42-2.58.7-3.84.56-2.51,1.32-4.93,2.06-7.35.67-2.14,1.26-4.31,1.86-6.43.8-2.73,1.41-5.48,2.22-8.14.32-.83.31-.76.65.03.17.39.32.97.46,1.66.06.35.13.73.15,1.12,0,.39-.03.8-.1,1.22-.47,2.95-1.08,5.81-1.91,8.6-.8,2.77-1.6,5.54-2.41,8.34-.49,1.65-.86,3.37-1.03,5.07-.09.85-.12,1.69-.08,2.49.03.8.19,1.55.44,2.06.16.27.29.43.5.56.2.13.51.22.88.24.73.07,1.63-.11,2.49-.36,1.76-.51,3.51-1.32,5.22-2.22,2.11-1.1,4.16-2.36,6.17-3.69,3.04-2.02,6.03-4.15,8.85-6.44,2.19-1.78,4.2-3.7,6.21-5.63.44-.43.87-.85,1.31-1.28,1.67-1.56,2.31-1.91,3.22-1.68.21.05.44.08.61.16.58.27.27,1.33-.88,2.61-3.42,3.79-7.51,7.23-11.79,10.46-3.19,2.39-6.51,4.65-10,6.71l-.04-.06Z"
      />
      <path
        className="cls-2"
        d="m88.15,47.78c2.08.36,4.19.72,6.32,1.13.78.15,1.57.23,2.35.43,1.38.36,2.76.75,4.15,1.46.68.35,1.37.85,1.96,1.54.15.16.28.36.4.57.13.2.22.42.29.66.09.23.13.45.15.67.03.22.06.43.04.66,0,.78-.24,1.55-.59,2.17-.35.63-.77,1.17-1.22,1.64-.56.62-1.21,1.12-1.86,1.57-.67.43-1.29.88-2.04,1.16-.46.18-.52.02-.4-.49.09-.54.41-1.4,1-2.04,1-1.17,2.07-2.21,2.43-3.13.18-.45.19-.81.09-1.21-.05-.23-.3-.59-.79-.95-.59-.42-1.41-.79-2.28-1.03-.87-.25-1.8-.42-2.73-.62-2.04-.43-4.14-.77-6.25-1.12l-3.91-.64c-1.98-.32-3.96-.72-5.93-1.1-1.53-.29-3.08-.48-4.62-.86-.34-.09-.68-.15-1.02-.26-1.26-.42-1.53-.88-1.47-1.64.02-.18.02-.36.06-.53.15-.56.8-.77,1.67-.64,2.54.5,5.2,1.04,7.97,1.53,2.05.36,4.13.71,6.25,1.02v.07Z"
      />
      <path
        className="cls-2"
        d="m100.72,102.94c-1.36,1.41-2.88,2.68-4.54,3.74-1.68,1.04-3.49,1.84-5.36,2.41-1.87.58-3.8.87-5.72.98-.96.08-1.91.04-2.87.03l-1.48-.12-1.45-.23c-2.79-.58-5.45-1.57-7.84-3.03-2.1-1.29-4.02-2.87-5.67-4.7-1.64-1.83-3.06-3.93-3.95-6.26-1.75-4.57-2.21-9.66-1.08-14.38.96-4.19,2.87-8.18,5.64-11.43,1.82-2.03,3.87-3.93,6.27-5.3l.44-.28c.15-.08.32-.14.47-.21l.94-.42c.63-.27,1.24-.58,1.91-.74l1.97-.57c.66-.15,1.35-.19,2.02-.28,1.68-.18,1.47.15.22.91-.62.4-1.5.85-2.47,1.51-.49.32-1.03.61-1.59.95-.54.38-1.11.76-1.71,1.11-2.1,1.32-4.13,2.79-5.74,4.69-.45.42-.77.97-1.17,1.44-.38.49-.76.98-1.07,1.52-.32.53-.68,1.04-.99,1.57l-.83,1.67c-.99,2.27-1.74,4.67-1.99,7.16-.22,2.48-.14,5.01.45,7.48,1.2,5.99,6.25,10.86,11.92,13.39,1.57.69,3.21,1.21,4.88,1.49,1.64.27,3.4.27,5.13.13,1.72-.16,3.42-.5,5.05-1.04,1.63-.54,3.16-1.34,4.58-2.3,1.74-1.19,3.29-2.69,4.62-4.37,1.33-1.69,2.41-3.58,3.34-5.54,2.92-5.82,2.44-13.02-.64-18.7-1.14-2.25-2.82-4.14-4.65-5.76l-1.44-1.15c-.49-.37-1.04-.64-1.56-.97-1.01-.7-2.18-1.09-3.28-1.61-1-.4-2.05-.59-3.07-.94-3.94-.85-5.24-.85-6.11-1.83-.2-.2-.47-.4-.59-.58-.2-.31.13-.56.93-.78.4-.12.92-.19,1.55-.23.63-.05,1.36-.09,2.18.05,4.85.44,9.87,2.45,13.81,5.97,1.9,1.81,3.65,3.85,4.88,6.22.71,1.14,1.15,2.4,1.67,3.63.23.63.38,1.28.58,1.92l.27.97c.07.33.12.66.18.99.73,3.91.5,8.05-.92,11.89-1.38,3.67-3.39,7.02-6.05,9.98l-.06-.04Z"
      />
      <path
        className="cls-2"
        d="m80.46,99.83l-.6.28-.29.14-.15.07s-.09.05-.2.11c-.76.38-1.52.8-2.28,1.24l-.84.3-.59-.05c-.18-.02-.27-.09-.41-.12l-.1-.04c-.05-.03-.09-.06-.12-.09-.06-.06-.12-.12-.17-.19-.21-.27-.4-.64-.46-1.2-.05-.57.01-1.24.06-1.87l.17-1.71.13-1.07.28-2.14.14-1.04.04-.27c.02-.14.04-.3.08-.44.09-.26.25-.6.35-.76.37-.66.72-1.21,1.1-1.8,2.64-4.01,5.26-8.01,7.56-12.19.47-.84.9-1.71,1.32-2.57l.6-1.31.7-1.66c.15-.22.29-.43.44-.64.3-.37.82-.38,1.33-.36.26.02.52.04.78.08.13.02.27.03.39.07l.27.05c.72.15,1.42.34,2.12.6.69.27,1.39.56,2,1.05.61.49,1.17,1.12,1.48,1.91.19.5.1.76-.03.79-.15.05-.4-.11-.79-.24-.38-.14-.94-.2-1.5-.24-.29-.02-.6-.01-.9-.06-.29-.06-.59-.14-.9-.25-.68-.24-1.42-.43-2.16-.55-.26-.04-.18-.03-.19-.03h0s-.01-.02,0,0c-.02.01,0-.04-.04.05l-.32.67-.67,1.32c-.46.87-.92,1.73-1.42,2.58-.97,1.7-2.03,3.35-3.13,4.97-1.89,2.8-3.68,5.84-5.53,8.68l-.3.48s-.04.07-.05.11c0,.03-.01.06-.03.2l-.18,1.34-.32,2.72-.16,1.42-.02.31s-.01.12.02.15l.12-.07c.16-.09.33-.18.51-.25.03-.02.06-.04.09-.05l2.31-1.02,1.44-.62.69-.32.31-.16c.05-.03.12-.06.16-.11l.11-.13.17-.25c1.93-2.87,3.83-5.87,5.59-8.87,1.37-2.34,2.58-4.71,3.84-7.07.28-.52.53-1.04.82-1.56,1.05-1.94,1.63-2.41,2.37-2.53.17-.02.36-.08.51-.07.49.04.4,1.02-.23,2.53-2.04,4.37-4.6,8.73-7.23,13.08l-1.93,3.21-1,1.6-.51.81-.28.42c-.07.12-.33.44-.49.66l-.37.29c-.14.09-.3.18-.37.23l-.49.26-.9.44-1.73.83-.03-.06Z"
      />
      <path
        className="cls-2"
        d="m69.52,113.26c-1.17-.68-2.32-1.4-3.39-2.22-.39-.3-.83-.52-1.2-.87-.32-.3-.66-.57-.96-.88l-.9-.95c-.54-.69-1.09-1.36-1.54-2.12-.44-.63-.83-1.3-1.15-2-.18-.46-.34-.93-.48-1.4-.12-.48-.14-.98-.18-1.46-.01-.3.14-.28.41-.09.26.2.69.53.95.95l.82,1.06c.3.33.63.63.82,1.01.25.35.52.69.76,1.05.25.35.55.68.81,1.05.32.43.73.8,1.14,1.18.43.35.9.66,1.33,1.02.99.72,2.01,1.43,3.11,2.03.67.38,1.35.74,2.05,1.06,1.06.49,2.16.93,3.29,1.22.88.2,1.78.24,2.65.34l.58.05c.71.11.92.36,1.04.83.03.11.07.22.08.32.05.35-.28.63-.88.8-.45.13-.89.14-1.34.16-.45,0-.92.05-1.36-.03-.45-.06-.9-.13-1.35-.22l-1.33-.34c-.66-.18-1.29-.43-1.94-.66-.62-.28-1.26-.52-1.87-.83l.03-.06Z"
      />
      <path
        className="cls-2"
        d="m63.69,116.03c-.68-.44-1.29-.99-1.91-1.52-.23-.19-.48-.35-.69-.56-.74-.74-1.42-1.55-1.96-2.45-.24-.4-.51-.76-.74-1.16-.32-.49-.6-1.02-.67-1.62-.02-.19.2-.24.5-.2.29.04.65.15.84.37.66.81,1.41,1.46,2.07,2.29.35.54.95.87,1.47,1.28.57.44,1.16.87,1.78,1.27.38.25.75.51,1.16.73.6.34,1.21.68,1.87.9.51.17,1.05.21,1.57.32.12.02.23.02.35.05.42.12.5.3.44.86-.01.13-.02.26-.04.38-.07.42-.35.7-.72.75-.55.07-1.09.05-1.63-.02-.54-.07-1.04-.29-1.55-.48-.75-.32-1.46-.71-2.17-1.13l.03-.05Z"
      />
      <path
        className="cls-2"
        d="m61.11,23.18c-.98-1.57-2.15-3.11-3.34-3.85-.29-.18-.56-.3-.79-.33-.13-.03-.18-.04-.22-.02-.03-.04-.15.05-.27.07-.54.23-1.27.84-1.92,1.46-.95.94-1.81,2.07-2.69,3.18-3.11,3.94-5.89,8.27-8.44,12.71-1.12,1.97-2.18,4-3.14,6.04-.62,1.31-1.19,2.64-1.75,3.96-.29.66-.61,1.3-.9,1.95l-.43.97c-.14.32-.36.62-.5.93-.36.73-.45.75-.75-.13-.11-.42-.34-1.1-.31-1.81,0-.73.09-1.55.33-2.39,2.08-5.74,4.82-11.09,8.23-16.14,2.03-3.01,3.92-6.29,6.3-9.25.65-.82,1.37-1.6,2.14-2.36.82-.74,1.62-1.5,2.96-2.02.32-.15.78-.17,1.16-.21.38.04.74.06,1.06.16.66.18,1.23.48,1.73.81,1.01.67,1.75,1.49,2.44,2.33,1.67,2.07,2.87,4.32,4,6.57,1.69,3.43,3.06,6.99,4.42,10.49,1.05,2.73,2.18,5.36,3.18,8.08.22.6.46,1.18.66,1.79.75,2.27.67,3.09.03,3.79-.14.16-.27.35-.41.46-.46.35-1.29-.41-1.97-1.99-1.96-4.71-3.74-9.76-5.76-14.7-1.51-3.64-3.09-7.32-5.14-10.51l.06-.05Z"
      />
      <path
        className="cls-2"
        d="m25.06,24.25c.22.02.25.29.18.69-.02.13-.21.43-.29.45-.15.03-.3.07-.44.08-.08,0-1.17-.31-1.56-.38-.37-.07-.71-.2-1.04-.35l-1.93-1.04c-.14-.08-.27-.18-.39-.3-.4-.41-.88-.69-1.39-.93-.08-.04-.16-.1-.28-.07-.2.04-1.51-.69-1.65-.85-.34-.38-.71-.71-1.06-1.06-.05-.05-.13-.06-.13-.18,0-.28-.16-.32-.27-.41-.16-.14-.31-.3-.44-.48-.07-.09-.08-.26-.12-.4-.03-.13.18-.38.22-.4.13-.04.15-.21.21-.34.02-.04.05-.09.07-.12.04-.05,1.44-.07,1.53.03.01,0,.41.2.84.4.42.2.87.41.96.44.1.03.16.14.23.22.03.04,1.32.61,1.36.64.23.17.52.25.67.58.03.06.08.08.14.11.44.23.82.56,1.22.86.2.15.43.26.65.4.04.02.06.07.1.11.04.04.08.1.13.13.53.28.97.72,1.5,1.02.12.07.56.63.7.7.08.04.18.44.27.45Z"
      />
      <path
        className="cls-2"
        d="m32.41,20.52c.12.14-.14.29-.57.46-.14.06-.55.1-.62.06-.14-.08-.28-.16-.39-.25-.06-.04-.61-1-.69-1.35-.14-.33-.17-.67-.18-1-.01-.67-.02-1.36.12-2,.02-.15.08-.28.17-.41.3-.44.42-.91.5-1.39.01-.08.05-.15-.02-.26-.13-.18.25-1.42.38-1.55.32-.33.57-.67.85-.99.04-.05.02-.12.16-.12.31-.01.31-.14.37-.25.09-.15.19-.29.34-.42.07-.06.26-.08.41-.11.14-.03.53.17.56.21.1.12.31.14.48.2.06.02.13.05.18.07.1.05.44,1.22.4,1.31-.02.03-.29,1.4-.29,1.56,0,.08-.09.14-.15.2-.04.03-.15,1.12-.16,1.15-.1.2-.05.44-.35.58-.06.03-.06.08-.06.12-.05.37-.23.71-.36,1.05-.08.18-.05.37-.11.55,0,.03-.05.06-.08.09-.03.04-.07.08-.08.12-.06.43-.27.86-.21,1.3.01.1-.29.58-.29.71,0,.06-.36.31-.31.37Z"
      />
      <path
        className="cls-2"
        d="m108.28,67.56c.01.14-.15.21-.38.26-.07.01-.27-.04-.29-.08-.04-.09-.09-.18-.12-.26-.01-.05-.07-.83-.13-1.11-.05-.27-.05-.52-.04-.77-.01-.52.11-1.01.19-1.51.02-.11.06-.21.12-.31.21-.33.31-.7.36-1.09,0-.06.04-.12-.02-.21-.1-.14.14-1.15.23-1.26.23-.27.41-.57.62-.84.03-.04.01-.1.12-.11.25-.02.24-.13.29-.22.07-.13.14-.25.26-.36.06-.05.21-.07.34-.1.12-.02.45.14.48.17.09.1.27.11.42.16.05.02.11.04.15.05.09.04.37.99.33,1.07,0,0-.07.29-.15.6-.07.3-.15.62-.15.68,0,.07-.08.12-.14.17-.03.03-.21.94-.23.97-.09.17-.09.37-.33.5-.05.02-.05.06-.06.1-.09.32-.28.61-.43.91-.08.15-.11.32-.17.48-.01.03-.04.05-.06.08-.03.03-.06.06-.07.1-.15.37-.41.72-.53,1.11-.03.09-.35.46-.37.57-.02.06-.26.2-.25.26Z"
      />
      <path
        className="cls-2"
        d="m117.23,67.37c-.03.14-.23.12-.5.06-.08-.02-.28-.16-.29-.21-.02-.11-.04-.22-.04-.32,0-.06.22-.88.29-1.19.07-.29.16-.57.35-.76.36-.4.71-.81,1.11-1.18.09-.08.19-.15.31-.19.4-.15.72-.4,1.03-.69.05-.05.12-.08.13-.18,0-.18.97-.83,1.14-.84.4-.05.78-.13,1.18-.16.06,0,.1-.06.18.02.19.18.28.11.4.08.16-.03.32-.04.5,0,.09.02.19.13.29.22.09.08.12.45.1.49-.04.13.04.28.08.43.01.05.02.12.03.16-.01.09-.49.84-.55.86,0,0-.23.12-.46.27-.23.14-.46.32-.5.36-.04.05-.13.03-.2.02-.04,0-.67.56-.7.58-.17.07-.26.23-.52.18-.05-.01-.08.02-.1.04-.23.2-.53.32-.8.49-.14.08-.25.2-.37.3-.02.02-.06.02-.09.03-.04.01-.08.02-.11.04-.27.26-.59.48-.9.71-.07.05-.55.19-.61.28-.04.04-.35.04-.36.1Z"
      />
      <path
        className="cls-2"
        d="m113.96,75.65c-.15.04-.23-.15-.26-.44-.01-.1.05-.36.1-.39.09-.07.17-.13.26-.18.05-.02.78-.14,1.04-.23.25-.07.48-.1.72-.11l1.46-.1c.11,0,.21,0,.32.05.35.18.71.23,1.09.21.06,0,.12.02.19-.06.11-.13,1.12-.05,1.25.03.3.19.62.33.92.51.04.02.1,0,.13.11.06.25.17.23.27.26.14.05.27.11.4.21.06.05.1.21.15.33.04.11-.07.48-.1.51-.09.1-.07.29-.1.44-.01.05-.02.12-.03.16-.03.09-.88.57-.96.55,0,0-.29,0-.6-.01-.3,0-.62,0-.69,0-.07.01-.13-.06-.19-.1-.03-.02-.96.01-.99,0-.18-.06-.38,0-.55-.24-.03-.04-.07-.04-.11-.04-.33,0-.64-.17-.96-.32-.16-.07-.33-.09-.49-.14-.03,0-.06-.04-.08-.06-.04-.02-.07-.06-.11-.07-.39-.09-.77-.29-1.18-.34-.09-.02-.5-.3-.61-.31-.06-.01-.21-.27-.27-.25Z"
      />
    </svg>
  );
}
