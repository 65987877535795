import React from 'react';
import { type NavigateFunction, useNavigate } from 'react-router-dom';

import { Card } from 'components/core/Card/Card';
import { CardBody } from 'components/core/Card/CardBody';
import { IconBadge } from 'components/core/IconBadge/IconBadge';
import { Settings } from 'components/core/Settings/Settings';
import { type ButtonMenuProps } from 'components/core/Settings/Settings.types';
import { Text } from 'components/core/Text/Text';

import { type AssetCardProps } from './AssetCard.props';

export function AssetCard({
  id = '',
  name = '',
  icon = 'home',
  loading = false,
  href,
  date = new Date(),
  openInNewTab = false,
  hasMenu = true,
  onDeletionClick,
}: AssetCardProps): JSX.Element {
  const navigate: NavigateFunction = useNavigate();

  /* ------------------------------------------------- menu options ------------------------------------------------- */

  const buttonsMenu: ButtonMenuProps[] = [
    {
      name: 'Modifier',
      iconName: 'edit',
      textColor: 'black',
      isEnabled: false,
      onClick: () => {
        console.log('Modifier');
      },
    },
    {
      name: 'Supprimer',
      iconName: 'trash',
      textColor: 'red',
      onClick: handleDeletionClick,
    },
  ];

  function handleClick(): void {
    if (loading) return;
    if (href === undefined) console.error('href is null or undefined');
    else {
      if (openInNewTab) window.open(href, '_blank');
      else navigate(href);
    }
  }

  function handleDeletionClick(event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void {
    event.stopPropagation();
    if (onDeletionClick !== undefined) onDeletionClick(id);
  }

  return (
    <Card onClick={handleClick} loading={loading} shadow="md" hasAnimation cursor="pointer">
      <CardBody>
        {loading ? (
          <>
            <IconBadge loading />
            <Text loading />
            <Text loading />
          </>
        ) : (
          <>
            {hasMenu && (
              <div className="absolute top-2 right-4">
                <Settings settingsIconName="more" buttonsMenu={buttonsMenu} />
              </div>
            )}
            <IconBadge shadow="sm" icon={icon} color="black" size="20" />
            <Text
              content={name.toUpperCase()}
              color="black"
              weight="bold"
              size="base"
              whitespace="normal"
              position="center"
            />
            <Text
              content={
                'Ajouté le ' +
                String(
                  date.toLocaleString('fr-FR', {
                    year: '2-digit',
                    month: 'numeric',
                    day: 'numeric',
                  }),
                )
              }
              color="gray-200"
              weight="bold"
              size="xs"
              position="center"
            />
          </>
        )}
      </CardBody>
    </Card>
  );
}
