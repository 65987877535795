/* tslint:disable */
/* eslint-disable */
/**
 * Specks API
 * APIs for specks SaaS
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { GetProductDto } from './GetProductDto';
import {
    GetProductDtoFromJSON,
    GetProductDtoFromJSONTyped,
    GetProductDtoToJSON,
} from './GetProductDto';

/**
 * 
 * @export
 * @interface GetProductsDto
 */
export interface GetProductsDto {
    /**
     * 
     * @type {Array<GetProductDto>}
     * @memberof GetProductsDto
     */
    products: Array<GetProductDto>;
}

/**
 * Check if a given object implements the GetProductsDto interface.
 */
export function instanceOfGetProductsDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "products" in value;

    return isInstance;
}

export function GetProductsDtoFromJSON(json: any): GetProductsDto {
    return GetProductsDtoFromJSONTyped(json, false);
}

export function GetProductsDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetProductsDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'products': ((json['products'] as Array<any>).map(GetProductDtoFromJSON)),
    };
}

export function GetProductsDtoToJSON(value?: GetProductsDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'products': ((value.products as Array<any>).map(GetProductDtoToJSON)),
    };
}

