import { type InputTypeBody } from 'forms/ApiBodyForm/ApiBodyForm';

import { type EnumValue } from './EnumValue.entity';

export class Enum {
  id: string;

  valueType: InputTypeBody;

  values: EnumValue[];
}
