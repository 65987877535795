import {
  type ComponentIdDto,
  type GetAppSchemaComponentDto,
  type GetComponentDto,
  type GetComponentWithPagesDto,
} from 'api';

import { type ComponentType } from 'models/ComponentTypes.type';
import { type Status } from 'models/Status.types';
import { BackApiSpecification } from 'models/backApiSpecification.entity';
import { Component } from 'models/component.entity';

export function fromGetComponentDto(getComponentDto: GetComponentDto): Component {
  const component: Component = new Component();
  component.id = getComponentDto.id;
  component.name = getComponentDto.name;
  component.status = getComponentDto.status as Status;
  component.externalLinks = getComponentDto.externalLinks;
  component.componentType = getComponentDto.componentType as ComponentType;
  component.internal = getComponentDto.internal;
  component.dictionary.id = getComponentDto.dictionary.id;
  component.faq.id = getComponentDto.faq.id;
  component.frontSpecification.id = getComponentDto.specification?.id;
  component.BackApiSpecification.id = getComponentDto.specification?.id;
  return component;
}
export function fromGetComponentWithPagesDto(getComponentDto: GetComponentWithPagesDto): Component {
  const component: Component = new Component();
  component.id = getComponentDto.id;
  component.name = getComponentDto.name;
  component.status = getComponentDto.status as Status;
  component.externalLinks = getComponentDto.externalLinks;
  component.componentType = getComponentDto.componentType as ComponentType;
  component.internal = getComponentDto.internal;
  component.frontSpecification.id = getComponentDto.specification?.id;
  component.BackApiSpecification.id = getComponentDto.specification?.id;
  component.pages = getComponentDto.pages;
  component.resources = getComponentDto.resources;
  return component;
}

export function fromComponentIdDto(componentIdDto: ComponentIdDto): Component {
  const component: Component = new Component();
  component.id = componentIdDto.id;
  return component;
}

export function fromGetAppSchemaComponentDto(getAppSchemaComponentDto: GetAppSchemaComponentDto): Component {
  const component: Component = new Component();
  component.id = getAppSchemaComponentDto.id;
  component.name = getAppSchemaComponentDto.name;
  component.componentType = getAppSchemaComponentDto.componentType as ComponentType;
  component.internal = getAppSchemaComponentDto.internal;
  component.status = getAppSchemaComponentDto.status as Status;
  component.isAlreadyUsedInAnotherAppSchema = getAppSchemaComponentDto.isAlreadyinAppSchema;
  component.BackApiSpecification = new BackApiSpecification();
  component.BackApiSpecification.id = getAppSchemaComponentDto.backSpecificationId;
  return component;
}
