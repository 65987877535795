/* tslint:disable */
/* eslint-disable */
/**
 * Specks API
 * APIs for specks SaaS
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  CreateAppSchemaRevisionDto,
  CreateExternalLinkDto,
  CreateFeatureDto,
  CreatePersonaDto,
  CreateProductDto,
  ErrorBadRequest,
  ErrorNotFound,
  GetAppSchemaComponentsDto,
  GetAppSchemaRevisionDto,
  GetExternalLinkDto,
  GetFeatureDto,
  GetPersonaDto,
  GetProductAppSchemaRevisionsDto,
  GetProductDto,
  GetProductExternalLinksDto,
  GetProductFeaturesDto,
  GetProductPersonaeDto,
  GetProductsDto,
  UpdateProductDto,
} from '../models';
import {
    CreateAppSchemaRevisionDtoFromJSON,
    CreateAppSchemaRevisionDtoToJSON,
    CreateExternalLinkDtoFromJSON,
    CreateExternalLinkDtoToJSON,
    CreateFeatureDtoFromJSON,
    CreateFeatureDtoToJSON,
    CreatePersonaDtoFromJSON,
    CreatePersonaDtoToJSON,
    CreateProductDtoFromJSON,
    CreateProductDtoToJSON,
    ErrorBadRequestFromJSON,
    ErrorBadRequestToJSON,
    ErrorNotFoundFromJSON,
    ErrorNotFoundToJSON,
    GetAppSchemaComponentsDtoFromJSON,
    GetAppSchemaComponentsDtoToJSON,
    GetAppSchemaRevisionDtoFromJSON,
    GetAppSchemaRevisionDtoToJSON,
    GetExternalLinkDtoFromJSON,
    GetExternalLinkDtoToJSON,
    GetFeatureDtoFromJSON,
    GetFeatureDtoToJSON,
    GetPersonaDtoFromJSON,
    GetPersonaDtoToJSON,
    GetProductAppSchemaRevisionsDtoFromJSON,
    GetProductAppSchemaRevisionsDtoToJSON,
    GetProductDtoFromJSON,
    GetProductDtoToJSON,
    GetProductExternalLinksDtoFromJSON,
    GetProductExternalLinksDtoToJSON,
    GetProductFeaturesDtoFromJSON,
    GetProductFeaturesDtoToJSON,
    GetProductPersonaeDtoFromJSON,
    GetProductPersonaeDtoToJSON,
    GetProductsDtoFromJSON,
    GetProductsDtoToJSON,
    UpdateProductDtoFromJSON,
    UpdateProductDtoToJSON,
} from '../models';

export interface ProductControllerCreateRequest {
    createProductDto: CreateProductDto;
}

export interface ProductControllerCreateAppSchemaRevisionRequest {
    id: string;
    createAppSchemaRevisionDto: CreateAppSchemaRevisionDto;
}

export interface ProductControllerCreateExternalLinkRequest {
    id: string;
    createExternalLinkDto: CreateExternalLinkDto;
}

export interface ProductControllerCreateFeatureRequest {
    id: string;
    createFeatureDto: CreateFeatureDto;
}

export interface ProductControllerCreatePersonaRequest {
    id: string;
    createPersonaDto: CreatePersonaDto;
}

export interface ProductControllerDeleteRequest {
    id: string;
}

export interface ProductControllerFindAllRequest {
    favorites?: boolean;
}

export interface ProductControllerFindByIdRequest {
    id: string;
}

export interface ProductControllerFindComponentsForAppSchemaRequest {
    id: string;
}

export interface ProductControllerFindFeaturesByIdRequest {
    id: string;
}

export interface ProductControllerFindLinkedComponentByIdRequest {
    componentId: string;
}

export interface ProductControllerFindProductAppSchemaRevisionsByIdRequest {
    id: string;
}

export interface ProductControllerFindProductExternalLinksByIdRequest {
    id: string;
}

export interface ProductControllerFindProductPersonaeByIdRequest {
    id: string;
}

export interface ProductControllerUpdateRequest {
    id: string;
    updateProductDto: UpdateProductDto;
}

/**
 * 
 */
export class ProductsApi extends runtime.BaseAPI {

    /**
     */
    async productControllerCreateRaw(requestParameters: ProductControllerCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetProductDto>> {
        if (requestParameters.createProductDto === null || requestParameters.createProductDto === undefined) {
            throw new runtime.RequiredError('createProductDto','Required parameter requestParameters.createProductDto was null or undefined when calling productControllerCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/products`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateProductDtoToJSON(requestParameters.createProductDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetProductDtoFromJSON(jsonValue));
    }

    /**
     */
    async productControllerCreate(requestParameters: ProductControllerCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetProductDto> {
        const response = await this.productControllerCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async productControllerCreateAppSchemaRevisionRaw(requestParameters: ProductControllerCreateAppSchemaRevisionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetAppSchemaRevisionDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling productControllerCreateAppSchemaRevision.');
        }

        if (requestParameters.createAppSchemaRevisionDto === null || requestParameters.createAppSchemaRevisionDto === undefined) {
            throw new runtime.RequiredError('createAppSchemaRevisionDto','Required parameter requestParameters.createAppSchemaRevisionDto was null or undefined when calling productControllerCreateAppSchemaRevision.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/products/{id}/app-schemas`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateAppSchemaRevisionDtoToJSON(requestParameters.createAppSchemaRevisionDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetAppSchemaRevisionDtoFromJSON(jsonValue));
    }

    /**
     */
    async productControllerCreateAppSchemaRevision(requestParameters: ProductControllerCreateAppSchemaRevisionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetAppSchemaRevisionDto> {
        const response = await this.productControllerCreateAppSchemaRevisionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async productControllerCreateExternalLinkRaw(requestParameters: ProductControllerCreateExternalLinkRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetExternalLinkDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling productControllerCreateExternalLink.');
        }

        if (requestParameters.createExternalLinkDto === null || requestParameters.createExternalLinkDto === undefined) {
            throw new runtime.RequiredError('createExternalLinkDto','Required parameter requestParameters.createExternalLinkDto was null or undefined when calling productControllerCreateExternalLink.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/products/{id}/external-links`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateExternalLinkDtoToJSON(requestParameters.createExternalLinkDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetExternalLinkDtoFromJSON(jsonValue));
    }

    /**
     */
    async productControllerCreateExternalLink(requestParameters: ProductControllerCreateExternalLinkRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetExternalLinkDto> {
        const response = await this.productControllerCreateExternalLinkRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async productControllerCreateFeatureRaw(requestParameters: ProductControllerCreateFeatureRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetFeatureDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling productControllerCreateFeature.');
        }

        if (requestParameters.createFeatureDto === null || requestParameters.createFeatureDto === undefined) {
            throw new runtime.RequiredError('createFeatureDto','Required parameter requestParameters.createFeatureDto was null or undefined when calling productControllerCreateFeature.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/products/{id}/features`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateFeatureDtoToJSON(requestParameters.createFeatureDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetFeatureDtoFromJSON(jsonValue));
    }

    /**
     */
    async productControllerCreateFeature(requestParameters: ProductControllerCreateFeatureRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetFeatureDto> {
        const response = await this.productControllerCreateFeatureRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async productControllerCreatePersonaRaw(requestParameters: ProductControllerCreatePersonaRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetPersonaDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling productControllerCreatePersona.');
        }

        if (requestParameters.createPersonaDto === null || requestParameters.createPersonaDto === undefined) {
            throw new runtime.RequiredError('createPersonaDto','Required parameter requestParameters.createPersonaDto was null or undefined when calling productControllerCreatePersona.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/products/{id}/persona`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreatePersonaDtoToJSON(requestParameters.createPersonaDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetPersonaDtoFromJSON(jsonValue));
    }

    /**
     */
    async productControllerCreatePersona(requestParameters: ProductControllerCreatePersonaRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetPersonaDto> {
        const response = await this.productControllerCreatePersonaRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async productControllerDeleteRaw(requestParameters: ProductControllerDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling productControllerDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/products/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async productControllerDelete(requestParameters: ProductControllerDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.productControllerDeleteRaw(requestParameters, initOverrides);
    }

    /**
     */
    async productControllerFindAllRaw(requestParameters: ProductControllerFindAllRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetProductsDto>> {
        const queryParameters: any = {};

        if (requestParameters.favorites !== undefined) {
            queryParameters['favorites'] = requestParameters.favorites;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/products`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetProductsDtoFromJSON(jsonValue));
    }

    /**
     */
    async productControllerFindAll(requestParameters: ProductControllerFindAllRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetProductsDto> {
        const response = await this.productControllerFindAllRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async productControllerFindByIdRaw(requestParameters: ProductControllerFindByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetProductDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling productControllerFindById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/products/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetProductDtoFromJSON(jsonValue));
    }

    /**
     */
    async productControllerFindById(requestParameters: ProductControllerFindByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetProductDto> {
        const response = await this.productControllerFindByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async productControllerFindComponentsForAppSchemaRaw(requestParameters: ProductControllerFindComponentsForAppSchemaRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetAppSchemaComponentsDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling productControllerFindComponentsForAppSchema.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/products/{id}/app-schemas/components`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetAppSchemaComponentsDtoFromJSON(jsonValue));
    }

    /**
     */
    async productControllerFindComponentsForAppSchema(requestParameters: ProductControllerFindComponentsForAppSchemaRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetAppSchemaComponentsDto> {
        const response = await this.productControllerFindComponentsForAppSchemaRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async productControllerFindFeaturesByIdRaw(requestParameters: ProductControllerFindFeaturesByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetProductFeaturesDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling productControllerFindFeaturesById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/products/{id}/features`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetProductFeaturesDtoFromJSON(jsonValue));
    }

    /**
     */
    async productControllerFindFeaturesById(requestParameters: ProductControllerFindFeaturesByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetProductFeaturesDto> {
        const response = await this.productControllerFindFeaturesByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async productControllerFindLinkedComponentByIdRaw(requestParameters: ProductControllerFindLinkedComponentByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetAppSchemaComponentsDto>> {
        if (requestParameters.componentId === null || requestParameters.componentId === undefined) {
            throw new runtime.RequiredError('componentId','Required parameter requestParameters.componentId was null or undefined when calling productControllerFindLinkedComponentById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/products/linked-components/{componentId}`.replace(`{${"componentId"}}`, encodeURIComponent(String(requestParameters.componentId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetAppSchemaComponentsDtoFromJSON(jsonValue));
    }

    /**
     */
    async productControllerFindLinkedComponentById(requestParameters: ProductControllerFindLinkedComponentByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetAppSchemaComponentsDto> {
        const response = await this.productControllerFindLinkedComponentByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async productControllerFindProductAppSchemaRevisionsByIdRaw(requestParameters: ProductControllerFindProductAppSchemaRevisionsByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetProductAppSchemaRevisionsDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling productControllerFindProductAppSchemaRevisionsById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/products/{id}/app-schemas`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetProductAppSchemaRevisionsDtoFromJSON(jsonValue));
    }

    /**
     */
    async productControllerFindProductAppSchemaRevisionsById(requestParameters: ProductControllerFindProductAppSchemaRevisionsByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetProductAppSchemaRevisionsDto> {
        const response = await this.productControllerFindProductAppSchemaRevisionsByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async productControllerFindProductExternalLinksByIdRaw(requestParameters: ProductControllerFindProductExternalLinksByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetProductExternalLinksDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling productControllerFindProductExternalLinksById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/products/{id}/external-links`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetProductExternalLinksDtoFromJSON(jsonValue));
    }

    /**
     */
    async productControllerFindProductExternalLinksById(requestParameters: ProductControllerFindProductExternalLinksByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetProductExternalLinksDto> {
        const response = await this.productControllerFindProductExternalLinksByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async productControllerFindProductPersonaeByIdRaw(requestParameters: ProductControllerFindProductPersonaeByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetProductPersonaeDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling productControllerFindProductPersonaeById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/products/{id}/personae`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetProductPersonaeDtoFromJSON(jsonValue));
    }

    /**
     */
    async productControllerFindProductPersonaeById(requestParameters: ProductControllerFindProductPersonaeByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetProductPersonaeDto> {
        const response = await this.productControllerFindProductPersonaeByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async productControllerGetIdsOfTutorialRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<string>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/products/tutorial`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async productControllerGetIdsOfTutorial(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<string>> {
        const response = await this.productControllerGetIdsOfTutorialRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async productControllerUpdateRaw(requestParameters: ProductControllerUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetProductDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling productControllerUpdate.');
        }

        if (requestParameters.updateProductDto === null || requestParameters.updateProductDto === undefined) {
            throw new runtime.RequiredError('updateProductDto','Required parameter requestParameters.updateProductDto was null or undefined when calling productControllerUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/products/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateProductDtoToJSON(requestParameters.updateProductDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetProductDtoFromJSON(jsonValue));
    }

    /**
     */
    async productControllerUpdate(requestParameters: ProductControllerUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetProductDto> {
        const response = await this.productControllerUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
