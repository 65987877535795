/* tslint:disable */
/* eslint-disable */
/**
 * Specks API
 * APIs for specks SaaS
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { UiComponentRevision } from './UiComponentRevision';
import {
    UiComponentRevisionFromJSON,
    UiComponentRevisionFromJSONTyped,
    UiComponentRevisionToJSON,
} from './UiComponentRevision';
import type { UicPosition } from './UicPosition';
import {
    UicPositionFromJSON,
    UicPositionFromJSONTyped,
    UicPositionToJSON,
} from './UicPosition';

/**
 * 
 * @export
 * @interface UiComponent
 */
export interface UiComponent {
    /**
     * 
     * @type {string}
     * @memberof UiComponent
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof UiComponent
     */
    functionalId?: string;
    /**
     * 
     * @type {string}
     * @memberof UiComponent
     */
    type: UiComponentTypeEnum;
    /**
     * 
     * @type {Date}
     * @memberof UiComponent
     */
    createdAt: Date;
    /**
     * 
     * @type {Array<UiComponentRevision>}
     * @memberof UiComponent
     */
    uiComponentRevisions: Array<UiComponentRevision>;
    /**
     * 
     * @type {Array<UicPosition>}
     * @memberof UiComponent
     */
    positionsAsParent: Array<UicPosition>;
    /**
     * 
     * @type {Array<UicPosition>}
     * @memberof UiComponent
     */
    positionsAsChild: Array<UicPosition>;
}


/**
 * @export
 */
export const UiComponentTypeEnum = {
    Title: 'title',
    Icon: 'icon',
    Text: 'text',
    Container: 'container',
    Input: 'input',
    Button: 'button',
    List: 'list',
    Media: 'media',
    Date: 'date',
    Select: 'select'
} as const;
export type UiComponentTypeEnum = typeof UiComponentTypeEnum[keyof typeof UiComponentTypeEnum];


/**
 * Check if a given object implements the UiComponent interface.
 */
export function instanceOfUiComponent(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "createdAt" in value;
    isInstance = isInstance && "uiComponentRevisions" in value;
    isInstance = isInstance && "positionsAsParent" in value;
    isInstance = isInstance && "positionsAsChild" in value;

    return isInstance;
}

export function UiComponentFromJSON(json: any): UiComponent {
    return UiComponentFromJSONTyped(json, false);
}

export function UiComponentFromJSONTyped(json: any, ignoreDiscriminator: boolean): UiComponent {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'functionalId': !exists(json, 'functionalId') ? undefined : json['functionalId'],
        'type': json['type'],
        'createdAt': (new Date(json['createdAt'])),
        'uiComponentRevisions': ((json['uiComponentRevisions'] as Array<any>).map(UiComponentRevisionFromJSON)),
        'positionsAsParent': ((json['positionsAsParent'] as Array<any>).map(UicPositionFromJSON)),
        'positionsAsChild': ((json['positionsAsChild'] as Array<any>).map(UicPositionFromJSON)),
    };
}

export function UiComponentToJSON(value?: UiComponent | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'functionalId': value.functionalId,
        'type': value.type,
        'createdAt': (value.createdAt.toISOString()),
        'uiComponentRevisions': ((value.uiComponentRevisions as Array<any>).map(UiComponentRevisionToJSON)),
        'positionsAsParent': ((value.positionsAsParent as Array<any>).map(UicPositionToJSON)),
        'positionsAsChild': ((value.positionsAsChild as Array<any>).map(UicPositionToJSON)),
    };
}

