import * as Yup from 'yup';

import React, { useEffect } from 'react';
import { type SubmitHandler, useForm } from 'react-hook-form';
import uuid from 'react-uuid';

import { yupResolver } from '@hookform/resolvers/yup';

import { type ResponseRowBgColor, responseRowBgColorStyle } from 'pages/Component/BackEndApi/Api/Detail/ResponseRow';

import { Button } from 'components/core/Button/Button';
import Input from 'components/core/Input/Input';
import { Text } from 'components/core/Text/Text';
import { type ResourceHandler, useResourceHandler } from 'components/specks/Widgets/ApiWidget';

import { ApiResponse } from 'models/ApiResponse.entity';

import concatClassNames from 'utils/classNames';

/* ----------------------------------------------------- Form ----------------------------------------------------- */

interface ApiResponseFormModel {
  code: number | undefined;
  message: string;
}

const schema: Yup.ObjectSchema<ApiResponseFormModel> = Yup.object().shape({
  code: Yup.number().required('Champ obligatoire').typeError('Saississez un nombre'),
  message: Yup.string().required('Champ obligatoire'),
});

/* ----------------------------------------------------- Props ---------------------------------------------------- */

interface ApiResponseFormProps {
  onSubmit: (newParameter: ApiResponse) => void;
  onCancel?: () => void;
  bgColor: ResponseRowBgColor;
  oldApiResponse?: ApiResponse;
}

// TODO : Check for unique code

export function ApiResponseForm({ onSubmit, onCancel, bgColor, oldApiResponse }: ApiResponseFormProps): JSX.Element {
  /* --------------------------------------------------- contexts --------------------------------------------------- */

  const resourceHandler: ResourceHandler = useResourceHandler();

  /* ---------------------------------------------------- states ---------------------------------------------------- */

  /* --------------------------------------------------- variables -------------------------------------------------- */

  const mutateStatus: string = resourceHandler.details.mutateStatus;

  /* ----------------------------------------------------- form ----------------------------------------------------- */

  const {
    register,
    watch,
    setValue,
    handleSubmit,
    formState: { errors, isSubmitted },
  } = useForm<ApiResponseFormModel>({
    resolver: yupResolver(schema),
  });

  const onSubmitForm: SubmitHandler<ApiResponseFormModel> = (data) => {
    const apiResponse: ApiResponse = new ApiResponse();
    apiResponse.fonctionalId = oldApiResponse !== undefined ? oldApiResponse.fonctionalId : uuid();
    apiResponse.statusCode = data.code ?? 0;
    apiResponse.message = data.message;
    apiResponse.apiResponseBody = oldApiResponse?.apiResponseBody ?? [];
    resourceHandler.details.setApiResponseData(apiResponse);
    onSubmit(apiResponse);
  };

  function onCancelForm(e: any): void {
    e.preventDefault();
    onCancel?.();
  }

  const watchMessage: string = watch('message');
  let isFormValid: boolean = true;
  if (watchMessage === undefined || watchMessage.length < 3) {
    isFormValid = false;
  }

  useEffect(() => {
    if (oldApiResponse === undefined) return;
    setValue('code', oldApiResponse.statusCode);
    setValue('message', oldApiResponse.message);
  }, [oldApiResponse, setValue]);

  /* --------------------------------------------------- variables -------------------------------------------------- */

  const gridTemplateColumns: string = 'grid-cols-[repeat(17,minmax(0,1fr))]';

  /* ------------------------------------------------------ JSX ----------------------------------------------------- */

  return (
    <form
      id="api-response-form"
      className={concatClassNames(
        'grid',
        gridTemplateColumns,
        'gap-1',
        'gap-y-1.5',
        responseRowBgColorStyle[bgColor],
        'rounded-md',
        'mt-2',
        'border-1 border-gradient2-from',
        'py-2',
        'px-3',
        'shadow-apiRow',
        'align-middle',
      )}
      onSubmit={handleSubmit(onSubmitForm)}
    >
      <div className={'col-span-2'}>
        <Input
          placeholder="Code"
          {...register('code')}
          maxLength={3}
          isNumber
          textSize="base"
          bgColor="white"
          borderColor="gray-500"
        />
      </div>
      <div className={'col-start-3 col-end-[17]'}>
        <Input
          placeholder="Contexte fonctionnel de la réponse"
          {...register('message')}
          maxLength={40}
          textSize="base"
          bgColor="white"
          borderColor="gray-500"
        />
      </div>
      <div id="edit-errors" className="col-start-1 col-span-12">
        {errors.code?.message !== undefined && isSubmitted ? (
          <Text content={errors.code.message} color="red-500" position="left" />
        ) : undefined}
        {errors.message?.message !== undefined && isSubmitted ? (
          <Text content={errors.message.message} color="red-500" position="left" />
        ) : undefined}
      </div>
      <div className="col-span-2 col-end-[16]">
        <Button
          type="button"
          content="Annuler"
          bgColor="white"
          textColor="black"
          borderWidth="xs"
          borderColor="grey-500"
          onClick={onCancelForm}
        />
      </div>
      <div className="col-span-2 col-end-[18]">
        {mutateStatus === 'loading' && <Button iconName="spinCircle" type="button" iconAnimation="spin" />}
        {mutateStatus !== 'loading' && <Button content="Valider" type="submit" disabled={!isFormValid} />}
      </div>
    </form>
  );
}
