import { SpecksApisContext } from 'App';

import React, { useContext, useEffect, useState } from 'react';
import { type QueryClient, useMutation, useQuery, useQueryClient } from 'react-query';
import { type NavigateFunction, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { useAuth0 } from '@auth0/auth0-react';

import { BasePage } from 'pages/core/BasePage/BasePage';

import { CreateOrEditComponentForm } from 'forms/CreateOrEditComponentForm/CreateOrEditComponentForm';

import { Button } from 'components/core/Button/Button';
import { CardContainer } from 'components/core/CardContainer/CardContainer';
import { Modal } from 'components/core/Modal/Modal';
import { Section } from 'components/core/Section/Section';
import { type ButtonMenuProps } from 'components/core/Settings/Settings.types';
import { SubTitle } from 'components/core/SubTitle/SubTitle';
import { Table } from 'components/core/Table/Table';
import { TableBody } from 'components/core/Table/TableBody';
import { Title } from 'components/core/Title/Title';
import { ComponentCard } from 'components/specks/ComponentCard/ComponentCard';
import { ComponentRow } from 'components/specks/ComponentRow/ComponentRow';
import { ContextMenu } from 'components/specks/ContextMenu/ContextMenu';
import { DeleteComponent } from 'components/specks/DeleteComponent/DeleteComponent';
import { Empty } from 'components/specks/Empty/Empty';
import { NetworkError } from 'components/specks/Error/Error';
import { Thumbnail } from 'components/specks/Thumbnail/Thumbnail';

import { type Component } from 'models/component.entity';

import concatClassNames from 'utils/classNames';
import getIcon from 'utils/getIcon';
import { tooltipDescriptionsStyle } from 'utils/tooltipsDescriptions';

export function ComponentsPage(): JSX.Element {
  /* --------------------------------------------------- contexts --------------------------------------------------- */

  const navigate: NavigateFunction = useNavigate();
  const { componentService } = useContext(SpecksApisContext);
  const { getAccessTokenSilently } = useAuth0();
  const queryClient: QueryClient = useQueryClient();

  /* ---------------------------------------------------- states ---------------------------------------------------- */

  const [isModalOpened, setIsModalOpened] = useState<boolean>(false);
  const [isCreateComponentLoading, setIsCreateComponentLoading] = useState<boolean>(false);
  const [componentToDelete, setComponentToDelete] = useState<string>();
  const [rightClickedComponent, setRightClickedComponent] = useState<string>();
  const [isCardView, setIsCardView] = useState<boolean>(false);

  const [points, setPoints] = useState({ x: 0, y: 0 });

  /* ---------------------------------------------------- Queries --------------------------------------------------- */

  async function getComponents(): Promise<Component[]> {
    const accessToken: string = await getAccessTokenSilently();
    return await componentService.findAll(accessToken);
  }

  const { data, status } = useQuery<Component[], Error>('components', getComponents, {
    cacheTime: 0,
  });

  useEffect(() => {
    window.addEventListener('click', handleClick);
    return () => {
      window.removeEventListener('click', handleClick);
    };
  }, []);

  function handleClick(): void {
    setRightClickedComponent(undefined);
  }

  /* ----------------------------------------------- Create Component ----------------------------------------------- */

  async function postComponent(component: Component): Promise<Component> {
    const accessToken: string = await getAccessTokenSilently();
    return await componentService.create(component, accessToken);
  }
  const { mutate } = useMutation(postComponent, {
    onSuccess: (component) => {
      toast.success('Composant créé avec succès');
      navigate(`/component/${component.id}`);
    },
    onError: () => {
      toast.error('Une erreur est survenue lors de la création du composant');
    },
  });

  function handleComponentFormSubmission(component: Component): void {
    try {
      setIsCreateComponentLoading(true);
      mutate(component);
    } catch (error) {
      console.error(error);
    }
  }

  /* ----------------------------------------------- Delete Component ----------------------------------------------- */

  function componentDeletionOnSuccess(): void {
    queryClient.setQueryData('components', (oldData: Component[] | undefined) => {
      if (oldData === undefined) {
        return new Array<Component>();
      } else {
        oldData = oldData.filter((component: Component) => component.id !== componentToDelete);
        return oldData;
      }
    });
    setComponentToDelete(undefined);
  }

  const buttonsMenu: ButtonMenuProps[] = [
    {
      name: 'Supprimer ce composant',
      textColor: 'black',
      isEnabled: true,
      onClick: (event: any) => {
        event.stopPropagation();
        setComponentToDelete(rightClickedComponent);
      },
    },
  ];

  /* --------------------------------------------------- Functions -------------------------------------------------- */

  function handleClickEmptyState(): void {
    setIsModalOpened(true);
  }

  function handleClickOpenModalCreateComponent(): void {
    setIsCreateComponentLoading(false);
    setIsModalOpened(true);
  }

  /* ------------------------------------------------------ JSX ----------------------------------------------------- */

  return (
    <BasePage>
      <DeleteComponent
        setComponentId={setComponentToDelete}
        componentId={componentToDelete}
        behaviourAfterDeletion={componentDeletionOnSuccess}
      />
      <Modal title="Définissez les paramètres de votre composant" isOpen={isModalOpened} setIsOpen={setIsModalOpened}>
        <CreateOrEditComponentForm onSubmit={handleComponentFormSubmission} isLoading={isCreateComponentLoading} />
      </Modal>
      <Section isFullHeight gap="10">
        <div className={concatClassNames('flex flex-row justify-between')}>
          <div className={concatClassNames('flex flex-row justify-between gap-4')}>
            <Thumbnail thumbnailIcon="component" thumbnailTooltipMessage={tooltipDescriptionsStyle.component} />
            <div className={concatClassNames('flex flex-col')}>
              <Title content={'Liste des composants applicatifs'} />
              <SubTitle content={"Retrouvez ici l'ensemble de vos composants applicatifs"} />
            </div>
          </div>
          <div className="flex flex-row justify-end items-center gap-6 px-10">
            <button
              onClick={() => {
                setIsCardView(false);
              }}
            >
              {isCardView ? <>{getIcon('list', 'gray-100', 'lg')}</> : <>{getIcon('list', 'gray-500', 'lg')}</>}
            </button>
            <button
              onClick={() => {
                setIsCardView(true);
              }}
            >
              {isCardView ? <>{getIcon('cards', 'gray-500', 'lg')}</> : <>{getIcon('cards', 'gray-100', 'lg')}</>}
            </button>
            <Button
              content="Nouveau composant applicatif"
              iconName="plus"
              width="fit"
              height="sm"
              onClick={handleClickOpenModalCreateComponent}
            />
          </div>
        </div>
        <>
          {status === 'error' && (
            <NetworkError message="Un problème est survenu lors de l'affichage de vos composants." />
          )}
          {status === 'loading' && (
            <CardContainer>
              <ComponentCard loading />
              <ComponentCard loading />
            </CardContainer>
          )}
          {status === 'success' && data.length === 0 && (
            <Empty icon="component" title="Créez votre premier composant!">
              <Button
                content="Nouveau composant applicatif"
                onClick={handleClickEmptyState}
                iconName="plus"
                height="sm"
                width="fit"
              />
            </Empty>
          )}
          {status === 'success' && data.length !== 0 && isCardView && (
            <CardContainer cardSize="sm">
              {data.map((component) => {
                return (
                  <ComponentCard
                    key={component.id}
                    id={component.id}
                    name={component.name}
                    status={component.status}
                    type={component.componentType}
                    setPoints={setPoints}
                    setRightClickedComponent={setRightClickedComponent}
                  />
                );
              })}
            </CardContainer>
          )}
          {status === 'success' && data.length !== 0 && !isCardView && (
            <Table>
              <TableBody overflow="hidden">
                {data.map((component) => {
                  return (
                    <ComponentRow
                      key={component.id}
                      id={component.id}
                      name={component.name}
                      status={component.status}
                      type={component.componentType}
                      pages={component.pages.pages}
                      resources={component.resources.resources}
                    />
                  );
                })}
              </TableBody>
            </Table>
          )}
        </>
      </Section>
      <>
        {rightClickedComponent !== undefined && (
          <ContextMenu x={points?.x - 50} y={points?.y - 25} buttonsMenu={buttonsMenu} />
        )}
      </>
    </BasePage>
  );
}
