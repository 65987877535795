/* tslint:disable */
/* eslint-disable */
/**
 * Specks API
 * APIs for specks SaaS
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CompanyIdDto } from './CompanyIdDto';
import {
    CompanyIdDtoFromJSON,
    CompanyIdDtoFromJSONTyped,
    CompanyIdDtoToJSON,
} from './CompanyIdDto';
import type { DictionaryIdDto } from './DictionaryIdDto';
import {
    DictionaryIdDtoFromJSON,
    DictionaryIdDtoFromJSONTyped,
    DictionaryIdDtoToJSON,
} from './DictionaryIdDto';
import type { ExternalLinkDto } from './ExternalLinkDto';
import {
    ExternalLinkDtoFromJSON,
    ExternalLinkDtoFromJSONTyped,
    ExternalLinkDtoToJSON,
} from './ExternalLinkDto';
import type { FaqIdDto } from './FaqIdDto';
import {
    FaqIdDtoFromJSON,
    FaqIdDtoFromJSONTyped,
    FaqIdDtoToJSON,
} from './FaqIdDto';
import type { SpecificationIdDto } from './SpecificationIdDto';
import {
    SpecificationIdDtoFromJSON,
    SpecificationIdDtoFromJSONTyped,
    SpecificationIdDtoToJSON,
} from './SpecificationIdDto';

/**
 * 
 * @export
 * @interface GetComponentDto
 */
export interface GetComponentDto {
    /**
     * 
     * @type {string}
     * @memberof GetComponentDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof GetComponentDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof GetComponentDto
     */
    status: GetComponentDtoStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof GetComponentDto
     */
    componentType: GetComponentDtoComponentTypeEnum;
    /**
     * 
     * @type {boolean}
     * @memberof GetComponentDto
     */
    internal: boolean;
    /**
     * 
     * @type {Array<ExternalLinkDto>}
     * @memberof GetComponentDto
     */
    externalLinks: Array<ExternalLinkDto>;
    /**
     * 
     * @type {DictionaryIdDto}
     * @memberof GetComponentDto
     */
    dictionary: DictionaryIdDto;
    /**
     * 
     * @type {FaqIdDto}
     * @memberof GetComponentDto
     */
    faq: FaqIdDto;
    /**
     * 
     * @type {SpecificationIdDto}
     * @memberof GetComponentDto
     */
    specification: SpecificationIdDto;
    /**
     * 
     * @type {CompanyIdDto}
     * @memberof GetComponentDto
     */
    company: CompanyIdDto;
}


/**
 * @export
 */
export const GetComponentDtoStatusEnum = {
    Production: 'production',
    Planed: 'planed',
    InDevelopment: 'inDevelopment',
    InDecommissionning: 'inDecommissionning',
    Decommissioned: 'decommissioned',
    InExploration: 'inExploration',
    Evolving: 'evolving'
} as const;
export type GetComponentDtoStatusEnum = typeof GetComponentDtoStatusEnum[keyof typeof GetComponentDtoStatusEnum];

/**
 * @export
 */
export const GetComponentDtoComponentTypeEnum = {
    Front: 'front',
    Batch: 'batch',
    Api: 'api',
    Database: 'database'
} as const;
export type GetComponentDtoComponentTypeEnum = typeof GetComponentDtoComponentTypeEnum[keyof typeof GetComponentDtoComponentTypeEnum];


/**
 * Check if a given object implements the GetComponentDto interface.
 */
export function instanceOfGetComponentDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "status" in value;
    isInstance = isInstance && "componentType" in value;
    isInstance = isInstance && "internal" in value;
    isInstance = isInstance && "externalLinks" in value;
    isInstance = isInstance && "dictionary" in value;
    isInstance = isInstance && "faq" in value;
    isInstance = isInstance && "specification" in value;
    isInstance = isInstance && "company" in value;

    return isInstance;
}

export function GetComponentDtoFromJSON(json: any): GetComponentDto {
    return GetComponentDtoFromJSONTyped(json, false);
}

export function GetComponentDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetComponentDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'status': json['status'],
        'componentType': json['componentType'],
        'internal': json['internal'],
        'externalLinks': ((json['externalLinks'] as Array<any>).map(ExternalLinkDtoFromJSON)),
        'dictionary': DictionaryIdDtoFromJSON(json['dictionary']),
        'faq': FaqIdDtoFromJSON(json['faq']),
        'specification': SpecificationIdDtoFromJSON(json['specification']),
        'company': CompanyIdDtoFromJSON(json['company']),
    };
}

export function GetComponentDtoToJSON(value?: GetComponentDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'status': value.status,
        'componentType': value.componentType,
        'internal': value.internal,
        'externalLinks': ((value.externalLinks as Array<any>).map(ExternalLinkDtoToJSON)),
        'dictionary': DictionaryIdDtoToJSON(value.dictionary),
        'faq': FaqIdDtoToJSON(value.faq),
        'specification': SpecificationIdDtoToJSON(value.specification),
        'company': CompanyIdDtoToJSON(value.company),
    };
}

