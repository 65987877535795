import { ExternalLinksApi } from 'api';

import { type ExternalLink } from 'models/externalLink.entity';

export class ExternalLinKService {
  private readonly externalLinksClient: ExternalLinksApi;

  constructor() {
    this.externalLinksClient = new ExternalLinksApi();
  }

  public async update(id: string, externalLink: ExternalLink, accessToken: string): Promise<ExternalLink> {
    return await this.externalLinksClient.externalLinksControllerUpdate(
      {
        id,
        updateExternalLinkDto: {
          name: externalLink.name,
          url: externalLink.url,
          img: externalLink.img,
        },
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
      },
    );
  }

  public async deleteExternalLink(id: string, accessToken: string): Promise<void> {
    await this.externalLinksClient.externalLinksControllerDelete(
      { id },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
      },
    );
  }
}
