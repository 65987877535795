/* tslint:disable */
/* eslint-disable */
/**
 * Specks API
 * APIs for specks SaaS
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ApiResponseBody } from './ApiResponseBody';
import {
    ApiResponseBodyFromJSON,
    ApiResponseBodyFromJSONTyped,
    ApiResponseBodyToJSON,
} from './ApiResponseBody';
import type { MethodRevision } from './MethodRevision';
import {
    MethodRevisionFromJSON,
    MethodRevisionFromJSONTyped,
    MethodRevisionToJSON,
} from './MethodRevision';

/**
 * 
 * @export
 * @interface ModelApiResponse
 */
export interface ModelApiResponse {
    /**
     * 
     * @type {string}
     * @memberof ModelApiResponse
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof ModelApiResponse
     */
    fonctionalId: string;
    /**
     * 
     * @type {string}
     * @memberof ModelApiResponse
     */
    responseType: ModelApiResponseResponseTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof ModelApiResponse
     */
    statusCode: number;
    /**
     * 
     * @type {string}
     * @memberof ModelApiResponse
     */
    message: string;
    /**
     * 
     * @type {ApiResponseBody}
     * @memberof ModelApiResponse
     */
    apiResponseBody: ApiResponseBody;
    /**
     * 
     * @type {MethodRevision}
     * @memberof ModelApiResponse
     */
    methodRevision: MethodRevision;
}


/**
 * @export
 */
export const ModelApiResponseResponseTypeEnum = {
    Success: 'SUCCESS',
    Error: 'ERROR'
} as const;
export type ModelApiResponseResponseTypeEnum = typeof ModelApiResponseResponseTypeEnum[keyof typeof ModelApiResponseResponseTypeEnum];


/**
 * Check if a given object implements the ModelApiResponse interface.
 */
export function instanceOfModelApiResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "fonctionalId" in value;
    isInstance = isInstance && "responseType" in value;
    isInstance = isInstance && "statusCode" in value;
    isInstance = isInstance && "message" in value;
    isInstance = isInstance && "apiResponseBody" in value;
    isInstance = isInstance && "methodRevision" in value;

    return isInstance;
}

export function ModelApiResponseFromJSON(json: any): ModelApiResponse {
    return ModelApiResponseFromJSONTyped(json, false);
}

export function ModelApiResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ModelApiResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'fonctionalId': json['fonctionalId'],
        'responseType': json['responseType'],
        'statusCode': json['statusCode'],
        'message': json['message'],
        'apiResponseBody': ApiResponseBodyFromJSON(json['apiResponseBody']),
        'methodRevision': MethodRevisionFromJSON(json['methodRevision']),
    };
}

export function ModelApiResponseToJSON(value?: ModelApiResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'fonctionalId': value.fonctionalId,
        'responseType': value.responseType,
        'statusCode': value.statusCode,
        'message': value.message,
        'apiResponseBody': ApiResponseBodyToJSON(value.apiResponseBody),
        'methodRevision': MethodRevisionToJSON(value.methodRevision),
    };
}

