import React from 'react';

import concatClassNames from 'utils/classNames';

import { type ChipPaddingHorizontal, type ChipProps, paddingHorizontalStyle } from '../Chip/Chip';
import { Text, textSizeStyle } from '../Text/Text';

export function ExternalApiBadge({
  paddingHorizontal = 'sm',
  textSize = 'xxs',
  label = 'API EXTERNE',
  borderColor,
}: ChipProps): JSX.Element {
  return (
    <div
      className={concatClassNames(
        'flex',
        'bg-white',
        'rounded-md',
        'items-center',
        borderColor !== undefined ? 'border-1 border-gray-50 p-1' : '',
      )}
    >
      <Text content={label} color="gray-400" position="center" size={textSize} />
    </div>
  );
}
