import React from 'react';

import { Chip } from 'components/core/Chip/Chip';

import { type IComponent } from 'models/ComponentTypes.type';

export function DatabaseTypeChip({
  bgColor = 'transparent',
  borderColor: border = 'none',
  textColor = 'yellow',
  label = 'Base de données',
  tooltip = true,
  tooltipMessage = 'Une base de données est un système de stockage de données qui permet de stocker, d’organiser des données de manière structurée.',
}: IComponent): JSX.Element {
  return (
    <Chip
      icon="database"
      iconColor="yellow"
      textColor={textColor}
      iconSize={'smd'}
      label={label}
      bgColor={bgColor}
      borderColor={border}
      textSize="sm"
      paddingTop="md"
      paddingBottom="md"
      paddingHorizontal="lg"
      tooltipMessage={tooltip ? tooltipMessage : undefined}
    />
  );
}
