import { UiComponentTypeEnum } from 'api';

import React, { useContext } from 'react';

import { type UniqueIdentifier } from '@dnd-kit/core';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Tooltip } from '@mui/material';

import { PageContext, type PageHandler, type SortedUIC } from 'pages/Component/FrontEnd/Page/ComponentFrontEndPagePage';

import { Card } from 'components/core/Card/Card';
import { type CardHorizontalMargin } from 'components/core/Card/Card.props';
import { CardBody } from 'components/core/Card/CardBody';
import { IconBadge } from 'components/core/IconBadge/IconBadge';
import { MasterZone, UICCanva } from 'components/core/PageCanvaFrame/PageCanvaFrame';
import { Text } from 'components/core/Text/Text';

import concatClassNames from 'utils/classNames';
import getIcon from 'utils/getIcon';

import { type IconName } from 'icons/Icon.props';

import { PelletInformation } from '../Pellet/Pellet';
import { type UiComponentCardType, uiComponentCardType } from './UiComponentCard.props';

type UiComponentCardFormat = 'panel' | 'canva';

type UiComponentCardProps =
  | {
      id?: string;
      name?: string;
      index?: number;
      format?: UiComponentCardFormat;
      type: UiComponentCardType;
      uiComponentId?: string;
      isSelected?: boolean;
      selectedUicId?: string;
      isDisabled?: boolean;
      horizontalMargin?: CardHorizontalMargin;
      isDraggable?: boolean;
      loading?: false;
      overUicId?: UniqueIdentifier | null;
      activeDraggedUicId?: UniqueIdentifier | null;
      children?: SortedUIC[];
      onClick?: (componentId: string) => void;
      isDroppable?: boolean;
      isComplete?: boolean;
      isDragged?: boolean;
      size?: 'xs' | 'sm';
    }
  | {
      id?: never;
      name?: never;
      index?: never;
      format?: never;
      type?: never;
      uiComponentId?: never;
      isSelected?: never;
      selectedUicId?: never;
      isDisabled?: never;
      horizontalMargin?: never;
      isDraggable?: never;
      loading: true;
      overUicId?: never;
      onClick?: never;
      activeDraggedUicId?: never;
      children?: never;
      isDroppable?: never;
      isComplete?: never;
      isDragged?: never;
      size?: never;
    };

export function UiComponentCard({
  id = 'toto UiComponentCard',
  format = 'panel',
  type = 'button',
  name = '',
  index = 0,
  loading = false,
  uiComponentId,
  isSelected = false,
  selectedUicId,
  isDisabled = false,
  horizontalMargin = 'auto',
  isDraggable = true,
  overUicId = null,
  activeDraggedUicId = null,
  children = [],
  onClick,
  isDroppable = true,
  isComplete = true,
  isDragged = false,
  size = 'sm',
}: UiComponentCardProps): JSX.Element {
  const icon: IconName = uiComponentCardType[type].icon;
  const panelLabel: string = uiComponentCardType[type].panelLabel;
  const canvaLabel: string = uiComponentCardType[type].canvaLabel;

  const { isEditable }: PageHandler = useContext(PageContext);

  const { attributes, listeners, setDraggableNodeRef, transform, transition, isDragging } = useSortable({
    id: uiComponentId !== undefined ? uiComponentId : id,
    disabled: isDisabled || uiComponentId === 'new',
    data: {
      type,
      name,
      index,
    },
    // transition: {
    //   duration: 500,
    //   easing: 'cubic-bezier(0.25, 1, 0.5, 1)',
    // },
  });

  function handleClick(): void {
    if (onClick != null) onClick(uiComponentId ?? '');
  }

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  function isContainer(): boolean {
    if (type === UiComponentTypeEnum.Container || type === UiComponentTypeEnum.List) {
      return true;
    }
    return false;
  }

  return (
    <Tooltip
      title={
        isDisabled
          ? 'Coming Soon'
          : isSelected && !isDragged && isEditable
          ? "Sauvegarder les modifications avant de déplacer ce composant d'interface"
          : ''
      }
      arrow
      followCursor
    >
      <div
        // id={`uiComponent-${uiComponentId ?? ''}`}
        ref={['panel', 'canva'].includes(format) && isDraggable && isEditable ? setDraggableNodeRef : null}
        {...listeners}
        {...attributes}
        style={{ ...style }}
        className={concatClassNames('flex justify-center', size === 'xs' ? 'w-fit' : '')}
      >
        <Card
          loading={loading}
          shadow="md"
          round="md"
          width={format === 'canva' && isContainer() ? 'fit' : size}
          height={format === 'canva' && isContainer() ? 'fit' : size}
          borderColor={format === 'panel' ? 'lightGray' : 'gradient2'}
          background={isSelected ? 'purple' : 'white'}
          onClick={format === 'canva' ? handleClick : undefined}
          horizontalMargin={horizontalMargin}
          cursor={
            isDisabled || !isDraggable ? 'not-allowed' : isEditable ? (isSelected ? 'default' : 'grab') : 'pointer'
          }
        >
          <CardBody
            height="sm"
            margin="none"
            items={format === 'panel' ? 'center' : 'start'}
            horizontalPadding="sm"
            gap="sm"
          >
            {loading ? (
              <>
                <IconBadge loading />
                <Text loading />
              </>
            ) : (
              <>
                {format === 'panel' && (
                  <>
                    <div className="">
                      {getIcon(icon, isDisabled ? 'gray-100' : 'purple', size === 'xs' ? 'md' : 'xl')}
                    </div>
                    <Text
                      content={panelLabel}
                      color={isDisabled ? 'gray-100' : 'black'}
                      size={size}
                      whitespace="pre-wrap"
                      position="center"
                    />
                    {isDraggable && !isDisabled && (
                      <div className={concatClassNames('absolute', 'right-1', 'top-2')}>
                        {getIcon('gripDotsVertical', 'purple', 'sm')}
                      </div>
                    )}
                  </>
                )}
                {format === 'canva' && (
                  <>
                    <div className={isContainer() ? 'flex flex-row gap-4' : ''}>
                      {/* <p className={concatClassNames('text-xs')}>{uiComponentId}</p> */}
                      <div>
                        {!isContainer() && (
                          <div className="absolute top-0 right-0 p-1">{getIcon(icon, 'purple', 'lg')}</div>
                        )}
                        {uiComponentId !== 'new' && (!isComplete || (isContainer() && children.length === 0)) && (
                          <div className="absolute top-0 left-0 p-1">
                            <PelletInformation
                              text={`${!isComplete ? "Ce composant d'interface semble incomplet" : ''}${
                                !isComplete && isContainer() && children.length === 0 ? ' et ' : ''
                              }${isContainer() && children.length === 0 ? 'Ce conteneur est vide' : ''}`}
                            />
                          </div>
                        )}
                        <div className="flex flex-col mt-6">
                          <Text content={canvaLabel} color="black" size="sm" whitespace="pre-wrap" position="left" />
                          <Text
                            content={name}
                            color="black"
                            weight="bold"
                            size="sm"
                            whitespace="pre-wrap"
                            position="left"
                          />
                        </div>
                        {isContainer() && <div className="p-1">{getIcon(icon, 'purple', 'lg')}</div>}
                      </div>
                      {isContainer() && (
                        <>
                          <UICCanva>
                            <MasterZone
                              uics={children}
                              activeDraggedUicId={activeDraggedUicId}
                              overUicId={overUicId}
                              parentId={uiComponentId}
                              parentType={type}
                              selectedUiComponentId={selectedUicId}
                              handleClickUiComponentCard={onClick}
                              isDroppable={isDroppable && !isDragging && !(type === 'list' && children.length > 0)}
                              isDragged={isDragged}
                            />
                          </UICCanva>
                        </>
                      )}
                    </div>
                  </>
                )}
              </>
            )}
          </CardBody>
        </Card>
      </div>
    </Tooltip>
  );
}
