import { type Component } from './component.entity';

export class Node {
  id: string;

  frontId?: string;

  positionX: number;

  positionY: number;

  component: Component;
}
