import * as Yup from 'yup';

import React, { useEffect, useState } from 'react';
import { Controller, type SubmitHandler, useForm } from 'react-hook-form';
import uuid from 'react-uuid';

import { yupResolver } from '@hookform/resolvers/yup';
import { Tooltip } from '@mui/material';

import { ApiEnumTypeForm } from 'forms/ApiEnumTypeForm/ApiEnumTypeForm';

import { Button } from 'components/core/Button/Button';
import Input from 'components/core/Input/Input';
import { ListBox } from 'components/core/ListBox/ListBox';
import { Modal } from 'components/core/Modal/Modal';
import { Text } from 'components/core/Text/Text';
import { type ResourceHandler, useResourceHandler } from 'components/specks/Widgets/ApiWidget';

import { type Body } from 'models/API/Body.entity';
import { type Enum } from 'models/API/Enum.entity';

import concatClassNames from 'utils/classNames';
import getIcon from 'utils/getIcon';

/* ------------------------------------------------ isRequired Form ----------------------------------------------- */

enum IsRequiredEnum {
  YES = 'Obligatoire',
  NO = 'Facultatif',
}

const isRequiredOptions: string[] = [IsRequiredEnum.YES, IsRequiredEnum.NO];

const isRequiredStyleOptions: Map<string, JSX.Element> = new Map<string, JSX.Element>([
  [IsRequiredEnum.YES, <Text key={uuid()} content="Obligatoire" size="api" position="center" />],
  [IsRequiredEnum.NO, <Text key={uuid()} content="Facultatif" size="api" position="center" />],
]);

const isRequiredSelectedStyleOptions: Map<string, JSX.Element> = new Map<string, JSX.Element>([
  [
    IsRequiredEnum.YES,
    <Text
      key={uuid()}
      content="Obligatoire"
      color="purple-500"
      size="api"
      textDecoration="underline-4"
      position="center"
    />,
  ],
  [
    IsRequiredEnum.NO,
    <Text
      key={uuid()}
      content="Facultatif"
      color="purple-500"
      size="api"
      textDecoration="underline-4"
      position="center"
    />,
  ],
]);

/* ------------------------------------------------ boolean Form ----------------------------------------------- */

export type BooelanEnum = 'yes' | 'no' | 'null';

const booleanName: Record<BooelanEnum, string> = {
  yes: 'Vrai',
  no: 'Faux',
  null: 'Non renseigné',
};

const booleanOptions: string[] = [booleanName.yes, booleanName.no, booleanName.null];

const booleanStyleOptions: Map<string, JSX.Element> = new Map<string, JSX.Element>([
  [booleanName.yes, <Text key={uuid()} content="Vrai" size="api" position="left" />],
  [booleanName.no, <Text key={uuid()} content="Faux" size="api" position="left" />],
  [booleanName.null, <Text key={uuid()} content="Non renseigné" size="api" position="left" />],
]);

const booleanSelectedStyleOptions: Map<string, JSX.Element> = new Map<string, JSX.Element>([
  [
    booleanName.yes,
    <Text key={uuid()} content="Vrai" color="purple-500" size="api" textDecoration="underline-4" position="left" />,
  ],
  [
    booleanName.no,
    <Text key={uuid()} content="Faux" color="purple-500" size="api" textDecoration="underline-4" position="left" />,
  ],
  [
    booleanName.null,
    <Text
      key={uuid()}
      content="Non renseigné"
      color="purple-500"
      size="api"
      textDecoration="underline-4"
      position="left"
    />,
  ],
]);

/* --------------------------------------------------- type Form -------------------------------------------------- */

export type InputTypeBody = 'STRING' | 'NUMBER' | 'BOOLEAN' | 'OBJECT' | 'ENUM' | 'LIST' | 'DATE';

export const inputTypeOptions: Map<InputTypeBody, string> = new Map<InputTypeBody, string>([
  ['STRING', 'Chaîne de caractères'],
  ['NUMBER', 'Nombre'],
  ['BOOLEAN', 'Booléen'],
  ['OBJECT', 'Objet'],
  ['ENUM', 'Options'],
  ['LIST', "Liste d'éléments"],
  ['DATE', 'Date'],
]);

function getInputTypeOptions(key: InputTypeBody): string {
  return inputTypeOptions.get(key) ?? '';
}

const typeOptions: InputTypeBody[] = ['STRING', 'NUMBER', 'BOOLEAN', 'OBJECT', 'ENUM', 'LIST', 'DATE'];

export const typeStyleOptions: Map<string, JSX.Element> = new Map<string, JSX.Element>([
  ['STRING', <Text key={uuid()} content={getInputTypeOptions('STRING')} position="left" size="api" />],
  ['NUMBER', <Text key={uuid()} content={getInputTypeOptions('NUMBER')} position="left" size="api" />],
  ['BOOLEAN', <Text key={uuid()} content={getInputTypeOptions('BOOLEAN')} position="left" size="api" />],
  ['OBJECT', <Text key={uuid()} content={getInputTypeOptions('OBJECT')} position="left" size="api" />],
  ['ENUM', <Text key={uuid()} content={getInputTypeOptions('ENUM')} position="left" size="api" />],
  ['LIST', <Text key={uuid()} content={getInputTypeOptions('LIST')} position="left" size="api" />],
  ['DATE', <Text key={uuid()} content={getInputTypeOptions('DATE')} position="left" size="api" />],
]);

const typeSelectedStyleOptions: Map<string, JSX.Element> = new Map<string, JSX.Element>([
  [
    'STRING',
    <Text
      key={uuid()}
      content={getInputTypeOptions('STRING')}
      color="purple-500"
      size="api"
      textDecoration="underline-4"
      position="left"
    />,
  ],
  [
    'NUMBER',
    <Text
      key={uuid()}
      content={getInputTypeOptions('NUMBER')}
      color="purple-500"
      size="api"
      textDecoration="underline-4"
      position="left"
    />,
  ],
  [
    'BOOLEAN',
    <Text
      key={uuid()}
      content={getInputTypeOptions('BOOLEAN')}
      color="purple-500"
      size="api"
      textDecoration="underline-4"
      position="left"
    />,
  ],
  [
    'OBJECT',
    <Text
      key={uuid()}
      content={getInputTypeOptions('OBJECT')}
      color="purple-500"
      size="api"
      textDecoration="underline-4"
      position="left"
    />,
  ],
  [
    'ENUM',
    <Text
      key={uuid()}
      content={getInputTypeOptions('ENUM')}
      color="purple-500"
      size="api"
      textDecoration="underline-4"
      position="left"
    />,
  ],
  [
    'LIST',
    <Text
      key={uuid()}
      content={getInputTypeOptions('LIST')}
      color="purple-500"
      size="api"
      textDecoration="underline-4"
      position="left"
    />,
  ],
  [
    'DATE',
    <Text
      key={uuid()}
      content={getInputTypeOptions('DATE')}
      color="purple-500"
      size="api"
      textDecoration="underline-4"
      position="left"
    />,
  ],
]);

/* ----------------------------------------------------- Form ----------------------------------------------------- */

interface ApiBodyParameterFormModel {
  name: string;
  isRequired: IsRequiredEnum | undefined;
  type: InputTypeBody;
  defaultValueString: string | undefined;
  defaultValueNumber: string | undefined;
  defaultValueBoolean: string | undefined;
  defaultValueDate: string | undefined;
}

const schema: Yup.ObjectSchema<ApiBodyParameterFormModel> = Yup.object().shape({
  name: Yup.string().required('Champ obligatoire').max(40, 'Le nom doit contenir au maximum 40 caractères'),
  isRequired: Yup.mixed<IsRequiredEnum>(),
  type: Yup.mixed<InputTypeBody>().required('Champ obligatoire'),
  defaultValueString: Yup.string(),
  defaultValueNumber: Yup.string(),
  defaultValueBoolean: Yup.mixed<string>(),
  defaultValueDate: Yup.mixed<string>(),
});

/* ----------------------------------------------------- Props ---------------------------------------------------- */

interface ApiBodyFormProps {
  onSubmit: (row: Body) => void;
  onCancel?: () => void;
  hasShadow?: boolean;
  existingBodyParameters: Body[];
  oldBodyParameter?: Body;
}

export function ApiBodyForm({
  onSubmit,
  onCancel,
  hasShadow = true,
  existingBodyParameters,
  oldBodyParameter,
}: ApiBodyFormProps): JSX.Element {
  /* --------------------------------------------------- contexts --------------------------------------------------- */

  const resourceHandler: ResourceHandler = useResourceHandler();

  /* ---------------------------------------------------- states ---------------------------------------------------- */

  const [isApiListTypeFormOpened, setIsApiListTypeFormOpened] = useState<boolean>(false);
  const [enumValues, setEnumValues] = useState<Enum>();

  /* --------------------------------------------------- variables -------------------------------------------------- */

  const gridTemplateColumns: string = 'grid-cols-[repeat(17,minmax(0,1fr))]';
  const gridTitleSpan: string = 'col-span-6';
  const gridTypeSpan: string = 'col-span-3';
  const gridMandatorySpan: string = 'col-span-3';
  const gridDefaultSpan: string = 'col-span-4';

  const mutateStatus: string = resourceHandler.details.mutateStatus;

  /* ----------------------------------------------------- form ----------------------------------------------------- */

  const {
    register,
    unregister,
    control,
    watch,
    setValue,
    setError,
    reset,
    handleSubmit,
    formState: { errors, isSubmitted },
  } = useForm<ApiBodyParameterFormModel>({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (mutateStatus === 'success') {
      reset();
    }
  }, [mutateStatus, reset]);

  const onSubmitForm: SubmitHandler<ApiBodyParameterFormModel> = (data) => {
    // check if name is unique
    const isNameUnique: boolean = existingBodyParameters.every((bodyParameter: Body) => {
      if (bodyParameter.id === oldBodyParameter?.id) return true;
      return bodyParameter.name !== data.name;
    });
    if (!isNameUnique) {
      setError('name', { type: 'custom', message: 'Il existe déjà un attribut avec ce nom' });
      return;
    }
    const bodyToSubmit: Body = {
      id: oldBodyParameter !== undefined ? oldBodyParameter.id : uuid(),
      name: data.name,
      isMandatory:
        data.isRequired === IsRequiredEnum.YES ? 'true' : data.isRequired === IsRequiredEnum.NO ? 'false' : 'null',
      valueType: data.type,
      defaultValue:
        data.defaultValueString ??
        data.defaultValueNumber?.toString() ??
        data.defaultValueBoolean ??
        data.defaultValueDate ??
        '',
      enum: enumValues,
      children: oldBodyParameter?.children.length === 0 ? [] : oldBodyParameter?.children ?? [],
    };
    resourceHandler.details.setBodyData(bodyToSubmit);
    onSubmit(bodyToSubmit);
    setEnumValues(undefined);
  };

  function onCancelForm(e: any): void {
    e.preventDefault();
    onCancel?.();
  }

  const watchName: string = watch('name');
  let isFormValid: boolean = true;
  if (watchName === undefined || watchName.length < 1) {
    isFormValid = false;
  }

  useEffect(() => {
    if (oldBodyParameter === undefined) return;
    setValue('name', oldBodyParameter.name);
    setValue('type', oldBodyParameter.valueType);
    setValue('isRequired', oldBodyParameter.isMandatory === 'true' ? IsRequiredEnum.YES : IsRequiredEnum.NO);
    if (oldBodyParameter.valueType === 'STRING') {
      setValue('defaultValueString', oldBodyParameter.defaultValue);
    }
    if (oldBodyParameter.valueType === 'NUMBER') {
      setValue('defaultValueNumber', oldBodyParameter.defaultValue);
    }
    if (oldBodyParameter.valueType === 'BOOLEAN') {
      setValue('defaultValueBoolean', oldBodyParameter.defaultValue);
    }
    if (oldBodyParameter.valueType === 'DATE') {
      setValue('defaultValueDate', oldBodyParameter.defaultValue);
    }
    if (oldBodyParameter.valueType === 'ENUM') {
      setEnumValues(oldBodyParameter.enum);
    }
  }, [oldBodyParameter, setValue]);

  const watchType: InputTypeBody = watch('type');

  useEffect(() => {
    if (oldBodyParameter !== undefined && oldBodyParameter.valueType === 'STRING') {
      setValue('defaultValueString', oldBodyParameter.defaultValue);
    }
    if (oldBodyParameter !== undefined && oldBodyParameter.valueType === 'NUMBER') {
      setValue('defaultValueNumber', oldBodyParameter.defaultValue);
    }
    if (oldBodyParameter !== undefined && oldBodyParameter.valueType === 'BOOLEAN') {
      setValue('defaultValueBoolean', oldBodyParameter.defaultValue);
    }
    if (oldBodyParameter !== undefined && oldBodyParameter.valueType === 'DATE') {
      setValue('defaultValueDate', oldBodyParameter.defaultValue);
    }
    if (watchType !== 'STRING') {
      unregister('defaultValueString');
    }
    if (watchType !== 'NUMBER') {
      unregister('defaultValueNumber');
    }
    if (watchType !== 'BOOLEAN') {
      unregister('defaultValueBoolean');
    }
    if (watchType !== 'DATE') {
      unregister('defaultValueDate');
    }
    if (watchType === 'OBJECT' || watchType === 'ENUM') {
      unregister('isRequired');
    }
  }, [watchType, unregister, oldBodyParameter, setValue]);

  // Used to display modal if type is LIST and select type and values of it
  useEffect(() => {
    if (watchType === 'ENUM') {
      setIsApiListTypeFormOpened(true);
    }
  }, [watchType]);

  function handleApiListTypeFormSubmission(enumEntity: Enum): void {
    enumEntity.values = enumEntity.values.filter((value) => value.name !== '');
    setEnumValues(enumEntity);
    setIsApiListTypeFormOpened(false);
  }

  /* ------------------------------------------------------ JSX ----------------------------------------------------- */

  return (
    <>
      <Modal
        title="Liste des valeurs possibles pour l'attribut"
        isOpen={isApiListTypeFormOpened}
        setIsOpen={setIsApiListTypeFormOpened}
        maxWidth="fit"
      >
        <ApiEnumTypeForm onSubmit={handleApiListTypeFormSubmission} prevValue={enumValues} />
      </Modal>
      <form
        id="api-body-form"
        className={concatClassNames(
          'grid',
          gridTemplateColumns,
          'gap-1',
          'mt-2',
          'gap-y-1.5',
          'border-1 border-gradient2-from',
          'bg-formpurple-5',
          'rounded-md',
          'py-2',
          'px-3',
          hasShadow ? 'shadow-apiRow' : '',
          'align-middle',
          'items-center',
        )}
        onSubmit={handleSubmit(onSubmitForm)}
      >
        <div className={concatClassNames(gridTitleSpan, 'flex items-center')}>
          <Input
            placeholder="Nom du paramètre"
            {...register('name')}
            value={watchName}
            maxLength={40}
            textSize="base"
            bgColor="white"
            borderColor="gray-500"
          />
        </div>
        <div className={concatClassNames(gridTypeSpan, 'self-center')}>
          <Controller
            name="type"
            control={control}
            defaultValue={'STRING'}
            render={({ field: { value, onChange } }) => (
              <ListBox
                selected={value}
                onChange={onChange}
                options={typeOptions}
                styleOptions={typeStyleOptions}
                selectedStyleOptions={typeSelectedStyleOptions}
                minimalist
              />
            )}
          />
        </div>
        {watchType === 'ENUM' && (
          <>
            <Tooltip title="Voir la liste des valeurs" arrow>
              <div
                className={concatClassNames('flex', 'items-center justify-start', 'cursor-pointer', 'w-fit')}
                onClick={() => {
                  setIsApiListTypeFormOpened(true);
                }}
              >
                {getIcon('eye', 'purple', 'md')}
              </div>
            </Tooltip>
            <Text content={`[${enumValues?.values.length ?? 0}]`} position="center" />
          </>
        )}
        <div className={concatClassNames(gridMandatorySpan, 'self-center')}>
          {watchType !== 'OBJECT' && watchType !== 'ENUM' && watchType !== 'LIST' && (
            <Controller
              name="isRequired"
              control={control}
              defaultValue={IsRequiredEnum.NO}
              render={({ field: { value, onChange } }) => (
                <ListBox
                  selected={value ?? IsRequiredEnum.NO}
                  onChange={onChange}
                  options={isRequiredOptions}
                  styleOptions={isRequiredStyleOptions}
                  selectedStyleOptions={isRequiredSelectedStyleOptions}
                  minimalist
                />
              )}
            />
          )}
        </div>
        <div className={concatClassNames(gridDefaultSpan, 'flex items-center')}>
          {watchType === 'STRING' && (
            <Input
              placeholder="Valeur par défaut"
              {...register('defaultValueString')}
              textSize="base"
              bgColor="white"
              borderColor="gray-500"
            />
          )}
          {watchType === 'DATE' && (
            <Input
              placeholder="Valeur par défaut"
              {...register('defaultValueDate')}
              textSize="base"
              bgColor="white"
              borderColor="gray-500"
            />
          )}
          {watchType === 'NUMBER' && (
            <Input
              placeholder="Valeur par défaut"
              {...register('defaultValueNumber')}
              isNumber
              textSize="base"
              bgColor="white"
              borderColor="gray-500"
            />
          )}
          {watchType === 'BOOLEAN' && (
            <Controller
              name="defaultValueBoolean"
              control={control}
              defaultValue={booleanName.null}
              render={({ field: { value, onChange } }) => (
                <ListBox
                  width="full"
                  selected={value ?? booleanName.null}
                  onChange={onChange}
                  options={booleanOptions}
                  styleOptions={booleanStyleOptions}
                  selectedStyleOptions={booleanSelectedStyleOptions}
                  minimalist
                />
              )}
            />
          )}
        </div>
        <div id="edit-errors" className="col-start-1 col-span-12">
          {watchName !== undefined && watchName.length === 40 && (
            <Text
              content="Vous avez atteint le nombre maximal de caractères"
              color="red-500"
              position="left"
              size="sm"
            />
          )}
          {errors.name?.message !== undefined && isSubmitted ? (
            <Text content={errors.name.message} color="red-500" position="left" size="sm" />
          ) : undefined}
          {errors.type?.message !== undefined && isSubmitted ? (
            <Text content={errors.type.message} color="red-500" position="left" size="sm" />
          ) : undefined}
          {errors.isRequired?.message !== undefined && isSubmitted ? (
            <Text content={errors.isRequired.message} color="red-500" position="left" size="sm" />
          ) : undefined}
          {errors.defaultValueString?.message !== undefined && isSubmitted ? (
            <Text content={errors.defaultValueString.message} color="red-500" position="left" size="sm" />
          ) : undefined}
          {errors.defaultValueNumber?.message !== undefined && isSubmitted ? (
            <Text content={errors.defaultValueNumber.message} color="red-500" position="left" size="sm" />
          ) : undefined}
          {errors.defaultValueBoolean?.message !== undefined && isSubmitted ? (
            <Text content={errors.defaultValueBoolean.message} color="red-500" position="left" size="sm" />
          ) : undefined}
        </div>
        <div className="col-span-2 col-end-[16]">
          <Button
            type="button"
            content="Annuler"
            bgColor="white"
            textColor="black"
            borderWidth="xs"
            borderColor="grey-500"
            onClick={onCancelForm}
          />
        </div>
        <div className="col-span-2 col-end-[18]">
          {mutateStatus === 'loading' && <Button iconName="spinCircle" type="button" iconAnimation="spin" />}
          {mutateStatus !== 'loading' && (
            <Button
              content="Valider"
              type="submit"
              disabled={!isFormValid || errors.isRequired != null || errors.name != null || errors.type != null}
            />
          )}
        </div>
      </form>
    </>
  );
}
