import { type GetComponentDto } from 'api';

import React from 'react';
import { type NavigateFunction, useNavigate } from 'react-router-dom';

import { Text } from 'components/core/Text/Text';

import { type ComponentType } from 'models/ComponentTypes.type';
import { Status } from 'models/Status.types';

import concatClassNames from 'utils/classNames';
import getComponentSymbol from 'utils/getComponentSymbol';
import getIcon from 'utils/getIcon';
import getStatusChip from 'utils/getStatusChip';

interface ProductRowProps {
  id?: string;
  name?: string;
  status?: Status;
  loading?: boolean;
  components: GetComponentDto[];
}

export function ProductRow({
  id = '',
  name = '',
  status = Status.Indevelopment,
  loading = false,
  components,
}: ProductRowProps): JSX.Element {
  const navigate: NavigateFunction = useNavigate();

  function handleClick(): void {
    if (loading) return;
    if (id === undefined) console.error('id or product is null or undefined');
    else navigate('/product/' + id);
  }

  return (
    <div
      onClick={handleClick}
      className={concatClassNames(
        'bg-[#f7f5f2]',
        'rounded-l-full rounded-tr-sm',
        'flex flex-row',
        'items-center justify-between',
        'hover:translate-x-3 duration-700 ease-in-out',
        'cursor-pointer',
      )}
    >
      <div className="flex flex-row items-center gap-4">
        <div
          className={concatClassNames(
            'flex align-middle justify-center items-center h-16 w-16',
            'bg-gradient-to-r from-gradient1-from to-gradient1-to rounded-full',
          )}
        >
          <div
            className={concatClassNames(
              'flex align-middle justify-center items-center h-14 w-14',
              'bg-white rounded-full',
            )}
          >
            {getIcon('product', 'gray-200', 'lg')}
          </div>
        </div>
        <Text content={name} size="base" weight="bold" />
        <div>{getStatusChip({ name: status, textSize: 'sm' })}</div>
      </div>
      <div className="items-end flex flex-row gap-2 pr-4 ">
        {components.map((component) => {
          return getComponentSymbol({
            type: component.componentType as ComponentType,
            id: component.id,
            tooltipMessage: component.name,
            handleOnClick: (e: any) => {
              e.preventDefault();
              e.stopPropagation();
              navigate(`/component/${component.id}`);
            },
          });
        })}
      </div>
    </div>
  );
}
