import { type Status } from './Status.types';
import { type Asset } from './asset.entity';

export class Feature {
  id: string;

  name: string;

  status: Status;

  assets: Asset[];
}
