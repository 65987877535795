/* tslint:disable */
/* eslint-disable */
/**
 * Specks API
 * APIs for specks SaaS
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Page } from './Page';
import {
    PageFromJSON,
    PageFromJSONTyped,
    PageToJSON,
} from './Page';
import type { UicPosition } from './UicPosition';
import {
    UicPositionFromJSON,
    UicPositionFromJSONTyped,
    UicPositionToJSON,
} from './UicPosition';

/**
 * 
 * @export
 * @interface PageRevision
 */
export interface PageRevision {
    /**
     * 
     * @type {string}
     * @memberof PageRevision
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof PageRevision
     */
    name: string;
    /**
     * 
     * @type {boolean}
     * @memberof PageRevision
     */
    latest: boolean;
    /**
     * 
     * @type {Date}
     * @memberof PageRevision
     */
    createdAt: Date;
    /**
     * 
     * @type {Array<UicPosition>}
     * @memberof PageRevision
     */
    uicPositions: Array<UicPosition>;
    /**
     * 
     * @type {Page}
     * @memberof PageRevision
     */
    page: Page;
}

/**
 * Check if a given object implements the PageRevision interface.
 */
export function instanceOfPageRevision(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "latest" in value;
    isInstance = isInstance && "createdAt" in value;
    isInstance = isInstance && "uicPositions" in value;
    isInstance = isInstance && "page" in value;

    return isInstance;
}

export function PageRevisionFromJSON(json: any): PageRevision {
    return PageRevisionFromJSONTyped(json, false);
}

export function PageRevisionFromJSONTyped(json: any, ignoreDiscriminator: boolean): PageRevision {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'latest': json['latest'],
        'createdAt': (new Date(json['createdAt'])),
        'uicPositions': ((json['uicPositions'] as Array<any>).map(UicPositionFromJSON)),
        'page': PageFromJSON(json['page']),
    };
}

export function PageRevisionToJSON(value?: PageRevision | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'latest': value.latest,
        'createdAt': (value.createdAt.toISOString()),
        'uicPositions': ((value.uicPositions as Array<any>).map(UicPositionToJSON)),
        'page': PageToJSON(value.page),
    };
}

