/* tslint:disable */
/* eslint-disable */
/**
 * Specks API
 * APIs for specks SaaS
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ComponentPersonalDto } from './ComponentPersonalDto';
import {
    ComponentPersonalDtoFromJSON,
    ComponentPersonalDtoFromJSONTyped,
    ComponentPersonalDtoToJSON,
} from './ComponentPersonalDto';

/**
 * 
 * @export
 * @interface GetNodeDto
 */
export interface GetNodeDto {
    /**
     * 
     * @type {string}
     * @memberof GetNodeDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof GetNodeDto
     */
    reactFlowId: string;
    /**
     * 
     * @type {number}
     * @memberof GetNodeDto
     */
    positionX: number;
    /**
     * 
     * @type {number}
     * @memberof GetNodeDto
     */
    positionY: number;
    /**
     * 
     * @type {ComponentPersonalDto}
     * @memberof GetNodeDto
     */
    component: ComponentPersonalDto;
}

/**
 * Check if a given object implements the GetNodeDto interface.
 */
export function instanceOfGetNodeDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "reactFlowId" in value;
    isInstance = isInstance && "positionX" in value;
    isInstance = isInstance && "positionY" in value;
    isInstance = isInstance && "component" in value;

    return isInstance;
}

export function GetNodeDtoFromJSON(json: any): GetNodeDto {
    return GetNodeDtoFromJSONTyped(json, false);
}

export function GetNodeDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetNodeDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'reactFlowId': json['reactFlowId'],
        'positionX': json['positionX'],
        'positionY': json['positionY'],
        'component': ComponentPersonalDtoFromJSON(json['component']),
    };
}

export function GetNodeDtoToJSON(value?: GetNodeDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'reactFlowId': value.reactFlowId,
        'positionX': value.positionX,
        'positionY': value.positionY,
        'component': ComponentPersonalDtoToJSON(value.component),
    };
}

