/* tslint:disable */
/* eslint-disable */
/**
 * Specks API
 * APIs for specks SaaS
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { GetUiComponentDto } from './GetUiComponentDto';
import {
    GetUiComponentDtoFromJSON,
    GetUiComponentDtoFromJSONTyped,
    GetUiComponentDtoToJSON,
} from './GetUiComponentDto';

/**
 * 
 * @export
 * @interface GetPageRevisionDto
 */
export interface GetPageRevisionDto {
    /**
     * 
     * @type {string}
     * @memberof GetPageRevisionDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof GetPageRevisionDto
     */
    name: string;
    /**
     * 
     * @type {boolean}
     * @memberof GetPageRevisionDto
     */
    latest: boolean;
    /**
     * 
     * @type {Array<GetUiComponentDto>}
     * @memberof GetPageRevisionDto
     */
    uiComponents: Array<GetUiComponentDto>;
    /**
     * 
     * @type {Date}
     * @memberof GetPageRevisionDto
     */
    createdAt: Date;
}

/**
 * Check if a given object implements the GetPageRevisionDto interface.
 */
export function instanceOfGetPageRevisionDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "latest" in value;
    isInstance = isInstance && "uiComponents" in value;
    isInstance = isInstance && "createdAt" in value;

    return isInstance;
}

export function GetPageRevisionDtoFromJSON(json: any): GetPageRevisionDto {
    return GetPageRevisionDtoFromJSONTyped(json, false);
}

export function GetPageRevisionDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetPageRevisionDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'latest': json['latest'],
        'uiComponents': ((json['uiComponents'] as Array<any>).map(GetUiComponentDtoFromJSON)),
        'createdAt': (new Date(json['createdAt'])),
    };
}

export function GetPageRevisionDtoToJSON(value?: GetPageRevisionDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'latest': value.latest,
        'uiComponents': ((value.uiComponents as Array<any>).map(GetUiComponentDtoToJSON)),
        'createdAt': (value.createdAt.toISOString()),
    };
}

