/* tslint:disable */
/* eslint-disable */
/**
 * Specks API
 * APIs for specks SaaS
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateComponentDto
 */
export interface CreateComponentDto {
    /**
     * 
     * @type {string}
     * @memberof CreateComponentDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof CreateComponentDto
     */
    status: CreateComponentDtoStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateComponentDto
     */
    componentType: CreateComponentDtoComponentTypeEnum;
    /**
     * 
     * @type {boolean}
     * @memberof CreateComponentDto
     */
    internal: boolean;
}


/**
 * @export
 */
export const CreateComponentDtoStatusEnum = {
    Production: 'production',
    Planed: 'planed',
    InDevelopment: 'inDevelopment',
    InDecommissionning: 'inDecommissionning',
    Decommissioned: 'decommissioned',
    InExploration: 'inExploration',
    Evolving: 'evolving'
} as const;
export type CreateComponentDtoStatusEnum = typeof CreateComponentDtoStatusEnum[keyof typeof CreateComponentDtoStatusEnum];

/**
 * @export
 */
export const CreateComponentDtoComponentTypeEnum = {
    Front: 'front',
    Batch: 'batch',
    Api: 'api',
    Database: 'database'
} as const;
export type CreateComponentDtoComponentTypeEnum = typeof CreateComponentDtoComponentTypeEnum[keyof typeof CreateComponentDtoComponentTypeEnum];


/**
 * Check if a given object implements the CreateComponentDto interface.
 */
export function instanceOfCreateComponentDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "status" in value;
    isInstance = isInstance && "componentType" in value;
    isInstance = isInstance && "internal" in value;

    return isInstance;
}

export function CreateComponentDtoFromJSON(json: any): CreateComponentDto {
    return CreateComponentDtoFromJSONTyped(json, false);
}

export function CreateComponentDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateComponentDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'status': json['status'],
        'componentType': json['componentType'],
        'internal': json['internal'],
    };
}

export function CreateComponentDtoToJSON(value?: CreateComponentDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'status': value.status,
        'componentType': value.componentType,
        'internal': value.internal,
    };
}

