import React, { useState } from 'react';

import { Tooltip } from '@mui/material';

import { inputTypeOptions } from 'forms/ApiBodyForm/ApiBodyForm';
import { ApiEnumTypeForm } from 'forms/ApiEnumTypeForm/ApiEnumTypeForm';

import { Modal } from 'components/core/Modal/Modal';
import { Settings } from 'components/core/Settings/Settings';
import { Text } from 'components/core/Text/Text';

import { type Body } from 'models/API/Body.entity';

import concatClassNames from 'utils/classNames';
import getIcon from 'utils/getIcon';

import { type RestApiHandler, useRestApiHandler } from './Detail';

type ComponentBackEndApiDetailBodyRowProps =
  | {
      id: string;
      body: Body;
      path: string[];
      responseFunctionalId?: string;
      onClickObject: () => void;
      onRowUpdate?: (id: string) => void;
      isEditing?: boolean;
      hasShadow?: boolean;
      onClickAddBodyParameter?: never;
      title?: false;
    }
  | {
      id?: never;
      body?: never;
      path?: never;
      responseFunctionalId?: never;
      onClickObject?: never;
      onRowUpdate?: never;
      isEditing?: never;
      hasShadow?: never;
      onClickAddBodyParameter?: () => void;
      title: true;
    };

export function ComponentBackEndApiDetailBodyRow({
  id = '',
  path = [],
  responseFunctionalId,
  body,
  onClickObject,
  onRowUpdate,
  isEditing = false,
  hasShadow = true,
  onClickAddBodyParameter,
  title = false,
}: ComponentBackEndApiDetailBodyRowProps): JSX.Element {
  /* --------------------------------------------------- contexts --------------------------------------------------- */

  const restApiHandler: RestApiHandler = useRestApiHandler();

  /* --------------------------------------------------- variables -------------------------------------------------- */

  const gridTemplateColumns: string = 'grid-cols-[repeat(17,minmax(0,1fr))]';
  const gridTitleSpan: string = 'col-span-6';
  const gridTypeSpan: string = 'col-span-3';
  const gridMandatorySpan: string = 'col-span-3';
  const gridDefaultSpan: string = 'col-span-4';

  /* ---------------------------------------------------- states ---------------------------------------------------- */

  const [isApiListTypeFormOpened, setIsApiListTypeFormOpened] = useState<boolean>(false);

  /* ------------------------------------------------------ JSX ----------------------------------------------------- */

  return (
    <>
      <Modal
        title="Liste des valeurs possibles pour l'attribut"
        isOpen={isApiListTypeFormOpened}
        setIsOpen={setIsApiListTypeFormOpened}
        maxWidth="fit"
      >
        <ApiEnumTypeForm prevValue={body?.enum} readonly />
      </Modal>
      <div className="w-full">
        {title && (
          <div className={concatClassNames('grid', gridTemplateColumns, 'gap-1', 'px-3')}>
            <div className={gridTitleSpan}>
              <Text content="Nom" position="left" size="sm" color="gray-200" />
            </div>
            <div className={gridTypeSpan}>
              <Text content="Type" position="left" size="sm" color="gray-200" />
            </div>
            <div className={gridMandatorySpan}></div>
            <div className={gridDefaultSpan}>
              <Text content="Valeur par défaut" position="left" size="sm" color="gray-200" />
            </div>
            {onClickAddBodyParameter != null && (
              <div
                className={concatClassNames(
                  'bg-white',
                  'p-0.5',
                  'mb-0.5',
                  'ml-auto',
                  'mr-auto',
                  'rounded-md',
                  'border-1 border-gray-50',
                  'w-fit',
                  'cursor-pointer',
                )}
                onClick={onClickAddBodyParameter}
              >
                {getIcon('plus', 'gray', 'sm')}
              </div>
            )}
          </div>
        )}
        {!title && body !== undefined && (
          <div
            className={concatClassNames(
              'grid',
              'gap-1',
              hasShadow ? 'bg-white rounded-md' : '',
              hasShadow ? 'py-2' : '',
              'px-3',
              hasShadow ? 'shadow-apiRow' : '',
              'items-center',
              gridTemplateColumns,
            )}
          >
            <div
              className={concatClassNames(
                gridTitleSpan,
                body.valueType === 'OBJECT' || body.valueType === 'LIST' ? 'cursor-pointer' : '',
              )}
              onClick={body.valueType === 'OBJECT' || body.valueType === 'LIST' ? onClickObject : undefined}
            >
              <Text
                content={body.name}
                position="left"
                size="sm"
                color={body.valueType === 'OBJECT' || body.valueType === 'LIST' ? 'purple-500' : 'gray-400'}
                textDecoration={body.valueType === 'OBJECT' || body.valueType === 'LIST' ? 'underline' : 'no-underline'}
                textBreak="all"
              />
            </div>
            <div className={concatClassNames(gridTypeSpan)}>
              <Text content={inputTypeOptions.get(body.valueType) ?? ''} position="left" size="sm" color="purple-400" />
            </div>
            {body.valueType === 'ENUM' && (
              <>
                <Tooltip title="Voir la liste des valeurs" arrow>
                  <div
                    className={concatClassNames('flex', 'items-center justify-start', 'cursor-pointer', 'w-fit')}
                    onClick={() => {
                      setIsApiListTypeFormOpened(true);
                    }}
                  >
                    {getIcon('eye', 'purple', 'md')}
                  </div>
                </Tooltip>
                <Text content={`[${body.enum?.values.length ?? 0}]`} position="center" />
              </>
            )}
            {body.valueType !== 'ENUM' && body.valueType !== 'OBJECT' && body.valueType !== 'LIST' && (
              <div className={gridMandatorySpan}>
                <Text
                  content={
                    body.isMandatory === 'true' ? 'Obligatoire' : body.isMandatory === 'false' ? 'Facultatif' : ''
                  }
                  position="left"
                  size="sm"
                  color="gray-200"
                />
              </div>
            )}
            {body.valueType !== 'ENUM' && body.valueType !== 'OBJECT' && (
              <div className={gridDefaultSpan}>
                <Text content={body.defaultValue} position="left" size="sm" color="gray-200" />
              </div>
            )}
            {isEditing && (
              <div className="col-end-[18] flex flex-row justify-center">
                <Settings
                  position="left"
                  iconSize="md"
                  settingsIconName="more"
                  buttonsMenu={[
                    {
                      name: 'Modifier',
                      iconName: 'edit',
                      textColor: 'black',
                      isEnabled: true,
                      onClick: () => {
                        onRowUpdate?.(id);
                      },
                    },
                    {
                      name: 'Supprimer',
                      iconName: 'trash',
                      textColor: 'red',
                      onClick: () => {
                        if (responseFunctionalId === undefined) restApiHandler.deleteBodyParameter(id, path);
                        else restApiHandler.deleteBodyResponse(id, path, responseFunctionalId);
                      },
                    },
                  ]}
                />
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
}
