import React from 'react';

import { Chip, type ChipBorderColor } from 'components/core/Chip/Chip';
import { type ChipBgColor } from 'components/core/Chip/Chip.props';
import { type TextSize, textSizeStyle } from 'components/core/Text/Text';

import concatClassNames from 'utils/classNames';

interface ApiTypeChipProps {
  type: string;
  isFunctional?: boolean;
  size?: TextSize;
}

interface ApiTypeChipValue {
  text: string;
  color: string;
  functional: string;
}

const typeToValue: Record<string, ApiTypeChipValue> = {
  GET: { text: 'GET', color: 'miscelleanous-blue', functional: 'LECTURE' },
  POST: { text: 'POST', color: 'green-500', functional: 'CRÉATION' },
  PUT: { text: 'PUT', color: 'yellow-400', functional: 'MISE A JOUR' },
  PATCH: { text: 'PATCH', color: 'yellow-400', functional: 'MISE A JOUR' },
  DELETE: { text: 'DELETE', color: 'red-500', functional: 'SUPPRESSION' },
  LOADING: { text: '', color: '', functional: '' },
};

export function ApiTypeChip({ type, isFunctional, size = 'api' }: ApiTypeChipProps): JSX.Element {
  if (type === 'LOADING')
    return <div className={concatClassNames('w-20', 'h-10', 'bg-gray-50', 'rounded-md', 'animate-pulse')}></div>;
  return (
    <Chip
      label={typeToValue[type].text + (isFunctional === true ? ` (${typeToValue[type].functional})` : '')}
      textColor="white"
      textSize={size}
      bgColor={typeToValue[type].color as ChipBgColor}
      textTracking="wide"
      paddingTop="md"
      paddingHorizontal="md"
      borderColor={typeToValue[type].color as ChipBorderColor}
    />
  );
}
