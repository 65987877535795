import { SpecksApisContext } from 'App';
import * as Yup from 'yup';

import React, { useContext, useEffect, useState } from 'react';
import { Controller, type SubmitHandler, useForm } from 'react-hook-form';
import { useQuery } from 'react-query';
import { type NavigateFunction, useNavigate } from 'react-router-dom';

import { useAuth0 } from '@auth0/auth0-react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Tooltip } from '@mui/material';

import { Button } from 'components/core/Button/Button';
import Input from 'components/core/Input/Input';
import { ListBox } from 'components/core/ListBox/ListBox';
import RadioButtons from 'components/core/RadioButtons/RadioButtons';
import { TabElement } from 'components/core/Tabs/TabElement';
import { TabList } from 'components/core/Tabs/TabList';
import { TabPanel } from 'components/core/Tabs/TabPanel';
import { TabPanels } from 'components/core/Tabs/TabPanels';
import { Tabs } from 'components/core/Tabs/Tabs';
import { Text } from 'components/core/Text/Text';
import TextArea from 'components/core/TextArea/TextArea';

import { type MethodService } from 'services/method.service';

import { type Method } from 'models/method.entity';
import type { ConstantLogical, TargetType, YesLogical } from 'models/ui-component-revisions.entity';
import { type UiComponent } from 'models/uiComponent.entity';

import concatClassNames from 'utils/classNames';
import getIcon from 'utils/getIcon';

import { ApiMethodRow } from '../Api/Method/ApiMethodRow';
import UICFieldReadOnly from '../UICFieldReadonly/UICFieldReadonly';
import { UiComponentCard } from '../UiComponentCard/UiComponentCard';

interface ButtonInfosFormProps {
  uiComponent: UiComponent;
  checkIfNameIsUnique?: (name: string, id: string) => boolean;
  onClickSave?: () => void;
  setIsFormDirty?: (isDirty: boolean) => void;
  onClickAddTarget?: () => void;
  setFnSetMethodId?: (fn: (methodId: string | undefined) => void) => void;
  isLoading: boolean;
}

const isVisibleOptions: YesLogical[] = ['YES', 'LOGICAL'];

export const isVisibleStyleOptions: string[] = ['Oui, toujours', 'Règle logique'];

const contentTypeOptions: ConstantLogical[] = ['CONSTANT', 'LOGICAL'];

export const contentTypeStyleOptions: string[] = ['Contenu constant', 'Règle logique'];

export type ActionType = 'METHOD' | 'FREE' | 'NOTHING';

const actionTypeOptions: ActionType[] = ['METHOD', 'FREE', 'NOTHING'];

export const inputTypeOptions: Map<ActionType, string> = new Map<ActionType, string>([
  ['METHOD', 'Appel API'],
  ['FREE', 'Autre'],
  ['NOTHING', 'Non renseigné'],
]);

function getActionTypeOptions(key: ActionType): string {
  return inputTypeOptions.get(key) ?? '';
}
export const statusStyleOptions: Map<string, JSX.Element> = new Map<string, JSX.Element>([
  ['METHOD', <Text content={getActionTypeOptions('METHOD')} size="sm" key={'METHOD'} position="center" />],
  ['FREE', <Text content={getActionTypeOptions('FREE')} size="sm" key={'FREE'} position="center" />],
  ['NOTHING', <Text content={getActionTypeOptions('NOTHING')} size="sm" key={'NOTHING'} position="center" />],
]);

interface ButtonFormModel {
  name: string;
  notes?: string;
  action: ActionType;
  isVisible: YesLogical;
  isVisibleLogicalRule?: string;
  targetFree?: string;
  targetMethodId: string | undefined;
  contentType: ConstantLogical;
  sourceMethodId: string | undefined;
  sourceConstant?: string;
  sourceLogicalRule?: string;
}

const schema: Yup.ObjectSchema<ButtonFormModel> = Yup.object().shape({
  name: Yup.string()
    .required('Champ obligatoire')
    .max(28, 'Le nom ne doit pas contenir plus de 28 caractères')
    .test('isUnique', "Un composant d'interface avec ce nom existe déjà", (value, context) => {
      const uiComponent: UiComponent | undefined = context?.options?.context?.uiComponent as UiComponent;
      const isNameUnique: boolean | undefined = context?.options?.context?.checkIfNameIsUnique(value, uiComponent.id);
      return value != null && value.length > 0 && isNameUnique;
    }),
  notes: Yup.string(),

  isVisible: Yup.mixed<YesLogical>().required('Champ obligatoire').default('YES'),
  isVisibleLogicalRule: Yup.string(),
  sourceMethodId: Yup.string(),
  contentType: Yup.mixed<ConstantLogical>().required('Champ obligatoire').default('CONSTANT'),
  sourceConstant: Yup.string(),
  sourceLogicalRule: Yup.string(),
  action: Yup.mixed<ActionType>().required('Champ obligatoire').default('NOTHING'),
  targetFree: Yup.string(),
  targetMethodId: Yup.string(),
});

export function ButtonInfosForm({
  uiComponent,
  checkIfNameIsUnique,
  onClickSave,
  setIsFormDirty,
  onClickAddTarget,
  setFnSetMethodId,
  isLoading,
}: ButtonInfosFormProps): JSX.Element {
  /* --------------------------------------------------- Contexts --------------------------------------------------- */

  const methodService: MethodService = useContext(SpecksApisContext).methodService;
  const { getAccessTokenSilently } = useAuth0();
  const navigate: NavigateFunction = useNavigate();

  /* ----------------------------------------------------- Form ----------------------------------------------------- */

  const {
    register,
    handleSubmit,
    control,
    watch,
    setValue,
    formState: { errors, isSubmitted },
  } = useForm<ButtonFormModel>({
    resolver: yupResolver(schema),
    context: { checkIfNameIsUnique, uiComponent },
  });

  /* --------------------------------------------------- States --------------------------------------------------- */

  const [originalName, setOriginalName] = useState<string>();
  const [originalNotes, setOriginalNotes] = useState<string>();
  const [originalIsVisible, setOriginalIsVisible] = useState<YesLogical>();
  const [originalIsVisibleLogicalRule, setOriginalIsVisibleLogicalRule] = useState<string | undefined>();
  const [originalSourceMethodId, setOriginalSourceMethodId] = useState<string | undefined>();
  const [originalContentType, setOriginalContentType] = useState<ConstantLogical>();
  const [originalSourceConstant, setOriginalSourceConstant] = useState<string | undefined>();
  const [originalSourceLogicalRule, setOriginalSourceLogicalRule] = useState<string | undefined>();
  const [originalTargetedMethodId, setOriginalTargetedMethodId] = useState<string | undefined>();
  const [originalTargetType, setOriginalTargetType] = useState<TargetType>();
  const [originalTargetFree, setOriginalTargetFree] = useState<string>();

  const watchName: string = watch('name');
  const watchNotes: string | undefined = watch('notes');
  const watchType: ActionType = watch('action');
  const watchIsVisible: YesLogical | undefined = watch('isVisible');
  const watchIsVisibleLogicalRule: string | undefined = watch('isVisibleLogicalRule');
  const watchSourceMethodId: string | undefined = watch('sourceMethodId');
  const watchContentType: ConstantLogical | undefined = watch('contentType');
  const watchSourceConstant: string | undefined = watch('sourceConstant');
  const watchSourceLogicalRule: string | undefined = watch('sourceLogicalRule');
  const watchTargetFree: string | undefined = watch('targetFree');
  const watchTargetMethodId: string | undefined = watch('targetMethodId');
  const isEditable: boolean = onClickSave != null;

  /* ----------------------------------------------- alerts management ---------------------------------------------- */

  const isCompleteName: boolean = watchName !== undefined && watchName.length !== 0;

  const isCompleteIsVisibleLogicalRule: boolean =
    watchIsVisible !== 'LOGICAL' || (watchIsVisibleLogicalRule !== undefined && watchIsVisibleLogicalRule.length !== 0);

  const isCompleteSourceConstant: boolean =
    watchContentType !== 'CONSTANT' || (watchSourceConstant !== undefined && watchSourceConstant.length !== 0);

  const isCompleteSourceLogicalRule: boolean =
    watchContentType !== 'LOGICAL' || (watchSourceLogicalRule !== undefined && watchSourceLogicalRule.length !== 0);

  const isCompleteTarget: boolean = watchType !== 'NOTHING';

  const isCompleteTargetFree: boolean =
    watchType !== 'FREE' || (watchTargetFree !== undefined && watchTargetFree.length !== 0);

  const isCompleteTargetMethod: boolean = watchType !== 'METHOD' || watchTargetMethodId !== undefined;

  /* -------------------------------------------------- Setup Data -------------------------------------------------- */

  // Setup the original values of the form (from the database)
  useEffect(() => {
    setOriginalName(uiComponent.uiComponentRevisions[0].name);
    setOriginalNotes(uiComponent.uiComponentRevisions[0].notes);

    setOriginalIsVisible(uiComponent.uiComponentRevisions[0].isVisible);
    setOriginalIsVisibleLogicalRule(uiComponent.uiComponentRevisions[0].isVisibleLogicalRule);
    setOriginalContentType(uiComponent.uiComponentRevisions[0].contentType);
    setOriginalSourceConstant(uiComponent.uiComponentRevisions[0].sourceConstant);
    setOriginalSourceLogicalRule(uiComponent.uiComponentRevisions[0].sourceLogicalRule);
    setOriginalSourceMethodId(uiComponent.uiComponentRevisions[0].sourceMethodId);
    setOriginalTargetType(uiComponent.uiComponentRevisions[0].targetType);
    setOriginalTargetFree(uiComponent.uiComponentRevisions[0].targetFree);
    setOriginalTargetedMethodId(uiComponent.uiComponentRevisions[0].targetMethodId);

    setValue('name', uiComponent.uiComponentRevisions[0].name);
    setValue('notes', uiComponent.uiComponentRevisions[0].notes);
    setValue('action', uiComponent.uiComponentRevisions[0].targetType);
    setValue('targetFree', uiComponent.uiComponentRevisions[0].targetFree);
    setValue('targetMethodId', uiComponent.uiComponentRevisions[0].targetMethodId);
    setValue('isVisible', uiComponent.uiComponentRevisions[0].isVisible);
    setValue('isVisibleLogicalRule', uiComponent.uiComponentRevisions[0].isVisibleLogicalRule);
    setValue('contentType', uiComponent.uiComponentRevisions[0].contentType);
    setValue('sourceConstant', uiComponent.uiComponentRevisions[0].sourceConstant);
    setValue('sourceLogicalRule', uiComponent.uiComponentRevisions[0].sourceLogicalRule);
    setValue('sourceMethodId', uiComponent.uiComponentRevisions[0].sourceMethodId);
  }, [setValue, uiComponent]);

  /* ---------------------------------------------------- queries --------------------------------------------------- */

  async function getMethod(methodId: string): Promise<Method> {
    const accessToken: string = await getAccessTokenSilently();
    return await methodService.findById(methodId, accessToken);
  }

  const { data: targetMethod, status: targetMethodStatus } = useQuery<Method, Error>({
    queryKey: ['method', watchTargetMethodId],
    queryFn: async ({ queryKey }) => {
      const [, methodId] = queryKey;
      return await getMethod(methodId as string);
    },
    enabled: watchTargetMethodId !== undefined,
  });

  const { data: sourceMethod, status: sourceMethodStatus } = useQuery<Method, Error>({
    queryKey: ['method', watchSourceMethodId],
    queryFn: async ({ queryKey }) => {
      const [, methodId] = queryKey;
      return await getMethod(methodId as string);
    },
    enabled: watchSourceMethodId !== undefined,
  });

  /* --------------------------------------------------- Functions -------------------------------------------------- */

  function handleOnClickSave(): void {
    if (onClickSave == null) return;
    uiComponent.uiComponentRevisions[0].name = watchName;
    uiComponent.uiComponentRevisions[0].notes = watchNotes ?? '';
    uiComponent.uiComponentRevisions[0].sourceMethodId = watchSourceMethodId;
    uiComponent.uiComponentRevisions[0].contentType = watchContentType ?? 'CONSTANT';
    uiComponent.uiComponentRevisions[0].sourceConstant = watchSourceConstant ?? '';
    uiComponent.uiComponentRevisions[0].sourceLogicalRule = watchSourceLogicalRule ?? '';
    uiComponent.uiComponentRevisions[0].isVisible = watchIsVisible ?? 'YES';
    uiComponent.uiComponentRevisions[0].isVisibleLogicalRule = watchIsVisibleLogicalRule ?? '';
    uiComponent.uiComponentRevisions[0].targetType = watchType as TargetType;
    uiComponent.uiComponentRevisions[0].targetMethodId = watchTargetMethodId === '' ? undefined : watchTargetMethodId;
    uiComponent.uiComponentRevisions[0].targetFree = watchTargetFree ?? '';
    uiComponent.uiComponentRevisions[0].isFormValid = checkIfFormIsComplete;
    onClickSave();
  }

  const onSubmitForm: SubmitHandler<ButtonFormModel> = (data: ButtonFormModel) => {
    handleOnClickSave();
  };

  function setContentMethodId(methodId: string | undefined): void {
    setValue('sourceMethodId', methodId);
  }

  function setTargetMethodId(methodId: string | undefined): void {
    setValue('targetMethodId', methodId);
  }

  function removeSourceMethodId(): void {
    setContentMethodId(undefined);
    setValue('sourceMethodId', undefined);
  }

  function removeTargetMethodId(): void {
    setTargetMethodId(undefined);
    setValue('targetMethodId', undefined);
  }

  /* --------------------------------------------------- Watchers --------------------------------------------------- */

  const checkIfFormIsComplete: boolean =
    isCompleteName &&
    isCompleteIsVisibleLogicalRule &&
    isCompleteSourceConstant &&
    isCompleteSourceLogicalRule &&
    isCompleteTargetFree &&
    isCompleteTargetMethod;

  let isFormValid: boolean = true;
  if (
    watchName === undefined ||
    watchName.length === 0 ||
    (watchName === originalName &&
      watchNotes === originalNotes &&
      watchType === originalTargetType &&
      watchIsVisible === originalIsVisible &&
      watchIsVisibleLogicalRule === originalIsVisibleLogicalRule &&
      watchSourceMethodId === originalSourceMethodId &&
      watchContentType === originalContentType &&
      watchSourceConstant === originalSourceConstant &&
      watchSourceLogicalRule === originalSourceLogicalRule &&
      watchTargetFree === originalTargetFree &&
      watchTargetMethodId === originalTargetedMethodId)
  ) {
    isFormValid = false;
  }
  setIsFormDirty?.(false);
  if (
    watchName !== originalName ||
    watchNotes !== originalNotes ||
    watchIsVisible !== originalIsVisible ||
    watchIsVisibleLogicalRule !== originalIsVisibleLogicalRule ||
    watchSourceMethodId !== originalSourceMethodId ||
    watchContentType !== originalContentType ||
    watchSourceConstant !== originalSourceConstant ||
    watchSourceLogicalRule !== originalSourceLogicalRule ||
    watchType !== originalTargetType ||
    watchTargetFree !== originalTargetFree ||
    watchTargetMethodId !== originalTargetedMethodId
  ) {
    setIsFormDirty?.(true);
  }

  /* --------------------------------------------------- Rendering -------------------------------------------------- */

  return (
    <Tabs>
      <TabList>
        <TabElement textSize="base" title={'Spécification'}></TabElement>
        <TabElement textSize="base" title={'Règles logiques'}></TabElement>
      </TabList>
      <TabPanels>
        <TabPanel>
          <form
            className="flex flex-col gap-6 justify-between flex-grow overflow-auto mt-2"
            onSubmit={handleSubmit(onSubmitForm)}
          >
            <div className="flex flex-col gap-6 overflow-auto">
              <div className={concatClassNames('flex flex-col', 'gap-1')}>
                {!isEditable && (
                  <UICFieldReadOnly
                    label="Identifiant Specks"
                    information="Cet identifiant vous permettra de le retrouver plus facilement dans Specks."
                    content={watchName}
                  />
                )}
                {isEditable && (
                  <Input
                    label="Identifiant Specks"
                    labelWeight="medium"
                    information="Cet identifiant vous permettra de le retrouver plus facilement dans Specks."
                    placeholder="L'identifiant Specks de votre composant d'interface"
                    textSize="base"
                    {...register('name')}
                    maxLength={28}
                    error={errors.name != null && isSubmitted ? errors.name.message : undefined}
                  />
                )}
                {watchName !== undefined && watchName.length === 0 && (
                  <Text content="Le nom est obligatoire" color="red-500" size="sm" position="center" />
                )}
              </div>

              <div className={concatClassNames('flex flex-col', 'gap-2')}>
                <UICFieldReadOnly
                  label="Visible ?"
                  information="Ce bouton est-il systématiquement visible par l'utilisateur ?"
                  isComplete={isCompleteIsVisibleLogicalRule}
                  error="Préconisation : ce champ ne devrait pas être vide"
                />
                <RadioButtons
                  {...register('isVisible')}
                  values={isVisibleOptions}
                  valuesDisplay={isVisibleStyleOptions}
                  selected={watchIsVisible}
                  isEditable={isEditable}
                  isComplete={isCompleteIsVisibleLogicalRule}
                  error="Préconisation : ce champ ne devrait pas être vide"
                />

                {watchIsVisible === 'LOGICAL' && (
                  <>
                    {isEditable && (
                      <TextArea
                        {...register('isVisibleLogicalRule')}
                        value={watchIsVisibleLogicalRule}
                        placeholder={`Etant données [les hypothèses suivantes], lorsque [tel événement se produit] alors [voici le comportement attendu] `}
                        rows={4}
                        isComplete={isCompleteIsVisibleLogicalRule}
                        disabled={!isEditable}
                      />
                    )}
                    {!isEditable && <UICFieldReadOnly content={watchIsVisibleLogicalRule ?? ''} />}
                  </>
                )}
              </div>

              <div
                className={concatClassNames(
                  'flex flex-col',
                  'drop-shadow-md bg-white rounded-md',
                  'border-x-1 border-gray-25',
                  'p-4',
                  'gap-2',
                )}
              >
                <UICFieldReadOnly
                  label="Texte du bouton"
                  information="Texte affiché sur le bouton"
                  isComplete={isCompleteSourceConstant && isCompleteSourceLogicalRule}
                  error="Préconisation : ce champ ne devrait pas être vide"
                />
                {(isEditable || watchSourceMethodId !== undefined) && (
                  <>
                    <div className={concatClassNames('flex flex-row gap-1 items-center')}>
                      <Text content={isEditable ? 'Source (facultatif)' : 'Source'} position="left" size="sm" />
                      <Tooltip
                        title={
                          'Souhaitez-vous définir une source pour récupérer tout ou partie du texte affiché sur le bouton ?'
                        }
                        placement="right"
                      >
                        <div>{<>{getIcon('information', 'gradient2-to', 'sm')}</>}</div>
                      </Tooltip>
                    </div>

                    <div className={concatClassNames('flex flex-row', 'gap-4')}>
                      {isEditable && watchSourceMethodId === undefined && (
                        <Button
                          content={'Ajouter une source'}
                          type="button"
                          iconName="plus"
                          iconPosition="right"
                          bgColor="white"
                          textColor="black"
                          borderWidth="xs"
                          borderColor="grey"
                          textSize="sm"
                          iconColor="black"
                          width="fit"
                          onClick={() => {
                            setFnSetMethodId?.(() => setContentMethodId);
                            onClickAddTarget?.();
                          }}
                        />
                      )}
                      <div id="selected_methods" className={concatClassNames('flex', 'items-center', 'flex-grow')}>
                        {watchSourceMethodId === undefined && (
                          <Text content={'Aucune méthode sélectionnée'} color="gray-400" size="sm" position="center" />
                        )}
                        {watchSourceMethodId !== undefined && sourceMethodStatus === 'success' && (
                          <ApiMethodRow
                            type={sourceMethod?.methodRevisions[0].methodType}
                            methodId={sourceMethod?.id ?? ''}
                            path={sourceMethod?.methodRevisions[0].name ?? ''}
                            description={sourceMethod?.methodRevisions[0].description ?? ''}
                            borderColored
                            clickable={true}
                            onDelete={isEditable ? removeSourceMethodId : undefined}
                          />
                        )}
                      </div>
                    </div>
                  </>
                )}

                <div className={concatClassNames('flex flex-col', 'gap-2')}>
                  <RadioButtons
                    values={contentTypeOptions}
                    valuesDisplay={contentTypeStyleOptions}
                    selected={watchContentType}
                    {...register('contentType')}
                    isEditable={isEditable}
                    isComplete={isCompleteSourceConstant && isCompleteSourceLogicalRule}
                    error="Préconisation : ce champ ne devrait pas être vide"
                  />
                  {watchContentType === 'CONSTANT' && (
                    <>
                      {isEditable && (
                        <TextArea
                          {...register('sourceConstant')}
                          value={watchSourceConstant}
                          placeholder="Saisissez ici le contenu du bouton qui sera systématiquement affiché à l'utilisateur"
                          rows={4}
                          isComplete={isCompleteSourceConstant}
                          disabled={!isEditable}
                        />
                      )}
                      {!isEditable && <UICFieldReadOnly content={watchSourceConstant ?? ''} />}
                    </>
                  )}
                  {watchContentType === 'LOGICAL' && (
                    <>
                      {isEditable && (
                        <TextArea
                          {...register('sourceLogicalRule')}
                          value={watchSourceLogicalRule}
                          rows={4}
                          placeholder={`Etant données [les hypothèses suivantes], lorsque [tel événement se produit] alors [voici le comportement attendu] `}
                          isComplete={isCompleteSourceLogicalRule}
                          disabled={!isEditable}
                        />
                      )}
                      {!isEditable && <UICFieldReadOnly content={watchSourceLogicalRule ?? ''} />}
                    </>
                  )}
                </div>
              </div>

              <div
                className={concatClassNames(
                  'flex flex-col',
                  'drop-shadow-md bg-white rounded-md',
                  'border-x-1 border-gray-25',
                  'p-4',
                  'gap-1',
                )}
              >
                <UICFieldReadOnly
                  label="Actions"
                  information="Actions déclenchées par le bouton"
                  isComplete={isCompleteTargetFree && isCompleteTargetMethod && isCompleteTarget}
                  error={
                    isCompleteTarget
                      ? isCompleteTargetMethod
                        ? 'Préconisation : ce champ ne devrait pas être vide'
                        : 'Préconisation : une methode devrait être sélectionnée'
                      : 'Préconisation : un bouton devrait avoir une action'
                  }
                  description={
                    !isEditable ? "Lorsque l'utilisateur clique sur le bouton, cela déclenche..." : undefined
                  }
                  content={
                    !isEditable
                      ? watchType === 'METHOD'
                        ? 'Appel API'
                        : watchType === 'FREE'
                        ? 'Autre'
                        : watchType === 'NOTHING'
                        ? 'Non renseigné'
                        : ''
                      : undefined
                  }
                />
                {isEditable && (
                  <Controller
                    name="action"
                    control={control}
                    defaultValue={'NOTHING'}
                    render={({ field: { value, onChange } }) => (
                      <ListBox
                        description="Lorsque l'utilisateur clique sur le bouton, cela déclenche..."
                        selected={value}
                        onChange={onChange}
                        options={actionTypeOptions}
                        styleOptions={statusStyleOptions}
                        itemsBorder
                        readonly={!isEditable}
                        isComplete={isCompleteTarget}
                      />
                    )}
                  />
                )}

                {watchType === 'METHOD' && (
                  <>
                    <div className={concatClassNames('flex flex-row', 'gap-4')}>
                      {isEditable && watchTargetMethodId === undefined && (
                        <Button
                          content={'Ajouter une méthode'}
                          type="button"
                          iconName="plus"
                          iconPosition="right"
                          bgColor="white"
                          textColor="black"
                          borderWidth="xs"
                          borderColor="grey"
                          textSize="sm"
                          iconColor="black"
                          width="fit"
                          onClick={() => {
                            setFnSetMethodId?.(() => setTargetMethodId);
                            onClickAddTarget?.();
                          }}
                          error={!isCompleteTargetMethod}
                        />
                      )}
                      <div id="selected_methods" className={concatClassNames('flex', 'items-center', 'flex-grow')}>
                        {watchTargetMethodId === undefined && (
                          <Text content="Aucune méthode sélectionnée" color="gray-400" size="sm" position="center" />
                        )}
                        {watchTargetMethodId !== undefined && targetMethodStatus === 'success' && (
                          <ApiMethodRow
                            type={targetMethod?.methodRevisions[0].methodType}
                            methodId={targetMethod?.id ?? ''}
                            path={targetMethod?.methodRevisions[0].name ?? ''}
                            description={targetMethod?.methodRevisions[0].description ?? ''}
                            borderColored
                            onDelete={isEditable ? removeTargetMethodId : undefined}
                          />
                        )}
                      </div>
                    </div>
                  </>
                )}
                {watchType === 'FREE' && (
                  <>
                    {isEditable && (
                      <TextArea
                        placeholder="Ajoutez une description"
                        {...register('targetFree')}
                        value={watchTargetFree}
                        textSize="sm"
                        disabled={!isEditable}
                        isComplete={isCompleteTargetFree}
                      />
                    )}
                    {!isEditable && <UICFieldReadOnly content={watchTargetFree ?? ''} />}
                  </>
                )}
              </div>

              {isEditable && (
                <TextArea
                  label="Notes"
                  labelWeight="medium"
                  labelColor="gray-700"
                  placeholder="Ajouter une note"
                  textSize="sm"
                  {...register('notes')}
                  value={watchNotes}
                  rows={6}
                  error={errors.notes != null && isSubmitted ? errors.notes.message : undefined}
                />
              )}
              {!isEditable && (
                <UICFieldReadOnly
                  label="Notes"
                  content={watchNotes === undefined || watchNotes.length === 0 ? 'N/A' : watchNotes}
                />
              )}
            </div>

            {onClickSave != null && (
              <div className="flex flex-row gap-2 justify-end">
                {!isLoading && <Button content="Sauvegarder" type="submit" disabled={!isFormValid} />}
                {isLoading && <Button iconName="spinCircle" type="button" height="sm" iconAnimation="spin" />}
              </div>
            )}
          </form>
        </TabPanel>
        <TabPanel>
          <div className={concatClassNames('flex flex-col flex-grow justify-between')}>
            <div className={concatClassNames('flex flex-col gap-4')}>
              <div className={concatClassNames('flex flex-col gap-1')}>
                <Text content="Règles du Composant d'interface" weight="bold" position="left" />
                <Text
                  content="Aucune règle n'a été définie pour ce composant. "
                  color="gray-100"
                  position="left"
                  size="sm"
                />
              </div>
              <div className={concatClassNames('flex flex-col gap-1')}>
                <Text content="Règles héritées (non modifiables)" weight="bold" position="left" />
                <Text
                  content="Ce composant ne reçoit aucune règle en héritage"
                  color="gray-100"
                  position="left"
                  size="sm"
                />
              </div>
            </div>
            {onClickSave != null && (
              <div className={concatClassNames('flex gap-2 pt-8 border-t-1 border-gray-50')}>
                <Button content="Ajouter une règle" iconName="plus" cursor="not-allowed" />
                <Button content="Sauvegarder" cursor="not-allowed" />
              </div>
            )}
          </div>
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
}
