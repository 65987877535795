import React from 'react';

import concatClassNames from 'utils/classNames';

import { type CardHeaderProps, cardHeaderHeightStyle } from './Card.props';

export function CardHeader({
  children,
  height = 'fill',
  loading = false,
  bgColor = 'bg-gray-50',
}: CardHeaderProps): JSX.Element {
  return (
    <div
      className={concatClassNames(
        'flex flex-col justify-center items-center',
        'w-full',
        'p-2',
        'rounded-t-3xl',
        cardHeaderHeightStyle[height],
        loading ? 'bg-gray-50' : bgColor,
      )}
    >
      {children}
    </div>
  );
}
