import React from 'react';

import { ApiTypeChip } from './ApiTypeChip';

interface GetChipProps {
  isFonctional?: boolean;
}

export function LoadingChip({ isFonctional = false }: GetChipProps): JSX.Element {
  return <ApiTypeChip type="LOADING" isFunctional={isFonctional} />;
}
