import { SpecksApisContext } from 'App';

import React, { useContext, useState } from 'react';
import { type QueryClient, useMutation, useQuery, useQueryClient } from 'react-query';
import { type NavigateFunction, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { useAuth0 } from '@auth0/auth0-react';

import { Button } from 'components/core/Button/Button';
import Input from 'components/core/Input/Input';
import { Modal } from 'components/core/Modal/Modal';
import { RichText } from 'components/core/RichText/RichText';
import { Text } from 'components/core/Text/Text';

import { Status } from 'models/Status.types';
import { type Product } from 'models/product.entity';

import getStatusChip from 'utils/getStatusChip';

interface DeleteComponentProps {
  componentId?: string;
  setComponentId: (componentId?: string) => void;
  behaviourAfterDeletion: () => void;
}

export function DeleteComponent({
  componentId,
  setComponentId,
  behaviourAfterDeletion,
}: DeleteComponentProps): JSX.Element {
  const { componentService } = useContext(SpecksApisContext);

  const queryClient: QueryClient = useQueryClient();
  const navigate: NavigateFunction = useNavigate();
  const { getAccessTokenSilently, user } = useAuth0();

  /* ---------------------------------------------------- states ---------------------------------------------------- */

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [deletionConfirmationInput, setDeletionConfirmationInput] = useState<string>('');

  /* ---------------------------------------------------- queries --------------------------------------------------- */
  async function getRelatedProducts(): Promise<Product[]> {
    if (componentId === undefined) return [];
    const accessToken: string = await getAccessTokenSilently();
    return await componentService.findRelatedProducts(componentId, accessToken);
  }

  const { data: relatedProductsData, status: relatedProductsStatus } = useQuery<Product[], Error>(
    ['products', componentId],
    getRelatedProducts,
    { enabled: componentId !== undefined },
  );
  /* ----------------------------------------------- Delete Component ----------------------------------------------- */

  async function deleteComponent(): Promise<void> {
    if (componentId === undefined) return;
    const accessToken: string = await getAccessTokenSilently();
    await componentService.delete(componentId, accessToken);
  }

  const { mutate: mutateDeleteComponent } = useMutation(deleteComponent, {
    onSuccess: () => {
      toast.success('Le composant a bien été supprimé');
      behaviourAfterDeletion();
      setIsLoading(false);
    },
    onError: () => {
      toast.error('Erreur lors de la suppression du composant');
      setIsLoading(false);
    },
  });

  function handleComponentDeletionConfirmation(): void {
    if (deletionConfirmationInput !== 'supprimer') {
      toast.error('Veuillez entrer "supprimer" pour valider la suppression');
      return;
    }
    setIsLoading(true);
    try {
      mutateDeleteComponent();
    } catch (error) {
      console.error(error);
    }
  }

  function closeModal(): void {
    setComponentId(undefined);
  }

  return (
    <Modal title="Attention" isOpen={componentId !== undefined} setIsOpen={closeModal}>
      <>
        {relatedProductsStatus === 'success' && relatedProductsData.length === 0 && (
          <>
            <RichText
              fragments={[
                {
                  contentType: 'p',
                  content:
                    'Vous vous apprêtez à supprimer définitivement ce composant ainsi que tous les éléments qui lui sont rattachés.',
                  whitespace: 'pre-line',
                  position: 'justify',
                },
                {
                  contentType: 'complex',
                  content: [
                    {
                      contentType: 'p',
                      content: 'Cette action est ',
                    },
                    {
                      contentType: 'span',
                      content: 'IRRÉVERSIBLE',
                      whitespace: 'pre-line',
                      position: 'justify',
                      color: 'purple-500',
                      weight: 'bold',
                    },
                    {
                      contentType: 'p',
                      content: '.',
                    },
                  ],
                  whitespace: 'pre-line',
                  position: 'justify',
                },
                {
                  contentType: 'p',
                  content: 'Si vous souhaitez simplement le décommissioner, changez le statut de ce composant pour :',
                  whitespace: 'pre-line',
                  position: 'justify',
                },
                {
                  contentType: 'span',
                  content: getStatusChip({ name: Status.Decommissioned, iconSize: 'xs', textSize: 'base' }),
                },
              ]}
            />
            <Input
              label={`Veuillez entrer "supprimer" pour valider la suppression`}
              onChange={(event) => {
                setDeletionConfirmationInput(event.target.value);
              }}
              value={deletionConfirmationInput}
            />
            <div className="flex flex-row gap-2 justify-end">
              {!isLoading && (
                <Button
                  onClick={handleComponentDeletionConfirmation}
                  content="Supprimer"
                  width="1/2"
                  type="submit"
                  height="sm"
                />
              )}
              {isLoading && <Button iconName="spinCircle" width="1/2" height="sm" iconAnimation="spin" />}
            </div>
          </>
        )}
        {relatedProductsStatus === 'success' && relatedProductsData.length !== 0 && (
          <>
            <Text
              whitespace="pre-line"
              content={`Vous ne pouvez pas supprimer ce composant car il est rattaché aux produits suivants :`}
              position="justify"
            />
            {relatedProductsData.map((product) => (
              <li key={product.id}>
                <a className="text-purple-600" href={`/product/${product.id}/app`}>
                  {product.name}
                </a>
              </li>
            ))}
          </>
        )}
        {relatedProductsStatus === 'loading' && (
          <Button iconName="spinCircle" width="1/2" height="sm" iconAnimation="spin" />
        )}
        {relatedProductsStatus === 'error' && (
          <Button iconName="spinCircle" width="1/2" height="sm" iconAnimation="spin" disabled />
        )}
      </>
    </Modal>
  );
}
