/* tslint:disable */
/* eslint-disable */
/**
 * Specks API
 * APIs for specks SaaS
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { DictionaryDto } from './DictionaryDto';
import {
    DictionaryDtoFromJSON,
    DictionaryDtoFromJSONTyped,
    DictionaryDtoToJSON,
} from './DictionaryDto';

/**
 * 
 * @export
 * @interface DictionaryEntryDto
 */
export interface DictionaryEntryDto {
    /**
     * 
     * @type {string}
     * @memberof DictionaryEntryDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof DictionaryEntryDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof DictionaryEntryDto
     */
    definition: string;
    /**
     * 
     * @type {DictionaryDto}
     * @memberof DictionaryEntryDto
     */
    dictionary: DictionaryDto;
}

/**
 * Check if a given object implements the DictionaryEntryDto interface.
 */
export function instanceOfDictionaryEntryDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "definition" in value;
    isInstance = isInstance && "dictionary" in value;

    return isInstance;
}

export function DictionaryEntryDtoFromJSON(json: any): DictionaryEntryDto {
    return DictionaryEntryDtoFromJSONTyped(json, false);
}

export function DictionaryEntryDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): DictionaryEntryDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'definition': json['definition'],
        'dictionary': DictionaryDtoFromJSON(json['dictionary']),
    };
}

export function DictionaryEntryDtoToJSON(value?: DictionaryEntryDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'definition': value.definition,
        'dictionary': DictionaryDtoToJSON(value.dictionary),
    };
}

