/* tslint:disable */
/* eslint-disable */
/**
 * Specks API
 * APIs for specks SaaS
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Asset } from './Asset';
import {
    AssetFromJSON,
    AssetFromJSONTyped,
    AssetToJSON,
} from './Asset';
import type { Product } from './Product';
import {
    ProductFromJSON,
    ProductFromJSONTyped,
    ProductToJSON,
} from './Product';

/**
 * 
 * @export
 * @interface ProductGenesis
 */
export interface ProductGenesis {
    /**
     * 
     * @type {string}
     * @memberof ProductGenesis
     */
    id: string;
    /**
     * 
     * @type {Product}
     * @memberof ProductGenesis
     */
    product: Product;
    /**
     * 
     * @type {Array<Asset>}
     * @memberof ProductGenesis
     */
    assets: Array<Asset>;
}

/**
 * Check if a given object implements the ProductGenesis interface.
 */
export function instanceOfProductGenesis(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "product" in value;
    isInstance = isInstance && "assets" in value;

    return isInstance;
}

export function ProductGenesisFromJSON(json: any): ProductGenesis {
    return ProductGenesisFromJSONTyped(json, false);
}

export function ProductGenesisFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProductGenesis {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'product': ProductFromJSON(json['product']),
        'assets': ((json['assets'] as Array<any>).map(AssetFromJSON)),
    };
}

export function ProductGenesisToJSON(value?: ProductGenesis | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'product': ProductToJSON(value.product),
        'assets': ((value.assets as Array<any>).map(AssetToJSON)),
    };
}

