import React from 'react';

import concatClassNames from 'utils/classNames';

export function IsEditingChip(): JSX.Element {
  return (
    <div
      className={concatClassNames(
        'flex',
        'items-center justify-center',
        'px-4 py-2',
        'text-base font-medium text-gray-400',
        'bg-[#f6e7d7]',
        'rounded-full',
      )}
    >
      <span>Mode édition</span>
    </div>
  );
}
