import { AuthenticationGuard } from 'authentication-guard';

import React, { createContext, useContext } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { ProductFruits } from 'react-product-fruits';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

import { useAuth0 } from '@auth0/auth0-react';

import { CallbackPage } from 'pages/CallbackPage/CallbackPage';
import { ComponentBackApiSpecificationApiPage } from 'pages/Component/BackEndApi/Api/ApiPage';
import { ComponentDictionaryFaqPage } from 'pages/Component/ComponentDictionaryFaqPage/ComponentDictionaryFaqPage';
import { ComponentPage } from 'pages/Component/ComponentPage/ComponentPage';
import { ComponentsPage } from 'pages/Component/ComponentsPage/ComponentsPage';
import { ComponentFrontEndPagePage } from 'pages/Component/FrontEnd/Page/ComponentFrontEndPagePage';
import { ComponentRelatedProductsPage } from 'pages/ComponentRelatedProductsPage/ComponentRelatedProductsPage ';
import { ExpiredFreeTrialPage } from 'pages/ExpiredFreeTrial/ExpiredFreeTrial';
import { AppSchemaRevisionPage } from 'pages/Product/AppSchemaPage/AppSchemaPage';
import { ProductDictionaryFaqPage } from 'pages/Product/DictionaryFaqPage/ProductDictionaryFaqPage';
import { FeaturePage } from 'pages/Product/Features/FeaturePage/FeaturePage';
import { ProductFeaturesPage } from 'pages/Product/Features/FeaturesPage/ProductFeaturesPage';
import { ProductGenesisPage } from 'pages/Product/GenesisPage/ProductGenesisPage';
import { ProductPage } from 'pages/Product/ProductPage/ProductPage';
import { ProductsPage } from 'pages/Product/ProductsPage/ProductsPage';
import { PublicPage } from 'pages/PublicPage/PublicPage';
import { UnauthorizedPage } from 'pages/Unauthorized/Unauthorized';

import { BackendApiSpecificationService } from 'services/apiSpecification.service';
import { AppSchemaService } from 'services/appSchema.service';
import { AssetsService } from 'services/asset.service';
import { CompanyService } from 'services/company.service';
import { ComponentService } from 'services/component.service';
import { DictionaryService } from 'services/dictionary.service';
import { ExternalLinKService } from 'services/external-links.service';
import { FaqService } from 'services/faq.service';
import { FeatureService } from 'services/feature.service';
import { FrontSpecificationService } from 'services/frontSpecification.service';
import { GenesisService } from 'services/genesis.service';
import { MethodService } from 'services/method.service';
import { PageService } from 'services/page.service';
import { PageRevisionService } from 'services/pageRevision.service';
import { PersonaService } from 'services/persona.service';
import { ProductService } from 'services/product.service';
import { ResourceService } from 'services/resource.service';
import { UiComponentService } from 'services/uiComponent.service';

import { LoggerProvider, type LoggerService, initLoggerService } from 'utils/logger';

import './index.css';
import { HomePage } from './pages/HomePage/HomePage';
import { NotFoundPage } from './pages/NotFoundPage/NotFoundPage';

const queryClient: QueryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: 0,
      onError: (error: any) => {
        if (error.message === 'ERR-001') window.location.replace('/denied');
        if (error.message === 'ERR-002') window.location.replace('/expired');
      },
    },
    mutations: {
      onError: (error: any) => {
        if (error.message === 'ERR-001') window.location.replace('/denied');
        if (error.message === 'ERR-002') window.location.replace('/expired');
      },
    },
  },
});

interface SpecksApi {
  productService: ProductService;
  dictionaryService: DictionaryService;
  externalLinkService: ExternalLinKService;
  faqService: FaqService;
  featureService: FeatureService;
  assetService: AssetsService;
  genesisService: GenesisService;
  componentService: ComponentService;
  frontSpecificationService: FrontSpecificationService;
  backApiSpecificationService: BackendApiSpecificationService;
  resourceService: ResourceService;
  appSchemaService: AppSchemaService;
  pageService: PageService;
  pageRevisionService: PageRevisionService;
  personaService: PersonaService;
  uiComponentService: UiComponentService;
  methodService: MethodService;
  companyService: CompanyService;
}

const services: SpecksApi = {
  productService: new ProductService(),
  dictionaryService: new DictionaryService(),
  faqService: new FaqService(),
  featureService: new FeatureService(),
  genesisService: new GenesisService(),
  assetService: new AssetsService(),
  componentService: new ComponentService(),
  frontSpecificationService: new FrontSpecificationService(),
  backApiSpecificationService: new BackendApiSpecificationService(),
  resourceService: new ResourceService(),
  appSchemaService: new AppSchemaService(),
  pageService: new PageService(),
  pageRevisionService: new PageRevisionService(),
  personaService: new PersonaService(),
  uiComponentService: new UiComponentService(),
  methodService: new MethodService(),
  externalLinkService: new ExternalLinKService(),
  companyService: new CompanyService(),
};

export const SpecksApisContext: React.Context<SpecksApi> = createContext(services);

// init logger
const loggerService: LoggerService = initLoggerService('TRACE');

interface HistoryProviderProps {
  children: JSX.Element[] | JSX.Element;
  handler: HistoryHandler;
}
export interface HistoryHandler {
  oldPath: string | undefined;
  actualPath: string | undefined;
}

export const HistoryContext: React.Context<HistoryHandler> = createContext({} as HistoryHandler);

export function useHistoryHandler(): HistoryHandler {
  return useContext(HistoryContext);
}

export function HistoryProvider({ children, handler }: HistoryProviderProps): JSX.Element {
  return <HistoryContext.Provider value={handler}>{children}</HistoryContext.Provider>;
}

const historyHandler: HistoryHandler = {
  oldPath: undefined,
  actualPath: undefined,
};

interface tutorialContextProps {
  setProductId?: (id: string) => void;
  setComponentId?: (id: string) => void;
  setPageId?: (id: string) => void;
  setUicId?: (id: string) => void;
}

const tutorialContext: tutorialContextProps = {
  setProductId: undefined,
  setComponentId: undefined,
  setPageId: undefined,
  setUicId: undefined,
};

interface UserInfoProps {
  username: string;
  email: string;
  firstname: string;
  lastname: string;
  signUpAt: string;
  role: string;
  props: {
    productId: string;
    componentId?: string;
    pageId?: string;
    uicId?: string;
  };
}

const userInfo: UserInfoProps = {
  username: '-ERROR-',
  email: '-ERROR-',
  firstname: '-ERROR-',
  lastname: '-ERROR-',
  signUpAt: '-ERROR-',
  role: 'user',
  props: {
    productId: '',
    componentId: '',
    pageId: '',
    uicId: '',
  },
};

export const TutorialContext: React.Context<tutorialContextProps> = createContext(tutorialContext);

export function App(): JSX.Element {
  const { user } = useAuth0();
  const [productId, setProductId] = React.useState<string>('');
  const [componentId, setComponentId] = React.useState<string>('');
  const [pageId, setPageId] = React.useState<string>('');
  const [uicId, setUicId] = React.useState<string>('');

  // type of userInfo

  userInfo.username =
    user !== undefined ? (user?.name?.slice(user?.name?.indexOf(']') + 2, user?.name?.length) as string) : 'Romain';
  userInfo.email = user !== undefined ? (user?.email as string) : '==REPLACE==';
  userInfo.firstname = user !== undefined ? (user?.name as string) : 'Laureline';
  userInfo.lastname = user !== undefined ? (user?.family_name as string) : '==REPLACE==';
  userInfo.signUpAt = user !== undefined ? (user?.created_at as string) : '==REPLACE==';
  userInfo.props.productId = productId;
  userInfo.props.componentId = componentId;
  userInfo.props.pageId = pageId;
  userInfo.props.uicId = uicId;

  tutorialContext.setProductId = setProductId;
  tutorialContext.setComponentId = setComponentId;
  tutorialContext.setPageId = setPageId;
  tutorialContext.setUicId = setUicId;

  return (
    <React.StrictMode>
      <QueryClientProvider client={queryClient}>
        <SpecksApisContext.Provider value={services}>
          <HistoryProvider handler={historyHandler}>
            <LoggerProvider loggerService={loggerService}>
              <DndProvider backend={HTML5Backend}>
                <ToastContainer
                  bodyClassName={() => 'flex font-body font-light block p-3'}
                  position="bottom-right"
                  pauseOnHover={true}
                  closeOnClick={true}
                  limit={3}
                  closeButton={false}
                  autoClose={2000}
                />
                <ProductFruits workspaceCode="Vt38REh6tGW71LJy" language="en" user={userInfo} />
                <Routes>
                  <Route path="/" element={<PublicPage />} />
                  <Route path="/callback" element={<AuthenticationGuard component={CallbackPage} />} />
                  <Route path="/denied" element={<AuthenticationGuard component={UnauthorizedPage} />} />
                  <Route path="/expired" element={<AuthenticationGuard component={ExpiredFreeTrialPage} />} />
                  <Route path="/home" element={<AuthenticationGuard component={HomePage} />} />
                  <Route path="/products" element={<AuthenticationGuard component={ProductsPage} />} />
                  <Route path="/components" element={<AuthenticationGuard component={ComponentsPage} />} />
                  <Route path="/product/:id" element={<AuthenticationGuard component={ProductPage} />} />
                  <Route
                    path="/product/:id/dico-faq"
                    element={<AuthenticationGuard component={ProductDictionaryFaqPage} />}
                  />
                  <Route
                    path="/product/:id/features"
                    element={<AuthenticationGuard component={ProductFeaturesPage} />}
                  />
                  <Route path="/product/:id/app" element={<AuthenticationGuard component={AppSchemaRevisionPage} />} />
                  <Route
                    path="/product/:productId/feature/:featureId"
                    element={<AuthenticationGuard component={FeaturePage} />}
                  />
                  <Route
                    path="/product/:productId/genesis"
                    element={<AuthenticationGuard component={ProductGenesisPage} />}
                  />
                  <Route path="/component/:componentId" element={<AuthenticationGuard component={ComponentPage} />} />
                  <Route
                    path="/component/:componentId/dico-faq"
                    element={<AuthenticationGuard component={ComponentDictionaryFaqPage} />}
                  />
                  <Route
                    path="/component/:componentId/produits-consommateurs"
                    element={<AuthenticationGuard component={ComponentRelatedProductsPage} />}
                  />
                  <Route
                    path="/component/:componentId/specifications-api"
                    element={<AuthenticationGuard component={ComponentBackApiSpecificationApiPage} />}
                  />
                  <Route
                    path="/component/:componentId/front/pages/:pageId"
                    element={<AuthenticationGuard component={ComponentFrontEndPagePage} />}
                  />
                  <Route path="*" element={<NotFoundPage />} />
                </Routes>
              </DndProvider>
            </LoggerProvider>
          </HistoryProvider>
        </SpecksApisContext.Provider>
      </QueryClientProvider>
    </React.StrictMode>
  );
}
