/* tslint:disable */
/* eslint-disable */
/**
 * Specks API
 * APIs for specks SaaS
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ComponentIdDto } from './ComponentIdDto';
import {
    ComponentIdDtoFromJSON,
    ComponentIdDtoFromJSONTyped,
    ComponentIdDtoToJSON,
} from './ComponentIdDto';
import type { ProductIdDto } from './ProductIdDto';
import {
    ProductIdDtoFromJSON,
    ProductIdDtoFromJSONTyped,
    ProductIdDtoToJSON,
} from './ProductIdDto';
import type { UserIdNameDto } from './UserIdNameDto';
import {
    UserIdNameDtoFromJSON,
    UserIdNameDtoFromJSONTyped,
    UserIdNameDtoToJSON,
} from './UserIdNameDto';

/**
 * 
 * @export
 * @interface GetCompanyDto
 */
export interface GetCompanyDto {
    /**
     * 
     * @type {string}
     * @memberof GetCompanyDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof GetCompanyDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof GetCompanyDto
     */
    companyStatus: GetCompanyDtoCompanyStatusEnum;
    /**
     * 
     * @type {Array<ProductIdDto>}
     * @memberof GetCompanyDto
     */
    products: Array<ProductIdDto>;
    /**
     * 
     * @type {Array<ComponentIdDto>}
     * @memberof GetCompanyDto
     */
    components: Array<ComponentIdDto>;
    /**
     * 
     * @type {Array<UserIdNameDto>}
     * @memberof GetCompanyDto
     */
    users: Array<UserIdNameDto>;
}


/**
 * @export
 */
export const GetCompanyDtoCompanyStatusEnum = {
    Trial: 'TRIAL',
    ExpiredTrial: 'EXPIRED_TRIAL',
    Active: 'ACTIVE',
    Inactive: 'INACTIVE'
} as const;
export type GetCompanyDtoCompanyStatusEnum = typeof GetCompanyDtoCompanyStatusEnum[keyof typeof GetCompanyDtoCompanyStatusEnum];


/**
 * Check if a given object implements the GetCompanyDto interface.
 */
export function instanceOfGetCompanyDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "companyStatus" in value;
    isInstance = isInstance && "products" in value;
    isInstance = isInstance && "components" in value;
    isInstance = isInstance && "users" in value;

    return isInstance;
}

export function GetCompanyDtoFromJSON(json: any): GetCompanyDto {
    return GetCompanyDtoFromJSONTyped(json, false);
}

export function GetCompanyDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetCompanyDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'companyStatus': json['companyStatus'],
        'products': ((json['products'] as Array<any>).map(ProductIdDtoFromJSON)),
        'components': ((json['components'] as Array<any>).map(ComponentIdDtoFromJSON)),
        'users': ((json['users'] as Array<any>).map(UserIdNameDtoFromJSON)),
    };
}

export function GetCompanyDtoToJSON(value?: GetCompanyDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'companyStatus': value.companyStatus,
        'products': ((value.products as Array<any>).map(ProductIdDtoToJSON)),
        'components': ((value.components as Array<any>).map(ComponentIdDtoToJSON)),
        'users': ((value.users as Array<any>).map(UserIdNameDtoToJSON)),
    };
}

