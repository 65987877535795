import { type InputTypeHeader } from 'forms/ApiHeaderParameterForm/ApiHeaderParameterForm';

export type BooleanEnum = 'true' | 'false' | 'null';

export class ApiHeaderParameter {
  id: string;

  name: string;

  isMandatory: BooleanEnum;

  valueType: InputTypeHeader;

  defaultValue: string;
}
