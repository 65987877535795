import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import uuid from 'react-uuid';

import { ApiBodyForm, type InputTypeBody } from 'forms/ApiBodyForm/ApiBodyForm';
import { ApiHeaderParameterForm } from 'forms/ApiHeaderParameterForm/ApiHeaderParameterForm';
import { ApiPathParameterForm } from 'forms/ApiPathParameterForm/ApiPathParameterForm';
import { ApiQueryParameterForm } from 'forms/ApiQueryParameterForm/ApiQueryParameterForm';

import { Text } from 'components/core/Text/Text';
import { Title } from 'components/core/Title/Title';
import { type ResourceHandler, useResourceHandler } from 'components/specks/Widgets/ApiWidget';

import { type Body } from 'models/API/Body.entity';
import { type ApiHeaderParameter } from 'models/API/Parameters/apiHeaderParameter.entity';
import { type ApiPathParameter } from 'models/API/Parameters/apiPathParameter.entity';
import { type ApiQueryParameter } from 'models/API/Parameters/apiQueryParameter.entity';

import concatClassNames from 'utils/classNames';
import getIcon from 'utils/getIcon';

import { ComponentBackEndApiDetailBodyRow } from './BodyRow';
import { ComponentBackEndApiDetailHeaderParameterRow } from './ParameterRow/HeaderParameterRow';
import { ComponentBackEndApiDetailPathParameterRow } from './ParameterRow/PathParameterRow';
import { ComponentBackEndApiDetailQueryParameterRow } from './ParameterRow/QueryParameterRow';
import { ComponentBackEndApiDetailSection } from './Section';

type ComponentBackEndApiDetailParametersProps =
  | {
      pathParameters: ApiPathParameter[];
      queryParameters: ApiQueryParameter[];
      headerParameters: ApiHeaderParameter[];
      bodyParameters: Body[];
      methodId: string;
      createNewPathParameter: (parameters: ApiPathParameter, update?: boolean) => void;
      createNewQueryParameter: (parameters: ApiQueryParameter, update?: boolean) => void;
      createNewHeaderParameter: (parameters: ApiHeaderParameter, update?: boolean) => void;
      updateBodyParameters: (newBodyParameters: Body[]) => void;
      isEditable: boolean;
    }
  | {
      pathParameters?: never;
      queryParameters?: never;
      headerParameters?: never;
      bodyParameters?: never;
      methodId?: never;
      createNewPathParameter?: never;
      createNewQueryParameter?: never;
      createNewHeaderParameter?: never;
      updateBodyParameters?: never;
      isEditable?: false;
    };

// Reminder (see ComponentBackEndApiDetailResponseRow for same code but with no default array)
// in ComponentBackEndApiDetailResponseRow, a default array will trigger the useEffect everytime
export function ComponentBackEndApiDetailParameters({
  pathParameters = [],
  queryParameters = [],
  headerParameters = [],
  bodyParameters,
  methodId,
  createNewPathParameter,
  createNewQueryParameter,
  createNewHeaderParameter,
  updateBodyParameters,
  isEditable = false,
}: ComponentBackEndApiDetailParametersProps): JSX.Element {
  /* --------------------------------------------------- contexts --------------------------------------------------- */

  const resourceHandler: ResourceHandler = useResourceHandler();

  /* ---------------------------------------------------- states ---------------------------------------------------- */

  const [bodyParam, setBodyParams] = useState<Body[] | undefined>(bodyParameters);
  const [actualBodyParameters, setActualBodyParameters] = useState<Body[] | undefined>(bodyParameters);
  const [parentBodyType, setparentBodyType] = useState<InputTypeBody>('STRING');
  const [bodyParametersPathIds, setBodyParametersPathIds] = useState<string[]>([]);

  /* ----------------------------------------------------- setup ---------------------------------------------------- */

  // Close the forms when the method change
  useEffect(() => {
    if (resourceHandler.details === undefined) return;
    resourceHandler.details.closeAllFormsExcept();
  }, [methodId]);

  // update the parameters when adding a new one
  useEffect(() => {
    setBodyParams(bodyParameters);
  }, [bodyParameters]);

  // Close the forms when leaving the edit mode
  useEffect(() => {
    if (!isEditable) {
      if (resourceHandler.details === undefined) return;
      resourceHandler.details.closeAllFormsExcept();
    }
  }, [isEditable]);

  // Reset the body parameters path when the method change
  useEffect(() => {
    setBodyParametersPathIds([]);
  }, [methodId]);

  useEffect(() => {
    function findActualBodyParameter(body: Body[], path: string[]): Body[] | undefined {
      if (path.length === 0) return body;
      const [currentPath, ...nextPath] = path;
      const currentBody: Body | undefined = body.find((bodyParameter: Body) => bodyParameter.id === currentPath);
      if (currentBody === undefined) return undefined;
      return findActualBodyParameter(currentBody.children, nextPath);
    }

    function findParentBodyType(body: Body[], path: string[]): InputTypeBody {
      if (path.length === 1)
        return body.find((bodyParameter: Body) => bodyParameter.id === path[0])?.valueType ?? 'STRING';
      const [currentPath, ...nextPath] = path;
      const currentBody: Body | undefined = body.find((bodyParameter: Body) => bodyParameter.id === currentPath);
      if (currentBody === undefined) return 'STRING';
      return findParentBodyType(currentBody.children, nextPath);
    }

    setparentBodyType(findParentBodyType(bodyParam ?? [], bodyParametersPathIds));
    const tmp: Body[] = findActualBodyParameter(bodyParam ?? [], bodyParametersPathIds) ?? [];
    setActualBodyParameters(tmp);
  }, [bodyParam, bodyParametersPathIds]);

  /* ------------------------------------------------- Move to forms ------------------------------------------------ */

  useEffect(() => {
    if (resourceHandler.details === undefined) return;
    if (!resourceHandler.details.isNewPathFormOpened) return;
    const formElement: HTMLElement | null = document.getElementById('api-path-parameter-form');
    if (formElement != null) formElement.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
  }, [resourceHandler]);

  useEffect(() => {
    if (resourceHandler.details === undefined) return;
    if (resourceHandler.details.isNewQueryFormOpened === undefined) return;
    const formElement: HTMLElement | null = document.getElementById('api-query-parameter-form');
    if (formElement != null) formElement.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
  }, [resourceHandler]);

  useEffect(() => {
    if (resourceHandler.details === undefined) return;
    if (resourceHandler.details.isNewHeaderFormOpened === undefined) return;
    const formElement: HTMLElement | null = document.getElementById('api-header-parameter-form');
    if (formElement != null) formElement.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
  }, [resourceHandler]);

  useEffect(() => {
    if (resourceHandler.details === undefined) return;
    if (resourceHandler.details.isNewBodyFormOpened === undefined) return;
    const formElement: HTMLElement | null = document.getElementById('api-body-form');
    if (formElement != null) formElement.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
  }, [resourceHandler]);

  /* --------------------------------------------------- functions -------------------------------------------------- */

  function _createTextBreadCrumb(content: string): JSX.Element {
    return <Text key={uuid()} size="api" weight="light" color="purple-500" content={content} position="center" />;
  }

  function findNameofCurrentBodyParameter(
    body: Body[],
    path: string[],
    depth: number = 1,
    parentValueType: InputTypeBody | undefined = undefined,
  ): JSX.Element | JSX.Element[] {
    if (depth === 4) return _createTextBreadCrumb('...');
    if (path.length === 0) return _createTextBreadCrumb('Body');
    if (path.length === depth) {
      const res: JSX.Element[] = [];
      const currentBody: Body | undefined = body.find((bodyParameter: Body) => bodyParameter.id === path[0]);
      const name: string = currentBody?.name ?? '';
      if (currentBody?.valueType === 'LIST') {
        const childBody: Body | undefined = currentBody.children[0];
        if (parentValueType !== 'LIST')
          res.push(_createTextBreadCrumb(`${name} : Liste de [ ${childBody === undefined ? '' : childBody.name} ]`));
        else res.push(_createTextBreadCrumb(`Liste de [ ${childBody === undefined ? '' : childBody.name} ]`));
      } else {
        res.push(_createTextBreadCrumb(name));
      }
      return res;
    }
    const [currentPath, ...nextPath] = path;
    const currentBody: Body | undefined = body.find((bodyParameter: Body) => bodyParameter.id === currentPath);
    if (currentBody === undefined) return _createTextBreadCrumb('');
    return findNameofCurrentBodyParameter(currentBody.children, nextPath, depth, currentBody.valueType);
  }

  function insertIntoBodyParameters(body: Body[], path: string[], newBodyParameter: Body): Body[] {
    if (path.length === 0) {
      return [...body, newBodyParameter];
    }
    const [currentPath, ...nextPath] = path;
    const currentBody: Body | undefined = body.find((bodyParameter: Body) => bodyParameter.id === currentPath);
    if (currentBody === undefined) {
      return body;
    }
    return body.map((bodyParameter: Body) => {
      if (bodyParameter.id === currentPath) {
        return {
          ...bodyParameter,
          children: insertIntoBodyParameters(bodyParameter.children, nextPath, newBodyParameter),
        };
      }
      return bodyParameter;
    });
  }

  function updateIntoBodyParameters(body: Body[], path: string[], newBodyParameter: Body): Body[] {
    if (path.length === 0) {
      return body.map((bodyParameter: Body) => {
        if (bodyParameter.id === newBodyParameter.id) {
          return newBodyParameter;
        }
        return bodyParameter;
      });
    }
    const [currentPath, ...nextPath] = path;
    const currentBody: Body | undefined = body.find((bodyParameter: Body) => bodyParameter.id === currentPath);
    if (currentBody === undefined) {
      return body;
    }
    return body.map((bodyParameter: Body) => {
      if (bodyParameter.id === currentPath) {
        return {
          ...bodyParameter,
          children: updateIntoBodyParameters(bodyParameter.children, nextPath, newBodyParameter),
        };
      }
      return bodyParameter;
    });
  }

  function addPathParameter(): void {
    if (resourceHandler.details === undefined) return;
    resourceHandler.details.closeAllFormsExcept();
    resourceHandler.details.setIsNewPathFormOpened(true);
  }

  function addQueryParameter(): void {
    if (resourceHandler.details === undefined) return;
    resourceHandler.details.closeAllFormsExcept();
    resourceHandler.details.setIsNewQueryFormOpened(true);
  }

  function addHeaderParameter(): void {
    if (resourceHandler.details === undefined) return;
    resourceHandler.details.closeAllFormsExcept();
    resourceHandler.details.setIsNewHeaderFormOpened(true);
  }

  function addBodyParameter(): void {
    if (resourceHandler.details === undefined) return;
    resourceHandler.details.closeAllFormsExcept();
    resourceHandler.details.setIsNewBodyFormOpened(true);
  }

  function handleOnClickConfirmNewPathParameter(newApiParameter: ApiPathParameter): void {
    if (createNewPathParameter === undefined) return;
    if (resourceHandler.details === undefined) return;
    resourceHandler.details.setMutateStatus('loading');
    resourceHandler.details.successMutateMessage = "Les paramètres d'URL ont été créé avec succès";
    resourceHandler.details.errorMutateMessage = "Une erreur est survenue pendant la création des paramètres d'URL";
    createNewPathParameter(newApiParameter);
  }

  function handleOnClickConfirmUpdatePathParameter(newApiParameter: ApiPathParameter): void {
    if (createNewPathParameter === undefined) return;
    if (resourceHandler.details === undefined) return;
    resourceHandler.details.setMutateStatus('loading');
    resourceHandler.details.successMutateMessage = "Les paramètres d'URL ont été mis à jour avec succès";
    resourceHandler.details.errorMutateMessage = "Une erreur est survenue pendant la mise à jour des paramètres d'URL";
    createNewPathParameter(newApiParameter, true);
  }

  function handleOnClickConfirmNewQueryParameter(newApiParameter: ApiQueryParameter): void {
    if (resourceHandler.details === undefined) return;
    resourceHandler.details.setMutateStatus('loading');
    resourceHandler.details.successMutateMessage = 'Les paramètres de requête ont été créé avec succès';
    resourceHandler.details.errorMutateMessage =
      'Une erreur est survenue pendant la création des paramètres de requête';
    createNewQueryParameter?.(newApiParameter);
  }

  function handleOnClickConfirmUpdateQueryParameter(newApiParameter: ApiQueryParameter): void {
    if (resourceHandler.details === undefined) return;
    resourceHandler.details.setMutateStatus('loading');
    resourceHandler.details.successMutateMessage = 'Les paramètres de requête ont été mis à jour avec succès';
    resourceHandler.details.errorMutateMessage =
      'Une erreur est survenue pendant la mise à jour des paramètres de requête';
    createNewQueryParameter?.(newApiParameter, true);
  }

  function handleOnClickConfirmNewHeaderParameter(newApiParameter: ApiHeaderParameter): void {
    if (resourceHandler.details === undefined) return;
    resourceHandler.details.setMutateStatus('loading');
    resourceHandler.details.successMutateMessage = "L'en-tête de la requête a été créé avec succès";
    resourceHandler.details.errorMutateMessage =
      "Une erreur est survenue pendant la création de l'en-tête de la requête";
    createNewHeaderParameter?.(newApiParameter);
  }

  function handleOnClickConfirmUpdateHeaderParameter(newApiParameter: ApiHeaderParameter): void {
    if (resourceHandler.details === undefined) return;
    resourceHandler.details.setMutateStatus('loading');
    resourceHandler.details.successMutateMessage = "L'en-tête de la requête a été mis à jour avec succès";
    resourceHandler.details.errorMutateMessage =
      "Une erreur est survenue pendant la mise a jour de l'en-tête de la requête";
    createNewHeaderParameter?.(newApiParameter, true);
  }

  function handleOnClickConfirmNewBodyParameter(newRow: Body): void {
    if (parentBodyType === 'LIST' && actualBodyParameters?.length !== 0) {
      toast.error("Une liste d'objet ne peut avoir qu'un seul objet");
      return;
    }
    if (resourceHandler.details === undefined) return;
    resourceHandler.details.successMutateMessage = 'Le paramètre du corps de la requète a été créé avec succès';
    resourceHandler.details.errorMutateMessage =
      "Une erreur est survenue pendant la création d'un paramètre du corps de la requète";
    resourceHandler.details.setMutateStatus('loading');
    const newBodyParameters: Body[] = insertIntoBodyParameters(bodyParameters ?? [], bodyParametersPathIds, newRow);
    updateBodyParameters?.(newBodyParameters);
  }

  function handleOnClickConfirmUpdateBodyRow(newRow: Body): void {
    if (newRow.valueType === 'LIST' && newRow.children.length > 1) {
      toast.error("Vous ne pouvez pas transformer cet objet en liste car cet objet contient plus d'un élément.");
      return;
    }
    if (resourceHandler.details === undefined) return;
    resourceHandler.details.setMutateStatus('loading');
    const newBodyParameters: Body[] = updateIntoBodyParameters(bodyParameters ?? [], bodyParametersPathIds, newRow);
    updateBodyParameters?.(newBodyParameters);
  }

  function handleOnClickCancelParameter(): void {
    if (resourceHandler.details === undefined) return;
    resourceHandler.details.closeAllFormsExcept();
  }

  /* -------------------------------------------- JSX get body breadcrumb ------------------------------------------- */

  function _getBodyBreadcrumb(index: number): JSX.Element {
    const res: JSX.Element[] = [];
    if (bodyParametersPathIds.length === index || (bodyParametersPathIds.length > 4 && index === 4)) {
      res.push(
        <div
          key={uuid()}
          className={concatClassNames('flex flex-row items-start px-2 mb-1 gap-2 cursor-pointer')}
          onClick={() => {
            setBodyParametersPathIds([]);
          }}
        >
          <Text key={uuid()} size="api" weight="light" color="purple-500" content={'Body'} position="center" />
        </div>,
      );
      res.push(<div key={uuid()}>{getIcon('chevronRight', 'gray', 'sm')}</div>);
    }
    if (bodyParametersPathIds.length >= index) {
      res.push(
        <div
          key={uuid()}
          className={concatClassNames('flex flex-row items-start px-2 mb-1 gap-2', index === 1 ? '' : 'cursor-pointer')}
          onClick={
            index === 1
              ? undefined
              : () => {
                  setBodyParametersPathIds(bodyParametersPathIds.slice(0, -(index - 1)));
                }
          }
        >
          {findNameofCurrentBodyParameter(bodyParameters ?? [], bodyParametersPathIds, index)}
        </div>,
      );
      if (index !== 1) res.push(<div key={uuid()}>{getIcon('chevronRight', 'gray', 'sm')}</div>);
    }
    return <React.Fragment key={uuid()}>{res}</React.Fragment>;
  }

  function getBodyBreadcrumbs(): JSX.Element {
    const res: JSX.Element[] = [];
    if (bodyParametersPathIds.length === 0 && actualBodyParameters?.length === 0) {
      return <></>;
    }
    if (bodyParametersPathIds.length === 0) {
      return (
        <div key={uuid()} className={concatClassNames('flex flex-row items-start px-1 mb-1 gap-2')}>
          <Text size="api" weight="light" color="purple-500" content={'Body :'} position="center" />
        </div>
      );
    }
    res.push(
      <div
        key={uuid()}
        className={concatClassNames(
          'bg-white',
          'h-fit',
          'rounded-full',
          'border-1',
          'border-gray-50',
          'cursor-pointer',
        )}
        onClick={() => {
          setBodyParametersPathIds(bodyParametersPathIds.slice(0, -1));
        }}
      >
        {getIcon('chevronLeft', 'gray', 'sm')}
      </div>,
    );
    res.push(_getBodyBreadcrumb(4));
    res.push(_getBodyBreadcrumb(3));
    res.push(_getBodyBreadcrumb(2));
    res.push(_getBodyBreadcrumb(1));
    return (
      <div key={uuid()} className={concatClassNames('flex flex-row', 'gap-1')}>
        {res}
      </div>
    );
  }

  /* ------------------------------------------------------ JSX ----------------------------------------------------- */
  // TODO : Add Error state
  return (
    <div className={concatClassNames('flex flex-col', 'gap-1')}>
      <Title content="Paramètres d'appel" size="h2" />
      {/* URL Parameter */}
      <ComponentBackEndApiDetailSection
        resourceId=""
        title="Paramètres d'URL"
        onClickAddInSection={isEditable ? addPathParameter : null}
        description="Path Parameters"
      >
        <div className={concatClassNames('flex flex-col', 'gap-1', 'flex-grow')}>
          {pathParameters.length === 0 &&
          resourceHandler.details !== undefined &&
          !resourceHandler.details.isNewPathFormOpened ? (
            <Text
              size="api"
              weight="light"
              color="gray-200"
              position="left"
              content="Il n'y a pas de paramètre d'URL pour cette méthode."
            />
          ) : (
            <ComponentBackEndApiDetailPathParameterRow title />
          )}
          {pathParameters.length > 0 && (
            <div className={concatClassNames('flex flex-col', 'gap-1', 'w-full')}>
              {pathParameters.map((parameter: ApiPathParameter) => {
                if (
                  resourceHandler.details?.pathIdUpdateFormOpened !== undefined &&
                  parameter.id === resourceHandler.details.pathIdUpdateFormOpened
                )
                  return (
                    <ApiPathParameterForm
                      key={uuid()}
                      onSubmit={handleOnClickConfirmUpdatePathParameter}
                      onCancel={handleOnClickCancelParameter}
                      existingPathParameters={pathParameters}
                      oldPathParameter={
                        resourceHandler.details !== undefined
                          ? resourceHandler.details.pathData ?? parameter
                          : parameter
                      }
                    />
                  );
                return (
                  <ComponentBackEndApiDetailPathParameterRow
                    key={uuid()}
                    id={parameter.id}
                    name={parameter.name}
                    type={parameter.valueType}
                    isRequired={parameter.isMandatory}
                    defaultValue={parameter.defaultValue}
                    isEditing={isEditable}
                  />
                );
              })}
            </div>
          )}
          {resourceHandler.details?.isNewPathFormOpened && (
            <ApiPathParameterForm
              onSubmit={handleOnClickConfirmNewPathParameter}
              onCancel={handleOnClickCancelParameter}
              existingPathParameters={pathParameters}
            />
          )}
        </div>
      </ComponentBackEndApiDetailSection>
      {/* Query Parameter */}
      <ComponentBackEndApiDetailSection
        resourceId=""
        title="Paramètres de requête"
        onClickAddInSection={isEditable ? addQueryParameter : null}
        description="Query Parameters"
      >
        <div className={concatClassNames('flex flex-col', 'gap-1', 'w-full')}>
          {queryParameters.length === 0 &&
          resourceHandler.details !== undefined &&
          !resourceHandler.details.isNewQueryFormOpened ? (
            <Text
              size="api"
              weight="light"
              color="gray-200"
              position="left"
              content="Il n'y a pas de paramètre de requête pour cette méthode."
            />
          ) : (
            <ComponentBackEndApiDetailQueryParameterRow title />
          )}
          {queryParameters.length > 0 && (
            <div className={concatClassNames('flex flex-col', 'gap-1', 'w-full')}>
              {queryParameters.map((parameter: ApiQueryParameter) => {
                if (
                  resourceHandler.details?.queryIdUpdateFormOpened !== undefined &&
                  parameter.id === resourceHandler.details.queryIdUpdateFormOpened
                )
                  return (
                    <ApiQueryParameterForm
                      key={uuid()}
                      onSubmit={handleOnClickConfirmUpdateQueryParameter}
                      onCancel={handleOnClickCancelParameter}
                      existingQueryParameters={queryParameters}
                      oldQueryParameter={
                        resourceHandler.details !== undefined
                          ? resourceHandler.details.queryData ?? parameter
                          : parameter
                      }
                    />
                  );
                return (
                  <ComponentBackEndApiDetailQueryParameterRow
                    key={uuid()}
                    id={parameter.id}
                    name={parameter.name}
                    type={parameter.valueType}
                    isRequired={parameter.isMandatory}
                    defaultValue={parameter.defaultValue}
                    isEditing={isEditable}
                    onRowUpdate={(id: string) => {
                      if (resourceHandler.details === undefined) return;
                      resourceHandler.details.closeAllFormsExcept(id);
                      resourceHandler.details.setQueryIdUpdateFormOpened(id);
                    }}
                  />
                );
              })}
            </div>
          )}
          {resourceHandler.details?.isNewQueryFormOpened && (
            <ApiQueryParameterForm
              onSubmit={handleOnClickConfirmNewQueryParameter}
              onCancel={handleOnClickCancelParameter}
              existingQueryParameters={queryParameters}
            />
          )}
        </div>
      </ComponentBackEndApiDetailSection>
      {/* Header Parameter */}
      <ComponentBackEndApiDetailSection
        resourceId=""
        title="En-tête de la requête"
        onClickAddInSection={isEditable ? addHeaderParameter : null}
        description="Header"
      >
        <div className={concatClassNames('flex flex-col', 'gap-1', 'w-full')}>
          {headerParameters.length === 0 &&
          resourceHandler.details !== undefined &&
          !resourceHandler.details.isNewHeaderFormOpened ? (
            <Text
              size="api"
              weight="light"
              color="gray-200"
              position="left"
              content="Il n'y a pas de paramètre d'en-tête de requête pour cette méthode."
            />
          ) : (
            <ComponentBackEndApiDetailHeaderParameterRow title />
          )}
          {headerParameters.length > 0 && (
            <div className={concatClassNames('flex flex-col', 'gap-1', 'w-full')}>
              {headerParameters.map((parameter: ApiHeaderParameter) => {
                if (
                  resourceHandler.details?.headerIdUpdateFormOpened !== undefined &&
                  parameter.id === resourceHandler.details.headerIdUpdateFormOpened
                )
                  return (
                    <ApiHeaderParameterForm
                      key={uuid()}
                      onSubmit={handleOnClickConfirmUpdateHeaderParameter}
                      onCancel={handleOnClickCancelParameter}
                      exisstingHeaderParameters={headerParameters}
                      oldHeaderParameter={
                        resourceHandler.details !== undefined
                          ? resourceHandler.details.headerData ?? parameter
                          : parameter
                      }
                    />
                  );
                return (
                  <ComponentBackEndApiDetailHeaderParameterRow
                    id={parameter.id}
                    key={uuid()}
                    name={parameter.name}
                    type={parameter.valueType}
                    isRequired={parameter.isMandatory}
                    defaultValue={parameter.defaultValue}
                    isEditing={isEditable}
                    onRowUpdate={(id: string) => {
                      if (resourceHandler.details === undefined) return;
                      resourceHandler.details.closeAllFormsExcept(id);
                      resourceHandler.details.setHeaderIdUpdateFormOpened(id);
                    }}
                  />
                );
              })}
            </div>
          )}
          {resourceHandler.details?.isNewHeaderFormOpened && (
            <ApiHeaderParameterForm
              onSubmit={handleOnClickConfirmNewHeaderParameter}
              onCancel={handleOnClickCancelParameter}
              exisstingHeaderParameters={headerParameters}
            />
          )}
        </div>
      </ComponentBackEndApiDetailSection>
      {/* Body Parameter */}
      <ComponentBackEndApiDetailSection
        resourceId=""
        title="Corps de la requête"
        onClickAddInSection={
          isEditable && (actualBodyParameters?.length === 0 || parentBodyType !== 'LIST') ? addBodyParameter : null
        }
        description="Body"
      >
        <div className={concatClassNames('flex flex-col', 'gap-1', 'w-full')}>
          {bodyParameters?.length === 0 &&
          resourceHandler.details !== undefined &&
          !resourceHandler.details.isNewBodyFormOpened ? (
            <Text
              size="api"
              weight="light"
              color="gray-200"
              position="left"
              content="Il n'y a pas de paramètre de corps de requête pour cette méthode."
            />
          ) : (
            <>
              {getBodyBreadcrumbs()}
              {actualBodyParameters?.length === 0 &&
              resourceHandler.details !== undefined &&
              resourceHandler.details.isNewBodyFormOpened ? (
                <Text size="api" weight="light" color="gray-200" content="Cet objet ne contient pas d'attribut." />
              ) : (
                <ComponentBackEndApiDetailBodyRow title />
              )}
            </>
          )}
          {(actualBodyParameters?.length ?? 0) > 0 && (
            <div className={concatClassNames('flex flex-col', 'gap-1', 'w-full')}>
              {actualBodyParameters?.map((bodyParameter: Body) => {
                if (
                  resourceHandler.details?.bodyIdUpdateFormOpened !== undefined &&
                  bodyParameter.id === resourceHandler.details.bodyIdUpdateFormOpened
                )
                  return (
                    <ApiBodyForm
                      key={uuid()}
                      onSubmit={handleOnClickConfirmUpdateBodyRow}
                      onCancel={handleOnClickCancelParameter}
                      existingBodyParameters={actualBodyParameters ?? []}
                      oldBodyParameter={
                        resourceHandler.details !== undefined
                          ? resourceHandler.details.bodyData ?? bodyParameter
                          : bodyParameter
                      }
                    />
                  );
                return (
                  <ComponentBackEndApiDetailBodyRow
                    key={uuid()}
                    id={bodyParameter.id}
                    path={bodyParametersPathIds}
                    body={bodyParameter}
                    isEditing={isEditable}
                    onRowUpdate={(id: string) => {
                      if (resourceHandler.details === undefined) return;
                      resourceHandler.details.closeAllFormsExcept(id);
                      resourceHandler.details.setBodyIdUpdateFormOpened(id);
                    }}
                    onClickObject={() => {
                      setBodyParametersPathIds([...bodyParametersPathIds, bodyParameter.id]);
                    }}
                  />
                );
              })}
            </div>
          )}
          {resourceHandler.details?.isNewBodyFormOpened &&
            (actualBodyParameters?.length === 0 || parentBodyType !== 'LIST') && (
              <ApiBodyForm
                onSubmit={handleOnClickConfirmNewBodyParameter}
                onCancel={handleOnClickCancelParameter}
                existingBodyParameters={actualBodyParameters ?? []}
              />
            )}
        </div>
      </ComponentBackEndApiDetailSection>
    </div>
  );
}
