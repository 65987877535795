import React from 'react';
import { type Location, type NavigateFunction, useLocation, useNavigate } from 'react-router-dom';

import { useAuth0 } from '@auth0/auth0-react';

import { UserAvatar } from 'components/specks/UserAvatar/UserAvatar';

import concatClassNames from 'utils/classNames';

import Specks from 'icons/Specks';

import { MenuButton } from './MenuButton';

const href: any = {
  Produits: '/products',
  Composants: '/components',
  Accueil: '/home',
};
export function Menu(): JSX.Element {
  const location: Location = useLocation();
  const currentPath: string = location.pathname;
  const pathSegments: string[] = currentPath.split('/');
  let selectedButton: string = '';
  const { getAccessTokenSilently, user } = useAuth0();

  switch (pathSegments[1]) {
    case '':
      selectedButton = 'Accueil';
      break;
    case 'product':
    case 'products':
      selectedButton = 'Produits';
      break;
    case 'component':
    case 'components':
      selectedButton = 'Composants';
      break;
  }

  const navigate: NavigateFunction = useNavigate();
  function handleLogoClick(): void {
    navigate('/home');
  }
  function handleMenuButtonClick(id: string): void {
    switch (id) {
      case 'Produits':
        navigate(href.Produits);
        break;
      case 'Composants':
        navigate(href.Composants);
        break;
      case 'Accueil':
        navigate(href.Accueil);
        break;
    }
  }

  return (
    <div
      className={concatClassNames(
        'flex flex-col justify-between',
        'w-14 min-h-full',
        'bg-gradient-to-b from-gradient1-from to-gradient1-to',
        'items-center',
      )}
    >
      <div className="flex flex-col p-8 gap-24 items-center">
        <button onClick={handleLogoClick}>
          <div
            className={concatClassNames(
              'flex flex-col justify-center items-center',
              'w-8 h-8',
              'p-1.5 gap-1.5',
              'bg-white rounded-2xl',
            )}
          >
            <Specks size="md" color="black" />
          </div>
        </button>
        <div className="flex flex-col items-center p-0 gap-14 w-11 h-full">
          <MenuButton
            id="Accueil"
            description="Accueil"
            iconName="home"
            isSelected={selectedButton === 'Accueil' && true}
            onClick={handleMenuButtonClick}
          />
          <MenuButton
            id="Produits"
            description="Produits"
            iconName="product"
            isSelected={selectedButton === 'Produits' && true}
            onClick={handleMenuButtonClick}
          />
          <MenuButton
            id="Composants"
            description="Composants applicatifs"
            iconName="component"
            isSelected={selectedButton === 'Composants' && true}
            onClick={handleMenuButtonClick}
          />
        </div>
      </div>

      <div className=" flex flex-col items-center">
        {user != null ? (
          <>
            <UserAvatar size="sm" imgUrl={user.picture} name={user.name} email={user.email} />
          </>
        ) : (
          <UserAvatar size="sm" loading />
        )}
      </div>
    </div>
  );
}
