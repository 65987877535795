/* tslint:disable */
/* eslint-disable */
/**
 * Specks API
 * APIs for specks SaaS
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { GetComponentWithPagesDto } from './GetComponentWithPagesDto';
import {
    GetComponentWithPagesDtoFromJSON,
    GetComponentWithPagesDtoFromJSONTyped,
    GetComponentWithPagesDtoToJSON,
} from './GetComponentWithPagesDto';

/**
 * 
 * @export
 * @interface GetComponentsDto
 */
export interface GetComponentsDto {
    /**
     * 
     * @type {Array<GetComponentWithPagesDto>}
     * @memberof GetComponentsDto
     */
    components: Array<GetComponentWithPagesDto>;
}

/**
 * Check if a given object implements the GetComponentsDto interface.
 */
export function instanceOfGetComponentsDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "components" in value;

    return isInstance;
}

export function GetComponentsDtoFromJSON(json: any): GetComponentsDto {
    return GetComponentsDtoFromJSONTyped(json, false);
}

export function GetComponentsDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetComponentsDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'components': ((json['components'] as Array<any>).map(GetComponentWithPagesDtoFromJSON)),
    };
}

export function GetComponentsDtoToJSON(value?: GetComponentsDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'components': ((value.components as Array<any>).map(GetComponentWithPagesDtoToJSON)),
    };
}

