import React, { useState } from 'react';
import { Handle, Position } from 'reactflow';

import { ExternalApiBadge } from 'components/core/ExternalApiBadge/ExternalApiBadge';
import { Text, type TextColor } from 'components/core/Text/Text';

import { type IAppComponentType, componentTypes } from 'models/ComponentTypes.type';
import { type Status } from 'models/Status.types';

import concatClassNames from 'utils/classNames';
import getIcon from 'utils/getIcon';
import getStatusChip from 'utils/getStatusChip';

import { type IconColor, type IconName } from 'icons/Icon.props';

export interface INodeData {
  _identifier?: string;
  name: string;
  status: string;
  type: string;
  isDragging: boolean;
  isEditable?: boolean;
  componentId: string;
  onRightClickOnNode: (event: any, id: string) => void | void;
  isInternal: boolean;
}

interface AppSchemaNodeProps {
  data: INodeData;
}

function AppSchemaNode({ data }: AppSchemaNodeProps): JSX.Element {
  const [isHandlesShown, setIsHandlesShown] = useState<boolean>(false);

  const componentType: IAppComponentType | undefined = componentTypes.get(data.type);
  if (componentType == null) throw new Error(`Unknown component type: ${data.type}`);

  const icon: JSX.Element = getIcon(componentType.icon as IconName, componentType.color as IconColor, 'md');
  const statusChip: JSX.Element = getStatusChip({
    name: data.status as Status,
    iconSize: 'xxs',
    textSize: 'xxs',
    paddingHorizontal: 'sm',
  });

  function handleMouseEnter(): void {
    if (data.isEditable === false) return;
    setIsHandlesShown(true);
  }

  function handleMouseLeave(): void {
    setIsHandlesShown(false);
  }

  return (
    <>
      <div
        id={data._identifier}
        className={concatClassNames('flex flex-col', 'gap-3')}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onContextMenu={(e) => {
          data.onRightClickOnNode(e, data.componentId);
        }}
      >
        <div
          className={concatClassNames(
            'rounded-full',
            'w-36 h-36',
            'p-1',
            'bg-gradient-to-b from-gradient1-from to-gradient1-to',
          )}
        >
          <div
            className={concatClassNames(
              'flex flex-col',
              'h-full w-full',
              'gap-1',
              'items-center justify-center',
              'bg-white rounded-full',
            )}
          >
            {icon}
            <div className={concatClassNames('flex flex-col items-center')}>
              <Text content={data.name} size="xs" position="center" />
              <Text
                content={componentType.fullName}
                color={componentType.color as TextColor}
                size="xs"
                position="center"
              />
              {!data.isInternal && <ExternalApiBadge paddingHorizontal="sm" textSize="xxxs" label="API EXTERNE" />}
            </div>
            {statusChip}
          </div>

          <Handle
            type="target"
            position={Position.Left}
            id={'left-input'}
            className={concatClassNames(
              'h-5 w-5',
              'border-4 border-white',
              'bg-gradient-to-r bg-gradient1-from',
              '-left-3',
              isHandlesShown || data.isDragging ? 'visible' : 'invisible',
            )}
          />
          <Handle
            type="target"
            position={Position.Top}
            id={'top-input'}
            className={concatClassNames(
              'border-4 border-white h-5 w-5 bg-gradient-to-r bg-gradient1-from -top-3',
              isHandlesShown || data.isDragging ? 'visible' : 'invisible',
            )}
          />
          <Handle
            type="source"
            position={Position.Right}
            id={'right-output'}
            className={concatClassNames(
              'border-4 border-white h-5 w-5 bg-gradient-to-r bg-gradient1-from -right-3',
              isHandlesShown || data.isDragging ? 'visible' : 'invisible',
            )}
          />
          <Handle
            type="source"
            position={Position.Bottom}
            id={'bottom-output'}
            className={concatClassNames(
              'border-4 border-white h-5 w-5 bg-gradient-to-r bg-gradient1-from -bottom-3',
              isHandlesShown || data.isDragging ? 'visible' : 'invisible',
            )}
          />
        </div>
      </div>
    </>
  );
}

export default AppSchemaNode;
