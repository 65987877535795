import React, { useState } from 'react';

import { Text, type TextColor } from 'components/core/Text/Text';

import concatClassNames from 'utils/classNames';
import getIcon from 'utils/getIcon';

import ChevronDownIcon from 'icons/ChevronDownIcon';
import ChevronRightIcon from 'icons/ChevronRightIcon';

type ComponentBackEndApiDetailSectionProps =
  | {
      title: string;
      titleColor?: TextColor;
      description?: string;
      resourceId: string;
      isCollapsed?: boolean;
      onClickAddInSection: ((resourceId: string) => void) | null;
      children: JSX.Element | JSX.Element[];
      loading?: false;
    }
  | {
      title?: never;
      titleColor?: never;
      description?: never;
      resourceId?: never;
      isCollapsed?: never;
      onClickAddInSection?: never;
      children?: never;
      loading: true;
    };

export function ComponentBackEndApiDetailSection({
  title = '',
  titleColor = 'gray-300',
  description,
  resourceId,
  isCollapsed = false,
  onClickAddInSection,
  children,
  loading = false,
}: ComponentBackEndApiDetailSectionProps): JSX.Element {
  /* ---------------------------------------------------- states ---------------------------------------------------- */

  const [isCollapsedState, setIsCollapstedState] = useState<boolean>(isCollapsed);

  /* --------------------------------------------------- functions -------------------------------------------------- */

  function handleOnClick(): void {
    setIsCollapstedState(!isCollapsedState);
  }

  function handleOnClickAddMethod(e: any): void {
    e.stopPropagation();
    setIsCollapstedState(false);
    if (onClickAddInSection != null) onClickAddInSection(resourceId ?? '');
  }

  /* ------------------------------------------------------ JSX ----------------------------------------------------- */

  return (
    <div className={concatClassNames('flex flex-col', 'w-full', 'py-3', !isCollapsedState ? 'gap-4' : '')}>
      <div
        className={concatClassNames(
          'flex flex-row',
          'w-full',
          'justify-between',
          'border-b-1 border-gray-25',
          'cursor-pointer',
        )}
        onClick={handleOnClick}
      >
        {loading ? (
          <>
            <div className="flex flex-row items-center gap-1">
              <ChevronRightIcon color="gray" size="md" />
              <Text loading />
            </div>
            <div className="flex flex-row items-center gap-1" />
          </>
        ) : (
          <>
            <div className="flex flex-row items-center gap-1">
              {!isCollapsedState ? (
                <ChevronDownIcon color="gray" size="md" />
              ) : (
                <ChevronRightIcon color="gray" size="md" />
              )}
              <Text content={title} color={titleColor} weight="bold" size="base" position="left" />
              {description !== undefined && (
                <Text content={'(' + description + ')'} color="purple-400" position="center" />
              )}
            </div>
            <div className="flex flex-row items-center gap-1">
              {onClickAddInSection != null && (
                <div
                  className={concatClassNames('bg-white', 'p-0.5', 'mb-1', 'rounded-md', 'border-1 border-gray-50')}
                  onClick={handleOnClickAddMethod}
                >
                  {getIcon('plus', 'gray', 'sm')}
                </div>
              )}
              {onClickAddInSection == null && (
                <div
                  className={concatClassNames(
                    'bg-white',
                    'p-0.5',
                    'mb-1',
                    'rounded-md',
                    'border-1.5 border-transparent',
                  )}
                ></div>
              )}
            </div>
          </>
        )}
      </div>
      {!loading && !isCollapsedState && (
        <div className={concatClassNames('flex flex-row', 'w-full', !isCollapsedState ? '' : 'invisible')}>
          {children}
        </div>
      )}
    </div>
  );
}
