import * as Yup from 'yup';

import React from 'react';
import { type SubmitHandler, useForm } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';

import { Button } from 'components/core/Button/Button';
import Input from 'components/core/Input/Input';
import TextArea from 'components/core/TextArea/TextArea';

import { Page } from 'models/page.entity';
import { PageRevision } from 'models/pageRevision.entity';

interface CreatePersonaFormModel {
  name: string;
  age: string | undefined;

  description: string | undefined;

  likes: string | undefined;

  dislikes: string | undefined;
}

export class Persona {
  id: string;

  // createdAt: Date;

  // name: PersonaRevision[] = [];

  name: string | undefined;

  age: string | undefined;

  description: string | undefined;

  likes: string | undefined;

  dislikes: string | undefined;
}

const schema: Yup.ObjectSchema<CreatePersonaFormModel> = Yup.object().shape({
  name: Yup.string()
    .required('Champ obligatoire')
    .min(3, 'Le nom doit contenir au moins 3 caractères')
    .max(28, 'Le nom doit contenir au maximum 28 caractères')
    .test('isUnique', 'Un persona avec ce nom existe déjà', (value, context) => {
      const currentPersonae: Persona[] | undefined = context?.options?.context?.currentPersonae as Persona[];
      const currentPersona: Persona | undefined = context?.options?.context?.persona as Persona;
      return (
        value != null &&
        value.length > 0 &&
        !currentPersonae.some((persona) => persona.name === value && persona.id !== currentPersona?.id)
      );
    }),
  age: Yup.string(),
  description: Yup.string(),

  likes: Yup.string(),
  dislikes: Yup.string(),
});

type formContext = 'isEditing' | 'isCreating';

interface CreateorEditPersonaFormProps {
  onSubmit: (persona: Persona) => void;
  isEditing?: boolean;
  isLoading?: boolean;
  currentPersonae: Persona[];
  persona?: Persona;
}

export function CreateorEditPersonaForm({
  onSubmit,
  currentPersonae,
  persona,
  isEditing = false,
  isLoading = false,
}: CreateorEditPersonaFormProps): JSX.Element {
  const {
    register,
    watch,
    handleSubmit,
    formState: { errors, isSubmitted },
  } = useForm<CreatePersonaFormModel>({
    resolver: yupResolver(schema),
    context: { currentPersonae, persona },
    defaultValues: {
      name: isEditing && persona !== undefined ? persona?.name : '',
      description: isEditing && persona !== undefined ? persona?.description : '',
      age: isEditing && persona !== undefined ? persona?.age : '',
      likes: isEditing && persona !== undefined ? persona?.likes : '',
      dislikes: isEditing && persona !== undefined ? persona?.dislikes : '',
    },
  });

  const watchName: string = watch('name');

  let isFormValid: boolean = true;

  if (watchName === undefined || watchName.length < 3) {
    isFormValid = false;
  }

  const onSubmitForm: SubmitHandler<CreatePersonaFormModel> = (data) => {
    const persona: Persona = new Persona();
    persona.name = data.name;
    persona.age = data.age;
    persona.description = data.description;
    persona.likes = data.likes;
    persona.dislikes = data.dislikes;

    onSubmit(persona);
  };

  return (
    <form className="flex flex-col gap-6" onSubmit={handleSubmit(onSubmitForm)}>
      <Input
        label="Nom"
        placeholder="Quel nom générique souhaitez-vous donner à votre persona?"
        {...register('name')}
        maxLength={28}
        error={errors.name != null && isSubmitted ? errors.name.message : undefined}
      />
      <Input
        label="Age"
        placeholder="Quelle est sa tranche d'âge?"
        {...register('age')}
        error={errors.age != null && isSubmitted ? errors.age.message : undefined}
      />
      <TextArea
        label="Description"
        rows={3}
        placeholder="Décrivez ce persona (besoins principaux, objectifs personnels, défis...)"
        {...register('description')}
        error={errors.description != null && isSubmitted ? errors.description.message : undefined}
      />
      <TextArea
        label="Confort ❤️"
        rows={2}
        placeholder="Ce qu'elle aime le plus dans son quotidien, ou dans ses interactions avec des produits et services similaires"
        {...register('likes')}
        error={errors.likes != null && isSubmitted ? errors.likes.message : undefined}
      />
      <TextArea
        label=" Frustrations 😡"
        rows={2}
        placeholder="Ce qui la dérange ou la frustre le plus lorsqu'elle utilise des produits ou services similaires"
        {...register('dislikes')}
        error={errors.dislikes != null && isSubmitted ? errors.dislikes.message : undefined}
      />

      {/* <Input
        label="Fréquence"
        placeholder="fréquence d'apparition de ce persona"
        {...register('name')}
        error={errors.name != null && isSubmitted ? errors.name.message : undefined}
      /> */}
      <div className="flex flex-row">
        <Button content={isEditing ? 'Modifier' : 'Créer'} width="full" type="submit" disabled={!isFormValid} />
      </div>
    </form>
  );
}
