import { UiComponentTypeEnum } from 'api';

import React from 'react';

import { useDroppable } from '@dnd-kit/core';

import { UiComponentCard } from 'components/specks/UiComponentCard/UiComponentCard';

import concatClassNames from 'utils/classNames';

import SideMenu from '../SideMenu/SideMenu';
import { Title } from '../Title/Title';

interface UiComponentListMenuProps {
  name: string;
}

export function UiComponentListMenu({ name }: UiComponentListMenuProps): JSX.Element {
  /* ----------------------------------------------------- drop ----------------------------------------------------- */
  // prevent drop in this area

  const { setNodeRef } = useDroppable({
    id: `undroppable-zone`,
  });

  /* ------------------------------------------------------ JSX ----------------------------------------------------- */

  // TODO : Handle Loading
  // TODO : Handle Error
  return (
    <SideMenu width="md" ref={setNodeRef}>
      {/* <Title content={name} /> */}
      <div className={concatClassNames('flex flex-col', 'gap-6', 'overflow-auto')}>
        <UiComponentListMenuGrid name="Ajouter :">
          <UiComponentCard type="title" id={UiComponentTypeEnum.Title} horizontalMargin="none" />
          <UiComponentCard type="text" id={UiComponentTypeEnum.Text} horizontalMargin="none" />
          <UiComponentCard type="button" id={UiComponentTypeEnum.Button} horizontalMargin="none" />
          <UiComponentCard type="media" id={UiComponentTypeEnum.Media} horizontalMargin="none" />
          <UiComponentCard type="container" id={UiComponentTypeEnum.Container} horizontalMargin="none" />
          <UiComponentCard type="list" id={UiComponentTypeEnum.List} horizontalMargin="none" />
          <UiComponentCard type="icon" isDisabled isDraggable={false} horizontalMargin="none" />
        </UiComponentListMenuGrid>
        <UiComponentListMenuGrid name="Permettre à l'utilisateur de :">
          <UiComponentCard type="input" id={UiComponentTypeEnum.Input} horizontalMargin="none" />
          <UiComponentCard type="date" id={UiComponentTypeEnum.Date} horizontalMargin="none" />
          <UiComponentCard type="select" id={UiComponentTypeEnum.Select} horizontalMargin="none" />
        </UiComponentListMenuGrid>
      </div>
    </SideMenu>
  );
}
interface UiComponentListMenuGridProps {
  children: React.ReactNode;
  name?: string;
}

function UiComponentListMenuGrid({ children, name }: UiComponentListMenuGridProps): JSX.Element {
  return (
    <div className={concatClassNames('flex flex-col', 'py-1', 'gap-3')}>
      {name !== undefined && <Title content={name} size="h4" />}
      <div
        className={concatClassNames(
          'grid grid-cols-fill-fr grid-cols-[200px_minmax(96px, _1fr)_100px]',
          'gap-y-4 gap-x-4',
          'pb-1',
        )}
      >
        {children}
      </div>
    </div>
  );
}
