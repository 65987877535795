import React from 'react';
import uuid from 'react-uuid';

import { Text } from 'components/core/Text/Text';

import concatClassNames from 'utils/classNames';

import { ApiMethodRow } from './ApiMethodRow';
import { type ApiMethod } from './ApiMethodsWrapper';

interface ApiMethodSectionProps {
  title: string;
  methods: ApiMethod[];
  onMethodClick: (methodId: string) => void;
  selectedMethodId?: string;
}

export function ApiMethodSection({
  title,
  methods,
  onMethodClick,
  selectedMethodId,
}: ApiMethodSectionProps): JSX.Element {
  return (
    <div className={concatClassNames('flex flex-col', 'gap-1 flex-grow')}>
      <Text content={title} position="left" color="gray-300" />
      {methods.map((method: ApiMethod) => {
        return (
          <ApiMethodRow
            key={uuid()}
            methodId={method.id}
            path={method.path}
            type={method.type}
            description={method.description}
            onClick={onMethodClick}
            borderColored={selectedMethodId !== undefined && selectedMethodId === method.id}
          />
        );
      })}
    </div>
  );
}
