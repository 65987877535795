import { CompaniesApi, type CompanyCompanyStatusEnum, type GetCompaniesDto, type GetCompanyDto } from 'api';

import { type Company } from 'models/company.entity';

export class CompanyService {
  private readonly companyClient: CompaniesApi;

  constructor() {
    this.companyClient = new CompaniesApi();
  }

  public transformGetCompanyDtoToCompany(getCompanyDto: GetCompanyDto): Company {
    const company: Company = {
      id: getCompanyDto.id,
      name: getCompanyDto.name,
      companyStatus: getCompanyDto.companyStatus as CompanyCompanyStatusEnum,
    };
    return company;
  }

  public async getCompanyByUserId(accessToken: string): Promise<Company[]> {
    const companiesDto: GetCompaniesDto = await this.companyClient.companyControllerFindByUserId({
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
    });
    return companiesDto.companies.map((companyDto) => this.transformGetCompanyDtoToCompany(companyDto));
  }
}
