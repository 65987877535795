import { type Active, type Collision, closestCorners, pointerWithin, rectIntersection } from '@dnd-kit/core';
import { type DroppableContainer, type RectMap } from '@dnd-kit/core/dist/store/types';
import { type ClientRect, type Coordinates } from '@dnd-kit/core/dist/types';

export function localCollisionsAlgo(args: {
  active: Active;
  collisionRect: ClientRect;
  droppableRects: RectMap;
  droppableContainers: DroppableContainer[];
  pointerCoordinates: Coordinates | null;
}): Collision[] {
  // First, let's see if there are any collisions with the pointer
  const pointerCollisions: Collision[] = pointerWithin(args);

  // Collision detection algorithms return an array of collisions
  if (pointerCollisions.length > 0) {
    return pointerCollisions;
  }

  // If there are no collisions with the pointer, return rectangle intersections
  return closestCorners(args);
}
