import { SpecksApisContext } from 'App';

import React, { useContext, useState } from 'react';
import { useQuery } from 'react-query';
import { type NavigateFunction, useNavigate, useParams } from 'react-router-dom';

import { useAuth0 } from '@auth0/auth0-react';

import { BaseEditPage } from 'pages/core/BaseEditPage/BaseEditPage';

import { Button } from 'components/core/Button/Button';
import 'components/core/CanvaFrame/styles.css';
import { type BreadCrumbsProps, Header } from 'components/core/Header/Header';
import { AppSchemaComponent } from 'components/specks/AppSchemaComponent/AppSchemaComponent';

import { type Product } from 'models/product.entity';

import { tooltipDescriptionsStyle } from 'utils/tooltipsDescriptions';

interface AppPageParams {
  id?: string;
}
export function AppSchemaRevisionPage(): JSX.Element {
  /* --------------------------------------------------- contexts --------------------------------------------------- */
  const { getAccessTokenSilently } = useAuth0();
  const { productService } = useContext(SpecksApisContext);
  const { id: productId = '' }: AppPageParams = useParams();
  const navigate: NavigateFunction = useNavigate();

  /* ---------------------------------------------------- states ---------------------------------------------------- */
  const [isAppSchemaSaving, setIsAppSchemaSaving] = useState<boolean>(false);
  const [isCanvaModified, setIsCanvaModified] = useState<boolean>(false);

  /* --------------------------------------------------- variables -------------------------------------------------- */

  /* ---------------------------------------------------- queries --------------------------------------------------- */

  const { data: productData, status: productStatus } = useQuery<Product, Error>(['product', productId], async () => {
    const accessToken: string = await getAccessTokenSilently();
    return await productService.findById(productId, accessToken);
  });

  function handleSaveClick(): void {
    setIsAppSchemaSaving(true);
  }
  /* ------------------------------------------------------ JSX ----------------------------------------------------- */

  const breadCrumbs: BreadCrumbsProps = {
    paths: [
      {
        name: 'Produits',
        href: '/products',
      },
      {
        name: productData?.name ?? '',
        href: `/product/${productData?.id ?? ''}`,
      },
    ],
    current: 'Schéma Applicatif',
  };

  const headerRightButton: JSX.Element = (
    <>
      {!isAppSchemaSaving ? (
        <Button
          content="Sauvegarder"
          width="lg"
          iconName="save"
          iconPosition="left"
          onClick={handleSaveClick}
          disabled={!isCanvaModified}
          height="sm"
        />
      ) : (
        <Button iconName="spinCircle" type="button" width="lg" height="sm" iconAnimation="spin" />
      )}
    </>
  );

  async function handleClickBack(): Promise<void> {
    navigate(`/product/${productId}`);
  }

  return (
    <BaseEditPage>
      <Header
        breadCrumbs={breadCrumbs}
        handleClickBack={handleClickBack}
        title={productData?.name}
        status={productData?.status}
        marginY
        rightExtra={headerRightButton}
        thumbnail={true}
        thumbnailIcon={'product'}
        thumbnailTooltipMessage={tooltipDescriptionsStyle.product}
      />
      <>
        {productStatus === 'success' && (
          <AppSchemaComponent
            isEditable
            productId={productData?.id}
            setIsCanvaModified={setIsCanvaModified}
            isAppSchemaSaving={isAppSchemaSaving}
            setIsAppSchemaSaving={setIsAppSchemaSaving}
          />
        )}
      </>
    </BaseEditPage>
  );
}
