import { SpecksApisContext } from 'App';
import * as Yup from 'yup';

import React, { useContext, useEffect, useState } from 'react';
import { type SubmitHandler, useForm } from 'react-hook-form';
import { useQuery } from 'react-query';

import { useAuth0 } from '@auth0/auth0-react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Tooltip } from '@mui/material';

import { Button } from 'components/core/Button/Button';
import Input from 'components/core/Input/Input';
import RadioButtons from 'components/core/RadioButtons/RadioButtons';
import { TabElement } from 'components/core/Tabs/TabElement';
import { TabList } from 'components/core/Tabs/TabList';
import { TabPanel } from 'components/core/Tabs/TabPanel';
import { TabPanels } from 'components/core/Tabs/TabPanels';
import { Tabs } from 'components/core/Tabs/Tabs';
import { Text } from 'components/core/Text/Text';
import TextArea from 'components/core/TextArea/TextArea';

import { type MethodService } from 'services/method.service';

import { type Method } from 'models/method.entity';
import { type ConstantLogical, type YesLogical } from 'models/ui-component-revisions.entity';
import { type UiComponent } from 'models/uiComponent.entity';

import concatClassNames from 'utils/classNames';
import getIcon from 'utils/getIcon';

import { ApiMethodRow } from '../Api/Method/ApiMethodRow';
import UICFieldReadOnly from '../UICFieldReadonly/UICFieldReadonly';
import { UiComponentCard } from '../UiComponentCard/UiComponentCard';

interface MediaInfosFormProps {
  uiComponent: UiComponent;
  checkIfNameIsUnique?: (name: string, id: string) => boolean;
  onClickSave?: () => void;
  onClickAddTarget?: () => void;
  setIsFormDirty?: (isDirty: boolean) => void;
  setFnSetMethodId?: (fn: (methodId: string | undefined) => void) => void;
  isLoading: boolean;
}

const contentTypeOptions: ConstantLogical[] = ['CONSTANT', 'LOGICAL'];

export const contentTypeStyleOptions: string[] = ['Contenu constant', 'Règle logique'];

const isVisibleOptions: YesLogical[] = ['YES', 'LOGICAL'];

export const isVisibleStyleOptions: string[] = ['Oui, toujours', 'Règle logique'];

interface MediaFormModel {
  name: string;
  notes?: string;
  sourceMethodId: string | undefined;
  contentType: ConstantLogical;
  sourceConstant?: string;
  sourceLogicalRule?: string;
  isVisible: YesLogical;
  isVisibleLogicalRule?: string;
}

const schema: Yup.ObjectSchema<MediaFormModel> = Yup.object().shape({
  name: Yup.string()
    .required('Champ obligatoire')
    .max(28, 'Le nom ne doit pas contenir plus de 28 caractères')
    .test('isUnique', "Un composant d'interface avec ce nom existe déjà", (value, context) => {
      const uiComponent: UiComponent | undefined = context?.options?.context?.uiComponent as UiComponent;
      const isNameUnique: boolean | undefined = context?.options?.context?.checkIfNameIsUnique(value, uiComponent.id);
      return value != null && value.length > 0 && isNameUnique;
    }),
  sourceMethodId: Yup.string(),
  contentType: Yup.mixed<ConstantLogical>().required('Champ obligatoire').default('CONSTANT'),
  sourceConstant: Yup.string(),
  sourceLogicalRule: Yup.string(),
  isVisible: Yup.mixed<YesLogical>().required('Champ obligatoire').default('YES'),
  isVisibleLogicalRule: Yup.string(),
  notes: Yup.string(),
});

export function MediaInfosForm({
  uiComponent,
  checkIfNameIsUnique,
  onClickSave,
  onClickAddTarget,
  setIsFormDirty,
  setFnSetMethodId,
  isLoading,
}: MediaInfosFormProps): JSX.Element {
  /* --------------------------------------------------- Contexts --------------------------------------------------- */

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors, isSubmitted },
  } = useForm<MediaFormModel>({
    resolver: yupResolver(schema),
    context: { checkIfNameIsUnique, uiComponent },
  });

  const methodService: MethodService = useContext(SpecksApisContext).methodService;
  const { getAccessTokenSilently } = useAuth0();

  /* --------------------------------------------------- States --------------------------------------------------- */

  const [originalName, setOriginalName] = useState<string>('');
  const [originalNotes, setOriginalNotes] = useState<string>('');
  const [originalSourceMethodId, setOriginalSourceMethodId] = useState<string | undefined>();
  const [originalContentType, setOriginalContentType] = useState<ConstantLogical>();
  const [originalSourceConstant, setOriginalSourceConstant] = useState<string | undefined>();
  const [originalSourceLogicalRule, setOriginalSourceLogicalRule] = useState<string | undefined>();
  const [originalIsVisible, setOriginalIsVisible] = useState<YesLogical>();
  const [originalIsVisibleLogicalRule, setOriginalIsVisibleLogicalRule] = useState<string | undefined>();

  const watchName: string = watch('name');
  const watchNotes: string | undefined = watch('notes');
  const watchSourceMethodId: string | undefined = watch('sourceMethodId');
  const watchContentType: ConstantLogical | undefined = watch('contentType');
  const watchSourceConstant: string | undefined = watch('sourceConstant');
  const watchSourceLogicalRule: string | undefined = watch('sourceLogicalRule');
  const watchIsVisible: YesLogical | undefined = watch('isVisible');
  const watchIsVisibleLogicalRule: string | undefined = watch('isVisibleLogicalRule');
  const isEditable: boolean = onClickSave != null;

  /* ---------------------------------------------------- queries --------------------------------------------------- */

  async function getMethod(methodId: string): Promise<Method> {
    const accessToken: string = await getAccessTokenSilently();
    return await methodService.findById(methodId, accessToken);
  }

  const { data: method, status: methodStatus } = useQuery<Method, Error>({
    queryKey: ['method', watchSourceMethodId],
    queryFn: async ({ queryKey }) => {
      const [, methodId] = queryKey;
      return await getMethod(methodId as string);
    },
    enabled: watchSourceMethodId !== undefined,
  });

  /* ----------------------------------------------- alerts management ---------------------------------------------- */

  const isCompleteName: boolean = watchName !== undefined && watchName.length !== 0;

  const isCompleteSourceConstant: boolean =
    watchSourceConstant === undefined ||
    watchContentType !== 'CONSTANT' ||
    (watchContentType === 'CONSTANT' && watchSourceConstant !== undefined && watchSourceConstant.length > 0);

  const isCompleteSourceLogicalRule: boolean =
    watchSourceLogicalRule === undefined ||
    watchContentType !== 'LOGICAL' ||
    (watchContentType === 'LOGICAL' && watchSourceLogicalRule !== undefined && watchSourceLogicalRule.length > 0);

  const isCompleteIsVisibleLogicalRule: boolean =
    watchIsVisible !== 'LOGICAL' || (watchIsVisibleLogicalRule !== undefined && watchIsVisibleLogicalRule.length > 0);

  /* -------------------------------------------------- Setup Data -------------------------------------------------- */

  useEffect(() => {
    setOriginalName(uiComponent.uiComponentRevisions[0].name);
    setOriginalNotes(uiComponent.uiComponentRevisions[0].notes);
    setOriginalSourceMethodId(uiComponent.uiComponentRevisions[0].sourceMethodId);
    setOriginalContentType(uiComponent.uiComponentRevisions[0].contentType);
    setOriginalSourceConstant(uiComponent.uiComponentRevisions[0].sourceConstant);
    setOriginalSourceLogicalRule(uiComponent.uiComponentRevisions[0].sourceLogicalRule);
    setOriginalIsVisible(uiComponent.uiComponentRevisions[0].isVisible);
    setOriginalIsVisibleLogicalRule(uiComponent.uiComponentRevisions[0].isVisibleLogicalRule);

    setValue('name', uiComponent.uiComponentRevisions[0].name);
    setValue('notes', uiComponent.uiComponentRevisions[0].notes);
    setValue('sourceMethodId', uiComponent.uiComponentRevisions[0].sourceMethodId);
    setValue('contentType', uiComponent.uiComponentRevisions[0].contentType);
    setValue('sourceConstant', uiComponent.uiComponentRevisions[0].sourceConstant);
    setValue('sourceLogicalRule', uiComponent.uiComponentRevisions[0].sourceLogicalRule);
    setValue('isVisible', uiComponent.uiComponentRevisions[0].isVisible);
    setValue('isVisibleLogicalRule', uiComponent.uiComponentRevisions[0].isVisibleLogicalRule);
  }, [setValue, uiComponent, originalName, originalNotes]);

  /* --------------------------------------------------- Functions -------------------------------------------------- */

  function handleOnClickSave(): void {
    if (onClickSave == null) return;
    uiComponent.uiComponentRevisions[0].name = watchName;
    uiComponent.uiComponentRevisions[0].notes = watchNotes ?? '';
    uiComponent.uiComponentRevisions[0].sourceMethodId = watchSourceMethodId;
    uiComponent.uiComponentRevisions[0].contentType = watchContentType ?? 'CONSTANT';
    uiComponent.uiComponentRevisions[0].sourceConstant = watchSourceConstant ?? '';
    uiComponent.uiComponentRevisions[0].sourceLogicalRule = watchSourceLogicalRule ?? '';
    uiComponent.uiComponentRevisions[0].isVisible = watchIsVisible ?? 'YES';
    uiComponent.uiComponentRevisions[0].isVisibleLogicalRule = watchIsVisibleLogicalRule ?? '';
    uiComponent.uiComponentRevisions[0].isFormValid = checkIfFormIsComplete;

    onClickSave();
  }

  const onSubmitForm: SubmitHandler<MediaFormModel> = (data: MediaFormModel) => {
    handleOnClickSave();
  };

  function setContentMethodId(methodId: string | undefined): void {
    setValue('sourceMethodId', methodId);
  }

  function removeSourceMethodId(): void {
    setContentMethodId(undefined);
    setValue('sourceMethodId', undefined);
  }

  /* --------------------------------------------------- Watchers --------------------------------------------------- */

  const checkIfFormIsComplete: boolean =
    isCompleteIsVisibleLogicalRule && isCompleteName && isCompleteSourceConstant && isCompleteSourceLogicalRule;

  let isFormValid: boolean = true;
  if (
    watchName === undefined ||
    watchName.length === 0 ||
    (watchName === originalName &&
      watchNotes === originalNotes &&
      watchSourceMethodId === originalSourceMethodId &&
      watchContentType === originalContentType &&
      watchSourceConstant === originalSourceConstant &&
      watchSourceLogicalRule === originalSourceLogicalRule &&
      watchIsVisible === originalIsVisible &&
      watchIsVisibleLogicalRule === originalIsVisibleLogicalRule)
  ) {
    isFormValid = false;
  }
  setIsFormDirty?.(false);
  if (
    watchName !== originalName ||
    watchNotes !== originalNotes ||
    watchSourceMethodId !== originalSourceMethodId ||
    watchContentType !== originalContentType ||
    watchSourceConstant !== originalSourceConstant ||
    watchSourceLogicalRule !== originalSourceLogicalRule ||
    watchIsVisible !== originalIsVisible ||
    watchIsVisibleLogicalRule !== originalIsVisibleLogicalRule
  ) {
    setIsFormDirty?.(true);
  }

  /* --------------------------------------------------- Rendering -------------------------------------------------- */

  return (
    <Tabs>
      <TabList>
        <TabElement textSize="base" title={'Spécification'}></TabElement>
        <TabElement textSize="base" title={'Règles logiques'}></TabElement>
      </TabList>
      <TabPanels>
        <TabPanel>
          <form
            className="flex flex-col gap-6 justify-between flex-grow overflow-auto mt-2"
            onSubmit={handleSubmit(onSubmitForm)}
          >
            <div className="flex flex-col gap-6 overflow-auto">
              {/* <div className={concatClassNames('flex flex-col', 'gap-5', 'items-start')}>
                <Text content="Type de composant d'interface" weight="bold" size="base" position="center" />
                <UiComponentCard type="media" horizontalMargin={'none'} isDraggable={false} />
              </div> */}

              <div className={concatClassNames('flex flex-col', 'gap-1')}>
                {!isEditable && (
                  <UICFieldReadOnly
                    label="Identifiant Specks"
                    information="Cet identifiant vous permettra de le retrouver plus facilement dans Specks."
                    content={watchName}
                  />
                )}
                {isEditable && (
                  <Input
                    label="Identifiant Specks"
                    labelWeight="medium"
                    information="Cet identifiant vous permettra de le retrouver plus facilement dans Specks."
                    placeholder="L'identifiant Specks de votre composant d'interface"
                    textSize="base"
                    {...register('name')}
                    maxLength={28}
                    error={errors.name != null && isSubmitted ? errors.name.message : undefined}
                  />
                )}
                {watchName !== undefined && watchName.length === 0 && (
                  <Text content="Le nom est obligatoire" color="red-500" size="sm" position="center" />
                )}
              </div>

              <div
                className={concatClassNames(
                  'flex flex-col',
                  'drop-shadow-md bg-white rounded-md',
                  'border-x-1 border-gray-25',
                  'p-4',
                  'gap-2',
                )}
              >
                <UICFieldReadOnly
                  label="Contenu du Média"
                  information="Souhaitez-vous définir une source pour récupérer le contenu du média ?"
                  isComplete={isCompleteSourceConstant && isCompleteSourceLogicalRule}
                  error="Préconisation : ce champ ne devrait pas être vide"
                />
                {(isEditable || watchSourceMethodId !== undefined) && (
                  <>
                    <div className={concatClassNames('flex flex-row gap-1 items-center')}>
                      <Text content={isEditable ? 'Source (facultatif)' : 'Source'} position="left" size="sm" />
                      <Tooltip
                        title={'Souhaitez-vous définir une source pour récupérer le contenu du média ?'}
                        placement="right"
                      >
                        <div>{<>{getIcon('information', 'gradient2-to', 'sm')}</>}</div>
                      </Tooltip>
                    </div>

                    <div className={concatClassNames('flex flex-row', 'gap-4')}>
                      {isEditable && watchSourceMethodId === undefined && (
                        <Button
                          content={'Ajouter une source'}
                          type="button"
                          iconName="plus"
                          iconPosition="right"
                          bgColor="white"
                          textColor="black"
                          borderWidth="xs"
                          borderColor="grey"
                          textSize="sm"
                          iconColor="black"
                          width="fit"
                          onClick={() => {
                            setFnSetMethodId?.(() => setContentMethodId);
                            onClickAddTarget?.();
                          }}
                        />
                      )}
                      <div id="selected_methods" className={concatClassNames('flex', 'items-center', 'flex-grow')}>
                        {watchSourceMethodId === undefined && (
                          <Text content={'Aucune méthode sélectionnée'} color="gray-400" size="sm" position="center" />
                        )}
                        {watchSourceMethodId !== undefined && methodStatus === 'success' && (
                          <ApiMethodRow
                            type={method?.methodRevisions[0].methodType}
                            methodId={method?.id ?? ''}
                            path={method?.methodRevisions[0].name ?? ''}
                            description={method?.methodRevisions[0].description ?? ''}
                            borderColored
                            clickable={true}
                            onDelete={isEditable ? removeSourceMethodId : undefined}
                          />
                        )}
                      </div>
                    </div>
                  </>
                )}

                <div className={concatClassNames('flex flex-col', 'gap-2')}>
                  <RadioButtons
                    values={contentTypeOptions}
                    valuesDisplay={contentTypeStyleOptions}
                    selected={watchContentType}
                    {...register('contentType')}
                    isEditable={isEditable}
                    isComplete={isCompleteSourceConstant && isCompleteSourceLogicalRule}
                    error="Préconisation : ce champ ne devrait pas être vide"
                  />
                  {watchContentType === 'CONSTANT' && (
                    <>
                      {isEditable && (
                        <TextArea
                          {...register('sourceConstant')}
                          value={watchSourceConstant}
                          rows={4}
                          error={
                            errors.sourceConstant != null && isSubmitted ? errors.sourceConstant.message : undefined
                          }
                          isComplete={isCompleteSourceConstant}
                          disabled={!isEditable}
                        />
                      )}
                      {!isEditable && <UICFieldReadOnly content={watchSourceConstant ?? ''} />}
                    </>
                  )}
                  {watchContentType === 'LOGICAL' && (
                    <>
                      {isEditable && (
                        <TextArea
                          {...register('sourceLogicalRule')}
                          value={watchSourceLogicalRule}
                          rows={4}
                          placeholder={`Etant données [les hypothèses suivantes], lorsque [tel événement se produit] alors [voici le comportement attendu] `}
                          error={
                            errors.sourceLogicalRule != null && isSubmitted
                              ? errors.sourceLogicalRule.message
                              : undefined
                          }
                          disabled={!isEditable}
                          isComplete={isCompleteSourceLogicalRule}
                        />
                      )}
                      {!isEditable && <UICFieldReadOnly content={watchSourceLogicalRule ?? ''} />}
                    </>
                  )}
                </div>
              </div>

              <div className={concatClassNames('flex flex-col', 'gap-2')}>
                <RadioButtons
                  {...register('isVisible')}
                  information="Ce média est-il systématiquement visible par l'utilisateur ?"
                  label="Visible ?"
                  values={isVisibleOptions}
                  valuesDisplay={isVisibleStyleOptions}
                  selected={watchIsVisible}
                  isEditable={isEditable}
                  isComplete={isCompleteIsVisibleLogicalRule}
                  error="Préconisation : ce champ ne devrait pas être vide"
                />

                {watchIsVisible === 'LOGICAL' && (
                  <>
                    {isEditable && (
                      <TextArea
                        {...register('isVisibleLogicalRule')}
                        value={watchIsVisibleLogicalRule}
                        placeholder={`Etant données [les hypothèses suivantes], lorsque [tel événement se produit] alors [voici le comportement attendu]`}
                        rows={4}
                        error={
                          errors.isVisibleLogicalRule != null && isSubmitted
                            ? errors.isVisibleLogicalRule.message
                            : undefined
                        }
                        disabled={!isEditable}
                        isComplete={isCompleteIsVisibleLogicalRule}
                      />
                    )}
                    {!isEditable && <UICFieldReadOnly content={watchIsVisibleLogicalRule ?? ''} />}
                  </>
                )}
              </div>

              {isEditable && (
                <TextArea
                  label="Notes"
                  labelWeight="medium"
                  labelColor="gray-700"
                  placeholder="Ajouter une note"
                  {...register('notes')}
                  value={watchNotes}
                  rows={6}
                  error={errors.notes != null && isSubmitted ? errors.notes.message : undefined}
                />
              )}
              {!isEditable && (
                <UICFieldReadOnly
                  label="Notes"
                  content={watchNotes === undefined || watchNotes.length === 0 ? 'N/A' : watchNotes}
                />
              )}
            </div>

            {onClickSave != null && (
              <div className="flex flex-row gap-2 justify-end">
                {!isLoading && <Button content="Sauvegarder" type="submit" disabled={!isFormValid} />}
                {isLoading && <Button iconName="spinCircle" type="button" height="sm" iconAnimation="spin" />}
              </div>
            )}
          </form>
        </TabPanel>
        <TabPanel>
          <div className={concatClassNames('flex flex-col flex-grow justify-between')}>
            <div className={concatClassNames('flex flex-col gap-4')}>
              <div className={concatClassNames('flex flex-col gap-1')}>
                <Text content="Règles du Composant d'interface" weight="bold" position="left" />
                <Text
                  content="Aucune règle n'a été définie pour ce composant. "
                  color="gray-100"
                  position="left"
                  size="sm"
                />
              </div>
              <div className={concatClassNames('flex flex-col gap-1')}>
                <Text content="Règles héritées (non modifiables)" weight="bold" position="left" />
                <Text
                  content="Ce composant ne reçoit aucune règle en héritage"
                  color="gray-100"
                  position="left"
                  size="sm"
                />
              </div>
            </div>
            {onClickSave != null && (
              <div className={concatClassNames('flex gap-2 pt-8 border-t-1 border-gray-50')}>
                <Button content="Ajouter une règle" iconName="plus" cursor="not-allowed" />
                <Button content="Sauvegarder" cursor="not-allowed" />
              </div>
            )}
          </div>
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
}
