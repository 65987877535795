import { type UiComponentTypeEnum } from 'api';

import React, { useContext } from 'react';

import { type UniqueIdentifier } from '@dnd-kit/core';

import { PageContext, type PageHandler, type SortedUIC } from 'pages/Component/FrontEnd/Page/ComponentFrontEndPagePage';

import { UiComponentCard } from 'components/specks/UiComponentCard/UiComponentCard';
import { UicDroppableZone } from 'components/specks/UicDroppableZone/UicDroppableZone';

import ComponentUtils from 'utils/ComponentUtils';
import concatClassNames from 'utils/classNames';

interface PageCanvaFrameProps {
  children: React.ReactNode;
  childrenLength?: number;
}

interface MasterZoneProps {
  uics: SortedUIC[];
  parentId?: string;
  parentType?: UiComponentTypeEnum;
  activeDraggedUicId: UniqueIdentifier | null;
  overUicId: UniqueIdentifier | null;
  selectedUiComponentId: string | undefined;
  handleClickUiComponentCard?: (componentId: string) => void;
  isDroppable?: boolean;
  isDragged?: boolean;
  isLastOvered?: boolean;
}

export function MasterZone({
  uics,
  parentId,
  parentType,
  activeDraggedUicId,
  overUicId,
  selectedUiComponentId,
  handleClickUiComponentCard,
  isDroppable = true,
  isLastOvered = false,
  isDragged = false,
}: MasterZoneProps): JSX.Element {
  const { isEditable }: PageHandler = useContext(PageContext);

  function getselectedIndex(uics: SortedUIC[], selectedUiComponentId: string | undefined): number {
    if (selectedUiComponentId === undefined) return -1;
    return uics.findIndex((uic) => uic.id === selectedUiComponentId);
  }

  const selectedIndex: number = getselectedIndex(uics, selectedUiComponentId);

  return (
    <>
      {isEditable && uics.length === 0 && (
        // First without brothers
        <div
          className={concatClassNames(
            'flex flex-row',
            'justify-center',
            parentType === undefined ? 'h-full w-full' : '',
            // 'border-4 border-orange-500'
            '',
          )}
        >
          {activeDraggedUicId !== null && isDroppable && (
            <UicDroppableZone isFirst={true} parentId={parentId} hasChildren={false} />
          )}
          {(activeDraggedUicId === null || !isDroppable) && (
            <UicDroppableZone
              isFirst={true}
              parentId={parentId}
              isDroppable={false}
              hasChildren={false}
              isDragged={isDragged}
            />
          )}
        </div>
      )}

      {uics.map((uic, index) => {
        return (
          <div key={index} className="relative flex items-center gap-0">
            {/* First with brothers */}
            {index === 0 && activeDraggedUicId !== null && isDroppable && (
              <UicDroppableZone isFirst parentId={parentId} isSaveable={selectedIndex !== 0} />
            )}
            <UiComponentCard
              type={uic.uiCompnent.type}
              uiComponentId={uic.id}
              name={uic.uiCompnent.uiComponentRevisions[0].name}
              index={ComponentUtils.lastRevision(uic.uiCompnent)?.index}
              format="canva"
              activeDraggedUicId={activeDraggedUicId}
              isSelected={uic.id === selectedUiComponentId || uic.id === 'new'}
              selectedUicId={selectedUiComponentId}
              overUicId={overUicId}
              onClick={uic.id === 'new' ? undefined : handleClickUiComponentCard}
              isDroppable={isDroppable}
              isComplete={uic.uiCompnent.uiComponentRevisions[0].isFormValid}
            >
              {uic.children}
            </UiComponentCard>
            {index === uics.length - 1 && parentType === undefined ? (
              <div
                className={concatClassNames(
                  'flex flex-row',
                  'justify-center',
                  parentType === undefined ? 'h-full' : '',
                  'items-center',
                  // 'border-4 border-orange-500'
                  '',
                )}
              >
                {activeDraggedUicId !== null && isDroppable && (
                  <UicDroppableZone previousUiComponentId={uic.id} parentId={parentId} isOvered={isLastOvered} />
                )}
              </div>
            ) : (
              <>
                {activeDraggedUicId !== null && isDroppable && (
                  <UicDroppableZone
                    previousUiComponentId={uic.id}
                    parentId={parentId}
                    isSaveable={uic.id !== selectedUiComponentId && selectedIndex - 1 !== index}
                  />
                )}
              </>
            )}
          </div>
        );
      })}
    </>
  );
}

export function UICCanva({ children, childrenLength = 0 }: PageCanvaFrameProps): JSX.Element {
  return (
    <div
      id="UICCanva"
      className={concatClassNames(
        'flex flex-wrap',
        childrenLength !== 0 ? '' : 'w-full',
        'relative',
        'items-center',
        'px-5 py-5',
        'gap-6',
      )}
    >
      {children}
    </div>
  );
}

export function PageCanvaFrame({ children, childrenLength = 0 }: PageCanvaFrameProps): JSX.Element {
  return (
    <div className={concatClassNames('bg-gray-25 flex-1 overflow-y-auto', 'px-5 ', 'pt-5')}>
      <UICCanva childrenLength={childrenLength}>{children}</UICCanva>
    </div>
  );
}
