/* tslint:disable */
/* eslint-disable */
/**
 * Specks API
 * APIs for specks SaaS
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ErrorBadRequest,
  GetExternalLinkDto,
  UpdateExternalLinkDto,
} from '../models';
import {
    ErrorBadRequestFromJSON,
    ErrorBadRequestToJSON,
    GetExternalLinkDtoFromJSON,
    GetExternalLinkDtoToJSON,
    UpdateExternalLinkDtoFromJSON,
    UpdateExternalLinkDtoToJSON,
} from '../models';

export interface ExternalLinksControllerDeleteRequest {
    id: string;
}

export interface ExternalLinksControllerUpdateRequest {
    id: string;
    updateExternalLinkDto: UpdateExternalLinkDto;
}

/**
 * 
 */
export class ExternalLinksApi extends runtime.BaseAPI {

    /**
     * Delete ExternalLink by ID
     * Supprimer un lien externe
     */
    async externalLinksControllerDeleteRaw(requestParameters: ExternalLinksControllerDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling externalLinksControllerDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/external-links/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete ExternalLink by ID
     * Supprimer un lien externe
     */
    async externalLinksControllerDelete(requestParameters: ExternalLinksControllerDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.externalLinksControllerDeleteRaw(requestParameters, initOverrides);
    }

    /**
     */
    async externalLinksControllerUpdateRaw(requestParameters: ExternalLinksControllerUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetExternalLinkDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling externalLinksControllerUpdate.');
        }

        if (requestParameters.updateExternalLinkDto === null || requestParameters.updateExternalLinkDto === undefined) {
            throw new runtime.RequiredError('updateExternalLinkDto','Required parameter requestParameters.updateExternalLinkDto was null or undefined when calling externalLinksControllerUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/external-links/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateExternalLinkDtoToJSON(requestParameters.updateExternalLinkDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetExternalLinkDtoFromJSON(jsonValue));
    }

    /**
     */
    async externalLinksControllerUpdate(requestParameters: ExternalLinksControllerUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetExternalLinkDto> {
        const response = await this.externalLinksControllerUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
