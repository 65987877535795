import React from 'react';
import ReactFlow, {
  Background,
  type Connection,
  ConnectionMode,
  Controls,
  type Edge,
  type EdgeTypes,
  type Node,
  type NodeTypes,
  type OnConnectStartParams,
  type OnEdgesChange,
  type OnNodesChange,
  type ReactFlowInstance,
} from 'reactflow';
import 'reactflow/dist/style.css';

import AppSchemaEdge from 'components/specks/ReactFlowCustom/AppSchemaEdge/AppSchemaEdge';
import AppSchemaNode from 'components/specks/ReactFlowCustom/AppSchemaNode/AppSchemaNode';

import './styles.css';

interface CanvaFrameProps {
  nodes: Node[];
  edges: Edge[];
  onNodesChange: OnNodesChange;
  onEdgesChange: OnEdgesChange;
  onEdgeUpdate: any;
  onEdgeUpdateStart: any;
  onEdgeUpdateEnd: any;
  isEditable?: boolean;
  onClick?: (event: React.MouseEvent, node: Node) => void;
  onConnect?: (params: Edge | Connection) => void;
  onConnectStart?: (event: unknown, params: OnConnectStartParams) => void;
  onConnectStop?: () => void;
  refCallBack?: (reactFlowDiv: HTMLDivElement | null) => void;
  onInit?: (instance: ReactFlowInstance) => void;
  snapGridStep: number;
}

const nodeTypes: NodeTypes = {
  appSchema: AppSchemaNode,
};

const edgeTypes: EdgeTypes = {
  appSchemaEdge: AppSchemaEdge,
};

export function CanvaFrame({
  nodes,
  edges,
  isEditable = false,
  onNodesChange,
  onEdgesChange,
  onEdgeUpdate,
  onEdgeUpdateStart,
  onEdgeUpdateEnd,
  onClick,
  onInit,
  onConnect,
  onConnectStart,
  onConnectStop,
  refCallBack,
  snapGridStep,
}: CanvaFrameProps): JSX.Element {
  // TODO : Pay ReactFlow

  function handleOnEdgeUpdate(_: any, edge: Edge): void {
    onEdgeUpdateEnd(edge, nodes);
  }

  return (
    <ReactFlow
      nodes={nodes}
      edges={edges}
      onNodesChange={onNodesChange}
      onEdgesChange={onEdgesChange}
      nodeTypes={nodeTypes}
      edgeTypes={edgeTypes}
      onConnect={onConnect}
      onConnectStart={onConnectStart}
      onConnectEnd={onConnectStop}
      proOptions={{ hideAttribution: true }}
      nodesDraggable={isEditable}
      nodesConnectable={isEditable}
      fitView
      snapToGrid={true}
      snapGrid={[snapGridStep, snapGridStep]}
      onEdgeUpdate={onEdgeUpdate}
      onEdgeUpdateStart={onEdgeUpdateStart}
      onEdgeUpdateEnd={handleOnEdgeUpdate}
      onNodeClick={onClick}
      edgesFocusable={isEditable}
      edgesUpdatable={isEditable}
      onContextMenu={(e) => {
        e.preventDefault();
      }}
      onInit={onInit}
      ref={refCallBack}
      connectionMode={ConnectionMode.Loose}
    >
      <Controls position="top-right" showInteractive={isEditable} />
      <Background className="bg-gray-25" />
    </ReactFlow>
  );
}
