import { type GetComponentDto } from 'api';

import { type Status } from './Status.types';
import { type Component } from './component.entity';
import { Dictionary } from './dictionary.entity';
import { type ExternalLink } from './externalLink.entity';
import { Faq } from './faq.entity';
import { type Feature } from './feature.entity';
import { Genesis } from './genesis.entity';

export class Product {
  constructor() {
    this.features = [];
    this.dictionary = new Dictionary();
    this.faq = new Faq();
    this.genesis = new Genesis();
    this.externalLinks = [];
  }

  id: string;

  name: string;

  status: Status;

  features: Feature[];

  dictionary: Dictionary;

  faq: Faq;

  genesis: Genesis;

  externalLinks: ExternalLink[];

  components: GetComponentDto[];
}
