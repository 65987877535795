import { type InputTypeQuery } from 'forms/ApiQueryParameterForm/ApiQueryParameterForm';

export type BooleanEnum = 'true' | 'false' | 'null';

export class ApiQueryParameter {
  id: string;

  name: string;

  isMandatory: BooleanEnum;

  valueType: InputTypeQuery;

  defaultValue: string;
}
