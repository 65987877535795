/* tslint:disable */
/* eslint-disable */
/**
 * Specks API
 * APIs for specks SaaS
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { GetLinkDto } from './GetLinkDto';
import {
    GetLinkDtoFromJSON,
    GetLinkDtoFromJSONTyped,
    GetLinkDtoToJSON,
} from './GetLinkDto';

/**
 * 
 * @export
 * @interface GetLinksDto
 */
export interface GetLinksDto {
    /**
     * 
     * @type {Array<GetLinkDto>}
     * @memberof GetLinksDto
     */
    links: Array<GetLinkDto>;
}

/**
 * Check if a given object implements the GetLinksDto interface.
 */
export function instanceOfGetLinksDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "links" in value;

    return isInstance;
}

export function GetLinksDtoFromJSON(json: any): GetLinksDto {
    return GetLinksDtoFromJSONTyped(json, false);
}

export function GetLinksDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetLinksDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'links': ((json['links'] as Array<any>).map(GetLinkDtoFromJSON)),
    };
}

export function GetLinksDtoToJSON(value?: GetLinksDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'links': ((value.links as Array<any>).map(GetLinkDtoToJSON)),
    };
}

