/* tslint:disable */
/* eslint-disable */
/**
 * Specks API
 * APIs for specks SaaS
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ApiBodyParameter } from './ApiBodyParameter';
import {
    ApiBodyParameterFromJSON,
    ApiBodyParameterFromJSONTyped,
    ApiBodyParameterToJSON,
} from './ApiBodyParameter';
import type { ApiHeaderParameter } from './ApiHeaderParameter';
import {
    ApiHeaderParameterFromJSON,
    ApiHeaderParameterFromJSONTyped,
    ApiHeaderParameterToJSON,
} from './ApiHeaderParameter';
import type { ApiPathParameter } from './ApiPathParameter';
import {
    ApiPathParameterFromJSON,
    ApiPathParameterFromJSONTyped,
    ApiPathParameterToJSON,
} from './ApiPathParameter';
import type { ApiQueryParameter } from './ApiQueryParameter';
import {
    ApiQueryParameterFromJSON,
    ApiQueryParameterFromJSONTyped,
    ApiQueryParameterToJSON,
} from './ApiQueryParameter';
import type { Link } from './Link';
import {
    LinkFromJSON,
    LinkFromJSONTyped,
    LinkToJSON,
} from './Link';
import type { Method } from './Method';
import {
    MethodFromJSON,
    MethodFromJSONTyped,
    MethodToJSON,
} from './Method';
import type { ModelApiResponse } from './ModelApiResponse';
import {
    ModelApiResponseFromJSON,
    ModelApiResponseFromJSONTyped,
    ModelApiResponseToJSON,
} from './ModelApiResponse';

/**
 * 
 * @export
 * @interface PartialUpdateMethodDto
 */
export interface PartialUpdateMethodDto {
    /**
     * 
     * @type {string}
     * @memberof PartialUpdateMethodDto
     */
    id?: string;
    /**
     * 
     * @type {Date}
     * @memberof PartialUpdateMethodDto
     */
    createdAt?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof PartialUpdateMethodDto
     */
    latest?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PartialUpdateMethodDto
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof PartialUpdateMethodDto
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof PartialUpdateMethodDto
     */
    methodType?: PartialUpdateMethodDtoMethodTypeEnum;
    /**
     * 
     * @type {Array<ApiPathParameter>}
     * @memberof PartialUpdateMethodDto
     */
    apiPathParameters?: Array<ApiPathParameter>;
    /**
     * 
     * @type {Array<ApiQueryParameter>}
     * @memberof PartialUpdateMethodDto
     */
    apiQueryParameters?: Array<ApiQueryParameter>;
    /**
     * 
     * @type {Array<ApiHeaderParameter>}
     * @memberof PartialUpdateMethodDto
     */
    apiHeaderParameters?: Array<ApiHeaderParameter>;
    /**
     * 
     * @type {ApiBodyParameter}
     * @memberof PartialUpdateMethodDto
     */
    apiBodyParameter?: ApiBodyParameter;
    /**
     * 
     * @type {Array<ModelApiResponse>}
     * @memberof PartialUpdateMethodDto
     */
    apiResponses?: Array<ModelApiResponse>;
    /**
     * 
     * @type {Method}
     * @memberof PartialUpdateMethodDto
     */
    method?: Method;
    /**
     * 
     * @type {Array<Link>}
     * @memberof PartialUpdateMethodDto
     */
    bearerLinks?: Array<Link>;
}


/**
 * @export
 */
export const PartialUpdateMethodDtoMethodTypeEnum = {
    Get: 'GET',
    Post: 'POST',
    Put: 'PUT',
    Patch: 'PATCH',
    Delete: 'DELETE'
} as const;
export type PartialUpdateMethodDtoMethodTypeEnum = typeof PartialUpdateMethodDtoMethodTypeEnum[keyof typeof PartialUpdateMethodDtoMethodTypeEnum];


/**
 * Check if a given object implements the PartialUpdateMethodDto interface.
 */
export function instanceOfPartialUpdateMethodDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function PartialUpdateMethodDtoFromJSON(json: any): PartialUpdateMethodDto {
    return PartialUpdateMethodDtoFromJSONTyped(json, false);
}

export function PartialUpdateMethodDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PartialUpdateMethodDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'createdAt': !exists(json, 'createdAt') ? undefined : (new Date(json['createdAt'])),
        'latest': !exists(json, 'latest') ? undefined : json['latest'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'methodType': !exists(json, 'methodType') ? undefined : json['methodType'],
        'apiPathParameters': !exists(json, 'apiPathParameters') ? undefined : ((json['apiPathParameters'] as Array<any>).map(ApiPathParameterFromJSON)),
        'apiQueryParameters': !exists(json, 'apiQueryParameters') ? undefined : ((json['apiQueryParameters'] as Array<any>).map(ApiQueryParameterFromJSON)),
        'apiHeaderParameters': !exists(json, 'apiHeaderParameters') ? undefined : ((json['apiHeaderParameters'] as Array<any>).map(ApiHeaderParameterFromJSON)),
        'apiBodyParameter': !exists(json, 'apiBodyParameter') ? undefined : ApiBodyParameterFromJSON(json['apiBodyParameter']),
        'apiResponses': !exists(json, 'apiResponses') ? undefined : ((json['apiResponses'] as Array<any>).map(ModelApiResponseFromJSON)),
        'method': !exists(json, 'method') ? undefined : MethodFromJSON(json['method']),
        'bearerLinks': !exists(json, 'bearerLinks') ? undefined : ((json['bearerLinks'] as Array<any>).map(LinkFromJSON)),
    };
}

export function PartialUpdateMethodDtoToJSON(value?: PartialUpdateMethodDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'createdAt': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'latest': value.latest,
        'name': value.name,
        'description': value.description,
        'methodType': value.methodType,
        'apiPathParameters': value.apiPathParameters === undefined ? undefined : ((value.apiPathParameters as Array<any>).map(ApiPathParameterToJSON)),
        'apiQueryParameters': value.apiQueryParameters === undefined ? undefined : ((value.apiQueryParameters as Array<any>).map(ApiQueryParameterToJSON)),
        'apiHeaderParameters': value.apiHeaderParameters === undefined ? undefined : ((value.apiHeaderParameters as Array<any>).map(ApiHeaderParameterToJSON)),
        'apiBodyParameter': ApiBodyParameterToJSON(value.apiBodyParameter),
        'apiResponses': value.apiResponses === undefined ? undefined : ((value.apiResponses as Array<any>).map(ModelApiResponseToJSON)),
        'method': MethodToJSON(value.method),
        'bearerLinks': value.bearerLinks === undefined ? undefined : ((value.bearerLinks as Array<any>).map(LinkToJSON)),
    };
}

