import { type GetPageRevisionDto, type GetPageRevisionInfoDto } from 'api';

import { PageRevision } from 'models/pageRevision.entity';

import { fromGetUiComponentDto } from './uiComponentFactory';

export function fromGetPageRevisionDto(getPageRevisionDto: GetPageRevisionDto): PageRevision {
  const pageRevision: PageRevision = new PageRevision();
  pageRevision.id = getPageRevisionDto.id;
  pageRevision.name = getPageRevisionDto.name;
  pageRevision.createdAt = getPageRevisionDto.createdAt;
  pageRevision.uiComponents = getPageRevisionDto.uiComponents.map((uiComponentDto) => {
    return fromGetUiComponentDto(uiComponentDto);
  });
  return pageRevision;
}

export function fromGetPageRevisionInfoDto(getPageRevisionDto: GetPageRevisionInfoDto): PageRevision {
  const pageRevision: PageRevision = new PageRevision();
  pageRevision.id = getPageRevisionDto.id;
  pageRevision.name = getPageRevisionDto.name;
  pageRevision.createdAt = getPageRevisionDto.createdAt;
  return pageRevision;
}
