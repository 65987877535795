/* tslint:disable */
/* eslint-disable */
/**
 * Specks API
 * APIs for specks SaaS
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { GetPageRevisionDto } from './GetPageRevisionDto';
import {
    GetPageRevisionDtoFromJSON,
    GetPageRevisionDtoFromJSONTyped,
    GetPageRevisionDtoToJSON,
} from './GetPageRevisionDto';

/**
 * 
 * @export
 * @interface GetPageDto
 */
export interface GetPageDto {
    /**
     * 
     * @type {string}
     * @memberof GetPageDto
     */
    id: string;
    /**
     * 
     * @type {Array<GetPageRevisionDto>}
     * @memberof GetPageDto
     */
    pageRevisions: Array<GetPageRevisionDto>;
}

/**
 * Check if a given object implements the GetPageDto interface.
 */
export function instanceOfGetPageDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "pageRevisions" in value;

    return isInstance;
}

export function GetPageDtoFromJSON(json: any): GetPageDto {
    return GetPageDtoFromJSONTyped(json, false);
}

export function GetPageDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetPageDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'pageRevisions': ((json['pageRevisions'] as Array<any>).map(GetPageRevisionDtoFromJSON)),
    };
}

export function GetPageDtoToJSON(value?: GetPageDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'pageRevisions': ((value.pageRevisions as Array<any>).map(GetPageRevisionDtoToJSON)),
    };
}

