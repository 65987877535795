/* tslint:disable */
/* eslint-disable */
/**
 * Specks API
 * APIs for specks SaaS
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { BackApiSpecification } from './BackApiSpecification';
import {
    BackApiSpecificationFromJSON,
    BackApiSpecificationFromJSONTyped,
    BackApiSpecificationToJSON,
} from './BackApiSpecification';
import type { ResourceRevision } from './ResourceRevision';
import {
    ResourceRevisionFromJSON,
    ResourceRevisionFromJSONTyped,
    ResourceRevisionToJSON,
} from './ResourceRevision';

/**
 * 
 * @export
 * @interface Resource
 */
export interface Resource {
    /**
     * 
     * @type {string}
     * @memberof Resource
     */
    id: string;
    /**
     * 
     * @type {BackApiSpecification}
     * @memberof Resource
     */
    backApiSpecification: BackApiSpecification;
    /**
     * 
     * @type {Array<ResourceRevision>}
     * @memberof Resource
     */
    resourceRevisions: Array<ResourceRevision>;
}

/**
 * Check if a given object implements the Resource interface.
 */
export function instanceOfResource(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "backApiSpecification" in value;
    isInstance = isInstance && "resourceRevisions" in value;

    return isInstance;
}

export function ResourceFromJSON(json: any): Resource {
    return ResourceFromJSONTyped(json, false);
}

export function ResourceFromJSONTyped(json: any, ignoreDiscriminator: boolean): Resource {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'backApiSpecification': BackApiSpecificationFromJSON(json['backApiSpecification']),
        'resourceRevisions': ((json['resourceRevisions'] as Array<any>).map(ResourceRevisionFromJSON)),
    };
}

export function ResourceToJSON(value?: Resource | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'backApiSpecification': BackApiSpecificationToJSON(value.backApiSpecification),
        'resourceRevisions': ((value.resourceRevisions as Array<any>).map(ResourceRevisionToJSON)),
    };
}

