import React from 'react';

import { Tooltip } from '@mui/material';

import concatClassNames from 'utils/classNames';
import getIcon from 'utils/getIcon';

import { type IconColor, type IconName } from 'icons/Icon.props';

import { type CursorTypes, cursorStyle } from '../Core.style';
import { Text, type TextSize } from '../Text/Text';

type ButtonWidth = 'full' | 'fit' | '1/2' | 'lg';

const buttonWidthStyle: Record<ButtonWidth, string> = {
  full: 'w-full',
  fit: 'w-fit',
  '1/2': 'w-1/2',
  lg: 'w-72',
};

type ButtonHeight = 'full' | 'fit' | 'sm';

const buttonHeightStyle: Record<ButtonHeight, string> = {
  full: 'h-full',
  fit: 'h-fit',
  sm: 'h-11',
};

type ButtonBgColor = 'gradient1' | 'gradient2' | 'grey' | 'white';

const buttonBgColorStyle: Record<ButtonBgColor, string> = {
  gradient1: 'bg-gradient-to-r from-gradient1-from to-gradient1-to',
  gradient2: 'bg-gradient-to-r from-gradient2-from to-gradient2-to',
  grey: 'bg-gray-25',
  white: 'bg-white',
};

type ButtonTextColor = 'white' | 'black';

type ButtonBorderColor = 'grey' | 'grey-500' | 'none';

const buttonBorderColorStyle: Record<ButtonBorderColor, string> = {
  grey: 'border-gray-50',
  'grey-500': 'border-gray-500',
  none: 'border-none',
};

type ButtonBorderWidth = 'none' | 'xs';

const buttonBorderWidthStyle: Record<ButtonBorderWidth, string> = {
  none: 'border-none',
  xs: 'border-1',
};

type ButtonWhitespace = 'pre' | 'normal' | 'nowrap';

const buttonWhitespaceStyle: Record<ButtonWhitespace, string> = {
  pre: 'whitespace-pre',
  normal: 'whitespace-normal',
  nowrap: 'whitespace-nowrap',
};

type ButtonIconAnimation = 'none' | 'spin';

const buttonIconAnimationStyle: Record<ButtonIconAnimation, string> = {
  none: '',
  spin: 'animate-spin',
};

type ButtonPosition = 'none' | 'fixedb';

const buttonPositionStyle: Record<ButtonPosition, string> = {
  none: '',
  fixedb: 'sticky bottom-2 right-0 left-0',
};

interface ButtonProps {
  content?: string;
  disabled?: boolean;
  type?: 'button' | 'submit' | 'reset';
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  iconName?: IconName;
  iconPosition?: 'left' | 'right';
  width?: ButtonWidth;
  height?: ButtonHeight;
  bgColor?: ButtonBgColor;
  textColor?: ButtonTextColor;
  textSize?: TextSize;
  borderColor?: ButtonBorderColor;
  borderWidth?: ButtonBorderWidth;
  iconColor?: IconColor;
  iconAnimation?: ButtonIconAnimation;
  whitespace?: ButtonWhitespace;
  position?: ButtonPosition;
  cursor?: CursorTypes;
  error?: boolean;
  tooltip?: string;
  rounded?: string;
}

export function Button({
  content,
  type,
  disabled,
  iconName,
  iconPosition = 'right',
  onClick,
  whitespace = 'pre',
  width = 'full',
  height = 'full',
  bgColor = 'gradient2',
  textColor = 'white',
  textSize = 'base',
  borderColor = 'none',
  borderWidth = 'none',
  iconColor = 'white',
  iconAnimation = 'none',
  position = 'none',
  cursor = 'pointer',
  error = false,
  tooltip,
  rounded = 'rounded-lg',
}: ButtonProps): JSX.Element {
  return (
    <Tooltip
      title={tooltip !== undefined ? tooltip : ''}
      followCursor
      placement="bottom-start"
      enterDelay={800}
      enterNextDelay={800}
    >
      <button
        className={concatClassNames(
          'flex',
          'flex-row',
          buttonWidthStyle[width],
          buttonHeightStyle[height],
          buttonWhitespaceStyle[whitespace],
          'gap-2.5',
          'justify-center',
          'items-center',
          'align-middle',
          'px-4 py-2.5',
          rounded,
          'bg-gradient-to-r',
          buttonBgColorStyle[bgColor],
          error ? 'border-orange-500' : buttonBorderColorStyle[borderColor],
          buttonBorderWidthStyle[borderWidth],
          buttonPositionStyle[position],
          cursorStyle[cursor],
          'disabled:from-gradient2-from/60',
          'disabled:to-gradient2-to/60',
          'disabled:pointer-events-none',
        )}
        disabled={disabled}
        type={type}
        onClick={onClick}
      >
        {iconName !== undefined && iconPosition === 'left' && (
          <div className={concatClassNames(buttonIconAnimationStyle[iconAnimation])}>
            {getIcon(iconName, iconColor, 'sm')}
          </div>
        )}
        {content !== undefined && (
          <Text content={content} size={textSize} color={textColor} whitespace="pre-line" position="center" />
        )}
        {iconName !== undefined && iconPosition === 'right' && (
          <div className={concatClassNames(buttonIconAnimationStyle[iconAnimation])}>
            {getIcon(iconName, iconColor, 'sm')}
          </div>
        )}
      </button>
    </Tooltip>
  );
}
