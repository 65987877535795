/* tslint:disable */
/* eslint-disable */
/**
 * Specks API
 * APIs for specks SaaS
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GetApiQueryParameterDto
 */
export interface GetApiQueryParameterDto {
    /**
     * 
     * @type {string}
     * @memberof GetApiQueryParameterDto
     */
    id: string;
    /**
     * 
     * @type {number}
     * @memberof GetApiQueryParameterDto
     */
    index: number;
    /**
     * 
     * @type {string}
     * @memberof GetApiQueryParameterDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof GetApiQueryParameterDto
     */
    isMandatory: GetApiQueryParameterDtoIsMandatoryEnum;
    /**
     * 
     * @type {string}
     * @memberof GetApiQueryParameterDto
     */
    valueType: GetApiQueryParameterDtoValueTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof GetApiQueryParameterDto
     */
    defaultValue: string;
}


/**
 * @export
 */
export const GetApiQueryParameterDtoIsMandatoryEnum = {
    True: 'true',
    False: 'false',
    Null: 'null'
} as const;
export type GetApiQueryParameterDtoIsMandatoryEnum = typeof GetApiQueryParameterDtoIsMandatoryEnum[keyof typeof GetApiQueryParameterDtoIsMandatoryEnum];

/**
 * @export
 */
export const GetApiQueryParameterDtoValueTypeEnum = {
    String: 'STRING',
    Number: 'NUMBER',
    Boolean: 'BOOLEAN',
    Object: 'OBJECT',
    Enum: 'ENUM',
    List: 'LIST',
    Date: 'DATE'
} as const;
export type GetApiQueryParameterDtoValueTypeEnum = typeof GetApiQueryParameterDtoValueTypeEnum[keyof typeof GetApiQueryParameterDtoValueTypeEnum];


/**
 * Check if a given object implements the GetApiQueryParameterDto interface.
 */
export function instanceOfGetApiQueryParameterDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "index" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "isMandatory" in value;
    isInstance = isInstance && "valueType" in value;
    isInstance = isInstance && "defaultValue" in value;

    return isInstance;
}

export function GetApiQueryParameterDtoFromJSON(json: any): GetApiQueryParameterDto {
    return GetApiQueryParameterDtoFromJSONTyped(json, false);
}

export function GetApiQueryParameterDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetApiQueryParameterDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'index': json['index'],
        'name': json['name'],
        'isMandatory': json['isMandatory'],
        'valueType': json['valueType'],
        'defaultValue': json['defaultValue'],
    };
}

export function GetApiQueryParameterDtoToJSON(value?: GetApiQueryParameterDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'index': value.index,
        'name': value.name,
        'isMandatory': value.isMandatory,
        'valueType': value.valueType,
        'defaultValue': value.defaultValue,
    };
}

