import { type Option, type OptionsSource } from 'components/specks/SelectInfosForm/SelectInfosForm';

export enum TargetType {
  FREE = 'FREE', // targetedFree
  // PAGE = 'PAGE',
  METHOD = 'METHOD', // targetedMethod
  NOTHING = 'NOTHING', // all undefined
}

export enum DateFormat {
  EMPTY = 'EMPTY',
  DMY_WITH_YEAR = 'DMY_WITH_YEAR',
  DMY_WITH_DATE_TIME = 'DMY_WITH_DATE_TIME',
  DM = 'DM',
  MD = 'MD',
  MMM = 'MMM',
  MMMM = 'MMMM',
  CUSTOM = 'CUSTOM',
}

export type TitleImportancy = 'UNDEFINED' | 'H1' | 'H2' | 'H3' | 'H4';

export type TextImportancy = 'UNDEFINED' | 'VERY_IMPORTANT' | 'IMPORTANT' | 'LESS_IMPORTANT';

export type YesLogical = 'YES' | 'LOGICAL';

export type ConstantLogical = 'CONSTANT' | 'LOGICAL';

export type YesNoLogical = 'YES' | 'NO' | 'LOGICAL';

export class UiComponentRevision {
  // Generic

  id: string;

  name: string;

  isFormValid: boolean;

  notes: string;

  createdAt: Date;

  index: number;

  parentId: string | undefined;

  // Button

  targetType: TargetType;

  targetMethodId: string | undefined;

  targetFree: string;

  // Button, Title

  sourceMethodId: string | undefined;

  contentType: ConstantLogical;

  sourceConstant: string;

  sourceLogicalRule: string;

  // Title

  isVisible: YesLogical;

  isVisibleLogicalRule: string;

  titleImportancy: TitleImportancy;

  textImportancy: TextImportancy;

  // Input

  isEditable: YesNoLogical;

  isEditableLogicalRule: string;

  isPreFilled: YesNoLogical;

  isPreFilledConstant: string;

  isPreFilledLogicalRule: string;

  hasPlaceholder: YesNoLogical;

  hasPlaceholderConstant: string;

  hasPlaceholderLogicalRule: string;

  isMandatory: YesNoLogical;

  isMandatoryLogicalRule: string;

  hasPattern: YesNoLogical;

  hasPatternConstant: string;

  hasPatternLogicalRule: string;

  label: string;

  dateFormat: DateFormat;

  dateCustom: string;

  usedFor: string;

  latest: boolean;

  // Select

  isMultiChoiceAllowed: YesNoLogical;

  isMultiChoiceAllowedLogicalRule: string;

  optionsSource: OptionsSource;

  options: Option[];
}
