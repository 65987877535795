/* tslint:disable */
/* eslint-disable */
/**
 * Specks API
 * APIs for specks SaaS
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    CreateUicPositionButtonDto,
    instanceOfCreateUicPositionButtonDto,
    CreateUicPositionButtonDtoFromJSON,
    CreateUicPositionButtonDtoFromJSONTyped,
    CreateUicPositionButtonDtoToJSON,
} from './CreateUicPositionButtonDto';
import {
    CreateUicPositionContainerDto,
    instanceOfCreateUicPositionContainerDto,
    CreateUicPositionContainerDtoFromJSON,
    CreateUicPositionContainerDtoFromJSONTyped,
    CreateUicPositionContainerDtoToJSON,
} from './CreateUicPositionContainerDto';
import {
    CreateUicPositionDateDto,
    instanceOfCreateUicPositionDateDto,
    CreateUicPositionDateDtoFromJSON,
    CreateUicPositionDateDtoFromJSONTyped,
    CreateUicPositionDateDtoToJSON,
} from './CreateUicPositionDateDto';
import {
    CreateUicPositionIconDto,
    instanceOfCreateUicPositionIconDto,
    CreateUicPositionIconDtoFromJSON,
    CreateUicPositionIconDtoFromJSONTyped,
    CreateUicPositionIconDtoToJSON,
} from './CreateUicPositionIconDto';
import {
    CreateUicPositionInputDto,
    instanceOfCreateUicPositionInputDto,
    CreateUicPositionInputDtoFromJSON,
    CreateUicPositionInputDtoFromJSONTyped,
    CreateUicPositionInputDtoToJSON,
} from './CreateUicPositionInputDto';
import {
    CreateUicPositionListDto,
    instanceOfCreateUicPositionListDto,
    CreateUicPositionListDtoFromJSON,
    CreateUicPositionListDtoFromJSONTyped,
    CreateUicPositionListDtoToJSON,
} from './CreateUicPositionListDto';
import {
    CreateUicPositionMediaDto,
    instanceOfCreateUicPositionMediaDto,
    CreateUicPositionMediaDtoFromJSON,
    CreateUicPositionMediaDtoFromJSONTyped,
    CreateUicPositionMediaDtoToJSON,
} from './CreateUicPositionMediaDto';
import {
    CreateUicPositionSelectDto,
    instanceOfCreateUicPositionSelectDto,
    CreateUicPositionSelectDtoFromJSON,
    CreateUicPositionSelectDtoFromJSONTyped,
    CreateUicPositionSelectDtoToJSON,
} from './CreateUicPositionSelectDto';
import {
    CreateUicPositionTextDto,
    instanceOfCreateUicPositionTextDto,
    CreateUicPositionTextDtoFromJSON,
    CreateUicPositionTextDtoFromJSONTyped,
    CreateUicPositionTextDtoToJSON,
} from './CreateUicPositionTextDto';
import {
    CreateUicPositionTitleDto,
    instanceOfCreateUicPositionTitleDto,
    CreateUicPositionTitleDtoFromJSON,
    CreateUicPositionTitleDtoFromJSONTyped,
    CreateUicPositionTitleDtoToJSON,
} from './CreateUicPositionTitleDto';

/**
 * @type PageControllerCreateUiComponentRequest
 * 
 * @export
 */
export type PageControllerCreateUiComponentRequest = { type: 'button' } & CreateUicPositionButtonDto | { type: 'container' } & CreateUicPositionContainerDto | { type: 'date' } & CreateUicPositionDateDto | { type: 'icon' } & CreateUicPositionIconDto | { type: 'input' } & CreateUicPositionInputDto | { type: 'list' } & CreateUicPositionListDto | { type: 'media' } & CreateUicPositionMediaDto | { type: 'select' } & CreateUicPositionSelectDto | { type: 'text' } & CreateUicPositionTextDto | { type: 'title' } & CreateUicPositionTitleDto;

export function PageControllerCreateUiComponentRequestFromJSON(json: any): PageControllerCreateUiComponentRequest {
    return PageControllerCreateUiComponentRequestFromJSONTyped(json, false);
}

export function PageControllerCreateUiComponentRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): PageControllerCreateUiComponentRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    switch (json['type']) {
        case 'button':
            return {...CreateUicPositionButtonDtoFromJSONTyped(json, true), type: 'button'};
        case 'container':
            return {...CreateUicPositionContainerDtoFromJSONTyped(json, true), type: 'container'};
        case 'date':
            return {...CreateUicPositionDateDtoFromJSONTyped(json, true), type: 'date'};
        case 'icon':
            return {...CreateUicPositionIconDtoFromJSONTyped(json, true), type: 'icon'};
        case 'input':
            return {...CreateUicPositionInputDtoFromJSONTyped(json, true), type: 'input'};
        case 'list':
            return {...CreateUicPositionListDtoFromJSONTyped(json, true), type: 'list'};
        case 'media':
            return {...CreateUicPositionMediaDtoFromJSONTyped(json, true), type: 'media'};
        case 'select':
            return {...CreateUicPositionSelectDtoFromJSONTyped(json, true), type: 'select'};
        case 'text':
            return {...CreateUicPositionTextDtoFromJSONTyped(json, true), type: 'text'};
        case 'title':
            return {...CreateUicPositionTitleDtoFromJSONTyped(json, true), type: 'title'};
        default:
            throw new Error(`No variant of PageControllerCreateUiComponentRequest exists with 'type=${json['type']}'`);
    }
}

export function PageControllerCreateUiComponentRequestToJSON(value?: PageControllerCreateUiComponentRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    switch (value['type']) {
        case 'button':
            return CreateUicPositionButtonDtoToJSON(value);
        case 'container':
            return CreateUicPositionContainerDtoToJSON(value);
        case 'date':
            return CreateUicPositionDateDtoToJSON(value);
        case 'icon':
            return CreateUicPositionIconDtoToJSON(value);
        case 'input':
            return CreateUicPositionInputDtoToJSON(value);
        case 'list':
            return CreateUicPositionListDtoToJSON(value);
        case 'media':
            return CreateUicPositionMediaDtoToJSON(value);
        case 'select':
            return CreateUicPositionSelectDtoToJSON(value);
        case 'text':
            return CreateUicPositionTextDtoToJSON(value);
        case 'title':
            return CreateUicPositionTitleDtoToJSON(value);
        default:
            throw new Error(`No variant of PageControllerCreateUiComponentRequest exists with 'type=${value['type']}'`);
    }

}

