/* tslint:disable */
/* eslint-disable */
/**
 * Specks API
 * APIs for specks SaaS
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Link } from './Link';
import {
    LinkFromJSON,
    LinkFromJSONTyped,
    LinkToJSON,
} from './Link';
import type { LogicalRule } from './LogicalRule';
import {
    LogicalRuleFromJSON,
    LogicalRuleFromJSONTyped,
    LogicalRuleToJSON,
} from './LogicalRule';
import type { Method } from './Method';
import {
    MethodFromJSON,
    MethodFromJSONTyped,
    MethodToJSON,
} from './Method';
import type { Option } from './Option';
import {
    OptionFromJSON,
    OptionFromJSONTyped,
    OptionToJSON,
} from './Option';
import type { UiComponent } from './UiComponent';
import {
    UiComponentFromJSON,
    UiComponentFromJSONTyped,
    UiComponentToJSON,
} from './UiComponent';

/**
 * 
 * @export
 * @interface UiComponentRevision
 */
export interface UiComponentRevision {
    /**
     * 
     * @type {string}
     * @memberof UiComponentRevision
     */
    id: string;
    /**
     * 
     * @type {boolean}
     * @memberof UiComponentRevision
     */
    isFormValid: boolean;
    /**
     * 
     * @type {string}
     * @memberof UiComponentRevision
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof UiComponentRevision
     */
    notes: string;
    /**
     * 
     * @type {string}
     * @memberof UiComponentRevision
     */
    usedFor: string;
    /**
     * 
     * @type {string}
     * @memberof UiComponentRevision
     */
    contentType: UiComponentRevisionContentTypeEnum;
    /**
     * 
     * @type {Method}
     * @memberof UiComponentRevision
     */
    sourceMethod: Method;
    /**
     * 
     * @type {string}
     * @memberof UiComponentRevision
     */
    sourceConstant: string;
    /**
     * 
     * @type {LogicalRule}
     * @memberof UiComponentRevision
     */
    sourceLogicalRule: LogicalRule;
    /**
     * 
     * @type {string}
     * @memberof UiComponentRevision
     */
    targetType: UiComponentRevisionTargetTypeEnum;
    /**
     * 
     * @type {Method}
     * @memberof UiComponentRevision
     */
    targetMethod: Method;
    /**
     * 
     * @type {string}
     * @memberof UiComponentRevision
     */
    targetFree: string;
    /**
     * 
     * @type {string}
     * @memberof UiComponentRevision
     */
    optionsSource: UiComponentRevisionOptionsSourceEnum;
    /**
     * 
     * @type {string}
     * @memberof UiComponentRevision
     */
    isVisible: UiComponentRevisionIsVisibleEnum;
    /**
     * 
     * @type {LogicalRule}
     * @memberof UiComponentRevision
     */
    isVisibleLogicalRule: LogicalRule;
    /**
     * 
     * @type {string}
     * @memberof UiComponentRevision
     */
    titleImportancy: UiComponentRevisionTitleImportancyEnum;
    /**
     * 
     * @type {boolean}
     * @memberof UiComponentRevision
     */
    latest: boolean;
    /**
     * 
     * @type {string}
     * @memberof UiComponentRevision
     */
    textImportancy: UiComponentRevisionTextImportancyEnum;
    /**
     * 
     * @type {string}
     * @memberof UiComponentRevision
     */
    isEditable: UiComponentRevisionIsEditableEnum;
    /**
     * 
     * @type {LogicalRule}
     * @memberof UiComponentRevision
     */
    isEditableLogicalRule: LogicalRule;
    /**
     * 
     * @type {string}
     * @memberof UiComponentRevision
     */
    isPreFilled: UiComponentRevisionIsPreFilledEnum;
    /**
     * 
     * @type {string}
     * @memberof UiComponentRevision
     */
    isPreFilledConstant: string;
    /**
     * 
     * @type {LogicalRule}
     * @memberof UiComponentRevision
     */
    isPreFilledLogicalRule: LogicalRule;
    /**
     * 
     * @type {string}
     * @memberof UiComponentRevision
     */
    hasPlaceholder: UiComponentRevisionHasPlaceholderEnum;
    /**
     * 
     * @type {string}
     * @memberof UiComponentRevision
     */
    hasPlaceholderConstant: string;
    /**
     * 
     * @type {LogicalRule}
     * @memberof UiComponentRevision
     */
    hasPlaceholderLogicalRule: LogicalRule;
    /**
     * 
     * @type {string}
     * @memberof UiComponentRevision
     */
    isMandatory: UiComponentRevisionIsMandatoryEnum;
    /**
     * 
     * @type {LogicalRule}
     * @memberof UiComponentRevision
     */
    isMandatoryLogicalRule: LogicalRule;
    /**
     * 
     * @type {string}
     * @memberof UiComponentRevision
     */
    hasPattern: UiComponentRevisionHasPatternEnum;
    /**
     * 
     * @type {string}
     * @memberof UiComponentRevision
     */
    hasPatternConstant: string;
    /**
     * 
     * @type {LogicalRule}
     * @memberof UiComponentRevision
     */
    hasPatternLogicalRule: LogicalRule;
    /**
     * 
     * @type {string}
     * @memberof UiComponentRevision
     */
    label: string;
    /**
     * 
     * @type {string}
     * @memberof UiComponentRevision
     */
    dateFormat: UiComponentRevisionDateFormatEnum;
    /**
     * 
     * @type {string}
     * @memberof UiComponentRevision
     */
    dateCustom: string;
    /**
     * 
     * @type {string}
     * @memberof UiComponentRevision
     */
    isMultiChoiceAllowed: UiComponentRevisionIsMultiChoiceAllowedEnum;
    /**
     * 
     * @type {LogicalRule}
     * @memberof UiComponentRevision
     */
    isMultiChoiceAllowedLogicalRule: LogicalRule;
    /**
     * 
     * @type {Date}
     * @memberof UiComponentRevision
     */
    createdAt: Date;
    /**
     * 
     * @type {UiComponent}
     * @memberof UiComponentRevision
     */
    uiComponent: UiComponent;
    /**
     * 
     * @type {Array<Link>}
     * @memberof UiComponentRevision
     */
    bearerLinks: Array<Link>;
    /**
     * 
     * @type {Array<Option>}
     * @memberof UiComponentRevision
     */
    options: Array<Option>;
}


/**
 * @export
 */
export const UiComponentRevisionContentTypeEnum = {
    Constant: 'CONSTANT',
    Logical: 'LOGICAL'
} as const;
export type UiComponentRevisionContentTypeEnum = typeof UiComponentRevisionContentTypeEnum[keyof typeof UiComponentRevisionContentTypeEnum];

/**
 * @export
 */
export const UiComponentRevisionTargetTypeEnum = {
    Free: 'FREE',
    Method: 'METHOD',
    Nothing: 'NOTHING'
} as const;
export type UiComponentRevisionTargetTypeEnum = typeof UiComponentRevisionTargetTypeEnum[keyof typeof UiComponentRevisionTargetTypeEnum];

/**
 * @export
 */
export const UiComponentRevisionOptionsSourceEnum = {
    Method: 'METHOD',
    Free: 'FREE'
} as const;
export type UiComponentRevisionOptionsSourceEnum = typeof UiComponentRevisionOptionsSourceEnum[keyof typeof UiComponentRevisionOptionsSourceEnum];

/**
 * @export
 */
export const UiComponentRevisionIsVisibleEnum = {
    Yes: 'YES',
    Logical: 'LOGICAL'
} as const;
export type UiComponentRevisionIsVisibleEnum = typeof UiComponentRevisionIsVisibleEnum[keyof typeof UiComponentRevisionIsVisibleEnum];

/**
 * @export
 */
export const UiComponentRevisionTitleImportancyEnum = {
    Undefined: 'UNDEFINED',
    H1: 'H1',
    H2: 'H2',
    H3: 'H3',
    H4: 'H4'
} as const;
export type UiComponentRevisionTitleImportancyEnum = typeof UiComponentRevisionTitleImportancyEnum[keyof typeof UiComponentRevisionTitleImportancyEnum];

/**
 * @export
 */
export const UiComponentRevisionTextImportancyEnum = {
    Undefined: 'UNDEFINED',
    VeryImportant: 'VERY_IMPORTANT',
    Important: 'IMPORTANT',
    LessImportant: 'LESS_IMPORTANT'
} as const;
export type UiComponentRevisionTextImportancyEnum = typeof UiComponentRevisionTextImportancyEnum[keyof typeof UiComponentRevisionTextImportancyEnum];

/**
 * @export
 */
export const UiComponentRevisionIsEditableEnum = {
    Yes: 'YES',
    No: 'NO',
    Logical: 'LOGICAL'
} as const;
export type UiComponentRevisionIsEditableEnum = typeof UiComponentRevisionIsEditableEnum[keyof typeof UiComponentRevisionIsEditableEnum];

/**
 * @export
 */
export const UiComponentRevisionIsPreFilledEnum = {
    Yes: 'YES',
    No: 'NO',
    Logical: 'LOGICAL'
} as const;
export type UiComponentRevisionIsPreFilledEnum = typeof UiComponentRevisionIsPreFilledEnum[keyof typeof UiComponentRevisionIsPreFilledEnum];

/**
 * @export
 */
export const UiComponentRevisionHasPlaceholderEnum = {
    Yes: 'YES',
    No: 'NO',
    Logical: 'LOGICAL'
} as const;
export type UiComponentRevisionHasPlaceholderEnum = typeof UiComponentRevisionHasPlaceholderEnum[keyof typeof UiComponentRevisionHasPlaceholderEnum];

/**
 * @export
 */
export const UiComponentRevisionIsMandatoryEnum = {
    Yes: 'YES',
    No: 'NO',
    Logical: 'LOGICAL'
} as const;
export type UiComponentRevisionIsMandatoryEnum = typeof UiComponentRevisionIsMandatoryEnum[keyof typeof UiComponentRevisionIsMandatoryEnum];

/**
 * @export
 */
export const UiComponentRevisionHasPatternEnum = {
    Yes: 'YES',
    No: 'NO',
    Logical: 'LOGICAL'
} as const;
export type UiComponentRevisionHasPatternEnum = typeof UiComponentRevisionHasPatternEnum[keyof typeof UiComponentRevisionHasPatternEnum];

/**
 * @export
 */
export const UiComponentRevisionDateFormatEnum = {
    Empty: 'EMPTY',
    DmyWithYear: 'DMY_WITH_YEAR',
    DmyWithDateTime: 'DMY_WITH_DATE_TIME',
    Dm: 'DM',
    Md: 'MD',
    Mmm: 'MMM',
    Mmmm: 'MMMM',
    Custom: 'CUSTOM'
} as const;
export type UiComponentRevisionDateFormatEnum = typeof UiComponentRevisionDateFormatEnum[keyof typeof UiComponentRevisionDateFormatEnum];

/**
 * @export
 */
export const UiComponentRevisionIsMultiChoiceAllowedEnum = {
    Yes: 'YES',
    No: 'NO',
    Logical: 'LOGICAL'
} as const;
export type UiComponentRevisionIsMultiChoiceAllowedEnum = typeof UiComponentRevisionIsMultiChoiceAllowedEnum[keyof typeof UiComponentRevisionIsMultiChoiceAllowedEnum];


/**
 * Check if a given object implements the UiComponentRevision interface.
 */
export function instanceOfUiComponentRevision(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "isFormValid" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "notes" in value;
    isInstance = isInstance && "usedFor" in value;
    isInstance = isInstance && "contentType" in value;
    isInstance = isInstance && "sourceMethod" in value;
    isInstance = isInstance && "sourceConstant" in value;
    isInstance = isInstance && "sourceLogicalRule" in value;
    isInstance = isInstance && "targetType" in value;
    isInstance = isInstance && "targetMethod" in value;
    isInstance = isInstance && "targetFree" in value;
    isInstance = isInstance && "optionsSource" in value;
    isInstance = isInstance && "isVisible" in value;
    isInstance = isInstance && "isVisibleLogicalRule" in value;
    isInstance = isInstance && "titleImportancy" in value;
    isInstance = isInstance && "latest" in value;
    isInstance = isInstance && "textImportancy" in value;
    isInstance = isInstance && "isEditable" in value;
    isInstance = isInstance && "isEditableLogicalRule" in value;
    isInstance = isInstance && "isPreFilled" in value;
    isInstance = isInstance && "isPreFilledConstant" in value;
    isInstance = isInstance && "isPreFilledLogicalRule" in value;
    isInstance = isInstance && "hasPlaceholder" in value;
    isInstance = isInstance && "hasPlaceholderConstant" in value;
    isInstance = isInstance && "hasPlaceholderLogicalRule" in value;
    isInstance = isInstance && "isMandatory" in value;
    isInstance = isInstance && "isMandatoryLogicalRule" in value;
    isInstance = isInstance && "hasPattern" in value;
    isInstance = isInstance && "hasPatternConstant" in value;
    isInstance = isInstance && "hasPatternLogicalRule" in value;
    isInstance = isInstance && "label" in value;
    isInstance = isInstance && "dateFormat" in value;
    isInstance = isInstance && "dateCustom" in value;
    isInstance = isInstance && "isMultiChoiceAllowed" in value;
    isInstance = isInstance && "isMultiChoiceAllowedLogicalRule" in value;
    isInstance = isInstance && "createdAt" in value;
    isInstance = isInstance && "uiComponent" in value;
    isInstance = isInstance && "bearerLinks" in value;
    isInstance = isInstance && "options" in value;

    return isInstance;
}

export function UiComponentRevisionFromJSON(json: any): UiComponentRevision {
    return UiComponentRevisionFromJSONTyped(json, false);
}

export function UiComponentRevisionFromJSONTyped(json: any, ignoreDiscriminator: boolean): UiComponentRevision {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'isFormValid': json['isFormValid'],
        'name': json['name'],
        'notes': json['notes'],
        'usedFor': json['usedFor'],
        'contentType': json['contentType'],
        'sourceMethod': MethodFromJSON(json['sourceMethod']),
        'sourceConstant': json['sourceConstant'],
        'sourceLogicalRule': LogicalRuleFromJSON(json['sourceLogicalRule']),
        'targetType': json['targetType'],
        'targetMethod': MethodFromJSON(json['targetMethod']),
        'targetFree': json['targetFree'],
        'optionsSource': json['optionsSource'],
        'isVisible': json['isVisible'],
        'isVisibleLogicalRule': LogicalRuleFromJSON(json['isVisibleLogicalRule']),
        'titleImportancy': json['titleImportancy'],
        'latest': json['latest'],
        'textImportancy': json['textImportancy'],
        'isEditable': json['isEditable'],
        'isEditableLogicalRule': LogicalRuleFromJSON(json['isEditableLogicalRule']),
        'isPreFilled': json['isPreFilled'],
        'isPreFilledConstant': json['isPreFilledConstant'],
        'isPreFilledLogicalRule': LogicalRuleFromJSON(json['isPreFilledLogicalRule']),
        'hasPlaceholder': json['hasPlaceholder'],
        'hasPlaceholderConstant': json['hasPlaceholderConstant'],
        'hasPlaceholderLogicalRule': LogicalRuleFromJSON(json['hasPlaceholderLogicalRule']),
        'isMandatory': json['isMandatory'],
        'isMandatoryLogicalRule': LogicalRuleFromJSON(json['isMandatoryLogicalRule']),
        'hasPattern': json['hasPattern'],
        'hasPatternConstant': json['hasPatternConstant'],
        'hasPatternLogicalRule': LogicalRuleFromJSON(json['hasPatternLogicalRule']),
        'label': json['label'],
        'dateFormat': json['dateFormat'],
        'dateCustom': json['dateCustom'],
        'isMultiChoiceAllowed': json['isMultiChoiceAllowed'],
        'isMultiChoiceAllowedLogicalRule': LogicalRuleFromJSON(json['isMultiChoiceAllowedLogicalRule']),
        'createdAt': (new Date(json['createdAt'])),
        'uiComponent': UiComponentFromJSON(json['uiComponent']),
        'bearerLinks': ((json['bearerLinks'] as Array<any>).map(LinkFromJSON)),
        'options': ((json['options'] as Array<any>).map(OptionFromJSON)),
    };
}

export function UiComponentRevisionToJSON(value?: UiComponentRevision | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'isFormValid': value.isFormValid,
        'name': value.name,
        'notes': value.notes,
        'usedFor': value.usedFor,
        'contentType': value.contentType,
        'sourceMethod': MethodToJSON(value.sourceMethod),
        'sourceConstant': value.sourceConstant,
        'sourceLogicalRule': LogicalRuleToJSON(value.sourceLogicalRule),
        'targetType': value.targetType,
        'targetMethod': MethodToJSON(value.targetMethod),
        'targetFree': value.targetFree,
        'optionsSource': value.optionsSource,
        'isVisible': value.isVisible,
        'isVisibleLogicalRule': LogicalRuleToJSON(value.isVisibleLogicalRule),
        'titleImportancy': value.titleImportancy,
        'latest': value.latest,
        'textImportancy': value.textImportancy,
        'isEditable': value.isEditable,
        'isEditableLogicalRule': LogicalRuleToJSON(value.isEditableLogicalRule),
        'isPreFilled': value.isPreFilled,
        'isPreFilledConstant': value.isPreFilledConstant,
        'isPreFilledLogicalRule': LogicalRuleToJSON(value.isPreFilledLogicalRule),
        'hasPlaceholder': value.hasPlaceholder,
        'hasPlaceholderConstant': value.hasPlaceholderConstant,
        'hasPlaceholderLogicalRule': LogicalRuleToJSON(value.hasPlaceholderLogicalRule),
        'isMandatory': value.isMandatory,
        'isMandatoryLogicalRule': LogicalRuleToJSON(value.isMandatoryLogicalRule),
        'hasPattern': value.hasPattern,
        'hasPatternConstant': value.hasPatternConstant,
        'hasPatternLogicalRule': LogicalRuleToJSON(value.hasPatternLogicalRule),
        'label': value.label,
        'dateFormat': value.dateFormat,
        'dateCustom': value.dateCustom,
        'isMultiChoiceAllowed': value.isMultiChoiceAllowed,
        'isMultiChoiceAllowedLogicalRule': LogicalRuleToJSON(value.isMultiChoiceAllowedLogicalRule),
        'createdAt': (value.createdAt.toISOString()),
        'uiComponent': UiComponentToJSON(value.uiComponent),
        'bearerLinks': ((value.bearerLinks as Array<any>).map(LinkToJSON)),
        'options': ((value.options as Array<any>).map(OptionToJSON)),
    };
}

