import { type Component } from './component.entity';
import { type MethodRevision } from './methodRevision.entity';
import { type Page } from './page.entity';
import { type UiComponent } from './uiComponent.entity';

export class Link {
  id: string;

  caller: Component;

  receiver: Component;

  bearerForReceiver: MethodRevision;

  bearerForCaller: UiComponent;

  bearerForCallerPage: Page;
}
