import * as Yup from 'yup';

import React, { useEffect, useState } from 'react';
import { Controller, type SubmitHandler, useForm } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';

import { Button } from 'components/core/Button/Button';
import Input from 'components/core/Input/Input';
import { ListBox } from 'components/core/ListBox/ListBox';
import RadioButtons from 'components/core/RadioButtons/RadioButtons';
import { TabElement } from 'components/core/Tabs/TabElement';
import { TabList } from 'components/core/Tabs/TabList';
import { TabPanel } from 'components/core/Tabs/TabPanel';
import { TabPanels } from 'components/core/Tabs/TabPanels';
import { Tabs } from 'components/core/Tabs/Tabs';
import { Text } from 'components/core/Text/Text';
import TextArea from 'components/core/TextArea/TextArea';

import { type DateFormat, type YesNoLogical } from 'models/ui-component-revisions.entity';
import { type UiComponent } from 'models/uiComponent.entity';

import concatClassNames from 'utils/classNames';

import UICFieldReadOnly from '../UICFieldReadonly/UICFieldReadonly';
import { UiComponentCard } from '../UiComponentCard/UiComponentCard';

interface DateInfosFormProps {
  uiComponent: UiComponent;
  checkIfNameIsUnique?: (name: string, id: string) => boolean;
  onClickSave?: () => void;
  setIsFormDirty?: (isDirty: boolean) => void;
  onClickAddTarget?: () => void;
  setFnSetMethodId?: (fn: (methodId: string | undefined) => void) => void;
  isLoading: boolean;
}

const paramsTypeOptions: YesNoLogical[] = ['YES', 'NO', 'LOGICAL'];

export const paramsTypeStyleOptions: string[] = ['Oui, toujours', 'Non', 'Règle logique'];

export type FormatType = 'EMPTY' | 'DMY_WITH_YEAR' | 'DMY_WITH_DATE_TIME' | 'DM' | 'MD' | 'MMM' | 'MMMM' | 'CUSTOM';

const formatTypeOptions: FormatType[] = [
  'EMPTY',
  'DMY_WITH_YEAR',
  'DMY_WITH_DATE_TIME',
  'DM',
  'MD',
  'MMM',
  'MMMM',
  'CUSTOM',
];

export const formatInputTypeOptions: Map<FormatType, string> = new Map<FormatType, string>([
  ['EMPTY', 'Non renseigné'],
  ['DMY_WITH_YEAR', 'jj/mm/aaaa (Ex : 13/09/2023)'],
  ['DMY_WITH_DATE_TIME', 'jj/mm/aaaa hh:mm (Ex : 13/09/2023 08:05)'],
  ['DM', 'jj/mm (Ex : 13/09)'],
  ['MD', 'mm/jj (Ex : 09/13)'],
  ['MMM', 'jj/mmm (Ex : 13 Sept.)'],
  ['MMMM', 'jj/mmmm (Ex : 13 Septembre)'],
  ['CUSTOM', 'Personnalisé'],
]);

function getFormatInputTypeOptions(key: FormatType): string {
  return formatInputTypeOptions.get(key) ?? '';
}

export const dateStyleOptions: Map<string, JSX.Element> = new Map<string, JSX.Element>([
  ['EMPTY', <Text content={getFormatInputTypeOptions('EMPTY')} size="sm" key={'EMPTY'} position="left" />],
  [
    'DMY_WITH_YEAR',
    <Text content={getFormatInputTypeOptions('DMY_WITH_YEAR')} size="sm" key={'DMY_WITH_YEAR'} position="left" />,
  ],
  [
    'DMY_WITH_DATE_TIME',
    <Text
      content={getFormatInputTypeOptions('DMY_WITH_DATE_TIME')}
      size="sm"
      key={'DMY_WITH_DATE_TIME'}
      position="left"
    />,
  ],
  ['DM', <Text content={getFormatInputTypeOptions('DM')} size="sm" key={'DM'} position="left" />],
  ['MD', <Text content={getFormatInputTypeOptions('MD')} size="sm" key={'MD'} position="left" />],
  ['MMM', <Text content={getFormatInputTypeOptions('MMM')} size="sm" key={'MMM'} position="left" />],
  ['MMMM', <Text content={getFormatInputTypeOptions('MMMM')} size="sm" key={'MMMM'} position="left" />],
  ['CUSTOM', <Text content={getFormatInputTypeOptions('CUSTOM')} size="sm" key={'CUSTOM'} position="left" />],
]);

interface DateFormModel {
  name: string;
  notes?: string;
  label?: string;

  isPreFilled: YesNoLogical;
  isPreFilledConstant?: string;
  isPreFilledLogicalRule?: string;

  hasPlaceholder: YesNoLogical;
  hasPlaceholderConstant?: string;
  hasPlaceholderLogicalRule?: string;

  isMandatory: YesNoLogical;
  isMandatoryLogicalRule?: string;

  dateFormat: FormatType;
  dateCustom?: string;
}

const schema: Yup.ObjectSchema<DateFormModel> = Yup.object().shape({
  name: Yup.string()
    .required('Champ obligatoire')
    .max(28, 'Le nom ne doit pas contenir plus de 28 caractères')
    .test('isUnique', "Un composant d'interface avec ce nom existe déjà", (value, context) => {
      const uiComponent: UiComponent | undefined = context?.options?.context?.uiComponent as UiComponent;
      const isNameUnique: boolean | undefined = context?.options?.context?.checkIfNameIsUnique(value, uiComponent.id);
      return value != null && value.length > 0 && isNameUnique;
    }),
  notes: Yup.string(),
  label: Yup.string(),
  isPreFilled: Yup.mixed<YesNoLogical>().required('Champ obligatoire').default('NO'),
  isPreFilledConstant: Yup.string(),
  isPreFilledLogicalRule: Yup.string(),

  hasPlaceholder: Yup.mixed<YesNoLogical>().required('Champ obligatoire').default('NO'),
  hasPlaceholderConstant: Yup.string(),
  hasPlaceholderLogicalRule: Yup.string(),

  isMandatory: Yup.mixed<YesNoLogical>().required('Champ obligatoire').default('YES'),
  isMandatoryLogicalRule: Yup.string(),

  dateFormat: Yup.mixed<FormatType>().required('Champ obligatoire').default('EMPTY'),
  dateCustom: Yup.string(),
});

export function DateInfosForm({
  uiComponent,
  checkIfNameIsUnique,
  onClickSave,
  setIsFormDirty,
  onClickAddTarget,
  setFnSetMethodId,
  isLoading,
}: DateInfosFormProps): JSX.Element {
  /* --------------------------------------------------- Contexts --------------------------------------------------- */

  /* ----------------------------------------------------- Form ----------------------------------------------------- */

  const {
    register,
    handleSubmit,
    control,
    watch,
    setValue,
    formState: { errors, isSubmitted },
  } = useForm<DateFormModel>({
    resolver: yupResolver(schema),
    context: {
      checkIfNameIsUnique,
      uiComponent,
    },
  });

  /* --------------------------------------------------- States --------------------------------------------------- */

  const [originalName, setOriginalName] = useState<string>();
  const [originalNotes, setOriginalNotes] = useState<string>();
  const [originalLabel, setOriginalLabel] = useState<string>();
  const [originalIsPreFilled, setOriginalIsPreFilled] = useState<YesNoLogical>();
  const [originalIsPreFilledConstant, setOriginalIsPreFilledConstant] = useState<string>();
  const [originalIsPreFilledLogicalRule, setOriginalIsPreFilledLogicalRule] = useState<string>();
  const [originalHasPlaceholder, setOriginalHasPlaceholder] = useState<YesNoLogical>();
  const [originalHasPlaceholderConstant, setOriginalHasPlaceholderConstant] = useState<string>();
  const [originalHasPlaceholderLogicalRule, setOriginalHasPlaceholderLogicalRule] = useState<string>();
  const [originalIsMandatory, setOriginalIsMandatory] = useState<YesNoLogical>();
  const [originalIsMandatoryLogicalRule, setOriginalIsMandatoryLogicalRule] = useState<string>();
  const [originalDateFormat, setOriginalDateFormat] = useState<FormatType>();
  const [originalDateCustom, setOriginalDateCustom] = useState<string>();

  const watchName: string = watch('name');
  const watchNotes: string | undefined = watch('notes');
  const watchLabel: string | undefined = watch('label');
  const watchIsPreFilled: YesNoLogical = watch('isPreFilled');
  const watchIsPreFilledConstant: string | undefined = watch('isPreFilledConstant');
  const watchIsPreFilledLogicalRule: string | undefined = watch('isPreFilledLogicalRule');
  const watchHasPlaceholder: YesNoLogical = watch('hasPlaceholder');
  const watchHasPlaceholderConstant: string | undefined = watch('hasPlaceholderConstant');
  const watchHasPlaceholderLogicalRule: string | undefined = watch('hasPlaceholderLogicalRule');
  const watchIsMandatory: YesNoLogical = watch('isMandatory');
  const watchIsMandatoryLogicalRule: string | undefined = watch('isMandatoryLogicalRule');
  const watchDateFormat: FormatType = watch('dateFormat');
  const watchDateCustom: string | undefined = watch('dateCustom');
  const isEditable: boolean = onClickSave != null;

  /* ----------------------------------------------- alerts management ---------------------------------------------- */

  const isCompleteName: boolean = watchName !== undefined && watchName.length !== 0;

  const isCompletePreFilledConstant: boolean =
    watchIsPreFilled !== 'YES' || (watchIsPreFilledConstant !== undefined && watchIsPreFilledConstant.length !== 0);

  const isCompletePreFilledLogicalRule: boolean =
    watchIsPreFilled !== 'LOGICAL' ||
    (watchIsPreFilledLogicalRule !== undefined && watchIsPreFilledLogicalRule.length !== 0);

  const isCompleteHasPlaceholderConstant: boolean =
    watchHasPlaceholder !== 'YES' ||
    (watchHasPlaceholderConstant !== undefined && watchHasPlaceholderConstant.length !== 0);

  const isCompleteHasPlaceholderLogicalRule: boolean =
    watchHasPlaceholder !== 'LOGICAL' ||
    (watchHasPlaceholderLogicalRule !== undefined && watchHasPlaceholderLogicalRule.length !== 0);

  const isCompleteMandatoryLogicalRule: boolean =
    watchIsMandatory !== 'LOGICAL' ||
    (watchIsMandatoryLogicalRule !== undefined && watchIsMandatoryLogicalRule.length !== 0);

  const isCompleteDate: boolean = watchDateFormat !== undefined && watchDateFormat !== 'EMPTY';
  const isCompleteDateCustom: boolean =
    watchDateFormat !== 'CUSTOM' || (watchDateCustom !== undefined && watchDateCustom.length !== 0);

  /* -------------------------------------------------- Setup Data -------------------------------------------------- */

  // Setup the original values of the form (from the database)
  useEffect(() => {
    setOriginalName(uiComponent.uiComponentRevisions[0].name);
    setOriginalNotes(uiComponent.uiComponentRevisions[0].notes);
    setOriginalLabel(uiComponent.uiComponentRevisions[0].label);
    setOriginalIsPreFilled(uiComponent.uiComponentRevisions[0].isPreFilled);
    setOriginalIsPreFilledConstant(uiComponent.uiComponentRevisions[0].isPreFilledConstant);
    setOriginalIsPreFilledLogicalRule(uiComponent.uiComponentRevisions[0].isPreFilledLogicalRule);
    setOriginalHasPlaceholder(uiComponent.uiComponentRevisions[0].hasPlaceholder);
    setOriginalHasPlaceholderConstant(uiComponent.uiComponentRevisions[0].hasPlaceholderConstant);
    setOriginalHasPlaceholderLogicalRule(uiComponent.uiComponentRevisions[0].hasPlaceholderLogicalRule);
    setOriginalIsMandatory(uiComponent.uiComponentRevisions[0].isMandatory);
    setOriginalIsMandatoryLogicalRule(uiComponent.uiComponentRevisions[0].isMandatoryLogicalRule);
    setOriginalDateFormat(uiComponent.uiComponentRevisions[0].dateFormat);
    setOriginalDateCustom(uiComponent.uiComponentRevisions[0].dateCustom);

    setValue('name', uiComponent.uiComponentRevisions[0].name);
    setValue('notes', uiComponent.uiComponentRevisions[0].notes);
    setValue('label', uiComponent.uiComponentRevisions[0].label);
    setValue('isPreFilled', uiComponent.uiComponentRevisions[0].isPreFilled);
    setValue('isPreFilledConstant', uiComponent.uiComponentRevisions[0].isPreFilledConstant);
    setValue('isPreFilledLogicalRule', uiComponent.uiComponentRevisions[0].isPreFilledLogicalRule);
    setValue('hasPlaceholder', uiComponent.uiComponentRevisions[0].hasPlaceholder);
    setValue('hasPlaceholderConstant', uiComponent.uiComponentRevisions[0].hasPlaceholderConstant);
    setValue('hasPlaceholderLogicalRule', uiComponent.uiComponentRevisions[0].hasPlaceholderLogicalRule);
    setValue('isMandatory', uiComponent.uiComponentRevisions[0].isMandatory);
    setValue('isMandatoryLogicalRule', uiComponent.uiComponentRevisions[0].isMandatoryLogicalRule);
    setValue('dateFormat', uiComponent.uiComponentRevisions[0].dateFormat);
    setValue('dateCustom', uiComponent.uiComponentRevisions[0].dateCustom);
  }, [setValue, uiComponent]);

  /* --------------------------------------------------- Functions -------------------------------------------------- */

  function handleOnClickSave(): void {
    if (onClickSave == null) return;
    uiComponent.uiComponentRevisions[0].name = watchName;
    uiComponent.uiComponentRevisions[0].notes = watchNotes ?? '';
    uiComponent.uiComponentRevisions[0].label = watchLabel ?? '';
    uiComponent.uiComponentRevisions[0].isPreFilled = watchIsPreFilled;
    uiComponent.uiComponentRevisions[0].isPreFilledConstant = watchIsPreFilledConstant ?? '';
    uiComponent.uiComponentRevisions[0].isPreFilledLogicalRule = watchIsPreFilledLogicalRule ?? '';
    uiComponent.uiComponentRevisions[0].hasPlaceholder = watchHasPlaceholder;
    uiComponent.uiComponentRevisions[0].hasPlaceholderConstant = watchHasPlaceholderConstant ?? '';
    uiComponent.uiComponentRevisions[0].hasPlaceholderLogicalRule = watchHasPlaceholderLogicalRule ?? '';
    uiComponent.uiComponentRevisions[0].isMandatory = watchIsMandatory;
    uiComponent.uiComponentRevisions[0].isMandatoryLogicalRule = watchIsMandatoryLogicalRule ?? '';

    uiComponent.uiComponentRevisions[0].dateFormat = (watchDateFormat as DateFormat) ?? 'EMPTY';
    uiComponent.uiComponentRevisions[0].dateCustom = watchDateCustom ?? '';
    uiComponent.uiComponentRevisions[0].isFormValid = checkIfFormIsComplete;
    onClickSave();
  }

  const onSubmitForm: SubmitHandler<DateFormModel> = (data: DateFormModel) => {
    handleOnClickSave();
  };

  /* --------------------------------------------------- Watchers --------------------------------------------------- */

  const checkIfFormIsComplete: boolean =
    isCompleteName &&
    isCompletePreFilledConstant &&
    isCompletePreFilledLogicalRule &&
    isCompleteHasPlaceholderConstant &&
    isCompleteHasPlaceholderLogicalRule &&
    isCompleteMandatoryLogicalRule &&
    isCompleteDate &&
    isCompleteDateCustom;

  let isFormValid: boolean = true;
  if (
    watchName === undefined ||
    watchName.length === 0 ||
    (watchName === originalName &&
      watchNotes === originalNotes &&
      watchLabel === originalLabel &&
      watchIsPreFilled === originalIsPreFilled &&
      watchIsPreFilledConstant === originalIsPreFilledConstant &&
      watchIsPreFilledLogicalRule === originalIsPreFilledLogicalRule &&
      watchHasPlaceholder === originalHasPlaceholder &&
      watchHasPlaceholderConstant === originalHasPlaceholderConstant &&
      watchHasPlaceholderLogicalRule === originalHasPlaceholderLogicalRule &&
      watchIsMandatory === originalIsMandatory &&
      watchIsMandatoryLogicalRule === originalIsMandatoryLogicalRule &&
      watchDateFormat === originalDateFormat &&
      watchDateCustom === originalDateCustom)
  ) {
    isFormValid = false;
  }
  setIsFormDirty?.(false);
  if (
    watchName !== originalName ||
    watchNotes !== originalNotes ||
    watchLabel !== originalLabel ||
    watchIsPreFilled !== originalIsPreFilled ||
    watchIsPreFilledConstant !== originalIsPreFilledConstant ||
    watchIsPreFilledLogicalRule !== originalIsPreFilledLogicalRule ||
    watchHasPlaceholder !== originalHasPlaceholder ||
    watchHasPlaceholderConstant !== originalHasPlaceholderConstant ||
    watchHasPlaceholderLogicalRule !== originalHasPlaceholderLogicalRule ||
    watchIsMandatory !== originalIsMandatory ||
    watchIsMandatoryLogicalRule !== originalIsMandatoryLogicalRule ||
    watchDateFormat !== originalDateFormat ||
    watchDateCustom !== originalDateCustom
  ) {
    setIsFormDirty?.(true);
  }

  /* --------------------------------------------------- Rendering -------------------------------------------------- */

  return (
    <Tabs>
      <TabList>
        <TabElement textSize="base" title={'Spécification'}></TabElement>
        <TabElement textSize="base" title={'Règles logiques'}></TabElement>
      </TabList>
      <TabPanels>
        <TabPanel>
          <form
            className="flex flex-col gap-6 justify-between flex-grow overflow-auto mt-2"
            onSubmit={handleSubmit(onSubmitForm)}
          >
            <div className="flex flex-col gap-6 overflow-auto">
              <div className={concatClassNames('flex flex-col', 'gap-1')}>
                {!isEditable && (
                  <UICFieldReadOnly
                    label="Identifiant Specks"
                    information="Cet identifiant vous permettra de le retrouver plus facilement dans Specks."
                    content={watchName}
                  />
                )}
                {isEditable && (
                  <Input
                    label="Identifiant Specks"
                    labelWeight="medium"
                    information="Cet identifiant vous permettra de le retrouver plus facilement dans Specks."
                    placeholder="L'identifiant Specks de votre composant d'interface"
                    textSize="base"
                    {...register('name')}
                    maxLength={28}
                  />
                )}
                {watchName !== undefined && watchName.length === 0 && (
                  <Text content="Le nom est obligatoire" color="red-500" size="sm" position="center" />
                )}
              </div>

              <div className={concatClassNames('flex flex-col', 'gap-1')}>
                <UICFieldReadOnly
                  label="Label"
                  information="Quel label doit inviter l'utilisateur à saisir cette date?"
                  content={
                    isEditable ? undefined : watchLabel === undefined || watchLabel.length === 0 ? 'N/A' : watchLabel
                  }
                  error="Préconisation : ce champ ne devrait pas être vide"
                />
                {isEditable && (
                  <Input
                    labelWeight="medium"
                    placeholder={`Ex : "Date de naissance" ou "Quelle est votre date de naissance?"`}
                    {...register('label')}
                    textSize="base"
                    error={errors.label != null && isSubmitted ? errors.label.message : undefined}
                  />
                )}
              </div>

              <div className={concatClassNames('flex flex-col', 'gap-2')}>
                <UICFieldReadOnly
                  label="Préremplissage ?"
                  information="Cette date doit-elle être préremplie lorsque l'utilisateur accède à la page ?"
                  isComplete={isCompletePreFilledConstant && isCompletePreFilledLogicalRule}
                  error="Préconisation : ce champ ne devrait pas être vide"
                />
                <RadioButtons
                  values={paramsTypeOptions}
                  valuesDisplay={paramsTypeStyleOptions}
                  selected={watchIsPreFilled}
                  {...register('isPreFilled')}
                  isEditable={isEditable}
                  isComplete={isCompletePreFilledConstant && isCompletePreFilledLogicalRule}
                  error="Préconisation : ce champ ne devrait pas être vide"
                />

                {watchIsPreFilled === 'YES' && (
                  <>
                    {isEditable && (
                      <>
                        <TextArea
                          {...register('isPreFilledConstant')}
                          error={
                            errors.isPreFilledConstant != null && isSubmitted
                              ? errors.isPreFilledConstant.message
                              : undefined
                          }
                          value={watchIsPreFilledConstant}
                          rows={2}
                          placeholder="Saisissez la date qui sera pré-remplie lorsque l'utilisateur accèdera à la page"
                          disabled={!isEditable}
                          isComplete={isCompletePreFilledConstant}
                        />
                        {watchIsPreFilledConstant?.length === 0 && (
                          <Text
                            content="Vous devez renseigner la donnée à pré-remplir."
                            color="red-500"
                            size="sm"
                            position="center"
                          />
                        )}
                      </>
                    )}
                    {!isEditable && (
                      <UICFieldReadOnly
                        content={
                          watchIsPreFilledConstant === undefined || watchIsPreFilledConstant.length === 0
                            ? 'N/A'
                            : watchIsPreFilledConstant
                        }
                      />
                    )}
                  </>
                )}
                {watchIsPreFilled === 'LOGICAL' && (
                  <>
                    {isEditable && (
                      <TextArea
                        {...register('isPreFilledLogicalRule')}
                        value={watchIsPreFilledLogicalRule}
                        error={
                          errors.isPreFilledLogicalRule != null && isSubmitted
                            ? errors.isPreFilledLogicalRule.message
                            : undefined
                        }
                        rows={4}
                        placeholder={`Etant données [les hypothèses suivantes], lorsque [tel événement se produit] alors [voici le comportement attendu] `}
                        isComplete={isCompletePreFilledLogicalRule}
                      />
                    )}
                    {!isEditable && (
                      <UICFieldReadOnly
                        content={
                          watchIsPreFilledLogicalRule === undefined || watchIsPreFilledLogicalRule.length === 0
                            ? 'N/A'
                            : watchIsPreFilledLogicalRule
                        }
                      />
                    )}
                  </>
                )}
              </div>

              <div className={concatClassNames('flex flex-col', 'gap-2')}>
                <UICFieldReadOnly
                  label="Placeholder ?"
                  information="Souhaitez-vous afficher un texte d'exemple (placeholder) à l'intérieur de la zone de saisie pour guider l'utilisateur dans sa saisie ?"
                  isComplete={isCompleteHasPlaceholderConstant && isCompleteHasPlaceholderLogicalRule}
                  error="Préconisation : ce champ ne devrait pas être vide"
                />
                <RadioButtons
                  values={paramsTypeOptions}
                  valuesDisplay={paramsTypeStyleOptions}
                  selected={watchHasPlaceholder}
                  {...register('hasPlaceholder')}
                  isEditable={isEditable}
                  isComplete={isCompleteHasPlaceholderConstant && isCompleteHasPlaceholderLogicalRule}
                  error="Préconisation : ce champ ne devrait pas être vide"
                />

                {watchHasPlaceholder === 'YES' && (
                  <>
                    {isEditable && (
                      <>
                        <TextArea
                          {...register('hasPlaceholderConstant')}
                          value={
                            !isEditable &&
                            (watchHasPlaceholderConstant === undefined || watchHasPlaceholderConstant.length === 0)
                              ? 'N/A'
                              : watchHasPlaceholderConstant
                          }
                          rows={2}
                          placeholder="Saisissez le placeholder qui guidera l'utilisateur (-> ceci est un placeholder ! 😉)"
                          disabled={!isEditable}
                          isComplete={isCompleteHasPlaceholderConstant}
                        />
                        {watchHasPlaceholderConstant?.length === 0 && (
                          <Text
                            content="Vous devez renseigner la donnée à insérer dans le placeholder."
                            color="red-500"
                            size="sm"
                            position="center"
                          />
                        )}
                      </>
                    )}

                    {!isEditable && (
                      <UICFieldReadOnly
                        content={
                          watchHasPlaceholderConstant === undefined || watchHasPlaceholderConstant.length === 0
                            ? 'N/A'
                            : watchHasPlaceholderConstant
                        }
                      />
                    )}
                  </>
                )}
                {watchHasPlaceholder === 'LOGICAL' && (
                  <>
                    {isEditable && (
                      <TextArea
                        {...register('hasPlaceholderLogicalRule')}
                        value={watchHasPlaceholderLogicalRule}
                        rows={4}
                        placeholder={`Etant données [les hypothèses suivantes], lorsque [tel événement se produit] alors [voici le comportement attendu] `}
                        isComplete={isCompleteHasPlaceholderLogicalRule}
                      />
                    )}

                    {!isEditable && (
                      <UICFieldReadOnly
                        content={
                          watchHasPlaceholderLogicalRule === undefined || watchHasPlaceholderLogicalRule.length === 0
                            ? 'N/A'
                            : watchHasPlaceholderLogicalRule
                        }
                      />
                    )}
                  </>
                )}
              </div>

              <div className={concatClassNames('flex flex-col', 'gap-2')}>
                <UICFieldReadOnly
                  label="Obligatoire ?"
                  information="La saisie de cette date est-elle obligatoire pour l'utilisateur?"
                  isComplete={isCompleteMandatoryLogicalRule}
                  error="Préconisation : ce champ ne devrait pas être vide"
                />
                <RadioButtons
                  values={paramsTypeOptions}
                  valuesDisplay={paramsTypeStyleOptions}
                  selected={watchIsMandatory}
                  {...register('isMandatory')}
                  isEditable={isEditable}
                  isComplete={isCompleteMandatoryLogicalRule}
                  error="Préconisation : ce champ ne devrait pas être vide"
                />

                {watchIsMandatory === 'LOGICAL' && (
                  <>
                    {isEditable && (
                      <TextArea
                        {...register('isMandatoryLogicalRule')}
                        rows={4}
                        value={watchIsMandatoryLogicalRule}
                        placeholder={`Etant données [les hypothèses suivantes], lorsque [tel événement se produit] alors [voici le comportement attendu] `}
                        disabled={!isEditable}
                        isComplete={isCompleteMandatoryLogicalRule}
                      />
                    )}
                    {!isEditable && (
                      <UICFieldReadOnly
                        content={
                          watchIsMandatoryLogicalRule === undefined || watchIsMandatoryLogicalRule.length === 0
                            ? 'N/A'
                            : watchIsMandatoryLogicalRule
                        }
                      />
                    )}
                  </>
                )}
              </div>

              <div className={concatClassNames('flex flex-col', 'gap-2')}>
                <>
                  <UICFieldReadOnly
                    label="Format"
                    information="Dans quel format l'utilisateur doit-il renseigner cette date?"
                    content={
                      isEditable
                        ? undefined
                        : watchDateFormat !== 'CUSTOM'
                        ? getFormatInputTypeOptions(watchDateFormat)
                        : watchDateCustom === undefined || watchDateCustom.length === 0
                        ? 'N/A'
                        : watchDateCustom
                    }
                    isComplete={isCompleteDate && isCompleteDateCustom}
                    error="Préconisation : ce champ ne devrait pas être vide"
                  />
                  {isEditable && (
                    <>
                      <Controller
                        name="dateFormat"
                        control={control}
                        defaultValue={'EMPTY'}
                        render={({ field: { value, onChange } }) => (
                          <ListBox
                            isComplete={isCompleteDate}
                            labelSize="lg"
                            selected={value}
                            onChange={onChange}
                            options={formatTypeOptions}
                            styleOptions={dateStyleOptions}
                            itemsBorder
                            readonly={!isEditable}
                          />
                        )}
                      />
                      {watchDateFormat === 'CUSTOM' && (
                        <>
                          <TextArea
                            labelSize="base"
                            labelWeight="medium"
                            labelColor="gray-700"
                            placeholder="Ajoutez une description"
                            {...register('dateCustom')}
                            value={watchDateCustom}
                            textSize="sm"
                            disabled={!isEditable}
                            isComplete={isCompleteDateCustom}
                          />
                          {watchDateCustom?.length === 0 && (
                            <Text
                              content="Vous devez renseigner une description."
                              color="red-500"
                              size="sm"
                              position="center"
                            />
                          )}
                        </>
                      )}
                    </>
                  )}
                </>
              </div>

              {isEditable && (
                <TextArea
                  label="Notes"
                  labelWeight="medium"
                  labelColor="gray-700"
                  placeholder="Ajouter une note"
                  {...register('notes')}
                  value={watchNotes}
                  rows={6}
                  disabled={!isEditable}
                />
              )}
              {!isEditable && (
                <UICFieldReadOnly
                  label="Notes"
                  content={watchNotes === undefined || watchNotes.length === 0 ? 'N/A' : watchNotes}
                />
              )}
            </div>

            {onClickSave != null && (
              <div className="flex flex-row gap-2 justify-end">
                {!isLoading && <Button content="Sauvegarder" type="submit" disabled={!isFormValid} />}
                {isLoading && <Button iconName="spinCircle" type="button" height="sm" iconAnimation="spin" />}
              </div>
            )}
          </form>
        </TabPanel>
        <TabPanel>
          <div className={concatClassNames('flex flex-col flex-grow justify-between')}>
            <div className={concatClassNames('flex flex-col gap-4')}>
              <div className={concatClassNames('flex flex-col gap-1')}>
                <Text content="Règles du Composant d'interface" weight="bold" position="left" />
                <Text
                  content="Aucune règle n'a été définie pour ce composant. "
                  color="gray-100"
                  position="left"
                  size="sm"
                />
              </div>
              <div className={concatClassNames('flex flex-col gap-1')}>
                <Text content="Règles héritées (non modifiables)" weight="bold" position="left" />
                <Text
                  content="Ce composant ne reçoit aucune règle en héritage"
                  color="gray-100"
                  position="left"
                  size="sm"
                />
              </div>
            </div>
            {onClickSave != null && (
              <div className={concatClassNames('flex gap-2 pt-8 border-t-1 border-gray-50')}>
                <Button content="Ajouter une règle" iconName="plus" cursor="not-allowed" />
                <Button content="Sauvegarder" cursor="not-allowed" />
              </div>
            )}
          </div>
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
}
