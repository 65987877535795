import React, { forwardRef } from 'react';
import { type ChangeEventHandler } from 'react';

import { Tooltip } from '@mui/material';

import concatClassNames from 'utils/classNames';
import getIcon from 'utils/getIcon';

import { Text, type TextColor, type TextSize, type TextWeight, textSizeStyle } from '../Text/Text';

type InputVerticalPadding = 'md' | 'lg';

const inputVerticalPaddingStyle: Record<InputVerticalPadding, string> = {
  md: 'py-1.5',
  lg: 'py-2.5',
};

type InputHorizontalPadding = 'md';

const inputHorizontalPaddingStyle: Record<InputHorizontalPadding, string> = {
  md: 'px-3.5',
};

type InputBgColor = 'gray-25' | 'white';

const inputBgColorStyle: Record<InputBgColor, string> = {
  'gray-25': 'bg-gray-25',
  white: 'bg-white',
};

type InputBorderColor = 'gray-50' | 'gray-500' | 'error';

const inputBorderColorStyle: Record<InputBorderColor, string> = {
  'gray-50': 'border-gray-50',
  'gray-500': 'border-gray-500',
  error: 'border-error-500',
};

type InputWidth = 'full' | 'auto';

const inputWidthStyle: Record<InputWidth, string> = {
  full: 'w-full',
  auto: 'w-auto',
};

interface InputProps {
  label?: string;
  labelSize?: TextSize;
  labelWeight?: TextWeight;
  labelColor?: TextColor;
  information?: string;
  example?: string;
  exampleSize?: TextSize;
  placeholder?: string;
  name?: string;
  value?: string;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  disabled?: boolean;
  error?: string;
  errorSize?: TextSize;
  maxLength?: number;
  isNumber?: boolean;
  readonly?: boolean;
  textSize?: TextSize;
  verticalPadding?: InputVerticalPadding;
  horizontalPadding?: InputHorizontalPadding;
  bgColor?: InputBgColor;
  borderColor?: InputBorderColor;
  width?: InputWidth;
}

function Input(
  {
    label,
    labelSize = 'lg',
    labelWeight = 'bold',
    labelColor = 'black',
    information,
    example,
    exampleSize = 'xs',
    placeholder,
    name,
    value,
    onChange,
    disabled = false,
    error,
    errorSize = 'xs',
    maxLength,
    isNumber = false,
    readonly = false,
    textSize = 'lg',
    verticalPadding = 'lg',
    horizontalPadding = 'md',
    bgColor = 'gray-25',
    borderColor = 'gray-50',
    width = 'auto',
  }: InputProps,
  ref: React.Ref<HTMLInputElement>,
): JSX.Element {
  function chechIfNumber(event: any): void {
    if (!isNumber) return onChange?.(event);
    event.target.value = parseInt(event.target.value);
    if (isNaN(event.target.value)) event.target.value = null;
    onChange?.(event);
  }

  return (
    <div className={concatClassNames('flex flex-col', 'gap-1', inputWidthStyle[width])}>
      <div className={concatClassNames('flex flex-row', 'gap-2', 'items-center')}>
        {label !== undefined && (
          <Text position="left" size={labelSize} color="gray-700" weight="medium" content={label} />
        )}
        <Tooltip title={information} placement="right">
          <div>{information !== undefined && <>{getIcon('information', 'gradient2-to', 'smd')}</>}</div>
        </Tooltip>
      </div>
      {example !== undefined && <Text content={example} weight="light" position="left" size={exampleSize} />}
      <input
        className={concatClassNames(
          inputBgColorStyle[bgColor],
          inputVerticalPaddingStyle[verticalPadding],
          inputHorizontalPaddingStyle[horizontalPadding],
          'border rounded-lg',
          inputBorderColorStyle[error === undefined ? borderColor : 'error'],
          textSizeStyle[textSize],
          'text-gray-500',
          'placeholder:text-gray-200 placeholder:font-thin placeholder:italic',
        )}
        placeholder={disabled ? undefined : placeholder}
        name={name}
        value={value}
        onChange={chechIfNumber}
        ref={ref}
        disabled={disabled}
        maxLength={maxLength}
        readOnly={readonly}
      />
      {error !== undefined && <Text content={error} position="left" size={errorSize} color="error-500" />}
    </div>
  );
}

export default forwardRef<HTMLInputElement, InputProps>(Input);
