import { type GetPageRevisionsInfoDto, type GetResourceSimpleDto } from 'api';

import React from 'react';
import { type NavigateFunction, useNavigate } from 'react-router-dom';

import { Text } from 'components/core/Text/Text';

import { ComponentType } from 'models/ComponentTypes.type';
import { Status } from 'models/Status.types';
import { type Product } from 'models/product.entity';

import concatClassNames from 'utils/classNames';
import getIcon from 'utils/getIcon';
import getPageSymbol from 'utils/getPageSymbol';
import getResourceSymbol from 'utils/getResourceSymbol';
import getStatusChip from 'utils/getStatusChip';

export type ComponentRowProps =
  | {
      id: string;
      name: string;
      status: Status;
      loading?: false;
      type: ComponentType;
      relatedProducts?: Product[];
      pages?: GetPageRevisionsInfoDto[];
      resources?: GetResourceSimpleDto[];
    }
  | {
      id?: never;
      name?: never;
      status?: never;
      loading: true;
      type?: never;
      relatedProducts?: never;
      pages?: never;
      resources?: never;
    };

export function ComponentRow({
  id = '',
  name = '',
  status = Status.Indevelopment,
  type = ComponentType.Front,
  loading = false,
  pages = [],
  resources = [],
}: ComponentRowProps): JSX.Element {
  const navigate: NavigateFunction = useNavigate();

  function handleClick(): void {
    if (loading) return;
    if (type === ComponentType.Database) return;
    if (id === undefined) console.error('id or component is null or undefined');
    else navigate('/component/' + id);
  }

  return (
    <div
      onClick={handleClick}
      className={concatClassNames(
        'bg-[#f7f5f2] rounded-l-full rounded-tr-sm',
        'flex flex-row items-center justify-between',
        'hover:translate-x-3 duration-700 ease-in-out',
        type === ComponentType.Front
          ? 'bg-[#D83389]/5'
          : type === ComponentType.BackApi
          ? 'bg-[#2D3389]/5'
          : type === ComponentType.Database
          ? 'bg-[#FBD34B]/5'
          : 'bg-white',
        'cursor-pointer',
      )}
    >
      <div className="flex flex-row items-center gap-4">
        <div
          className={concatClassNames(
            'flex align-middle justify-center items-center h-16 w-16',
            'rounded-full',
            type === ComponentType.Front
              ? 'bg-[#D83389]'
              : type === ComponentType.BackApi
              ? 'bg-[#2D3389]'
              : type === ComponentType.Database
              ? 'bg-[#FBD34B]'
              : 'bg-white',
          )}
        >
          <div
            className={concatClassNames(
              'flex align-middle justify-center items-center h-14 w-14',
              'bg-white rounded-full',
            )}
          >
            {type === ComponentType.Front ? (
              getIcon('front', 'pink', 'lg')
            ) : type === ComponentType.BackApi ? (
              getIcon('api', 'blue', 'lg')
            ) : type === ComponentType.Database ? (
              getIcon('database', 'yellow', 'lg')
            ) : (
              <div>error</div>
            )}
          </div>
        </div>
        <Text content={name} size="base" weight="bold" />
        {getStatusChip({ name: status, textSize: 'sm' })}
      </div>
      {/* <div className="items-end flex flex-row gap-2 pr-4">
        {pages.map((page) => {
          return getPageSymbol({
            tooltipMessage: page.pageRevisions[0].name,
          });
        })}
        {resources.map((resource) => {
          return getResourceSymbol({
            tooltipMessage: resource.name,
          });
        })}
      </div> */}
    </div>
  );
}
