/* tslint:disable */
/* eslint-disable */
/**
 * Specks API
 * APIs for specks SaaS
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateUiComponentButtonDto
 */
export interface CreateUiComponentButtonDto {
    /**
     * 
     * @type {string}
     * @memberof CreateUiComponentButtonDto
     */
    functionalId?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUiComponentButtonDto
     */
    type: CreateUiComponentButtonDtoTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateUiComponentButtonDto
     */
    name: string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateUiComponentButtonDto
     */
    isFormValid: boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateUiComponentButtonDto
     */
    notes: string;
    /**
     * 
     * @type {number}
     * @memberof CreateUiComponentButtonDto
     */
    index: number;
    /**
     * 
     * @type {string}
     * @memberof CreateUiComponentButtonDto
     */
    parentId?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUiComponentButtonDto
     */
    isVisible: CreateUiComponentButtonDtoIsVisibleEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateUiComponentButtonDto
     */
    isVisibleLogicalRule: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUiComponentButtonDto
     */
    contentType: CreateUiComponentButtonDtoContentTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateUiComponentButtonDto
     */
    sourceMethodId?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUiComponentButtonDto
     */
    sourceConstant: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUiComponentButtonDto
     */
    sourceLogicalRule: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUiComponentButtonDto
     */
    targetType: CreateUiComponentButtonDtoTargetTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateUiComponentButtonDto
     */
    targetMethodId?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUiComponentButtonDto
     */
    targetFree: string;
}


/**
 * @export
 */
export const CreateUiComponentButtonDtoTypeEnum = {
    Title: 'title',
    Icon: 'icon',
    Text: 'text',
    Container: 'container',
    Input: 'input',
    Button: 'button',
    List: 'list',
    Media: 'media',
    Date: 'date',
    Select: 'select'
} as const;
export type CreateUiComponentButtonDtoTypeEnum = typeof CreateUiComponentButtonDtoTypeEnum[keyof typeof CreateUiComponentButtonDtoTypeEnum];

/**
 * @export
 */
export const CreateUiComponentButtonDtoIsVisibleEnum = {
    Yes: 'YES',
    Logical: 'LOGICAL'
} as const;
export type CreateUiComponentButtonDtoIsVisibleEnum = typeof CreateUiComponentButtonDtoIsVisibleEnum[keyof typeof CreateUiComponentButtonDtoIsVisibleEnum];

/**
 * @export
 */
export const CreateUiComponentButtonDtoContentTypeEnum = {
    Constant: 'CONSTANT',
    Logical: 'LOGICAL'
} as const;
export type CreateUiComponentButtonDtoContentTypeEnum = typeof CreateUiComponentButtonDtoContentTypeEnum[keyof typeof CreateUiComponentButtonDtoContentTypeEnum];

/**
 * @export
 */
export const CreateUiComponentButtonDtoTargetTypeEnum = {
    Free: 'FREE',
    Method: 'METHOD',
    Nothing: 'NOTHING'
} as const;
export type CreateUiComponentButtonDtoTargetTypeEnum = typeof CreateUiComponentButtonDtoTargetTypeEnum[keyof typeof CreateUiComponentButtonDtoTargetTypeEnum];


/**
 * Check if a given object implements the CreateUiComponentButtonDto interface.
 */
export function instanceOfCreateUiComponentButtonDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "isFormValid" in value;
    isInstance = isInstance && "notes" in value;
    isInstance = isInstance && "index" in value;
    isInstance = isInstance && "isVisible" in value;
    isInstance = isInstance && "isVisibleLogicalRule" in value;
    isInstance = isInstance && "contentType" in value;
    isInstance = isInstance && "sourceConstant" in value;
    isInstance = isInstance && "sourceLogicalRule" in value;
    isInstance = isInstance && "targetType" in value;
    isInstance = isInstance && "targetFree" in value;

    return isInstance;
}

export function CreateUiComponentButtonDtoFromJSON(json: any): CreateUiComponentButtonDto {
    return CreateUiComponentButtonDtoFromJSONTyped(json, false);
}

export function CreateUiComponentButtonDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateUiComponentButtonDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'functionalId': !exists(json, 'functionalId') ? undefined : json['functionalId'],
        'type': json['type'],
        'name': json['name'],
        'isFormValid': json['isFormValid'],
        'notes': json['notes'],
        'index': json['index'],
        'parentId': !exists(json, 'parentId') ? undefined : json['parentId'],
        'isVisible': json['isVisible'],
        'isVisibleLogicalRule': json['isVisibleLogicalRule'],
        'contentType': json['contentType'],
        'sourceMethodId': !exists(json, 'sourceMethodId') ? undefined : json['sourceMethodId'],
        'sourceConstant': json['sourceConstant'],
        'sourceLogicalRule': json['sourceLogicalRule'],
        'targetType': json['targetType'],
        'targetMethodId': !exists(json, 'targetMethodId') ? undefined : json['targetMethodId'],
        'targetFree': json['targetFree'],
    };
}

export function CreateUiComponentButtonDtoToJSON(value?: CreateUiComponentButtonDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'functionalId': value.functionalId,
        'type': value.type,
        'name': value.name,
        'isFormValid': value.isFormValid,
        'notes': value.notes,
        'index': value.index,
        'parentId': value.parentId,
        'isVisible': value.isVisible,
        'isVisibleLogicalRule': value.isVisibleLogicalRule,
        'contentType': value.contentType,
        'sourceMethodId': value.sourceMethodId,
        'sourceConstant': value.sourceConstant,
        'sourceLogicalRule': value.sourceLogicalRule,
        'targetType': value.targetType,
        'targetMethodId': value.targetMethodId,
        'targetFree': value.targetFree,
    };
}

