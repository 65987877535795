import React from 'react';

import { Tooltip } from '@mui/material';

import { Chip } from 'components/core/Chip/Chip';

import { type IComponent } from 'models/ComponentTypes.type';

export function FrontTypeChip({
  bgColor = 'transparent',
  borderColor = 'none',
  textSize = 'sm',
  textColor = 'pink',
  tooltipMessage = 'Un front-end est la partie d’un site web ou d’une application mobile qui est visible et accessible par les utilisateurs finaux.',
  label = 'Front-end',
  tooltip = false,
}: IComponent): JSX.Element {
  return (
    <Chip
      icon="front"
      iconColor="pink"
      textColor={textColor}
      iconSize={'smd'}
      label={label}
      bgColor={bgColor}
      borderColor={borderColor}
      textSize={textSize}
      paddingTop="md"
      paddingBottom="md"
      paddingHorizontal="lg"
      tooltipMessage={tooltip ? tooltipMessage : undefined}
    />
  );
}
