import React from 'react';
import uuid from 'react-uuid';

import SideMenu from 'components/core/SideMenu/SideMenu';
import { TabElement } from 'components/core/Tabs/TabElement';
import { TabList } from 'components/core/Tabs/TabList';
import { TabPanel } from 'components/core/Tabs/TabPanel';
import { TabPanels } from 'components/core/Tabs/TabPanels';
import { Tabs } from 'components/core/Tabs/Tabs';
import { Text } from 'components/core/Text/Text';

import type { Revision } from 'models/revision.entity';

import concatClassNames from 'utils/classNames';

interface RevisionPanelProps {
  isVersionsSideMenuOpen: boolean;
  subRevisionsStatus: string;
  subRevisionsData: Revision[];
  onClickRevision: (revisionTimestamp: Date) => void;
  actualRevisionTimestamp?: Date;
}

interface RevisionValue {
  name: string;
  value: Date;
}

interface RevisionGroup {
  day: string;
  revisions: RevisionValue[];
}

export function RevisionPanel({
  isVersionsSideMenuOpen,
  subRevisionsStatus,
  subRevisionsData,
  onClickRevision,
  actualRevisionTimestamp,
}: RevisionPanelProps): JSX.Element {
  const revisionGroups: RevisionGroup[] = [];
  subRevisionsData.forEach((revision: Revision) => {
    const day: string = ('0' + String(revision.createdAt.getDate())).slice(-2);
    const monthString: string = revision.createdAt.toLocaleString('default', { month: 'long' });
    const monthNumber: string = ('0' + String(revision.createdAt.getMonth() + 1)).slice(-2);
    const fullYear: string = String(revision.createdAt.getFullYear());
    const hour: string = ('0' + String(revision.createdAt.getHours())).slice(-2);
    const minutes: string = ('0' + String(revision.createdAt.getMinutes())).slice(-2);
    const date: string = `${day} ${monthString} ${fullYear}`;
    const dateAndTime: string = `${day}/${monthNumber}/${fullYear} à ${hour}:${minutes}`;
    const revisionGroupIndex: number = revisionGroups.findIndex(
      (revisionGroup: RevisionGroup) => revisionGroup.day === date,
    );
    if (revisionGroupIndex === -1) {
      revisionGroups.push({
        day: date,
        revisions: [{ name: dateAndTime, value: revision.createdAt }],
      });
    } else {
      revisionGroups[revisionGroupIndex].revisions.push({ name: dateAndTime, value: revision.createdAt });
    }
  });
  return (
    <>
      {isVersionsSideMenuOpen && (
        <SideMenu absolute horizontalPosition="right">
          <RevisionList
            subRevisionsStatus={subRevisionsStatus}
            subRevisionsGroups={revisionGroups}
            onClickRevision={onClickRevision}
            actualRevisionTimestamp={actualRevisionTimestamp}
          />
        </SideMenu>
      )}
    </>
  );
}

interface RevisionListProps {
  subRevisionsStatus: string;
  subRevisionsGroups: RevisionGroup[];
  onClickRevision: (revisionTimestamp: Date) => void;
  actualRevisionTimestamp?: Date;
}

function RevisionList({
  subRevisionsStatus,
  subRevisionsGroups,
  onClickRevision,
  actualRevisionTimestamp,
}: RevisionListProps): JSX.Element {
  return (
    <div
      className={concatClassNames('bg-white', 'p-1', 'rounded-md', 'flex flex-col', 'gap-1', 'h-fit', 'overflow-auto')}
    >
      <Tabs>
        <TabList position="center">
          <TabElement title="Révisions" />
        </TabList>
        <TabPanels>
          <TabPanel>
            {subRevisionsStatus === 'success' &&
              subRevisionsGroups.map((revisionGroup: RevisionGroup, indexGroups) => {
                return (
                  <div
                    key={uuid()}
                    className={concatClassNames(
                      'flex flex-col',
                      'gap-2',
                      'mt-3',
                      'p-2',
                      'bg-white',
                      'w-fill h-fill',
                      'justify-start',
                    )}
                  >
                    <Text position="left" content={revisionGroup.day} size="base" weight="bold" />
                    <div className={concatClassNames('flex flex-col', 'gap-1', 'w-fill')}>
                      {revisionGroup.revisions.map((revision: RevisionValue, index: number) => (
                        <div
                          key={uuid()}
                          className={concatClassNames(
                            actualRevisionTimestamp === revision.value ||
                              (actualRevisionTimestamp === undefined && indexGroups === 0 && index === 0)
                              ? 'bg-branding-25'
                              : 'bg-white',
                            'rounded-md',
                            'shadow-md',
                            'cursor-pointer',
                            'hover:bg-branding-25',
                            'transition duration-300',
                            'w-fill h-fill',
                            'p-3 m-1',
                          )}
                          onClick={() => {
                            onClickRevision(revision.value);
                          }}
                        >
                          <Text position="center" content={revision.name} size="base" />
                        </div>
                      ))}
                    </div>
                  </div>
                );
              })}
            {subRevisionsStatus === 'loading' && (
              <>
                <div
                  key={uuid()}
                  className={concatClassNames(
                    'flex flex-row',
                    'items-center',
                    'gap-2',
                    'p-1',
                    'bg-white',
                    'rounded-md',
                    'shadow-md',
                    'justify-center',
                  )}
                >
                  <Text loading />
                </div>
                <div
                  key={uuid()}
                  className={concatClassNames(
                    'flex flex-row',
                    'items-center',
                    'gap-2',
                    'p-1',
                    'bg-white',
                    'rounded-md',
                    'shadow-md',
                    'justify-center',
                  )}
                >
                  <Text loading />
                </div>
                <div
                  key={uuid()}
                  className={concatClassNames(
                    'flex flex-row',
                    'items-center',
                    'gap-2',
                    'p-1',
                    'bg-white',
                    'rounded-md',
                    'shadow-md',
                    'justify-center',
                  )}
                >
                  <Text loading />
                </div>
                <div
                  key={uuid()}
                  className={concatClassNames(
                    'flex flex-row',
                    'items-center',
                    'gap-2',
                    'p-1',
                    'bg-white',
                    'rounded-md',
                    'shadow-md',
                    'justify-center',
                  )}
                >
                  <Text loading />
                </div>
              </>
            )}
          </TabPanel>
        </TabPanels>
      </Tabs>
    </div>
  );
}
