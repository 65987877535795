/* tslint:disable */
/* eslint-disable */
/**
 * Specks API
 * APIs for specks SaaS
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ExternalLinkDto } from './ExternalLinkDto';
import {
    ExternalLinkDtoFromJSON,
    ExternalLinkDtoFromJSONTyped,
    ExternalLinkDtoToJSON,
} from './ExternalLinkDto';

/**
 * 
 * @export
 * @interface GetProductExternalLinksDto
 */
export interface GetProductExternalLinksDto {
    /**
     * 
     * @type {Array<ExternalLinkDto>}
     * @memberof GetProductExternalLinksDto
     */
    externalLinks: Array<ExternalLinkDto>;
}

/**
 * Check if a given object implements the GetProductExternalLinksDto interface.
 */
export function instanceOfGetProductExternalLinksDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "externalLinks" in value;

    return isInstance;
}

export function GetProductExternalLinksDtoFromJSON(json: any): GetProductExternalLinksDto {
    return GetProductExternalLinksDtoFromJSONTyped(json, false);
}

export function GetProductExternalLinksDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetProductExternalLinksDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'externalLinks': ((json['externalLinks'] as Array<any>).map(ExternalLinkDtoFromJSON)),
    };
}

export function GetProductExternalLinksDtoToJSON(value?: GetProductExternalLinksDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'externalLinks': ((value.externalLinks as Array<any>).map(ExternalLinkDtoToJSON)),
    };
}

