import React from 'react';

import { Switch as HeadlessSwitch } from '@headlessui/react';

import concatClassNames from 'utils/classNames';

interface SwitchProps {
  value: boolean;
  onChange: React.Dispatch<React.SetStateAction<boolean>>;
  readonly?: boolean;
}

export function Switch({ value, onChange, readonly = false }: SwitchProps): JSX.Element {
  return (
    <HeadlessSwitch
      checked={value}
      onChange={(event) => {
        if (readonly) return;
        onChange(event);
      }}
      className={concatClassNames(
        `${value ? 'bg-gradient-to-r bg-gradient2-from to-gradient2-to' : 'bg-gray-50'}`,
        'inline-flex',
        'h-[19px] w-[37px]',
        'cursor-pointer',
        'rounded-full',
        'transition-colors duration-200 ease-in-out',
        'focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75',
      )}
    >
      <span
        className={concatClassNames(
          `${value ? 'translate-x-[19px]' : 'translate-x-[1px]'}`,
          'translate-y-[1px]',
          'pointer-events-none inline-block',
          'h-[17px] w-[17px]',
          'rounded-full bg-white',
          'transition duration-200 ease-in-out',
        )}
      />
    </HeadlessSwitch>
  );
}
