import {
  type GetMethodDetailsDto,
  MethodsApi,
  type PartialUpdateMethodDto,
  type UpdateApiHeaderParameterDtoValueTypeEnum,
  type UpdateApiPathParameterDtoValueTypeEnum,
  type UpdateApiQueryParameterDtoValueTypeEnum,
  type UpdateApiResponseDto,
  type UpdateApiResponseDtoResponseTypeEnum,
} from 'api';
import { toBodyDto } from 'factory/BodyFactory';
import { fromGetMethodDetailsDto } from 'factory/MethodFactory';

import uuid from 'react-uuid';

import { type Method } from 'models/method.entity';

export class MethodService {
  private readonly methodClient: MethodsApi;

  constructor() {
    this.methodClient = new MethodsApi();
  }

  public async findById(id: string, accessToken: string): Promise<Method> {
    const methodDetailsDto: GetMethodDetailsDto = await this.methodClient.methodControllerFindById(
      { id },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
      },
    );
    return fromGetMethodDetailsDto(methodDetailsDto);
  }

  public async partialUpdate(id: string, method: Partial<Method>, accessToken: string): Promise<Method> {
    const partialUpdateMethodDto: PartialUpdateMethodDto = {};

    partialUpdateMethodDto.name = method.methodRevisions?.[0]?.name;
    partialUpdateMethodDto.description = method.methodRevisions?.[0]?.description;
    partialUpdateMethodDto.methodType = method.methodRevisions?.[0]?.methodType;

    const methodDetailsDto: GetMethodDetailsDto = await this.methodClient.methodControllerPartialUpdate(
      { id, partialUpdateMethodDto },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
      },
    );

    return fromGetMethodDetailsDto(methodDetailsDto);
  }

  public async update(id: string, newMethod: Method, accessToken: string): Promise<Method> {
    const methodDetailsDto: GetMethodDetailsDto = await this.methodClient.methodControllerUpdate(
      {
        id,
        updateMethodDto: {
          name: newMethod.methodRevisions[0].name,
          description: newMethod.methodRevisions[0].description,
          methodType: newMethod.methodRevisions[0].methodType,
          apiPathParameters: newMethod.methodRevisions[0].apiPathParameters.map((element, index) => {
            return {
              name: element.name,
              index,
              isMandatory: element.isMandatory,
              defaultValue: element.defaultValue,
              valueType: element.valueType.toUpperCase() as UpdateApiPathParameterDtoValueTypeEnum,
            };
          }),
          apiQueryParameters: newMethod.methodRevisions[0].apiQueryParameters.map((element, index) => {
            return {
              name: element.name,
              index,
              isMandatory: element.isMandatory,
              defaultValue: element.defaultValue,
              valueType: element.valueType.toUpperCase() as UpdateApiQueryParameterDtoValueTypeEnum,
            };
          }),
          apiHeaderParameters: newMethod.methodRevisions[0].apiHeaderParameters.map((element, index) => {
            return {
              name: element.name,
              index,
              isMandatory: element.isMandatory,
              defaultValue: element.defaultValue,
              valueType: element.valueType.toUpperCase() as UpdateApiHeaderParameterDtoValueTypeEnum,
            };
          }),
          apiBodyParameter: { values: toBodyDto(newMethod.methodRevisions[0].apiBodyParameter) },
          apiResponses: newMethod.methodRevisions[0].apiResponses.map((element) => {
            const apiResponseDto: UpdateApiResponseDto = {
              fonctionalId: element.fonctionalId ?? uuid(),
              message: element.message,

              responseType: element.responseType as UpdateApiResponseDtoResponseTypeEnum,
              statusCode: element.statusCode,
              apiResponseBody: {
                values: toBodyDto(element.apiResponseBody),
              },
            };
            return apiResponseDto;
          }),
        },
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
      },
    );
    return fromGetMethodDetailsDto(methodDetailsDto);
  }

  public async deleteMethod(id: string, accessToken: string): Promise<void> {
    await this.methodClient.methodControllerDeleteById(
      { id },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
      },
    );
  }
}
