import * as Yup from 'yup';

import React from 'react';
import { type SubmitHandler, useForm } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';

import { Button } from 'components/core/Button/Button';
import Input from 'components/core/Input/Input';

import { Resource } from 'models/resource.entity';
import { ResourceRevision } from 'models/resourceRevision.entity';

interface CreateorEditResourceFormModel {
  name: string;
}

const schema: Yup.ObjectSchema<CreateorEditResourceFormModel> = Yup.object().shape({
  name: Yup.string()
    .required('Champ obligatoire')
    .min(3, 'Le nom doit contenir au moins 3 caractères')
    .test('isUnique', 'Une ressource avec ce nom existe déjà', (value, context) => {
      const currentResources: Resource[] | undefined = context?.options?.context?.currentResources as Resource[];
      return (
        value != null && value.length > 0 && !currentResources.some((page) => page.resourceRevisions[0].name === value)
      );
    }),
});

interface CreateorEditResourceFormProps {
  onSubmit: (resource: Resource) => void;
  currentResources: Resource[];
  isEditing?: boolean;
  isLoading?: boolean;
  resource?: Resource;
}

export function CreateOrEditResourceForm({
  onSubmit,
  currentResources,
  isEditing = false,
  isLoading = false,
  resource,
}: CreateorEditResourceFormProps): JSX.Element {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors, isSubmitted },
  } = useForm<CreateorEditResourceFormModel>({
    resolver: yupResolver(schema),
    context: { currentResources },
    defaultValues: { name: isEditing && resource !== undefined ? resource?.resourceRevisions[0].name : '' },
  });
  const watchName: string = watch('name');

  let isFormDirty: boolean = false;
  if (watchName !== resource?.resourceRevisions[0].name) {
    isFormDirty = true;
  }

  let isFormValid: boolean = true;
  if (watchName === undefined || watchName.length < 3) {
    isFormValid = false;
  }

  const onSubmitForm: SubmitHandler<CreateorEditResourceFormModel> = (data) => {
    const resource: Resource = new Resource();
    const resourceRevisions: ResourceRevision = new ResourceRevision();
    resourceRevisions.name = data.name;
    resource.resourceRevisions = [resourceRevisions];
    onSubmit(resource);
  };

  return (
    <form className="flex flex-col gap-6" onSubmit={handleSubmit(onSubmitForm)}>
      <Input
        label="Nom de la ressource"
        placeholder="Définissez le nom de la ressource"
        {...register('name')}
        maxLength={28}
        error={errors.name != null && isSubmitted ? errors.name.message : undefined}
      />

      <div className="flex flex-row justify-end">
        <Button
          content={isEditing ? 'Modifier' : 'Créer'}
          width="1/2"
          type="submit"
          disabled={!isFormValid || !isFormDirty}
        />
      </div>
    </form>
  );
}
