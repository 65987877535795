/* tslint:disable */
/* eslint-disable */
/**
 * Specks API
 * APIs for specks SaaS
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  CreateComponentDto,
  CreateExternalLinkDto,
  ErrorBadRequest,
  ErrorForbidden,
  GetComponentDto,
  GetComponentExternalLinksDto,
  GetComponentRelatedProductsDto,
  GetComponentSpecificationDto,
  GetComponentsDto,
  GetExternalLinkDto,
  GetLinksDto,
  UpdateComponentDto,
} from '../models';
import {
    CreateComponentDtoFromJSON,
    CreateComponentDtoToJSON,
    CreateExternalLinkDtoFromJSON,
    CreateExternalLinkDtoToJSON,
    ErrorBadRequestFromJSON,
    ErrorBadRequestToJSON,
    ErrorForbiddenFromJSON,
    ErrorForbiddenToJSON,
    GetComponentDtoFromJSON,
    GetComponentDtoToJSON,
    GetComponentExternalLinksDtoFromJSON,
    GetComponentExternalLinksDtoToJSON,
    GetComponentRelatedProductsDtoFromJSON,
    GetComponentRelatedProductsDtoToJSON,
    GetComponentSpecificationDtoFromJSON,
    GetComponentSpecificationDtoToJSON,
    GetComponentsDtoFromJSON,
    GetComponentsDtoToJSON,
    GetExternalLinkDtoFromJSON,
    GetExternalLinkDtoToJSON,
    GetLinksDtoFromJSON,
    GetLinksDtoToJSON,
    UpdateComponentDtoFromJSON,
    UpdateComponentDtoToJSON,
} from '../models';

export interface ComponentControllerCreateRequest {
    createComponentDto: CreateComponentDto;
}

export interface ComponentControllerCreateExternalLinkRequest {
    id: string;
    createExternalLinkDto: CreateExternalLinkDto;
}

export interface ComponentControllerDeleteRequest {
    id: string;
}

export interface ComponentControllerFindAllRequest {
    favorites?: boolean;
}

export interface ComponentControllerFindByIdRequest {
    id: string;
}

export interface ComponentControllerFindComponentByMethodIdRequest {
    id: string;
}

export interface ComponentControllerFindComponentExternalLinksByIdRequest {
    id: string;
}

export interface ComponentControllerFindLinksByIdRequest {
    id: string;
    active?: boolean;
    productContext?: string;
}

export interface ComponentControllerFindRelatedProductsRequest {
    id: string;
}

export interface ComponentControllerFindSpecificationByIdRequest {
    id: string;
}

export interface ComponentControllerUpdateRequest {
    id: string;
    updateComponentDto: UpdateComponentDto;
}

/**
 * 
 */
export class ComponentsApi extends runtime.BaseAPI {

    /**
     */
    async componentControllerCreateRaw(requestParameters: ComponentControllerCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetComponentDto>> {
        if (requestParameters.createComponentDto === null || requestParameters.createComponentDto === undefined) {
            throw new runtime.RequiredError('createComponentDto','Required parameter requestParameters.createComponentDto was null or undefined when calling componentControllerCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/components`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateComponentDtoToJSON(requestParameters.createComponentDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetComponentDtoFromJSON(jsonValue));
    }

    /**
     */
    async componentControllerCreate(requestParameters: ComponentControllerCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetComponentDto> {
        const response = await this.componentControllerCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async componentControllerCreateExternalLinkRaw(requestParameters: ComponentControllerCreateExternalLinkRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetExternalLinkDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling componentControllerCreateExternalLink.');
        }

        if (requestParameters.createExternalLinkDto === null || requestParameters.createExternalLinkDto === undefined) {
            throw new runtime.RequiredError('createExternalLinkDto','Required parameter requestParameters.createExternalLinkDto was null or undefined when calling componentControllerCreateExternalLink.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/components/{id}/external-links`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateExternalLinkDtoToJSON(requestParameters.createExternalLinkDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetExternalLinkDtoFromJSON(jsonValue));
    }

    /**
     */
    async componentControllerCreateExternalLink(requestParameters: ComponentControllerCreateExternalLinkRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetExternalLinkDto> {
        const response = await this.componentControllerCreateExternalLinkRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async componentControllerDeleteRaw(requestParameters: ComponentControllerDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling componentControllerDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/components/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async componentControllerDelete(requestParameters: ComponentControllerDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.componentControllerDeleteRaw(requestParameters, initOverrides);
    }

    /**
     */
    async componentControllerFindAllRaw(requestParameters: ComponentControllerFindAllRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetComponentsDto>> {
        const queryParameters: any = {};

        if (requestParameters.favorites !== undefined) {
            queryParameters['favorites'] = requestParameters.favorites;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/components`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetComponentsDtoFromJSON(jsonValue));
    }

    /**
     */
    async componentControllerFindAll(requestParameters: ComponentControllerFindAllRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetComponentsDto> {
        const response = await this.componentControllerFindAllRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async componentControllerFindByIdRaw(requestParameters: ComponentControllerFindByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetComponentDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling componentControllerFindById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/components/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetComponentDtoFromJSON(jsonValue));
    }

    /**
     */
    async componentControllerFindById(requestParameters: ComponentControllerFindByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetComponentDto> {
        const response = await this.componentControllerFindByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get component by methodId
     * Get component by methodId
     */
    async componentControllerFindComponentByMethodIdRaw(requestParameters: ComponentControllerFindComponentByMethodIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetComponentDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling componentControllerFindComponentByMethodId.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/components/component/method/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetComponentDtoFromJSON(jsonValue));
    }

    /**
     * Get component by methodId
     * Get component by methodId
     */
    async componentControllerFindComponentByMethodId(requestParameters: ComponentControllerFindComponentByMethodIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetComponentDto> {
        const response = await this.componentControllerFindComponentByMethodIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async componentControllerFindComponentExternalLinksByIdRaw(requestParameters: ComponentControllerFindComponentExternalLinksByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetComponentExternalLinksDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling componentControllerFindComponentExternalLinksById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/components/{id}/external-links`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetComponentExternalLinksDtoFromJSON(jsonValue));
    }

    /**
     */
    async componentControllerFindComponentExternalLinksById(requestParameters: ComponentControllerFindComponentExternalLinksByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetComponentExternalLinksDto> {
        const response = await this.componentControllerFindComponentExternalLinksByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async componentControllerFindLinksByIdRaw(requestParameters: ComponentControllerFindLinksByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetLinksDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling componentControllerFindLinksById.');
        }

        const queryParameters: any = {};

        if (requestParameters.active !== undefined) {
            queryParameters['active'] = requestParameters.active;
        }

        if (requestParameters.productContext !== undefined) {
            queryParameters['productContext'] = requestParameters.productContext;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/components/{id}/links`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetLinksDtoFromJSON(jsonValue));
    }

    /**
     */
    async componentControllerFindLinksById(requestParameters: ComponentControllerFindLinksByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetLinksDto> {
        const response = await this.componentControllerFindLinksByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get Related Products of a component
     * Récupérer les produits consommateurs du composant.
     */
    async componentControllerFindRelatedProductsRaw(requestParameters: ComponentControllerFindRelatedProductsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetComponentRelatedProductsDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling componentControllerFindRelatedProducts.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/components/{id}/products`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetComponentRelatedProductsDtoFromJSON(jsonValue));
    }

    /**
     * Get Related Products of a component
     * Récupérer les produits consommateurs du composant.
     */
    async componentControllerFindRelatedProducts(requestParameters: ComponentControllerFindRelatedProductsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetComponentRelatedProductsDto> {
        const response = await this.componentControllerFindRelatedProductsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async componentControllerFindSpecificationByIdRaw(requestParameters: ComponentControllerFindSpecificationByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetComponentSpecificationDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling componentControllerFindSpecificationById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/components/{id}/specification`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetComponentSpecificationDtoFromJSON(jsonValue));
    }

    /**
     */
    async componentControllerFindSpecificationById(requestParameters: ComponentControllerFindSpecificationByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetComponentSpecificationDto> {
        const response = await this.componentControllerFindSpecificationByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async componentControllerUpdateRaw(requestParameters: ComponentControllerUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetComponentDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling componentControllerUpdate.');
        }

        if (requestParameters.updateComponentDto === null || requestParameters.updateComponentDto === undefined) {
            throw new runtime.RequiredError('updateComponentDto','Required parameter requestParameters.updateComponentDto was null or undefined when calling componentControllerUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/components/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateComponentDtoToJSON(requestParameters.updateComponentDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetComponentDtoFromJSON(jsonValue));
    }

    /**
     */
    async componentControllerUpdate(requestParameters: ComponentControllerUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetComponentDto> {
        const response = await this.componentControllerUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
