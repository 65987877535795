import { type TextSize, type TextWeight } from '../Text/Text';

type TabListPosition = 'left' | 'center';

export const tabListPositionStyle = {
  left: 'justify-start',
  center: 'justify-center',
};

type TabElementWidth = 'fit' | 'fill';

export interface TabsProps {
  children: JSX.Element[];
  onChange?: (index: number) => void;
}

export interface TabListProps {
  children: JSX.Element | JSX.Element[];
  jsxElement?: JSX.Element;
  position?: TabListPosition;
}

export interface TabElementProps {
  title: string;
  textSize?: TextSize;
  selectedTextWeight?: TextWeight;
  weight?: TextWeight;
  width?: TabElementWidth;
}

export interface TabPanelsProps {
  children: JSX.Element | JSX.Element[];
}

export interface TabPanelProps {
  children: false | JSX.Element | JSX.Element[] | Array<false | JSX.Element | JSX.Element[]>;
}
