import React from 'react';

import { Text } from 'components/core/Text/Text';

import concatClassNames from 'utils/classNames';

import { ApiMethodSection } from './ApiMethodSection';

export interface ApiMethod {
  id: string;
  description: string;
  type: string;
  path: string;
}

interface ApiMethodsWrapperProps {
  methods: ApiMethod[];
  onMethodClick: (methodId: string) => void;
  selectedMethodId?: string;
}

export function ApiMethodsWrapper({ methods, onMethodClick, selectedMethodId }: ApiMethodsWrapperProps): JSX.Element {
  const methodsByType: {
    GET: ApiMethod[];
    POST: ApiMethod[];
    PUT: ApiMethod[];
    PATCH: ApiMethod[];
    DELETE: ApiMethod[];
  } = {
    GET: [],
    POST: [],
    PUT: [],
    PATCH: [],
    DELETE: [],
  };

  methods.forEach((method: ApiMethod) => {
    methodsByType[method.type as keyof typeof methodsByType].push(method);
  });

  return (
    <div className={concatClassNames('flex flex-col', 'w-full', 'gap-3', 'overflow-auto')}>
      {methodsByType.GET.length > 0 && (
        <ApiMethodSection
          title="Lecture"
          methods={methodsByType.GET}
          onMethodClick={onMethodClick}
          selectedMethodId={selectedMethodId}
        />
      )}
      {methodsByType.POST.length > 0 && (
        <ApiMethodSection
          title="Création"
          methods={methodsByType.POST}
          onMethodClick={onMethodClick}
          selectedMethodId={selectedMethodId}
        />
      )}
      {(methodsByType.PUT.length > 0 || methodsByType.PATCH.length > 0) && (
        <ApiMethodSection
          title="Mise à jour"
          methods={[...methodsByType.PUT, ...methodsByType.PATCH]}
          onMethodClick={onMethodClick}
          selectedMethodId={selectedMethodId}
        />
      )}
      {methodsByType.DELETE.length > 0 && (
        <ApiMethodSection
          title="Suppression"
          methods={methodsByType.DELETE}
          onMethodClick={onMethodClick}
          selectedMethodId={selectedMethodId}
        />
      )}
      {methodsByType.GET.length === 0 &&
        methodsByType.PATCH.length === 0 &&
        methodsByType.POST.length === 0 &&
        methodsByType.PUT.length === 0 &&
        methodsByType.PATCH.length === 0 &&
        methodsByType.DELETE.length === 0 && <Text content="Pas de méthode existante" position="center" />}
    </div>
  );
}
