import { SpecksApisContext } from 'App';
import { type GetMethodDto } from 'api';
import { fromGetMethodDto } from 'factory/MethodFactory';

import React, { createContext, useContext, useEffect, useState } from 'react';
import { type QueryClient, useMutation, useQuery, useQueryClient } from 'react-query';
import { type NavigateFunction, useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import uuid from 'react-uuid';

import { useAuth0 } from '@auth0/auth0-react';

import { ComponentBackEndApiDetail } from 'pages/Component/BackEndApi/Api/Detail/Detail';
import { ResourceFront } from 'pages/Component/BackEndApi/Api/Resource.front';

import { CreateOrEditMethodForm } from 'forms/CreateOrEditMethodForm/CreateOrEditMethodForm';
import { CreateOrEditResourceForm } from 'forms/CreateOrEditResourceForm/CreateOrEditResourceForm';

import { Button } from 'components/core/Button/Button';
import { Modal } from 'components/core/Modal/Modal';
import SideMenu from 'components/core/SideMenu/SideMenu';
import { ApiCollapse } from 'components/specks/Api/ApiCollapse/ApiCollapse';
import { ApiMethodsWrapper } from 'components/specks/Api/Method/ApiMethodsWrapper';
import { Empty } from 'components/specks/Empty/Empty';
import { NetworkError } from 'components/specks/Error/Error';

import { type Body } from 'models/API/Body.entity';
import { type ApiHeaderParameter } from 'models/API/Parameters/apiHeaderParameter.entity';
import { type ApiPathParameter } from 'models/API/Parameters/apiPathParameter.entity';
import { type ApiQueryParameter } from 'models/API/Parameters/apiQueryParameter.entity';
import { type ApiResponse } from 'models/ApiResponse.entity';
import { type Component } from 'models/component.entity';
import { type Method } from 'models/method.entity';
import { type Resource } from 'models/resource.entity';

import concatClassNames from 'utils/classNames';
import getIcon from 'utils/getIcon';
import { tooltipDescriptionsStyle } from 'utils/tooltipsDescriptions';

/* ----------------------------------------------- resource Provider ---------------------------------------------- */
interface ResourceProviderProps {
  children: JSX.Element[] | JSX.Element;
  handler: ResourceHandler;
}

interface ComponentBackApiSpecificationApiPageProps {
  // Parameters
  //   Path
  setIsNewPathFormOpened: (value: boolean) => void;
  isNewPathFormOpened: boolean;
  setPathIdUpdateFormOpened: (value: string | undefined) => void;
  pathIdUpdateFormOpened?: string;
  setPathData: (data: ApiPathParameter | undefined) => void;
  pathData?: ApiPathParameter;
  //  Query
  setIsNewQueryFormOpened: (value: boolean) => void;
  isNewQueryFormOpened: boolean;
  setQueryIdUpdateFormOpened: (value: string | undefined) => void;
  queryIdUpdateFormOpened?: string;
  setQueryData: (data: ApiQueryParameter | undefined) => void;
  queryData?: ApiQueryParameter;
  //  Header
  setIsNewHeaderFormOpened: (value: boolean) => void;
  isNewHeaderFormOpened: boolean;
  setHeaderIdUpdateFormOpened: (value: string | undefined) => void;
  headerIdUpdateFormOpened?: string;
  setHeaderData: (data: ApiHeaderParameter | undefined) => void;
  headerData?: ApiHeaderParameter;
  //  Body
  setIsNewBodyFormOpened: (value: boolean) => void;
  isNewBodyFormOpened: boolean;
  setBodyIdUpdateFormOpened: (value: string | undefined) => void;
  bodyIdUpdateFormOpened?: string;
  setBodyData: (data: Body | undefined) => void;
  bodyData?: Body;

  setDeleteRowId: (value: string | undefined) => void;
  deleteRowId?: string;

  // ResponseRow
  setIsSuccessResponseNewFormOpened: (value: boolean) => void;
  isSuccessResponseNewFormOpened: boolean;
  setSuccessResponseIdUpdateFormOpened: (value: string | undefined) => void;
  successResponseIdUpdateFormOpened?: string;
  setIsErrorResponseNewFormOpened: (value: boolean) => void;
  isErrorResponseNewFormOpened: boolean;
  setErrorResponseIdUpdateFormOpened: (value: string | undefined) => void;
  errorResponseIdUpdateFormOpened?: string;
  setApiResponseData: (data: ApiResponse | undefined) => void;
  apiResponseData?: ApiResponse;
  setNewlyCreatedResponseRow: (value: string | undefined) => void;
  newlyCreatedResponseRow?: string;

  // ResponseBodyRow
  setNewResponseBodyIdFormOpened: (value: string | undefined) => void;
  newResponseBodyIdFormOpened?: string;
  setResponseBodyIdUpdateFormOpened: (value: string | undefined) => void;
  responseBodyIdUpdateFormOpened?: string;
  setUpdatingBodyData: (data: Body | undefined) => void;
  updatingBodyData?: Body;

  // Mutate
  //    Messages
  successMutateMessage?: string;
  errorMutateMessage?: string;

  //    Status
  setMutateStatus: (value: 'success' | 'error' | 'loading' | 'idle') => void;
  mutateStatus: 'success' | 'error' | 'loading' | 'idle';

  doFormReset?: boolean;
  closeUpdateForms: (id?: string) => void;
  closeAllFormsExcept: (id?: string) => void;
}

export interface ResourceHandler {
  resources: Resource[];
  resourceIdofSelectedMethod?: string;
  selectedMethod?: Method;
  details: ComponentBackApiSpecificationApiPageProps;
}

export const ResourceContext: React.Context<ResourceHandler> = createContext({} as ResourceHandler);

export function useResourceHandler(): ResourceHandler {
  return useContext(ResourceContext);
}

export function ResourceProvider({ children, handler }: ResourceProviderProps): JSX.Element {
  return <ResourceContext.Provider value={handler}>{children}</ResourceContext.Provider>;
}

interface ApiWidgetProps {
  componentId: string;
  isEditable?: boolean;
  isSideMenuCollapsable?: boolean;
}

export function ApiWidget({
  componentId,
  isEditable = false,
  isSideMenuCollapsable = false,
}: ApiWidgetProps): JSX.Element {
  interface CreateMethodProps {
    resourceId: string;
    method: Method;
  }

  interface CreateResourceProps {
    BackApiSpecificationId: string;
    resource: Resource;
  }

  /* --------------------------------------------------- contexts --------------------------------------------------- */

  const { componentService, backApiSpecificationService, resourceService, methodService } =
    useContext(SpecksApisContext);
  const [searchParams] = useSearchParams();
  const navigate: NavigateFunction = useNavigate();
  const queryClient: QueryClient = useQueryClient();
  const { getAccessTokenSilently } = useAuth0();

  /* --------------------------------------------------- variables -------------------------------------------------- */

  const selectedPathMethodId: string | undefined = searchParams.get('methodid') ?? undefined;

  /* ---------------------------------------------------- states ---------------------------------------------------- */
  const [selectedResource, setSelectedResource] = useState<Resource | undefined>();
  const [isEditResourceModalOpened, setIsEditResourceModalOpened] = useState<boolean>(false);
  const [isAddResourceFormOpened, setIsAddResourceFormOpened] = useState<boolean>(false);

  const [isAddMethodFormOpened, setIsAddMethodFormOpened] = useState<boolean>(false);
  const [isEditMethodModalOpened, setIsEditMethodModalOpened] = useState<boolean>(false);

  const [selectedResourceId, setSelectedResourceId] = useState<string>('');
  const [isAddSidePanelOpened, setIsAddSidePanelOpened] = useState<boolean>(false);
  const [resources, setResources] = useState<ResourceFront[]>([]);
  const [selectedMethodId, setSelectedMethodId] = useState<string | undefined>(selectedPathMethodId);
  const [selectedMethod, setSelectedMethod] = useState<Method | undefined>();

  const [resourceToRemove, setResourceToRemove] = useState<string>();

  const [methodToRemove, setMethodToRemove] = useState<string>();

  //  Parameters
  const [isNewPathFormOpened, setIsNewPathFormOpened] = useState<boolean>(false);
  const [pathIdUpdateFormOpened, setPathIdUpdateFormOpened] = useState<string | undefined>();
  const [pathData, setPathData] = useState<ApiPathParameter>();

  const [isNewQueryFormOpened, setIsNewQueryFormOpened] = useState<boolean>(false);
  const [queryIdUpdateFormOpened, setQueryIdUpdateFormOpened] = useState<string | undefined>();
  const [queryData, setQueryData] = useState<ApiQueryParameter>();

  const [isNewHeaderFormOpened, setIsNewHeaderFormOpened] = useState<boolean>(false);
  const [headerIdUpdateFormOpened, setHeaderIdUpdateFormOpened] = useState<string | undefined>();
  const [headerData, setHeaderData] = useState<ApiHeaderParameter>();

  const [isNewBodyFormOpened, setIsNewBodyFormOpened] = useState<boolean>(false);
  const [bodyIdUpdateFormOpened, setBodyIdUpdateFormOpened] = useState<string | undefined>();
  const [bodyData, setBodyData] = useState<Body>();

  const [deleteRowId, setDeleteRowId] = useState<string | undefined>();

  //  ResponseRow
  const [isSuccessResponseNewFormOpened, setIsSuccessResponseNewFormOpened] = useState<boolean>(false);
  const [successResponseIdUpdateFormOpened, setSuccessResponseIdUpdateFormOpened] = useState<string | undefined>();
  const [isErrorResponseNewFormOpened, setIsErrorResponseNewFormOpened] = useState<boolean>(false);
  const [errorResponseIdUpdateFormOpened, setErrorResponseIdUpdateFormOpened] = useState<string | undefined>();
  const [apiResponseData, setApiResponseData] = useState<ApiResponse>();
  const [newlyCreatedResponseRow, setNewlyCreatedResponseRow] = useState<string | undefined>();

  //  ResponseBodyRow
  const [newResponseBodyIdFormOpened, setNewResponseBodyIdFormOpened] = useState<string | undefined>();
  const [responseBodyIdUpdateFormOpened, setResponseBodyIdUpdateFormOpened] = useState<string | undefined>();
  const [updatingBodyData, setUpdatingBodyData] = useState<Body>();

  const [mutateStatus, setMutateStatus] = useState<'success' | 'error' | 'loading' | 'idle'>('idle');

  const [isSideMenuOpened, setIsSideMenuOpened] = useState<boolean>(true);
  const [arrowXPosition, setArrowXPosition] = useState<number>(0);

  /* ---------------------------------------------------- queries --------------------------------------------------- */

  async function getComponent(): Promise<Component> {
    const accessToken: string = await getAccessTokenSilently();
    return await componentService.findById(componentId, accessToken);
  }

  const { data: componentData, status: componentStatus } = useQuery<Component, Error>(
    ['component', componentId],
    getComponent,
    {
      cacheTime: 0,
    },
  );

  const BackApiSpecificationId: string | undefined = componentData?.BackApiSpecification?.id;
  const { data: resourcesData, status: resourcesStatus } = useQuery<Resource[], Error>({
    queryKey: ['resources', BackApiSpecificationId],
    queryFn: async ({ queryKey }) => {
      const [, BackApiSpecificationId] = queryKey;
      const accessToken: string = await getAccessTokenSilently();
      return await backApiSpecificationService.getResources(BackApiSpecificationId as string, accessToken);
    },
    enabled: BackApiSpecificationId !== undefined,
  });

  // Change URL PATH
  useEffect(() => {
    const path: string = window.location.pathname;
    let search: string = '';
    let isQueryParamAlreadySet: boolean = false;
    if (isEditable) {
      if (!isQueryParamAlreadySet) search = search.concat('?');
      else search = search.concat('&');
      search = search.concat('edition=true');
      isQueryParamAlreadySet = true;
    }
    if (selectedMethodId !== undefined) {
      if (!isQueryParamAlreadySet) search = search.concat('?');
      else search = search.concat('&');
      search = search.concat(`methodid=${selectedMethodId}`);
      isQueryParamAlreadySet = true;
    }
    navigate(path.concat(search));
  }, [isEditable, navigate, selectedMethodId]);

  useEffect(() => {
    if (selectedPathMethodId !== undefined) setSelectedMethod(getMethodById(selectedPathMethodId));
  }, [selectedPathMethodId, resourcesData]);

  useEffect(() => {
    if (resourcesStatus === 'success') {
      const resourcesFront: ResourceFront[] = resourcesData?.map((resource: Resource) => new ResourceFront(resource));
      setResources(resourcesFront);
    }
  }, [resourcesData, resourcesStatus]);

  useEffect(() => {
    let updatedResourcesFront: ResourceFront[];
    if (resources === undefined) return;
    if (resources.length === 0) {
      if (resourcesStatus !== 'success') return;
      updatedResourcesFront = resourcesData.map((resource: Resource) => {
        const newResourceFront: ResourceFront = new ResourceFront(resource);
        if (
          newResourceFront.resourceRevisions[0].methods.find((method) => method.id === selectedMethodId) !== undefined
        ) {
          newResourceFront.isCollapsed = false;
        }
        return newResourceFront;
      });
    } else {
      updatedResourcesFront = resources.map((resource: ResourceFront) => {
        if (resource.resourceRevisions[0].methods.find((method) => method.id === selectedMethodId) !== undefined) {
          resource.isCollapsed = false;
        }
        return resource;
      });
    }
    setResources(updatedResourcesFront);
    // DO NOT ADD RESOURCES TO DEPENDENCIES DUE TO RESOURCES BEING UPDATED AT THE END OF THIS USEEFFECT
  }, [selectedMethodId, resourcesStatus, resourcesData]);

  // update Arrow of SideMenu
  useEffect(() => {
    setArrowXPosition(isSideMenuOpened ? (document.getElementById('SideMenu')?.offsetWidth ?? 10) - 10 : 0);
  }, [isSideMenuOpened]);

  /* ------------------------------------------------- Create Method ------------------------------------------------ */

  async function createMethod({ resourceId, method }: CreateMethodProps): Promise<GetMethodDto> {
    const accessToken: string = await getAccessTokenSilently();
    return await resourceService.createMethod(resourceId, method, accessToken);
  }

  const { mutate: mutateAddMethod } = useMutation(createMethod, {
    onSuccess: (getMethod: GetMethodDto) => {
      queryClient.setQueryData(['resources', BackApiSpecificationId], (oldData: Resource[] | undefined) => {
        if (oldData === undefined) {
          return [];
        }
        // open the method
        setSelectedMethodId(getMethod.id);
        oldData
          .find((resource: Resource) => resource.id === selectedResourceId)
          ?.resourceRevisions[0].methods.push(fromGetMethodDto(getMethod));
        oldData.forEach((resource: Resource) => {
          resource.resourceRevisions[0].methods.sort((a: Method, b: Method) => {
            return a.methodRevisions[0].name.localeCompare(b.methodRevisions[0].name);
          });
        });
        return oldData;
      });
      setResources(
        resources.map((resource: ResourceFront) => {
          if (resource.id === selectedResourceId) {
            resource.isCollapsed = false;
          }
          return resource;
        }),
      );
      toast.success('La méthode a bien été ajoutée');
      setIsAddMethodFormOpened(false);
      setSelectedMethod(getMethodById(getMethod.id));
    },
    onError: () => {
      toast.error("Une erreur est survenue pendant l'ajout de la méthode");
    },
  });

  async function handleMethodFormSubmission(method: Method): Promise<void> {
    try {
      mutateAddMethod({ resourceId: selectedResourceId, method });
    } catch (error) {
      console.error(error);
    }
  }

  /* ------------------------------------------------- Delete Method ------------------------------------------------ */

  async function deleteMethod(methodId: string): Promise<void> {
    const accessToken: string = await getAccessTokenSilently();
    await methodService.deleteMethod(methodId, accessToken);
  }

  const { mutate: mutateDeleteMethod } = useMutation(deleteMethod, {
    onSuccess: () => {
      queryClient.setQueryData(['resources', BackApiSpecificationId], (oldData: Resource[] | undefined) => {
        if (oldData === undefined) {
          return [];
        }
        oldData.forEach((resource: Resource) => {
          resource.resourceRevisions[0].methods = resource.resourceRevisions[0].methods.filter(
            (method: Method) => method.id !== selectedMethodId,
          );
        });
        return oldData;
      });
      setSelectedMethodId(undefined);
      toast.success('La méthode a bien été supprimée');
    },
    onError: () => {
      toast.error('Une erreur est survenue pendant la suppression de la méthode');
    },
  });

  useEffect(() => {
    if (methodToRemove !== undefined) {
      mutateDeleteMethod(methodToRemove);
    }
  }, [methodToRemove, mutateDeleteMethod]);

  /* -------------------------------------------------- edit method ------------------------------------------------- */
  async function editMethod(method: Method): Promise<Method> {
    const accessToken: string = await getAccessTokenSilently();
    if (selectedMethod === undefined) throw new Error('selectedMethod is undefined');
    return await methodService.partialUpdate(selectedMethodId ?? '', method, accessToken);
  }

  function handleEditMethodFormSubmission(methodToUpdate: Method): void {
    setMutateStatus('loading');
    setTimeout(() => {
      handleMethodEditionConfirmation(methodToUpdate);
    }, 1);
  }
  function handleMethodEditionConfirmation(methodToUpdate: Method): void {
    toast.update("La méthode est en cours de modification, vous allez être redirigé vers la page de l'API");
    try {
      mutateEditMethod(methodToUpdate);
    } catch (error) {
      toast.error('Une erreur est survenue pendant la modification de la méthode');
      setMutateStatus('error');
    }
  }

  const { mutate: mutateEditMethod } = useMutation(editMethod, {
    onSuccess: (updatedMethod: Method) => {
      queryClient.setQueryData(['resources', BackApiSpecificationId], (oldData: Resource[] | undefined) => {
        if (oldData === undefined) {
          return [];
        }
        oldData.forEach((resource: Resource) => {
          resource.resourceRevisions[0].methods = resource.resourceRevisions[0].methods.map((method: Method) => {
            if (method.id === updatedMethod.id) {
              return updatedMethod;
            }
            return method;
          });
        });
        return oldData;
      });
      toast.success('La méthode a bien été modifiée');
      setSelectedMethod(updatedMethod);
      setIsEditMethodModalOpened(false);
      setMutateStatus('success');
    },
    onError: () => {
      toast.error('Une erreur est survenue pendant la modification de la méthode');
      setMutateStatus('error');
    },
  });

  function handleMethodModificationClick(methodId: string): void {
    if (getMethodById(methodId) === undefined) return;
    setSelectedMethod(selectedMethod);
    setSelectedMethodId(methodId);
    setIsEditMethodModalOpened(true);
  }

  /* ------------------------------------------------ Create Resource ----------------------------------------------- */

  async function createResource({ BackApiSpecificationId, resource }: CreateResourceProps): Promise<Resource> {
    const accessToken: string = await getAccessTokenSilently();
    return await backApiSpecificationService.createResource(BackApiSpecificationId, resource, accessToken);
  }

  const { mutate: mutateAddResource } = useMutation(createResource, {
    onSuccess: (resource: Resource) => {
      setIsAddResourceFormOpened(false);
      setIsAddSidePanelOpened(false);
      queryClient.setQueryData(['resources', BackApiSpecificationId], (oldData: Resource[] | undefined) => {
        if (oldData === undefined) {
          return [];
        }
        oldData.push(resource);
        oldData.sort((a: Resource, b: Resource) => {
          return a.resourceRevisions[0].name.localeCompare(b.resourceRevisions[0].name);
        });
        return oldData;
      });
      toast.success('La ressource a bien été ajoutée');
      const unsortedResources: ResourceFront[] = [...resources, new ResourceFront(resource, false)];
      setResources(
        unsortedResources.sort((a: ResourceFront, b: ResourceFront) =>
          a.resourceRevisions[0].name.localeCompare(b.resourceRevisions[0].name),
        ),
      );
    },
    onError: () => {
      toast.error("Une erreur est survenue pendant l'ajout de la ressource");
    },
  });

  async function handleResourceFormSubmission(resource: Resource): Promise<void> {
    try {
      if (BackApiSpecificationId !== undefined) {
        mutateAddResource({ BackApiSpecificationId, resource });
      }
    } catch (error) {
      console.error(error);
    }
  }

  /* ------------------------------------------------- edit resource ------------------------------------------------ */

  async function editResource(resource: Resource): Promise<Resource> {
    const accessToken: string = await getAccessTokenSilently();
    return await resourceService.updateResource(selectedResourceId, resource, accessToken);
  }

  function handleEditResourceFormSubmission(updatedResource: Resource): void {
    setMutateStatus('loading');
    setTimeout(() => {
      handleResourceEditionConfirmation(updatedResource);
    }, 1);
  }

  function handleResourceEditionConfirmation(updatedResource: Resource): void {
    toast.update("La ressource est en cours de modification, vous allez être redirigé vers la page de l'API");
    try {
      mutateEditResource(updatedResource);
    } catch (error) {
      toast.error('Une erreur est survenue pendant la modification de la ressource');
      setMutateStatus('error');
    }
  }
  const { mutate: mutateEditResource } = useMutation(editResource, {
    onSuccess: (editedResource: Resource) => {
      queryClient.setQueryData(['resources', BackApiSpecificationId], (oldData: Resource[] | undefined) => {
        if (oldData === undefined) {
          return new Array<Resource>();
        } else {
          setResources(
            resources.map((resource: ResourceFront) => {
              if (resource.id === editedResource.id) {
                return new ResourceFront(editedResource);
              }
              return resource;
            }),
          );
          oldData = oldData.map((resource: Resource) => {
            if (resource.id === editedResource.id) {
              return editedResource;
            }
            return resource;
          });
          return oldData;
        }
      });
      toast.success('La ressource a bien été modifiée');
      setIsEditResourceModalOpened(false);
      setMutateStatus('error');
    },
    onError: () => {
      toast.error('Une erreur est survenue pendant la modification de la ressource');
      setMutateStatus('error');
    },
  });

  function handleResourceModificationClick(resourceId: string): void {
    const selectedResource: Resource | undefined = resourcesData?.find(
      (resource: Resource) => resource.id === resourceId,
    );
    if (selectedResource === undefined) return;
    setSelectedResource(selectedResource);
    setSelectedResourceId(resourceId);
    setIsEditResourceModalOpened(true);
  }

  /* ----------------------------------------------- delete Resource ----------------------------------------------- */

  async function deleteResource(resourceId: string): Promise<void> {
    setResourceToRemove(resourceId);
    const accessToken: string = await getAccessTokenSilently();
    await resourceService.deleteResource(resourceId, accessToken);
  }

  const { mutate: mutateDeleteResource } = useMutation(deleteResource, {
    onSuccess: () => {
      queryClient.setQueryData(['resources', BackApiSpecificationId], (oldData: Resource[] | undefined) => {
        if (oldData === undefined) {
          return [];
        }
        return oldData.filter((resource: Resource) => resource.id !== resourceToRemove);
      });
      setResources(resources.filter((resource: ResourceFront) => resource.id !== resourceToRemove));
      toast.success('La ressource a bien été supprimée');
    },
    onError: () => {
      toast.error('Une erreur est survenue pendant la suppression de la ressource');
    },
  });

  useEffect(() => {
    if (resourceToRemove !== undefined) {
      mutateDeleteResource(resourceToRemove);
    }
  }, [resourceToRemove, mutateDeleteResource]);

  /* --------------------------------------------------- functions -------------------------------------------------- */

  function handleClickAddMethod(resourceId: string): void {
    setSelectedResourceId(resourceId);
    setIsAddMethodFormOpened(true);
  }

  function handleClickAddResource(): void {
    setIsAddResourceFormOpened(true);
  }

  function handleResourceClick(resourceId: string): void {
    resources.forEach((resource: ResourceFront) => {
      if (resource.id === resourceId) {
        resource.isCollapsed = !resource.isCollapsed;
      }
    });
    setResources([...resources]);
  }

  function handleClickEdit(): void {
    navigate(`/component/${componentId}/specifications-api?edition=true`);
  }

  function handleMethodClick(methodId: string): void {
    setSelectedMethodId(methodId);
    setSelectedMethod(getMethodById(methodId));
  }

  function isResourcesEmpty(): boolean {
    return resources.length === 0;
  }

  function getMethodById(methodId: string): Method | undefined {
    const selectedMethod: Method | undefined = resourcesData
      ?.map((resource: Resource) => {
        return resource.resourceRevisions[0].methods.find((method: Method) => method.id === methodId);
      })
      .find((method: Method | undefined) => method !== undefined);

    return selectedMethod;
  }

  /* ------------------------------------------------ set up handler ------------------------------------------------ */

  const resourceHandler: ResourceHandler = {
    resources: resourcesStatus === 'success' ? resourcesData : [],
    selectedMethod,
    resourceIdofSelectedMethod: resourcesData?.find((resource: Resource) => {
      return resource.resourceRevisions[0].methods.find((method) => method.id === selectedMethodId) !== undefined;
    })?.id,
    details: {
      setIsNewPathFormOpened,
      isNewPathFormOpened,
      setPathIdUpdateFormOpened,
      pathIdUpdateFormOpened,
      setPathData,
      pathData,
      setIsNewQueryFormOpened,
      isNewQueryFormOpened,
      setQueryIdUpdateFormOpened,
      queryIdUpdateFormOpened,
      setQueryData,
      queryData,
      setIsNewHeaderFormOpened,
      isNewHeaderFormOpened,
      setHeaderIdUpdateFormOpened,
      headerIdUpdateFormOpened,
      setHeaderData,
      headerData,
      setIsNewBodyFormOpened,
      isNewBodyFormOpened,
      setBodyIdUpdateFormOpened,
      bodyIdUpdateFormOpened,
      setBodyData,
      bodyData,

      setDeleteRowId,
      deleteRowId,

      setIsSuccessResponseNewFormOpened,
      isSuccessResponseNewFormOpened,
      setSuccessResponseIdUpdateFormOpened,
      successResponseIdUpdateFormOpened,
      setIsErrorResponseNewFormOpened,
      isErrorResponseNewFormOpened,
      setErrorResponseIdUpdateFormOpened,
      errorResponseIdUpdateFormOpened,
      setApiResponseData,
      apiResponseData,

      setNewResponseBodyIdFormOpened,
      newResponseBodyIdFormOpened,
      setResponseBodyIdUpdateFormOpened,
      responseBodyIdUpdateFormOpened,
      setUpdatingBodyData,
      updatingBodyData,
      setNewlyCreatedResponseRow,
      newlyCreatedResponseRow,

      successMutateMessage: undefined,
      errorMutateMessage: undefined,
      setMutateStatus,
      mutateStatus,

      closeUpdateForms(id: string = '') {
        console.error('This message should not appear');
      },
      closeAllFormsExcept: (id: string = '') => {
        console.error('This message should not appear');
      },
    },
  };

  resourceHandler.details.closeUpdateForms = (id: string = '') => {
    if (resourceHandler.details.pathIdUpdateFormOpened !== id) setPathIdUpdateFormOpened(undefined);
    if (resourceHandler.details.queryIdUpdateFormOpened !== id) setQueryIdUpdateFormOpened(undefined);
    if (resourceHandler.details.headerIdUpdateFormOpened !== id) setHeaderIdUpdateFormOpened(undefined);
    if (resourceHandler.details.bodyIdUpdateFormOpened !== id) setBodyIdUpdateFormOpened(undefined);
    if (resourceHandler.details.successResponseIdUpdateFormOpened !== id)
      if (resourceHandler.details.errorResponseIdUpdateFormOpened !== id) setErrorResponseIdUpdateFormOpened(undefined);

    if (resourceHandler.details.newResponseBodyIdFormOpened !== id) setNewResponseBodyIdFormOpened(undefined);
    if (resourceHandler.details.responseBodyIdUpdateFormOpened !== id) setResponseBodyIdUpdateFormOpened(undefined);
  };

  resourceHandler.details.closeAllFormsExcept = (id: string = '') => {
    setIsNewPathFormOpened(false);

    setIsNewQueryFormOpened(false);

    setIsNewHeaderFormOpened(false);

    setIsNewBodyFormOpened(false);

    setIsSuccessResponseNewFormOpened(false);
    setNewlyCreatedResponseRow(undefined);

    setIsErrorResponseNewFormOpened(false);
    setSuccessResponseIdUpdateFormOpened(undefined);
    resourceHandler.details.closeUpdateForms(id);
  };

  /* ------------------------------------------------------ JSX ----------------------------------------------------- */

  return (
    <ResourceProvider handler={resourceHandler}>
      <>
        {resourcesStatus === 'success' && (
          <Modal
            title="Ajouter une ressource"
            isOpen={isAddResourceFormOpened}
            setIsOpen={setIsAddResourceFormOpened}
            tooltipMessage={tooltipDescriptionsStyle.ressource}
          >
            <CreateOrEditResourceForm onSubmit={handleResourceFormSubmission} currentResources={resourcesData} />
          </Modal>
        )}
        <Modal
          title="Modifiez votre ressource"
          isOpen={isEditResourceModalOpened}
          setIsOpen={setIsEditResourceModalOpened}
        >
          <CreateOrEditResourceForm
            isEditing
            resource={selectedResource}
            onSubmit={handleEditResourceFormSubmission}
            currentResources={resourcesData ?? []}
          />
        </Modal>
      </>
      <Modal
        title="Ajouter une méthode"
        isOpen={isAddMethodFormOpened}
        setIsOpen={setIsAddMethodFormOpened}
        tooltipMessage={tooltipDescriptionsStyle.method}
      >
        <CreateOrEditMethodForm onSubmit={handleMethodFormSubmission} />
      </Modal>
      <Modal title="Modifiez votre méthode" isOpen={isEditMethodModalOpened} setIsOpen={setIsEditMethodModalOpened}>
        <CreateOrEditMethodForm
          isEditing
          method={selectedPathMethodId !== undefined ? getMethodById(selectedPathMethodId) : undefined}
          onSubmit={handleEditMethodFormSubmission}
        />
      </Modal>
      <div id="widget_API" className="flex flex-row flex-grow items-stretch overflow-auto h-full">
        {isSideMenuOpened && (
          <SideMenu width={'md'} isCollapsable={isSideMenuCollapsable}>
            <>
              {!isAddSidePanelOpened && (
                <div className={concatClassNames('flex flex-col', 'h-full', 'justify-between', 'gap-6')}>
                  <div className="flex flex-col h-full gap-4 overflow-auto">
                    {resourcesStatus === 'success' &&
                      resources.length !== 0 &&
                      resources?.map((resource: ResourceFront) => (
                        <ApiCollapse
                          key={uuid()}
                          resourceId={resource.id}
                          title={resource.resourceRevisions[0].name}
                          arrowPosition="left"
                          onClickAddMethod={isEditable ? handleClickAddMethod : null}
                          isCollapsed={resource.isCollapsed}
                          onClick={handleResourceClick}
                          onDelete={isEditable ? setResourceToRemove : undefined}
                          onEdit={isEditable ? handleResourceModificationClick : undefined}
                        >
                          <ApiMethodsWrapper
                            methods={resource.resourceRevisions[0].methods.map((method: Method) => {
                              return {
                                id: method.id,
                                type: method.methodRevisions[0].methodType,
                                path: method.methodRevisions[0].name,
                                description: method.methodRevisions[0].description,
                              };
                            })}
                            onMethodClick={handleMethodClick}
                            selectedMethodId={selectedMethodId}
                          />
                        </ApiCollapse>
                      ))}
                    {resourcesStatus === 'success' && resourcesData.length === 0 && (
                      <Empty
                        title="Aucune ressource renseignée"
                        icon="box"
                        subtitle="Ce composant ne possède pas encore de ressource API."
                      >
                        <>
                          {isEditable && (
                            <Button
                              content="Ajouter"
                              width="fit"
                              height="sm"
                              onClick={handleClickAddResource}
                              iconName="plus"
                            />
                          )}
                        </>
                      </Empty>
                    )}
                    {resourcesStatus === 'error' ||
                      (componentStatus === 'error' && (
                        <NetworkError message="Une erreur est survenue pendant l'importation des Ressources" />
                      ))}
                    {resourcesStatus === 'loading' && (
                      <>
                        <ApiCollapse loading arrowPosition="left" />
                        <ApiCollapse loading arrowPosition="left" />
                        <ApiCollapse loading arrowPosition="left" />
                      </>
                    )}
                  </div>
                  {isEditable && (
                    <div className="flex flex-row gap-2 justify-end pt-8 border-t-1 border-gray-50">
                      <Button
                        content="Ajouter"
                        iconPosition="right"
                        iconName="plus"
                        type="submit"
                        disabled={resourcesStatus !== 'success'}
                        onClick={handleClickAddResource}
                      />
                    </div>
                  )}
                </div>
              )}
            </>
          </SideMenu>
        )}

        {/* arrow at the left of the sideMenu to open/close the sideMenu */}
        {/* <div
          className={concatClassNames(
            'absolute',
            'p-1',
            'rounded-full',
            'bg-white',
            'border-1',
            'border-gray-200',
            'top-1/2',
            'z-10',
            'cursor-pointer',
            isSideMenuOpened ? 'rotate-180' : '',
            'transition duration-200 transform',
          )}
          style={{
            left: arrowXPosition,
          }}
          onClick={() => {
            setIsSideMenuOpened(!isSideMenuOpened);
          }}
        >
          {getIcon('angleRight', 'black', 'sm')}
        </div> */}
        <div className={concatClassNames('relative', 'flex', 'w-full', 'h-full')}>
          {!isEditable && isResourcesEmpty() && (
            <div
              className={concatClassNames(
                'absolute',
                'flex flex-col',
                'h-full',
                'w-full',
                'justify-center',
                'items-center',
                'z-10',
              )}
            >
              <Empty
                title="Aucune méthode renseignée"
                subtitle="Lorsque vous aurez défini vos ressources & méthodes, vous pourrez les visualiser à cet endroit."
                width="2/5"
                height="fit"
                icon="html"
              >
                <Button content="Commencez !" width="fit" height="sm" onClick={handleClickEdit} iconName="plus" />
              </Empty>
            </div>
          )}
          {!isEditable && !isResourcesEmpty() && selectedMethodId === undefined && (
            <div
              className={concatClassNames(
                'absolute',
                'flex flex-col',
                'h-full',
                'w-full',
                'justify-center',
                'items-center',
              )}
            >
              <Empty
                title="Cliquez sur une méthode du pane latéral gauche pour la visualiser"
                width="2/5"
                height="fit"
                icon="html"
              />
            </div>
          )}
          {isEditable && isResourcesEmpty() && (
            <div
              className={concatClassNames(
                'absolute',
                'flex flex-col',
                'h-full',
                'w-full',
                'justify-center',
                'items-center',
                'z-10',
              )}
            >
              <Empty
                title="Visualisez ici le détail de votre méthode"
                subtitle="Cliquez sur le “+” à droite d’une ressource pour ajouter une méthode au sein d’une ressource déjà existante, sinon :"
                width="2/5"
                height="fit"
                icon="html"
              >
                <Button
                  content="Créez une nouvelle ressource"
                  width="fit"
                  height="sm"
                  onClick={handleClickAddResource}
                  iconName="plus"
                />
              </Empty>
            </div>
          )}
          {isEditable && !isResourcesEmpty() && selectedMethodId === undefined && (
            <div
              className={concatClassNames(
                'absolute',
                'flex flex-col',
                'h-full',
                'w-full',
                'justify-center',
                'items-center',
                'z-10',
              )}
            >
              <Empty
                title="Cliquez sur une méthode du pane latéral gauche pour la visualiser"
                width="2/5"
                height="fit"
                icon="html"
              />
            </div>
          )}
          <ComponentBackEndApiDetail
            selectedMethodId={selectedMethodId}
            isEditable={isEditable}
            onDelete={setMethodToRemove}
            onEdit={handleMethodModificationClick}
          />
        </div>
      </div>
    </ResourceProvider>
  );
}
