import * as Yup from 'yup';

import React from 'react';
import { type SubmitHandler, useFieldArray, useForm } from 'react-hook-form';
import uuid from 'react-uuid';

import { yupResolver } from '@hookform/resolvers/yup';
import { Tooltip } from '@mui/material';

import { Button } from 'components/core/Button/Button';
import Input from 'components/core/Input/Input';
import { Text } from 'components/core/Text/Text';

import { Enum } from 'models/API/Enum.entity';

import concatClassNames from 'utils/classNames';
import getIcon from 'utils/getIcon';

// const typeOptions: InputTypeBody[] = ['STRING', 'NUMBER'];

// export const inputTypeOptions: Map<string, string> = new Map<string, string>([
//   ['STRING', 'Chaîne de caractères'],
//   ['NUMBER', 'Nombre'],
// ]);

interface FormValues {
  // enumType: InputTypeBody;
  enumValues: Array<{
    name: string;
    description?: string;
  }>;
}

const schema: Yup.ObjectSchema<FormValues> = Yup.object().shape({
  // enumType: Yup.mixed<InputTypeBody>().required('Champ obligatoire'),
  enumValues: Yup.array()
    .of(
      Yup.object().shape({
        name: Yup.string().required('Champ obligatoire'),
        // .test('isNumber', 'Doit être un nombre', (value, ctx) => {
        //   if (value === undefined) return true;
        //   if (value === '') return true;
        //   let ca: boolean = false;
        //   ctx.from?.forEach((field) => {
        //     if (field.value.enumType !== undefined && field.value.enumType !== 'NUMBER') {
        //       ca = true;
        //       return true;
        //     }
        //   });
        //   if (ca) return true;
        //   if (isNaN(Number(value))) return false;
        //   return true;
        // }),
        description: Yup.string(),
      }),
    )
    .required('Champ obligatoire'),
});

interface ApiEnumTypeFormProps {
  onSubmit?: (enumEntity: Enum) => void;
  prevValue?: Enum;
  readonly?: boolean;
}

export function ApiEnumTypeForm({ onSubmit, prevValue, readonly = false }: ApiEnumTypeFormProps): JSX.Element {
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>({
    mode: 'all',
    defaultValues: {
      // enumType: prevValue === undefined ? 'STRING' : prevValue.valueType,
      enumValues:
        prevValue === undefined
          ? [{ name: '', description: '' }]
          : prevValue.values.map((value) => ({ name: value.name, description: value.description })),
    },
    resolver: yupResolver(schema),
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'enumValues',
  });

  const onSubmitForm: SubmitHandler<FormValues> = (data) => {
    const enumEntity: Enum = new Enum();
    // enumEntity.valueType = data.enumType;
    enumEntity.values = data.enumValues.map((enumValue) => {
      return {
        id: '',
        name: enumValue.name,
        description: enumValue.description ?? '',
      };
    });
    onSubmit?.(enumEntity);
  };
  const gridTemplateColumns: string = 'grid-cols-[repeat(16,minmax(0,1fr))]';
  const gridTitleSpan: string = 'col-span-7';
  const gridDescriptionSpan: string = 'col-span-8';

  return (
    <form className={concatClassNames('flex flex-col', 'gap-3', 'w-[700px]')} onSubmit={handleSubmit(onSubmitForm)}>
      {/* <Controller
        name="enumType"
        control={control}
        defaultValue={'STRING'}
        render={({ field: { value, onChange } }) => (
          <ListBox
            label="Type des valeurs"
            selected={value}
            onChange={onChange}
            options={typeOptions}
            styleOptions={typeStyleOptions}
            readonly={readonly}
          />
        )}
      />
      {errors.enumType != null && isSubmitted && <p>{errors.enumType.message}</p>} */}

      <Text content="Valeurs" position="left" size="base" color="black" weight="bold" />
      <div className={concatClassNames('flex flex-col', 'gap-1.5')}>
        {fields.length === 0 && (
          <div className="flex flex-row justify-center">
            <Text content="Aucune valeur" position="left" size="sm" color="gray-200" />
          </div>
        )}
        {fields.map((field, index) => {
          return (
            <React.Fragment key={uuid()}>
              <div
                key={field.id}
                id="api-enum-form"
                className={concatClassNames(
                  'grid',
                  gridTemplateColumns,
                  'gap-1',
                  'gap-y-1.5',
                  'rounded-md',
                  'pr-3',
                  'align-middle',
                )}
              >
                <Tooltip title={errors.enumValues?.[index]?.name?.message} arrow followCursor>
                  <div className={concatClassNames(gridTitleSpan, 'flex items-center')}>
                    <Input
                      placeholder="Valeur"
                      {...register(`enumValues.${index}.name` as const, {
                        required: true,
                      })}
                      width="full"
                      borderColor={errors.enumValues?.[index]?.name?.message !== undefined ? 'error' : 'gray-50'}
                      readonly={readonly}
                      // isNumber={watch(`enumType`) === 'NUMBER'}
                    />
                  </div>
                </Tooltip>

                <div className={concatClassNames(gridDescriptionSpan, 'flex items-center')}>
                  <Input
                    placeholder="Description"
                    {...register(`enumValues.${index}.description` as const, {
                      required: true,
                    })}
                    width="full"
                    borderColor={errors.enumValues?.[index]?.description?.message !== undefined ? 'error' : 'gray-50'}
                    readonly={readonly}
                  />
                </div>

                {!readonly && (
                  <div
                    className={concatClassNames('flex items-center justify-center', 'cursor-pointer')}
                    onClick={() => {
                      remove(index);
                    }}
                  >
                    {getIcon('trash', 'red', 'md')}
                  </div>
                )}
              </div>
            </React.Fragment>
          );
        })}
      </div>

      {!readonly && (
        <>
          <div className="flex flex-row justify-start">
            <Button
              content="Ajouter une valeur"
              width="fit"
              type="button"
              bgColor="grey"
              borderColor="grey-500"
              borderWidth="xs"
              textColor="black"
              onClick={() => {
                append({
                  name: '',
                  description: '',
                });
              }}
            />
          </div>
          <div className="flex flex-row justify-end">
            <Button content="Valider" width="1/2" type="submit" />
          </div>
        </>
      )}
    </form>
  );
}
