import * as Yup from 'yup';

import React from 'react';
import { Controller, type SubmitHandler, useForm } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';

import { statusOptions, statusStyleOptions } from 'forms/CreateorEditProductForm/CreateorEditProductForm';

import { Button } from 'components/core/Button/Button';
import Input from 'components/core/Input/Input';
import { ListBox } from 'components/core/ListBox/ListBox';

import { Status } from 'models/Status.types';
import { Feature } from 'models/feature.entity';

interface CreateProductFeatureFormModel {
  name: string;
  status: Status;
}

const schema: Yup.ObjectSchema<CreateProductFeatureFormModel> = Yup.object().shape({
  name: Yup.string().required('Champ obligatoire').min(3, 'Le nom doit contenir au moins 3 caractères'),
  status: Yup.mixed<Status>().required('Champ obligatoire'),
});

interface CreateProductFeatureFormProps {
  onSubmit: (feature: Feature) => void;
}

export function CreateProductFeatureForm({ onSubmit }: CreateProductFeatureFormProps): JSX.Element {
  const {
    register,
    control,
    handleSubmit,
    watch,
    formState: { errors, isSubmitted },
  } = useForm<CreateProductFeatureFormModel>({
    resolver: yupResolver(schema),
    shouldUnregister: true,
  });

  const watchName: string = watch('name');

  let isFormValid: boolean = true;

  if (watchName === undefined || watchName === '') {
    isFormValid = false;
  }

  const onSubmitForm: SubmitHandler<CreateProductFeatureFormModel> = (data) => {
    const feature: Feature = new Feature();
    feature.name = data.name;
    feature.status = data.status;
    onSubmit(feature);
  };

  return (
    <form className="flex flex-col gap-6" onSubmit={handleSubmit(onSubmitForm)}>
      <Input
        label="Nom de la fonctionnalité"
        placeholder="Votre fonctionnalité"
        {...register('name')}
        maxLength={28}
        error={errors.name != null && isSubmitted ? errors.name.message : undefined}
      />
      <Controller
        name="status"
        control={control}
        defaultValue={Status.Indevelopment}
        render={({ field: { value, onChange } }) => (
          <ListBox
            label="Statut de la fonctionnalité"
            selected={value}
            onChange={onChange}
            options={statusOptions}
            styleOptions={statusStyleOptions}
          />
        )}
      />
      {errors.status != null && isSubmitted && <p>{errors.status.message}</p>}

      <div className="flex flex-row gap-2 justify-end">
        <Button content="Créer" type="submit" width="1/2" disabled={!isFormValid} />
      </div>
    </form>
  );
}
