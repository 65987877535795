/* eslint-disable max-len */
import React from 'react';

import concatClassNames from 'utils/classNames';

import { type IconProps, iconColorStyle, iconSizeStyle } from './Icon.props';

export default function PenLineIcon({ color, size }: IconProps): JSX.Element {
  return (
    <svg id="Layer_3" xmlns="http://www.w3.org/2000/svg" className={iconSizeStyle[size]} viewBox="0 0 128 128">
      <path
        className="cls-1"
        d="m106.92,25.73c-.92.06-5.79,2.37-6.32,4.7-.44,1.96-4.6-1.08-4.6-1.08,0,0,2.13-3.37,5.92-3.97s5,.35,5,.35Z"
      />
      <path
        className="cls-1"
        d="m92.93,39.22c1.59-3.6,5.52-6.73,7.18-6.67,0,0-4.13-2.12-5.61-1.55s-7.19,7.33-7.19,7.33l5.62.9Z"
      />
      <path
        className="cls-1"
        d="m75.08,57.17c-1.89,2.48-3.74,5.01-5.56,7.55-.67.93-1.26,1.9-1.93,2.83-2.32,3.29-4.62,6.6-6.82,9.97-.97,1.5-1.9,3.04-2.82,4.56-1.17,1.95-2.19,4-3.79,5.71-.53.51-.47.44-.41-.21,0-.69.12-1.9.64-3.13,1.07-2.08,2.22-4.13,3.43-6.13,1.25-1.98,2.54-3.94,3.91-5.86,1.64-2.3,3.14-4.82,4.75-7.21,1.78-2.62,3.61-5.22,5.49-7.77,1.15-1.58,2.34-3.13,3.53-4.68,1.81-2.35,3.69-4.65,5.6-6.9,1.49-1.75,2.89-3.52,4.6-5.12.37-.35.74-.71,1.18-1.03.38-.32.76-.52,1.09-.66.31-.16.62-.23.88-.27.49-.1.86.05,1.12.27.12.1.25.19.31.29.1.17-.03.37-.25.62-.11.13-.24.23-.42.41-.17.16-.37.3-.59.52-1.38,1.14-2.78,2.57-4.12,4.09-1.35,1.52-2.7,3.12-4.02,4.74-1.96,2.42-3.9,4.9-5.77,7.44l-.05-.03Z"
      />
      <path
        className="cls-1"
        d="m56.71,89.95c.37.34.62.78.82,1.19.06.15.19.28.23.44.17.56.25,1.09.22,1.62,0,.23,0,.45.03.67.02.28.05.56-.08.84-.04.09-.23.08-.44.01-.21-.07-.46-.18-.52-.31-.18-.5-.46-.91-.4-1.4.02-.3-.17-.54-.29-.78-.13-.26-.28-.51-.48-.73-.13-.12-.23-.29-.39-.38-.22-.18-.49-.33-.79-.37-.23-.04-.47.02-.7.03-.05,0-.1.03-.15.01-.19-.05-.26-.16-.33-.44l-.04-.19c-.04-.2.04-.4.21-.54.49-.43,1.16-.5,1.79-.35.47.12.94.34,1.35.65l-.03.04Z"
      />
      <path className="cls-1" d="m54.59,108.59c-1.87-3.63-4.85-3.09-4.85-3.09l-1.26,6.16,6.11-3.07Z" />
      <path
        className="cls-1"
        d="m59.51,91.77s.04.02.08.04c.04,0-.03.02.36-.02.28-.04.56-.07.9-.03.32,0,.63.06.93.14.31.06.58.18.86.3.55.24,1.08.51,1.52.89.58.54,1.08,1.15,1.41,1.86.17.34.31.71.41,1.08l.12.57c.02.14,0,.18,0,.28,0,.08,0,.16-.02.25,0,.08-.06.22.05.19.15,0,.33,0,.5,0,.04,0,.28,0,.48.02.2.02.41.05.62.08.52.14,1.09.36,1.54.77.44.45.63,1.08.63,1.61-.02.35-.2.31-.54.18-.17-.06-.4-.14-.64-.17-.24-.03-.48,0-.65,0-.38-.1-.84-.05-1.44-.15-.3-.02-.77.02-1.15.04-.39,0-.78-.04-.87-.35l-.2-.32h0s0-.02,0-.02h0c.17-.18-.24.24-.2.2h0s-.01-.03-.01-.03l-.02-.05-.04-.1-.08-.2-.16-.39-.04-.1v-.15s.02-.31.02-.31l-.03-.73-.03-.31c-.07-.86-.82-1.55-1.52-2.03-.38-.23-.78-.42-1.2-.5-.21-.03-.4-.08-.64-.05l-.36.04-.66.33c-.12.07-.24.15-.35.21l-.74.2c-.24.07-.31.06-.47.1l-.21.04c-.07.03-.19-.09-.21-.16-.08-.15-.13-.32-.19-.52-.08-.3-.15-.68-.15-1.14.02-.47.01-.91-.04-1.33-.07-.55-.23-.99-.49-1.35-.44-.54-1.09-.73-1.8-.79-.15-.02-.32,0-.48-.01-.61-.14-.85-.38-1.03-.85-.04-.11-.1-.21-.12-.3-.05-.16,0-.32.13-.49.14-.16.35-.39.67-.49.46-.22.97-.27,1.51-.24.54.03,1.13.15,1.71.42,1.18.57,2,1.87,2.27,2.98.05.21.1.42.13.62,0,.01,0,0,0,.01,0,0-.01.01-.02.03-.02.03-.05.11-.02.1l-.04.09Z"
      />
      <path
        className="cls-1"
        d="m100.6,25.74c.53.15,1.05.33,1.57.53.19.08.39.11.57.2.64.35,1.24.79,1.78,1.26.12.11.25.2.37.3l.35.33c.29.28.59.6.66,1.04.02.14-.23.26-.54.34-.31.08-.68.17-.82.09l-.4-.2c-.14-.06-.28-.11-.38-.2-.23-.18-.46-.37-.7-.6-.3-.28-.73-.32-1.12-.44-.43-.14-.86-.32-1.33-.44-.28-.08-.57-.14-.86-.2-.44-.07-.89-.15-1.34-.12-.35.02-.68.22-1.02.26-.07.01-.15.02-.22,0-.28-.06-.39-.21-.49-.62l-.07-.28c-.06-.3.03-.59.24-.81.61-.76,1.37-.73,2.1-.72.55.02,1.09.12,1.63.22v.05Z"
      />
      <path
        className="cls-1"
        d="m104.35,23.46c1.91.05,3.8.56,5.44,1.46.6.34,1.2.65,1.66,1.04.85.71,1.68,1.46,2.42,2.36.73.89,1.38,1.93,1.73,3.06.33.99.5,2.02.53,3,0,.63-.01,1.25-.1,1.86-.05.61-.37,1.17-.63,1.72-.18.34-.44.23-.76-.14-.16-.18-.34-.43-.52-.72-.09-.14-.18-.29-.26-.45-.04-.08-.07-.15-.11-.24,0-.08-.02-.15-.02-.23-.03-1.1-.13-2.12-.32-3-.21-.88-.55-1.67-1.12-2.51-.68-1.02-1.91-1.71-3.08-2.42-1.16-.76-2.45-1.31-3.86-1.49-.86-.13-1.75-.12-2.63.04-1.33.23-2.67.7-3.82,1.48-.9.59-1.58,1.44-2.25,2.25-.14.19-.26.39-.42.56-.64.59-.99.67-1.67.39-.15-.06-.31-.1-.44-.17-.43-.21-.6-.82-.34-1.58.36-1.14,1.13-2.05,1.96-2.94.83-.9,1.91-1.57,3.03-2.15,1.7-.83,3.63-1.26,5.56-1.27v.08Z"
      />
      <path
        className="cls-1"
        d="m98.45,29.26c.92.05,1.6.27,2.25.5.65.24,1.24.51,1.81.8,1.14.58,2.19,1.24,3.22,1.92.74.5,1.52.96,2.22,1.52,1.24.98,2.38,2.09,3.44,3.31.53.62,1.04,1.26,1.48,2.02.22.38.43.79.58,1.28.07.26.13.53.13.86,0,.31-.04.44-.06.67-.19,1.46-.57,2.88-1.19,4.17-.4.82-.83,1.64-1.42,2.35-.3.35-.63.67-.97.97-.34.3-.71.57-1.14.76-.53.24-.56.06-.48-.5.06-.6.19-1.55.57-2.36l.25-.54c.1-.17.19-.34.28-.51.18-.34.34-.69.49-1.05.28-.71.53-1.42.72-2.13.09-.36.16-.72.22-1.09.04-.18.04-.36.07-.55v-.07s.01-.03.01-.03v-.02s0,0,0,0h0c.16,0-.36-.02.36,0h0c-.1-.01-.2-.01-.3,0-.12,0-.02.02-.05,0v-.04c-.03-.31-.45-1.06-.9-1.67-1.13-1.56-2.93-2.78-4.58-4.02-1.84-1.32-3.82-2.6-5.83-3.31-.3-.1-.61-.18-.88-.23-.29-.05-.47-.05-.83-.01-.63.03-1.27.18-1.89.39-1.89.65-3.67,1.92-5.17,3.42-.59.59-1.12,1.23-1.63,1.88l-1.56,1.96c-.23.29-.44.59-.7.87-.96,1.03-1.53,1.17-2.1,1.03-.13-.03-.27-.05-.38-.1-.33-.17-.21-.8.22-1.74.7-1.33,1.55-2.66,2.51-3.99,1.02-1.27,2.18-2.51,3.53-3.63,2.03-1.63,4.49-3.08,7.7-3.18v.08Z"
      />
      <path
        className="cls-1"
        d="m94.43,41.87c-.5-.22-1.04-.33-1.57-.46l-.8-.2-.82-.1c-.55-.04-1.09-.18-1.66-.15h-1.56s-.06,0-.08.01c-.02.01-.01,0-.05.03-.05.03-.11.07-.18.12-.27.2-.57.47-.86.74-.59.56-1.18,1.18-1.76,1.82-1.17,1.28-2.28,2.64-3.36,4.04-2.17,2.78-4.26,5.65-6.38,8.49-2.06,2.81-4.01,5.7-6.01,8.55-3.53,5.03-7,10.11-10.3,15.27-1.62,2.57-3.31,5.22-4.58,7.82-.19.43-.16.29-.25.8l-.22,1.13-.43,2.27-.86,4.55c-.42,2.2-.85,4.4-1.27,6.6-.22,1.1-.44,2.2-.69,3.3l-.33,1.46-.18.76c-.05.23-.12.45-.18.67l-.1.32c-.05.08.08.03.11.02l.17-.06c.38-.16.75-.33,1.11-.5l.52-.25,1.17-.53,4.79-2.15c1.6-.71,3.23-1.37,4.89-1.96.83-.3,1.66-.59,2.5-.86.85-.25,1.72-.45,2.62-.58,1.13-.12,1.64-.07,1.64.06,0,.14-.49.39-1.25,1-1.51,1.25-4.25,3.32-7.88,4.99l-6.72,3.01-3.02,1.33c-.56.24-1.14.49-1.65.64-.53.16-.95.15-1.29.1-.34-.05-.57-.19-.77-.34-.09-.07-.19-.15-.3-.26l-.24-.22-.24-.22c-.03-.18-.05-.39-.08-.59l-.07-.6-.04-.3.15-.47c.34-1.15.7-2.3.95-3.41l.45-1.94.73-3.37c.46-2.27.92-4.55,1.38-6.83.46-2.29.92-4.59,1.38-6.89l.2-.95c.02-.13.06-.26.12-.39l.13-.32.21-.46c.29-.58.58-1.12.88-1.65.6-1.06,1.22-2.07,1.86-3.08,4.67-7.31,9.52-14.82,14.6-22.03,2.71-3.79,5.41-7.59,8.34-11.25,1.45-1.83,2.94-3.64,4.48-5.43.77-.89,1.56-1.78,2.4-2.64.43-.43.86-.86,1.36-1.27.26-.21.49-.4.86-.63.38-.24.81-.4,1.26-.44h1.24c3.13-.03,6.32.67,9.11,2.19,2.81,1.49,5.1,3.81,6.75,6.48.48.81.92,1.64,1.25,2.54.18.44.32.91.44,1.4.07.25.08.51.13.82.02.31-.04.64-.09.95l-.31.75c-.1.23-.19.49-.31.68l-.67,1.23c-.46.81-.95,1.58-1.43,2.36-.98,1.54-1.99,3.06-3.01,4.56-2.05,3-4.12,5.96-6.21,8.89-4.03,5.68-7.92,11.27-11.98,16.83-.89,1.22-1.75,2.44-2.67,3.65-3.53,4.43-4.9,5.65-6.17,6.2-.29.12-.63.32-.84.36-.69.14.37-1.95,2.66-5.4,1.68-2.57,3.49-5.09,5.33-7.64,1.83-2.55,3.68-5.12,5.54-7.71,3.72-5.19,7.49-10.44,11.13-15.77,1.13-1.66,2.24-3.33,3.31-5.01.56-.84,1.03-1.68,1.55-2.52.45-.79.97-1.79,1.14-2.22,0-.02,0,.06-.02-.16-.03-.19-.12-.48-.18-.72l-.31-.81-.39-.82c-.58-1.08-1.29-2.11-2.1-3.05-1.65-1.85-3.76-3.27-6.09-4.08v-.08Z"
      />
      <path
        className="cls-1"
        d="m80.57,66.35l-4.34,5.62c-.53.69-.99,1.41-1.53,2.1-1.88,2.4-3.78,4.8-5.73,7.15-.86,1.05-1.69,2.13-2.5,3.2-.52.69-1.07,1.36-1.63,2.02-.56.66-1.17,1.29-1.88,1.82-.44.34-.48.2-.4-.32.06-.34.13-.71.19-1.06.11-.41.31-.83.58-1.25.92-1.5,1.97-2.89,3.03-4.29,1.05-1.4,2.11-2.8,3.26-4.15,1.38-1.61,2.58-3.46,3.86-5.21l4.26-5.75,2.63-3.54c1.31-1.8,2.71-3.55,4.01-5.34,1.02-1.38,1.87-2.85,2.91-4.2.22-.3.43-.6.67-.9.92-1.06,1.39-1.26,1.88-1.25.11,0,.24-.02.32,0,.27.05.18.7-.24,1.66-1.34,2.78-3.21,5.34-5.03,7.97-1.34,1.96-2.84,3.82-4.25,5.74l-.05-.03Z"
      />
      <path
        className="cls-1"
        d="m88.32,69.87l-4.4,5.95c-.53.72-1.03,1.48-1.57,2.19-1.93,2.52-3.9,5.01-5.92,7.45-.89,1.09-1.78,2.19-2.67,3.28-.58.69-1.1,1.43-1.76,2.06-.32.32-.64.64-.97.95-.34.31-.74.55-1.11.82-.48.32-.48.2-.32-.3.1-.33.21-.69.31-1.04.15-.4.36-.84.63-1.27.48-.77,1.03-1.48,1.58-2.2l1.64-2.16c1.08-1.46,2.24-2.86,3.44-4.25,1.43-1.67,2.68-3.58,4-5.4l4.34-6.01,2.62-3.74c1.3-1.9,2.66-3.78,3.89-5.71.96-1.5,1.78-3.04,2.72-4.53.2-.33.4-.66.6-.99.77-1.22,1.22-1.49,1.71-1.54.12-.01.24-.04.34-.03.32.04.24.68-.15,1.7-.66,1.45-1.36,2.92-2.16,4.36-.82,1.43-1.69,2.85-2.59,4.26-1.31,2.11-2.75,4.15-4.18,6.18l-.05-.03Z"
      />
      <path
        className="cls-1"
        d="m28.91,111.76c.39.85.28,2.13.19,2.56-.09.56-.16,1.01-.16,1.29.01.28,0,.31.1.46.09.13.35.31.69.43.5.2,1.16.14,1.8.17,2.37.02,5.06-.86,7.92-1.05,1.27-.08,2.59-.14,3.91.14.42.08.83.25,1.24.38.39.18.77.39,1.15.6.75.45,1.22,1.22,1.64,1.92.26.45.04.53-.51.51-.34-.02-.69-.04-1.02-.06-.19-.02-.41,0-.62-.01-.11,0-.2-.01-.31,0-.08-.03-.17-.06-.25-.11-.28-.16-.6-.2-.88-.32-.28-.11-.6-.1-.88-.2-.29-.07-.6-.1-.9-.17l-.96-.06c-1.32-.02-2.77.21-4.33.37-1.86.19-3.82.89-6.08.76-.61-.03-1.25-.15-1.88-.37-.63-.23-1.31-.6-1.84-1.25-.54-.64-.74-1.56-.71-2.2,0-.68.13-1.22.18-1.7.04-.34.05-.4.04-.58,0-.15-.05-.32-.15-.44-.09-.13-.23-.22-.39-.26-.07-.02-.17-.04-.23-.05-.08-.02-.24,0-.36,0-1.68.11-3.62.73-5.36,1.48-1.38.6-2.61,1.44-3.92,2.16-.29.16-.56.34-.86.48-1.12.51-1.67.33-2.15-.05-.11-.09-.24-.17-.32-.26-.14-.16-.13-.37-.02-.65.11-.28.32-.64.66-.98,1.02-.97,2.2-1.75,3.48-2.42,1.27-.68,2.62-1.28,4.05-1.8.97-.35,1.97-.65,3.11-.84.6-.08,1.15-.12,1.96-.04.98.15,1.83.49,2.46,1.17.21.31.43.6.56.95l-.06.04Z"
      />
      <path
        className="cls-1"
        d="m81.71,23.51c.22-.05.24.29.18.81-.02.17-.21.61-.28.66-.15.1-.3.2-.44.26-.08.03-1.18.07-1.58.15-.38.06-.74.06-1.05-.05l-1.97-.53c-.15-.04-.28-.12-.39-.23-.41-.37-.89-.55-1.39-.68-.08-.02-.16-.07-.28.01-.2.14-1.5-.36-1.64-.51-.33-.39-.69-.69-1.03-1.04-.05-.05-.13-.03-.13-.19,0-.38-.13-.4-.25-.49-.16-.12-.31-.26-.41-.52-.04-.15-.05-.39-.08-.57-.03-.16.13-.42.17-.45.12-.08.12-.26.16-.39.02-.05.04-.1.05-.14.01-.02.11-.08.27-.2.17-.13.36-.24.56-.33.37-.14.76-.28.82-.26.01,0,.42.09.85.18.43.09.88.18.97.18.1,0,.16.12.23.2.03.04,1.34.25,1.38.28.23.14.53.11.66.5.02.08.08.09.13.11.43.15.8.47,1.19.73.2.14.43.2.64.31.04.02.06.08.09.11.04.04.07.1.12.12.51.21.97.57,1.48.79.12.05.54.65.68.68.08.03.17.51.26.49Z"
      />
      <path
        className="cls-1"
        d="m93.64,19.6c.13.17-.12.34-.56.51-.14.05-.57.08-.64.04-.13-.09-.27-.18-.37-.27-.06-.06-.5-1.01-.7-1.33-.18-.31-.3-.63-.37-.95l-.34-1.97c-.02-.15-.02-.29.02-.44.13-.52.08-1.02-.05-1.51-.02-.08-.01-.17-.13-.24-.24-.11-.28-1.48-.2-1.67.19-.46.29-.91.43-1.36.02-.06-.02-.13.09-.2.27-.16.23-.29.25-.43.04-.19.05-.38.18-.58.06-.1.2-.2.31-.3.1-.09.42-.07.46-.05.11.08.27,0,.4-.03.05,0,.11-.02.15-.01.03,0,.32.25.56.52.23.27.48.55.49.61,0,.01.11.41.23.82.12.41.25.85.29.93.04.09-.01.19-.04.29,0,.02.1.34.2.66.07.32.14.65.14.67-.02.27.09.52-.15.8-.05.06-.03.11-.02.16.06.45-.02.91-.07,1.36-.02.23.02.46.03.69,0,.04-.03.09-.05.13-.02.05-.05.11-.04.16.02.27.01.55,0,.82-.03.28-.05.55-.01.82,0,.13-.26.75-.23.89.01.08-.32.37-.26.44Z"
      />
    </svg>
  );
}
