import * as Yup from 'yup';

import React from 'react';
import { type SubmitHandler, useForm } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';

import Avatar from 'components/Avatar/Avatar';
import { Button } from 'components/core/Button/Button';
import Input from 'components/core/Input/Input';

import { Asset } from 'models/asset.entity';

interface CreateAssetFormModel {
  name: string;
  url: string;
  // avatar: string;
}

const schema: Yup.ObjectSchema<CreateAssetFormModel> = Yup.object().shape({
  name: Yup.string().required('Champ obligatoire').min(3, 'Le nom doit contenir au moins 3 caractères'),
  url: Yup.string()
    .required('Champ obligatoire')
    .url('URL invalide. Avez-vous pensé à préciser le protocole (https://)?'),
  // avatar: Yup.string().required('Champ obligatoire'),
});

interface CreateAssetFormProps {
  onSubmit: (asset: Asset) => void;
}

export function CreateAssetForm({ onSubmit }: CreateAssetFormProps): JSX.Element {
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitted },
  } = useForm<CreateAssetFormModel>({
    resolver: yupResolver(schema),
  });

  const onSubmitForm: SubmitHandler<CreateAssetFormModel> = (data) => {
    const asset: Asset = new Asset();
    asset.name = data.name;
    asset.url = data.url;
    asset.img = 'totoimg';
    onSubmit(asset);
  };

  // TODO : Handle register avatar
  return (
    <form className="flex flex-col gap-6" onSubmit={handleSubmit(onSubmitForm)}>
      <Avatar
        iconName={'externalLink'}
        // label="Image"
        // {...register('avatar')}
        // error={errors.avatar != null && isSubmitted ? errors.avatar.message : undefined}
        iconSize="20"
      />
      <Input
        label="Nom"
        placeholder="Saissisez le nom du document que vous voulez ajouter"
        {...register('name')}
        maxLength={28}
        error={errors.name != null && isSubmitted ? errors.name.message : undefined}
      />

      <Input
        label="URL"
        placeholder="Exemple : https://miro.com/atelier-exploration-2023-03-22"
        {...register('url')}
        error={errors.url != null && isSubmitted ? errors.url.message : undefined}
      />
      <div className="flex flex-row">
        <Button content="Ajouter" width="full" type="submit" />
      </div>
    </form>
  );
}
