import React from 'react';

import getIcon from 'utils/getIcon';

import { type ErrorProps } from './Error.props';

export function NetworkError({ message }: ErrorProps): JSX.Element {
  return (
    <div className="flex flex-col w-full h-full gap-2 justify-center items-center p-0">
      <div className="flex flex-col justify-center items-center p-3 gap-3 w-28 h-28 bg-error-200 rounded-[46px]">
        {getIcon('alertTriangle', 'red', 'lg')}
      </div>
      <span className="text-base font-medium text-error-600 text-center">{message}</span>
    </div>
  );
}
