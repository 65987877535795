import { type GetPersonaDto, PersonaeApi, type UpdatePersonaDto } from 'api';

import { type Persona } from 'forms/CreateOrEditPersonaForm/CreateOrEditPersonaForm';

export class PersonaService {
  private readonly personaeClient: PersonaeApi;

  constructor() {
    this.personaeClient = new PersonaeApi();
  }

  public async deletePersona(id: string, accessToken: string): Promise<void> {
    await this.personaeClient.personaControllerDelete(
      { id },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
      },
    );
  }

  public async updatePersona(productId: string, persona: Persona, accessToken: string): Promise<GetPersonaDto> {
    const getPersonaDto: GetPersonaDto = await this.personaeClient.personaControllerUpdate(
      {
        id: productId,
        updatePersonaDto: persona as UpdatePersonaDto,
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
      },
    );
    return getPersonaDto;
  }
}
