import { type GetBackApiSpecificationDto, type GetResourceDto } from 'api';

import { Resource } from 'models/resource.entity';
import { ResourceRevision } from 'models/resourceRevision.entity';

import { fromGetMethodDto } from './MethodFactory';

export function fromGetResourceDto(getResourceDto: GetResourceDto): Resource {
  const resource: Resource = new Resource();
  resource.id = getResourceDto.id;
  const resourceRevisions: ResourceRevision = new ResourceRevision();
  resource.resourceRevisions = [];
  resourceRevisions.name = getResourceDto.name;
  resourceRevisions.methods = getResourceDto.methods.map((method) => {
    return fromGetMethodDto(method);
  });
  resource.resourceRevisions.push(resourceRevisions);
  return resource;
}

export function fromGetApiSpecificationDto(getApiSpecificationDto: GetBackApiSpecificationDto): Resource {
  const resource: Resource = new Resource();

  resource.id = getApiSpecificationDto.id;
  const resourceRevisions: ResourceRevision = new ResourceRevision();
  resource.resourceRevisions = [];
  resourceRevisions.name = getApiSpecificationDto.name;
  resourceRevisions.methods = [];
  resource.resourceRevisions.push(resourceRevisions);

  return resource;
}
