/* tslint:disable */
/* eslint-disable */
/**
 * Specks API
 * APIs for specks SaaS
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    UpdateUiComponentButtonDto,
    instanceOfUpdateUiComponentButtonDto,
    UpdateUiComponentButtonDtoFromJSON,
    UpdateUiComponentButtonDtoFromJSONTyped,
    UpdateUiComponentButtonDtoToJSON,
} from './UpdateUiComponentButtonDto';
import {
    UpdateUiComponentContainerDto,
    instanceOfUpdateUiComponentContainerDto,
    UpdateUiComponentContainerDtoFromJSON,
    UpdateUiComponentContainerDtoFromJSONTyped,
    UpdateUiComponentContainerDtoToJSON,
} from './UpdateUiComponentContainerDto';
import {
    UpdateUiComponentDateDto,
    instanceOfUpdateUiComponentDateDto,
    UpdateUiComponentDateDtoFromJSON,
    UpdateUiComponentDateDtoFromJSONTyped,
    UpdateUiComponentDateDtoToJSON,
} from './UpdateUiComponentDateDto';
import {
    UpdateUiComponentIconDto,
    instanceOfUpdateUiComponentIconDto,
    UpdateUiComponentIconDtoFromJSON,
    UpdateUiComponentIconDtoFromJSONTyped,
    UpdateUiComponentIconDtoToJSON,
} from './UpdateUiComponentIconDto';
import {
    UpdateUiComponentInputDto,
    instanceOfUpdateUiComponentInputDto,
    UpdateUiComponentInputDtoFromJSON,
    UpdateUiComponentInputDtoFromJSONTyped,
    UpdateUiComponentInputDtoToJSON,
} from './UpdateUiComponentInputDto';
import {
    UpdateUiComponentListDto,
    instanceOfUpdateUiComponentListDto,
    UpdateUiComponentListDtoFromJSON,
    UpdateUiComponentListDtoFromJSONTyped,
    UpdateUiComponentListDtoToJSON,
} from './UpdateUiComponentListDto';
import {
    UpdateUiComponentMediaDto,
    instanceOfUpdateUiComponentMediaDto,
    UpdateUiComponentMediaDtoFromJSON,
    UpdateUiComponentMediaDtoFromJSONTyped,
    UpdateUiComponentMediaDtoToJSON,
} from './UpdateUiComponentMediaDto';
import {
    UpdateUiComponentSelectDto,
    instanceOfUpdateUiComponentSelectDto,
    UpdateUiComponentSelectDtoFromJSON,
    UpdateUiComponentSelectDtoFromJSONTyped,
    UpdateUiComponentSelectDtoToJSON,
} from './UpdateUiComponentSelectDto';
import {
    UpdateUiComponentTextDto,
    instanceOfUpdateUiComponentTextDto,
    UpdateUiComponentTextDtoFromJSON,
    UpdateUiComponentTextDtoFromJSONTyped,
    UpdateUiComponentTextDtoToJSON,
} from './UpdateUiComponentTextDto';
import {
    UpdateUiComponentTitleDto,
    instanceOfUpdateUiComponentTitleDto,
    UpdateUiComponentTitleDtoFromJSON,
    UpdateUiComponentTitleDtoFromJSONTyped,
    UpdateUiComponentTitleDtoToJSON,
} from './UpdateUiComponentTitleDto';

/**
 * @type UiComponentControllerUpdateRequest
 * 
 * @export
 */
export type UiComponentControllerUpdateRequest = { type: 'button' } & UpdateUiComponentButtonDto | { type: 'container' } & UpdateUiComponentContainerDto | { type: 'date' } & UpdateUiComponentDateDto | { type: 'icon' } & UpdateUiComponentIconDto | { type: 'input' } & UpdateUiComponentInputDto | { type: 'list' } & UpdateUiComponentListDto | { type: 'media' } & UpdateUiComponentMediaDto | { type: 'select' } & UpdateUiComponentSelectDto | { type: 'text' } & UpdateUiComponentTextDto | { type: 'title' } & UpdateUiComponentTitleDto;

export function UiComponentControllerUpdateRequestFromJSON(json: any): UiComponentControllerUpdateRequest {
    return UiComponentControllerUpdateRequestFromJSONTyped(json, false);
}

export function UiComponentControllerUpdateRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): UiComponentControllerUpdateRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    switch (json['type']) {
        case 'button':
            return {...UpdateUiComponentButtonDtoFromJSONTyped(json, true), type: 'button'};
        case 'container':
            return {...UpdateUiComponentContainerDtoFromJSONTyped(json, true), type: 'container'};
        case 'date':
            return {...UpdateUiComponentDateDtoFromJSONTyped(json, true), type: 'date'};
        case 'icon':
            return {...UpdateUiComponentIconDtoFromJSONTyped(json, true), type: 'icon'};
        case 'input':
            return {...UpdateUiComponentInputDtoFromJSONTyped(json, true), type: 'input'};
        case 'list':
            return {...UpdateUiComponentListDtoFromJSONTyped(json, true), type: 'list'};
        case 'media':
            return {...UpdateUiComponentMediaDtoFromJSONTyped(json, true), type: 'media'};
        case 'select':
            return {...UpdateUiComponentSelectDtoFromJSONTyped(json, true), type: 'select'};
        case 'text':
            return {...UpdateUiComponentTextDtoFromJSONTyped(json, true), type: 'text'};
        case 'title':
            return {...UpdateUiComponentTitleDtoFromJSONTyped(json, true), type: 'title'};
        default:
            throw new Error(`No variant of UiComponentControllerUpdateRequest exists with 'type=${json['type']}'`);
    }
}

export function UiComponentControllerUpdateRequestToJSON(value?: UiComponentControllerUpdateRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    switch (value['type']) {
        case 'button':
            return UpdateUiComponentButtonDtoToJSON(value);
        case 'container':
            return UpdateUiComponentContainerDtoToJSON(value);
        case 'date':
            return UpdateUiComponentDateDtoToJSON(value);
        case 'icon':
            return UpdateUiComponentIconDtoToJSON(value);
        case 'input':
            return UpdateUiComponentInputDtoToJSON(value);
        case 'list':
            return UpdateUiComponentListDtoToJSON(value);
        case 'media':
            return UpdateUiComponentMediaDtoToJSON(value);
        case 'select':
            return UpdateUiComponentSelectDtoToJSON(value);
        case 'text':
            return UpdateUiComponentTextDtoToJSON(value);
        case 'title':
            return UpdateUiComponentTitleDtoToJSON(value);
        default:
            throw new Error(`No variant of UiComponentControllerUpdateRequest exists with 'type=${value['type']}'`);
    }

}

