import React, { useEffect, useState } from 'react';
import uuid from 'react-uuid';

import { ApiBodyForm } from 'forms/ApiBodyForm/ApiBodyForm';

import { Settings } from 'components/core/Settings/Settings';
import { Text } from 'components/core/Text/Text';
import { type ResourceHandler, useResourceHandler } from 'components/specks/Widgets/ApiWidget';

import { type Body } from 'models/API/Body.entity';

import concatClassNames from 'utils/classNames';
import getIcon from 'utils/getIcon';

import ChevronDownIcon from 'icons/ChevronDownIcon';
import ChevronRightIcon from 'icons/ChevronRightIcon';

import { ComponentBackEndApiDetailBodyRow } from './BodyRow';
import { type RestApiHandler, useRestApiHandler } from './Detail';

export type ResponseRowBgColor = 'success' | 'error';

export const responseRowBgColorStyle: Record<ResponseRowBgColor, string> = {
  success: 'bg-green-200/20',
  error: 'bg-red-200/20',
};

type ComponentBackEndApiDetailResponsesRowProps =
  | {
      id: string;
      statusCode: number;
      message: string;
      apiResponseBody: Body[];
      createNewBody: (newBody: Body[], update?: boolean) => void;
      bodyResponsesPathIds: string[];
      setBodyResponsesPathIds: (bodyResponsesPathIds: string[]) => void;
      isCollapsed?: boolean;
      setIsCollapsed?: (isCollapsed: boolean) => void;
      isEditable?: boolean;
      title?: false;
      bgColor?: ResponseRowBgColor;
      onRowUpdate?: (id: string) => void;
    }
  | {
      id?: never;
      statusCode?: never;
      message?: never;
      apiResponseBody?: never;
      createNewBody?: never;
      bodyResponsesPathIds?: never;
      setBodyResponsesPathIds?: never;
      isCollapsed?: boolean;
      setIsCollapsed?: never;
      isEditable?: never;
      title: true;
      bgColor?: never;
      onRowUpdate?: never;
    };

// Reminder if apiResponseBody has an empty array as default, the useEffect will trigger everytime
// (see ComponentBackEndApiDetailParameters for same code but with default array)
export function ComponentBackEndApiDetailResponseRow({
  id = '',
  statusCode = 0,
  message = '',
  apiResponseBody,
  createNewBody,
  bodyResponsesPathIds,
  setBodyResponsesPathIds,
  isCollapsed = true,
  setIsCollapsed,
  isEditable = false,
  title = false,
  bgColor = 'success',
  onRowUpdate,
}: ComponentBackEndApiDetailResponsesRowProps): JSX.Element {
  /* --------------------------------------------------- contexts --------------------------------------------------- */

  const restApiHandler: RestApiHandler = useRestApiHandler();
  const resourceHandler: ResourceHandler = useResourceHandler();

  /* ---------------------------------------------------- states ---------------------------------------------------- */

  const [actualBodyResponses, setActualBodyResponses] = useState<Body[]>(apiResponseBody ?? []);

  /* ----------------------------------------------------- setup ---------------------------------------------------- */

  useEffect(() => {
    function findActualBodyResponse(body: Body[], path: string[]): Body[] | undefined {
      if (path.length === 0) return body;
      const [currentPath, ...nextPath] = path;
      const currentBody: Body | undefined = body.find((bodyResponse: Body) => bodyResponse.id === currentPath);
      if (currentBody === undefined) return undefined;
      return findActualBodyResponse(currentBody.children, nextPath);
    }

    setActualBodyResponses(findActualBodyResponse(apiResponseBody ?? [], bodyResponsesPathIds ?? []) ?? []);
  }, [bodyResponsesPathIds, apiResponseBody]);

  /* --------------------------------------------------- functions -------------------------------------------------- */

  function insertIntoBodyResponses(body: Body[], path: string[], newBodyResponses: Body): Body[] {
    if (path.length === 0) {
      return [...body, newBodyResponses];
    }
    const [currentPath, ...nextPath] = path;
    const currentBody: Body | undefined = body.find((bodyResponse: Body) => bodyResponse.id === currentPath);
    if (currentBody === undefined) {
      return body;
    }
    return body.map((bodyResponse: Body) => {
      if (bodyResponse.id === currentPath) {
        return {
          ...bodyResponse,
          children: insertIntoBodyResponses(bodyResponse.children, nextPath, newBodyResponses),
        };
      }
      return bodyResponse;
    });
  }

  function updateIntoBodyResponses(body: Body[], path: string[], newBodyResponses: Body): Body[] {
    if (path.length === 0) {
      return body.map((bodyResponse: Body) => {
        if (bodyResponse.id === newBodyResponses.id) {
          return newBodyResponses;
        }
        return bodyResponse;
      });
    }
    const [currentPath, ...nextPath] = path;
    const currentBody: Body | undefined = body.find((bodyResponse: Body) => bodyResponse.id === currentPath);
    if (currentBody === undefined) {
      return body;
    }
    return body.map((bodyResponse: Body) => {
      if (bodyResponse.id === currentPath) {
        return {
          ...bodyResponse,
          children: updateIntoBodyResponses(bodyResponse.children, nextPath, newBodyResponses),
        };
      }
      return bodyResponse;
    });
  }

  function handleOnClickConfirmNewBodyResponse(newRow: Body): void {
    const newBodyResponses: Body[] = insertIntoBodyResponses(apiResponseBody ?? [], bodyResponsesPathIds ?? [], newRow);
    resourceHandler.details.successMutateMessage = 'Le champ a bien été ajouté à la réponse.';
    resourceHandler.details.errorMutateMessage = "Il y a eu un problème lors de l'ajout du champ.";
    createNewBody?.(newBodyResponses);
  }

  function handleOnClickConfirmUpdateBodyResponse(newRow: Body): void {
    resourceHandler.details.setUpdatingBodyData(newRow);
    const newBodyResponses: Body[] = updateIntoBodyResponses(apiResponseBody ?? [], bodyResponsesPathIds ?? [], newRow);
    resourceHandler.details.successMutateMessage = 'Le champ a bien été modifié.';
    resourceHandler.details.errorMutateMessage = 'Il y a eu un problème lors de la modification du champ.';
    createNewBody?.(newBodyResponses, true);
  }

  function handleOnClickCancelNewParameter(): void {
    resourceHandler.details.closeAllFormsExcept();
  }

  function handleOnClickCancelUpdateBodyRow(): void {
    resourceHandler.details.closeAllFormsExcept();
  }

  function initNewBodyResponse(): void {
    resourceHandler.details.closeAllFormsExcept(id);
    resourceHandler.details.setNewResponseBodyIdFormOpened(id);
  }

  function handleOnClick(): void {
    setIsCollapsed?.(!isCollapsed);
  }

  function findNameofParentBodyResponse(body: Body[], path: string[], depth: number = 1): string {
    if (path.length === 0) return 'Body';
    if (path.length === depth) {
      const parentBody: Body | undefined = body.find((bodyResponse: Body) => bodyResponse.id === path[0]);
      const name: string = parentBody?.name ?? '';
      if (parentBody?.valueType === 'LIST') return `Liste de [ ${name} ]`;
      return name;
    }
    const [currentPath, ...nextPath] = path;
    const currentBody: Body | undefined = body.find((bodyResponse: Body) => bodyResponse.id === currentPath);
    if (currentBody === undefined) return '';
    return findNameofParentBodyResponse(currentBody.children, nextPath, depth);
  }

  /* -------------------------------------------- JSX get body breadcrumb ------------------------------------------- */

  function _getBodyBreadcrumb(index: number): JSX.Element {
    if (bodyResponsesPathIds === undefined) return <></>;
    const res: JSX.Element[] = [];
    if (bodyResponsesPathIds.length === index || (bodyResponsesPathIds.length > 4 && index === 4)) {
      res.push(
        <div
          key={uuid()}
          className={concatClassNames('flex flex-row items-start px-2 gap-2 cursor-pointer')}
          onClick={() => {
            setBodyResponsesPathIds([]);
          }}
        >
          <Text key={uuid()} size="api" weight="light" color="purple-500" content={'Body'} position="center" />
        </div>,
      );
      res.push(<div key={uuid()}>{getIcon('chevronRight', 'gray', 'sm')}</div>);
    }
    if (bodyResponsesPathIds.length >= index) {
      res.push(
        <div
          key={uuid()}
          className={concatClassNames('flex flex-row items-start px-2 gap-2', index === 1 ? '' : 'cursor-pointer')}
          onClick={
            index === 1
              ? undefined
              : () => {
                  setBodyResponsesPathIds(bodyResponsesPathIds.slice(0, -(index - 1)));
                }
          }
        >
          <Text
            key={uuid()}
            size="api"
            weight="light"
            color="purple-500"
            content={
              index === 4
                ? '...'
                : String(findNameofParentBodyResponse(apiResponseBody, bodyResponsesPathIds, index)) +
                  String(index === 1 ? ' :' : '')
            }
            position="center"
          />
        </div>,
      );
      if (index !== 1) res.push(<div key={uuid()}>{getIcon('chevronRight', 'gray', 'sm')}</div>);
    }
    return <React.Fragment key={uuid()}>{res}</React.Fragment>;
  }

  function getBodyBreadcrumbs(): JSX.Element {
    if (bodyResponsesPathIds === undefined) return <></>;
    const res: JSX.Element[] = [];
    if (bodyResponsesPathIds.length === 0 && actualBodyResponses.length === 0) {
      return <></>;
    }
    if (bodyResponsesPathIds.length === 0) {
      return (
        <div key={uuid()} className={concatClassNames('flex flex-row items-start px-1 mb-1 gap-2')}>
          <Text size="api" weight="light" color="purple-500" content={'Body :'} position="center" />
        </div>
      );
    }
    res.push(
      <div
        key={uuid()}
        className={concatClassNames(
          'bg-white',
          'h-fit',
          'rounded-full',
          'border-1',
          'border-gray-50',
          'cursor-pointer',
        )}
        onClick={() => {
          setBodyResponsesPathIds(bodyResponsesPathIds.slice(0, -1));
        }}
      >
        {getIcon('chevronLeft', 'gray', 'smd')}
      </div>,
    );
    res.push(_getBodyBreadcrumb(4));
    res.push(_getBodyBreadcrumb(3));
    res.push(_getBodyBreadcrumb(2));
    res.push(_getBodyBreadcrumb(1));
    return (
      <div key={uuid()} className={concatClassNames('flex flex-row', 'gap-1', 'items-center', 'mb-2')}>
        {res}
      </div>
    );
  }

  /* ------------------------------------------------------ JSX ----------------------------------------------------- */

  return (
    <div className={concatClassNames('w-full', isCollapsed ? '' : 'mb-2')}>
      {title && (
        <div className={concatClassNames('grid grid-cols-[repeat(17,minmax(0,1fr))]', 'gap-1', 'px-2')}>
          <div className="col-span-2">
            <Text content="Code" position="left" size="sm" color="gray-200" />
          </div>
          <div className="col-start-3 col-end-[18]">
            <Text content="Contexte fonctionnel" position="left" size="sm" color="gray-200" />
          </div>
        </div>
      )}
      {!title && (
        <div className={concatClassNames('bg-gray-15')}>
          <div
            className={concatClassNames(
              'flex flex-col',
              'gap-3',
              responseRowBgColorStyle[bgColor],
              'rounded-md',
              'shadow-apiRow',
              'py-4',
              'px-2',
            )}
          >
            <div
              className={concatClassNames('grid grid-cols-[repeat(17,minmax(0,1fr))]', 'gap-1')}
              onClick={handleOnClick}
            >
              <div className="col-span-2">
                <Text content={statusCode.toString()} position="left" size="sm" color="gray-400" weight="medium" />
              </div>
              <div className="col-start-3 col-end-[16]">
                <Text content={message} position="left" size="sm" color="gray-400" weight="light" />
              </div>
              {isEditable && (
                <div className={concatClassNames('flex items-center justify-center')}>
                  <Settings
                    position="left"
                    iconSize="md"
                    settingsIconName="more"
                    buttonsMenu={[
                      {
                        name: 'Modifier',
                        iconName: 'edit',
                        textColor: 'black',
                        onClick: (e) => {
                          e.stopPropagation();
                          onRowUpdate?.(id);
                        },
                      },
                      {
                        name: 'Supprimer',
                        iconName: 'trash',
                        textColor: 'red',
                        onClick: (e) => {
                          e.stopPropagation();
                          restApiHandler.deleteResponse(id);
                        },
                      },
                    ]}
                  />
                </div>
              )}
              <div className={concatClassNames('col-end-[18]')}>
                {isCollapsed ? <ChevronRightIcon color="gray" size="md" /> : <ChevronDownIcon color="gray" size="md" />}
              </div>
            </div>
            {!isCollapsed && (
              <div
                className={concatClassNames(
                  'flex flex-col',
                  'gap-1',
                  'w-full',
                  ' rounded-md py-4 px-2',
                  'shadow-shadowUp',
                )}
              >
                {apiResponseBody !== undefined &&
                apiResponseBody.length === 0 &&
                !(
                  resourceHandler.details !== undefined && resourceHandler.details.newResponseBodyIdFormOpened === id
                ) ? (
                  <div className={concatClassNames('flex flex-row', 'justify-center', 'gap-2')}>
                    <Text
                      size="api"
                      weight="light"
                      color="gray-500"
                      content="Le détail de cette réponse n'a pas été fourni."
                      position="center"
                    />
                    {isEditable && (
                      <div onClick={initNewBodyResponse} className="cursor-pointer">
                        <Text
                          size="api"
                          content="En ajouter un !"
                          color="purple-500"
                          weight="light"
                          textDecoration="underline"
                          position="center"
                        />
                      </div>
                    )}
                  </div>
                ) : (
                  <>
                    {getBodyBreadcrumbs()}
                    {actualBodyResponses.length === 0 &&
                    resourceHandler.details !== undefined &&
                    resourceHandler.details.newResponseBodyIdFormOpened !== id ? (
                      <div className={concatClassNames('flex flex-row', 'justify-center', 'gap-2')}>
                        <Text
                          size="api"
                          weight="light"
                          color="gray-200"
                          content="Cet objet ne contient pas d'attribut."
                          position="center"
                        />
                        {isEditable && (
                          <div onClick={initNewBodyResponse} className="cursor-pointer">
                            <Text
                              size="api"
                              content="En ajouter un !"
                              color="purple-500"
                              weight="light"
                              textDecoration="underline"
                              position="center"
                            />
                          </div>
                        )}
                      </div>
                    ) : (
                      <ComponentBackEndApiDetailBodyRow
                        title
                        onClickAddBodyParameter={isEditable ? initNewBodyResponse : undefined}
                      />
                    )}
                  </>
                )}
                {actualBodyResponses.length > 0 && (
                  <div className={concatClassNames('flex flex-col', 'gap-1', 'w-full')}>
                    {actualBodyResponses.map((bodyResponse: Body) => {
                      if (
                        resourceHandler.details?.responseBodyIdUpdateFormOpened !== undefined &&
                        bodyResponse.id === resourceHandler.details.responseBodyIdUpdateFormOpened
                      ) {
                        return (
                          <ApiBodyForm
                            key={uuid()}
                            onSubmit={handleOnClickConfirmUpdateBodyResponse}
                            onCancel={handleOnClickCancelUpdateBodyRow}
                            hasShadow={false}
                            existingBodyParameters={actualBodyResponses}
                            oldBodyParameter={
                              resourceHandler.details !== undefined
                                ? resourceHandler.details.updatingBodyData ?? bodyResponse
                                : bodyResponse
                            }
                          />
                        );
                      }
                      return (
                        <ComponentBackEndApiDetailBodyRow
                          key={uuid()}
                          id={bodyResponse.id}
                          path={bodyResponsesPathIds ?? []}
                          responseFunctionalId={id}
                          body={bodyResponse}
                          onClickObject={() => {
                            setBodyResponsesPathIds?.([...bodyResponsesPathIds, bodyResponse.id]);
                          }}
                          onRowUpdate={(id: string) => {
                            resourceHandler.details.closeAllFormsExcept(id);
                            resourceHandler.details.setResponseBodyIdUpdateFormOpened(id);
                          }}
                          isEditing={isEditable}
                        />
                      );
                    })}
                  </div>
                )}
                {resourceHandler.details !== undefined &&
                  resourceHandler.details.newResponseBodyIdFormOpened === id && (
                    <ApiBodyForm
                      onSubmit={handleOnClickConfirmNewBodyResponse}
                      onCancel={handleOnClickCancelNewParameter}
                      hasShadow={false}
                      existingBodyParameters={actualBodyResponses}
                    />
                  )}
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}
