/* tslint:disable */
/* eslint-disable */
/**
 * Specks API
 * APIs for specks SaaS
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { PageRevision } from './PageRevision';
import {
    PageRevisionFromJSON,
    PageRevisionFromJSONTyped,
    PageRevisionToJSON,
} from './PageRevision';
import type { UiComponent } from './UiComponent';
import {
    UiComponentFromJSON,
    UiComponentFromJSONTyped,
    UiComponentToJSON,
} from './UiComponent';

/**
 * 
 * @export
 * @interface UicPosition
 */
export interface UicPosition {
    /**
     * 
     * @type {string}
     * @memberof UicPosition
     */
    id: string;
    /**
     * 
     * @type {number}
     * @memberof UicPosition
     */
    index: number;
    /**
     * 
     * @type {UiComponent}
     * @memberof UicPosition
     */
    parent?: UiComponent;
    /**
     * 
     * @type {UiComponent}
     * @memberof UicPosition
     */
    uiComponent: UiComponent;
    /**
     * 
     * @type {PageRevision}
     * @memberof UicPosition
     */
    pageRevision: PageRevision;
}

/**
 * Check if a given object implements the UicPosition interface.
 */
export function instanceOfUicPosition(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "index" in value;
    isInstance = isInstance && "uiComponent" in value;
    isInstance = isInstance && "pageRevision" in value;

    return isInstance;
}

export function UicPositionFromJSON(json: any): UicPosition {
    return UicPositionFromJSONTyped(json, false);
}

export function UicPositionFromJSONTyped(json: any, ignoreDiscriminator: boolean): UicPosition {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'index': json['index'],
        'parent': !exists(json, 'parent') ? undefined : UiComponentFromJSON(json['parent']),
        'uiComponent': UiComponentFromJSON(json['uiComponent']),
        'pageRevision': PageRevisionFromJSON(json['pageRevision']),
    };
}

export function UicPositionToJSON(value?: UicPosition | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'index': value.index,
        'parent': UiComponentToJSON(value.parent),
        'uiComponent': UiComponentToJSON(value.uiComponent),
        'pageRevision': PageRevisionToJSON(value.pageRevision),
    };
}

