import React, { useCallback } from 'react';
import { type NavigateFunction, useNavigate } from 'react-router-dom';

import { Card } from 'components/core/Card/Card';
import { CardBody } from 'components/core/Card/CardBody';
import { CardHeader } from 'components/core/Card/CardHeader';
import { Chip } from 'components/core/Chip/Chip';
import { IconBadge } from 'components/core/IconBadge/IconBadge';
import { Text } from 'components/core/Text/Text';

import { ComponentType } from 'models/ComponentTypes.type';
import { Status } from 'models/Status.types';

import concatClassNames from 'utils/classNames';
import getIcon from 'utils/getIcon';
import getStatusChip from 'utils/getStatusChip';
import getTypeChip from 'utils/getTypeChip';

export type ComponentCardProps =
  | {
      id: string;
      name: string;
      status: Status;
      loading?: false;
      type: ComponentType;
      setRightClickedComponent?: (componentId: string) => void;
      setPoints?: ({ x, y }: { x: number; y: number }) => void;
    }
  | {
      id?: never;
      name?: never;
      status?: never;
      loading: true;
      type?: never;
      setRightClickedComponent?: never;
      setPoints?: never;
    };

export function ComponentCard({
  id = '',
  name = '',
  status = Status.Indevelopment,
  type = ComponentType.Front,
  loading = false,
  setRightClickedComponent,
  setPoints,
}: ComponentCardProps): JSX.Element {
  const navigate: NavigateFunction = useNavigate();

  /* ----------------------------------------------- Right Click Menu ----------------------------------------------- */

  const handleRightClickOnCard: (event: any) => void = useCallback((event: any) => {
    setPoints?.({ x: event.pageX, y: event.pageY });
    setRightClickedComponent?.(id);
  }, []);

  function handleClick(): void {
    if (loading) return;
    if (type === ComponentType.Database) return;
    if (id === undefined) console.error('id or component is null or undefined');
    else navigate('/component/' + id);
  }

  return (
    <Card
      shadow="md"
      onClick={handleClick}
      loading={loading}
      hasAnimation={type !== ComponentType.Database}
      cursor={type !== ComponentType.Database ? 'pointer' : 'not-allowed'}
      onRightClick={handleRightClickOnCard}
      tooltip={
        type === ComponentType.Database
          ? `Les seuls composants spécifiables sont les composants Front-end et Back-end API Rest. Cliquez droit pour plus d'options.`
          : undefined
      }
    >
      {loading ? (
        <>
          <CardHeader loading>
            <Chip loading />
          </CardHeader>
          <CardBody>
            <IconBadge loading />
            <Text loading />
            <Text loading />
          </CardBody>
        </>
      ) : (
        <>
          <CardHeader bgColor="bg-gradient-to-r from-gradient4-from to-gradient4-to">
            <div className={concatClassNames('flex flex-row gap-2 items-center')}>
              {getIcon('component', 'white', 'sm')}
              <Text content="COMPOSANT" size="xs" weight="bold" color="white" tracking="wide" position="center" />
            </div>
          </CardHeader>
          <CardBody margin="md">
            {/* {getComponentTypeImage(type)} */}

            <div className="flex flex-col gap-3 items-center">
              <div className="flex flex-col gap-1 items-center">
                <Text content={name} size="base" weight="bold" color="black" tracking="wide" position="center" />
                {getTypeChip(type, 'transparent', 'none')}
              </div>
              {getStatusChip({ name: status, textSize: 'sm' })}
            </div>
          </CardBody>
        </>
      )}
    </Card>
  );
}
