import { SpecksApisContext } from 'App';
import sampleImage from 'assets/sample-frame.png';

import React, { useContext, useEffect } from 'react';
import { useQuery } from 'react-query';

import { useAuth0 } from '@auth0/auth0-react';

import { Button } from 'components/core/Button/Button';
import { SubTitle } from 'components/core/SubTitle/SubTitle';
import { Title } from 'components/core/Title/Title';

import { type Company } from 'models/company.entity';

import concatClassNames from 'utils/classNames';

import Specks from 'icons/Specks';
import SpecksText from 'icons/SpecksText';

export function ExpiredFreeTrialPage(): JSX.Element {
  /* --------------------------------------------------- contexts --------------------------------------------------- */

  const { companyService } = useContext(SpecksApisContext);
  const { getAccessTokenSilently, user } = useAuth0();

  /* ---------------------------------------------------- Queries --------------------------------------------------- */

  async function getCompanyByUserId(): Promise<Company[]> {
    const accessToken: string = await getAccessTokenSilently();
    if (user === undefined) return [];
    return await companyService.getCompanyByUserId(accessToken);
  }

  const { data: companyData, status: companyStatus } = useQuery<Company[], Error>(
    ['company', user?.sub],
    getCompanyByUserId,
  );

  useEffect(() => {
    if (companyStatus === 'success') {
      if (companyData.length > 0 && companyData[0].companyStatus !== 'EXPIRED_TRIAL') {
        window.location.href = '/';
      }
    }
  }, [companyStatus, companyData]);

  return (
    <div className={concatClassNames('h-screen', 'w-screen', 'flex')}>
      <div
        className={concatClassNames(
          'w-44',
          'h-20',
          'm-3',
          'absolute',
          'flex',
          'flex-row',
          'justify-between',
          'items-center',
        )}
      >
        <Specks color="black" size="xxl" />
        <SpecksText color="black" size="xxxl" />
      </div>
      <div className={concatClassNames('h-full', 'w-1/2', 'flex', 'flex-row', 'justify-center', 'items-center')}>
        <div className={concatClassNames('px-24', 'w-full', 'flex', 'flex-col', 'gap-4')}>
          <Title size="h1" content="Votre version d'essai a expiré." />
          <SubTitle content="Contactez nous pour étendre votre abonnement." textSize="2xl" />
          <br />
          <br />
          <div className={concatClassNames('flex flex-row', 'justify-end', 'gap-4')}>
            <Button
              onClick={() => {
                window.location.href = 'https://calendly.com/amandine-musseau/30?month=2024-02';
              }}
              content="Je prends rendez-vous"
              width="lg"
              textSize="lg"
              bgColor="gradient1"
            />
            <Button
              onClick={() => {
                window.location.href = 'https://specks.io';
              }}
              content="Visitez notre site"
              iconName="arrowUpFromSquare"
              width="lg"
              textSize="lg"
              iconColor="white"
            />
          </div>
        </div>
      </div>
      <div
        className={concatClassNames(
          'w-1/2',
          'flex',
          'flex-row',
          'justify-end',
          'items-center',
          'bg-gradient-to-r from-gradient5-from to-gradient5-to',
        )}
      >
        <img className={concatClassNames('h-2/3', 'w-2/3', 'rounded-2xl')} src={sampleImage} alt="sample" />
      </div>
    </div>
  );
}
