import { type Body } from './API/Body.entity';
import { type ApiHeaderParameter } from './API/Parameters/apiHeaderParameter.entity';
import { type ApiPathParameter } from './API/Parameters/apiPathParameter.entity';
import { type ApiQueryParameter } from './API/Parameters/apiQueryParameter.entity';
import { type ApiResponse } from './ApiResponse.entity';

export enum MethodType {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  PATCH = 'PATCH',
  DELETE = 'DELETE',
}

export class MethodRevision {
  id: string;
  createdAt: Date;
  lastest: boolean;
  name: string;
  description: string;

  methodType: MethodType;

  apiPathParameters: ApiPathParameter[];

  apiQueryParameters: ApiQueryParameter[];

  apiHeaderParameters: ApiHeaderParameter[];

  apiBodyParameter: Body[];

  apiResponses: ApiResponse[];
}
