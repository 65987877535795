import { type UiComponentTypeEnum } from 'api';

import React, { useContext, useEffect } from 'react';

import { type UniqueIdentifier, useDroppable } from '@dnd-kit/core';
import { type PickAnimated, type SpringValues, animated, useSpring } from '@react-spring/web';

import { PageContext, type PageHandler } from 'pages/Component/FrontEnd/Page/ComponentFrontEndPagePage';

import concatClassNames from 'utils/classNames';

interface UicDroppableZoneProps {
  previousUiComponentId?: string;
  parentId?: string;
  isDroppable?: boolean;
  hasChildren?: boolean;
  isFirst?: boolean;
  isOvered?: boolean;
  isDragged?: boolean;
  isSaveable?: boolean;
}
export function UicDroppableZone({
  previousUiComponentId,
  parentId,
  isDroppable = true,
  hasChildren = true,
  isFirst = false,
  isOvered = false,
  isDragged = false,
  isSaveable = true,
}: UicDroppableZoneProps): JSX.Element {
  const { isEditable }: PageHandler = useContext(PageContext);

  const { setNodeRef, isOver: isOveruseDroppable } = useDroppable({
    id: isSaveable
      ? `${isFirst ? 'first/' : ''}droppable-zone-${previousUiComponentId ?? 'undefined'}${
          parentId !== undefined ? `/parentId-${parentId}` : ''
        }`
      : `didntmove-zone-${previousUiComponentId ?? 'undefined'}`,
    data: {
      ...(isFirst ? { isFirst: true } : {}),
    },
    resizeObserverConfig: {
      disabled: true,
    },
  });

  const lineAnimation: SpringValues<PickAnimated<object>> = useSpring({
    from: { opacity: 0.5 },
    to: async (next) => {
      while (true) {
        await next({ opacity: 1, config: { duration: 900 } });
        await next({ opacity: 0.5, config: { duration: 900 } });
      }
    },
  });

  function getWidth(): string {
    if (isFirst && !hasChildren && parentId === undefined) return 'w-full';
    if (isFirst && !hasChildren) return 'w-[126px]';
    if (isOver) return 'w-[15px]';
    return 'w-[4px]';
  }
  function getPosition(): string {
    if (isFirst && !hasChildren) return '-left-[0px]';
    if (isOver && isFirst && hasChildren) return '-left-[3px] -ml-4';
    if (isFirst && hasChildren) return '-left-[13px]';
    if (isOver) return '-right-[3px] -mr-4';
    return '-right-[13px]';
  }

  function getBgColor(): string {
    if (isOver && !isDragged) return 'bg-miscelleanous-blue';
    if (isFirst && !hasChildren && !isDroppable) return 'bg-gray-25';
    return 'bg-miscelleanous-blue/50';
  }

  function getBorder(): string {
    if (isFirst && !hasChildren && parentId === undefined) return 'border-1 border-dashed border-gray-400';
    return '';
  }

  const isOver = isOvered || isOveruseDroppable;

  if (!isEditable) {
    return <></>;
  }
  return (
    <div
      id={`${isFirst ? 'first/' : ''}droppable-zone-${previousUiComponentId ?? 'undefined'}${
        parentId !== undefined ? `/parentId-${parentId}` : ''
      }`}
      className={concatClassNames(
        // 'border-2 border-green-400',
        hasChildren ? 'absolute' : 'relative',
        getPosition(),
        getBgColor(),
        getWidth(),
        getBorder(),
        'h-[126px]',
        'rounded-md',
      )}
      style={{
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <div className={concatClassNames('hidden', 'absolute', 'text-xs text-center')}>
        <p>{String(isSaveable)}</p>
        <p>isFirst {String(isFirst)}</p>
        <p>haschil {String(hasChildren)}</p>
        <p>parentI {String(parentId)}</p>
        <p>isOver {String(isOver)}</p>
        <p>isDropp {String(isDroppable)}</p>
        <p>isDragg {String(isDragged)}</p>
      </div>
      <animated.div
        ref={isDroppable ? setNodeRef : null}
        className={concatClassNames(
          // 'border-2 border-orange-400',
          'absolute',
          '-inset-12',
          'p-12',
        )}
      >
        {!isDroppable && !isOver && parentId !== 'new' && !isDragged && (
          <p
            className={concatClassNames(
              'flex h-full justify-center items-center',
              'text-center text-branding-500',
              parentId === undefined ? 'text-lg' : 'text-xs',
            )}
          >
            Glissez-déposez un composant à cet endroit
          </p>
        )}
      </animated.div>
    </div>
  );
}
