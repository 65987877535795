/* tslint:disable */
/* eslint-disable */
/**
 * Specks API
 * APIs for specks SaaS
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  CreateExternalLinkDto,
  CreatePageDto,
  ErrorBadRequest,
  ErrorNotFound,
  GetExternalLinkDto,
  GetPageRevisionsInfoDto,
  GetPagesDto,
  GetSpecificationExternalLinksDto,
} from '../models';
import {
    CreateExternalLinkDtoFromJSON,
    CreateExternalLinkDtoToJSON,
    CreatePageDtoFromJSON,
    CreatePageDtoToJSON,
    ErrorBadRequestFromJSON,
    ErrorBadRequestToJSON,
    ErrorNotFoundFromJSON,
    ErrorNotFoundToJSON,
    GetExternalLinkDtoFromJSON,
    GetExternalLinkDtoToJSON,
    GetPageRevisionsInfoDtoFromJSON,
    GetPageRevisionsInfoDtoToJSON,
    GetPagesDtoFromJSON,
    GetPagesDtoToJSON,
    GetSpecificationExternalLinksDtoFromJSON,
    GetSpecificationExternalLinksDtoToJSON,
} from '../models';

export interface FrontSpecificationControllerCreateExternalLinkRequest {
    id: string;
    createExternalLinkDto: CreateExternalLinkDto;
}

export interface FrontSpecificationControllerCreatePageRequest {
    id: string;
    createPageDto: CreatePageDto;
}

export interface FrontSpecificationControllerFindFrontSpecificationExternalLinksByIdRequest {
    id: string;
}

export interface FrontSpecificationControllerFindFrontSpecificationPagesByIdRequest {
    id: string;
}

/**
 * 
 */
export class FrontSpecificationsApi extends runtime.BaseAPI {

    /**
     */
    async frontSpecificationControllerCreateExternalLinkRaw(requestParameters: FrontSpecificationControllerCreateExternalLinkRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetExternalLinkDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling frontSpecificationControllerCreateExternalLink.');
        }

        if (requestParameters.createExternalLinkDto === null || requestParameters.createExternalLinkDto === undefined) {
            throw new runtime.RequiredError('createExternalLinkDto','Required parameter requestParameters.createExternalLinkDto was null or undefined when calling frontSpecificationControllerCreateExternalLink.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/front-specifications/{id}/external-links`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateExternalLinkDtoToJSON(requestParameters.createExternalLinkDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetExternalLinkDtoFromJSON(jsonValue));
    }

    /**
     */
    async frontSpecificationControllerCreateExternalLink(requestParameters: FrontSpecificationControllerCreateExternalLinkRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetExternalLinkDto> {
        const response = await this.frontSpecificationControllerCreateExternalLinkRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async frontSpecificationControllerCreatePageRaw(requestParameters: FrontSpecificationControllerCreatePageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetPageRevisionsInfoDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling frontSpecificationControllerCreatePage.');
        }

        if (requestParameters.createPageDto === null || requestParameters.createPageDto === undefined) {
            throw new runtime.RequiredError('createPageDto','Required parameter requestParameters.createPageDto was null or undefined when calling frontSpecificationControllerCreatePage.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/front-specifications/{id}/pages`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreatePageDtoToJSON(requestParameters.createPageDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetPageRevisionsInfoDtoFromJSON(jsonValue));
    }

    /**
     */
    async frontSpecificationControllerCreatePage(requestParameters: FrontSpecificationControllerCreatePageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetPageRevisionsInfoDto> {
        const response = await this.frontSpecificationControllerCreatePageRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async frontSpecificationControllerFindFrontSpecificationExternalLinksByIdRaw(requestParameters: FrontSpecificationControllerFindFrontSpecificationExternalLinksByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetSpecificationExternalLinksDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling frontSpecificationControllerFindFrontSpecificationExternalLinksById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/front-specifications/{id}/external-links`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetSpecificationExternalLinksDtoFromJSON(jsonValue));
    }

    /**
     */
    async frontSpecificationControllerFindFrontSpecificationExternalLinksById(requestParameters: FrontSpecificationControllerFindFrontSpecificationExternalLinksByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetSpecificationExternalLinksDto> {
        const response = await this.frontSpecificationControllerFindFrontSpecificationExternalLinksByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async frontSpecificationControllerFindFrontSpecificationPagesByIdRaw(requestParameters: FrontSpecificationControllerFindFrontSpecificationPagesByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetPagesDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling frontSpecificationControllerFindFrontSpecificationPagesById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/front-specifications/{id}/pages`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetPagesDtoFromJSON(jsonValue));
    }

    /**
     */
    async frontSpecificationControllerFindFrontSpecificationPagesById(requestParameters: FrontSpecificationControllerFindFrontSpecificationPagesByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetPagesDto> {
        const response = await this.frontSpecificationControllerFindFrontSpecificationPagesByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
