/* tslint:disable */
/* eslint-disable */
/**
 * Specks API
 * APIs for specks SaaS
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  CreateCompanyDto,
  ErrorBadRequest,
  ErrorInternalServer,
  ErrorUnauthorizedRequest,
  GetCompaniesDto,
  GetCompanyDto,
} from '../models';
import {
    CreateCompanyDtoFromJSON,
    CreateCompanyDtoToJSON,
    ErrorBadRequestFromJSON,
    ErrorBadRequestToJSON,
    ErrorInternalServerFromJSON,
    ErrorInternalServerToJSON,
    ErrorUnauthorizedRequestFromJSON,
    ErrorUnauthorizedRequestToJSON,
    GetCompaniesDtoFromJSON,
    GetCompaniesDtoToJSON,
    GetCompanyDtoFromJSON,
    GetCompanyDtoToJSON,
} from '../models';

export interface CompanyControllerCreateRequest {
    createCompanyDto: CreateCompanyDto;
}

/**
 * 
 */
export class CompaniesApi extends runtime.BaseAPI {

    /**
     */
    async companyControllerCreateRaw(requestParameters: CompanyControllerCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetCompanyDto>> {
        if (requestParameters.createCompanyDto === null || requestParameters.createCompanyDto === undefined) {
            throw new runtime.RequiredError('createCompanyDto','Required parameter requestParameters.createCompanyDto was null or undefined when calling companyControllerCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/Companies`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateCompanyDtoToJSON(requestParameters.createCompanyDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetCompanyDtoFromJSON(jsonValue));
    }

    /**
     */
    async companyControllerCreate(requestParameters: CompanyControllerCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetCompanyDto> {
        const response = await this.companyControllerCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async companyControllerFindAllRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetCompaniesDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/Companies`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetCompaniesDtoFromJSON(jsonValue));
    }

    /**
     */
    async companyControllerFindAll(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetCompaniesDto> {
        const response = await this.companyControllerFindAllRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async companyControllerFindByUserIdRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetCompaniesDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/Companies/user`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetCompaniesDtoFromJSON(jsonValue));
    }

    /**
     */
    async companyControllerFindByUserId(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetCompaniesDto> {
        const response = await this.companyControllerFindByUserIdRaw(initOverrides);
        return await response.value();
    }

}
