import React from 'react';

import concatClassNames from 'utils/classNames';

import { type TableProps, flexTypeStyle } from './Table.props';

export function Table({ children, padding = 'px-8', flexType = 'wrap' }: TableProps): JSX.Element {
  return (
    <div className={concatClassNames('flex flex-col', flexTypeStyle[flexType], 'w-full', padding)}>{children}</div>
  );
}
