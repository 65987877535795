import React from 'react';

import concatClassNames from 'utils/classNames';

type TitleSize = 'api' | 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';

// TODO : Check why the base sizes don't work
const titleSize: Record<TitleSize, string> = {
  api: 'text-apiTitle',
  h1: 'text-4xl',
  h2: 'text-3xl',
  h3: 'text-2xl',
  h4: 'text-xl',
  h5: 'text-lg',
  h6: 'text-md',
};

type TitleMarginTop = 'none' | 'auto';

const titleMarginTop: Record<TitleMarginTop, string> = {
  none: 'mt-0',
  auto: 'mt-auto',
};

type TitleMarginRight = 'none' | 'auto';

const titleMarginRight: Record<TitleMarginRight, string> = {
  none: 'mr-0',
  auto: 'mr-auto',
};

type TitleMarginBottom = 'none' | 'auto';

const titleMarginBottom: Record<TitleMarginBottom, string> = {
  none: 'mb-0',
  auto: 'mb-auto',
};

type TitleMarginLeft = 'none' | 'auto';

const titleMarginLeft: Record<TitleMarginLeft, string> = {
  none: 'ml-0',
  auto: 'ml-auto',
};

interface TitleProps {
  content?: string;
  size?: TitleSize;
  marginTop?: TitleMarginTop;
  marginRight?: TitleMarginRight;
  marginBottom?: TitleMarginBottom;
  marginLeft?: TitleMarginLeft;
  loading?: boolean;
}

export function Title({
  content,
  size = 'h3',
  marginTop = 'none',
  marginRight = 'none',
  marginBottom = 'none',
  marginLeft = 'none',
  loading,
}: TitleProps): JSX.Element {
  if (loading === true) {
    return <div className="animate-pulse bg-gray-100 h-9 w-52 rounded-xl" />;
  }
  return (
    <h1
      className={concatClassNames(
        'font-semibold',
        titleSize[size],
        titleMarginTop[marginTop],
        titleMarginRight[marginRight],
        titleMarginBottom[marginBottom],
        titleMarginLeft[marginLeft],
      )}
    >
      {content}
    </h1>
  );
}
