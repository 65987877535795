import {
  type CreateMethodDtoMethodTypeEnum,
  type GetMethodDto,
  type GetResourceDto,
  ResourcesApi,
  type UpdateResourceDto,
} from 'api';
import { fromGetResourceDto } from 'factory/ResourceFactory';

import { type Method } from 'models/method.entity';
import { type Resource } from 'models/resource.entity';

export class ResourceService {
  private readonly ResourceClient: ResourcesApi;

  constructor() {
    this.ResourceClient = new ResourcesApi();
  }

  /* ---------------------------------------------------- Method ---------------------------------------------------- */

  public async createMethod(resourceId: string, method: Method, accessToken: string): Promise<GetMethodDto> {
    return await this.ResourceClient.resourceControllerCreateMethod(
      {
        id: resourceId,
        createMethodDto: {
          name: method.methodRevisions[0].name,
          description: method.methodRevisions[0].description,
          methodType: method.methodRevisions[0].methodType as CreateMethodDtoMethodTypeEnum,
        },
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
      },
    );
  }

  public async deleteResource(id: string, accessToken: string): Promise<void> {
    await this.ResourceClient.resourceControllerDeleteById(
      { id },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
      },
    );
  }

  public async updateResource(resourceId: string, resource: Resource, accessToken: string): Promise<Resource> {
    const updateResourceDto: UpdateResourceDto = {
      name: resource.resourceRevisions[0].name,
    };
    const getResourceDto: GetResourceDto = await this.ResourceClient.resourceControllerUpdate(
      { id: resourceId, updateResourceDto },
      { headers: { Authorization: `Bearer ${accessToken}`, 'Content-Type': 'application/json' } },
    );

    return fromGetResourceDto(getResourceDto);
  }
}
