/* tslint:disable */
/* eslint-disable */
/**
 * Specks API
 * APIs for specks SaaS
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { FeatureDto } from './FeatureDto';
import {
    FeatureDtoFromJSON,
    FeatureDtoFromJSONTyped,
    FeatureDtoToJSON,
} from './FeatureDto';

/**
 * 
 * @export
 * @interface GetProductFeaturesDto
 */
export interface GetProductFeaturesDto {
    /**
     * 
     * @type {Array<FeatureDto>}
     * @memberof GetProductFeaturesDto
     */
    features: Array<FeatureDto>;
}

/**
 * Check if a given object implements the GetProductFeaturesDto interface.
 */
export function instanceOfGetProductFeaturesDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "features" in value;

    return isInstance;
}

export function GetProductFeaturesDtoFromJSON(json: any): GetProductFeaturesDto {
    return GetProductFeaturesDtoFromJSONTyped(json, false);
}

export function GetProductFeaturesDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetProductFeaturesDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'features': ((json['features'] as Array<any>).map(FeatureDtoFromJSON)),
    };
}

export function GetProductFeaturesDtoToJSON(value?: GetProductFeaturesDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'features': ((value.features as Array<any>).map(FeatureDtoToJSON)),
    };
}

