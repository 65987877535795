/* tslint:disable */
/* eslint-disable */
/**
 * Specks API
 * APIs for specks SaaS
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CompanyIdDto } from './CompanyIdDto';
import {
    CompanyIdDtoFromJSON,
    CompanyIdDtoFromJSONTyped,
    CompanyIdDtoToJSON,
} from './CompanyIdDto';
import type { ExternalLinkDto } from './ExternalLinkDto';
import {
    ExternalLinkDtoFromJSON,
    ExternalLinkDtoFromJSONTyped,
    ExternalLinkDtoToJSON,
} from './ExternalLinkDto';
import type { GetPagesDto } from './GetPagesDto';
import {
    GetPagesDtoFromJSON,
    GetPagesDtoFromJSONTyped,
    GetPagesDtoToJSON,
} from './GetPagesDto';
import type { GetResourcesSimpleDto } from './GetResourcesSimpleDto';
import {
    GetResourcesSimpleDtoFromJSON,
    GetResourcesSimpleDtoFromJSONTyped,
    GetResourcesSimpleDtoToJSON,
} from './GetResourcesSimpleDto';
import type { SpecificationIdDto } from './SpecificationIdDto';
import {
    SpecificationIdDtoFromJSON,
    SpecificationIdDtoFromJSONTyped,
    SpecificationIdDtoToJSON,
} from './SpecificationIdDto';

/**
 * 
 * @export
 * @interface GetComponentWithPagesDto
 */
export interface GetComponentWithPagesDto {
    /**
     * 
     * @type {string}
     * @memberof GetComponentWithPagesDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof GetComponentWithPagesDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof GetComponentWithPagesDto
     */
    status: GetComponentWithPagesDtoStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof GetComponentWithPagesDto
     */
    componentType: GetComponentWithPagesDtoComponentTypeEnum;
    /**
     * 
     * @type {boolean}
     * @memberof GetComponentWithPagesDto
     */
    internal: boolean;
    /**
     * 
     * @type {Array<ExternalLinkDto>}
     * @memberof GetComponentWithPagesDto
     */
    externalLinks: Array<ExternalLinkDto>;
    /**
     * 
     * @type {SpecificationIdDto}
     * @memberof GetComponentWithPagesDto
     */
    specification: SpecificationIdDto;
    /**
     * 
     * @type {CompanyIdDto}
     * @memberof GetComponentWithPagesDto
     */
    company: CompanyIdDto;
    /**
     * 
     * @type {GetPagesDto}
     * @memberof GetComponentWithPagesDto
     */
    pages: GetPagesDto;
    /**
     * 
     * @type {GetResourcesSimpleDto}
     * @memberof GetComponentWithPagesDto
     */
    resources: GetResourcesSimpleDto;
}


/**
 * @export
 */
export const GetComponentWithPagesDtoStatusEnum = {
    Production: 'production',
    Planed: 'planed',
    InDevelopment: 'inDevelopment',
    InDecommissionning: 'inDecommissionning',
    Decommissioned: 'decommissioned',
    InExploration: 'inExploration',
    Evolving: 'evolving'
} as const;
export type GetComponentWithPagesDtoStatusEnum = typeof GetComponentWithPagesDtoStatusEnum[keyof typeof GetComponentWithPagesDtoStatusEnum];

/**
 * @export
 */
export const GetComponentWithPagesDtoComponentTypeEnum = {
    Front: 'front',
    Batch: 'batch',
    Api: 'api',
    Database: 'database'
} as const;
export type GetComponentWithPagesDtoComponentTypeEnum = typeof GetComponentWithPagesDtoComponentTypeEnum[keyof typeof GetComponentWithPagesDtoComponentTypeEnum];


/**
 * Check if a given object implements the GetComponentWithPagesDto interface.
 */
export function instanceOfGetComponentWithPagesDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "status" in value;
    isInstance = isInstance && "componentType" in value;
    isInstance = isInstance && "internal" in value;
    isInstance = isInstance && "externalLinks" in value;
    isInstance = isInstance && "specification" in value;
    isInstance = isInstance && "company" in value;
    isInstance = isInstance && "pages" in value;
    isInstance = isInstance && "resources" in value;

    return isInstance;
}

export function GetComponentWithPagesDtoFromJSON(json: any): GetComponentWithPagesDto {
    return GetComponentWithPagesDtoFromJSONTyped(json, false);
}

export function GetComponentWithPagesDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetComponentWithPagesDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'status': json['status'],
        'componentType': json['componentType'],
        'internal': json['internal'],
        'externalLinks': ((json['externalLinks'] as Array<any>).map(ExternalLinkDtoFromJSON)),
        'specification': SpecificationIdDtoFromJSON(json['specification']),
        'company': CompanyIdDtoFromJSON(json['company']),
        'pages': GetPagesDtoFromJSON(json['pages']),
        'resources': GetResourcesSimpleDtoFromJSON(json['resources']),
    };
}

export function GetComponentWithPagesDtoToJSON(value?: GetComponentWithPagesDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'status': value.status,
        'componentType': value.componentType,
        'internal': value.internal,
        'externalLinks': ((value.externalLinks as Array<any>).map(ExternalLinkDtoToJSON)),
        'specification': SpecificationIdDtoToJSON(value.specification),
        'company': CompanyIdDtoToJSON(value.company),
        'pages': GetPagesDtoToJSON(value.pages),
        'resources': GetResourcesSimpleDtoToJSON(value.resources),
    };
}

