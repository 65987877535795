/* tslint:disable */
/* eslint-disable */
/**
 * Specks API
 * APIs for specks SaaS
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { BackApiSpecification } from './BackApiSpecification';
import {
    BackApiSpecificationFromJSON,
    BackApiSpecificationFromJSONTyped,
    BackApiSpecificationToJSON,
} from './BackApiSpecification';
import type { Component } from './Component';
import {
    ComponentFromJSON,
    ComponentFromJSONTyped,
    ComponentToJSON,
} from './Component';
import type { FrontSpecification } from './FrontSpecification';
import {
    FrontSpecificationFromJSON,
    FrontSpecificationFromJSONTyped,
    FrontSpecificationToJSON,
} from './FrontSpecification';
import type { Product } from './Product';
import {
    ProductFromJSON,
    ProductFromJSONTyped,
    ProductToJSON,
} from './Product';

/**
 * 
 * @export
 * @interface ExternalLink
 */
export interface ExternalLink {
    /**
     * 
     * @type {string}
     * @memberof ExternalLink
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof ExternalLink
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof ExternalLink
     */
    url: string;
    /**
     * 
     * @type {string}
     * @memberof ExternalLink
     */
    img: string;
    /**
     * 
     * @type {Product}
     * @memberof ExternalLink
     */
    product: Product;
    /**
     * 
     * @type {Component}
     * @memberof ExternalLink
     */
    component: Component;
    /**
     * 
     * @type {FrontSpecification}
     * @memberof ExternalLink
     */
    frontSpecification: FrontSpecification;
    /**
     * 
     * @type {BackApiSpecification}
     * @memberof ExternalLink
     */
    backApiSpecification: BackApiSpecification;
}

/**
 * Check if a given object implements the ExternalLink interface.
 */
export function instanceOfExternalLink(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "url" in value;
    isInstance = isInstance && "img" in value;
    isInstance = isInstance && "product" in value;
    isInstance = isInstance && "component" in value;
    isInstance = isInstance && "frontSpecification" in value;
    isInstance = isInstance && "backApiSpecification" in value;

    return isInstance;
}

export function ExternalLinkFromJSON(json: any): ExternalLink {
    return ExternalLinkFromJSONTyped(json, false);
}

export function ExternalLinkFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExternalLink {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'url': json['url'],
        'img': json['img'],
        'product': ProductFromJSON(json['product']),
        'component': ComponentFromJSON(json['component']),
        'frontSpecification': FrontSpecificationFromJSON(json['frontSpecification']),
        'backApiSpecification': BackApiSpecificationFromJSON(json['backApiSpecification']),
    };
}

export function ExternalLinkToJSON(value?: ExternalLink | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'url': value.url,
        'img': value.img,
        'product': ProductToJSON(value.product),
        'component': ComponentToJSON(value.component),
        'frontSpecification': FrontSpecificationToJSON(value.frontSpecification),
        'backApiSpecification': BackApiSpecificationToJSON(value.backApiSpecification),
    };
}

