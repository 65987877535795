/* tslint:disable */
/* eslint-disable */
/**
 * Specks API
 * APIs for specks SaaS
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { GetResourceSimpleDto } from './GetResourceSimpleDto';
import {
    GetResourceSimpleDtoFromJSON,
    GetResourceSimpleDtoFromJSONTyped,
    GetResourceSimpleDtoToJSON,
} from './GetResourceSimpleDto';

/**
 * 
 * @export
 * @interface GetResourcesSimpleDto
 */
export interface GetResourcesSimpleDto {
    /**
     * 
     * @type {Array<GetResourceSimpleDto>}
     * @memberof GetResourcesSimpleDto
     */
    resources: Array<GetResourceSimpleDto>;
}

/**
 * Check if a given object implements the GetResourcesSimpleDto interface.
 */
export function instanceOfGetResourcesSimpleDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "resources" in value;

    return isInstance;
}

export function GetResourcesSimpleDtoFromJSON(json: any): GetResourcesSimpleDto {
    return GetResourcesSimpleDtoFromJSONTyped(json, false);
}

export function GetResourcesSimpleDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetResourcesSimpleDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'resources': ((json['resources'] as Array<any>).map(GetResourceSimpleDtoFromJSON)),
    };
}

export function GetResourcesSimpleDtoToJSON(value?: GetResourcesSimpleDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'resources': ((value.resources as Array<any>).map(GetResourceSimpleDtoToJSON)),
    };
}

