import { type Edge } from './Edge.entity';
import { type Node } from './Node.entity';

export class AppSchemaRevision {
  id: string;

  revision: string;

  nodes: Node[];

  edges: Edge[];

  createdAt: Date;
}
