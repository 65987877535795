import React from 'react';

import concatClassNames from 'utils/classNames';

import { type TableHeaderElementProps, elementPositionStyle, elementWidthStyle } from './Table.props';

export function TableHeaderElement({
  children,
  position = 'left',
  width = 'full',
}: TableHeaderElementProps): JSX.Element {
  return (
    <li
      className={concatClassNames('flex', elementWidthStyle[width], 'px-4 ', 'gap-4', elementPositionStyle[position])}
    >
      {children}
    </li>
  );
}
