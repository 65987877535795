/* tslint:disable */
/* eslint-disable */
/**
 * Specks API
 * APIs for specks SaaS
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { GetUiComponentRevisionDto } from './GetUiComponentRevisionDto';
import {
    GetUiComponentRevisionDtoFromJSON,
    GetUiComponentRevisionDtoFromJSONTyped,
    GetUiComponentRevisionDtoToJSON,
} from './GetUiComponentRevisionDto';

/**
 * 
 * @export
 * @interface GetUiComponentDto
 */
export interface GetUiComponentDto {
    /**
     * 
     * @type {string}
     * @memberof GetUiComponentDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof GetUiComponentDto
     */
    type: GetUiComponentDtoTypeEnum;
    /**
     * 
     * @type {Date}
     * @memberof GetUiComponentDto
     */
    createdAt: Date;
    /**
     * 
     * @type {Array<GetUiComponentRevisionDto>}
     * @memberof GetUiComponentDto
     */
    uiComponentRevisions: Array<GetUiComponentRevisionDto>;
}


/**
 * @export
 */
export const GetUiComponentDtoTypeEnum = {
    Title: 'title',
    Icon: 'icon',
    Text: 'text',
    Container: 'container',
    Input: 'input',
    Button: 'button',
    List: 'list',
    Media: 'media',
    Date: 'date',
    Select: 'select'
} as const;
export type GetUiComponentDtoTypeEnum = typeof GetUiComponentDtoTypeEnum[keyof typeof GetUiComponentDtoTypeEnum];


/**
 * Check if a given object implements the GetUiComponentDto interface.
 */
export function instanceOfGetUiComponentDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "createdAt" in value;
    isInstance = isInstance && "uiComponentRevisions" in value;

    return isInstance;
}

export function GetUiComponentDtoFromJSON(json: any): GetUiComponentDto {
    return GetUiComponentDtoFromJSONTyped(json, false);
}

export function GetUiComponentDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetUiComponentDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'type': json['type'],
        'createdAt': (new Date(json['createdAt'])),
        'uiComponentRevisions': ((json['uiComponentRevisions'] as Array<any>).map(GetUiComponentRevisionDtoFromJSON)),
    };
}

export function GetUiComponentDtoToJSON(value?: GetUiComponentDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'type': value.type,
        'createdAt': (value.createdAt.toISOString()),
        'uiComponentRevisions': ((value.uiComponentRevisions as Array<any>).map(GetUiComponentRevisionDtoToJSON)),
    };
}

