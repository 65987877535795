/* tslint:disable */
/* eslint-disable */
/**
 * Specks API
 * APIs for specks SaaS
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdateUiComponentTextDto
 */
export interface UpdateUiComponentTextDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateUiComponentTextDto
     */
    functionalId?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateUiComponentTextDto
     */
    type: UpdateUiComponentTextDtoTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof UpdateUiComponentTextDto
     */
    name: string;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateUiComponentTextDto
     */
    isFormValid: boolean;
    /**
     * 
     * @type {string}
     * @memberof UpdateUiComponentTextDto
     */
    notes: string;
    /**
     * 
     * @type {number}
     * @memberof UpdateUiComponentTextDto
     */
    index: number;
    /**
     * 
     * @type {string}
     * @memberof UpdateUiComponentTextDto
     */
    parentId?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateUiComponentTextDto
     */
    contentType: UpdateUiComponentTextDtoContentTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof UpdateUiComponentTextDto
     */
    sourceMethodId?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateUiComponentTextDto
     */
    sourceConstant: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateUiComponentTextDto
     */
    sourceLogicalRule: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateUiComponentTextDto
     */
    isVisible: UpdateUiComponentTextDtoIsVisibleEnum;
    /**
     * 
     * @type {string}
     * @memberof UpdateUiComponentTextDto
     */
    isVisibleLogicalRule: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateUiComponentTextDto
     */
    textImportancy: UpdateUiComponentTextDtoTextImportancyEnum;
}


/**
 * @export
 */
export const UpdateUiComponentTextDtoTypeEnum = {
    Title: 'title',
    Icon: 'icon',
    Text: 'text',
    Container: 'container',
    Input: 'input',
    Button: 'button',
    List: 'list',
    Media: 'media',
    Date: 'date',
    Select: 'select'
} as const;
export type UpdateUiComponentTextDtoTypeEnum = typeof UpdateUiComponentTextDtoTypeEnum[keyof typeof UpdateUiComponentTextDtoTypeEnum];

/**
 * @export
 */
export const UpdateUiComponentTextDtoContentTypeEnum = {
    Constant: 'CONSTANT',
    Logical: 'LOGICAL'
} as const;
export type UpdateUiComponentTextDtoContentTypeEnum = typeof UpdateUiComponentTextDtoContentTypeEnum[keyof typeof UpdateUiComponentTextDtoContentTypeEnum];

/**
 * @export
 */
export const UpdateUiComponentTextDtoIsVisibleEnum = {
    Yes: 'YES',
    Logical: 'LOGICAL'
} as const;
export type UpdateUiComponentTextDtoIsVisibleEnum = typeof UpdateUiComponentTextDtoIsVisibleEnum[keyof typeof UpdateUiComponentTextDtoIsVisibleEnum];

/**
 * @export
 */
export const UpdateUiComponentTextDtoTextImportancyEnum = {
    Undefined: 'UNDEFINED',
    VeryImportant: 'VERY_IMPORTANT',
    Important: 'IMPORTANT',
    LessImportant: 'LESS_IMPORTANT'
} as const;
export type UpdateUiComponentTextDtoTextImportancyEnum = typeof UpdateUiComponentTextDtoTextImportancyEnum[keyof typeof UpdateUiComponentTextDtoTextImportancyEnum];


/**
 * Check if a given object implements the UpdateUiComponentTextDto interface.
 */
export function instanceOfUpdateUiComponentTextDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "isFormValid" in value;
    isInstance = isInstance && "notes" in value;
    isInstance = isInstance && "index" in value;
    isInstance = isInstance && "contentType" in value;
    isInstance = isInstance && "sourceConstant" in value;
    isInstance = isInstance && "sourceLogicalRule" in value;
    isInstance = isInstance && "isVisible" in value;
    isInstance = isInstance && "isVisibleLogicalRule" in value;
    isInstance = isInstance && "textImportancy" in value;

    return isInstance;
}

export function UpdateUiComponentTextDtoFromJSON(json: any): UpdateUiComponentTextDto {
    return UpdateUiComponentTextDtoFromJSONTyped(json, false);
}

export function UpdateUiComponentTextDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateUiComponentTextDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'functionalId': !exists(json, 'functionalId') ? undefined : json['functionalId'],
        'type': json['type'],
        'name': json['name'],
        'isFormValid': json['isFormValid'],
        'notes': json['notes'],
        'index': json['index'],
        'parentId': !exists(json, 'parentId') ? undefined : json['parentId'],
        'contentType': json['contentType'],
        'sourceMethodId': !exists(json, 'sourceMethodId') ? undefined : json['sourceMethodId'],
        'sourceConstant': json['sourceConstant'],
        'sourceLogicalRule': json['sourceLogicalRule'],
        'isVisible': json['isVisible'],
        'isVisibleLogicalRule': json['isVisibleLogicalRule'],
        'textImportancy': json['textImportancy'],
    };
}

export function UpdateUiComponentTextDtoToJSON(value?: UpdateUiComponentTextDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'functionalId': value.functionalId,
        'type': value.type,
        'name': value.name,
        'isFormValid': value.isFormValid,
        'notes': value.notes,
        'index': value.index,
        'parentId': value.parentId,
        'contentType': value.contentType,
        'sourceMethodId': value.sourceMethodId,
        'sourceConstant': value.sourceConstant,
        'sourceLogicalRule': value.sourceLogicalRule,
        'isVisible': value.isVisible,
        'isVisibleLogicalRule': value.isVisibleLogicalRule,
        'textImportancy': value.textImportancy,
    };
}

