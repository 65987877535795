import * as Yup from 'yup';

import React from 'react';
import { Controller, type SubmitHandler, useForm } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';

import { Button } from 'components/core/Button/Button';
import Input from 'components/core/Input/Input';
import { ListBox } from 'components/core/ListBox/ListBox';
import { DeleteChip } from 'components/specks/Chips/Api/DeleteChip';
import { GetChip } from 'components/specks/Chips/Api/GetChip';
import { PatchChip } from 'components/specks/Chips/Api/PatchChip';
import { PostChip } from 'components/specks/Chips/Api/PostChip';
import { PutChip } from 'components/specks/Chips/Api/PutChip';

import { Method } from 'models/method.entity';
import { MethodRevision, MethodType } from 'models/methodRevision.entity';

interface CreateOrEditMethodFormModel {
  path: string;
  description: string;
  methodType: MethodType;
}

export const statusOptions: string[] = [
  MethodType.GET,
  MethodType.POST,
  MethodType.PUT,
  MethodType.PATCH,
  MethodType.DELETE,
];

export const statusStyleOptions: Map<string, JSX.Element> = new Map<string, JSX.Element>([
  [MethodType.GET, <GetChip key={MethodType.GET} isFonctional />],
  [MethodType.POST, <PostChip key={MethodType.POST} isFonctional />],
  [MethodType.PUT, <PutChip key={MethodType.PUT} isFonctional />],
  [MethodType.PATCH, <PatchChip key={MethodType.PATCH} isFonctional />],
  [MethodType.DELETE, <DeleteChip key={MethodType.DELETE} isFonctional />],
]);

const schema: Yup.ObjectSchema<CreateOrEditMethodFormModel> = Yup.object().shape({
  methodType: Yup.mixed<MethodType>().required('Champ obligatoire'),
  path: Yup.string().required('Champ obligatoire').min(3, "Le chemin d'accès doit contenir au moins 3 caractères"),
  description: Yup.string().required('Champ obligatoire').min(3, 'La description doit contenir au moins 3 caractères'),
});

interface CreateOrEditMethodFormProps {
  onSubmit: (method: Method) => void;
  isEditing?: boolean;
  isLoading?: boolean;
  method?: Method;
}

export function CreateOrEditMethodForm({
  onSubmit,
  isEditing = false,
  isLoading = false,
  method,
}: CreateOrEditMethodFormProps): JSX.Element {
  const {
    register,
    control,
    handleSubmit,
    watch,
    formState: { errors, isSubmitted },
  } = useForm<CreateOrEditMethodFormModel>({
    resolver: yupResolver(schema),
    defaultValues: {
      path: isEditing && method !== undefined ? method?.methodRevisions[0].name : '',
      methodType: isEditing && method !== undefined ? method?.methodRevisions[0].methodType : MethodType.POST,
      description: isEditing && method !== undefined ? method?.methodRevisions[0].description : '',
    },
  });

  const watchName: string = watch('path');
  const watchMethodType: MethodType = watch('methodType');
  const watchDescription: string = watch('description');

  let isFormDirty: boolean = false;
  if (
    watchName !== method?.methodRevisions[0].name ||
    watchMethodType !== method?.methodRevisions[0].methodType ||
    watchDescription !== method?.methodRevisions[0].description
  ) {
    isFormDirty = true;
  }

  let isFormValid: boolean = true;
  if (
    watchName === undefined ||
    watchName.length < 3 ||
    watchDescription === undefined ||
    watchDescription.length < 3 ||
    watchMethodType === undefined
  ) {
    isFormValid = false;
  }

  const onSubmitForm: SubmitHandler<CreateOrEditMethodFormModel> = (data) => {
    const method: Method = new Method();
    const methodRevision: MethodRevision = new MethodRevision();
    methodRevision.name = data.path;
    methodRevision.description = data.description;
    methodRevision.methodType = data.methodType;
    method.methodRevisions = [];
    method.methodRevisions.push(methodRevision);
    onSubmit(method);
  };

  return (
    <form className="flex flex-col gap-6" onSubmit={handleSubmit(onSubmitForm)}>
      <Controller
        name="methodType"
        control={control}
        defaultValue={MethodType.POST}
        render={({ field: { value, onChange } }) => (
          <ListBox
            label="Type de requête"
            selected={value}
            onChange={onChange}
            options={statusOptions}
            styleOptions={statusStyleOptions}
          />
        )}
      />
      {errors.methodType != null && isSubmitted && <p>{errors.methodType.message}</p>}
      <Input
        label="Chemin d'accès"
        placeholder="/vehicule/:id"
        {...register('path')}
        error={errors.path != null && isSubmitted ? errors.path.message : undefined}
      />
      <Input
        label="Description"
        placeholder="Ex: Récupère un véhicule par son identifiant."
        {...register('description')}
        error={errors.description != null && isSubmitted ? errors.description.message : undefined}
      />

      <div className="flex flex-row justify-end">
        <Button
          content={isEditing ? 'Modifier' : 'Créer'}
          width="1/2"
          type="submit"
          disabled={!isFormValid || !isFormDirty}
        />
      </div>
    </form>
  );
}
