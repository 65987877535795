import { type ChipBorderColor } from 'components/core/Chip/Chip';
import { type ChipBgColor } from 'components/core/Chip/Chip.props';
import { type TextColor, type TextSize } from 'components/core/Text/Text';

export enum ComponentType {
  Front = 'front',
  BackApi = 'api',
  Database = 'database',
}

export interface IComponent {
  borderColor?: ChipBorderColor;
  bgColor?: ChipBgColor;
  textSize?: TextSize;
  tooltipMessage?: string;
  textColor?: TextColor;
  label?: string;
  tooltip?: boolean;
}

export interface IAppComponentType {
  icon: string;
  color: string;
  bgColor: string;
  fullName: string;
  description: string;
}

export const componentTypes: Map<string, IAppComponentType> = new Map<string, IAppComponentType>([
  [
    'front',
    {
      icon: 'front',
      color: 'pink',
      bgColor: 'bg-pink-100',

      fullName: 'Front-end',
      description:
        'Un front-end est la partie d’un site web ou d’une application mobile qui est visible et accessible par les utilisateurs finaux.',
    },
  ],
  [
    'api',
    {
      icon: 'api',
      color: 'blue',
      bgColor: 'bg-blue-100',
      fullName: 'Back-end API REST',
      description:
        'Un back-end est la partie d’un site web ou d’une application mobile qui est invisible pour les utilisateurs finaux. Il est responsable de la gestion des données et des requêtes HTTP',
    },
  ],
  [
    'database',
    {
      icon: 'database',
      color: 'yellow',
      bgColor: 'bg-yellow-100',
      fullName: 'Base de données',
      description:
        'Une base de données est un système de stockage de données qui permet de stocker, d’organiser des données de manière structurée.',
    },
  ],
]);
