/* tslint:disable */
/* eslint-disable */
/**
 * Specks API
 * APIs for specks SaaS
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  CreateExternalLinkDto,
  CreateResourceDto,
  ErrorBadRequest,
  GetBackApiSpecificationDto,
  GetBackApiSpecificationResourceDto,
  GetExternalLinkDto,
  GetSpecificationExternalLinksDto,
} from '../models';
import {
    CreateExternalLinkDtoFromJSON,
    CreateExternalLinkDtoToJSON,
    CreateResourceDtoFromJSON,
    CreateResourceDtoToJSON,
    ErrorBadRequestFromJSON,
    ErrorBadRequestToJSON,
    GetBackApiSpecificationDtoFromJSON,
    GetBackApiSpecificationDtoToJSON,
    GetBackApiSpecificationResourceDtoFromJSON,
    GetBackApiSpecificationResourceDtoToJSON,
    GetExternalLinkDtoFromJSON,
    GetExternalLinkDtoToJSON,
    GetSpecificationExternalLinksDtoFromJSON,
    GetSpecificationExternalLinksDtoToJSON,
} from '../models';

export interface BackApiSpecificationControllerCreateExternalLinkRequest {
    id: string;
    createExternalLinkDto: CreateExternalLinkDto;
}

export interface BackApiSpecificationControllerCreateResourceRequest {
    id: string;
    createResourceDto: CreateResourceDto;
}

export interface BackApiSpecificationControllerFindBackApiSpecificationExternalLinksByIdRequest {
    id: string;
}

export interface BackApiSpecificationControllerFindResourcesByIdRequest {
    id: string;
}

/**
 * 
 */
export class BackApiSpecificationsApi extends runtime.BaseAPI {

    /**
     */
    async backApiSpecificationControllerCreateExternalLinkRaw(requestParameters: BackApiSpecificationControllerCreateExternalLinkRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetExternalLinkDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling backApiSpecificationControllerCreateExternalLink.');
        }

        if (requestParameters.createExternalLinkDto === null || requestParameters.createExternalLinkDto === undefined) {
            throw new runtime.RequiredError('createExternalLinkDto','Required parameter requestParameters.createExternalLinkDto was null or undefined when calling backApiSpecificationControllerCreateExternalLink.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/back-api-specifications/{id}/external-links`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateExternalLinkDtoToJSON(requestParameters.createExternalLinkDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetExternalLinkDtoFromJSON(jsonValue));
    }

    /**
     */
    async backApiSpecificationControllerCreateExternalLink(requestParameters: BackApiSpecificationControllerCreateExternalLinkRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetExternalLinkDto> {
        const response = await this.backApiSpecificationControllerCreateExternalLinkRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async backApiSpecificationControllerCreateResourceRaw(requestParameters: BackApiSpecificationControllerCreateResourceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetBackApiSpecificationDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling backApiSpecificationControllerCreateResource.');
        }

        if (requestParameters.createResourceDto === null || requestParameters.createResourceDto === undefined) {
            throw new runtime.RequiredError('createResourceDto','Required parameter requestParameters.createResourceDto was null or undefined when calling backApiSpecificationControllerCreateResource.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/back-api-specifications/{id}/resources`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateResourceDtoToJSON(requestParameters.createResourceDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetBackApiSpecificationDtoFromJSON(jsonValue));
    }

    /**
     */
    async backApiSpecificationControllerCreateResource(requestParameters: BackApiSpecificationControllerCreateResourceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetBackApiSpecificationDto> {
        const response = await this.backApiSpecificationControllerCreateResourceRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async backApiSpecificationControllerFindBackApiSpecificationExternalLinksByIdRaw(requestParameters: BackApiSpecificationControllerFindBackApiSpecificationExternalLinksByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetSpecificationExternalLinksDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling backApiSpecificationControllerFindBackApiSpecificationExternalLinksById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/back-api-specifications/{id}/external-links`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetSpecificationExternalLinksDtoFromJSON(jsonValue));
    }

    /**
     */
    async backApiSpecificationControllerFindBackApiSpecificationExternalLinksById(requestParameters: BackApiSpecificationControllerFindBackApiSpecificationExternalLinksByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetSpecificationExternalLinksDto> {
        const response = await this.backApiSpecificationControllerFindBackApiSpecificationExternalLinksByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async backApiSpecificationControllerFindResourcesByIdRaw(requestParameters: BackApiSpecificationControllerFindResourcesByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetBackApiSpecificationResourceDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling backApiSpecificationControllerFindResourcesById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/back-api-specifications/{id}/resources`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetBackApiSpecificationResourceDtoFromJSON(jsonValue));
    }

    /**
     */
    async backApiSpecificationControllerFindResourcesById(requestParameters: BackApiSpecificationControllerFindResourcesByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetBackApiSpecificationResourceDto> {
        const response = await this.backApiSpecificationControllerFindResourcesByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
