import { Resource } from 'models/resource.entity';

export class ResourceFront extends Resource {
  isCollapsed: boolean;

  constructor(resource: Resource, isCollapsed: boolean = true) {
    super();
    this.id = resource.id;
    this.resourceRevisions = resource.resourceRevisions;
    this.isCollapsed = isCollapsed;
  }
}
