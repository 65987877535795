/* tslint:disable */
/* eslint-disable */
/**
 * Specks API
 * APIs for specks SaaS
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { EnumDto } from './EnumDto';
import {
    EnumDtoFromJSON,
    EnumDtoFromJSONTyped,
    EnumDtoToJSON,
} from './EnumDto';

/**
 * 
 * @export
 * @interface BodyDto
 */
export interface BodyDto {
    /**
     * 
     * @type {string}
     * @memberof BodyDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof BodyDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof BodyDto
     */
    isMandatory: BodyDtoIsMandatoryEnum;
    /**
     * 
     * @type {string}
     * @memberof BodyDto
     */
    defaultValue: string;
    /**
     * 
     * @type {string}
     * @memberof BodyDto
     */
    valueType: BodyDtoValueTypeEnum;
    /**
     * 
     * @type {EnumDto}
     * @memberof BodyDto
     */
    enumDto: EnumDto;
    /**
     * 
     * @type {Array<BodyDto>}
     * @memberof BodyDto
     */
    children: Array<BodyDto>;
}


/**
 * @export
 */
export const BodyDtoIsMandatoryEnum = {
    True: 'true',
    False: 'false',
    Null: 'null'
} as const;
export type BodyDtoIsMandatoryEnum = typeof BodyDtoIsMandatoryEnum[keyof typeof BodyDtoIsMandatoryEnum];

/**
 * @export
 */
export const BodyDtoValueTypeEnum = {
    String: 'STRING',
    Number: 'NUMBER',
    Boolean: 'BOOLEAN',
    Object: 'OBJECT',
    Enum: 'ENUM',
    List: 'LIST',
    Date: 'DATE'
} as const;
export type BodyDtoValueTypeEnum = typeof BodyDtoValueTypeEnum[keyof typeof BodyDtoValueTypeEnum];


/**
 * Check if a given object implements the BodyDto interface.
 */
export function instanceOfBodyDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "isMandatory" in value;
    isInstance = isInstance && "defaultValue" in value;
    isInstance = isInstance && "valueType" in value;
    isInstance = isInstance && "enumDto" in value;
    isInstance = isInstance && "children" in value;

    return isInstance;
}

export function BodyDtoFromJSON(json: any): BodyDto {
    return BodyDtoFromJSONTyped(json, false);
}

export function BodyDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): BodyDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'isMandatory': json['isMandatory'],
        'defaultValue': json['defaultValue'],
        'valueType': json['valueType'],
        'enumDto': EnumDtoFromJSON(json['enumDto']),
        'children': ((json['children'] as Array<any>).map(BodyDtoFromJSON)),
    };
}

export function BodyDtoToJSON(value?: BodyDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'isMandatory': value.isMandatory,
        'defaultValue': value.defaultValue,
        'valueType': value.valueType,
        'enumDto': EnumDtoToJSON(value.enumDto),
        'children': ((value.children as Array<any>).map(BodyDtoToJSON)),
    };
}

