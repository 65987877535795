/* tslint:disable */
/* eslint-disable */
/**
 * Specks API
 * APIs for specks SaaS
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Faq } from './Faq';
import {
    FaqFromJSON,
    FaqFromJSONTyped,
    FaqToJSON,
} from './Faq';

/**
 * 
 * @export
 * @interface FaqEntry
 */
export interface FaqEntry {
    /**
     * 
     * @type {string}
     * @memberof FaqEntry
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof FaqEntry
     */
    question: string;
    /**
     * 
     * @type {string}
     * @memberof FaqEntry
     */
    answer: string;
    /**
     * 
     * @type {Faq}
     * @memberof FaqEntry
     */
    faq: Faq;
}

/**
 * Check if a given object implements the FaqEntry interface.
 */
export function instanceOfFaqEntry(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "question" in value;
    isInstance = isInstance && "answer" in value;
    isInstance = isInstance && "faq" in value;

    return isInstance;
}

export function FaqEntryFromJSON(json: any): FaqEntry {
    return FaqEntryFromJSONTyped(json, false);
}

export function FaqEntryFromJSONTyped(json: any, ignoreDiscriminator: boolean): FaqEntry {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'question': json['question'],
        'answer': json['answer'],
        'faq': FaqFromJSON(json['faq']),
    };
}

export function FaqEntryToJSON(value?: FaqEntry | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'question': value.question,
        'answer': value.answer,
        'faq': FaqToJSON(value.faq),
    };
}

