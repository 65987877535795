import React from 'react';
import { type NavigateFunction, useNavigate } from 'react-router-dom';
import uuid from 'react-uuid';

import ChevronRightIcon from 'icons/ChevronRightIcon';

interface BreadCrumbsPrevProps {
  name: string;
  href: string;
}

type BreadCrumbsProps =
  | {
      loading?: false;
      paths: BreadCrumbsPrevProps[];
      current: string | undefined;
    }
  | {
      loading: true;
      paths?: never;
      current?: never;
    };

export function BreadCrumbs({ loading = false, paths = [], current }: BreadCrumbsProps): JSX.Element {
  const navigate: NavigateFunction = useNavigate();
  function getList(): JSX.Element {
    if (loading) {
      const className: string = 'animate-pulse bg-gray-100 h-4 w-20 rounded my-0.5';
      return (
        <>
          <div className={className} />
          <div className={className} />
          <div className={className} />
        </>
      );
    }

    function handleOnClick(href: string): void {
      navigate(href);
    }

    return (
      <>
        {paths.map((path) => (
          <li key={uuid()}>
            <div className="flex flex-row justify-start items-center gap-3">
              <a
                onClick={() => {
                  handleOnClick(path.href);
                }}
                className="text-purple-700 text-sm cursor-pointer"
              >
                {path.name}
              </a>
              <ChevronRightIcon color="gray" size="sm" />
            </div>
          </li>
        ))}
        <span className="text-gray-700 text-sm">{current}</span>
      </>
    );
  }

  return (
    <nav>
      <ol className="flex flex-row justify-start items-center gap-3">{getList()}</ol>
    </nav>
  );
}
