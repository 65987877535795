import * as Yup from 'yup';

import React from 'react';
import { Controller, type SubmitHandler, useForm } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';

import { Button } from 'components/core/Button/Button';
import Input from 'components/core/Input/Input';
import { ListBox } from 'components/core/ListBox/ListBox';
import { DecommissionedStatusChip } from 'components/specks/Chips/status/DecommissionedStatusChip/DecommissionedStatusChip';
import { InDevelopmentStatusChip } from 'components/specks/Chips/status/InDevelopmentStatusChip/InDevelopmentStatusChip';
import { ProductionStatusChip } from 'components/specks/Chips/status/ProductionStatusChip/ProductionStatusChip';

import { Status } from 'models/Status.types';
import { Product } from 'models/product.entity';

interface CreateProductFormModel {
  name: string;
  status: Status;
}

interface CreateorEditProductFormProps {
  onSubmit: (product: Product) => void;
  isLoading: boolean;
}

export const statusOptions: string[] = [Status.Indevelopment, Status.Production, Status.Decommissioned];

export const statusStyleOptions: Map<string, JSX.Element> = new Map<string, JSX.Element>([
  [Status.Indevelopment, <InDevelopmentStatusChip key={Status.Indevelopment} />],
  [Status.Production, <ProductionStatusChip key={Status.Production} />],
  [Status.Decommissioned, <DecommissionedStatusChip key={Status.Decommissioned} />],
]);

const schema: Yup.ObjectSchema<CreateProductFormModel> = Yup.object().shape({
  name: Yup.string()
    .required('Champ obligatoire')
    .min(3, 'Le nom doit contenir au moins 3 caractères')
    .max(28, 'Le nom ne doit pas contenir plus de 28 caractères'),
  status: Yup.mixed<Status>().required('Champ obligatoire'),
});

interface CreateorEditProductFormProps {
  onSubmit: (product: Product) => void;
  isEditing?: boolean;
  product?: Product;
}

export function CreateorEditProductForm({
  onSubmit,
  isEditing = false,
  product,
  isLoading,
}: CreateorEditProductFormProps): JSX.Element {
  const {
    register,
    control,
    handleSubmit,
    watch,
    formState: { errors, isSubmitted },
  } = useForm<CreateProductFormModel>({
    resolver: yupResolver(schema),
    defaultValues: {
      name: isEditing && product !== undefined ? product?.name : '',
      status: isEditing && product !== undefined ? product?.status : Status.Indevelopment,
    },
  });

  const watchName: string = watch('name');
  const watchStatus: string = watch('status');
  let isFormValid: boolean = true;
  if (watchName === undefined || watchName.length < 3) {
    isFormValid = false;
  }

  const onSubmitForm: SubmitHandler<CreateProductFormModel> = (data) => {
    const product: Product = new Product();
    product.name = data.name;
    product.status = data.status;
    onSubmit(product);
  };

  let isFormDirty: boolean = false;
  if (watchName !== product?.name || watchStatus !== product?.status) {
    isFormDirty = true;
  }

  return (
    <form className="flex flex-col gap-6" onSubmit={handleSubmit(onSubmitForm)}>
      <Input
        placeholder={isEditing ? '' : 'Nom du produit'}
        {...register('name')}
        maxLength={28}
        error={errors.name != null && isSubmitted ? errors.name.message : undefined}
      />

      <Controller
        name="status"
        control={control}
        render={({ field: { value, onChange } }) => {
          return (
            <ListBox
              label="Statut du produit"
              selected={value}
              onChange={onChange}
              options={statusOptions}
              styleOptions={statusStyleOptions}
            />
          );
        }}
      />
      {errors.status != null && isSubmitted && <p>{errors.status.message}</p>}

      <div className="flex flex-row gap-2 justify-end">
        {!isLoading && (
          <Button
            content={isEditing ? 'Modifier' : 'Créer'}
            type="submit"
            width="1/2"
            disabled={!isFormValid || !isFormDirty}
          />
        )}
        {isLoading && <Button iconName="spinCircle" type="button" width="1/2" height="sm" iconAnimation="spin" />}
      </div>
    </form>
  );
}
