/* tslint:disable */
/* eslint-disable */
/**
 * Specks API
 * APIs for specks SaaS
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { BackApiSpecificationDto } from './BackApiSpecificationDto';
import {
    BackApiSpecificationDtoFromJSON,
    BackApiSpecificationDtoFromJSONTyped,
    BackApiSpecificationDtoToJSON,
} from './BackApiSpecificationDto';
import type { FrontSpecificationDto } from './FrontSpecificationDto';
import {
    FrontSpecificationDtoFromJSON,
    FrontSpecificationDtoFromJSONTyped,
    FrontSpecificationDtoToJSON,
} from './FrontSpecificationDto';

/**
 * 
 * @export
 * @interface GetComponentSpecificationDto
 */
export interface GetComponentSpecificationDto {
    /**
     * 
     * @type {FrontSpecificationDto}
     * @memberof GetComponentSpecificationDto
     */
    frontSpecification: FrontSpecificationDto;
    /**
     * 
     * @type {BackApiSpecificationDto}
     * @memberof GetComponentSpecificationDto
     */
    backApiSpecification: BackApiSpecificationDto;
}

/**
 * Check if a given object implements the GetComponentSpecificationDto interface.
 */
export function instanceOfGetComponentSpecificationDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "frontSpecification" in value;
    isInstance = isInstance && "backApiSpecification" in value;

    return isInstance;
}

export function GetComponentSpecificationDtoFromJSON(json: any): GetComponentSpecificationDto {
    return GetComponentSpecificationDtoFromJSONTyped(json, false);
}

export function GetComponentSpecificationDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetComponentSpecificationDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'frontSpecification': FrontSpecificationDtoFromJSON(json['frontSpecification']),
        'backApiSpecification': BackApiSpecificationDtoFromJSON(json['backApiSpecification']),
    };
}

export function GetComponentSpecificationDtoToJSON(value?: GetComponentSpecificationDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'frontSpecification': FrontSpecificationDtoToJSON(value.frontSpecification),
        'backApiSpecification': BackApiSpecificationDtoToJSON(value.backApiSpecification),
    };
}

