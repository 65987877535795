import React from 'react';
import { BaseEdge, type EdgeProps, getBezierPath } from 'reactflow';

interface IHandlePosition {
  x: number;
  y: number;
}

const handlePositions: Record<string, IHandlePosition> = {};

handlePositions.top = { x: 0, y: 12 };
handlePositions.right = { x: -12, y: 0 };
handlePositions.bottom = { x: 0, y: -12 };
handlePositions.left = { x: 12, y: 0 };

function AppSchemaEdge({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  markerEnd,
  sourceHandleId,
  targetHandleId,
}: EdgeProps): JSX.Element {
  if (
    sourceHandleId === undefined ||
    sourceHandleId == null ||
    targetHandleId === undefined ||
    targetHandleId == null
  ) {
    return <></>;
  }
  const sourceHandlePosition: IHandlePosition = handlePositions[sourceHandleId.split('-')[0]];
  const targetHandlePosition: IHandlePosition = handlePositions[targetHandleId.split('-')[0]];

  const [edgePath] = getBezierPath({
    sourceX: sourceX + sourceHandlePosition.x,
    sourceY: sourceY + sourceHandlePosition.y,
    sourcePosition,
    targetX: targetX + targetHandlePosition.x,
    targetY: targetY + targetHandlePosition.y,
    targetPosition,
  });
  return <BaseEdge id={id} markerEnd={markerEnd} path={edgePath} />;
}
export default AppSchemaEdge;
