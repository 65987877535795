/* tslint:disable */
/* eslint-disable */
/**
 * Specks API
 * APIs for specks SaaS
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { BackApiSpecification } from './BackApiSpecification';
import {
    BackApiSpecificationFromJSON,
    BackApiSpecificationFromJSONTyped,
    BackApiSpecificationToJSON,
} from './BackApiSpecification';
import type { ComponentCompany } from './ComponentCompany';
import {
    ComponentCompanyFromJSON,
    ComponentCompanyFromJSONTyped,
    ComponentCompanyToJSON,
} from './ComponentCompany';
import type { ComponentDictionary } from './ComponentDictionary';
import {
    ComponentDictionaryFromJSON,
    ComponentDictionaryFromJSONTyped,
    ComponentDictionaryToJSON,
} from './ComponentDictionary';
import type { ComponentFaq } from './ComponentFaq';
import {
    ComponentFaqFromJSON,
    ComponentFaqFromJSONTyped,
    ComponentFaqToJSON,
} from './ComponentFaq';
import type { ExternalLink } from './ExternalLink';
import {
    ExternalLinkFromJSON,
    ExternalLinkFromJSONTyped,
    ExternalLinkToJSON,
} from './ExternalLink';
import type { FrontSpecification } from './FrontSpecification';
import {
    FrontSpecificationFromJSON,
    FrontSpecificationFromJSONTyped,
    FrontSpecificationToJSON,
} from './FrontSpecification';
import type { Link } from './Link';
import {
    LinkFromJSON,
    LinkFromJSONTyped,
    LinkToJSON,
} from './Link';
import type { Node } from './Node';
import {
    NodeFromJSON,
    NodeFromJSONTyped,
    NodeToJSON,
} from './Node';

/**
 * 
 * @export
 * @interface Component
 */
export interface Component {
    /**
     * 
     * @type {string}
     * @memberof Component
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof Component
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof Component
     */
    status: ComponentStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof Component
     */
    componentType: ComponentComponentTypeEnum;
    /**
     * 
     * @type {boolean}
     * @memberof Component
     */
    internal: boolean;
    /**
     * 
     * @type {FrontSpecification}
     * @memberof Component
     */
    frontSpecification: FrontSpecification;
    /**
     * 
     * @type {BackApiSpecification}
     * @memberof Component
     */
    backApiSpecification: BackApiSpecification;
    /**
     * 
     * @type {ComponentDictionary}
     * @memberof Component
     */
    dictionary: ComponentDictionary;
    /**
     * 
     * @type {ComponentFaq}
     * @memberof Component
     */
    faq: ComponentFaq;
    /**
     * 
     * @type {Array<ExternalLink>}
     * @memberof Component
     */
    externalLinks: Array<ExternalLink>;
    /**
     * 
     * @type {Array<Node>}
     * @memberof Component
     */
    nodes: Array<Node>;
    /**
     * 
     * @type {Array<Link>}
     * @memberof Component
     */
    callerLinks: Array<Link>;
    /**
     * 
     * @type {Array<Link>}
     * @memberof Component
     */
    receiverLinks: Array<Link>;
    /**
     * 
     * @type {ComponentCompany}
     * @memberof Component
     */
    company: ComponentCompany;
}


/**
 * @export
 */
export const ComponentStatusEnum = {
    Production: 'production',
    Planed: 'planed',
    InDevelopment: 'inDevelopment',
    InDecommissionning: 'inDecommissionning',
    Decommissioned: 'decommissioned',
    InExploration: 'inExploration',
    Evolving: 'evolving'
} as const;
export type ComponentStatusEnum = typeof ComponentStatusEnum[keyof typeof ComponentStatusEnum];

/**
 * @export
 */
export const ComponentComponentTypeEnum = {
    Front: 'front',
    Batch: 'batch',
    Api: 'api',
    Database: 'database'
} as const;
export type ComponentComponentTypeEnum = typeof ComponentComponentTypeEnum[keyof typeof ComponentComponentTypeEnum];


/**
 * Check if a given object implements the Component interface.
 */
export function instanceOfComponent(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "status" in value;
    isInstance = isInstance && "componentType" in value;
    isInstance = isInstance && "internal" in value;
    isInstance = isInstance && "frontSpecification" in value;
    isInstance = isInstance && "backApiSpecification" in value;
    isInstance = isInstance && "dictionary" in value;
    isInstance = isInstance && "faq" in value;
    isInstance = isInstance && "externalLinks" in value;
    isInstance = isInstance && "nodes" in value;
    isInstance = isInstance && "callerLinks" in value;
    isInstance = isInstance && "receiverLinks" in value;
    isInstance = isInstance && "company" in value;

    return isInstance;
}

export function ComponentFromJSON(json: any): Component {
    return ComponentFromJSONTyped(json, false);
}

export function ComponentFromJSONTyped(json: any, ignoreDiscriminator: boolean): Component {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'status': json['status'],
        'componentType': json['componentType'],
        'internal': json['internal'],
        'frontSpecification': FrontSpecificationFromJSON(json['frontSpecification']),
        'backApiSpecification': BackApiSpecificationFromJSON(json['backApiSpecification']),
        'dictionary': ComponentDictionaryFromJSON(json['dictionary']),
        'faq': ComponentFaqFromJSON(json['faq']),
        'externalLinks': ((json['externalLinks'] as Array<any>).map(ExternalLinkFromJSON)),
        'nodes': ((json['nodes'] as Array<any>).map(NodeFromJSON)),
        'callerLinks': ((json['callerLinks'] as Array<any>).map(LinkFromJSON)),
        'receiverLinks': ((json['receiverLinks'] as Array<any>).map(LinkFromJSON)),
        'company': ComponentCompanyFromJSON(json['company']),
    };
}

export function ComponentToJSON(value?: Component | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'status': value.status,
        'componentType': value.componentType,
        'internal': value.internal,
        'frontSpecification': FrontSpecificationToJSON(value.frontSpecification),
        'backApiSpecification': BackApiSpecificationToJSON(value.backApiSpecification),
        'dictionary': ComponentDictionaryToJSON(value.dictionary),
        'faq': ComponentFaqToJSON(value.faq),
        'externalLinks': ((value.externalLinks as Array<any>).map(ExternalLinkToJSON)),
        'nodes': ((value.nodes as Array<any>).map(NodeToJSON)),
        'callerLinks': ((value.callerLinks as Array<any>).map(LinkToJSON)),
        'receiverLinks': ((value.receiverLinks as Array<any>).map(LinkToJSON)),
        'company': ComponentCompanyToJSON(value.company),
    };
}

