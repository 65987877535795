/* eslint-disable max-len */
import React from 'react';

import concatClassNames from 'utils/classNames';

import { type IconProps, iconColorStyle, iconSizeStyle } from './Icon.props';

export function ExternalLinkIcon({ color, size }: IconProps): JSX.Element {
  return (
    <svg
      id="Layer_3"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 128 128"
      className={concatClassNames(iconSizeStyle[size], iconColorStyle[color])}
    >
      <path
        className="cls-1"
        d="m13.76,39.76c-.02-.73,0-1.46.02-2.17,0-.26-.05-.52-.04-.78.07-.92.21-1.83.38-2.72.07-.4.06-.79.06-1.19-.06-.51,0-1.01.32-1.5.1-.15.46-.11.87.04.4.15.91.41,1.04.7.13.25.15.5.15.75,0,.25,0,.49,0,.74-.02.49-.08.98-.22,1.48-.2.6-.05,1.21-.06,1.81,0,.67-.01,1.33.01,2,.02.41.04.82.09,1.23.07.62.14,1.24.34,1.83.15.46.49.85.69,1.28.04.09.11.18.15.28.13.36,0,.54-.57.84-.13.07-.26.14-.39.2-.44.2-.88.19-1.16-.05-.39-.35-.77-.72-.97-1.17-.2-.45-.35-.92-.46-1.4-.17-.72-.24-1.46-.31-2.18h.06Z"
      />
      <path
        className="cls-1"
        d="m47.86,31.51c.46.57.89,1.17,1.25,1.91.08.15.19.47.28.74.06.29.14.57.13.85,0,.28-.04.55-.14.8-.04.13-.11.24-.17.36-.09.11-.19.16-.29.24-.24.25-.69.37-1.06.47l-.52.13-.37.07c-1.72.27-3.42.44-5.12.64-.75.09-1.5.22-2.24.35-.95.16-1.88.47-2.9.14-.31-.06-.29-.18-.07-.44.11-.14.27-.28.45-.56.19-.22.42-.37.68-.48,1.91-.39,3.84-.69,5.8-.86.59-.05,1.17-.16,1.76-.29l1.68-.41c.17-.04.26-.04.39-.08.1-.07.11-.21.1-.33.03-.15-.28-.76-.64-1.24-.44-.61-.97-1.19-1.55-1.72-.88-.8-1.84-1.56-2.88-2.13-.81-.45-1.7-.68-2.54-.86l-.54-.09c-.33-.06-.52-.14-.65-.3-.07-.08-.12-.16-.16-.26-.04-.12-.08-.25-.12-.38l-.11-.31c-.1-.22.27-.86,1-.93,2.09-.08,3.84.95,5.41,2.03,1.16.84,2.21,1.81,3.18,2.92l-.04.04Z"
      />
      <path
        className="cls-1"
        d="m31.18,26.1c2.13-.02,4.26-.03,6.41-.02.78,0,1.57-.06,2.36-.03,1.4.06,2.79.15,4.26.42l.55.1.58.17c.2.07.37.1.59.19.22.11.47.22.65.35.76.52,1.22,1.11,1.68,1.69.78,1.04,1.46,2.09,2.12,3.11.92,1.28,1.59,2.66,1.98,4.19.14.46,0,.53-.53.39-.27-.07-.62-.18-1.04-.34-.2-.08-.44-.17-.64-.29-.19-.12-.38-.27-.54-.43-.27-.3-.61-.56-.82-.89l-.63-.98c-.42-.64-.83-1.29-1.24-1.89-.42-.59-.83-1.2-1.21-1.69-.2-.24-.39-.45-.54-.6-.08-.07-.1-.09-.19-.16l-.42-.17c-.32-.16-.81-.21-1.22-.3-.45-.05-.91-.09-1.38-.12-.94-.03-1.9-.02-2.86-.03-2.11-.02-4.24,0-6.37.03l-3.95.09c-1.99.06-4,.08-5.98.2-1.54.09-3.05.33-4.59.45-.34.03-.67.05-1.01.04-1.27,0-1.67-.46-1.92-.99-.06-.13-.13-.24-.17-.35-.05-.19.05-.35.28-.55.23-.19.59-.46,1.04-.57.67-.12,1.35-.35,2.03-.41.68-.08,1.38-.15,2.07-.21,1.39-.11,2.81-.19,4.24-.26,2.13-.08,4.27-.16,6.4-.24v.07Z"
      />
      <path
        className="cls-1"
        d="m93.55,103c.95-.52,1.78-1.17,2.64-1.94l.65-.6c.22-.21.46-.43.62-.61.17-.19.36-.37.53-.58l.49-.67c.46-.68.82-1.44,1.2-2.2,1.38-2.68,2.41-5.62,3.36-8.57.42-1.31.76-2.66,1.07-3.99l.68-2.56c.26-.85.59-1.68.9-2.52.21-.52.26-.43.34.06.06.49.25,1.41.24,2.45-.07.91-.2,1.81-.32,2.72-.14.9-.37,1.78-.58,2.67-.43,1.77-.96,3.53-1.64,5.25-.82,2.06-1.48,4.27-2.55,6.34-.63,1.12-1.27,2.25-2.24,3.25-.86.86-1.75,1.79-2.84,2.54-1.32.94-2.8,1.58-4.28,2.05-2.25.73-4.55,1.09-6.81,1.42-1.76.25-3.48.51-5.22.68-.38.04-.76.09-1.15.09-1.44.01-1.91-.09-2.23-.35-.07-.06-.17-.12-.21-.18-.15-.19.42-.43,1.45-.68,1.52-.39,3.09-.64,4.66-.96,1.56-.32,3.13-.64,4.71-.96,2.3-.51,4.6-1.11,6.49-2.2l.03.05Z"
      />
      <path
        className="cls-1"
        d="m26.8,39.78c5.1-1.77,10.31-2.97,15.56-3.75,1.91-.34,3.83-.57,5.75-.8,6.79-.61,13.6-.59,20.36-.28,3,.21,6.02.27,8.99.56,1.9.21,3.82.29,5.72.61,1.89.38,3.74.95,5.58,1.49,1.15.36,1,.27-.03.35-1.02.05-2.96.57-5.17.72-1.93-.11-3.85-.23-5.77-.31-1.92-.12-3.83-.36-5.75-.43-1.92-.09-3.84-.28-5.77-.33-1.93-.07-3.86-.14-5.8-.21-4.64-.04-9.47.36-14.15,1.02-5.16.64-10.31,1.56-15.29,3.06-3.04.96-6.07,2.03-8.84,3.51l-1.54.88-1.41,1-.57.56c-.09.09-.2.18-.28.28l-.23.33c-.32.42-.56.96-.82,1.47-.93,2.14-1.5,4.51-1.89,6.86-.61,3.68-.69,7.38-.64,11.06,0,.81.01,1.61.02,2.42.02,3.03-.17,4.01-.63,4.72-.1.16-.2.37-.3.47-.17.17-.35-.05-.59-.6-.24-.54-.55-1.41-.74-2.56-.32-1.69-.49-3.42-.63-5.18-.04-1.75-.04-3.53.08-5.32.22-3.58.7-7.2,1.83-10.84.41-1.35.95-2.71,1.85-4.11l.36-.52c.13-.17.29-.31.44-.47.3-.3.61-.63.91-.89.6-.43,1.21-.92,1.82-1.29,2.44-1.56,4.98-2.54,7.53-3.55l.03.07Z"
      />
      <path
        className="cls-1"
        d="m73.69,107.01c3.32-.23,6.64-.55,9.92-1.03,3.27-.48,6.53-1.1,9.58-2.12.56-.18,1.11-.38,1.62-.61l.79-.34.73-.39c.52-.24.9-.57,1.35-.84.16-.15.3-.25.45-.42.15-.19.31-.34.45-.56,1.06-1.48,1.85-3.47,2.52-5.44.67-1.99,1.22-4.05,1.72-6.13.99-4.17,1.72-8.42,2.34-12.69.53-3.79.89-7.63,1.14-11.43.14-2.44.28-4.88.41-7.33.15-2.44.24-4.88.61-7.32.43-1.52.47-1.32,1.05-.02.31.65.58,1.58.88,2.7.25,1.12.4,2.44.39,3.87.03,5.06-.43,10.09-.96,15.12-.57,5.03-1.33,10.05-2.45,15.05-.68,2.98-1.35,6.04-2.31,9.09-.48,1.53-1.01,3.05-1.76,4.56-.39.75-.8,1.51-1.39,2.23-.15.18-.29.36-.46.54l-.56.52c-.38.31-.73.51-1.1.76-3.26,1.91-6.67,2.74-10.05,3.48-3.4.7-6.8,1.16-10.22,1.51-4.2.43-8.41.66-12.61.8-6.37.2-12.76.07-19.07-.23-4.91-.24-9.73-.44-14.6-1.09-1.07-.15-2.13-.27-3.21-.49-4.01-.79-5.2-1.64-5.71-2.72-.12-.25-.29-.51-.32-.71-.06-.35.34-.49,1.08-.49.36,0,.82.05,1.37.09.54.05,1.14.18,1.84.3,4.11.77,8.46,1.19,12.88,1.54,4.42.33,8.93.55,13.45.64,6.72.12,13.48.03,20.19-.48v.08Z"
      />
      <path
        className="cls-1"
        d="m48.74,37.84c-3.83.25-7.64.65-11.41,1.24-3.77.59-7.52,1.37-11.09,2.56-2.59.88-5.11,2.01-7.2,3.53l-.67.52-.62.55-.55.59c-.13.19-.28.34-.4.59-.51.88-.88,2.01-1.17,3.14-.58,2.29-.85,4.73-1.02,7.16-.3,4.88-.16,9.83.31,14.74.42,4.36,1.16,8.72,2.14,12.96.62,2.73,1.38,5.42,2.16,8.11l1.22,4.01.63,2c.19.67.36,1.35.54,2.03.4,1.71,0,1.63-1.06.44-1.14-1.16-2.69-3.56-3.7-6.7-1.81-5.55-3.06-11.27-3.93-17.05-.84-5.78-1.28-11.65-1.03-17.55.17-3.53.43-7.13,1.28-10.77.22-.91.49-1.82.88-2.73.18-.46.43-.91.7-1.37l.49-.68.51-.55c1.39-1.43,3.02-2.44,4.67-3.29,3.66-1.81,7.51-2.87,11.37-3.73,3.86-.84,7.76-1.42,11.67-1.83,4.81-.51,9.64-.77,14.46-.88,7.3-.15,14.64.14,21.87.79,5.61.52,11.13,1.12,16.63,2.48,1.21.31,2.4.63,3.6,1.06,4.45,1.63,5.69,3.22,5.81,4.46.03.29.08.63.04.81-.09.32-.46.13-1.14-.21-.69-.33-1.66-.94-3.1-1.57-2.13-.93-4.48-1.59-6.89-2.15-2.41-.56-4.89-.98-7.4-1.36-5.03-.73-10.2-1.19-15.38-1.46-7.71-.38-15.49-.37-23.2.2v-.06Z"
      />
      <path
        className="cls-1"
        d="m70.59,88.34c-.1,0-.15-3.86-1.21-10.01-4.42-1.7-22.13-1.16-33.8,11.53,3.18-23.7,22.98-26.56,29.32-27.28-1.25-2.66-2.78-5.1-4.67-7.08-.22-.21,17.57,6.22,26.5,13.98.11.11-3.69,7.57-16.15,18.87Z"
      />
      <path
        className="cls-1"
        d="m108.45,32.93c-.06.17-.29.09-.61-.13-.1-.07-.32-.36-.32-.42,0-.13,0-.26.02-.38.02-.06.5-.77.63-1.04.14-.25.31-.46.49-.66.37-.4.72-.83,1.07-1.25.08-.1.18-.16.3-.21.4-.17.7-.46.96-.79.04-.05.1-.1.09-.2-.01-.17.74-.99.89-1.05.38-.14.72-.31,1.07-.49.05-.02.07-.08.18-.05.24.07.3-.03.39-.1.13-.09.3-.15.47-.21.09-.03.22-.01.33-.02.1,0,.24.17.25.21,0,.1.13.13.21.19.03.02.06.05.08.07.02.02-.01.3-.02.62,0,.33-.08.61-.1.66,0,.02-.18.33-.37.64-.19.33-.4.65-.44.72-.04.07-.14.1-.22.13-.03.02-.68.92-.72.95-.18.14-.29.36-.59.37-.06,0-.09.04-.12.08-.26.29-.6.51-.95.7-.17.11-.32.25-.47.37-.03.02-.08.02-.11.04-.05.02-.1.02-.13.05-.36.3-.8.51-1.14.84-.08.08-.62.23-.7.32-.05.05-.4-.02-.42.05Z"
      />
      <path
        className="cls-1"
        d="m100.46,28.8c.14.17-.06.37-.43.57-.12.07-.5.13-.57.09-.13-.08-.26-.16-.36-.24-.05-.05-.55-.94-.78-1.22-.2-.28-.35-.57-.48-.87-.26-.61-.59-1.19-.83-1.81-.06-.14-.08-.28-.09-.43-.01-.53-.19-1-.43-1.46-.04-.08-.06-.16-.18-.2-.19-.07-.78-1.33-.74-1.54.04-.49,0-.95,0-1.43,0-.07-.06-.12.03-.22.21-.22.14-.35.13-.49-.03-.19.02-.42.07-.65.03-.11.14-.25.21-.38.07-.12.36-.17.41-.16.13.05.24-.07.36-.12.04-.02.09-.04.13-.05.04,0,.34.18.72.35.34.19.66.4.67.46,0,.01.21.38.42.76.21.38.44.79.5.86.06.08.02.2.01.31,0,.05.59,1.23.6,1.29.05.27.22.51.07.84-.03.07,0,.12.02.17.16.44.2.92.27,1.4.04.24.12.47.16.71,0,.04-.01.1-.02.14,0,.06-.02.12-.01.17.13.56.14,1.15.31,1.7.04.13-.07.81-.02.95.02.08-.21.42-.16.49Z"
      />
    </svg>
  );
}
