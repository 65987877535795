import { SpecksApisContext } from 'App';
import { type GetFaqEntryDto } from 'api';

import React, { useContext, useState } from 'react';
import { type QueryClient, useMutation, useQuery, useQueryClient } from 'react-query';
import { type NavigateFunction, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import uuid from 'react-uuid';

import { useAuth0 } from '@auth0/auth0-react';

import { BasePage } from 'pages/core/BasePage/BasePage';

import { CreateDictionaryEntryForm } from 'forms/CreateDictionaryEntryForm/CreateDictionaryEntryForm';
import { CreateFaqEntryForm } from 'forms/CreateFaqEntryForm/CreateFaqEntryForm';

import { Button } from 'components/core/Button/Button';
import { type BreadCrumbsProps, Header } from 'components/core/Header/Header';
import { Modal } from 'components/core/Modal/Modal';
import { Section } from 'components/core/Section/Section';
import { Settings } from 'components/core/Settings/Settings';
import { Table } from 'components/core/Table/Table';
import { TableBody } from 'components/core/Table/TableBody';
import { TableData } from 'components/core/Table/TableData';
import { TableHeader } from 'components/core/Table/TableHeader';
import { TableHeaderElement } from 'components/core/Table/TableHeaderElement';
import { TableRow } from 'components/core/Table/TableRow';
import { TabElement } from 'components/core/Tabs/TabElement';
import { TabList } from 'components/core/Tabs/TabList';
import { TabPanel } from 'components/core/Tabs/TabPanel';
import { TabPanels } from 'components/core/Tabs/TabPanels';
import { Tabs } from 'components/core/Tabs/Tabs';
import { Text } from 'components/core/Text/Text';
import { Title } from 'components/core/Title/Title';
import { Empty } from 'components/specks/Empty/Empty';
import { NetworkError } from 'components/specks/Error/Error';
import { FaqCollapse } from 'components/specks/FaqCollapse/FaqCollapse';
import { UserAvatar } from 'components/specks/UserAvatar/UserAvatar';

import { type FaqService } from 'services/faq.service';

import { Dictionary } from 'models/dictionary.entity';
import { type DictionaryEntry } from 'models/dictionaryEntry.entity';
import { Faq } from 'models/faq.entity';
import { FaqEntry } from 'models/faqEntry.entity';
import { type Product } from 'models/product.entity';

import concatClassNames from 'utils/classNames';
import { tooltipDescriptionsStyle } from 'utils/tooltipsDescriptions';

interface ProductDictionaryFaqPageParams {
  id?: string;
}

interface IDictionaryEntry {
  productId: string;
  dictionaryEntry: DictionaryEntry;
}

export function ProductDictionaryFaqPage(): JSX.Element {
  /* --------------------------------------------------- contexts --------------------------------------------------- */

  const { productService, dictionaryService } = useContext(SpecksApisContext);
  const faqService: FaqService = useContext(SpecksApisContext).faqService;
  const { id = '' }: ProductDictionaryFaqPageParams = useParams();
  const navigate: NavigateFunction = useNavigate();
  const queryClient: QueryClient = useQueryClient();
  const { getAccessTokenSilently, user } = useAuth0();

  /* ---------------------------------------------------- states ---------------------------------------------------- */

  const [isDeleteFaqEntryModalOpened, setIsDeleteFaqEntryModalOpened] = useState<boolean>(false);
  const [isDeletedictionaryEntryModalOpened, setIsDeletedictionaryEntryModalOpened] = useState<boolean>(false);
  const [selectedIndexTab, setSelectedIndexTab] = useState(0);
  const [isDictionaryModalOpened, setIsDictionaryModalOpened] = useState<boolean>(false);
  const [isFaqModalOpened, setIsFaqModalOpened] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedFaqEntryId, setSelectedFaqEntryId] = useState<string>('');
  const [selectedDictionaryEntryId, setSelectedDictionaryEntryId] = useState<string>('');

  /* ---------------------------------------------------- Queries --------------------------------------------------- */

  const { data: productData } = useQuery<Product, Error>({
    queryKey: ['product'],
    queryFn: async () => {
      const accessToken: string = await getAccessTokenSilently();
      return await productService.findById(id, accessToken);
    },
  });

  /*
    Dictionary Fetching
  */

  const dictionaryId: string | undefined = productData?.dictionary?.id;
  const { data: dictionaryData, status: dictionaryStatus } = useQuery<Dictionary, Error>({
    queryKey: ['dictionary', dictionaryId, id],
    queryFn: async ({ queryKey }) => {
      const [, dictionaryId] = queryKey;
      const accessToken: string = await getAccessTokenSilently();
      return await dictionaryService.findById(dictionaryId as string, accessToken);
    },
    enabled: dictionaryId !== undefined,
  });

  /*
    Faq Fetching
  */

  const faqId: string | undefined = productData?.faq?.id;
  const { data: faqData, status: faqStatus } = useQuery<Faq, Error>({
    queryKey: ['faq', faqId, id],
    queryFn: async ({ queryKey }) => {
      const [, faqId] = queryKey;
      const accessToken: string = await getAccessTokenSilently();
      return await faqService.findById(faqId as string, accessToken);
    },
    enabled: faqId !== undefined,
  });

  /* ----------------------------------------------- Create Faq Entry ----------------------------------------------- */

  async function createFaqEntry(faqEntry: FaqEntry): Promise<GetFaqEntryDto> {
    if (faqStatus !== 'success') {
      return new FaqEntry() as GetFaqEntryDto;
    }
    const accessToken: string = await getAccessTokenSilently();
    return await faqService.createEntry(faqData.id, faqEntry, accessToken);
  }

  const { mutate: mutateFaq } = useMutation(createFaqEntry, {
    onSuccess: (getFaqEntry: GetFaqEntryDto) => {
      queryClient.setQueryData<Faq>(['faq', faqId, id], (oldData: Faq | undefined) => {
        if (oldData === undefined) {
          return new Faq();
        }
        oldData.entries.push(getFaqEntry);
        return oldData;
      });
      setIsFaqModalOpened(false);
      setIsLoading(false);
      toast.success('Votre entrée a bien été créée.');
    },
    onError: () => {
      toast.error('Il y a eu un problème au moment de la création de cette entrée.');
    },
  });

  async function handleFaqEntryFormSubmission(faqEntry: FaqEntry): Promise<void> {
    setIsLoading(true);
    try {
      mutateFaq(faqEntry);
    } catch (error) {
      console.error('there was an error');
    }
  }

  /* -------------------------------------------- Create Dictionary Entry ------------------------------------------- */

  async function createDictionaryEntry({ productId, dictionaryEntry }: IDictionaryEntry): Promise<DictionaryEntry> {
    const accessToken: string = await getAccessTokenSilently();

    return await dictionaryService.createEntry(productId, dictionaryEntry, accessToken);
  }

  const { mutate: mutateDictionary } = useMutation(createDictionaryEntry, {
    onSuccess: (getDictionaryEntry: DictionaryEntry) => {
      queryClient.setQueryData<Dictionary>(['dictionary', dictionaryId, id], (oldData: Dictionary | undefined) => {
        if (oldData === undefined) {
          return new Dictionary();
        }
        oldData.entries.push(getDictionaryEntry);
        return oldData;
      });
      setIsDictionaryModalOpened(false);
      setIsLoading(false);
      toast.success('La nouvelle entrée a bien été ajoutée.');
    },
    onError: () => {
      toast.error('Il y a eu un problème au moment de la création de cette entrée.');
    },
  });

  async function handleDictionaryEntryFormSubmission(dictionaryEntry: DictionaryEntry): Promise<void> {
    setIsLoading(true);
    if (dictionaryStatus === 'success') {
      mutateDictionary({
        productId: dictionaryData.id,
        dictionaryEntry,
      });
    }
  }

  /* --------------------------------------------------- functions -------------------------------------------------- */

  function handleClickAddDictionary(): void {
    setIsDictionaryModalOpened(true);
  }

  function handleClickAddFaq(): void {
    setIsFaqModalOpened(true);
  }

  function handleClickBack(): void {
    navigate('/product/' + id);
  }

  function handleClickTab(index: number): void {
    setSelectedIndexTab(index);
  }

  function handleOnSubmitFaqEntryDeletion(event: any): void {
    event.preventDefault();
    setIsLoading(true);
    setTimeout(() => {
      handleQuestionDeletionConfirmation();
    }, 1);
  }

  function handledictionaryEntryDeletionClick(entryId: string): void {
    setSelectedDictionaryEntryId(entryId);
    setIsDeletedictionaryEntryModalOpened(true);
  }

  function handleFaqEntryDeletionClick(entryId: string): void {
    setSelectedFaqEntryId(entryId);
    setIsDeleteFaqEntryModalOpened(true);
  }

  /* ---------------------------------------- Delete dictionary Entry ------------------------------------ */

  async function deleteDictionaryEntry(): Promise<void> {
    const accessToken: string = await getAccessTokenSilently();
    await dictionaryService.deleteEntry(selectedDictionaryEntryId, accessToken);
  }

  const { mutate: mutateDeleteDictionaryEntry } = useMutation(deleteDictionaryEntry, {
    onSuccess: () => {
      queryClient.setQueryData<Dictionary>(['dictionary', dictionaryId, id], (oldData: Dictionary | undefined) => {
        if (oldData !== undefined) {
          oldData.entries = oldData.entries.filter((entry) => entry.id !== selectedDictionaryEntryId);
          return oldData;
        } else {
          return new Dictionary();
        }
      });
      setSelectedIndexTab(0);
      toast.success('La définition a bien été supprimée');
      setIsLoading(false);
    },
    onError: () => {
      toast.error('Erreur lors de la suppression de la définition');
      setIsLoading(false);
    },
  });

  function handledictionaryEntryDeletionConfirmation(): void {
    setIsDeletedictionaryEntryModalOpened(false);
    try {
      mutateDeleteDictionaryEntry();
    } catch (error) {
      console.error(error);
    }
  }

  /* ------------------------------------------------- Delete Faq Entry ------------------------------------ */

  async function deleteQuestion(): Promise<void> {
    const accessToken: string = await getAccessTokenSilently();
    await faqService.deleteEntry(selectedFaqEntryId, accessToken);
  }

  const { mutate: mutateDeleteQuestion } = useMutation(deleteQuestion, {
    onSuccess: () => {
      queryClient.setQueryData<Faq>(['faq', faqId, id], (oldData: Faq | undefined) => {
        if (oldData !== undefined) {
          oldData.entries = oldData.entries.filter((entry) => entry.id !== selectedFaqEntryId);
          return oldData;
        } else {
          return new Faq();
        }
      });
      setSelectedIndexTab(1);
      toast.success('La question a bien été supprimée');
      setIsLoading(false);
    },
    onError: () => {
      toast.error('Erreur lors de la suppression de la question');
      setIsLoading(false);
    },
  });

  function handleQuestionDeletionConfirmation(): void {
    setIsDeleteFaqEntryModalOpened(false);
    try {
      mutateDeleteQuestion();
    } catch (error) {
      console.error(error);
    }
  }

  /* ------------------------------------------------------ JSX ----------------------------------------------------- */

  const breadCrumbs: BreadCrumbsProps = {
    paths: [
      {
        name: 'Produits',
        href: '/products',
      },
      {
        name: productData?.name ?? '',
        href: `/product/${productData?.id ?? ''}`,
      },
    ],
    current: 'Lexique & Q/R',
  };

  return (
    <BasePage>
      <Modal title="Ajouter une définition" isOpen={isDictionaryModalOpened} setIsOpen={setIsDictionaryModalOpened}>
        <CreateDictionaryEntryForm onSubmit={handleDictionaryEntryFormSubmission} isLoading={isLoading} />
      </Modal>
      <Modal title="Ajouter une question" isOpen={isFaqModalOpened} setIsOpen={setIsFaqModalOpened}>
        <CreateFaqEntryForm onSubmit={handleFaqEntryFormSubmission} isLoading={isLoading} />
      </Modal>
      <Modal
        title="Suppression d'une question"
        isOpen={isDeleteFaqEntryModalOpened}
        setIsOpen={setIsDeleteFaqEntryModalOpened}
      >
        <>
          <Text
            position="justify"
            content="Vous allez supprimer définitivement cette question. Êtes-vous certain de vouloir poursuivre ?"
          />
          <div className="flex flex-row gap-2 justify-end">
            {!isLoading && (
              <Button
                onClick={handleOnSubmitFaqEntryDeletion}
                content="Supprimer"
                width="1/2"
                type="submit"
                height="sm"
              />
            )}
            {isLoading && <Button iconName="spinCircle" width="1/2" height="sm" iconAnimation="spin" />}
          </div>
        </>
      </Modal>
      <Modal
        title="Suppression d'une entrée du lexique"
        isOpen={isDeletedictionaryEntryModalOpened}
        setIsOpen={setIsDeletedictionaryEntryModalOpened}
      >
        <>
          <Text
            position="justify"
            content="Vous allez supprimer définitivement cette entrée. Êtes-vous certain de vouloir poursuivre ?"
          />
          <div className="flex flex-row gap-2 justify-end">
            {!isLoading && (
              <Button
                onClick={handledictionaryEntryDeletionConfirmation}
                content="Supprimer"
                width="1/2"
                type="submit"
                height="sm"
              />
            )}
            {isLoading && <Button iconName="spinCircle" width="1/2" height="sm" iconAnimation="spin" />}
          </div>
        </>
      </Modal>
      <Header
        breadCrumbs={breadCrumbs}
        status={productData?.status}
        handleClickBack={handleClickBack}
        title={productData?.name}
        thumbnail={true}
        thumbnailIcon={'product'}
        thumbnailTooltipMessage={tooltipDescriptionsStyle.product}
      />
      <Section isFullHeight>
        {/* <Title content="Lexique & Q/R" /> */}
        <></>
        <Tabs onChange={handleClickTab}>
          <TabList
            jsxElement={
              <Button
                content={selectedIndexTab === 0 ? 'Ajouter une définition' : 'Ajouter une question'}
                width="fit"
                height="sm"
                whitespace="pre"
                onClick={selectedIndexTab === 0 ? handleClickAddDictionary : handleClickAddFaq}
                iconName="plus"
              />
            }
          >
            <TabElement title={'Lexique'} textSize="lg"></TabElement>
            <TabElement title={'Questions/Réponses'} textSize="lg"></TabElement>
          </TabList>
          <TabPanels>
            <TabPanel>
              {dictionaryStatus === 'error' && (
                <NetworkError message="Un problème est survenu lors de l'affichage des définitions." />
              )}
              {dictionaryStatus === 'success' && dictionaryData?.entries.length === 0 && (
                <Empty
                  icon="books"
                  title="Pas de définition existante"
                  subtitle="Ce produit n'a pas encore de définition. Commencez !"
                >
                  <Button
                    content="Ajouter une définition"
                    width="fit"
                    height="sm"
                    onClick={handleClickAddDictionary}
                    iconName="plus"
                  />
                </Empty>
              )}
              {dictionaryData?.entries.length !== 0 && (
                <>
                  {(dictionaryStatus === 'loading' || dictionaryStatus === 'success') && (
                    <Table flexType="grow">
                      <>
                        {dictionaryStatus === 'success' && dictionaryData?.entries.length !== 0 && (
                          <TableHeader>
                            <TableHeaderElement width="sm">
                              <Text content="MOTS-CLEFS" size="sm" weight="bold" color="black" position="center" />
                            </TableHeaderElement>
                            <TableHeaderElement>
                              <Text content="DÉFINITION" size="sm" weight="bold" color="black" position="center" />
                            </TableHeaderElement>
                          </TableHeader>
                        )}
                      </>
                      <TableBody flexType="grow">
                        <>
                          {dictionaryStatus === 'loading' && (
                            <>
                              <TableRow>
                                <TableData width="md">
                                  <Text loading />
                                </TableData>
                                <TableData>
                                  <Text loading />
                                </TableData>
                                <TableData width="md"></TableData>
                              </TableRow>
                              <TableRow>
                                <TableData width="md">
                                  <Text loading />
                                </TableData>
                                <TableData>
                                  <Text loading />
                                </TableData>
                                <TableData width="md"></TableData>
                              </TableRow>
                            </>
                          )}
                          {dictionaryStatus === 'success' &&
                            dictionaryData?.entries.map((item) => {
                              return (
                                <div key={uuid()} className="flex flex-row items-center">
                                  <TableRow>
                                    <TableData width="sm">
                                      <Text content={item.name} size="sm" weight="bold" color="black" position="left" />
                                    </TableData>
                                    <TableData>
                                      <Text
                                        content={item.definition}
                                        size="sm"
                                        weight="medium"
                                        color="gray-400"
                                        position="left"
                                      />
                                    </TableData>
                                    <Settings
                                      position="left"
                                      settingsIconName="more"
                                      buttonsMenu={[
                                        {
                                          name: 'Modifier',
                                          iconName: 'edit',
                                          textColor: 'black',
                                          isEnabled: false,
                                          onClick: () => {
                                            console.log('Modifier');
                                          },
                                        },
                                        {
                                          name: 'Supprimer',
                                          iconName: 'trash',
                                          textColor: 'red',
                                          onClick: () => {
                                            handledictionaryEntryDeletionClick(item.id);
                                          },
                                        },
                                      ]}
                                    />
                                  </TableRow>
                                </div>
                              );
                            })}
                        </>
                      </TableBody>
                    </Table>
                  )}
                </>
              )}
            </TabPanel>
            <TabPanel>
              {faqStatus === 'error' && (
                <NetworkError message="Un problème est survenu lors de l'affichage des Questions/Réponses." />
              )}
              {faqStatus === 'success' && faqData?.entries.length === 0 && (
                <Empty
                  icon="books"
                  title="Pas de question existante"
                  subtitle="Ce produit n'a pas encore de question. Commencez !"
                >
                  <Button
                    content="Ajouter une question"
                    width="fit"
                    height="sm"
                    onClick={handleClickAddFaq}
                    iconName="plus"
                  />
                </Empty>
              )}
              <div className={concatClassNames('flex flex-col', 'gap-4', 'px-8')}>
                {faqStatus === 'loading' && (
                  <>
                    <FaqCollapse loading />
                    <FaqCollapse loading />
                  </>
                )}
                {faqStatus === 'success' &&
                  faqData.entries.map((entry) => {
                    return (
                      <FaqCollapse
                        key={uuid()}
                        id={entry.id}
                        onDeletionClick={handleFaqEntryDeletionClick}
                        title={entry.question}
                        content={entry.answer}
                      />
                    );
                  })}
              </div>
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Section>
    </BasePage>
  );
}
