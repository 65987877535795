/* tslint:disable */
/* eslint-disable */
/**
 * Specks API
 * APIs for specks SaaS
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AppSchemaRevision } from './AppSchemaRevision';
import {
    AppSchemaRevisionFromJSON,
    AppSchemaRevisionFromJSONTyped,
    AppSchemaRevisionToJSON,
} from './AppSchemaRevision';
import type { Node } from './Node';
import {
    NodeFromJSON,
    NodeFromJSONTyped,
    NodeToJSON,
} from './Node';

/**
 * 
 * @export
 * @interface Edge
 */
export interface Edge {
    /**
     * 
     * @type {string}
     * @memberof Edge
     */
    id: string;
    /**
     * 
     * @type {Node}
     * @memberof Edge
     */
    sourceNode: Node;
    /**
     * 
     * @type {string}
     * @memberof Edge
     */
    sourceHandleId: string;
    /**
     * 
     * @type {Node}
     * @memberof Edge
     */
    targetNode: Node;
    /**
     * 
     * @type {string}
     * @memberof Edge
     */
    targetHandleId: string;
    /**
     * 
     * @type {AppSchemaRevision}
     * @memberof Edge
     */
    appSchemaRevision: AppSchemaRevision;
}

/**
 * Check if a given object implements the Edge interface.
 */
export function instanceOfEdge(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "sourceNode" in value;
    isInstance = isInstance && "sourceHandleId" in value;
    isInstance = isInstance && "targetNode" in value;
    isInstance = isInstance && "targetHandleId" in value;
    isInstance = isInstance && "appSchemaRevision" in value;

    return isInstance;
}

export function EdgeFromJSON(json: any): Edge {
    return EdgeFromJSONTyped(json, false);
}

export function EdgeFromJSONTyped(json: any, ignoreDiscriminator: boolean): Edge {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'sourceNode': NodeFromJSON(json['sourceNode']),
        'sourceHandleId': json['sourceHandleId'],
        'targetNode': NodeFromJSON(json['targetNode']),
        'targetHandleId': json['targetHandleId'],
        'appSchemaRevision': AppSchemaRevisionFromJSON(json['appSchemaRevision']),
    };
}

export function EdgeToJSON(value?: Edge | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'sourceNode': NodeToJSON(value.sourceNode),
        'sourceHandleId': value.sourceHandleId,
        'targetNode': NodeToJSON(value.targetNode),
        'targetHandleId': value.targetHandleId,
        'appSchemaRevision': AppSchemaRevisionToJSON(value.appSchemaRevision),
    };
}

