import { type GetPageDto, type GetPageRevisionsInfoDto } from 'api';

import { Page } from 'models/page.entity';

import { fromGetPageRevisionDto, fromGetPageRevisionInfoDto } from './PageRevisionFactory';

export function fromGetPageDto(getPageDto: GetPageDto): Page {
  const page: Page = new Page();
  page.id = getPageDto.id;
  // page.createdAt = getPageDto.createdAt;
  page.pageRevisions = [];
  page.pageRevisions = getPageDto.pageRevisions.map((pageRevisionDto) => {
    return fromGetPageRevisionDto(pageRevisionDto);
  });

  return page;
}

export function fromGetPageRevisionsInfoDto(getPageDto: GetPageRevisionsInfoDto): Page {
  const page: Page = new Page();
  page.id = getPageDto.id;
  // page.createdAt = getPageDto.createdAt;
  page.pageRevisions = [];
  page.pageRevisions = getPageDto.pageRevisions.map((pageRevisionDto) => {
    return fromGetPageRevisionInfoDto(pageRevisionDto);
  });

  return page;
}
