/* eslint-disable max-len */
import React from 'react';

import concatClassNames from 'utils/classNames';

import { type IconProps, iconColorStyle, iconSizeStyle } from './Icon.props';

export default function UiIcon({ color, size }: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={concatClassNames(iconSizeStyle[size])}
      fill={iconColorStyle[color]}
      viewBox="0 0 128 128"
    >
      <title>Free Icons</title>
      <g id="Layer_18" data-name="Layer 18">
        <path d="M81,28.09C74.94,27.89,68.8,28,62.67,28s-12.24.25-18.34.61c-4.46.25-8.89.68-13.3,1.19a91.1,91.1,0,0,0-11.5,2,26.66,26.66,0,0,0-5.34,1.83A8.07,8.07,0,0,0,12.09,35l-.32.42a2.17,2.17,0,0,0-.19.41l-.16.62-.14.68a56.33,56.33,0,0,0-.66,5.76c-.15,2-.25,3.91-.33,5.87-.16,3.93-.21,7.86-.2,11.8,0,7,.34,14,.94,20.91q.57,6.65,1.59,13.24a101.08,101.08,0,0,0,2.73,12.94c.85,2.55.14,2.52-1.3.49s-3.1-6.23-4-11.38A215.05,215.05,0,0,1,7.68,69.31a240.84,240.84,0,0,1,.61-27.63c.15-1.39.28-2.78.51-4.2a19.94,19.94,0,0,1,.47-2.21A3.9,3.9,0,0,1,9.89,34l.42-.55.46-.43a13.88,13.88,0,0,1,4-2.3A37.9,37.9,0,0,1,19,29.4a111.57,111.57,0,0,1,16.9-2.65c12.39-1.1,24.8-1.3,37.18-1.31,3.82,0,7.64.07,11.46.27A81,81,0,0,1,96,27a25.31,25.31,0,0,1,4.31,1.23,9.51,9.51,0,0,1,2.07,1.12A6.87,6.87,0,0,1,104,31.06a18.6,18.6,0,0,1,2.1,4,40.2,40.2,0,0,1,1.4,4.18,112.18,112.18,0,0,1,3,17.11c.94,8.88,1.47,17.61,1.69,26.43,0,1.94.1,3.84.1,5.8,0,7.26-.3,9.57-1,11.1a6.32,6.32,0,0,1-.5,1c-.56.67-.85-2.29-.92-7.57-.11-7.83-.33-15.88-.81-24a185.12,185.12,0,0,0-2.79-24.38,50.15,50.15,0,0,0-2.41-8.61,16,16,0,0,0-2-3.7,5.92,5.92,0,0,0-.6-.68l-.28-.24-.34-.24a9.57,9.57,0,0,0-1.76-.83,38.79,38.79,0,0,0-8.69-1.7c-3-.31-6.09-.47-9.16-.56Z" />
        <path d="M79.56,79.91a13.65,13.65,0,0,0-2.6-.86l-.63-.15c-.13,0-.17.11-.2.21s0,0,0,.21,0,.32,0,.49l0,1a67.23,67.23,0,0,0,.3,7.61c.11,1.14.29,2.26.51,3.37l.46,2.12c.13.71.21,1.42.29,2.13,0,.44-.34.51-.85.25a3.12,3.12,0,0,1-1.43-1.69,42.56,42.56,0,0,1-1.28-9c0-1.83-.31-3.7-.25-5.64,0-.27,0-.53.06-.81a5,5,0,0,1,.06-.53c0-.2.07-.4.12-.6a2.52,2.52,0,0,1,.51-1,1.1,1.1,0,0,1,1-.38h1.16c.33,0,.55.11.84.16a13.17,13.17,0,0,1,1.55.45,29.45,29.45,0,0,1,3.53,1.55c1.72.88,3.33,1.91,5,2.87,1.26.75,2.56,1.38,3.83,2,.28.15.57.26.84.42a2.18,2.18,0,0,1,.95.77,1.27,1.27,0,0,1,.12.88c0,.17,0,.34-.07.49-.12.5-.8.68-1.69.41a47.35,47.35,0,0,1-6.95-3.94A38,38,0,0,0,79.54,80Z" />
        <path d="M88.8,91.84c1.2,1.17,2.43,2.32,3.7,3.42.46.41,1,.74,1.44,1.14,1.65,1.42,3.33,2.78,5.08,4.06a19.88,19.88,0,0,0,2.43,1.5c1.07.53,2.18,1.11,3.12,1.49.26.1.31.52-.1.92a2.21,2.21,0,0,1-2,.42,14.55,14.55,0,0,1-3.63-1.69,19.74,19.74,0,0,1-3.14-2.32c-1.17-1-2.53-1.88-3.76-2.88S89.25,95.67,88,94.5c-.79-.72-1.56-1.46-2.33-2.2-1.16-1.13-2.27-2.32-3.39-3.48-.87-.91-1.81-1.74-2.65-2.74-.18-.22-.38-.44-.54-.68a1.92,1.92,0,0,1-.32-.64,1.36,1.36,0,0,1-.07-.51,1.53,1.53,0,0,1,.45-.75,2.78,2.78,0,0,1,.28-.24.66.66,0,0,1,.6,0,1.86,1.86,0,0,1,.81.42c1.36,1.41,2.84,3,4.42,4.6,1.17,1.18,2.37,2.36,3.61,3.51Z" />
        <path d="M64.57,80.77c.25,0,.31.38.09.84a1.74,1.74,0,0,1-.48.52l-.4.16c-.06,0-1-.17-1.4-.17a4.14,4.14,0,0,1-1-.2c-.65-.18-1.3-.4-2-.58a1.42,1.42,0,0,1-.41-.2,3.38,3.38,0,0,0-1.48-.55c-.09,0-.17,0-.28,0a5,5,0,0,1-1.7-.27c-.41-.24-.82-.46-1.24-.68-.07,0-.14,0-.18-.12-.1-.27-.25-.24-.38-.28a2,2,0,0,1-.56-.26,3,3,0,0,1-.27-.32.49.49,0,0,1,0-.41c.09-.09,0-.23,0-.35a.57.57,0,0,1,0-.13,2.69,2.69,0,0,1,.67-.46,2.63,2.63,0,0,1,.79-.31l1,0c.49,0,1,.06,1.11,0a.63.63,0,0,1,.3.14s1.49.16,1.55.19c.28.11.59.1.81.39,0,.06.11.06.16.08a7,7,0,0,1,1.43.56c.23.11.49.17.73.26,0,0,.08.06.12.09a.49.49,0,0,0,.15.1c.59.19,1.1.57,1.7.76.14,0,.66.59.83.65S64.47,80.77,64.57,80.77Z" />
        <path d="M71.2,72.16c.23,0,.06.21,0,.76,0,.17-.22.63-.32.66a2,2,0,0,1-.59.07,12.52,12.52,0,0,1-1.52-.76,3.42,3.42,0,0,1-.86-.67c-.52-.52-1-1-1.52-1.54a1.41,1.41,0,0,1-.25-.41,4.59,4.59,0,0,0-.91-1.35c-.06-.06-.1-.15-.23-.16a4.35,4.35,0,0,1-1.14-1.43c-.06-.26-.13-.49-.2-.74l-.17-.77c0-.07-.07-.13,0-.24.16-.27.05-.38,0-.53a1.74,1.74,0,0,1-.06-.73,1.26,1.26,0,0,1,.24-.46c.08-.12.29-.09.34-.07s.21,0,.3,0l.11,0a6.76,6.76,0,0,1,.68.21,2.87,2.87,0,0,1,.62.32s.27.29.57.58.58.64.65.69.08.18.1.28.94.94,1,1c.14.23.38.38.35.73,0,.07,0,.1.08.14a7,7,0,0,1,.72,1.18,5.7,5.7,0,0,0,.42.55.69.69,0,0,1,0,.13.28.28,0,0,0,.06.16,9.08,9.08,0,0,0,1,1.25c.1.09.31.68.43.75S71.11,72.15,71.2,72.16Z" />
        <path d="M81.42,69.81c.15.09-.1.16-.54.46a1.65,1.65,0,0,1-.67.24,1.61,1.61,0,0,1-.46-.26,3,3,0,0,1-.27-.68c-.09-.32-.18-.69-.21-.86a2.82,2.82,0,0,1,0-1,17.8,17.8,0,0,1,.38-1.85,1.13,1.13,0,0,1,.19-.38,3.34,3.34,0,0,0,.57-1.28.3.3,0,0,0,0-.25,3.34,3.34,0,0,1,.52-1.47c.33-.3.61-.62.93-.92,0,0,0-.11.17-.11.28,0,.31-.11.39-.21a2,2,0,0,1,.46-.33,1.54,1.54,0,0,1,.4,0c.12,0,.37.23.39.27s.22.17.34.25l.12.09a3.78,3.78,0,0,1,.12.62,1.89,1.89,0,0,1,.06.63s-.13.31-.24.63a6.78,6.78,0,0,0-.22.74c0,.08-.1.12-.16.18s-.26,1-.28,1.07c-.1.19-.1.41-.38.54-.05,0-.06.07-.07.11a5.58,5.58,0,0,1-.45,1,3.21,3.21,0,0,0-.15.53s0,.06-.08.09a.23.23,0,0,0-.07.1A5.25,5.25,0,0,0,81.92,69c0,.1-.28.49-.25.59S81.36,69.77,81.42,69.81Z" />
        <path d="M25.65,43a2.08,2.08,0,0,1,1.5,1.3c1.16,3.11-4.09,3.29-4.06.52A2,2,0,0,1,25.65,43Z" />
        <path d="M33.63,42.16a2.07,2.07,0,0,1,1.5,1.29c1.16,3.11-4.09,3.29-4.06.52A2,2,0,0,1,33.63,42.16Z" />
        <path d="M41.61,41.3a2.12,2.12,0,0,1,1.5,1.3c1.16,3.11-4.09,3.29-4.06.52A2,2,0,0,1,41.61,41.3Z" />
        <path d="M66.41,111.4c-4.13.14-8.26.23-12.39.3-1.51,0-3,.1-4.52.11-5.32,0-10.63-.06-15.94-.17-2.36,0-4.73,0-7.06,0l-4.5,0c-1.48,0-3,0-4.36,0a.57.57,0,0,1-.36,0,.67.67,0,0,1-.2-.21c-.13-.17-.07-.54.34-.89a6.48,6.48,0,0,1,4-1.25c6.17,0,12.24.21,18.41.45,3.69.15,7.53,0,11.3-.06q6.22-.09,12.45-.29c2.55-.08,5.11-.17,7.66-.3,3.86-.18,7.74-.39,11.57-.69,3-.25,5.88-.62,8.82-1l1.92-.23c2.42-.25,3.2-.18,3.77.34a2.66,2.66,0,0,1,.38.36c.28.42-.68.87-2.44,1.15-5.24.85-10.79,1.36-16.38,1.74-4.15.28-8.33.48-12.5.67Z" />
        <path d="M64.77,112.71q-5.35.18-10.71.29l-3.9.1c-4.59.05-9.18,0-13.77,0l-6.1,0c-2.59,0-5.2,0-7.77-.07-1.62-.06.41-1.36,3.42-1.34,5.3,0,10.57.16,15.91.28,3.19.09,6.51,0,9.77-.09,3.58-.07,7.17-.15,10.76-.28,2.21-.07,4.41-.18,6.62-.28,3.34-.16,6.7-.33,10-.58,2.57-.21,5.09-.46,7.63-.74l1.66-.18c2.1-.19,2.77-.16,3.25.14a2.22,2.22,0,0,1,.32.21c.22.25-.62.53-2.14.74-4.54.61-9.34,1-14.17,1.32-3.58.24-7.19.41-10.79.58Z" />
        <path d="M101.2,17.62c0,.31-.15.63-.21.93,0,.12,0,.23,0,.34-.1.4-.24.79-.33,1.18a1.3,1.3,0,0,0,0,.52c0,.22.17.44,0,.66s-2,0-2-.29a2,2,0,0,1,.25-1.32c.15-.27,0-.54.07-.81l.11-.9a5.35,5.35,0,0,0,.07-.56,3.3,3.3,0,0,0,0-.83,3,3,0,0,0-.27-.61s-.06-.09,0-.14,0-.15.19-.2a2.53,2.53,0,0,1,.47-.13l.27-.06a1.85,1.85,0,0,1,.86.1,1.41,1.41,0,0,1,.69,1.18c0,.31,0,.63,0,.94Z" />
        <path d="M115.53,25.24l-1.67.61c-.2.08-.38.2-.59.27l-2.21.69a6,6,0,0,0-.94.4,2.66,2.66,0,0,1-1.23.42c-.28,0-.45-1.4,0-1.64a9.63,9.63,0,0,1,2.33-1,6.63,6.63,0,0,0,1.4-.72c.52-.27,1.06-.49,1.6-.74l1-.45c.5-.24,1-.45,1.5-.73s.67-.55,1-.76a1.19,1.19,0,0,1,.25-.13c.34-.1.51,0,.76.38,0,.09.12.17.17.26a.91.91,0,0,1,0,.89,3.53,3.53,0,0,1-1.74,1.56c-.52.25-1.06.47-1.6.71Z" />
        <path d="M78.15,33.55c-5.25-.17-10.55-.11-15.83-.06s-10.56.22-15.82.52c-3.84.23-7.67.59-11.47,1a78,78,0,0,0-9.93,1.69,23,23,0,0,0-4.61,1.58,7.22,7.22,0,0,0-1.81,1.24l-.28.37a2,2,0,0,0-.17.36A10.49,10.49,0,0,0,18,41.41a84.42,84.42,0,0,0-.85,10c-.13,3.39-.18,6.78-.17,10.17,0,6,.3,12.09.81,18,.33,3.82.78,7.64,1.37,11.42a86.37,86.37,0,0,0,2.36,11.16c.74,2.2.14,2.17-1.08.42A27.09,27.09,0,0,1,17,92.82,184.62,184.62,0,0,1,15,69.15a206.41,206.41,0,0,1,.52-23.83c.12-1.19.23-2.39.43-3.62a16.27,16.27,0,0,1,.41-1.9,3.16,3.16,0,0,1,.53-1.09l.35-.47.4-.37a11.61,11.61,0,0,1,3.45-2,32.35,32.35,0,0,1,3.59-1.13,96,96,0,0,1,14.58-2.28c10.67-.95,21.37-1.13,32.05-1.14,3.29,0,6.59.06,9.89.23A70.42,70.42,0,0,1,91,32.66a22.62,22.62,0,0,1,3.71,1.06,8.56,8.56,0,0,1,1.78,1A5.8,5.8,0,0,1,98,36.17a15.12,15.12,0,0,1,1.81,3.44,31.71,31.71,0,0,1,1.21,3.6A95,95,0,0,1,103.52,58c.82,7.66,1.27,15.19,1.45,22.79,0,1.68.09,3.31.09,5,0,6.26-.26,8.25-.86,9.58a7.16,7.16,0,0,1-.42.87c-.47.57-.71-2-.76-6.53-.09-6.76-.28-13.7-.69-20.71a161.75,161.75,0,0,0-2.4-21,43.91,43.91,0,0,0-2.09-7.44,13.59,13.59,0,0,0-1.69-3.19,3.94,3.94,0,0,0-.52-.6l-.24-.21-.3-.21a8.34,8.34,0,0,0-1.53-.72,33.79,33.79,0,0,0-7.5-1.47c-2.61-.27-5.25-.41-7.9-.48Z" />
        <path d="M65.21,105.87l-10.5,0-3.84.06c-4.5,0-9-.11-13.51-.17-2,0-4,0-6,0a51.73,51.73,0,0,1-7.66-.38c-1.58-.2.52-1.09,3.42-1.18,5.16,0,10.35,0,15.58.12,3.13.06,6.39-.08,9.59-.12l10.57-.14,6.51-.11c3.28-.08,6.58-.13,9.84-.27,2.53-.1,5-.31,7.52-.47.55,0,1.09-.1,1.64-.1,2.07,0,2.73.17,3.18.56a2.09,2.09,0,0,1,.3.28c.21.29-.63.6-2.13.85a133,133,0,0,1-13.92.91l-10.59.2Z" />
        <path d="M14.55,78c-.21-3.14-.35-6.28-.48-9.42-.05-1.14-.17-2.29-.19-3.43-.09-4-.06-8.09.06-12.13.07-1.8.06-3.61.11-5.4.09-2.3,0-4.64.91-7,.31-.74.9-.35,1.4.39a4.78,4.78,0,0,1,.85,3c-.37,4.44-.35,9.07-.67,13.72-.17,2.79.07,5.68.17,8.54.11,3.13.24,6.27.43,9.41.11,1.93.24,3.86.41,5.78.26,2.92.48,5.85.91,8.72a65.52,65.52,0,0,0,1.39,6.52c.12.47.27.92.4,1.38a12.37,12.37,0,0,1,.42,1.79,1.85,1.85,0,0,1-.22,1c-.06.14-.09.3-.13.4s-.27.14-.66-.08a1.59,1.59,0,0,1-.57-.53,3.32,3.32,0,0,1-.49-.85,35.93,35.93,0,0,1-1.89-6c-.47-2.07-.86-4.18-1.18-6.29-.51-3.14-.79-6.31-1-9.48Z" />
      </g>
    </svg>
  );
}
