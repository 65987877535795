import {
  ComponentsApi,
  type CreateComponentDto,
  type CreateComponentDtoComponentTypeEnum,
  type CreateComponentDtoStatusEnum,
  type CreateExternalLinkDto,
  type GetComponentDto,
  type GetComponentRelatedProductsDto,
  type GetComponentSpecificationDto,
  type GetComponentWithPagesDto,
  type GetComponentsDto,
  type GetExternalLinkDto,
  type GetLinkDto,
  type GetLinksDto,
  type GetProductDto,
} from 'api';
import { fromComponentIdDto, fromGetComponentDto, fromGetComponentWithPagesDto } from 'factory/ComponentFactory';
import { fromGetMethodRevisionDto } from 'factory/MethodRevisionFactory copy';
import { fromGetProductDto } from 'factory/ProductFactory';
import { fromGetUiComponentRevisionInfoDtoToPage } from 'factory/UiComponentRevisionFactory';
import { fromGetUiComponentLightInfoDto } from 'factory/uiComponentFactory';

import { type BackApiSpecification } from 'models/backApiSpecification.entity';
import { type Component } from 'models/component.entity';
import { type ExternalLink } from 'models/externalLink.entity';
import { type FrontSpecification } from 'models/frontSpecification.entity';
import { Link } from 'models/link.entity';
import { type Product } from 'models/product.entity';

export class ComponentService {
  private readonly componentClient: ComponentsApi;

  constructor() {
    this.componentClient = new ComponentsApi();
  }

  public async create(component: Component, accessToken: string): Promise<Component> {
    const createComponentDto: CreateComponentDto = {
      status: component.status as CreateComponentDtoStatusEnum,
      name: component.name,
      componentType: component.componentType as CreateComponentDtoComponentTypeEnum,
      internal: component.internal,
    };

    const res: GetComponentDto = await this.componentClient.componentControllerCreate(
      {
        createComponentDto,
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
      },
    );
    return fromGetComponentDto(res);
  }

  public async findAll(accessToken: string): Promise<Component[]> {
    const resDto: GetComponentsDto = await this.componentClient.componentControllerFindAll(undefined, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
    });
    return resDto.components.map((componentDto: GetComponentWithPagesDto): Component => {
      return fromGetComponentWithPagesDto(componentDto);
    });
  }

  public async findById(id: string, accessToken: string): Promise<Component> {
    const res: GetComponentDto = await this.componentClient.componentControllerFindById(
      {
        id,
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
      },
    );
    return fromGetComponentDto(res);
  }

  public async delete(id: string, accessToken: string): Promise<void> {
    await this.componentClient.componentControllerDelete(
      { id },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
      },
    );
  }

  public async update(componentId: string, component: Component, accessToken: string): Promise<Component> {
    const getComponentDto: GetComponentDto = await this.componentClient.componentControllerUpdate(
      {
        id: componentId,
        updateComponentDto: {
          name: component.name,
          status: component.status,
          internal: component.internal,
          componentType: component.componentType,
        },
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
      },
    );
    return fromGetComponentDto(getComponentDto);
  }

  /* ---------------------------------------------- related components ---------------------------------------------- */
  public async findLinks(id: string, accessToken: string, active: boolean, productContext?: string): Promise<Link[]> {
    const res: GetLinksDto = await this.componentClient.componentControllerFindLinksById(
      {
        id,
        active,
        productContext,
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
      },
    );

    return res.links.map((linkDto: GetLinkDto): Link => {
      const link: Link = new Link();
      link.id = linkDto.id;
      link.caller = fromComponentIdDto(linkDto.caller);
      link.receiver = fromComponentIdDto(linkDto.receiver);
      link.bearerForReceiver = fromGetMethodRevisionDto(linkDto.bearerForReceiver);
      link.bearerForCaller = fromGetUiComponentLightInfoDto(linkDto.bearerForCaller.uiComponent);
      link.bearerForCallerPage = fromGetUiComponentRevisionInfoDtoToPage(linkDto.bearerForCaller);
      return link;
    });
  }

  /* ----------------------------------------------- Related Products ----------------------------------------------- */

  public async findRelatedProducts(id: string, accessToken: string): Promise<Product[]> {
    const res: GetComponentRelatedProductsDto = await this.componentClient.componentControllerFindRelatedProducts(
      {
        id,
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
      },
    );
    return res.relatedProducts.map((productDto: GetProductDto): Product => {
      return fromGetProductDto(productDto);
    });
  }

  /* ------------------------------------------------- specification ------------------------------------------------ */

  public async getSpecificationByComponentId(
    componentId: string,
    accessToken: string,
  ): Promise<FrontSpecification | BackApiSpecification> {
    const res: GetComponentSpecificationDto = await this.componentClient.componentControllerFindSpecificationById(
      {
        id: componentId,
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
      },
    );
    if (res.backApiSpecification !== undefined) {
      return res.backApiSpecification;
    }
    return res.frontSpecification;
  }

  /* ---------------------------------------------------- methods --------------------------------------------------- */

  public async getComponentByMethodId(methodId: string, accessToken: string): Promise<Component> {
    const res: GetComponentDto = await this.componentClient.componentControllerFindComponentByMethodId(
      {
        id: methodId,
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
      },
    );
    return fromGetComponentDto(res);
  }
  /* ------------------------------------------------- externalLinks ------------------------------------------------ */

  public async createExternalLink(
    componentId: string,
    externalLink: ExternalLink,
    accessToken: string,
  ): Promise<GetExternalLinkDto> {
    return await this.componentClient.componentControllerCreateExternalLink(
      {
        id: componentId,
        createExternalLinkDto: externalLink as CreateExternalLinkDto,
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
      },
    );
  }
}
