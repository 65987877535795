/* tslint:disable */
/* eslint-disable */
/**
 * Specks API
 * APIs for specks SaaS
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { MethodRevision } from './MethodRevision';
import {
    MethodRevisionFromJSON,
    MethodRevisionFromJSONTyped,
    MethodRevisionToJSON,
} from './MethodRevision';
import type { ResourceRevision } from './ResourceRevision';
import {
    ResourceRevisionFromJSON,
    ResourceRevisionFromJSONTyped,
    ResourceRevisionToJSON,
} from './ResourceRevision';
import type { UiComponentRevision } from './UiComponentRevision';
import {
    UiComponentRevisionFromJSON,
    UiComponentRevisionFromJSONTyped,
    UiComponentRevisionToJSON,
} from './UiComponentRevision';

/**
 * 
 * @export
 * @interface Method
 */
export interface Method {
    /**
     * 
     * @type {string}
     * @memberof Method
     */
    id: string;
    /**
     * 
     * @type {Array<MethodRevision>}
     * @memberof Method
     */
    methodRevisions: Array<MethodRevision>;
    /**
     * 
     * @type {ResourceRevision}
     * @memberof Method
     */
    resourceRevision: ResourceRevision;
    /**
     * 
     * @type {Array<UiComponentRevision>}
     * @memberof Method
     */
    uiComponentRevisions: Array<UiComponentRevision>;
}

/**
 * Check if a given object implements the Method interface.
 */
export function instanceOfMethod(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "methodRevisions" in value;
    isInstance = isInstance && "resourceRevision" in value;
    isInstance = isInstance && "uiComponentRevisions" in value;

    return isInstance;
}

export function MethodFromJSON(json: any): Method {
    return MethodFromJSONTyped(json, false);
}

export function MethodFromJSONTyped(json: any, ignoreDiscriminator: boolean): Method {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'methodRevisions': ((json['methodRevisions'] as Array<any>).map(MethodRevisionFromJSON)),
        'resourceRevision': ResourceRevisionFromJSON(json['resourceRevision']),
        'uiComponentRevisions': ((json['uiComponentRevisions'] as Array<any>).map(UiComponentRevisionFromJSON)),
    };
}

export function MethodToJSON(value?: Method | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'methodRevisions': ((value.methodRevisions as Array<any>).map(MethodRevisionToJSON)),
        'resourceRevision': ResourceRevisionToJSON(value.resourceRevision),
        'uiComponentRevisions': ((value.uiComponentRevisions as Array<any>).map(UiComponentRevisionToJSON)),
    };
}

