/* eslint-disable max-len */
import React from 'react';

import concatClassNames from 'utils/classNames';

import { type IconProps, iconColorStyle, iconSizeStyle } from './Icon.props';

export default function HtmlIcon({ color, size }: IconProps): JSX.Element {
  return (
    <svg
      id="Layer_3"
      xmlns="http://www.w3.org/2000/svg"
      className={iconSizeStyle[size]}
      viewBox="0 0 128 128"
      fill={iconColorStyle[color]}
    >
      <path
        stroke={iconColorStyle[color]}
        strokeWidth={1}
        className="cls-1"
        d="m24.53,41.55c-1.51.53-2.13,3.1.08,3.31,2.21.21-1.26,1.96-2.45.98s-2.46-2.3-1.37-3.8c1.09-1.5,3.74-.48,3.74-.48Z"
      />
      <path
        stroke={iconColorStyle[color]}
        strokeWidth={1}
        className="cls-1"
        d="m34.06,41.57s.19,3.07,1.84,2.02c1.83-1.17,1.27,2.57-.63,2.45s-3.89-1.11-3.76-2.35c.13-1.24,2.55-2.11,2.55-2.11Z"
      />
      <path
        stroke={iconColorStyle[color]}
        strokeWidth={1}
        className="cls-1"
        d="m44.73,42.07s.2,2.78,2.35,1.73c1.33-.65-3.6,4.24-4.48.52-.57-2.41,2.13-2.25,2.13-2.25Z"
      />
      <path
        stroke={iconColorStyle[color]}
        strokeWidth={1}
        className="cls-1"
        d="m14.69,47.16c0,.73.35,1.41.42,1.38.02,0-.01.02.05.04l.22.07.22.08.29.06c.37.09.78.17,1.2.23.61.09,1.24.09,1.88.15,2.24.23,4.55.33,6.85.43,1.02.05,2.05.03,3.07,0,1.3-.07,2.63-.07,3.94.31.41.12.35.28,0,.56-.35.27-.97.79-1.71.94-.66.18-1.32.22-1.98.27-.66.05-1.33.09-2,.12-.67.03-1.34.05-2.01.04-.67-.02-1.35-.08-2.03-.14-1.62-.17-3.31-.05-5-.17-.93-.09-1.86-.15-2.84-.41-.5-.15-1-.25-1.65-.74-.26-.23-.46-.52-.63-.78-.11-.27-.26-.54-.33-.8-.36-1.29-.34-2.47-.27-3.64.14-1.76.55-3.46,1.01-5.12.36-1.29.68-2.58,1.21-3.84.11-.28.21-.56.36-.83.54-.99.97-1.17,1.58-.99.14.04.29.07.42.13.43.21.51.79.25,1.46-.36,1.03-.73,2.06-1.09,3.1-.34,1.06-.66,2.14-.91,3.25-.35,1.62-.62,3.34-.46,4.82h-.06Z"
      />
      <path
        stroke={iconColorStyle[color]}
        strokeWidth={1}
        className="cls-1"
        d="m41.14,51.18l11.74.56,4.28.15,15.09.83c2.23.1,4.48.21,6.7.31,1.42.06,2.85.13,4.28.19,1.43.06,2.85.22,4.25.6.87.23.76.21,0,.39-.38.08-.93.29-1.58.42-.33.07-.68.16-1.06.2-.37.02-.77.03-1.18.02-2.9-.09-5.8-.18-8.71-.27-2.91-.12-5.82-.29-8.75-.49-3.5-.24-7.15-.25-10.73-.36l-11.83-.42c-2.43-.1-4.86-.19-7.28-.29-3.68-.14-7.37-.3-11.02-.4-2.84-.04-5.63-.08-8.44-.11-.62-.01-1.23-.03-1.85-.04-2.31-.11-3.05-.32-3.5-.85-.1-.12-.24-.25-.3-.36-.19-.4.77-.66,2.46-.68,2.5-.04,5.07-.06,7.68-.04,2.61.04,5.25.12,7.9.2,3.94.14,7.89.27,11.84.41v.05Z"
      />
      <path
        stroke={iconColorStyle[color]}
        strokeWidth={1}
        className="cls-1"
        d="m16.88,112.8c-.68-.37-1.35-.76-2-1.26-.65-.47-1.31-1.08-1.82-1.96l-.24-.53c-.09-.19-.09-.28-.14-.42-.08-.26-.16-.52-.21-.79-.34-1.9-.41-3.8-.51-5.66-.05-.82-.11-1.65-.16-2.46-.08-1.04-.08-2.09-.04-3.14.02-.33.12-.29.28-.03.16.26.42.75.57,1.34.27,1.05.43,2.1.59,3.14.16,1.04.32,2.08.45,3.12.13,1.26.62,2.48.95,3.69.05.16.1.35.15.48.05.09.09.19.13.29.17.2.3.42.55.62.43.42,1.02.77,1.6,1.11.74.4,1.52.77,2.33,1.08,1.22.48,2.47.96,3.76,1.27,1,.25,2.02.34,3.03.47.22.03.45.04.67.08.83.15,1.05.36,1.09,1.02,0,.15.03.3.02.45-.03.5-.45.82-1.09.82-1.9,0-3.83-.4-5.75-.98-1.43-.42-2.83-.98-4.22-1.68l.02-.05Z"
      />
      <path
        stroke={iconColorStyle[color]}
        strokeWidth={1}
        className="cls-1"
        d="m44.91,49.57l13.23.46c1.61.05,3.22.03,4.83.09,5.67.23,11.34.45,17,.73,2.51.12,5.04.16,7.54.2,1.6.02,3.21.04,4.82.06l1.2.03,1.2.13c.8.1,1.6.18,2.4.32.99.26.86.35,0,.73-.43.2-1.03.4-1.76.62-.37.11-.76.22-1.18.31-.21.05-.43.08-.65.14-.22.01-.45.02-.68.03-6.55.02-13.09.04-19.69-.42-3.94-.27-8.05-.22-12.09-.32l-13.32-.37c-2.73-.07-5.47-.13-8.2-.2-4.14-.08-8.3-.19-12.4-.16-3.19.03-6.32.22-9.47.38-.69.04-1.37.08-2.07.12-2.59.08-3.37,0-3.98-.79-.14-.16-.31-.31-.4-.46-.29-.44.75-1.17,2.68-1.35,5.68-.62,11.64-.64,17.62-.61,4.44.03,8.9.15,13.36.24v.07Z"
      />
      <path
        stroke={iconColorStyle[color]}
        strokeWidth={1}
        className="cls-1"
        d="m11.22,107.83s-7.09,1.54-5.92,3.4c1.54,2.47,14.21,4.94,18.69,3.68,3.8-1.06-10.97-2.31-10.97-2.31l-1.8-4.78Z"
      />
      <path
        stroke={iconColorStyle[color]}
        strokeWidth={1}
        className="cls-1"
        d="m10.38,103.32c-.73-13.19-.96-26.33-.81-39.51.08-4.81.1-9.62.44-14.44.14-2.13.33-4.26.59-6.4.31-2.16.56-4.26,1.42-6.58.04-.14.11-.29.21-.46l.27-.49c.17-.34.48-.71.83-.97.75-.56,1.31-.73,1.9-.94,1.15-.37,2.24-.56,3.33-.73,2.17-.32,4.31-.48,6.44-.6,8.52-.41,16.98-.2,25.43.08,7.5.27,15.03.57,22.46.92,9.54.57,19.13,1.03,28.59,2.51,2.95.52,2.55.48,0,.67-2.54.2-7.22.71-12.72.58l-14.6-.51c-4.86-.22-9.72-.43-14.59-.65-9.73-.39-19.49-.76-29.25-.73-2.91.02-5.86.12-8.76.4-1.45.14-2.9.33-4.25.64-.67.15-1.33.35-1.8.58-.24.13-.28.15-.37.26-.04.07-.07.15-.13.26-.06.1-.12.2-.17.37-.45,1.15-.7,2.65-.93,4.07-.21,1.45-.37,2.93-.5,4.41-.26,2.97-.42,5.97-.54,8.95-.49,13.15-.34,26.38.12,39.57.16,4.06.33,8.12.65,12.14.08,1,.18,2.01.31,2.97.11.87.25.91,1.19,1.36,1.68.69,3.71,1.03,5.66,1.32,6.02.81,12.21,1.05,18.35,1.22,6.15.16,12.3.18,18.41.14,9.51-.07,18.82-.4,28.23-.59,2.07-.04,4.1-.08,6.19-.12,7.75-.04,10.23.21,11.87,1.02.37.18.87.36,1.09.53.72.55-2.43,1.01-8.08,1.14-16.77.45-34.49,1.02-52.34.87-6.63-.08-13.28-.22-19.99-.77-1.68-.15-3.36-.32-5.07-.58-1.71-.28-3.41-.56-5.26-1.25-.24-.11-.46-.18-.72-.32-.26-.13-.46-.22-.81-.46-.65-.48-1.28-1.04-1.48-1.84-.17-.35-.22-.82-.26-1.01-.05-.26-.07-.46-.1-.69-.06-.44-.11-.88-.15-1.3-.17-1.7-.29-3.36-.4-5.03l.08-.02Z"
      />
      <path
        stroke={iconColorStyle[color]}
        strokeWidth={1}
        className="cls-1"
        d="m107.08,84.01c.17-3.73.32-7.47.43-11.21.05-1.36,0-2.73.03-4.09.13-4.81.37-9.62.47-14.43.02-2.13.07-4.28.05-6.4,0-2.72-.14-5.45.2-8.17.12-.85.21-.75.54-.05.35.7.96,2.01,1.17,3.58.26,2.78.48,5.57.62,8.36.08,2.79,0,5.59-.13,8.4-.15,3.36-.05,6.85-.11,10.29-.08,3.78-.2,7.56-.34,11.33-.09,2.32-.18,4.65-.27,6.97-.14,3.52-.35,7.05-.47,10.55-.04,1.36-.07,2.71-.08,4.05-.02,1.34-.09,2.69-.17,4.04-.03.59-.05,1.18-.1,1.77-.27,2.22-.58,2.9-1.22,3.3-.15.09-.3.22-.43.26-.46.15-.76-.79-.83-2.4-.07-2.39-.11-4.84-.15-7.33.09-2.48.17-5,.26-7.52.14-3.76.28-7.53.43-11.29h.07Z"
      />
      <path
        stroke={iconColorStyle[color]}
        strokeWidth={1}
        className="cls-1"
        d="m27.14,44.19c-.28,1.4-1.22,2.71-2.8,3.06-.54.11-1.05.12-1.5,0-1.56-.43-2.63-1.74-2.89-3.16-.11-.64-.03-1.3.21-1.86.26-.75.99-1.33,1.77-1.36.24,0,.21.09.1.29-.12.19-.24.56-.33.84-.08.26-.16.47-.22.68-.05.22-.07.44-.04.63.04.37.22.77.5,1.15.34.47,1.03.66,1.53.68.28,0,.42-.02.62-.16.21-.14.39-.38.49-.65.13-.34.17-.76.07-1.14-.14-.59-.5-1.13-1.1-1.26-.46-.12-.95.13-1.28.35-.07.05-.13.12-.2.17-.23.18-.43.06-.98-.27l-.38-.22c-.4-.23-.59-.73-.27-1.28.52-.73,1.39-1.28,2.35-1.37.96-.11,2.04.16,2.9.84,1.31,1.04,1.71,2.64,1.49,4.04h-.06Z"
      />
      <path
        stroke={iconColorStyle[color]}
        strokeWidth={1}
        className="cls-1"
        d="m38.19,44.19c-.28,1.4-1.22,2.71-2.8,3.06-.54.11-1.05.12-1.5,0-1.56-.43-2.63-1.74-2.89-3.16-.11-.64-.03-1.3.21-1.86.26-.75.99-1.33,1.77-1.36.24,0,.21.09.1.29-.12.19-.24.56-.33.84-.08.26-.16.47-.22.68-.05.22-.07.44-.04.63.04.37.22.77.5,1.15.34.47,1.03.66,1.53.68.28,0,.42-.02.62-.16.21-.14.39-.38.49-.65.13-.34.17-.76.07-1.14-.14-.59-.5-1.13-1.1-1.26-.46-.12-.95.13-1.28.35-.07.05-.13.12-.2.17-.23.18-.43.06-.98-.27l-.38-.22c-.4-.23-.59-.73-.27-1.28.52-.73,1.39-1.28,2.35-1.37.96-.11,2.04.16,2.9.84,1.31,1.04,1.71,2.64,1.49,4.04h-.06Z"
      />
      <path
        stroke={iconColorStyle[color]}
        strokeWidth={1}
        className="cls-1"
        d="m48.44,44.16c-.24,1.32-1.22,2.4-2.53,2.63-.45.08-.87.09-1.25,0-1.31-.32-2.36-1.32-2.68-2.58-.14-.56-.07-1.17.16-1.66.26-.66.94-1.11,1.6-1.11.21,0,.18.09.08.26-.11.16-.23.48-.33.72-.08.22-.16.39-.22.57-.05.18-.06.35-.04.5.04.28.21.6.48.91.33.38.96.51,1.42.53.26,0,.42-.03.61-.14.19-.12.35-.31.44-.52.11-.27.15-.61.04-.92-.15-.47-.5-.9-1.06-.99-.43-.08-.87.14-1.18.33l-.19.15c-.22.15-.39.04-.85-.27l-.32-.21c-.34-.22-.48-.66-.18-1.13.97-1.26,2.87-1.58,4.46-.59,1.21.75,1.79,2.21,1.58,3.52h-.05Z"
      />
      <path
        stroke={iconColorStyle[color]}
        strokeWidth={1}
        className="cls-1"
        d="m37.15,83.28l-.96-.74c-.44-.34-.88-.68-1.23-1.04-.33-.37-.55-.74-.46-1.09v-.14s.02-.02.02-.02h0s-.24,0-.13,0h0s0,0,0,0h0s.01-.04.01-.04l.02-.07.09-.28c.06-.19.1-.38.26-.56.32-.34.58-.69,1-.98l.58-.45c.18-.14.41-.33.53-.39.58-.36,1.11-.75,1.69-1.07,2.04-1.07,4.09-2,6.08-3.02.89-.44,1.72-1,2.56-1.49.54-.32,1.05-.68,1.58-1.01l.39-.25.44-.18c.29-.12.59-.23.89-.36.38-.14.49.06.44.54-.02.24-.08.56-.19.93-.05.18-.12.36-.2.55-.04.1-.09.18-.13.28-.06.07-.13.14-.2.2-2.03,1.82-4.39,3.01-6.8,4.04-1.45.61-2.79,1.53-4.11,2.34-.2.12-.39.24-.58.36l-.28.18c-.1.07-.2.13-.22.13-.15.09-.31.18-.47.31-.47.24,0,.49.21.71l.14.12.08.07.16.12.64.49,1.3.96c1.32.96,2.66,1.93,4.03,2.79,1.07.67,2.21,1.19,3.33,1.77.25.13.5.23.74.37.9.5,1.05.87.84,1.6-.05.16-.08.34-.14.49-.2.49-.84.7-1.56.43-2.14-.82-4.13-2.09-6.1-3.43-1.45-1-2.89-2.04-4.34-3.09l.04-.05Z"
      />
      <path
        stroke={iconColorStyle[color]}
        strokeWidth={1}
        className="cls-1"
        d="m62.31,78.46l-2.49,4.17c-.3.51-.53,1.05-.84,1.56-1.07,1.78-2.16,3.55-3.17,5.37-.45.8-.82,1.65-1.24,2.46-.51,1.06-.97,2.13-1.65,3.11-.21.3-.51.12-.72-.31-.21-.43-.37-1.13-.17-1.79.39-1.15.72-2.33,1.27-3.4.54-1.08,1.13-2.14,1.78-3.17.78-1.23,1.34-2.64,2.02-3.95l2.31-4.3,1.43-2.64c.7-1.34,1.44-2.67,2.04-4.05.47-1.07.77-2.19,1.12-3.29.08-.24.13-.49.22-.73.32-.89.64-1.07,1.43-.95.18.03.36.04.53.09.57.15.89.71.76,1.43-.39,2.11-1.29,4.13-2.27,6.1-.73,1.47-1.53,2.9-2.31,4.33l-.06-.03Z"
      />
      <path
        stroke={iconColorStyle[color]}
        strokeWidth={1}
        className="cls-1"
        d="m82.52,83.09c.08,0,.22-.03.35-.1.11-.07,0-.12-.03-.2l-.09-.12c-.07-.07,0-.04-.29-.27-.51-.42-1.07-.78-1.58-1.21-1.77-1.54-3.54-3.09-5.36-4.57-.81-.65-1.63-1.3-2.46-1.91l-1.6-1.18c-.54-.38-.98-.92-1.45-1.4-.28-.32-.11-.47.35-.52.45-.03,1.29-.11,2,.21,1.2.65,2.39,1.32,3.54,2.05,1.15.72,2.18,1.62,3.22,2.52,1.22,1.1,2.71,1.95,4.04,2.97l2.16,1.66c.24.19.47.38.7.56.14.09.18.2.24.3.06.1.13.21.17.3l.27.54.07.13.03.06.02.04v.02s0,0,0,0c.04,0-.1,0,.1,0h0s0,0,0,0h0c-.02.13-.05.24-.09.35-.11.18-.21.36-.33.53-.14.17-.28.33-.47.49-.58.52-1.41,1.01-2.06,1.39-.51.28-1.02.55-1.53.81-1.54.8-3.11,1.51-4.6,2.34-1.16.64-2.19,1.44-3.29,2.14-.24.16-.46.33-.71.48-.91.54-1.3.52-1.89-.04-.13-.13-.28-.24-.4-.38-.39-.46-.26-1.12.34-1.69,1.78-1.7,3.94-2.91,6.11-4.06,1.48-.77,2.96-1.55,4.45-2.32l.05.06Z"
      />
      <path
        stroke={iconColorStyle[color]}
        strokeWidth={1}
        className="cls-1"
        d="m98.33,19.96c0-.13.05-.23.11-.32.02-.03.07-.03.08,0,.02.08.05.16.06.21,0,.04.14.73.21.97.06.23.12.45.11.68l.04,1.39c0,.1,0,.21-.02.31-.05.35-.05.7-.03,1.06,0,.06,0,.12.03.18.05.09.07,1.06.04,1.18-.06.31-.1.61-.15.92,0,.04,0,.09-.04.13-.1.09-.09.18-.1.28-.01.13-.03.26-.07.39-.02.06-.07.13-.12.19-.04.06-.18.02-.19,0-.04-.06-.11-.02-.17-.01-.02,0-.04,0-.06,0-.03,0-.28-.77-.28-.86-.02-.02-.03-1.12-.04-1.25,0-.07.02-.13.03-.2,0-.05-.02-.91-.02-.94.02-.18,0-.36.08-.54.02-.04.01-.07.01-.11,0-.31.05-.63.08-.94.02-.16.01-.32.02-.48,0-.03.01-.06.02-.09,0-.04.02-.07.02-.11.01-.38.05-.76.11-1.14.01-.09.14-.51.14-.61,0-.06.09-.24.09-.29Z"
      />
      <path
        stroke={iconColorStyle[color]}
        strokeWidth={1}
        className="cls-1"
        d="m94.96,23.9c-.12.01-.22-.08-.3-.18-.03-.03-.02-.12.01-.14.08-.04.15-.08.2-.11.06-.02.22-.07.39-.13.2-.05.42-.08.53-.11.22-.05.43-.07.65-.08.44-.02.88-.08,1.32-.09.1,0,.19,0,.29.03.33.07.67.07,1,.05.06,0,.11,0,.17-.04.09-.06.99-.08,1.11-.05.29.07.58.12.87.18.04,0,.09,0,.12.04.09.11.18.09.27.1.12.01.25.03.37.07.06.02.12.08.18.14.05.05.02.22,0,.24-.06.05-.01.13,0,.21,0,.02,0,.06,0,.07,0,.05-.73.32-.81.31-.02,0-1.02.06-1.19.07-.06,0-.12-.02-.19-.04-.05,0-.87.04-.9.04-.17-.02-.35.01-.52-.08-.03-.02-.07-.02-.1-.01-.3,0-.6-.04-.89-.07-.15-.02-.3-.02-.46-.03-.03,0-.05-.02-.08-.02-.03,0-.07-.02-.1-.02-.36-.02-.72-.09-1.08-.08-.08,0-.48-.1-.57-.12-.05-.01-.23-.14-.27-.13Z"
      />
      <path
        stroke={iconColorStyle[color]}
        strokeWidth={1}
        className="cls-1"
        d="m96.07,21.63c-.09-.07-.1-.21-.11-.33,0-.04.05-.09.09-.08.08.02.16.04.22.05.05,0,.66.3.84.43.19.11.36.24.53.37l1.04.8c.08.06.15.13.21.2.21.26.46.48.73.68.05.03.08.08.15.08.12,0,.82.59.87.71.16.26.33.5.49.76.02.04.07.06.05.12-.03.15.05.2.1.27.06.11.12.23.17.36.02.06,0,.16,0,.24,0,.07-.14.16-.16.16-.08,0-.1.08-.15.13-.02.02-.04.04-.05.05-.02,0-.83-.2-.85-.28,0,0-.2-.18-.4-.37-.2-.18-.42-.37-.47-.4-.05-.04-.07-.1-.1-.16-.01-.03-.63-.57-.65-.6-.11-.14-.25-.23-.3-.43-.01-.04-.04-.06-.06-.08-.21-.2-.39-.44-.58-.67-.1-.12-.21-.22-.31-.33-.02-.02-.03-.05-.04-.07-.02-.03-.04-.06-.06-.09-.25-.25-.48-.54-.75-.77-.06-.05-.28-.4-.34-.47-.04-.04-.08-.25-.12-.28Z"
      />
      <path
        stroke={iconColorStyle[color]}
        strokeWidth={1}
        className="cls-1"
        d="m96.42,26.44c-.05.12-.22.11-.41.05-.06-.02-.19-.14-.18-.19,0-.09.01-.18.03-.26.05-.11.31-.69.41-.92.11-.2.25-.37.4-.54l.89-1.03c.07-.08.14-.14.23-.2.3-.19.53-.45.74-.72.04-.05.08-.08.08-.16,0-.12.62-.82.73-.89.31-.19.53-.33.8-.49.04-.02.06-.07.13-.06.16.03.21-.05.28-.11.1-.08.21-.16.33-.22.06-.03.16-.02.25-.03.08,0,.2.15.21.18,0,.08.11.11.17.17.02.02.05.04.06.06.03.04-.22.82-.27.88-.01.01-.64.85-.74.98-.04.05-.11.08-.17.11-.03.02-.56.7-.59.72-.14.12-.23.28-.44.32-.04,0-.06.04-.09.07-.21.23-.46.42-.7.62-.12.1-.22.22-.34.33-.02.02-.05.03-.08.04-.03.02-.07.03-.09.06-.26.27-.56.5-.79.79-.04.08-.48.23-.54.31-.04.04-.29.05-.31.1Z"
      />
      <path
        stroke={iconColorStyle[color]}
        strokeWidth={1}
        className="cls-1"
        d="m113.79,13.03c0-.1.04-.19.09-.26.02-.02.06-.02.07,0,.02.06.04.13.05.17,0,.04.11.59.17.78.05.18.1.37.09.55,0,.37.02.75.03,1.13,0,.08,0,.17-.02.25-.04.29-.05.57-.03.86,0,.05,0,.1.03.14.04.08.06.86.04.96-.05.3-.08.5-.13.75,0,.04,0,.07-.03.1-.08.07-.07.15-.08.23-.01.11-.03.21-.06.32-.01.05-.06.1-.1.15-.03.05-.15.01-.16,0-.04-.05-.09-.01-.14-.01-.02,0-.04,0-.05,0-.03,0-.23-.63-.23-.7-.01-.02-.03-.91-.04-1.01,0-.05.02-.11.03-.16,0-.04-.02-.74-.02-.77.02-.15,0-.3.07-.44.01-.03.01-.06.01-.09,0-.25.04-.51.06-.76.01-.13.01-.26.02-.39,0-.02.01-.05.02-.07,0-.03.02-.06.02-.09,0-.31.04-.62.09-.92.01-.07.11-.41.12-.49,0-.05.08-.2.07-.24Z"
      />
      <path
        stroke={iconColorStyle[color]}
        strokeWidth={1}
        className="cls-1"
        d="m111.06,16.24c-.1.01-.18-.06-.25-.15-.02-.03-.02-.1.01-.12.06-.03.12-.07.16-.09.03-.03.57-.18.75-.2.18-.04.35-.06.53-.07.36-.02.71-.07,1.07-.07.08,0,.16,0,.24.02.27.06.54.06.81.04.05,0,.09,0,.14-.03.07-.05.81-.06.9-.04.24.06.47.1.71.15.03,0,.07,0,.1.03.07.09.14.08.22.08.1,0,.2.02.3.06.05.02.1.07.15.11.04.04.02.18,0,.2-.05.04-.01.11,0,.17,0,.02,0,.05,0,.06,0,.04-.59.26-.66.26-.01,0-.82.05-.96.05-.05,0-.1-.02-.15-.03-.04,0-.71.04-.73.04-.14-.02-.28.01-.42-.07-.03-.02-.06-.01-.08-.01-.24,0-.48-.03-.73-.06-.15-.01-.25-.02-.37-.03-.02,0-.04-.01-.07-.02-.03,0-.06-.02-.08-.02-.29-.02-.59-.07-.88-.07-.07,0-.39-.08-.47-.1-.04,0-.18-.12-.22-.11Z"
      />
      <path
        stroke={iconColorStyle[color]}
        strokeWidth={1}
        className="cls-1"
        d="m111.96,14.4c-.07-.06-.08-.17-.09-.27,0-.03.04-.07.07-.07.07.02.13.03.18.04.04,0,.54.24.69.34.15.09.3.19.43.3l.85.65c.06.05.12.1.17.17.17.21.38.39.6.55.04.03.07.06.13.06.09,0,.67.48.71.57.16.25.27.41.39.62.02.03.05.05.04.1-.03.13.04.16.08.22.05.09.1.19.13.29.02.05,0,.13,0,.2,0,.06-.11.13-.14.13-.06,0-.09.07-.12.11-.01.01-.03.03-.04.04-.02,0-.68-.16-.7-.22,0,0-.16-.14-.32-.3-.17-.15-.34-.3-.38-.33-.04-.03-.06-.08-.08-.13-.01-.02-.51-.46-.53-.48-.09-.11-.2-.19-.24-.35,0-.03-.03-.05-.05-.07-.17-.17-.32-.36-.47-.55-.08-.1-.17-.18-.25-.27-.02-.02-.02-.04-.04-.06-.02-.02-.03-.05-.05-.07-.21-.2-.39-.44-.61-.63-.05-.04-.23-.32-.28-.38-.03-.03-.07-.2-.1-.23Z"
      />
      <path
        stroke={iconColorStyle[color]}
        strokeWidth={1}
        className="cls-1"
        d="m112.25,18.3c-.04.1-.18.09-.34.03-.05-.02-.16-.12-.15-.16,0-.07,0-.15.02-.21.04-.1.24-.56.33-.75.09-.16.2-.3.32-.44l.72-.84c.05-.06.12-.12.19-.16.24-.16.43-.36.6-.58.03-.04.07-.07.07-.13,0-.1.51-.67.6-.72.26-.16.44-.27.65-.4.03-.02.05-.06.1-.05.13.02.17-.04.23-.09.08-.07.17-.13.27-.18.05-.02.13-.02.2-.02.07,0,.17.13.17.15,0,.07.09.1.14.14.02.01.04.03.05.05.03.03-.17.67-.22.72,0,.01-.51.68-.6.8-.03.04-.09.06-.13.09-.03.03-.46.57-.48.59-.11.09-.19.22-.36.26-.03,0-.05.03-.07.05-.17.19-.37.34-.57.5-.1.08-.18.18-.27.27-.02.02-.04.02-.06.04-.03.02-.06.03-.08.05-.21.22-.46.4-.64.64-.03.07-.39.19-.44.25-.03.03-.24.04-.25.08Z"
      />
      <path
        stroke={iconColorStyle[color]}
        strokeWidth={1}
        className="cls-1"
        d="m56.47,21.03c0-.11.04-.2.09-.28.02-.03.06-.02.07,0,.02.07.04.13.05.18,0,.04.11.63.17.83.05.19.1.39.09.58,0,.39.02.79.03,1.19,0,.09,0,.18-.02.26-.04.3-.05.6-.03.9,0,.05,0,.1.03.15.04.08.06.9.04,1.01-.05.31-.08.53-.13.79,0,.04,0,.08-.03.11-.08.08-.07.16-.08.24-.01.11-.03.22-.06.34-.01.06-.06.11-.1.16-.03.05-.15.01-.16,0-.04-.05-.09-.01-.14-.01-.02,0-.04,0-.05,0-.03,0-.23-.66-.23-.73-.01-.02-.02-.96-.04-1.06,0-.06.02-.11.03-.17,0-.05-.02-.78-.02-.81.02-.16,0-.31.07-.46.01-.03.01-.06.01-.09,0-.27.04-.53.07-.8.01-.14.01-.27.02-.41,0-.02.01-.05.02-.07,0-.03.02-.06.02-.09,0-.32.04-.65.09-.97.01-.07.11-.43.12-.52,0-.05.08-.21.07-.25Z"
      />
      <path
        stroke={iconColorStyle[color]}
        strokeWidth={1}
        className="cls-1"
        d="m53.59,24.39c-.1.01-.18-.06-.26-.15-.02-.03-.02-.1.01-.12.07-.03.13-.07.17-.09.04-.03.6-.18.79-.2.18-.04.37-.06.55-.07.37-.02.75-.07,1.12-.07.08,0,.17,0,.25.02.28.06.57.06.85.04.05,0,.1,0,.14-.03.08-.05.85-.06.95-.04.25.06.5.1.74.15.04,0,.07,0,.11.03.07.09.15.08.23.08.11,0,.21.02.32.06.05.02.1.07.15.11.05.04.02.18,0,.2-.05.04-.01.11,0,.17,0,.02,0,.05,0,.06,0,.04-.62.26-.69.26-.01,0-.87.05-1.01.05-.05,0-.11-.02-.16-.03-.04,0-.75.04-.77.04-.15-.02-.3.01-.44-.07-.03-.02-.06-.01-.09-.01-.26,0-.51-.03-.76-.06-.15-.01-.26-.02-.39-.03-.02,0-.05-.01-.07-.02-.03,0-.06-.02-.09-.02-.31-.02-.62-.07-.92-.07-.07,0-.41-.08-.49-.1-.05,0-.19-.12-.23-.11Z"
      />
      <path
        stroke={iconColorStyle[color]}
        strokeWidth={1}
        className="cls-1"
        d="m54.53,22.46c-.08-.06-.09-.18-.1-.28,0-.03.04-.07.07-.07.07.02.14.04.19.05.05,0,.56.26.72.37.16.09.31.2.45.32l.89.69c.07.05.12.11.18.17.18.22.4.41.63.58.04.03.07.06.13.07.1,0,.7.51.74.61.17.26.28.43.42.64.02.03.06.05.05.1-.03.13.04.17.09.23.06.1.1.2.14.3.02.05,0,.13.01.2,0,.06-.11.13-.14.13-.06,0-.09.07-.12.11-.01.01-.03.03-.04.04-.02,0-.7-.18-.72-.25,0,0-.17-.15-.34-.31-.17-.16-.36-.32-.4-.35-.04-.03-.06-.09-.09-.13-.01-.02-.54-.49-.56-.51-.09-.12-.21-.2-.26-.36,0-.03-.03-.05-.05-.07-.18-.17-.33-.38-.5-.57-.08-.1-.18-.19-.27-.28-.02-.02-.03-.04-.04-.06-.02-.03-.03-.05-.05-.07-.22-.21-.41-.46-.64-.66-.05-.04-.24-.34-.3-.4-.03-.03-.07-.21-.1-.23Z"
      />
      <path
        stroke={iconColorStyle[color]}
        strokeWidth={1}
        className="cls-1"
        d="m54.82,26.56c-.05.1-.18.09-.35.04-.05-.02-.16-.12-.15-.16,0-.07.01-.15.02-.22.05-.1.26-.58.35-.78.1-.17.22-.32.34-.46l.76-.88c.06-.06.12-.12.2-.17.25-.17.45-.38.63-.62.03-.04.07-.07.07-.13,0-.1.54-.7.63-.76.27-.17.45-.29.68-.42.03-.02.05-.06.11-.05.14.02.18-.05.24-.1.09-.07.18-.14.28-.19.05-.03.14-.02.21-.03.07,0,.17.13.17.15,0,.07.09.1.14.14.02.01.04.03.05.05.03.03-.19.69-.24.74,0,.01-.55.72-.64.84-.03.05-.09.07-.14.1-.03.04-.49.6-.5.62-.12.1-.2.24-.38.28-.03,0-.05.03-.07.06-.18.2-.39.36-.59.53-.1.09-.19.19-.29.29-.02.02-.04.02-.07.04-.03.02-.06.03-.08.05-.22.23-.48.43-.68.68-.03.07-.4.2-.46.27-.04.03-.25.05-.26.09Z"
      />
      <path
        stroke={iconColorStyle[color]}
        strokeWidth={1}
        className="cls-1"
        d="m118.27,41.42c-.01-.15.05-.27.11-.39.02-.04.08-.03.1.01.03.09.06.19.08.25.01.05.19.87.28,1.15.08.27.16.54.15.81l.07,1.66c0,.12,0,.25-.02.37-.06.42-.06.84-.05,1.26,0,.07,0,.14.03.21.06.11.06,1.26.03,1.4-.08.42-.14.73-.21,1.09-.01.05,0,.11-.05.15-.12.1-.11.22-.12.33-.02.16-.05.31-.1.47-.02.08-.09.15-.14.22-.05.07-.21.02-.22,0-.05-.07-.13-.02-.19-.02-.02,0-.05,0-.07,0-.04,0-.29-.92-.29-1.02-.02-.03,0-1.34-.01-1.49,0-.08.02-.16.05-.23,0-.06,0-1.09,0-1.13.02-.22,0-.43.1-.65.02-.04.02-.09.02-.13,0-.37.05-.74.09-1.12.02-.19.01-.38.02-.57,0-.03.01-.07.02-.1,0-.04.02-.09.02-.13,0-.45.03-.9.09-1.36.01-.1.14-.6.14-.72,0-.07.1-.29.09-.35Z"
      />
      <path
        stroke={iconColorStyle[color]}
        strokeWidth={1}
        className="cls-1"
        d="m114.24,46.11c-.14.02-.26-.09-.36-.21-.03-.04-.03-.14.02-.17.09-.05.18-.09.24-.13.06-.02.25-.08.47-.15.24-.06.5-.09.63-.13.26-.05.51-.08.77-.09.52-.03,1.05-.09,1.57-.1.12,0,.23,0,.35.03.4.08.79.08,1.19.05.07,0,.13,0,.2-.04.11-.07,1.19-.09,1.32-.05.35.08.69.14,1.04.21.05.01.1,0,.15.05.1.13.21.11.32.12.15.01.3.03.45.08.07.02.14.1.22.16.06.06.03.26,0,.28-.07.06-.02.16-.01.24,0,.03,0,.06,0,.09,0,.05-.87.37-.96.36-.02,0-1.23.07-1.42.08-.07,0-.15-.02-.22-.04-.05,0-1.04.05-1.08.05-.21-.02-.41.02-.62-.1-.04-.02-.08-.02-.12-.02-.36,0-.71-.05-1.07-.09-.18-.02-.36-.03-.54-.04-.03,0-.07-.02-.1-.03-.04-.01-.08-.03-.12-.03-.43-.02-.86-.1-1.29-.09-.1,0-.57-.11-.68-.14-.06-.01-.27-.17-.33-.15Z"
      />
      <path
        stroke={iconColorStyle[color]}
        strokeWidth={1}
        className="cls-1"
        d="m115.56,43.41c-.11-.09-.12-.24-.13-.39,0-.05.06-.1.1-.09.1.03.19.05.26.07.06,0,.79.36,1,.51.23.13.43.28.63.44l1.24.96c.09.07.17.15.25.24.25.31.55.57.88.81.05.04.1.09.18.1.14,0,.97.71,1.04.84.19.31.4.6.59.9.03.04.08.07.07.14-.04.18.06.24.12.32.08.13.15.27.2.43.03.07.01.19.01.28,0,.09-.16.18-.19.18-.09,0-.12.09-.17.15-.02.02-.04.04-.06.06-.03.01-.98-.25-1.01-.34,0,0-.23-.22-.48-.44-.24-.22-.5-.44-.55-.48-.06-.04-.09-.12-.12-.19-.02-.03-.75-.68-.77-.71-.13-.16-.3-.28-.36-.51-.01-.04-.04-.07-.07-.1-.25-.24-.47-.53-.69-.8-.12-.14-.25-.26-.37-.39-.02-.02-.04-.06-.05-.09-.02-.04-.04-.07-.07-.1-.3-.3-.57-.64-.89-.92-.08-.06-.34-.47-.41-.56-.05-.04-.1-.29-.14-.33Z"
      />
      <path
        stroke={iconColorStyle[color]}
        strokeWidth={1}
        className="cls-1"
        d="m115.97,49.13c-.07.14-.26.13-.49.06-.07-.02-.22-.16-.21-.22,0-.1.02-.21.03-.31.06-.13.37-.82.49-1.09.13-.24.3-.44.48-.64l1.06-1.23c.08-.09.17-.17.27-.24.35-.23.63-.53.89-.86.04-.05.1-.1.1-.19,0-.14.75-.98.88-1.05.37-.23.63-.4.94-.59.05-.03.07-.08.15-.07.19.03.25-.07.34-.14.12-.1.25-.19.39-.27.07-.04.19-.03.29-.04.09,0,.24.18.24.21,0,.1.12.13.2.2.03.02.06.05.08.07.04.04-.27.97-.33,1.04-.01.02-.77,1.02-.89,1.17-.04.06-.12.09-.2.13-.03.02-.67.84-.7.86-.16.14-.28.33-.52.39-.05.01-.07.05-.1.08-.24.27-.54.5-.83.74-.15.12-.27.27-.4.4-.02.02-.06.03-.09.05-.04.02-.08.04-.11.07-.3.32-.67.59-.94.95-.05.1-.57.28-.64.38-.05.04-.34.07-.37.12Z"
      />
    </svg>
  );
}
