import React, { type ChangeEventHandler, forwardRef } from 'react';
import uuid from 'react-uuid';

import { Tooltip } from '@mui/material';

import { PelletInformation } from 'components/specks/Pellet/Pellet';

import concatClassNames from 'utils/classNames';
import getIcon from 'utils/getIcon';

import { Text, type TextSize, textSizeStyle } from '../Text/Text';

export interface RadioButtonsProps {
  name: string;
  values: string[];
  valuesDisplay?: string[];
  isComplete?: boolean;
  error?: string;
  textSize?: TextSize;
  label?: string;
  labelSize?: TextSize;
  information?: string;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  selected?: string;
  loading?: boolean;
  isEditable?: boolean;
}

function RadioButtons(
  {
    name,
    values = [],
    valuesDisplay,
    isComplete = true,
    error = '',
    textSize = 'base',
    label,
    labelSize = 'lg',
    information,
    onChange,
    selected,
    loading = false,
    isEditable = false,
  }: RadioButtonsProps,
  ref: React.Ref<HTMLInputElement>,
): JSX.Element {
  /* ------------------------------------------------------ JSX ----------------------------------------------------- */

  return loading ? (
    <div
      className={concatClassNames(
        'w-full',
        'h-16',
        'flex',
        'flex-row',
        'rounded-lg',
        'p-1',
        'bg-gray-50',
        'animate-pulse',
      )}
    ></div>
  ) : (
    <div className={concatClassNames('flex', 'flex-col', 'gap-1')}>
      <div className={concatClassNames('flex flex-row', 'gap-2', 'items-center')}>
        {label !== undefined && (
          <Text position="left" size={labelSize} color="gray-700" weight="medium" content={label} />
        )}
        <Tooltip title={information} placement="right">
          <div>{information !== undefined && <>{getIcon('information', 'gradient2-to', 'smd')}</>}</div>
        </Tooltip>
      </div>
      <div className={concatClassNames('w-full', 'h-12', 'flex', 'flex-row', 'rounded-lg', 'bg-gray-25')}>
        {values.map((value: string, index) => {
          return (
            <div
              key={uuid()}
              className={concatClassNames(
                'flex',
                'flex-row',
                'justify-center',
                'items-center',
                'w-full',
                selected === value ? 'bg-gradient-to-r from-gradient2-from to-gradient2-to' : '',
                'rounded-md',
                'my-1',
              )}
            >
              <label
                className={concatClassNames(
                  'flex',
                  'w-full',
                  'h-full',
                  'text-center',
                  selected === value ? 'text-white' : 'text-gray-100',
                  isEditable ? 'cursor-pointer' : '',
                  'items-center',
                  'justify-center',
                  textSizeStyle[textSize],
                )}
              >
                <div className={concatClassNames('flex flex-row', 'gap-2')}>
                  {valuesDisplay !== undefined ? valuesDisplay[index] : value}

                  {!isComplete && selected === value && <PelletInformation text={error} />}
                </div>

                <input
                  type="radio"
                  id={value}
                  name={name}
                  value={value}
                  className={concatClassNames('hidden', 'opacity-0', isEditable ? 'cursor-pointer' : '')}
                  checked={selected === value}
                  onChange={isEditable ? onChange : undefined}
                  ref={ref}
                  readOnly={!isEditable}
                />
              </label>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default forwardRef<HTMLInputElement, RadioButtonsProps>(RadioButtons);
