import { SpecksApisContext } from 'App';

import React, { createContext, useContext, useEffect } from 'react';
import { type QueryClient, useMutation, useQuery, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';

import { useAuth0 } from '@auth0/auth0-react';

import { type ResourceHandler, useResourceHandler } from 'components/specks/Widgets/ApiWidget';

import { type MethodService } from 'services/method.service';

import { type Body } from 'models/API/Body.entity';
import { type ApiHeaderParameter } from 'models/API/Parameters/apiHeaderParameter.entity';
import { type ApiPathParameter } from 'models/API/Parameters/apiPathParameter.entity';
import { type ApiQueryParameter } from 'models/API/Parameters/apiQueryParameter.entity';
import { type ApiResponse } from 'models/ApiResponse.entity';
import { type Method } from 'models/method.entity';

import concatClassNames from 'utils/classNames';

import { ComponentBackEndApiDetailHeader } from './Header';
import { ComponentBackEndApiDetailParameters } from './Parameters';
import { ComponentBackEndApiDetailResponses } from './Responses';

/* ----------------------------------------------- ApiMethodProvider ---------------------------------------------- */

interface RestApiProviderProps {
  children: JSX.Element[] | JSX.Element;
  handler: RestApiHandler;
}

export interface RestApiHandler {
  deletePathParameter: (pathParameterId: string) => void;
  deleteQueryParameter: (queryParameterId: string) => void;
  deleteHeaderParameter: (headerParameterId: string) => void;
  deleteBodyParameter: (bodyParameterId: string, path: string[]) => void;
  deleteResponse: (responseId: string) => void;
  deleteBodyResponse: (bodyResponseId: string, path: string[], responseFunctionalId: string) => void;
}

export const RestApiContext: React.Context<RestApiHandler> = createContext({} as RestApiHandler);

export function useRestApiHandler(): RestApiHandler {
  return useContext(RestApiContext);
}

export function RestApiProvider({ children, handler }: RestApiProviderProps): JSX.Element {
  return <RestApiContext.Provider value={handler}>{children}</RestApiContext.Provider>;
}

/* --------------------------------------------------- Component -------------------------------------------------- */
interface ComponentBackEndApiDetailProps {
  selectedMethodId?: string;
  isEditable?: boolean;
  onDelete?: (methodId: string) => void;
  onEdit?: (methodId: string) => void;
}

export function ComponentBackEndApiDetail({
  selectedMethodId,
  isEditable = false,
  onDelete,
  onEdit,
}: ComponentBackEndApiDetailProps): JSX.Element {
  /* --------------------------------------------------- contexts --------------------------------------------------- */

  const methodService: MethodService = useContext(SpecksApisContext).methodService;
  const queryClient: QueryClient = useQueryClient();
  const { getAccessTokenSilently } = useAuth0();
  const resourceHandler: ResourceHandler = useResourceHandler();

  /* ---------------------------------------------------- states ---------------------------------------------------- */

  // TODO : Delete copy states
  const [pathParameters, setPathParameters] = React.useState<ApiPathParameter[]>([]);
  const [copyPathParameters, setCopyPathParameters] = React.useState<ApiPathParameter[]>([]);
  const [queryParameters, setQueryParameters] = React.useState<ApiQueryParameter[]>([]);
  const [copyQueryParameters, setCopyQueryParameters] = React.useState<ApiQueryParameter[]>([]);
  const [headerParameters, setHeaderParameters] = React.useState<ApiHeaderParameter[]>([]);
  const [copyHeaderParameters, setCopyHeaderParameters] = React.useState<ApiHeaderParameter[]>([]);
  const [bodyParameters, setBodyParameters] = React.useState<Body[]>([]);
  const [copyBodyParameters, setCopyBodyParameters] = React.useState<Body[]>([]);
  const [responses, setResponses] = React.useState<ApiResponse[]>([]);
  const [copyResponses, setCopyResponses] = React.useState<ApiResponse[]>([]);

  /* ---------------------------------------------------- queries --------------------------------------------------- */

  const { data: methodData, status: methodStatus } = useQuery<Method, Error>({
    queryKey: ['method', selectedMethodId],
    queryFn: async ({ queryKey }) => {
      const [, selectedMethodId] = queryKey;
      const accessToken: string = await getAccessTokenSilently();
      return await methodService.findById(selectedMethodId as string, accessToken);
    },
    enabled: selectedMethodId !== undefined,
  });

  /* -------------------------------------------------- Setup Data -------------------------------------------------- */

  useEffect(() => {
    if (methodStatus === 'success') {
      setPathParameters(methodData.methodRevisions[0].apiPathParameters);
      setCopyPathParameters(methodData.methodRevisions[0].apiPathParameters);
      setQueryParameters(methodData.methodRevisions[0].apiQueryParameters);
      setCopyQueryParameters(methodData.methodRevisions[0].apiQueryParameters);
      setHeaderParameters(methodData.methodRevisions[0].apiHeaderParameters);
      setCopyHeaderParameters(methodData.methodRevisions[0].apiHeaderParameters);
      setBodyParameters(methodData.methodRevisions[0].apiBodyParameter);
      setCopyBodyParameters(methodData.methodRevisions[0].apiBodyParameter);
      setResponses(methodData.methodRevisions[0].apiResponses);
      setCopyResponses(methodData.methodRevisions[0].apiResponses);
    }
  }, [methodData, methodStatus]);

  useEffect(() => {
    if (!isEditable) {
      setCopyPathParameters(pathParameters);
      setCopyQueryParameters(queryParameters);
      setCopyHeaderParameters(headerParameters);
      setCopyBodyParameters(bodyParameters);
      setCopyResponses(responses);
    }
  }, [bodyParameters, headerParameters, isEditable, pathParameters, queryParameters, responses]);

  /* ----------------------------------------------------- Save ----------------------------------------------------- */

  async function updateMethod(newMethod: Method): Promise<Method> {
    if (selectedMethodId === undefined) {
      throw new Error('No method selected');
    }
    const accessToken: string = await getAccessTokenSilently();
    return await methodService.update(selectedMethodId, newMethod, accessToken);
  }

  const { mutate } = useMutation(updateMethod, {
    onSuccess: (getMethod: Method) => {
      queryClient.setQueryData(['method', selectedMethodId], (oldData: Method | undefined) => {
        return getMethod;
      });
      setCopyPathParameters(pathParameters);
      setCopyQueryParameters(queryParameters);
      setCopyHeaderParameters(headerParameters);
      setCopyBodyParameters(bodyParameters);
      setCopyResponses(responses);
      resourceHandler.details.closeUpdateForms();
      resourceHandler.details.setMutateStatus('success');
      resourceHandler.details.setUpdatingBodyData(undefined);
      resourceHandler.details.setApiResponseData(undefined);
      resourceHandler.details.setPathData(undefined);
      resourceHandler.details.setQueryData(undefined);
      resourceHandler.details.setHeaderData(undefined);
      resourceHandler.details.setBodyData(undefined);
      resourceHandler.details.setDeleteRowId(undefined);
      if (resourceHandler.details.successMutateMessage !== undefined) {
        toast.success(resourceHandler.details.successMutateMessage);
        resourceHandler.details.successMutateMessage = undefined;
      }

      if (methodData === undefined) return;
      methodData.methodRevisions = getMethod.methodRevisions;
    },
    onError: () => {
      resourceHandler.details.setMutateStatus('error');
      resourceHandler.details.setDeleteRowId(undefined);
      if (resourceHandler.details.errorMutateMessage !== undefined) {
        toast.error(resourceHandler.details.errorMutateMessage);
        resourceHandler.details.errorMutateMessage = undefined;
      }
    },
  });

  function appendOrUpdatePathParameter(newPathParameter: ApiPathParameter, update: boolean = false): void {
    if (methodData === undefined) {
      throw new Error('No method selected');
    }
    resourceHandler.details.setMutateStatus('loading');
    let newPathParameters: ApiPathParameter[] = structuredClone(methodData.methodRevisions[0].apiPathParameters);
    if (update)
      newPathParameters = newPathParameters.map((pathParameter) => {
        if (pathParameter.id === newPathParameter.id) {
          pathParameter = newPathParameter;
        }
        return pathParameter;
      });
    else newPathParameters.push(newPathParameter);

    mutate({
      id: methodData.id,
      methodRevisions: methodData.methodRevisions.map((methodRevision) => {
        return {
          id: methodRevision.id,
          name: methodRevision.name,
          lastest: methodRevision.lastest,
          methodType: methodRevision.methodType,
          description: methodRevision.description,
          createdAt: methodRevision.createdAt,
          apiPathParameters: newPathParameters,
          apiQueryParameters: methodRevision.apiQueryParameters,
          apiHeaderParameters: methodRevision.apiHeaderParameters,
          apiBodyParameter: methodRevision.apiBodyParameter,
          apiResponses: methodRevision.apiResponses,
        };
      }),
    });
  }

  function appendOrUpdateQueryParameter(newQueryParameter: ApiQueryParameter, update: boolean = false): void {
    if (methodData === undefined) {
      throw new Error('No method selected');
    }
    resourceHandler.details.setMutateStatus('loading');
    let newQueryParameters: ApiQueryParameter[] = structuredClone(methodData.methodRevisions[0].apiQueryParameters);

    if (update)
      newQueryParameters = newQueryParameters.map((queryParameter) => {
        if (queryParameter.id === newQueryParameter.id) {
          queryParameter = newQueryParameter;
        }
        return queryParameter;
      });
    else newQueryParameters.push(newQueryParameter);
    mutate({
      id: methodData.id,
      methodRevisions: methodData.methodRevisions.map((methodRevision) => {
        return {
          id: methodRevision.id,
          name: methodRevision.name,
          lastest: methodRevision.lastest,
          methodType: methodRevision.methodType,
          description: methodRevision.description,
          createdAt: methodRevision.createdAt,
          apiPathParameters: methodRevision.apiPathParameters,
          apiQueryParameters: newQueryParameters,
          apiHeaderParameters: methodRevision.apiHeaderParameters,
          apiBodyParameter: methodRevision.apiBodyParameter,
          apiResponses: methodRevision.apiResponses,
        };
      }),
    });
  }

  function appendOrUpdateHeaderParameter(newHeaderParameter: ApiHeaderParameter, update: boolean = false): void {
    if (methodData === undefined) {
      throw new Error('No method selected');
    }
    resourceHandler.details.setMutateStatus('loading');
    let newHeaderParameters: ApiHeaderParameter[] = structuredClone(methodData.methodRevisions[0].apiHeaderParameters);

    if (update)
      newHeaderParameters = newHeaderParameters.map((headerParameter) => {
        if (headerParameter.id === newHeaderParameter.id) {
          headerParameter = newHeaderParameter;
        }
        return headerParameter;
      });
    else newHeaderParameters.push(newHeaderParameter);
    mutate({
      id: methodData.id,
      methodRevisions: methodData.methodRevisions.map((methodRevision) => {
        return {
          id: methodRevision.id,
          name: methodRevision.name,
          lastest: methodRevision.lastest,
          methodType: methodRevision.methodType,
          description: methodRevision.description,
          createdAt: methodRevision.createdAt,
          apiPathParameters: methodRevision.apiPathParameters,
          apiQueryParameters: methodRevision.apiQueryParameters,
          apiHeaderParameters: newHeaderParameters,
          apiBodyParameter: methodRevision.apiBodyParameter,
          apiResponses: methodRevision.apiResponses,
        };
      }),
    });
  }

  function updateBodyParameters(newBodyParameters: Body[]): void {
    if (methodData === undefined) {
      throw new Error('No method selected');
    }
    resourceHandler.details.setMutateStatus('loading');
    mutate({
      id: methodData.id,
      methodRevisions: methodData.methodRevisions.map((methodRevision) => {
        return {
          id: methodRevision.id,
          name: methodRevision.name,
          lastest: methodRevision.lastest,
          methodType: methodRevision.methodType,
          description: methodRevision.description,
          createdAt: methodRevision.createdAt,
          apiPathParameters: methodRevision.apiPathParameters,
          apiQueryParameters: methodRevision.apiQueryParameters,
          apiHeaderParameters: methodRevision.apiHeaderParameters,
          apiBodyParameter: newBodyParameters,
          apiResponses: methodRevision.apiResponses,
        };
      }),
    });
  }

  function handleOnClickCreateNewResponse(newResponse: ApiResponse, update: boolean = false): void {
    if (methodData === undefined) {
      throw new Error('No method selected');
    }
    resourceHandler.details.setMutateStatus('loading');
    resourceHandler.details.setNewlyCreatedResponseRow(newResponse.fonctionalId);
    mutate({
      id: methodData.id,
      methodRevisions: methodData.methodRevisions.map((methodRevision) => {
        return {
          id: methodRevision.id,
          name: methodRevision.name,
          lastest: methodRevision.lastest,
          methodType: methodRevision.methodType,
          description: methodRevision.description,
          createdAt: methodRevision.createdAt,
          apiPathParameters: methodRevision.apiPathParameters,
          apiQueryParameters: methodRevision.apiQueryParameters,
          apiHeaderParameters: methodRevision.apiHeaderParameters,
          apiBodyParameter: methodRevision.apiBodyParameter,
          apiResponses: update
            ? methodRevision.apiResponses.map((response: ApiResponse) => {
                if (response.fonctionalId === newResponse.fonctionalId) {
                  response = newResponse;
                }
                return response;
              })
            : [...methodRevision.apiResponses, newResponse],
        };
      }),
    });
  }

  function handleOnClickCreateNewBodyResponse(responseId: string, newBodyParameters: Body[]): void {
    if (methodData === undefined) {
      throw new Error('No method selected');
    }
    resourceHandler.details.setMutateStatus('loading');
    mutate({
      id: methodData.id,
      methodRevisions: methodData.methodRevisions.map((methodRevision) => {
        return {
          id: methodRevision.id,
          name: methodRevision.name,
          lastest: methodRevision.lastest,
          methodType: methodRevision.methodType,
          description: methodRevision.description,
          createdAt: methodRevision.createdAt,
          apiPathParameters: methodRevision.apiPathParameters,
          apiQueryParameters: methodRevision.apiQueryParameters,
          apiHeaderParameters: methodRevision.apiHeaderParameters,
          apiBodyParameter: methodRevision.apiBodyParameter,
          apiResponses: methodRevision.apiResponses.map((response: ApiResponse) => {
            return {
              id: response.id,
              fonctionalId: response.fonctionalId,
              statusCode: response.statusCode,
              message: response.message,
              responseType: response.responseType,
              apiResponseBody: responseId === response.id ? newBodyParameters : response.apiResponseBody,
            };
          }),
        };
      }),
    });
  }

  /* ------------------------------------------------- Setup handler ------------------------------------------------ */

  const { mutate: mutateDeleteBodyParameter } = useMutation(updateMethod, {
    onSuccess: (getMethod: Method) => {
      queryClient.setQueryData(['method', selectedMethodId], (oldData: Method | undefined) => {
        return getMethod;
      });
      setCopyBodyParameters(bodyParameters);
      toast.success(' Body parameter bien supprimé');
      if (methodData === undefined) return;
      methodData.methodRevisions = getMethod.methodRevisions;
    },
    onError: () => {
      toast.error('Erreur lors de la suppression du body parameter');
    },
  });

  const { mutate: mutateDeleteReponse } = useMutation(updateMethod, {
    onSuccess: (getMethod: Method) => {
      queryClient.setQueryData(['method', selectedMethodId], (oldData: Method | undefined) => {
        return getMethod;
      });
      setCopyBodyParameters(bodyParameters);
      toast.success('Réponse bien supprimée');
      if (methodData === undefined) return;
      methodData.methodRevisions = getMethod.methodRevisions;
    },
    onError: () => {
      toast.error('Erreur lors de la suppression de Réponse');
    },
  });

  const { mutate: mutateDeleteBodyReponse } = useMutation(updateMethod, {
    onSuccess: (getMethod: Method) => {
      queryClient.setQueryData(['method', selectedMethodId], (oldData: Method | undefined) => {
        return getMethod;
      });
      setCopyBodyParameters(bodyParameters);
      toast.success("L'attribut a bien été supprimé.");
      if (methodData === undefined) return;
      methodData.methodRevisions = getMethod.methodRevisions;
    },
    onError: () => {
      toast.error('Erreur lors de la suppression du body réponse');
    },
  });

  function removeBody(body: Body[], path: string[]): Body[] {
    if (path.length === 1) {
      // If we've reached the end of the path, remove the element
      return body.filter((b) => b.id !== path[0]);
    }

    const [current, ...rest] = path;
    const childIndex: number = body.findIndex((b) => b.id === current);
    if (childIndex === -1) return body;
    const updatedParent: Body | undefined = body.find((b) => b.id === current);

    if (updatedParent === undefined) return body;
    updatedParent.children = removeBody(updatedParent.children ?? [], rest);
    return [...body.slice(0, childIndex), updatedParent, ...body.slice(childIndex + 1)];
  }

  const restApiHandler: RestApiHandler = {
    deletePathParameter: (pathParameterId: string) => {
      if (methodData === undefined) {
        throw new Error('No method selected');
      }
      resourceHandler.details.setDeleteRowId(pathParameterId);
      resourceHandler.details.setMutateStatus('loading');
      resourceHandler.details.successMutateMessage = "Le paramètre d'URL a bien été supprimé";
      resourceHandler.details.errorMutateMessage = "Erreur lors de la suppression du paramètre d'URL";
      mutate({
        id: methodData.id,
        methodRevisions: methodData.methodRevisions.map((methodRevision) => {
          return {
            id: methodRevision.id,
            name: methodRevision.name,
            lastest: methodRevision.lastest,
            methodType: methodRevision.methodType,
            description: methodRevision.description,
            createdAt: methodRevision.createdAt,
            apiPathParameters: methodRevision.apiPathParameters.filter(
              (apiPathParameter) => apiPathParameter.id !== pathParameterId,
            ),
            apiQueryParameters: methodRevision.apiQueryParameters,
            apiHeaderParameters: methodRevision.apiHeaderParameters,
            apiBodyParameter: methodRevision.apiBodyParameter,
            apiResponses: methodRevision.apiResponses,
          };
        }),
      });
    },
    deleteQueryParameter: (queryParameterId: string) => {
      if (methodData === undefined) {
        throw new Error('No method selected');
      }
      mutate({
        id: methodData.id,
        methodRevisions: methodData.methodRevisions.map((methodRevision) => {
          return {
            id: methodRevision.id,
            name: methodRevision.name,
            lastest: methodRevision.lastest,
            methodType: methodRevision.methodType,
            description: methodRevision.description,
            createdAt: methodRevision.createdAt,
            apiPathParameters: methodRevision.apiPathParameters,
            apiQueryParameters: methodRevision.apiQueryParameters.filter(
              (apiQueryParameter) => apiQueryParameter.id !== queryParameterId,
            ),
            apiHeaderParameters: methodRevision.apiHeaderParameters,
            apiBodyParameter: methodRevision.apiBodyParameter,
            apiResponses: methodRevision.apiResponses,
          };
        }),
      });
      toast.success('Query parameter bien supprimé');
    },
    deleteHeaderParameter: (headerParameterId: string) => {
      if (methodData === undefined) {
        throw new Error('No method selected');
      }
      mutate({
        id: methodData.id,
        methodRevisions: methodData.methodRevisions.map((methodRevision) => {
          return {
            id: methodRevision.id,
            name: methodRevision.name,
            lastest: methodRevision.lastest,
            methodType: methodRevision.methodType,
            description: methodRevision.description,
            createdAt: methodRevision.createdAt,
            apiPathParameters: methodRevision.apiPathParameters,
            apiQueryParameters: methodRevision.apiQueryParameters,
            apiHeaderParameters: methodRevision.apiHeaderParameters.filter(
              (apiHeaderParameter) => apiHeaderParameter.id !== headerParameterId,
            ),
            apiBodyParameter: methodRevision.apiBodyParameter,
            apiResponses: methodRevision.apiResponses,
          };
        }),
      });
      toast.success('Header parameter bien supprimé');
    },
    deleteBodyParameter: (bodyParameterId: string, path: string[]) => {
      if (methodData === undefined) {
        throw new Error('No method selected');
      }
      mutateDeleteBodyParameter({
        id: methodData.id,
        methodRevisions: methodData.methodRevisions.map((methodRevision) => {
          return {
            id: methodRevision.id,
            name: methodRevision.name,
            lastest: methodRevision.lastest,
            methodType: methodRevision.methodType,
            description: methodRevision.description,
            createdAt: methodRevision.createdAt,
            apiPathParameters: methodRevision.apiPathParameters,
            apiQueryParameters: methodRevision.apiQueryParameters,
            apiHeaderParameters: methodRevision.apiHeaderParameters,
            apiBodyParameter: removeBody(methodRevision.apiBodyParameter, [...path, bodyParameterId]),
            apiResponses: methodRevision.apiResponses,
          };
        }),
      });
    },
    deleteResponse: (responseId: string) => {
      if (methodData === undefined) {
        throw new Error('No method selected');
      }
      mutateDeleteReponse({
        id: methodData.id,
        methodRevisions: methodData.methodRevisions.map((methodRevision) => {
          return {
            id: methodRevision.id,
            name: methodRevision.name,
            lastest: methodRevision.lastest,
            methodType: methodRevision.methodType,
            description: methodRevision.description,
            createdAt: methodRevision.createdAt,
            apiPathParameters: methodRevision.apiPathParameters,
            apiQueryParameters: methodRevision.apiQueryParameters,
            apiHeaderParameters: methodRevision.apiHeaderParameters,
            apiBodyParameter: methodRevision.apiBodyParameter,
            apiResponses: methodRevision.apiResponses.filter((apiResponse) => apiResponse.fonctionalId !== responseId),
          };
        }),
      });
    },
    deleteBodyResponse: (bodyResponseId: string, path: string[], responseFunctionalId: string) => {
      if (methodData === undefined) {
        throw new Error('No method selected');
      }
      mutateDeleteBodyReponse({
        id: methodData.id,
        methodRevisions: methodData.methodRevisions.map((methodRevision) => {
          return {
            id: methodRevision.id,
            name: methodRevision.name,
            lastest: methodRevision.lastest,
            methodType: methodRevision.methodType,
            description: methodRevision.description,
            createdAt: methodRevision.createdAt,
            apiPathParameters: methodRevision.apiPathParameters,
            apiQueryParameters: methodRevision.apiQueryParameters,
            apiHeaderParameters: methodRevision.apiHeaderParameters,
            apiBodyParameter: methodRevision.apiBodyParameter,
            apiResponses: methodRevision.apiResponses.map((response: ApiResponse) => {
              return {
                id: response.id,
                fonctionalId: response.fonctionalId,
                statusCode: response.statusCode,
                message: response.message,
                responseType: response.responseType,
                apiResponseBody:
                  responseFunctionalId === response.fonctionalId
                    ? removeBody(response.apiResponseBody, [...path, bodyResponseId])
                    : response.apiResponseBody,
              };
            }),
          };
        }),
      });
    },
  };

  /* ------------------------------------------------------ JSX ----------------------------------------------------- */

  // TODO : Add Error state
  return (
    <div
      className={concatClassNames(
        'col-span-5 overflow-auto',
        'flex flex-grow flex-col',
        'bg-gray-15',
        'p-8 pb-28',
        'gap-6',
      )}
    >
      {methodStatus === 'loading' && (
        <>
          <ComponentBackEndApiDetailHeader loading />
          <ComponentBackEndApiDetailParameters />
          <ComponentBackEndApiDetailResponses />
        </>
      )}
      {methodStatus === 'success' && (
        <>
          <RestApiProvider handler={restApiHandler}>
            <ComponentBackEndApiDetailHeader
              name={methodData.methodRevisions[0].name}
              description={methodData.methodRevisions[0].description}
              methodType={methodData.methodRevisions[0].methodType}
              isEditable={isEditable}
              onDelete={() => onDelete?.(selectedMethodId ?? '')}
              onEdit={() => onEdit?.(selectedMethodId ?? '')}
            />
            <ComponentBackEndApiDetailParameters
              pathParameters={copyPathParameters}
              queryParameters={copyQueryParameters}
              headerParameters={copyHeaderParameters}
              bodyParameters={copyBodyParameters}
              isEditable={isEditable}
              methodId={methodData.id}
              createNewPathParameter={appendOrUpdatePathParameter}
              createNewQueryParameter={appendOrUpdateQueryParameter}
              createNewHeaderParameter={appendOrUpdateHeaderParameter}
              updateBodyParameters={updateBodyParameters}
            />
            <ComponentBackEndApiDetailResponses
              responses={copyResponses}
              isEditable={isEditable}
              createNewResponse={handleOnClickCreateNewResponse}
              updateResponseBody={handleOnClickCreateNewBodyResponse}
            />
          </RestApiProvider>
        </>
      )}
    </div>
  );
}
