/* tslint:disable */
/* eslint-disable */
/**
 * Specks API
 * APIs for specks SaaS
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Page } from './Page';
import {
    PageFromJSON,
    PageFromJSONTyped,
    PageToJSON,
} from './Page';
import type { Sitemap } from './Sitemap';
import {
    SitemapFromJSON,
    SitemapFromJSONTyped,
    SitemapToJSON,
} from './Sitemap';

/**
 * 
 * @export
 * @interface SitemapNode
 */
export interface SitemapNode {
    /**
     * 
     * @type {string}
     * @memberof SitemapNode
     */
    id: string;
    /**
     * 
     * @type {Sitemap}
     * @memberof SitemapNode
     */
    sitemap: Sitemap;
    /**
     * 
     * @type {Page}
     * @memberof SitemapNode
     */
    page: Page;
}

/**
 * Check if a given object implements the SitemapNode interface.
 */
export function instanceOfSitemapNode(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "sitemap" in value;
    isInstance = isInstance && "page" in value;

    return isInstance;
}

export function SitemapNodeFromJSON(json: any): SitemapNode {
    return SitemapNodeFromJSONTyped(json, false);
}

export function SitemapNodeFromJSONTyped(json: any, ignoreDiscriminator: boolean): SitemapNode {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'sitemap': SitemapFromJSON(json['sitemap']),
        'page': PageFromJSON(json['page']),
    };
}

export function SitemapNodeToJSON(value?: SitemapNode | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'sitemap': SitemapToJSON(value.sitemap),
        'page': PageToJSON(value.page),
    };
}

