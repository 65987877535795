import React from 'react';

import { withAuthenticationRequired } from '@auth0/auth0-react';

import { LoadingPage } from 'pages/LoadingPage/LoadingPage';

interface AuthenticationGuardProps {
  component: React.ComponentType;
}

export function AuthenticationGuard({ component }: AuthenticationGuardProps): JSX.Element {
  const Component: React.FC = withAuthenticationRequired(component, {
    onRedirecting: () => {
      return <LoadingPage />;
    },
  });
  return <Component />;
}
