/* tslint:disable */
/* eslint-disable */
/**
 * Specks API
 * APIs for specks SaaS
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ErrorBadRequest,
  GetPageIdDto,
  GetUiComponentDto,
  UiComponentControllerUpdateRequest,
} from '../models';
import {
    ErrorBadRequestFromJSON,
    ErrorBadRequestToJSON,
    GetPageIdDtoFromJSON,
    GetPageIdDtoToJSON,
    GetUiComponentDtoFromJSON,
    GetUiComponentDtoToJSON,
    UiComponentControllerUpdateRequestFromJSON,
    UiComponentControllerUpdateRequestToJSON,
} from '../models';

export interface UiComponentControllerFindByIdRequest {
    id: string;
}

export interface UiComponentControllerFindPageByIdRequest {
    id: string;
}

export interface UiComponentControllerUpdateOperationRequest {
    id: string;
    uiComponentControllerUpdateRequest: UiComponentControllerUpdateRequest;
}

/**
 * 
 */
export class UiComponentsApi extends runtime.BaseAPI {

    /**
     */
    async uiComponentControllerFindByIdRaw(requestParameters: UiComponentControllerFindByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetUiComponentDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling uiComponentControllerFindById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/ui-components/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetUiComponentDtoFromJSON(jsonValue));
    }

    /**
     */
    async uiComponentControllerFindById(requestParameters: UiComponentControllerFindByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetUiComponentDto> {
        const response = await this.uiComponentControllerFindByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async uiComponentControllerFindPageByIdRaw(requestParameters: UiComponentControllerFindPageByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetPageIdDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling uiComponentControllerFindPageById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/ui-components/{id}/page`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetPageIdDtoFromJSON(jsonValue));
    }

    /**
     */
    async uiComponentControllerFindPageById(requestParameters: UiComponentControllerFindPageByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetPageIdDto> {
        const response = await this.uiComponentControllerFindPageByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async uiComponentControllerUpdateRaw(requestParameters: UiComponentControllerUpdateOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetUiComponentDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling uiComponentControllerUpdate.');
        }

        if (requestParameters.uiComponentControllerUpdateRequest === null || requestParameters.uiComponentControllerUpdateRequest === undefined) {
            throw new runtime.RequiredError('uiComponentControllerUpdateRequest','Required parameter requestParameters.uiComponentControllerUpdateRequest was null or undefined when calling uiComponentControllerUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/ui-components/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UiComponentControllerUpdateRequestToJSON(requestParameters.uiComponentControllerUpdateRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetUiComponentDtoFromJSON(jsonValue));
    }

    /**
     */
    async uiComponentControllerUpdate(requestParameters: UiComponentControllerUpdateOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetUiComponentDto> {
        const response = await this.uiComponentControllerUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
