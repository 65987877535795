import { AssetsApi } from 'api';

export class AssetsService {
  private readonly assetClient: AssetsApi;

  constructor() {
    this.assetClient = new AssetsApi();
  }

  public async deleteAsset(id: string, accessToken: string): Promise<void> {
    await this.assetClient.assetsControllerDelete(
      { id },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
      },
    );
  }
}
