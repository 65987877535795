/* tslint:disable */
/* eslint-disable */
/**
 * Specks API
 * APIs for specks SaaS
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { GetProductDto } from './GetProductDto';
import {
    GetProductDtoFromJSON,
    GetProductDtoFromJSONTyped,
    GetProductDtoToJSON,
} from './GetProductDto';

/**
 * 
 * @export
 * @interface GetComponentRelatedProductsDto
 */
export interface GetComponentRelatedProductsDto {
    /**
     * 
     * @type {string}
     * @memberof GetComponentRelatedProductsDto
     */
    id: string;
    /**
     * 
     * @type {Array<GetProductDto>}
     * @memberof GetComponentRelatedProductsDto
     */
    relatedProducts: Array<GetProductDto>;
}

/**
 * Check if a given object implements the GetComponentRelatedProductsDto interface.
 */
export function instanceOfGetComponentRelatedProductsDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "relatedProducts" in value;

    return isInstance;
}

export function GetComponentRelatedProductsDtoFromJSON(json: any): GetComponentRelatedProductsDto {
    return GetComponentRelatedProductsDtoFromJSONTyped(json, false);
}

export function GetComponentRelatedProductsDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetComponentRelatedProductsDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'relatedProducts': ((json['relatedProducts'] as Array<any>).map(GetProductDtoFromJSON)),
    };
}

export function GetComponentRelatedProductsDtoToJSON(value?: GetComponentRelatedProductsDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'relatedProducts': ((value.relatedProducts as Array<any>).map(GetProductDtoToJSON)),
    };
}

