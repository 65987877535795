/* tslint:disable */
/* eslint-disable */
/**
 * Specks API
 * APIs for specks SaaS
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { PageRevision } from './PageRevision';
import {
    PageRevisionFromJSON,
    PageRevisionFromJSONTyped,
    PageRevisionToJSON,
} from './PageRevision';
import type { SitemapNode } from './SitemapNode';
import {
    SitemapNodeFromJSON,
    SitemapNodeFromJSONTyped,
    SitemapNodeToJSON,
} from './SitemapNode';

/**
 * 
 * @export
 * @interface Page
 */
export interface Page {
    /**
     * 
     * @type {string}
     * @memberof Page
     */
    id: string;
    /**
     * 
     * @type {SitemapNode}
     * @memberof Page
     */
    sitemapNode: SitemapNode;
    /**
     * 
     * @type {Array<PageRevision>}
     * @memberof Page
     */
    pageRevisions: Array<PageRevision>;
}

/**
 * Check if a given object implements the Page interface.
 */
export function instanceOfPage(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "sitemapNode" in value;
    isInstance = isInstance && "pageRevisions" in value;

    return isInstance;
}

export function PageFromJSON(json: any): Page {
    return PageFromJSONTyped(json, false);
}

export function PageFromJSONTyped(json: any, ignoreDiscriminator: boolean): Page {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'sitemapNode': SitemapNodeFromJSON(json['sitemapNode']),
        'pageRevisions': ((json['pageRevisions'] as Array<any>).map(PageRevisionFromJSON)),
    };
}

export function PageToJSON(value?: Page | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'sitemapNode': SitemapNodeToJSON(value.sitemapNode),
        'pageRevisions': ((value.pageRevisions as Array<any>).map(PageRevisionToJSON)),
    };
}

