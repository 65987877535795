export type CursorTypes =
  | 'none'
  | 'auto'
  | 'default'
  | 'pointer'
  | 'move'
  | 'wait'
  | 'text'
  | 'not-allowed'
  | 'help'
  | 'grab';

export const cursorStyle: Record<CursorTypes, string> = {
  none: 'cursor-none',
  auto: 'cursor-auto',
  default: 'cursor-default',
  pointer: 'cursor-pointer',
  move: 'cursor-move',
  wait: 'cursor-wait',
  text: 'cursor-text',
  'not-allowed': 'cursor-not-allowed',
  help: 'cursor-help',
  grab: 'cursor-grab',
};
