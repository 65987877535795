import React from 'react';

import concatClassNames from 'utils/classNames';

export type TextSize = 'xxxs' | 'xxs' | 'xs' | 'sm' | 'api' | 'base' | 'lg' | '2xl' | 'apiTitleChip';
export type TextWeight = 'light' | 'normal' | 'medium' | 'bold';
export type LetterSpacing = 'tight' | 'normal' | 'wide';
export type TextPosition = 'left' | 'center' | 'right' | 'justify';
export type TextColor =
  | 'gray-100'
  | 'gray-200'
  | 'gray-300'
  | 'gray-400'
  | 'gray-500'
  | 'gray-600'
  | 'gray-700'
  | 'black'
  | 'pink'
  | 'blue'
  | 'yellow'
  | 'red-500'
  | 'red'
  | 'cyan'
  | 'green-500'
  | 'purple-400'
  | 'purple-500'
  | 'gradient2-to'
  | 'error-500'
  | 'orange-500'
  | 'white';

export type TextWhiteSpace = 'pre' | 'pre-line' | 'pre-wrap' | 'normal' | 'nowrap' | 'wrap' | 'wrap-reverse';
export type TextBreak = 'normal' | 'words' | 'all';

export const textSizeStyle: Record<TextSize, string> = {
  xxxs: 'text-[7px]',
  xxs: 'text-[9px]',
  xs: 'text-xs',
  sm: 'text-sm',
  api: 'text-api',
  base: 'text-base',
  lg: 'text-lg',
  '2xl': 'text-2xl',
  apiTitleChip: 'text-apiTitleChip',
};

export const textWeightStyle: Record<TextWeight, string> = {
  light: 'font-light',
  normal: 'font-normal',
  medium: 'font-medium',
  bold: 'font-bold',
};

export const letterSpacingStyle: Record<LetterSpacing, string> = {
  tight: 'tracking-tight',
  normal: 'tracking-normal',
  wide: 'tracking-wide',
};

// TODO : remove gray
export const textColorStyle: Record<TextColor, string> = {
  'gray-100': 'text-gray-100',
  'gray-200': 'text-gray-200',
  'gray-300': 'text-gray-300',
  'gray-400': 'text-gray-400',
  'gray-500': 'text-gray-500',
  'gray-600': 'text-gray-600',
  'gray-700': 'text-gray-700',
  black: 'text-black',
  pink: 'text-pink-600',
  blue: 'text-blue-800',
  yellow: 'text-yellow-400',
  'red-500': 'text-red-500',
  red: 'text-red-700',
  cyan: 'text-cyan-500',
  'green-500': 'text-green-500',
  'purple-400': 'text-purple-400',
  'purple-500': 'text-purple-500',
  'gradient2-to': 'text-gradient2-to',
  'error-500': 'text-error-500',
  'orange-500': 'text-orange-500',
  white: 'text-white',
};

export const textPositionStyle: Record<TextPosition, string> = {
  left: 'text-left',
  center: 'text-center',
  right: 'text-right',
  justify: 'text-justify',
};

export const textWhiteSpaceStyle: Record<TextWhiteSpace, string> = {
  pre: 'whitespace-pre',
  'pre-line': 'whitespace-pre-line',
  'pre-wrap': 'whitespace-pre-wrap',
  normal: 'whitespace-normal',
  nowrap: 'whitespace-nowrap',
  wrap: 'whitespace-wrap',
  'wrap-reverse': 'whitespace-wrap-reverse',
};

export const textBreakStyle: Record<TextBreak, string> = {
  normal: 'break-normal',
  words: 'break-words',
  all: 'break-all',
};

export type TextDecoration = 'underline' | 'underline-4' | 'line-through' | 'no-underline';

export const textDecorationStyle: Record<TextDecoration, string> = {
  underline: 'underline',
  'underline-4': 'underline underline-offset-4',
  'line-through': 'line-through',
  'no-underline': 'no-underline',
};

export type TextMarginTop = 'none' | 'auto';

export const textMarginTop: Record<TextMarginTop, string> = {
  none: 'mt-0',
  auto: 'mt-auto',
};

export type TextMarginRight = 'none' | 'auto';

export const textMarginRight: Record<TextMarginRight, string> = {
  none: 'mr-0',
  auto: 'mr-auto',
};

export type TextMarginBottom = 'none' | 'auto';

export const textMarginBottom: Record<TextMarginBottom, string> = {
  none: 'mb-0',
  auto: 'mb-auto',
};

export type TextMarginLeft = 'none' | 'auto';

export const textMarginLeft: Record<TextMarginLeft, string> = {
  none: 'ml-0',
  auto: 'ml-auto',
};

export type textOverflow = 'none' | 'truncate' | 'ellipsis' | 'clip';

export const textOverflowStyle: Record<textOverflow, string> = {
  none: '',
  truncate: 'truncate',
  ellipsis: 'text-ellipsis overflow-hidden',
  clip: 'text-clip overflow-hidden',
};

export type TextProps =
  | {
      content: string;
      size?: TextSize;
      weight?: TextWeight;
      color?: TextColor;
      tracking?: LetterSpacing;
      position?: TextPosition;
      whitespace?: TextWhiteSpace;
      textBreak?: TextBreak;
      textDecoration?: TextDecoration;
      marginTop?: TextMarginTop;
      marginRight?: TextMarginRight;
      marginBottom?: TextMarginBottom;
      marginLeft?: TextMarginLeft;
      overFlow?: textOverflow;
      hidden?: boolean;
      loading?: false;
    }
  | {
      content?: never;
      size?: never;
      weight?: never;
      color?: never;
      tracking?: never;
      position?: never;
      whitespace?: never;
      textBreak?: never;
      textDecoration?: never;
      marginTop?: never;
      marginRight?: never;
      marginBottom?: never;
      marginLeft?: never;
      overFlow?: never;
      hidden?: boolean;
      loading: true;
    };

export function Text({
  content,
  color = 'black',
  size = 'base',
  weight = 'normal',
  tracking = 'normal',
  position = 'center',
  whitespace = 'pre-line',
  textBreak = 'normal',
  textDecoration = 'no-underline',
  marginTop = 'none',
  marginRight = 'none',
  marginBottom = 'none',
  marginLeft = 'none',
  overFlow = 'none',
  hidden = false,
  loading = false,
}: TextProps): JSX.Element {
  // TODO : Loading with the correct size
  if (loading) {
    return (
      <div className={concatClassNames('w-40', 'h-5', 'my-0.5', 'bg-gray-50', 'rounded-md', 'animate-pulse')}></div>
    );
  } else {
    return (
      <p
        className={concatClassNames(
          textSizeStyle[size],
          textWeightStyle[weight],
          textColorStyle[color],
          letterSpacingStyle[tracking],
          textPositionStyle[position],
          textWhiteSpaceStyle[whitespace],
          textBreakStyle[textBreak],
          textDecorationStyle[textDecoration],
          textMarginTop[marginTop],
          textMarginRight[marginRight],
          textMarginBottom[marginBottom],
          textMarginLeft[marginLeft],
          textOverflowStyle[overFlow],
          'w-fit',
          'max-w-full',
        )}
        hidden={hidden}
      >
        {content}
      </p>
    );
  }
}
