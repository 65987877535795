/* eslint-disable max-len */
import React from 'react';

import concatClassNames from 'utils/classNames';

import { type IconProps, iconColorStyle, iconSizeStyle } from './Icon.props';

export default function DateInputIcon({ color, size }: IconProps): JSX.Element {
  return (
    <svg className={iconSizeStyle[size]} viewBox="0 0 43 39" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.88368 0C11.205 0 12.2726 1.08926 12.2726 2.4375V4.875H21.8281V2.4375C21.8281 1.08926 22.8957 0 24.217 0C25.5384 0 26.6059 1.08926 26.6059 2.4375V4.875H30.1892C32.1675 4.875 33.7726 6.5127 33.7726 8.53125V12.1875H0.328125V8.53125C0.328125 6.5127 1.93316 4.875 3.91146 4.875H7.49479V2.4375C7.49479 1.08926 8.56233 0 9.88368 0ZM0.328125 14.625H33.7726V17.9461L21.9252 30.0346C21.313 30.6592 20.88 31.4438 20.671 32.2969L19.5512 36.8748C19.3795 37.5908 19.4168 38.3221 19.6557 38.9924H3.91146C1.93316 38.9924 0.328125 37.3547 0.328125 35.3361V14.625ZM9.28646 20.7188C8.29358 20.7188 7.49479 21.5338 7.49479 22.5469C7.49479 23.56 8.29358 24.375 9.28646 24.375H20.0365C21.0293 24.375 21.8281 23.56 21.8281 22.5469C21.8281 21.5338 21.0293 20.7188 20.0365 20.7188H9.28646ZM7.49479 29.8594C7.49479 30.8725 8.29358 31.6875 9.28646 31.6875H15.2587C16.2516 31.6875 17.0503 30.8725 17.0503 29.8594C17.0503 28.8463 16.2516 28.0312 15.2587 28.0312H9.28646C8.29358 28.0312 7.49479 28.8463 7.49479 29.8594ZM41.3722 17.9537L42.4472 19.0506C43.6118 20.2389 43.6118 22.166 42.4472 23.3619L40.2524 25.6014L34.9521 20.1932L37.1469 17.9537C38.3115 16.7654 40.2002 16.7654 41.3722 17.9537ZM23.6123 31.7637L33.2575 21.9223L38.5578 27.3305L28.9127 37.1643C28.6066 37.4766 28.2259 37.6975 27.8003 37.8041L23.3137 38.9467C22.9031 39.0533 22.4776 38.9314 22.179 38.6268C21.8804 38.3221 21.7609 37.8879 21.8655 37.4689L22.9852 32.891C23.0898 32.4645 23.3062 32.0684 23.6123 31.7561V31.7637Z"
        fill={iconColorStyle[color]}
      />
    </svg>
  );
}
