/* tslint:disable */
/* eslint-disable */
/**
 * Specks API
 * APIs for specks SaaS
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { EnumValueDto } from './EnumValueDto';
import {
    EnumValueDtoFromJSON,
    EnumValueDtoFromJSONTyped,
    EnumValueDtoToJSON,
} from './EnumValueDto';

/**
 * 
 * @export
 * @interface EnumDto
 */
export interface EnumDto {
    /**
     * 
     * @type {string}
     * @memberof EnumDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof EnumDto
     */
    valueType: EnumDtoValueTypeEnum;
    /**
     * 
     * @type {Array<EnumValueDto>}
     * @memberof EnumDto
     */
    values: Array<EnumValueDto>;
}


/**
 * @export
 */
export const EnumDtoValueTypeEnum = {
    String: 'STRING',
    Number: 'NUMBER',
    Boolean: 'BOOLEAN',
    Object: 'OBJECT',
    Enum: 'ENUM',
    List: 'LIST',
    Date: 'DATE'
} as const;
export type EnumDtoValueTypeEnum = typeof EnumDtoValueTypeEnum[keyof typeof EnumDtoValueTypeEnum];


/**
 * Check if a given object implements the EnumDto interface.
 */
export function instanceOfEnumDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "valueType" in value;
    isInstance = isInstance && "values" in value;

    return isInstance;
}

export function EnumDtoFromJSON(json: any): EnumDto {
    return EnumDtoFromJSONTyped(json, false);
}

export function EnumDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): EnumDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'valueType': json['valueType'],
        'values': ((json['values'] as Array<any>).map(EnumValueDtoFromJSON)),
    };
}

export function EnumDtoToJSON(value?: EnumDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'valueType': value.valueType,
        'values': ((value.values as Array<any>).map(EnumValueDtoToJSON)),
    };
}

