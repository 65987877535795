/* tslint:disable */
/* eslint-disable */
/**
 * Specks API
 * APIs for specks SaaS
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateUiComponentTitleDto
 */
export interface CreateUiComponentTitleDto {
    /**
     * 
     * @type {string}
     * @memberof CreateUiComponentTitleDto
     */
    functionalId?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUiComponentTitleDto
     */
    type: CreateUiComponentTitleDtoTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateUiComponentTitleDto
     */
    name: string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateUiComponentTitleDto
     */
    isFormValid: boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateUiComponentTitleDto
     */
    notes: string;
    /**
     * 
     * @type {number}
     * @memberof CreateUiComponentTitleDto
     */
    index: number;
    /**
     * 
     * @type {string}
     * @memberof CreateUiComponentTitleDto
     */
    parentId?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUiComponentTitleDto
     */
    contentType: CreateUiComponentTitleDtoContentTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateUiComponentTitleDto
     */
    sourceMethodId?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUiComponentTitleDto
     */
    sourceConstant: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUiComponentTitleDto
     */
    sourceLogicalRule: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUiComponentTitleDto
     */
    isVisible: CreateUiComponentTitleDtoIsVisibleEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateUiComponentTitleDto
     */
    isVisibleLogicalRule: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUiComponentTitleDto
     */
    titleImportancy: CreateUiComponentTitleDtoTitleImportancyEnum;
}


/**
 * @export
 */
export const CreateUiComponentTitleDtoTypeEnum = {
    Title: 'title',
    Icon: 'icon',
    Text: 'text',
    Container: 'container',
    Input: 'input',
    Button: 'button',
    List: 'list',
    Media: 'media',
    Date: 'date',
    Select: 'select'
} as const;
export type CreateUiComponentTitleDtoTypeEnum = typeof CreateUiComponentTitleDtoTypeEnum[keyof typeof CreateUiComponentTitleDtoTypeEnum];

/**
 * @export
 */
export const CreateUiComponentTitleDtoContentTypeEnum = {
    Constant: 'CONSTANT',
    Logical: 'LOGICAL'
} as const;
export type CreateUiComponentTitleDtoContentTypeEnum = typeof CreateUiComponentTitleDtoContentTypeEnum[keyof typeof CreateUiComponentTitleDtoContentTypeEnum];

/**
 * @export
 */
export const CreateUiComponentTitleDtoIsVisibleEnum = {
    Yes: 'YES',
    Logical: 'LOGICAL'
} as const;
export type CreateUiComponentTitleDtoIsVisibleEnum = typeof CreateUiComponentTitleDtoIsVisibleEnum[keyof typeof CreateUiComponentTitleDtoIsVisibleEnum];

/**
 * @export
 */
export const CreateUiComponentTitleDtoTitleImportancyEnum = {
    Undefined: 'UNDEFINED',
    H1: 'H1',
    H2: 'H2',
    H3: 'H3',
    H4: 'H4'
} as const;
export type CreateUiComponentTitleDtoTitleImportancyEnum = typeof CreateUiComponentTitleDtoTitleImportancyEnum[keyof typeof CreateUiComponentTitleDtoTitleImportancyEnum];


/**
 * Check if a given object implements the CreateUiComponentTitleDto interface.
 */
export function instanceOfCreateUiComponentTitleDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "isFormValid" in value;
    isInstance = isInstance && "notes" in value;
    isInstance = isInstance && "index" in value;
    isInstance = isInstance && "contentType" in value;
    isInstance = isInstance && "sourceConstant" in value;
    isInstance = isInstance && "sourceLogicalRule" in value;
    isInstance = isInstance && "isVisible" in value;
    isInstance = isInstance && "isVisibleLogicalRule" in value;
    isInstance = isInstance && "titleImportancy" in value;

    return isInstance;
}

export function CreateUiComponentTitleDtoFromJSON(json: any): CreateUiComponentTitleDto {
    return CreateUiComponentTitleDtoFromJSONTyped(json, false);
}

export function CreateUiComponentTitleDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateUiComponentTitleDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'functionalId': !exists(json, 'functionalId') ? undefined : json['functionalId'],
        'type': json['type'],
        'name': json['name'],
        'isFormValid': json['isFormValid'],
        'notes': json['notes'],
        'index': json['index'],
        'parentId': !exists(json, 'parentId') ? undefined : json['parentId'],
        'contentType': json['contentType'],
        'sourceMethodId': !exists(json, 'sourceMethodId') ? undefined : json['sourceMethodId'],
        'sourceConstant': json['sourceConstant'],
        'sourceLogicalRule': json['sourceLogicalRule'],
        'isVisible': json['isVisible'],
        'isVisibleLogicalRule': json['isVisibleLogicalRule'],
        'titleImportancy': json['titleImportancy'],
    };
}

export function CreateUiComponentTitleDtoToJSON(value?: CreateUiComponentTitleDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'functionalId': value.functionalId,
        'type': value.type,
        'name': value.name,
        'isFormValid': value.isFormValid,
        'notes': value.notes,
        'index': value.index,
        'parentId': value.parentId,
        'contentType': value.contentType,
        'sourceMethodId': value.sourceMethodId,
        'sourceConstant': value.sourceConstant,
        'sourceLogicalRule': value.sourceLogicalRule,
        'isVisible': value.isVisible,
        'isVisibleLogicalRule': value.isVisibleLogicalRule,
        'titleImportancy': value.titleImportancy,
    };
}

