/* tslint:disable */
/* eslint-disable */
/**
 * Specks API
 * APIs for specks SaaS
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ComponentIdDto } from './ComponentIdDto';
import {
    ComponentIdDtoFromJSON,
    ComponentIdDtoFromJSONTyped,
    ComponentIdDtoToJSON,
} from './ComponentIdDto';

/**
 * 
 * @export
 * @interface CreateNodeDto
 */
export interface CreateNodeDto {
    /**
     * 
     * @type {string}
     * @memberof CreateNodeDto
     */
    reactFlowId: string;
    /**
     * 
     * @type {number}
     * @memberof CreateNodeDto
     */
    positionX: number;
    /**
     * 
     * @type {number}
     * @memberof CreateNodeDto
     */
    positionY: number;
    /**
     * 
     * @type {ComponentIdDto}
     * @memberof CreateNodeDto
     */
    component: ComponentIdDto;
}

/**
 * Check if a given object implements the CreateNodeDto interface.
 */
export function instanceOfCreateNodeDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "reactFlowId" in value;
    isInstance = isInstance && "positionX" in value;
    isInstance = isInstance && "positionY" in value;
    isInstance = isInstance && "component" in value;

    return isInstance;
}

export function CreateNodeDtoFromJSON(json: any): CreateNodeDto {
    return CreateNodeDtoFromJSONTyped(json, false);
}

export function CreateNodeDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateNodeDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'reactFlowId': json['reactFlowId'],
        'positionX': json['positionX'],
        'positionY': json['positionY'],
        'component': ComponentIdDtoFromJSON(json['component']),
    };
}

export function CreateNodeDtoToJSON(value?: CreateNodeDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'reactFlowId': value.reactFlowId,
        'positionX': value.positionX,
        'positionY': value.positionY,
        'component': ComponentIdDtoToJSON(value.component),
    };
}

