/* tslint:disable */
/* eslint-disable */
/**
 * Specks API
 * APIs for specks SaaS
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdateComponentDto
 */
export interface UpdateComponentDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateComponentDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateComponentDto
     */
    status: UpdateComponentDtoStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof UpdateComponentDto
     */
    componentType: UpdateComponentDtoComponentTypeEnum;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateComponentDto
     */
    internal: boolean;
}


/**
 * @export
 */
export const UpdateComponentDtoStatusEnum = {
    Production: 'production',
    Planed: 'planed',
    InDevelopment: 'inDevelopment',
    InDecommissionning: 'inDecommissionning',
    Decommissioned: 'decommissioned',
    InExploration: 'inExploration',
    Evolving: 'evolving'
} as const;
export type UpdateComponentDtoStatusEnum = typeof UpdateComponentDtoStatusEnum[keyof typeof UpdateComponentDtoStatusEnum];

/**
 * @export
 */
export const UpdateComponentDtoComponentTypeEnum = {
    Front: 'front',
    Batch: 'batch',
    Api: 'api',
    Database: 'database'
} as const;
export type UpdateComponentDtoComponentTypeEnum = typeof UpdateComponentDtoComponentTypeEnum[keyof typeof UpdateComponentDtoComponentTypeEnum];


/**
 * Check if a given object implements the UpdateComponentDto interface.
 */
export function instanceOfUpdateComponentDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "status" in value;
    isInstance = isInstance && "componentType" in value;
    isInstance = isInstance && "internal" in value;

    return isInstance;
}

export function UpdateComponentDtoFromJSON(json: any): UpdateComponentDto {
    return UpdateComponentDtoFromJSONTyped(json, false);
}

export function UpdateComponentDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateComponentDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'status': json['status'],
        'componentType': json['componentType'],
        'internal': json['internal'],
    };
}

export function UpdateComponentDtoToJSON(value?: UpdateComponentDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'status': value.status,
        'componentType': value.componentType,
        'internal': value.internal,
    };
}

