/* tslint:disable */
/* eslint-disable */
/**
 * Specks API
 * APIs for specks SaaS
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CreateOptionDto } from './CreateOptionDto';
import {
    CreateOptionDtoFromJSON,
    CreateOptionDtoFromJSONTyped,
    CreateOptionDtoToJSON,
} from './CreateOptionDto';

/**
 * 
 * @export
 * @interface CreateUiComponentSelectDto
 */
export interface CreateUiComponentSelectDto {
    /**
     * 
     * @type {string}
     * @memberof CreateUiComponentSelectDto
     */
    functionalId?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUiComponentSelectDto
     */
    type: CreateUiComponentSelectDtoTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateUiComponentSelectDto
     */
    name: string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateUiComponentSelectDto
     */
    isFormValid: boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateUiComponentSelectDto
     */
    notes: string;
    /**
     * 
     * @type {number}
     * @memberof CreateUiComponentSelectDto
     */
    index: number;
    /**
     * 
     * @type {string}
     * @memberof CreateUiComponentSelectDto
     */
    parentId?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUiComponentSelectDto
     */
    label: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUiComponentSelectDto
     */
    isVisible: CreateUiComponentSelectDtoIsVisibleEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateUiComponentSelectDto
     */
    isVisibleLogicalRule: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUiComponentSelectDto
     */
    optionsSource: CreateUiComponentSelectDtoOptionsSourceEnum;
    /**
     * 
     * @type {Array<CreateOptionDto>}
     * @memberof CreateUiComponentSelectDto
     */
    options: Array<CreateOptionDto>;
    /**
     * 
     * @type {string}
     * @memberof CreateUiComponentSelectDto
     */
    sourceMethodId?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUiComponentSelectDto
     */
    isMultiChoiceAllowed: CreateUiComponentSelectDtoIsMultiChoiceAllowedEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateUiComponentSelectDto
     */
    isMultiChoiceAllowedLogicalRule: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUiComponentSelectDto
     */
    isPreFilled: CreateUiComponentSelectDtoIsPreFilledEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateUiComponentSelectDto
     */
    isPreFilledConstant: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUiComponentSelectDto
     */
    isPreFilledLogicalRule: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUiComponentSelectDto
     */
    hasPlaceholder: CreateUiComponentSelectDtoHasPlaceholderEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateUiComponentSelectDto
     */
    hasPlaceholderConstant: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUiComponentSelectDto
     */
    hasPlaceholderLogicalRule: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUiComponentSelectDto
     */
    isMandatory: CreateUiComponentSelectDtoIsMandatoryEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateUiComponentSelectDto
     */
    isMandatoryLogicalRule: string;
}


/**
 * @export
 */
export const CreateUiComponentSelectDtoTypeEnum = {
    Title: 'title',
    Icon: 'icon',
    Text: 'text',
    Container: 'container',
    Input: 'input',
    Button: 'button',
    List: 'list',
    Media: 'media',
    Date: 'date',
    Select: 'select'
} as const;
export type CreateUiComponentSelectDtoTypeEnum = typeof CreateUiComponentSelectDtoTypeEnum[keyof typeof CreateUiComponentSelectDtoTypeEnum];

/**
 * @export
 */
export const CreateUiComponentSelectDtoIsVisibleEnum = {
    Yes: 'YES',
    Logical: 'LOGICAL'
} as const;
export type CreateUiComponentSelectDtoIsVisibleEnum = typeof CreateUiComponentSelectDtoIsVisibleEnum[keyof typeof CreateUiComponentSelectDtoIsVisibleEnum];

/**
 * @export
 */
export const CreateUiComponentSelectDtoOptionsSourceEnum = {
    Method: 'METHOD',
    Free: 'FREE'
} as const;
export type CreateUiComponentSelectDtoOptionsSourceEnum = typeof CreateUiComponentSelectDtoOptionsSourceEnum[keyof typeof CreateUiComponentSelectDtoOptionsSourceEnum];

/**
 * @export
 */
export const CreateUiComponentSelectDtoIsMultiChoiceAllowedEnum = {
    Yes: 'YES',
    No: 'NO',
    Logical: 'LOGICAL'
} as const;
export type CreateUiComponentSelectDtoIsMultiChoiceAllowedEnum = typeof CreateUiComponentSelectDtoIsMultiChoiceAllowedEnum[keyof typeof CreateUiComponentSelectDtoIsMultiChoiceAllowedEnum];

/**
 * @export
 */
export const CreateUiComponentSelectDtoIsPreFilledEnum = {
    Yes: 'YES',
    No: 'NO',
    Logical: 'LOGICAL'
} as const;
export type CreateUiComponentSelectDtoIsPreFilledEnum = typeof CreateUiComponentSelectDtoIsPreFilledEnum[keyof typeof CreateUiComponentSelectDtoIsPreFilledEnum];

/**
 * @export
 */
export const CreateUiComponentSelectDtoHasPlaceholderEnum = {
    Yes: 'YES',
    No: 'NO',
    Logical: 'LOGICAL'
} as const;
export type CreateUiComponentSelectDtoHasPlaceholderEnum = typeof CreateUiComponentSelectDtoHasPlaceholderEnum[keyof typeof CreateUiComponentSelectDtoHasPlaceholderEnum];

/**
 * @export
 */
export const CreateUiComponentSelectDtoIsMandatoryEnum = {
    Yes: 'YES',
    No: 'NO',
    Logical: 'LOGICAL'
} as const;
export type CreateUiComponentSelectDtoIsMandatoryEnum = typeof CreateUiComponentSelectDtoIsMandatoryEnum[keyof typeof CreateUiComponentSelectDtoIsMandatoryEnum];


/**
 * Check if a given object implements the CreateUiComponentSelectDto interface.
 */
export function instanceOfCreateUiComponentSelectDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "isFormValid" in value;
    isInstance = isInstance && "notes" in value;
    isInstance = isInstance && "index" in value;
    isInstance = isInstance && "label" in value;
    isInstance = isInstance && "isVisible" in value;
    isInstance = isInstance && "isVisibleLogicalRule" in value;
    isInstance = isInstance && "optionsSource" in value;
    isInstance = isInstance && "options" in value;
    isInstance = isInstance && "isMultiChoiceAllowed" in value;
    isInstance = isInstance && "isMultiChoiceAllowedLogicalRule" in value;
    isInstance = isInstance && "isPreFilled" in value;
    isInstance = isInstance && "isPreFilledConstant" in value;
    isInstance = isInstance && "isPreFilledLogicalRule" in value;
    isInstance = isInstance && "hasPlaceholder" in value;
    isInstance = isInstance && "hasPlaceholderConstant" in value;
    isInstance = isInstance && "hasPlaceholderLogicalRule" in value;
    isInstance = isInstance && "isMandatory" in value;
    isInstance = isInstance && "isMandatoryLogicalRule" in value;

    return isInstance;
}

export function CreateUiComponentSelectDtoFromJSON(json: any): CreateUiComponentSelectDto {
    return CreateUiComponentSelectDtoFromJSONTyped(json, false);
}

export function CreateUiComponentSelectDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateUiComponentSelectDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'functionalId': !exists(json, 'functionalId') ? undefined : json['functionalId'],
        'type': json['type'],
        'name': json['name'],
        'isFormValid': json['isFormValid'],
        'notes': json['notes'],
        'index': json['index'],
        'parentId': !exists(json, 'parentId') ? undefined : json['parentId'],
        'label': json['label'],
        'isVisible': json['isVisible'],
        'isVisibleLogicalRule': json['isVisibleLogicalRule'],
        'optionsSource': json['optionsSource'],
        'options': ((json['options'] as Array<any>).map(CreateOptionDtoFromJSON)),
        'sourceMethodId': !exists(json, 'sourceMethodId') ? undefined : json['sourceMethodId'],
        'isMultiChoiceAllowed': json['isMultiChoiceAllowed'],
        'isMultiChoiceAllowedLogicalRule': json['isMultiChoiceAllowedLogicalRule'],
        'isPreFilled': json['isPreFilled'],
        'isPreFilledConstant': json['isPreFilledConstant'],
        'isPreFilledLogicalRule': json['isPreFilledLogicalRule'],
        'hasPlaceholder': json['hasPlaceholder'],
        'hasPlaceholderConstant': json['hasPlaceholderConstant'],
        'hasPlaceholderLogicalRule': json['hasPlaceholderLogicalRule'],
        'isMandatory': json['isMandatory'],
        'isMandatoryLogicalRule': json['isMandatoryLogicalRule'],
    };
}

export function CreateUiComponentSelectDtoToJSON(value?: CreateUiComponentSelectDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'functionalId': value.functionalId,
        'type': value.type,
        'name': value.name,
        'isFormValid': value.isFormValid,
        'notes': value.notes,
        'index': value.index,
        'parentId': value.parentId,
        'label': value.label,
        'isVisible': value.isVisible,
        'isVisibleLogicalRule': value.isVisibleLogicalRule,
        'optionsSource': value.optionsSource,
        'options': ((value.options as Array<any>).map(CreateOptionDtoToJSON)),
        'sourceMethodId': value.sourceMethodId,
        'isMultiChoiceAllowed': value.isMultiChoiceAllowed,
        'isMultiChoiceAllowedLogicalRule': value.isMultiChoiceAllowedLogicalRule,
        'isPreFilled': value.isPreFilled,
        'isPreFilledConstant': value.isPreFilledConstant,
        'isPreFilledLogicalRule': value.isPreFilledLogicalRule,
        'hasPlaceholder': value.hasPlaceholder,
        'hasPlaceholderConstant': value.hasPlaceholderConstant,
        'hasPlaceholderLogicalRule': value.hasPlaceholderLogicalRule,
        'isMandatory': value.isMandatory,
        'isMandatoryLogicalRule': value.isMandatoryLogicalRule,
    };
}

