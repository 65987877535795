/* tslint:disable */
/* eslint-disable */
/**
 * Specks API
 * APIs for specks SaaS
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdateUiComponentInputDto
 */
export interface UpdateUiComponentInputDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateUiComponentInputDto
     */
    functionalId?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateUiComponentInputDto
     */
    type: UpdateUiComponentInputDtoTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof UpdateUiComponentInputDto
     */
    name: string;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateUiComponentInputDto
     */
    isFormValid: boolean;
    /**
     * 
     * @type {string}
     * @memberof UpdateUiComponentInputDto
     */
    notes: string;
    /**
     * 
     * @type {number}
     * @memberof UpdateUiComponentInputDto
     */
    index: number;
    /**
     * 
     * @type {string}
     * @memberof UpdateUiComponentInputDto
     */
    parentId?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateUiComponentInputDto
     */
    label: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateUiComponentInputDto
     */
    isEditable: UpdateUiComponentInputDtoIsEditableEnum;
    /**
     * 
     * @type {string}
     * @memberof UpdateUiComponentInputDto
     */
    isEditableLogicalRule: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateUiComponentInputDto
     */
    isPreFilled: UpdateUiComponentInputDtoIsPreFilledEnum;
    /**
     * 
     * @type {string}
     * @memberof UpdateUiComponentInputDto
     */
    isPreFilledConstant: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateUiComponentInputDto
     */
    isPreFilledLogicalRule: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateUiComponentInputDto
     */
    hasPlaceholder: UpdateUiComponentInputDtoHasPlaceholderEnum;
    /**
     * 
     * @type {string}
     * @memberof UpdateUiComponentInputDto
     */
    hasPlaceholderConstant: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateUiComponentInputDto
     */
    hasPlaceholderLogicalRule: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateUiComponentInputDto
     */
    isMandatory: UpdateUiComponentInputDtoIsMandatoryEnum;
    /**
     * 
     * @type {string}
     * @memberof UpdateUiComponentInputDto
     */
    isMandatoryLogicalRule: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateUiComponentInputDto
     */
    hasPattern: UpdateUiComponentInputDtoHasPatternEnum;
    /**
     * 
     * @type {string}
     * @memberof UpdateUiComponentInputDto
     */
    hasPatternConstant: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateUiComponentInputDto
     */
    hasPatternLogicalRule: string;
}


/**
 * @export
 */
export const UpdateUiComponentInputDtoTypeEnum = {
    Title: 'title',
    Icon: 'icon',
    Text: 'text',
    Container: 'container',
    Input: 'input',
    Button: 'button',
    List: 'list',
    Media: 'media',
    Date: 'date',
    Select: 'select'
} as const;
export type UpdateUiComponentInputDtoTypeEnum = typeof UpdateUiComponentInputDtoTypeEnum[keyof typeof UpdateUiComponentInputDtoTypeEnum];

/**
 * @export
 */
export const UpdateUiComponentInputDtoIsEditableEnum = {
    Yes: 'YES',
    No: 'NO',
    Logical: 'LOGICAL'
} as const;
export type UpdateUiComponentInputDtoIsEditableEnum = typeof UpdateUiComponentInputDtoIsEditableEnum[keyof typeof UpdateUiComponentInputDtoIsEditableEnum];

/**
 * @export
 */
export const UpdateUiComponentInputDtoIsPreFilledEnum = {
    Yes: 'YES',
    No: 'NO',
    Logical: 'LOGICAL'
} as const;
export type UpdateUiComponentInputDtoIsPreFilledEnum = typeof UpdateUiComponentInputDtoIsPreFilledEnum[keyof typeof UpdateUiComponentInputDtoIsPreFilledEnum];

/**
 * @export
 */
export const UpdateUiComponentInputDtoHasPlaceholderEnum = {
    Yes: 'YES',
    No: 'NO',
    Logical: 'LOGICAL'
} as const;
export type UpdateUiComponentInputDtoHasPlaceholderEnum = typeof UpdateUiComponentInputDtoHasPlaceholderEnum[keyof typeof UpdateUiComponentInputDtoHasPlaceholderEnum];

/**
 * @export
 */
export const UpdateUiComponentInputDtoIsMandatoryEnum = {
    Yes: 'YES',
    No: 'NO',
    Logical: 'LOGICAL'
} as const;
export type UpdateUiComponentInputDtoIsMandatoryEnum = typeof UpdateUiComponentInputDtoIsMandatoryEnum[keyof typeof UpdateUiComponentInputDtoIsMandatoryEnum];

/**
 * @export
 */
export const UpdateUiComponentInputDtoHasPatternEnum = {
    Yes: 'YES',
    No: 'NO',
    Logical: 'LOGICAL'
} as const;
export type UpdateUiComponentInputDtoHasPatternEnum = typeof UpdateUiComponentInputDtoHasPatternEnum[keyof typeof UpdateUiComponentInputDtoHasPatternEnum];


/**
 * Check if a given object implements the UpdateUiComponentInputDto interface.
 */
export function instanceOfUpdateUiComponentInputDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "isFormValid" in value;
    isInstance = isInstance && "notes" in value;
    isInstance = isInstance && "index" in value;
    isInstance = isInstance && "label" in value;
    isInstance = isInstance && "isEditable" in value;
    isInstance = isInstance && "isEditableLogicalRule" in value;
    isInstance = isInstance && "isPreFilled" in value;
    isInstance = isInstance && "isPreFilledConstant" in value;
    isInstance = isInstance && "isPreFilledLogicalRule" in value;
    isInstance = isInstance && "hasPlaceholder" in value;
    isInstance = isInstance && "hasPlaceholderConstant" in value;
    isInstance = isInstance && "hasPlaceholderLogicalRule" in value;
    isInstance = isInstance && "isMandatory" in value;
    isInstance = isInstance && "isMandatoryLogicalRule" in value;
    isInstance = isInstance && "hasPattern" in value;
    isInstance = isInstance && "hasPatternConstant" in value;
    isInstance = isInstance && "hasPatternLogicalRule" in value;

    return isInstance;
}

export function UpdateUiComponentInputDtoFromJSON(json: any): UpdateUiComponentInputDto {
    return UpdateUiComponentInputDtoFromJSONTyped(json, false);
}

export function UpdateUiComponentInputDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateUiComponentInputDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'functionalId': !exists(json, 'functionalId') ? undefined : json['functionalId'],
        'type': json['type'],
        'name': json['name'],
        'isFormValid': json['isFormValid'],
        'notes': json['notes'],
        'index': json['index'],
        'parentId': !exists(json, 'parentId') ? undefined : json['parentId'],
        'label': json['label'],
        'isEditable': json['isEditable'],
        'isEditableLogicalRule': json['isEditableLogicalRule'],
        'isPreFilled': json['isPreFilled'],
        'isPreFilledConstant': json['isPreFilledConstant'],
        'isPreFilledLogicalRule': json['isPreFilledLogicalRule'],
        'hasPlaceholder': json['hasPlaceholder'],
        'hasPlaceholderConstant': json['hasPlaceholderConstant'],
        'hasPlaceholderLogicalRule': json['hasPlaceholderLogicalRule'],
        'isMandatory': json['isMandatory'],
        'isMandatoryLogicalRule': json['isMandatoryLogicalRule'],
        'hasPattern': json['hasPattern'],
        'hasPatternConstant': json['hasPatternConstant'],
        'hasPatternLogicalRule': json['hasPatternLogicalRule'],
    };
}

export function UpdateUiComponentInputDtoToJSON(value?: UpdateUiComponentInputDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'functionalId': value.functionalId,
        'type': value.type,
        'name': value.name,
        'isFormValid': value.isFormValid,
        'notes': value.notes,
        'index': value.index,
        'parentId': value.parentId,
        'label': value.label,
        'isEditable': value.isEditable,
        'isEditableLogicalRule': value.isEditableLogicalRule,
        'isPreFilled': value.isPreFilled,
        'isPreFilledConstant': value.isPreFilledConstant,
        'isPreFilledLogicalRule': value.isPreFilledLogicalRule,
        'hasPlaceholder': value.hasPlaceholder,
        'hasPlaceholderConstant': value.hasPlaceholderConstant,
        'hasPlaceholderLogicalRule': value.hasPlaceholderLogicalRule,
        'isMandatory': value.isMandatory,
        'isMandatoryLogicalRule': value.isMandatoryLogicalRule,
        'hasPattern': value.hasPattern,
        'hasPatternConstant': value.hasPatternConstant,
        'hasPatternLogicalRule': value.hasPatternLogicalRule,
    };
}

