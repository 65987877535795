/* eslint-disable max-len */
import React from 'react';

import concatClassNames from 'utils/classNames';

import { type IconProps, iconColorStyle, iconSizeStyle } from './Icon.props';

export default function SearchIcon({ color, size }: IconProps): JSX.Element {
  return (
    <svg
      className={concatClassNames(iconSizeStyle[size])}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11 19C15.4183 19 19 15.4183 19 11C19 6.58172 15.4183 3 11 3C6.58172 3 3 6.58172 3 11C3 15.4183 6.58172 19 11 19Z"
        stroke={iconColorStyle[color]}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.9999 21L16.6499 16.65"
        stroke={iconColorStyle[color]}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
