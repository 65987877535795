import React, { useState } from 'react';

import { Tooltip } from '@mui/material';

import { Settings } from 'components/core/Settings/Settings';
import { Text } from 'components/core/Text/Text';

import concatClassNames from 'utils/classNames';
import getIcon from 'utils/getIcon';
import { tooltipDescriptionsStyle } from 'utils/tooltipsDescriptions';

import ChevronDownIcon from 'icons/ChevronDownIcon';
import ChevronRightIcon from 'icons/ChevronRightIcon';

type CollapseArrowPosition = 'left' | 'right';

type ApiCollapseProps =
  | {
      resourceId: string;
      title: string;
      children: JSX.Element | JSX.Element[];
      arrowPosition?: CollapseArrowPosition;
      onClickAddMethod: (resourceId: string) => void;
      onClick?: (resourceId: string) => void;
      isCollapsed?: boolean;
      onDelete?: (resourceId: string) => void;
      onEdit?: (resourceId: string) => void;

      loading?: false;
    }
  | {
      resourceId?: string;
      title: string;
      children: JSX.Element | JSX.Element[];
      arrowPosition?: CollapseArrowPosition;
      onClickAddMethod?: ((resourceId: string) => void) | null;
      onClick?: (resourceId: string) => void;
      isCollapsed?: boolean;
      onDelete?: (resourceId: string) => void;
      onEdit?: (resourceId: string) => void;

      loading?: false;
    }
  | {
      resourceId?: string;
      title?: string;
      children?: JSX.Element | JSX.Element[];
      arrowPosition?: CollapseArrowPosition;
      onClickAddMethod?: ((resourceId: string) => void) | null;
      onClick?: (resourceId: string) => void;
      isCollapsed?: boolean;
      onDelete?: (resourceId: string) => void;
      onEdit?: (resourceId: string) => void;

      loading: true;
    };

export function ApiCollapse({
  resourceId = '',
  title = '',
  children,
  arrowPosition = 'right',
  isCollapsed = true,
  onClickAddMethod,
  onClick,
  onDelete,
  onEdit,
  loading = false,
}: ApiCollapseProps): JSX.Element {
  /* ---------------------------------------------------- states ---------------------------------------------------- */

  const [isCollapsedState, setIsCollapstedState] = useState<boolean>(isCollapsed);

  /* --------------------------------------------------- functions -------------------------------------------------- */

  function handleOnClick(): void {
    if (onClick != null) {
      onClick(resourceId);
    } else {
      setIsCollapstedState(!isCollapsedState);
    }
  }

  function handleOnClickAddMethod(e: any): void {
    e.stopPropagation();
    if (onClickAddMethod != null) onClickAddMethod(resourceId ?? '');
  }

  /* ------------------------------------------------------ JSX ----------------------------------------------------- */

  return (
    <div
      id="ApiCollapse"
      className={concatClassNames(
        'flex flex-col',
        'w-full',
        'p-3',
        (onClick !== undefined && !isCollapsed) || (onClick === undefined && !isCollapsedState) ? 'gap-4' : '',
        'bg-gray-15 rounded-md',
      )}
    >
      <div
        className={concatClassNames('flex flex-row', 'w-full', 'justify-between', loading ? '' : 'cursor-pointer')}
        onClick={handleOnClick}
      >
        {loading ? (
          <>
            <div className="flex flex-row items-center gap-1">
              {arrowPosition === 'left' && (
                <>
                  <ChevronRightIcon color="gray" size="md" />
                </>
              )}
              <Text loading />
            </div>
            <div className="flex flex-row items-center gap-1">
              {arrowPosition === 'right' && <ChevronRightIcon color="gray" size="md" />}
            </div>
          </>
        ) : (
          <>
            <div className="flex flex-row items-center gap-2">
              {arrowPosition === 'left' &&
                ((onClick !== undefined && !isCollapsed) || (onClick === undefined && !isCollapsedState) ? (
                  <ChevronDownIcon color="gray" size="md" />
                ) : (
                  <ChevronRightIcon color="gray" size="md" />
                ))}
              <Text content={title} color="gray-600" weight="bold" size="base" position="left" />
              <Tooltip title={tooltipDescriptionsStyle.ressource} placement="right">
                <div className="bg-black border-1 rounded-full">{getIcon('information', 'white', 'smd')}</div>
              </Tooltip>
            </div>
            <div className="flex flex-row items-center gap-1">
              {arrowPosition === 'right' &&
                ((onClick !== undefined && !isCollapsed) || (onClick === undefined && !isCollapsedState) ? (
                  <ChevronDownIcon color="gray" size="md" />
                ) : (
                  <ChevronRightIcon color="gray" size="md" />
                ))}

              {onClickAddMethod != null && (
                <>
                  <Tooltip title="Ajouter une méthode" placement="bottom">
                    <div
                      className={concatClassNames('bg-white', 'p-1', 'rounded-md', 'border-1 border-gray-50')}
                      onClick={handleOnClickAddMethod}
                    >
                      {getIcon('plus', 'gray', 'sm')}
                    </div>
                  </Tooltip>
                  <Settings
                    position="left"
                    iconSize="md"
                    settingsIconName="more"
                    buttonsMenu={[
                      {
                        name: 'Modifier',
                        iconName: 'edit',
                        textColor: 'black',
                        isEnabled: true,
                        onClick: (e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          onEdit?.(resourceId);
                        },
                      },
                      {
                        name: 'Supprimer',
                        iconName: 'trash',
                        textColor: 'red',
                        onClick: (e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          onDelete?.(resourceId);
                        },
                      },
                    ]}
                  />
                </>
              )}
            </div>
          </>
        )}
      </div>
      {!loading && ((onClick !== undefined && !isCollapsed) || (onClick === undefined && !isCollapsedState)) && (
        <div
          className={concatClassNames(
            'flex flex-row',
            'w-full',
            'overflow-auto',
            (onClick !== undefined && !isCollapsed) || (onClick === undefined && !isCollapsedState) ? '' : 'invisible',
          )}
        >
          {children}
        </div>
      )}
    </div>
  );
}
