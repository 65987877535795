import React from 'react';

import { Tooltip } from '@mui/material';

import { Chip } from 'components/core/Chip/Chip';

import { type IComponent } from 'models/ComponentTypes.type';

export function BackApiTypeChip({
  bgColor = 'transparent',
  borderColor: border = 'none',
  textColor = 'blue',
  label = 'Back-end API REST',
  tooltip = true,
  tooltipMessage = 'Un back-end est la partie d’un site web ou d’une application mobile qui est invisible pour les utilisateurs finaux. Il est responsable de la transimission des données entre deux composants applicatifs (par exemple entre un front-end et une base de données).',
}: IComponent): JSX.Element {
  return (
    <Chip
      icon="api"
      iconColor="blue"
      textColor={textColor}
      iconSize={'smd'}
      label={label}
      bgColor={bgColor}
      borderColor={border}
      textSize="sm"
      paddingTop="md"
      paddingBottom="md"
      paddingHorizontal="lg"
      tooltipMessage={tooltip ? tooltipMessage : undefined}
    />
  );
}
