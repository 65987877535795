/* tslint:disable */
/* eslint-disable */
/**
 * Specks API
 * APIs for specks SaaS
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CreateUiComponentIconDto } from './CreateUiComponentIconDto';
import {
    CreateUiComponentIconDtoFromJSON,
    CreateUiComponentIconDtoFromJSONTyped,
    CreateUiComponentIconDtoToJSON,
} from './CreateUiComponentIconDto';
import type { UpdateUicPositionDto } from './UpdateUicPositionDto';
import {
    UpdateUicPositionDtoFromJSON,
    UpdateUicPositionDtoFromJSONTyped,
    UpdateUicPositionDtoToJSON,
} from './UpdateUicPositionDto';

/**
 * 
 * @export
 * @interface CreateUicPositionIconDto
 */
export interface CreateUicPositionIconDto {
    /**
     * 
     * @type {CreateUiComponentIconDto}
     * @memberof CreateUicPositionIconDto
     */
    uiComponent: CreateUiComponentIconDto;
    /**
     * 
     * @type {Array<UpdateUicPositionDto>}
     * @memberof CreateUicPositionIconDto
     */
    positions: Array<UpdateUicPositionDto>;
}

/**
 * Check if a given object implements the CreateUicPositionIconDto interface.
 */
export function instanceOfCreateUicPositionIconDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "uiComponent" in value;
    isInstance = isInstance && "positions" in value;

    return isInstance;
}

export function CreateUicPositionIconDtoFromJSON(json: any): CreateUicPositionIconDto {
    return CreateUicPositionIconDtoFromJSONTyped(json, false);
}

export function CreateUicPositionIconDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateUicPositionIconDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'uiComponent': CreateUiComponentIconDtoFromJSON(json['uiComponent']),
        'positions': ((json['positions'] as Array<any>).map(UpdateUicPositionDtoFromJSON)),
    };
}

export function CreateUicPositionIconDtoToJSON(value?: CreateUicPositionIconDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'uiComponent': CreateUiComponentIconDtoToJSON(value.uiComponent),
        'positions': ((value.positions as Array<any>).map(UpdateUicPositionDtoToJSON)),
    };
}

