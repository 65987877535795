import {
  BackApiSpecificationsApi,
  type CreateExternalLinkDto,
  type GetBackApiSpecificationDto,
  type GetBackApiSpecificationResourceDto,
  type GetExternalLinkDto,
  type GetResourceDto,
} from 'api';
import { fromGetApiSpecificationDto, fromGetResourceDto } from 'factory/ResourceFactory';

import { type ExternalLink } from 'models/externalLink.entity';
import { type Resource } from 'models/resource.entity';

export class BackendApiSpecificationService {
  private readonly BackApiSpecificationClient: BackApiSpecificationsApi;

  constructor() {
    this.BackApiSpecificationClient = new BackApiSpecificationsApi();
  }

  /* --------------------------------------------------- Resources -------------------------------------------------- */

  public async createResource(
    BackApiSpecificationId: string,
    resource: Resource,
    accessToken: string,
  ): Promise<Resource> {
    const getApiSpecificationResourceDto: GetBackApiSpecificationDto =
      await this.BackApiSpecificationClient.backApiSpecificationControllerCreateResource(
        {
          id: BackApiSpecificationId,
          createResourceDto: {
            name: resource.resourceRevisions[0].name,
          },
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
          },
        },
      );

    return fromGetApiSpecificationDto(getApiSpecificationResourceDto);
  }

  public async getResources(BackApiSpecificationId: string, accessToken: string): Promise<Resource[]> {
    const getApiSpecificationResourceDto: GetBackApiSpecificationResourceDto =
      await this.BackApiSpecificationClient.backApiSpecificationControllerFindResourcesById(
        {
          id: BackApiSpecificationId,
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
          },
        },
      );
    return getApiSpecificationResourceDto.resources.map((resource: GetResourceDto): Resource => {
      return fromGetResourceDto(resource);
    });
  }

  /* ------------------------------------------------- externalLinks ------------------------------------------------ */

  public async createExternalLink(
    BackApiSpecificationId: string,
    externalLink: ExternalLink,
    accessToken: string,
  ): Promise<GetExternalLinkDto> {
    return await this.BackApiSpecificationClient.backApiSpecificationControllerCreateExternalLink(
      {
        id: BackApiSpecificationId,
        createExternalLinkDto: externalLink as CreateExternalLinkDto,
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
      },
    );
  }
}
