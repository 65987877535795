/* tslint:disable */
/* eslint-disable */
/**
 * Specks API
 * APIs for specks SaaS
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { GetAppSchemaRevisionInformationsDto } from './GetAppSchemaRevisionInformationsDto';
import {
    GetAppSchemaRevisionInformationsDtoFromJSON,
    GetAppSchemaRevisionInformationsDtoFromJSONTyped,
    GetAppSchemaRevisionInformationsDtoToJSON,
} from './GetAppSchemaRevisionInformationsDto';

/**
 * 
 * @export
 * @interface GetProductAppSchemaRevisionsDto
 */
export interface GetProductAppSchemaRevisionsDto {
    /**
     * 
     * @type {Array<GetAppSchemaRevisionInformationsDto>}
     * @memberof GetProductAppSchemaRevisionsDto
     */
    appSchemas: Array<GetAppSchemaRevisionInformationsDto>;
}

/**
 * Check if a given object implements the GetProductAppSchemaRevisionsDto interface.
 */
export function instanceOfGetProductAppSchemaRevisionsDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "appSchemas" in value;

    return isInstance;
}

export function GetProductAppSchemaRevisionsDtoFromJSON(json: any): GetProductAppSchemaRevisionsDto {
    return GetProductAppSchemaRevisionsDtoFromJSONTyped(json, false);
}

export function GetProductAppSchemaRevisionsDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetProductAppSchemaRevisionsDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'appSchemas': ((json['appSchemas'] as Array<any>).map(GetAppSchemaRevisionInformationsDtoFromJSON)),
    };
}

export function GetProductAppSchemaRevisionsDtoToJSON(value?: GetProductAppSchemaRevisionsDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'appSchemas': ((value.appSchemas as Array<any>).map(GetAppSchemaRevisionInformationsDtoToJSON)),
    };
}

