import * as Yup from 'yup';

import React from 'react';
import { type SubmitHandler, useForm } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';

import Avatar from 'components/Avatar/Avatar';
import { Button } from 'components/core/Button/Button';
import Input from 'components/core/Input/Input';
import { Title } from 'components/core/Title/Title';

import { ExternalLink } from 'models/externalLink.entity';

interface CreateExternalLinkFormModel {
  name: string;
  url: string;
  // avatar: string;
}

const schema: Yup.ObjectSchema<CreateExternalLinkFormModel> = Yup.object().shape({
  name: Yup.string().required('Champ obligatoire').min(3, 'Le nom doit contenir au moins 3 caractères'),
  url: Yup.string()
    .required('Champ obligatoire')
    .url('URL invalide. Avez-vous pensé à préciser le protocole (https://)?'),
  // avatar: Yup.string().required('Champ obligatoire'),
});

interface CreateExternalLinkFormProps {
  onSubmit: (externalLink: ExternalLink) => void;
  externalLink?: ExternalLink;
}

export function CreateOrEditExternalLinkForm({ onSubmit, externalLink }: CreateExternalLinkFormProps): JSX.Element {
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitted },
  } = useForm<CreateExternalLinkFormModel>({
    resolver: yupResolver(schema),
    defaultValues: {
      name: externalLink !== undefined ? externalLink.name : '',
      url: externalLink !== undefined ? externalLink.url : '',
    },
  });

  const onSubmitForm: SubmitHandler<CreateExternalLinkFormModel> = (data) => {
    const externalLink: ExternalLink = new ExternalLink();
    externalLink.name = data.name;
    externalLink.url = data.url;
    externalLink.img = 'totoimg';
    onSubmit(externalLink);
  };

  // TODO : Handle register avatar
  return (
    <form className="flex flex-col gap-6" onSubmit={handleSubmit(onSubmitForm)}>
      <Avatar
        iconName={'externalLink'}
        // label="Image"
        // {...register('avatar')}
        // error={errors.avatar != null && isSubmitted ? errors.avatar.message : undefined}
        iconSize="20"
      />
      <Input
        label="Nom"
        placeholder="Saisissez le nom de l'outil ou du document. Exemple : Jira"
        {...register('name')}
        maxLength={28}
        error={errors.name != null && isSubmitted ? errors.name.message : undefined}
      />

      <Input
        label="URL"
        placeholder="Exemple : https://enterprise.atlassian.net/jira/software"
        {...register('url')}
        error={errors.url != null && isSubmitted ? errors.url.message : undefined}
      />
      <div className="flex flex-row">
        <Button content="Ajouter" width="full" type="submit" />
      </div>
    </form>
  );
}
