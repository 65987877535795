/* eslint-disable max-len */
import React from 'react';

import concatClassNames from 'utils/classNames';

import { type IconProps, iconSizeStyle } from './Icon.props';

export default function SpecksText({ color, size }: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1650.5 549.39"
      className={concatClassNames(iconSizeStyle[size])}
    >
      <g id="Layer_2" data-name="Layer 2">
        <g id="Calque_1" data-name="Calque 1">
          <path
            d="M18,335.21a31.91,31.91,0,0,1,30,21.34Q55.64,378,69.48,392.24q20.2,20.88,47.77,20.87,18.08,0,26.68-10.33T152.54,380q0-18.5-16.14-31.2T90.13,321.44Q49.68,301.64,29,277.54T8.36,219q0-25.4,12.69-44.12T55.7,146.26q22-9.88,49.06-9.9a186.28,186.28,0,0,1,30.78,2.8Q152.1,142,162,147.56l5.43-1.67c19-5.82,39.13,7,41,26.81,0,.15,0,.31,0,.47q2.14,23.45,2.15,54v6.46H198.13a31.83,31.83,0,0,1-29.53-20q-17.07-42.79-54.37-42.79-17.2,0-25.81,9.68T79.8,206.09a37,37,0,0,0,6.89,22.38,62.64,62.64,0,0,0,17.43,16.14,301.13,301.13,0,0,0,28.19,15.28,485.23,485.23,0,0,1,45.41,25,111.26,111.26,0,0,1,31.64,30.77Q222.7,334.79,222.69,361a81.64,81.64,0,0,1-12.05,43.69q-12,19.59-35.08,31.2T122,447.54q-34.87,0-69.31-14.63l-.55.24A31.85,31.85,0,0,1,8.05,410.67Q6.64,404,5.34,396.54A418.68,418.68,0,0,1,.05,354.72a18.09,18.09,0,0,1,18-19.51Z"
            fill="#010101"
          />
          <path
            d="M1445.49,335.21A32.44,32.44,0,0,1,1476,356.88q7.59,21.19,21.32,35.36,20.2,20.88,47.77,20.87,18.07,0,26.68-10.33t8.61-22.81q0-18.5-16.14-31.2t-46.27-27.33q-40.45-19.8-61.12-43.9T1436.17,219q0-25.4,12.69-44.12t34.65-28.62q21.95-9.88,49.06-9.9a186.07,186.07,0,0,1,30.77,2.8q16.58,2.81,26.48,8.4l5.43-1.67c19-5.82,39.13,7,41,26.81,0,.15,0,.31,0,.47q2.15,23.45,2.15,54v6.46h-12.51a31.82,31.82,0,0,1-29.53-20Q1579.34,170.8,1542,170.8q-17.2,0-25.82,9.68t-8.61,25.61q0,12.91,6.89,22.38a62.51,62.51,0,0,0,17.43,16.14,299.85,299.85,0,0,0,28.19,15.28,484.92,484.92,0,0,1,45.4,25,111.26,111.26,0,0,1,31.64,30.77q13.33,19.17,13.34,45.41a81.72,81.72,0,0,1-12,43.69q-12,19.59-35.08,31.2t-53.58,11.62q-34.86,0-69.3-14.63h0a32.33,32.33,0,0,1-44.76-22.84q-1.34-6.37-2.58-13.53-6-34.65-6-61.33Z"
            fill="#010101"
          />
          <path
            d="M1039.49,238.48c-9.5,0-18.09-6.29-20.31-15.53q-4.39-18.22-12.85-30.53Q994.5,175.21,976,175.21q-26.25,0-41.75,24.75t-15.49,76.39q0,62.41,24.1,91.25t62.4,28.83q18.51,0,32.72-6.88a48,48,0,0,0,16.18-12.12c5.92-7.16,9.35.76,12.07,6.16a37.09,37.09,0,0,1,3.87,13.46c.83,9.44-4.58,17.74-10.82,24.35q-11,11.61-31.63,19.58t-47.77,8q-37.89,0-69.09-17.65t-49.71-53.36q-18.51-35.73-18.5-86.51,0-49.49,16.79-84.14t47.12-52.08q30.35-17.43,70.8-17.44a104.16,104.16,0,0,1,21.52,2.59,96.32,96.32,0,0,1,21.09,6.88c4.3-2,10.61-4,19.19-6.08A20.68,20.68,0,0,1,1064,157q5.23,23.61,6.37,60.24a20.67,20.67,0,0,1-20.69,21.22Z"
            fill="#010101"
          />
          <path
            d="M519.7,201.29q-15.5-31.8-41.38-47.57A107.34,107.34,0,0,0,421.45,138q-45.06,0-68.13,22.52-3.37-12.94-10.7-17.73c-4.89-3.19-11.26-4.79-19.15-4.79q-15.2,0-43.07,8.16a225.2,225.2,0,0,0-37.9,14.49,14.49,14.49,0,0,0-8,12.93v3.28A14.34,14.34,0,0,0,246.94,191q11.2,1.42,16.85,5.76,8.44,6.48,8.45,21.68v269.7h88.4V477.24h0V375.39h0V191.44a34.84,34.84,0,0,1,15.76-3.38q27.59,0,44.48,24.78t16.9,79.38q0,57.45-15.49,86.16Q412.15,397.16,398,403.57c-7.85,3.58-13.17,11.05-13.17,19.68v1.5a21.46,21.46,0,0,0,25,21.17,147.28,147.28,0,0,0,49.93-18q34.9-20.28,55.18-58.57t20.27-90.65Q535.19,233.11,519.7,201.29Z"
            fill="#010101"
          />
          <path
            d="M806.56,288.57q7.89-14.35,7.89-39.13,0-52.93-30.4-82.77T702.4,136.83q-40.54,0-72.63,19.42t-50.4,55.46q-18.3,36-18.29,83.9,0,46.72,16.88,81.08t48.15,52.36q31.25,18,72.92,18,29.82,0,53.76-9.57t37.45-22.81q13.51-13.21,13.51-23.36,0-6.76-3.37-15.77l-.07-.18A12.59,12.59,0,0,0,782.09,369a106.12,106.12,0,0,1-55.48,15.57q-66,0-69.75-80.68h-.07v-44h0q1.14-45.44,12.93-64.81T699,175.67q13.5,0,21.39,13t7.88,46.17q0,13-2.82,19.14t-11.81,6.2h-24.9A7.75,7.75,0,0,0,681,267.9v13.63a21.38,21.38,0,0,0,21.39,21.39h76Q798.68,302.92,806.56,288.57Z"
            fill="#010101"
          />
          <path
            d="M1212,383.45V297.29h0V277h0V26.47q0-14.07-5.91-20.27T1186.63,0q-18,0-49.28,8.45-25,6.77-43,15a14.48,14.48,0,0,0-8.54,13.18h0a14.26,14.26,0,0,0,12.31,14.21q12.09,1.52,17.53,6.36,7.88,7,7.88,21.11V383.45q0,16.32-7,23.36a21.39,21.39,0,0,1-8.72,5,16.33,16.33,0,0,0-8.18,24.77l.68.91a16.33,16.33,0,0,0,13,6.16h109.34a16.37,16.37,0,0,0,13.12-6.32l.56-.76a16.29,16.29,0,0,0-8.32-24.72,21.33,21.33,0,0,1-9-5.08Q1211.95,399.78,1212,383.45Z"
            fill="#010101"
          />
          <path
            d="M1408.42,421.73c-.3-7.27-4.79-13.46-11.25-16.79a49.23,49.23,0,0,1-16-12.77q-9.27-11.54-27.86-45.89-.57-1.12-14.64-26.46-11.84-21.39-26.18-31.82t-35.19-13.79l85.58-84.45a72,72,0,0,1,15.48-11.27,30.63,30.63,0,0,1,7-2.52c8.4-2,14.32-9.9,12.36-18.31a46.16,46.16,0,0,0-1.81-6c-2.71-7.13-10.05-11.44-17.69-11.44H1272.09A19.17,19.17,0,0,0,1255,150.31a54.53,54.53,0,0,0-2.51,5.66c-.13.35-.26.7-.38,1.05-2.95,8.39,2.41,17.07,10.81,20a59.33,59.33,0,0,1,9.56,4.32q12.1,6.76,12.1,14.64,0,6.21-10.69,16.9l-9.65,10a100,100,0,0,0-28.06,69.47h0A98.44,98.44,0,0,0,1246.72,337q5.08,10,10.85,22.83,12.38,28.17,23.08,45.61t29.56,29.84q18.87,12.38,47.58,12.39,21.39,0,35.19-6.48t15.49-18.29Z"
            fill="#010101"
          />
          <path
            d="M272.26,184V489.15q0,16.34-7,23.37a21.36,21.36,0,0,1-8.73,5,16.32,16.32,0,0,0-8.18,24.76c.23.31.45.62.69.92a16.3,16.3,0,0,0,13,6.15H371.33a16.37,16.37,0,0,0,13.12-6.31l.56-.76a16.29,16.29,0,0,0-8.32-24.72,21.33,21.33,0,0,1-9-5.08q-7.06-7-7-23.37V403"
            fill="#010101"
          />
        </g>
      </g>
    </svg>
  );
}
