import React from 'react';

import concatClassNames from 'utils/classNames';

interface WidgetProps {
  children: JSX.Element;
  isFullHeight?: boolean;
  type: WidgetType;
}

export type WidgetType = '1/2' | '1/3' | '2/3' | 'full';

export const widgetTypeStyle: Record<WidgetType, string> = {
  '1/2': 'w-[50%]',
  '1/3': 'w-[28%]',
  '2/3': 'w-[72%]',
  full: 'w-full',
};
export function Widget({
  children,

  type = '1/2',
}: WidgetProps): JSX.Element {
  return (
    <div
      className={concatClassNames(
        'bg-white',
        'relative',
        'rounded shadow-sm',
        widgetTypeStyle[type],
        'flex-grow overflow-auto',
        'relative',
      )}
    >
      {children}
    </div>
  );
}
