import { type HistoryHandler, SpecksApisContext, useHistoryHandler } from 'App';

import React, { useContext } from 'react';
import { useQuery } from 'react-query';

import { useAuth0 } from '@auth0/auth0-react';

import { Menu } from 'components/core/Menu/Menu';

import { type Company } from 'models/company.entity';

import concatClassNames from 'utils/classNames';

interface BaseProps {
  children: JSX.Element | JSX.Element[];
  menu?: boolean;
}

export function BaseEditPage({ children, menu = true }: BaseProps): JSX.Element {
  const historyHandler: HistoryHandler = useHistoryHandler();
  if (historyHandler.actualPath !== window.location.pathname + window.location.search) {
    historyHandler.oldPath = historyHandler.actualPath;
    historyHandler.actualPath = window.location.pathname + window.location.search;
  }

  const { getAccessTokenSilently, user } = useAuth0();
  const { companyService } = useContext(SpecksApisContext);

  async function getCompanyByUserId(): Promise<Company[]> {
    const accessToken: string = await getAccessTokenSilently();
    if (user === undefined) return [];
    return await companyService.getCompanyByUserId(accessToken);
  }

  const { data: companyData, status: companyStatus } = useQuery<Company[], Error>(
    ['company', user?.sub],
    getCompanyByUserId,
  );

  const isTrial: boolean =
    companyStatus === 'success' && companyData.length > 0 ? companyData[0].companyStatus === 'TRIAL' : false;

  return (
    <>
      <div className={concatClassNames('flex flew-row', 'items-stretch', 'h-screen', 'p-0')}>
        {menu && <Menu />}
        <div
          className={concatClassNames(
            'flex flex-col',
            'relative',
            isTrial ? 'pt-9' : '',
            'items-stretch',
            'bg-white',
            'flex-grow',
          )}
        >
          {isTrial && (
            <div
              id="trial-header"
              className={concatClassNames(
                'absolute',
                'top-0 left-0 right-0',
                'flex',
                'py-1.5',
                'bg-[#fae0c3]',
                'justify-center',
                'items-center',
              )}
            >
              <p className={concatClassNames('text-black text-lg')}>{"Bienvenue dans votre version d'essai offerte"}</p>
            </div>
          )}
          {children}
        </div>
      </div>
    </>
  );
}
