import * as Yup from 'yup';

import React from 'react';
import { type SubmitHandler, useForm } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';

import { Button } from 'components/core/Button/Button';
import Input from 'components/core/Input/Input';
import TextArea from 'components/core/TextArea/TextArea';

import { FaqEntry } from 'models/faqEntry.entity';

interface CreateFaqEntryFormModel {
  question: string;
  answer: string;
}

const schema: Yup.ObjectSchema<CreateFaqEntryFormModel> = Yup.object().shape({
  question: Yup.string().required('Champ obligatoire').min(3, 'Le nom doit contenir au moins 3 caractères'),
  answer: Yup.string().required('Champ obligatoire'),
});

interface CreateFaqEntryFormProps {
  onSubmit: (faqEntry: FaqEntry) => void;
  isLoading: boolean;
}

export function CreateFaqEntryForm({ onSubmit, isLoading }: CreateFaqEntryFormProps): JSX.Element {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors, isSubmitted },
  } = useForm<CreateFaqEntryFormModel>({
    resolver: yupResolver(schema),
  });

  const watchQuestion: string = watch('question');
  const watchAnswer: string = watch('answer');

  let isFormInvalid: boolean = true;
  if (watchQuestion !== undefined && watchQuestion !== '' && watchAnswer !== '') {
    isFormInvalid = false;
  }

  const onSubmitForm: SubmitHandler<CreateFaqEntryFormModel> = (data) => {
    const faqEntry: FaqEntry = new FaqEntry();
    faqEntry.question = data.question;
    faqEntry.answer = data.answer;
    onSubmit(faqEntry);
  };

  return (
    <form className="flex flex-col gap-6" onSubmit={handleSubmit(onSubmitForm)}>
      <Input
        label="Question"
        placeholder="Ex : A quelle date prendra effet la nouvelle réglementation ?"
        // maxLength={28}
        {...register('question')}
        error={errors.question != null && isSubmitted ? errors.question.message : undefined}
      />
      <TextArea
        label="Réponse"
        placeholder="Ex : La nouvelle réglementation prendra effet au 1er janvier 2026."
        height="md"
        {...register('answer')}
        error={errors.answer != null && isSubmitted ? errors.answer.message : undefined}
      />
      <div className="flex flex-row gap-2 justify-end">
        {!isLoading && <Button type="submit" content="Créer" width="1/2" disabled={isFormInvalid} />}
        {isLoading && <Button iconName="spinCircle" width="1/2" height="sm" iconAnimation="spin" />}
      </div>
    </form>
  );
}
