import * as Yup from 'yup';

import React, { useEffect, useState } from 'react';
import { type SubmitHandler, useForm } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';

import { Button } from 'components/core/Button/Button';
import Input from 'components/core/Input/Input';
import RadioButtons from 'components/core/RadioButtons/RadioButtons';
import { TabElement } from 'components/core/Tabs/TabElement';
import { TabList } from 'components/core/Tabs/TabList';
import { TabPanel } from 'components/core/Tabs/TabPanel';
import { TabPanels } from 'components/core/Tabs/TabPanels';
import { Tabs } from 'components/core/Tabs/Tabs';
import { Text } from 'components/core/Text/Text';
import TextArea from 'components/core/TextArea/TextArea';

import { type UiComponent } from 'models/uiComponent.entity';

import concatClassNames from 'utils/classNames';

import { UiComponentCard } from '../UiComponentCard/UiComponentCard';

interface IconsInfosFormProps {
  uiComponent: UiComponent;
  checkIfNameIsUnique?: (name: string, id: string) => boolean;
  onClickSave?: () => void;
  setIsFormDirty?: (isDirty: boolean) => void;
  onClickAddTarget?: () => void;
  setFnSetMethodId?: (fn: (methodId: string | undefined) => void) => void;
  isLoading: boolean;
}

interface IconsFormModel {
  name: string;
  notes?: string;
}

const schema: Yup.ObjectSchema<IconsFormModel> = Yup.object().shape({
  name: Yup.string()
    .required('Champ obligatoire')
    .max(28, 'Le nom ne doit pas contenir plus de 28 caractères')
    .test('isUnique', "Un composant d'interface avec ce nom existe déjà", (value, context) => {
      const uiComponent: UiComponent | undefined = context?.options?.context?.uiComponent as UiComponent;
      const isNameUnique: boolean | undefined = context?.options?.context?.checkIfNameIsUnique(value, uiComponent.id);
      return value != null && value.length > 0 && isNameUnique;
    }),
  notes: Yup.string(),
});

export function IconsInfosForm({
  uiComponent,
  checkIfNameIsUnique,
  onClickSave,
  setIsFormDirty,
  onClickAddTarget,
  setFnSetMethodId,
  isLoading,
}: IconsInfosFormProps): JSX.Element {
  /* --------------------------------------------------- Contexts --------------------------------------------------- */

  /* ----------------------------------------------------- Form ----------------------------------------------------- */

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors, isSubmitted },
  } = useForm<IconsFormModel>({
    resolver: yupResolver(schema),
    context: { checkIfNameIsUnique, uiComponent },
  });

  /* --------------------------------------------------- States --------------------------------------------------- */

  const [originalName, setOriginalName] = useState<string>();
  const [originalNotes, setOriginalNotes] = useState<string>();
  const watchName: string = watch('name');
  const watchNotes: string | undefined = watch('notes');
  const isEditable: boolean = onClickSave != null;

  /* -------------------------------------------------- Setup Data -------------------------------------------------- */

  // Setup the original values of the form (from the database)
  useEffect(() => {
    setOriginalName(uiComponent.uiComponentRevisions[0].name);
    setOriginalNotes(uiComponent.uiComponentRevisions[0].notes);

    setValue('name', uiComponent.uiComponentRevisions[0].name);
    setValue('notes', uiComponent.uiComponentRevisions[0].notes);
  }, [setValue, uiComponent]);

  /* --------------------------------------------------- Functions -------------------------------------------------- */

  function handleOnClickSave(): void {
    if (onClickSave == null) return;
    uiComponent.uiComponentRevisions[0].name = watchName;
    uiComponent.uiComponentRevisions[0].notes = watchNotes ?? '';
    onClickSave();
  }

  const onSubmitForm: SubmitHandler<IconsFormModel> = (data: IconsFormModel) => {
    handleOnClickSave();
  };

  /* --------------------------------------------------- Watchers --------------------------------------------------- */

  let isFormValid: boolean = true;
  if (watchName === undefined || watchName.length < 3 || (watchName === originalName && watchNotes === originalNotes)) {
    isFormValid = false;
  }
  setIsFormDirty?.(false);
  if (watchName !== originalName || watchNotes !== originalNotes) {
    setIsFormDirty?.(true);
  }

  /* --------------------------------------------------- Rendering -------------------------------------------------- */

  return (
    <Tabs>
      <TabList>
        <TabElement textSize="base" title={'Spécification'}></TabElement>
        <TabElement textSize="base" title={'Règles logiques'}></TabElement>
      </TabList>
      <TabPanels>
        <TabPanel>
          <form
            className="flex flex-col gap-6 justify-between flex-grow overflow-auto mt-2"
            onSubmit={handleSubmit(onSubmitForm)}
          >
            <div className="flex flex-col gap-6 overflow-auto">
              {/* <div className={concatClassNames('flex flex-col', 'gap-5', 'items-start')}>
                <Text content="Type de composant d'interface" weight="bold" size="base" position="center" />
                <UiComponentCard type="icon" horizontalMargin={'none'} isDraggable={false} />
              </div> */}

              <div className={concatClassNames('flex flex-col', 'gap-1')}>
                <Input
                  label="Cet identifiant vous permettra de le retrouver plus facilement dans Specks."
                  placeholder="L'identifiant Specks de votre composant d'interface"
                  textSize="base"
                  {...register('name')}
                  value={watchName}
                  maxLength={28}
                  error={errors.name != null && isSubmitted ? errors.name.message : undefined}
                  disabled={!isEditable}
                />
                {watchName !== undefined && watchName.length < 3 && (
                  <Text
                    content="Le nom doit contenir au moins 3 caractères"
                    color="red-500"
                    size="sm"
                    position="center"
                  />
                )}
              </div>

              <TextArea
                label="Obectif fonctionel de l'icône"
                // value={!isEditable && (watch === undefined || watch.length === 0) ? 'N/A' : watch}
                labelSize="base"
              />

              <div className={concatClassNames('flex flex-col gap-1')}>
                <RadioButtons
                  name=""
                  label="URL de l'icône"
                  values={['Constant', 'Règles logiques']}
                  selected="Constant"
                  isEditable={isEditable}
                />
              </div>

              <TextArea
                label="Notes"
                labelSize="base"
                placeholder="Ajouter une note"
                textSize="base"
                {...register('notes')}
                value={!isEditable && (watchNotes === undefined || watchNotes.length === 0) ? 'N/A' : watchNotes}
                rows={6}
                error={errors.notes != null && isSubmitted ? errors.notes.message : undefined}
                disabled={!isEditable}
              />
            </div>

            {onClickSave != null && (
              <div className="flex flex-row gap-2 justify-end">
                {!isLoading && <Button content="Sauvegarder" type="submit" disabled={!isFormValid} />}
                {isLoading && <Button iconName="spinCircle" type="button" height="sm" iconAnimation="spin" />}
              </div>
            )}
          </form>
        </TabPanel>
        <TabPanel>
          <div className={concatClassNames('flex flex-col flex-grow justify-between')}>
            <div className={concatClassNames('flex flex-col gap-4')}>
              <div className={concatClassNames('flex flex-col gap-1')}>
                <Text content="Règles du Composant d'interface" weight="bold" position="left" />
                <Text
                  content="Aucune règle n'a été définie pour ce composant. "
                  color="gray-100"
                  position="left"
                  size="sm"
                />
              </div>
              <div className={concatClassNames('flex flex-col gap-1')}>
                <Text content="Règles héritées (non modifiables)" weight="bold" position="left" />
                <Text
                  content="Ce composant ne reçoit aucune règle en héritage"
                  color="gray-100"
                  position="left"
                  size="sm"
                />
              </div>
            </div>
            {onClickSave != null && (
              <div className={concatClassNames('flex gap-2 pt-8 border-t-1 border-gray-50')}>
                <Button content="Ajouter une règle" iconName="plus" cursor="not-allowed" />
                <Button content="Sauvegarder" cursor="not-allowed" />
              </div>
            )}
          </div>
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
}
