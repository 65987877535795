import * as Yup from 'yup';

import React, { useEffect, useState } from 'react';
import { type SubmitHandler, useForm } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';

import { Button } from 'components/core/Button/Button';
import Input from 'components/core/Input/Input';
import { TabElement } from 'components/core/Tabs/TabElement';
import { TabList } from 'components/core/Tabs/TabList';
import { TabPanel } from 'components/core/Tabs/TabPanel';
import { TabPanels } from 'components/core/Tabs/TabPanels';
import { Tabs } from 'components/core/Tabs/Tabs';
import { Text } from 'components/core/Text/Text';
import TextArea from 'components/core/TextArea/TextArea';

import { type UiComponent } from 'models/uiComponent.entity';

import concatClassNames from 'utils/classNames';

import UICFieldReadOnly from '../UICFieldReadonly/UICFieldReadonly';
import { UiComponentCard } from '../UiComponentCard/UiComponentCard';

interface ContainerInfosFormProps {
  uiComponent: UiComponent;
  checkIfNameIsUnique?: (name: string, id: string) => boolean;
  onClickSave?: () => void;
  onClickAddTarget?: () => void;
  setIsFormDirty?: (isDirty: boolean) => void;
  setFnSetMethodId?: (fn: (methodId: string | undefined) => void) => void;
  isLoading: boolean;
}

interface TextFormModel {
  name: string;
  notes?: string;
  usedFor?: string;
  sourceMethodId: string | undefined;
}

const schema: Yup.ObjectSchema<TextFormModel> = Yup.object().shape({
  name: Yup.string()
    .required('Champ obligatoire')
    .max(28, 'Le nom ne doit pas contenir plus de 28 caractères')
    .test('isUnique', "Un composant d'interface avec ce nom existe déjà", (value, context) => {
      const uiComponent: UiComponent | undefined = context?.options?.context?.uiComponent as UiComponent;
      const isNameUnique: boolean | undefined = context?.options?.context?.checkIfNameIsUnique(value, uiComponent.id);
      return value != null && value.length > 0 && isNameUnique;
    }),
  sourceMethodId: Yup.string(),
  notes: Yup.string(),
  usedFor: Yup.string(),
});

export function ContainerInfosForm({
  uiComponent,
  checkIfNameIsUnique,
  onClickSave,
  onClickAddTarget,
  setIsFormDirty,
  setFnSetMethodId,
  isLoading,
}: ContainerInfosFormProps): JSX.Element {
  /* --------------------------------------------------- Contexts --------------------------------------------------- */

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors, isSubmitted },
  } = useForm<TextFormModel>({
    resolver: yupResolver(schema),
    context: { checkIfNameIsUnique, uiComponent },
  });

  /* --------------------------------------------------- States --------------------------------------------------- */

  const [originalName, setOriginalName] = useState<string>('');
  const [originalNotes, setOriginalNotes] = useState<string>('');
  const [originalUsedFor, setOriginalUsedFor] = useState<string>('');
  const watchName: string = watch('name');
  const watchNotes: string | undefined = watch('notes');
  const watchUsedFor: string | undefined = watch('usedFor');
  const isEditable: boolean = onClickSave != null;

  /* ----------------------------------------------- alerts management ---------------------------------------------- */

  const isCompleteName: boolean = watchName !== undefined && watchName.length !== 0;
  const isCompleteUsedFor: boolean = watchUsedFor !== undefined && watchUsedFor.length > 0;

  /* -------------------------------------------------- Setup Data -------------------------------------------------- */

  useEffect(() => {
    setOriginalName(uiComponent.uiComponentRevisions[0].name);
    setOriginalNotes(uiComponent.uiComponentRevisions[0].notes);
    setOriginalUsedFor(uiComponent.uiComponentRevisions[0].usedFor);
    setValue('name', uiComponent.uiComponentRevisions[0].name);
    setValue('notes', uiComponent.uiComponentRevisions[0].notes);
    setValue('usedFor', uiComponent.uiComponentRevisions[0].usedFor);
  }, [setValue, uiComponent, originalName, originalNotes, originalUsedFor]);

  /* --------------------------------------------------- Functions -------------------------------------------------- */

  function handleOnClickSave(): void {
    if (onClickSave == null) return;
    uiComponent.uiComponentRevisions[0].name = watchName;
    uiComponent.uiComponentRevisions[0].notes = watchNotes ?? '';
    uiComponent.uiComponentRevisions[0].usedFor = watchUsedFor ?? '';
    uiComponent.uiComponentRevisions[0].isFormValid = checkIfFormIsComplete;
    onClickSave();
  }

  const onSubmitForm: SubmitHandler<TextFormModel> = (data: TextFormModel) => {
    handleOnClickSave();
  };

  /* --------------------------------------------------- Watchers --------------------------------------------------- */

  const checkIfFormIsComplete: boolean = isCompleteName && isCompleteUsedFor;

  let isFormValid: boolean = true;
  if (
    watchName === undefined ||
    watchName.length === 0 ||
    (watchName === originalName && watchNotes === originalNotes && watchUsedFor === originalUsedFor)
  ) {
    isFormValid = false;
  }
  setIsFormDirty?.(false);
  if (watchName !== originalName || watchNotes !== originalNotes || watchUsedFor !== originalUsedFor) {
    setIsFormDirty?.(true);
  }
  if (originalName === '' && watchName === '') setIsFormDirty?.(true);

  /* --------------------------------------------------- Rendering -------------------------------------------------- */

  return (
    <Tabs>
      <TabList>
        <TabElement textSize="base" title={'Spécification'}></TabElement>
        <TabElement textSize="base" title={'Règles logiques'}></TabElement>
      </TabList>
      <TabPanels>
        <TabPanel>
          <form
            className="flex flex-col gap-6 justify-between flex-grow overflow-auto mt-2"
            onSubmit={handleSubmit(onSubmitForm)}
          >
            <div className="flex flex-col gap-6 overflow-auto">
              <div className={concatClassNames('flex flex-col', 'gap-1')}>
                <UICFieldReadOnly
                  label="Identifiant Specks"
                  information="Cet identifiant vous permettra de le retrouver plus facilement dans Specks."
                  content={isEditable ? undefined : watchName}
                />
                {isEditable && (
                  <Input
                    labelWeight="medium"
                    placeholder="L'identifiant Specks de votre composant d'interface"
                    textSize="base"
                    {...register('name')}
                    maxLength={28}
                    error={errors.name != null && isSubmitted ? errors.name.message : undefined}
                  />
                )}
                {watchName !== undefined && watchName.length === 0 && (
                  <Text content="Le nom est obligatoire" color="red-500" size="sm" position="center" />
                )}
              </div>
              <div className={concatClassNames('flex flex-col', 'gap-2')}>
                <UICFieldReadOnly
                  label="A quoi sert-il ?"
                  information="Quelle est la fonction de ce conteneur ?"
                  isComplete={isCompleteUsedFor}
                  error="Préconisation : ce champ ne devrait pas être vide"
                />
                <TextArea
                  textSize="sm"
                  {...register('usedFor')}
                  rows={6}
                  error={errors.usedFor != null && isSubmitted ? errors.usedFor.message : undefined}
                  disabled={!isEditable}
                />
              </div>

              <TextArea
                label="Notes"
                placeholder="Ajouter une note"
                textSize="sm"
                // labelHeight="base"
                {...register('notes')}
                rows={6}
                error={errors.notes != null && isSubmitted ? errors.notes.message : undefined}
                disabled={!isEditable}
              />
            </div>

            {onClickSave != null && (
              <div className="flex flex-row gap-2 justify-end">
                {!isLoading && <Button content="Sauvegarder" type="submit" disabled={!isFormValid} />}
                {isLoading && <Button iconName="spinCircle" iconAnimation="spin" />}
              </div>
            )}
          </form>
        </TabPanel>
        <TabPanel>
          <div className={concatClassNames('flex flex-col flex-grow justify-between')}>
            <div className={concatClassNames('flex flex-col gap-4')}>
              <div className={concatClassNames('flex flex-col gap-1')}>
                <Text content="Règles du Composant d'interface" weight="bold" position="left" />
                <Text
                  content="Aucune règle n'a été définie pour ce composant. "
                  color="gray-100"
                  position="left"
                  size="sm"
                />
              </div>
              <div className={concatClassNames('flex flex-col gap-1')}>
                <Text content="Règles héritées (non modifiables)" weight="bold" position="left" />
                <Text
                  content="Ce composant ne reçoit aucune règle en héritage"
                  color="gray-100"
                  position="left"
                  size="sm"
                />
              </div>
            </div>
            {onClickSave != null && (
              <div className={concatClassNames('flex gap-2 pt-8 border-t-1 border-gray-50')}>
                <Button content="Ajouter une règle" iconName="plus" cursor="not-allowed" />
                <Button content="Sauvegarder" cursor="not-allowed" />
              </div>
            )}
          </div>
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
}
