import * as Yup from 'yup';

import React from 'react';
import { Controller, type SubmitHandler, useForm } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';
import { Tooltip } from '@mui/material';

import { Button } from 'components/core/Button/Button';
import Input from 'components/core/Input/Input';
import { ListBox } from 'components/core/ListBox/ListBox';
import { Switch } from 'components/core/Switch/Switch';
import { Text } from 'components/core/Text/Text';
import { DecommissionedStatusChip } from 'components/specks/Chips/status/DecommissionedStatusChip/DecommissionedStatusChip';
import { InDevelopmentStatusChip } from 'components/specks/Chips/status/InDevelopmentStatusChip/InDevelopmentStatusChip';
import { ProductionStatusChip } from 'components/specks/Chips/status/ProductionStatusChip/ProductionStatusChip';

import { ComponentType } from 'models/ComponentTypes.type';
import { Status } from 'models/Status.types';
import { Component } from 'models/component.entity';

import getTypeChip from 'utils/getTypeChip';

interface CreateOrEditComponentFormModel {
  name: string;
  componentType: ComponentType;
  external: boolean;
  status: Status;
}

const schema: Yup.ObjectSchema<CreateOrEditComponentFormModel> = Yup.object().shape({
  name: Yup.string().required('Champ obligatoire').min(3, 'Le nom doit contenir au moins 3 caractères'),
  status: Yup.mixed<Status>().required('Champ obligatoire').default(Status.Indevelopment),
  componentType: Yup.mixed<ComponentType>().required('Champ obligatoire').default(ComponentType.Front),
  external: Yup.boolean().required('Champ obligatoire').default(false),
});

interface CreateOrEditComponentFormProps {
  onSubmit: (product: Component) => void;
  isEditing?: boolean;
  component?: Component;
  isLoading: boolean;
}

const componentTypeOptions: string[] = [ComponentType.Front, ComponentType.BackApi, ComponentType.Database];

const componentTypeStyleOptions: Map<string, JSX.Element> = new Map<string, JSX.Element>([
  [ComponentType.Front, getTypeChip(ComponentType.Front, 'white', 'pink')],
  [ComponentType.BackApi, getTypeChip(ComponentType.BackApi, 'white', 'blue')],
  [ComponentType.Database, getTypeChip(ComponentType.Database, 'white', 'yellow')],
]);

export const statusOptions: string[] = [Status.Indevelopment, Status.Production, Status.Decommissioned];

export const statusStyleOptions: Map<string, JSX.Element> = new Map<string, JSX.Element>([
  [Status.Indevelopment, <InDevelopmentStatusChip key={Status.Indevelopment} />],
  [Status.Production, <ProductionStatusChip key={Status.Production} />],
  [Status.Decommissioned, <DecommissionedStatusChip key={Status.Decommissioned} />],
]);

export function CreateOrEditComponentForm({
  onSubmit,
  isEditing = false,
  component,
  isLoading,
}: CreateOrEditComponentFormProps): JSX.Element {
  const {
    register,
    control,
    handleSubmit,
    watch,
    formState: { errors, isSubmitted },
  } = useForm<CreateOrEditComponentFormModel>({
    resolver: yupResolver(schema),
    defaultValues: {
      name: isEditing && component !== undefined ? component?.name : '',
      status: isEditing && component !== undefined ? component?.status : Status.Indevelopment,
      componentType: isEditing && component !== undefined ? component?.componentType : ComponentType.Front,
      external: isEditing && component !== undefined ? !component?.internal : false,
    },
  });

  const watchName: string = watch('name');

  let isFormValid: boolean = true;

  if (watchName === undefined || watchName.length < 3) {
    isFormValid = false;
  }

  const onSubmitForm: SubmitHandler<CreateOrEditComponentFormModel> = (data) => {
    const component: Component = new Component();
    component.name = data.name;
    component.status = data.status;
    component.componentType = data.componentType;
    component.internal = !data.external;
    onSubmit(component);
  };

  return (
    <form className="flex flex-col gap-6" onSubmit={handleSubmit(onSubmitForm)}>
      <Input
        placeholder="Nom du composant"
        {...register('name', {
          required: 'Champ obligatoire',
          maxLength: {
            value: 28,
            message: 'Le nom du composant ne doit pas dépasser 28 caractères',
          },
        })}
        maxLength={28}
        readonly={isLoading}
        error={errors.name != null && isSubmitted ? errors.name.message : undefined}
      />

      {!isEditing && (
        <div className="flex flex-row items-center gap-2">
          <Tooltip
            title="Si vous avez un doute, validez cette information auprès de votre tech lead."
            placement="right"
          >
            <div>
              <Text size="2xl" content="👩‍💻"></Text>
            </div>
          </Tooltip>
          <Controller
            name="componentType"
            control={control}
            defaultValue={ComponentType.Front}
            rules={{ required: true }}
            render={({ field: { value, onChange } }) => (
              <ListBox
                label="Type de composant"
                selected={value}
                onChange={onChange}
                options={componentTypeOptions}
                styleOptions={componentTypeStyleOptions}
                readonly={isLoading}
                width="full"
              />
            )}
          />
        </div>
      )}

      <div className="flex flex-row items-center gap-2">
        <Text
          content="Ce composant est un service tiers, externe à mon entreprise"
          color="black"
          size="base"
          weight="medium"
          position="center"
        />
        <Controller
          control={control}
          name="external"
          render={({ field: { ref, ...field } }) => <Switch {...field} readonly={isLoading} />}
        />
      </div>

      <Controller
        name="status"
        control={control}
        defaultValue={Status.Indevelopment}
        rules={{ required: true }}
        render={({ field: { value, onChange } }) => (
          <ListBox
            label="Statut du composant"
            selected={value}
            onChange={onChange}
            options={statusOptions}
            styleOptions={statusStyleOptions}
            readonly={isLoading}
          />
        )}
      />

      {errors.status != null && isSubmitted && <p>{errors.status.message}</p>}
      <div className="flex flex-row gap-2 justify-end">
        {isLoading && <Button iconName="spinCircle" type="button" width="1/2" height="sm" iconAnimation="spin" />}
        {!isLoading && <Button content="Créer" type="submit" width="1/2" disabled={!isFormValid} />}
      </div>
    </form>
  );
}
