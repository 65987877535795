import { type GetUiComponentRevisionInfoDto } from 'api';

import { Page } from 'models/page.entity';
import { PageRevision } from 'models/pageRevision.entity';
import { UiComponentRevision } from 'models/ui-component-revisions.entity';

import { fromGetUiComponentLightInfoDto } from './uiComponentFactory';

export function fromGetUiComponentRevisionInfoDtoToPage(
  getUiComponentRevisionInfoDto: GetUiComponentRevisionInfoDto,
): Page {
  const page: Page = new Page();
  page.id = getUiComponentRevisionInfoDto.page.id;
  page.pageRevisions = [new PageRevision()];
  page.pageRevisions[0].uiComponents = [fromGetUiComponentLightInfoDto(getUiComponentRevisionInfoDto.uiComponent)];
  page.pageRevisions[0].uiComponents[0].uiComponentRevisions = [new UiComponentRevision()];
  page.pageRevisions[0].uiComponents[0].uiComponentRevisions[0].id = getUiComponentRevisionInfoDto.id;
  page.pageRevisions[0].uiComponents[0].uiComponentRevisions[0].name = getUiComponentRevisionInfoDto.name;
  return page;
}
