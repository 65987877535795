/* eslint-disable max-len */
import React from 'react';

import concatClassNames from 'utils/classNames';

import { type IconProps, iconColorStyle, iconSizeStyle } from './Icon.props';

export default function ContainerIcon({ color, size }: IconProps): JSX.Element {
  return (
    <svg className={iconSizeStyle[size]} viewBox="0 0 40 39" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.1361 1.50586H6.23724C5.19426 1.50586 4.194 1.92018 3.45651 2.65768C2.71901 3.39518 2.30469 4.39544 2.30469 5.43841V11.3372M37.6977 11.3372V5.43841C37.6977 4.39544 37.2834 3.39518 36.5459 2.65768C35.8084 1.92018 34.8081 1.50586 33.7651 1.50586H27.8663M27.8663 36.8989H33.7651C34.8081 36.8989 35.8084 36.4845 36.5459 35.747C37.2834 35.0095 37.6977 34.0093 37.6977 32.9663V27.0675M2.30469 27.0675V32.9663C2.30469 34.0093 2.71901 35.0095 3.45651 35.747C4.194 36.4845 5.19426 36.8989 6.23724 36.8989H12.1361"
        stroke={iconColorStyle[color]}
        strokeWidth="2.94942"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
