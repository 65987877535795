import React, { useState } from 'react';
import { type NavigateFunction, useNavigate } from 'react-router-dom';

import { Card } from 'components/core/Card/Card';
import { CardBody } from 'components/core/Card/CardBody';
import { IconBadge } from 'components/core/IconBadge/IconBadge';
import { Settings } from 'components/core/Settings/Settings';
import { type ButtonMenuProps } from 'components/core/Settings/Settings.types';
import { Text } from 'components/core/Text/Text';

import concatClassNames from 'utils/classNames';
import getIcon from 'utils/getIcon';

import { type IconName } from 'icons/Icon.props';

type PageCardProps =
  | {
      id?: string;
      name: string;
      href: string;
      loading?: false;
      onDeletionClick?: (id: string) => void;
      onEditClick?: (id: string) => void;
    }
  | {
      id?: never;
      name?: never;
      href?: never;
      loading: true;
      onDeletionClick?: never;
      onEditClick?: never;
    };

export function PageCard({ id = '', name = '', loading = false, href, onDeletionClick }: PageCardProps): JSX.Element {
  const navigate: NavigateFunction = useNavigate();

  function handleClick(): void {
    if (loading) return;
    if (href === undefined) console.error('href is null or undefined');
    else {
      navigate(href);
    }
  }

  /* ---------------------------------------------------- states ---------------------------------------------------- */

  const [isCardHovered, setCardIsHovered] = useState<boolean>(false);
  const [isIconHovered, setIconIsHovered] = useState<boolean>(false);

  /* --------------------------------------------------- functions -------------------------------------------------- */

  function handleDeletionClick(event: React.MouseEvent<HTMLDivElement, MouseEvent>): void {
    event.stopPropagation();
    if (onDeletionClick !== undefined) onDeletionClick(id);
  }

  return (
    <div
      className={concatClassNames('flex justify-center')}
      onMouseEnter={() => {
        setCardIsHovered(true);
      }}
      onMouseLeave={() => {
        setCardIsHovered(false);
      }}
    >
      <Card
        onClick={handleClick}
        loading={loading}
        shadow="md"
        height="smd"
        width="smd"
        cursor="pointer"
        background={isCardHovered ? 'sand' : undefined}
      >
        <CardBody orientation="vertical" justify="center" horizontalPadding="lg" gap="md">
          {loading ? (
            <>
              <IconBadge loading />
              <Text loading />
            </>
          ) : (
            <>
              {getIcon('feather', 'cyan', 'xl')}
              <Text content={name} color="black" weight="bold" size="base" position="left" />
              {isCardHovered && (
                <div className={concatClassNames('absolute -bottom-2', 'flex flex-col', 'w-full', 'items-center')}>
                  <div
                    className={concatClassNames(
                      'flex items-center',
                      'border-2 border-gray-50 hover:border-red-400',
                      'rounded-full',
                      'w-fit',
                      'p-2',
                      'bg-white hover:bg-gray-25',
                    )}
                    onMouseEnter={() => {
                      setIconIsHovered(true);
                    }}
                    onMouseLeave={() => {
                      setIconIsHovered(false);
                    }}
                    onClick={handleDeletionClick}
                  >
                    {isIconHovered ? getIcon('trash', 'red', 'md') : getIcon('trash', 'gray', 'md')}
                  </div>
                </div>
              )}
            </>
          )}
        </CardBody>
      </Card>
    </div>
  );
}
