import { type GetPageRevisionDto, type GetSubRevisionsDto, PageRevisionsApi } from 'api';
import { fromGetPageRevisionDto } from 'factory/PageRevisionFactory';
import { fromGetSubRevisionsDto } from 'factory/RevisionFactory';

import { type PageRevision } from 'models/pageRevision.entity';
import type { Revision } from 'models/revision.entity';

export class PageRevisionService {
  private readonly pageClient: PageRevisionsApi;

  constructor() {
    this.pageClient = new PageRevisionsApi();
  }

  public async getPageRevision(pageRevisionId: string, accessToken: string): Promise<PageRevision> {
    const getPageRevisionDto: GetPageRevisionDto = await this.pageClient.pageRevisionControllerFindById(
      {
        id: pageRevisionId,
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
      },
    );
    return fromGetPageRevisionDto(getPageRevisionDto);
  }

  public async getPageRevisions(pageId: string, accessToken: string): Promise<Revision[]> {
    const getSubRevisionsDto: GetSubRevisionsDto = await this.pageClient.pageRevisionControllerFindRevisionsById(
      {
        id: pageId,
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
      },
    );
    return fromGetSubRevisionsDto(getSubRevisionsDto);
  }
}
