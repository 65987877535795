/* tslint:disable */
/* eslint-disable */
/**
 * Specks API
 * APIs for specks SaaS
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AppSchemaRevision } from './AppSchemaRevision';
import {
    AppSchemaRevisionFromJSON,
    AppSchemaRevisionFromJSONTyped,
    AppSchemaRevisionToJSON,
} from './AppSchemaRevision';
import type { Component } from './Component';
import {
    ComponentFromJSON,
    ComponentFromJSONTyped,
    ComponentToJSON,
} from './Component';
import type { Edge } from './Edge';
import {
    EdgeFromJSON,
    EdgeFromJSONTyped,
    EdgeToJSON,
} from './Edge';

/**
 * 
 * @export
 * @interface Node
 */
export interface Node {
    /**
     * 
     * @type {string}
     * @memberof Node
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof Node
     */
    reactFlowId: string;
    /**
     * 
     * @type {number}
     * @memberof Node
     */
    positionX: number;
    /**
     * 
     * @type {number}
     * @memberof Node
     */
    positionY: number;
    /**
     * 
     * @type {Component}
     * @memberof Node
     */
    component: Component;
    /**
     * 
     * @type {AppSchemaRevision}
     * @memberof Node
     */
    appSchemaRevision: AppSchemaRevision;
    /**
     * 
     * @type {Array<Edge>}
     * @memberof Node
     */
    edges: Array<Edge>;
}

/**
 * Check if a given object implements the Node interface.
 */
export function instanceOfNode(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "reactFlowId" in value;
    isInstance = isInstance && "positionX" in value;
    isInstance = isInstance && "positionY" in value;
    isInstance = isInstance && "component" in value;
    isInstance = isInstance && "appSchemaRevision" in value;
    isInstance = isInstance && "edges" in value;

    return isInstance;
}

export function NodeFromJSON(json: any): Node {
    return NodeFromJSONTyped(json, false);
}

export function NodeFromJSONTyped(json: any, ignoreDiscriminator: boolean): Node {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'reactFlowId': json['reactFlowId'],
        'positionX': json['positionX'],
        'positionY': json['positionY'],
        'component': ComponentFromJSON(json['component']),
        'appSchemaRevision': AppSchemaRevisionFromJSON(json['appSchemaRevision']),
        'edges': ((json['edges'] as Array<any>).map(EdgeFromJSON)),
    };
}

export function NodeToJSON(value?: Node | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'reactFlowId': value.reactFlowId,
        'positionX': value.positionX,
        'positionY': value.positionY,
        'component': ComponentToJSON(value.component),
        'appSchemaRevision': AppSchemaRevisionToJSON(value.appSchemaRevision),
        'edges': ((value.edges as Array<any>).map(EdgeToJSON)),
    };
}

