import React from 'react';

import { Tab } from '@headlessui/react';

import concatClassNames from 'utils/classNames';

import { type TabListProps, tabListPositionStyle } from './Tabs.types';

export function TabList({ children, jsxElement, position = 'left' }: TabListProps): JSX.Element {
  return (
    <Tab.List className="">
      <div className="flex flex-row justify-between w-full ">
        <div
          className={concatClassNames(
            'flex flex-row',
            'w-full',
            '-mb-px',
            tabListPositionStyle[position],
            jsxElement !== undefined ? 'mr-8' : '',
            'border-b',
            'border-b-gray-25',
          )}
        >
          {children}
        </div>
        {jsxElement !== undefined && jsxElement}
      </div>
    </Tab.List>
  );
}
