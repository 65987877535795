type ElementPosition = 'left' | 'center' | 'right';

export const elementPositionStyle: Record<ElementPosition, string> = {
  left: 'justify-start',
  center: 'justify-center',
  right: 'justify-end',
};

type ElementWidth = 'sm' | 'md' | 'full';

export const elementWidthStyle: Record<ElementWidth, string> = {
  sm: 'w-48',
  md: 'w-96',
  full: 'w-full',
};

type OverflowType = 'auto' | 'hidden';

export const overflowStyle: Record<OverflowType, string> = {
  auto: 'overflow-auto',
  hidden: 'overflow-hidden',
};

type FlexTypeType = 'grow' | 'wrap';

export const flexTypeStyle: Record<FlexTypeType, string> = {
  grow: 'flex-grow',
  wrap: 'flex-wrap',
};

export interface TableProps {
  children: JSX.Element | JSX.Element[];
  padding?: string;
  flexType?: FlexTypeType;
}

export interface TableHeaderProps {
  children: JSX.Element | JSX.Element[];
}

export interface TableHeaderElementProps {
  children?: JSX.Element | JSX.Element[];
  position?: ElementPosition;
  width?: ElementWidth;
}

export interface TableBodyProps {
  children: JSX.Element | JSX.Element[];
  margin?: string;
  overflow?: OverflowType;
  flexType?: FlexTypeType;
}

export interface TableRowProps {
  children: JSX.Element | JSX.Element[];
}

export interface TableDataProps {
  children?: JSX.Element | JSX.Element[];
  position?: ElementPosition;
  width?: ElementWidth;
}
