import React, { Fragment } from 'react';

import { Tab } from '@headlessui/react';

import concatClassNames from 'utils/classNames';

import { textSizeStyle, textWeightStyle } from '../Text/Text';
import { type TabElementProps } from './Tabs.types';

export function TabElement({
  title,
  textSize = 'base',
  selectedTextWeight = 'normal',
  weight = 'normal',
  width = 'fit',
}: TabElementProps): JSX.Element {
  return (
    <Tab as={Fragment}>
      {({ selected }) => (
        <div
          className={concatClassNames(
            selected
              ? 'border-b-4 border-black rounded text-black font-bold'
              : ' hover:border-b-4 text-gray-300 hover:border-gray-300 hover:border-white hover:rounded',
            'flex',
            'pb-3 px-4 pt-1',
            'focus:outline-none',
            width === 'fit' ? '' : 'flex-grow',
            'justify-center',
            selected ? textWeightStyle[selectedTextWeight] : textWeightStyle[weight],
            selected ? '' : 'cursor-pointer',
          )}
        >
          <button className={concatClassNames(textSizeStyle[textSize])}>{title}</button>
        </div>
      )}
    </Tab>
  );
}
