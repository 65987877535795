import { AppSchemaRevisionsApi, type GetAppSchemaRevisionDto } from 'api';

import { type ComponentType } from 'models/ComponentTypes.type';
import { Edge } from 'models/Edge.entity';
import { Node } from 'models/Node.entity';
import { type Status } from 'models/Status.types';
import { AppSchemaRevision } from 'models/appSchema.entity';
import { Component } from 'models/component.entity';

export class AppSchemaService {
  private readonly appSchemaClient: AppSchemaRevisionsApi;

  constructor() {
    this.appSchemaClient = new AppSchemaRevisionsApi();
  }

  public async getAppSchemaRevision(id: string, accessToken: string): Promise<AppSchemaRevision> {
    const res: GetAppSchemaRevisionDto = await this.appSchemaClient.appSchemaRevisionControllerFindById(
      {
        id,
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'content-type': 'application/json',
        },
      },
    );
    const appSchema: AppSchemaRevision = new AppSchemaRevision();
    appSchema.id = res.id;
    appSchema.revision = res.revision;
    appSchema.nodes = res.nodes.map((nodeDto) => {
      const node: Node = new Node();
      node.id = nodeDto.id;
      node.positionX = nodeDto.positionX;
      node.positionY = nodeDto.positionY;
      node.component = new Component();
      node.component.id = nodeDto.component.id;
      node.component.name = nodeDto.component.name;
      node.component.componentType = nodeDto.component.componentType as ComponentType;
      node.component.status = nodeDto.component.status as Status;
      node.component.internal = nodeDto.component.internal;
      return node;
    });
    appSchema.edges = res.edges.map((edgeDto) => {
      const edge: Edge = new Edge();
      edge.id = edgeDto.id;
      edge.sourceNode = new Node();
      edge.sourceNode.id = edgeDto.sourceNodeId.id;
      edge.sourceStringId = edgeDto.sourceHandleId;
      edge.targetNode = new Node();
      edge.targetNode.id = edgeDto.targetNodeId.id;
      edge.targetStringId = edgeDto.targetHandleId;
      return edge;
    });
    appSchema.createdAt = res.createdAt;
    return appSchema;
  }
}
