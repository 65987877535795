import React from 'react';

import { type TextSize } from 'components/core/Text/Text';

import { ApiTypeChip } from './ApiTypeChip';

interface GetChipProps {
  isFonctional?: boolean;
  size?: TextSize;
}

export function GetChip({ isFonctional = false, size = 'api' }: GetChipProps): JSX.Element {
  return <ApiTypeChip type="GET" isFunctional={isFonctional} size={size} />;
}
