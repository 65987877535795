import {
  CreateUiComponentButtonDtoTypeEnum,
  CreateUiComponentContainerDtoTypeEnum,
  CreateUiComponentDateDtoTypeEnum,
  CreateUiComponentInputDtoTypeEnum,
  CreateUiComponentMediaDtoTypeEnum,
  CreateUiComponentSelectDtoTypeEnum,
  CreateUiComponentTextDtoTypeEnum,
  CreateUiComponentTitleDtoTypeEnum,
  type GetUiComponentDto,
  type PageControllerCreateUiComponentRequest,
  type UiComponentControllerUpdateRequest,
  UiComponentsApi,
  type UpdateUicPositionDto,
} from 'api';
import { fromGetUiComponentDto } from 'factory/uiComponentFactory';

import { type UiComponent } from 'models/uiComponent.entity';

export class UiComponentService {
  private readonly uiComponentClient: UiComponentsApi;

  constructor() {
    this.uiComponentClient = new UiComponentsApi();
  }

  public async getUiComponent(uiComponentId: string, accessToken: string): Promise<UiComponent> {
    const getUiComponentDto: GetUiComponentDto = await this.uiComponentClient.uiComponentControllerFindById(
      {
        id: uiComponentId,
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
      },
    );
    return fromGetUiComponentDto(getUiComponentDto);
  }

  public convertUiComponentToPageControllerCreateUiComponentRequest(
    uiComponents: UiComponent[],
    newUiComponent: UiComponent,
  ): PageControllerCreateUiComponentRequest {
    const positions: UpdateUicPositionDto[] = uiComponents.map((uiComponent) => {
      return {
        index: uiComponent.uiComponentRevisions[0].index,
        parentId: uiComponent.uiComponentRevisions[0].parentId,
        uiComponentId: uiComponent.id ?? '',
      };
    });

    switch (newUiComponent.type) {
      case 'button':
        return {
          type: CreateUiComponentButtonDtoTypeEnum.Button,
          uiComponent: {
            type: CreateUiComponentButtonDtoTypeEnum.Button,
            name: newUiComponent.uiComponentRevisions[0].name,
            notes: newUiComponent.uiComponentRevisions[0].notes,
            index: newUiComponent.uiComponentRevisions[0].index,
            parentId: newUiComponent.uiComponentRevisions[0].parentId,

            isVisible: newUiComponent.uiComponentRevisions[0].isVisible,
            isVisibleLogicalRule: newUiComponent.uiComponentRevisions[0].isVisibleLogicalRule,

            sourceMethodId: newUiComponent.uiComponentRevisions[0].sourceMethodId,
            contentType: newUiComponent.uiComponentRevisions[0].contentType,
            sourceConstant: newUiComponent.uiComponentRevisions[0].sourceConstant,
            sourceLogicalRule: newUiComponent.uiComponentRevisions[0].sourceLogicalRule,

            targetType: newUiComponent.uiComponentRevisions[0].targetType,
            targetFree: newUiComponent.uiComponentRevisions[0].targetFree,
            targetMethodId: newUiComponent.uiComponentRevisions[0].targetMethodId ?? '',
            isFormValid: newUiComponent.uiComponentRevisions[0].isFormValid,
          },
          positions,
        };
      case 'title':
        return {
          type: CreateUiComponentTitleDtoTypeEnum.Title,
          uiComponent: {
            type: CreateUiComponentTitleDtoTypeEnum.Title,
            name: newUiComponent.uiComponentRevisions[0].name,
            notes: newUiComponent.uiComponentRevisions[0].notes,
            index: newUiComponent.uiComponentRevisions[0].index,
            parentId: newUiComponent.uiComponentRevisions[0].parentId,

            sourceMethodId: newUiComponent.uiComponentRevisions[0].sourceMethodId,
            contentType: newUiComponent.uiComponentRevisions[0].contentType,
            sourceConstant: newUiComponent.uiComponentRevisions[0].sourceConstant,
            sourceLogicalRule: newUiComponent.uiComponentRevisions[0].sourceLogicalRule,

            isVisible: newUiComponent.uiComponentRevisions[0].isVisible,
            isVisibleLogicalRule: newUiComponent.uiComponentRevisions[0].isVisibleLogicalRule,
            titleImportancy: newUiComponent.uiComponentRevisions[0].titleImportancy,
            isFormValid: newUiComponent.uiComponentRevisions[0].isFormValid,
          },
          positions,
        };
      case 'input':
        return {
          type: CreateUiComponentInputDtoTypeEnum.Input,
          uiComponent: {
            type: CreateUiComponentInputDtoTypeEnum.Input,
            name: newUiComponent.uiComponentRevisions[0].name,
            notes: newUiComponent.uiComponentRevisions[0].notes,
            index: newUiComponent.uiComponentRevisions[0].index,
            parentId: newUiComponent.uiComponentRevisions[0].parentId,

            label: newUiComponent.uiComponentRevisions[0].label,
            isEditable: newUiComponent.uiComponentRevisions[0].isEditable,
            isEditableLogicalRule: newUiComponent.uiComponentRevisions[0].isEditableLogicalRule,
            isPreFilled: newUiComponent.uiComponentRevisions[0].isPreFilled,
            isPreFilledConstant: newUiComponent.uiComponentRevisions[0].isPreFilledConstant,
            isPreFilledLogicalRule: newUiComponent.uiComponentRevisions[0].isPreFilledLogicalRule,
            hasPlaceholder: newUiComponent.uiComponentRevisions[0].hasPlaceholder,
            hasPlaceholderConstant: newUiComponent.uiComponentRevisions[0].hasPlaceholderConstant,
            hasPlaceholderLogicalRule: newUiComponent.uiComponentRevisions[0].hasPlaceholderLogicalRule,
            isMandatory: newUiComponent.uiComponentRevisions[0].isMandatory,
            isMandatoryLogicalRule: newUiComponent.uiComponentRevisions[0].isMandatoryLogicalRule,
            hasPattern: newUiComponent.uiComponentRevisions[0].hasPattern,
            hasPatternConstant: newUiComponent.uiComponentRevisions[0].hasPatternConstant,
            hasPatternLogicalRule: newUiComponent.uiComponentRevisions[0].hasPatternLogicalRule,
            isFormValid: newUiComponent.uiComponentRevisions[0].isFormValid,
          },
          positions,
        };
      case 'date':
        return {
          type: CreateUiComponentDateDtoTypeEnum.Date,
          uiComponent: {
            type: CreateUiComponentDateDtoTypeEnum.Date,
            name: newUiComponent.uiComponentRevisions[0].name,
            notes: newUiComponent.uiComponentRevisions[0].notes,
            index: newUiComponent.uiComponentRevisions[0].index,
            parentId: newUiComponent.uiComponentRevisions[0].parentId,

            isPreFilled: newUiComponent.uiComponentRevisions[0].isPreFilled,
            isPreFilledConstant: newUiComponent.uiComponentRevisions[0].isPreFilledConstant,
            isPreFilledLogicalRule: newUiComponent.uiComponentRevisions[0].isPreFilledLogicalRule,
            hasPlaceholder: newUiComponent.uiComponentRevisions[0].hasPlaceholder,
            hasPlaceholderConstant: newUiComponent.uiComponentRevisions[0].hasPlaceholderConstant,
            hasPlaceholderLogicalRule: newUiComponent.uiComponentRevisions[0].hasPlaceholderLogicalRule,
            isMandatory: newUiComponent.uiComponentRevisions[0].isMandatory,
            isMandatoryLogicalRule: newUiComponent.uiComponentRevisions[0].isMandatoryLogicalRule,
            label: newUiComponent.uiComponentRevisions[0].label,
            dateFormat: newUiComponent.uiComponentRevisions[0].dateFormat,
            dateCustom: newUiComponent.uiComponentRevisions[0].dateCustom,
            isFormValid: newUiComponent.uiComponentRevisions[0].isFormValid,
          },
          positions,
        };
      case 'media':
        return {
          type: CreateUiComponentMediaDtoTypeEnum.Media,
          uiComponent: {
            type: CreateUiComponentMediaDtoTypeEnum.Media,
            name: newUiComponent.uiComponentRevisions[0].name,
            notes: newUiComponent.uiComponentRevisions[0].notes,
            index: newUiComponent.uiComponentRevisions[0].index,
            parentId: newUiComponent.uiComponentRevisions[0].parentId,

            sourceMethodId: newUiComponent.uiComponentRevisions[0].sourceMethodId,
            contentType: newUiComponent.uiComponentRevisions[0].contentType,
            sourceConstant: newUiComponent.uiComponentRevisions[0].sourceConstant,
            sourceLogicalRule: newUiComponent.uiComponentRevisions[0].sourceLogicalRule,

            isVisible: newUiComponent.uiComponentRevisions[0].isVisible,
            isVisibleLogicalRule: newUiComponent.uiComponentRevisions[0].isVisibleLogicalRule,
            isFormValid: newUiComponent.uiComponentRevisions[0].isFormValid,
          },
          positions,
        };
      case 'text':
        return {
          type: CreateUiComponentTextDtoTypeEnum.Text,
          uiComponent: {
            type: CreateUiComponentTextDtoTypeEnum.Text,
            name: newUiComponent.uiComponentRevisions[0].name,
            notes: newUiComponent.uiComponentRevisions[0].notes,
            index: newUiComponent.uiComponentRevisions[0].index,
            parentId: newUiComponent.uiComponentRevisions[0].parentId,

            sourceMethodId: newUiComponent.uiComponentRevisions[0].sourceMethodId,
            contentType: newUiComponent.uiComponentRevisions[0].contentType,
            sourceConstant: newUiComponent.uiComponentRevisions[0].sourceConstant,
            sourceLogicalRule: newUiComponent.uiComponentRevisions[0].sourceLogicalRule,

            isVisible: newUiComponent.uiComponentRevisions[0].isVisible,
            isVisibleLogicalRule: newUiComponent.uiComponentRevisions[0].isVisibleLogicalRule,

            textImportancy: newUiComponent.uiComponentRevisions[0].textImportancy,
            isFormValid: newUiComponent.uiComponentRevisions[0].isFormValid,
          },
          positions,
        };
      case 'icon':
        return {
          type: CreateUiComponentButtonDtoTypeEnum.Icon,
          uiComponent: {
            type: CreateUiComponentButtonDtoTypeEnum.Icon,
            name: newUiComponent.uiComponentRevisions[0].name,
            notes: newUiComponent.uiComponentRevisions[0].notes,
            index: newUiComponent.uiComponentRevisions[0].index,
            parentId: newUiComponent.uiComponentRevisions[0].parentId,
            isFormValid: newUiComponent.uiComponentRevisions[0].isFormValid,
          },
          positions,
        };
      case 'select':
        return {
          type: CreateUiComponentSelectDtoTypeEnum.Select,
          uiComponent: {
            type: CreateUiComponentSelectDtoTypeEnum.Select,
            name: newUiComponent.uiComponentRevisions[0].name,
            notes: newUiComponent.uiComponentRevisions[0].notes,
            label: newUiComponent.uiComponentRevisions[0].label,
            index: newUiComponent.uiComponentRevisions[0].index,
            parentId: newUiComponent.uiComponentRevisions[0].parentId,

            isVisible: newUiComponent.uiComponentRevisions[0].isVisible,
            isVisibleLogicalRule: newUiComponent.uiComponentRevisions[0].isVisibleLogicalRule,

            optionsSource: newUiComponent.uiComponentRevisions[0].optionsSource,

            options: newUiComponent.uiComponentRevisions[0].options,

            sourceMethodId: newUiComponent.uiComponentRevisions[0].sourceMethodId,
            isMultiChoiceAllowed: newUiComponent.uiComponentRevisions[0].isMultiChoiceAllowed,
            isMultiChoiceAllowedLogicalRule: newUiComponent.uiComponentRevisions[0].isMultiChoiceAllowedLogicalRule,

            isPreFilled: newUiComponent.uiComponentRevisions[0].isPreFilled,
            isPreFilledConstant: newUiComponent.uiComponentRevisions[0].isPreFilledConstant,
            isPreFilledLogicalRule: newUiComponent.uiComponentRevisions[0].isPreFilledLogicalRule,
            hasPlaceholder: newUiComponent.uiComponentRevisions[0].hasPlaceholder,
            hasPlaceholderConstant: newUiComponent.uiComponentRevisions[0].hasPlaceholderConstant,
            hasPlaceholderLogicalRule: newUiComponent.uiComponentRevisions[0].hasPlaceholderLogicalRule,
            isMandatory: newUiComponent.uiComponentRevisions[0].isMandatory,
            isMandatoryLogicalRule: newUiComponent.uiComponentRevisions[0].isMandatoryLogicalRule,
            isFormValid: newUiComponent.uiComponentRevisions[0].isFormValid,
          },
          positions,
        };
      case 'container':
        return {
          type: CreateUiComponentContainerDtoTypeEnum.Container,
          uiComponent: {
            type: CreateUiComponentContainerDtoTypeEnum.Container,
            name: newUiComponent.uiComponentRevisions[0].name,
            notes: newUiComponent.uiComponentRevisions[0].notes,
            index: newUiComponent.uiComponentRevisions[0].index,
            parentId: newUiComponent.uiComponentRevisions[0].parentId,
            usedFor: newUiComponent.uiComponentRevisions[0].usedFor,

            isFormValid: newUiComponent.uiComponentRevisions[0].isFormValid,
          },
          positions,
        };
      case 'list':
        return {
          type: CreateUiComponentTextDtoTypeEnum.List,
          uiComponent: {
            type: CreateUiComponentTextDtoTypeEnum.List,
            name: newUiComponent.uiComponentRevisions[0].name,
            notes: newUiComponent.uiComponentRevisions[0].notes,
            index: newUiComponent.uiComponentRevisions[0].index,
            parentId: newUiComponent.uiComponentRevisions[0].parentId,
            isFormValid: newUiComponent.uiComponentRevisions[0].isFormValid,
          },
          positions,
        };
      default:
        return {
          type: CreateUiComponentButtonDtoTypeEnum.Icon,
          uiComponent: {
            type: CreateUiComponentButtonDtoTypeEnum.Icon,
            name: newUiComponent.uiComponentRevisions[0].name ?? '',
            notes: newUiComponent.uiComponentRevisions[0].notes ?? '',
            index: newUiComponent.uiComponentRevisions[0].index ?? 1,
            parentId: newUiComponent.uiComponentRevisions[0].parentId,
            isFormValid: newUiComponent.uiComponentRevisions[0].isFormValid,
          },
          positions,
        };
    }
  }

  public convertUiComponentToUiComponentControllerUpdateRequest(
    uiComponent: UiComponent,
  ): UiComponentControllerUpdateRequest {
    switch (uiComponent.type) {
      case 'button':
        return {
          type: CreateUiComponentButtonDtoTypeEnum.Button,
          name: uiComponent.uiComponentRevisions[0].name,
          isFormValid: uiComponent.uiComponentRevisions[0].isFormValid,
          notes: uiComponent.uiComponentRevisions[0].notes,
          index: uiComponent.uiComponentRevisions[0].index,

          isVisible: uiComponent.uiComponentRevisions[0].isVisible,
          isVisibleLogicalRule: uiComponent.uiComponentRevisions[0].isVisibleLogicalRule,

          sourceMethodId: uiComponent.uiComponentRevisions[0].sourceMethodId,
          contentType: uiComponent.uiComponentRevisions[0].contentType,
          sourceConstant: uiComponent.uiComponentRevisions[0].sourceConstant,
          sourceLogicalRule: uiComponent.uiComponentRevisions[0].sourceLogicalRule,

          targetType: uiComponent.uiComponentRevisions[0].targetType,
          targetFree: uiComponent.uiComponentRevisions[0].targetFree,
          targetMethodId: uiComponent.uiComponentRevisions[0].targetMethodId,
        };
      case 'title':
        return {
          type: CreateUiComponentTitleDtoTypeEnum.Title,
          name: uiComponent.uiComponentRevisions[0].name,
          isFormValid: uiComponent.uiComponentRevisions[0].isFormValid,
          notes: uiComponent.uiComponentRevisions[0].notes,
          index: uiComponent.uiComponentRevisions[0].index,
          sourceMethodId: uiComponent.uiComponentRevisions[0].sourceMethodId,
          contentType: uiComponent.uiComponentRevisions[0].contentType,
          sourceConstant: uiComponent.uiComponentRevisions[0].sourceConstant,
          sourceLogicalRule: uiComponent.uiComponentRevisions[0].sourceLogicalRule,

          isVisible: uiComponent.uiComponentRevisions[0].isVisible,
          isVisibleLogicalRule: uiComponent.uiComponentRevisions[0].isVisibleLogicalRule,
          titleImportancy: uiComponent.uiComponentRevisions[0].titleImportancy,
        };
      case 'input':
        return {
          type: CreateUiComponentInputDtoTypeEnum.Input,
          name: uiComponent.uiComponentRevisions[0].name,
          isFormValid: uiComponent.uiComponentRevisions[0].isFormValid,
          notes: uiComponent.uiComponentRevisions[0].notes,
          index: uiComponent.uiComponentRevisions[0].index,

          label: uiComponent.uiComponentRevisions[0].label,
          isEditable: uiComponent.uiComponentRevisions[0].isEditable,
          isEditableLogicalRule: uiComponent.uiComponentRevisions[0].isEditableLogicalRule,
          isPreFilled: uiComponent.uiComponentRevisions[0].isPreFilled,
          isPreFilledConstant: uiComponent.uiComponentRevisions[0].isPreFilledConstant,
          isPreFilledLogicalRule: uiComponent.uiComponentRevisions[0].isPreFilledLogicalRule,
          hasPlaceholder: uiComponent.uiComponentRevisions[0].hasPlaceholder,
          hasPlaceholderConstant: uiComponent.uiComponentRevisions[0].hasPlaceholderConstant,
          hasPlaceholderLogicalRule: uiComponent.uiComponentRevisions[0].hasPlaceholderLogicalRule,
          isMandatory: uiComponent.uiComponentRevisions[0].isMandatory,
          isMandatoryLogicalRule: uiComponent.uiComponentRevisions[0].isMandatoryLogicalRule,
          hasPattern: uiComponent.uiComponentRevisions[0].hasPattern,
          hasPatternConstant: uiComponent.uiComponentRevisions[0].hasPatternConstant,
          hasPatternLogicalRule: uiComponent.uiComponentRevisions[0].hasPatternLogicalRule,
        };
      case 'date':
        return {
          type: CreateUiComponentDateDtoTypeEnum.Date,
          name: uiComponent.uiComponentRevisions[0].name,
          isFormValid: uiComponent.uiComponentRevisions[0].isFormValid,
          notes: uiComponent.uiComponentRevisions[0].notes,
          index: uiComponent.uiComponentRevisions[0].index,

          isPreFilled: uiComponent.uiComponentRevisions[0].isPreFilled,
          isPreFilledConstant: uiComponent.uiComponentRevisions[0].isPreFilledConstant,
          isPreFilledLogicalRule: uiComponent.uiComponentRevisions[0].isPreFilledLogicalRule,
          hasPlaceholder: uiComponent.uiComponentRevisions[0].hasPlaceholder,
          hasPlaceholderConstant: uiComponent.uiComponentRevisions[0].hasPlaceholderConstant,
          hasPlaceholderLogicalRule: uiComponent.uiComponentRevisions[0].hasPlaceholderLogicalRule,
          isMandatory: uiComponent.uiComponentRevisions[0].isMandatory,
          isMandatoryLogicalRule: uiComponent.uiComponentRevisions[0].isMandatoryLogicalRule,
          label: uiComponent.uiComponentRevisions[0].label,
          dateFormat: uiComponent.uiComponentRevisions[0].dateFormat,
          dateCustom: uiComponent.uiComponentRevisions[0].dateCustom,
        };
      case 'media':
        return {
          type: CreateUiComponentMediaDtoTypeEnum.Media,
          name: uiComponent.uiComponentRevisions[0].name,
          isFormValid: uiComponent.uiComponentRevisions[0].isFormValid,
          notes: uiComponent.uiComponentRevisions[0].notes,
          index: uiComponent.uiComponentRevisions[0].index,

          sourceMethodId: uiComponent.uiComponentRevisions[0].sourceMethodId,
          contentType: uiComponent.uiComponentRevisions[0].contentType,
          sourceConstant: uiComponent.uiComponentRevisions[0].sourceConstant,
          sourceLogicalRule: uiComponent.uiComponentRevisions[0].sourceLogicalRule,

          isVisible: uiComponent.uiComponentRevisions[0].isVisible,
          isVisibleLogicalRule: uiComponent.uiComponentRevisions[0].isVisibleLogicalRule,
        };
      case 'text':
        return {
          type: CreateUiComponentTextDtoTypeEnum.Text,
          name: uiComponent.uiComponentRevisions[0].name,
          isFormValid: uiComponent.uiComponentRevisions[0].isFormValid,
          notes: uiComponent.uiComponentRevisions[0].notes,
          index: uiComponent.uiComponentRevisions[0].index,

          sourceMethodId: uiComponent.uiComponentRevisions[0].sourceMethodId,
          contentType: uiComponent.uiComponentRevisions[0].contentType,
          sourceConstant: uiComponent.uiComponentRevisions[0].sourceConstant,
          sourceLogicalRule: uiComponent.uiComponentRevisions[0].sourceLogicalRule,

          isVisible: uiComponent.uiComponentRevisions[0].isVisible,
          isVisibleLogicalRule: uiComponent.uiComponentRevisions[0].isVisibleLogicalRule,

          textImportancy: uiComponent.uiComponentRevisions[0].textImportancy,
        };
      case 'icon':
        return {
          type: CreateUiComponentButtonDtoTypeEnum.Icon,
          name: uiComponent.uiComponentRevisions[0].name,
          isFormValid: uiComponent.uiComponentRevisions[0].isFormValid,
          notes: uiComponent.uiComponentRevisions[0].notes,
          index: uiComponent.uiComponentRevisions[0].index,
        };
      case 'select':
        return {
          name: uiComponent.uiComponentRevisions[0].name,
          isFormValid: uiComponent.uiComponentRevisions[0].isFormValid,
          notes: uiComponent.uiComponentRevisions[0].notes,
          type: CreateUiComponentSelectDtoTypeEnum.Select,
          label: uiComponent.uiComponentRevisions[0].label,
          index: uiComponent.uiComponentRevisions[0].index,

          isVisible: uiComponent.uiComponentRevisions[0].isVisible,
          isVisibleLogicalRule: uiComponent.uiComponentRevisions[0].isVisibleLogicalRule,

          optionsSource: uiComponent.uiComponentRevisions[0].optionsSource,

          options: uiComponent.uiComponentRevisions[0].options,

          sourceMethodId: uiComponent.uiComponentRevisions[0].sourceMethodId,
          isMultiChoiceAllowed: uiComponent.uiComponentRevisions[0].isMultiChoiceAllowed,
          isMultiChoiceAllowedLogicalRule: uiComponent.uiComponentRevisions[0].isMultiChoiceAllowedLogicalRule,

          isPreFilled: uiComponent.uiComponentRevisions[0].isPreFilled,
          isPreFilledConstant: uiComponent.uiComponentRevisions[0].isPreFilledConstant,
          isPreFilledLogicalRule: uiComponent.uiComponentRevisions[0].isPreFilledLogicalRule,
          hasPlaceholder: uiComponent.uiComponentRevisions[0].hasPlaceholder,
          hasPlaceholderConstant: uiComponent.uiComponentRevisions[0].hasPlaceholderConstant,
          hasPlaceholderLogicalRule: uiComponent.uiComponentRevisions[0].hasPlaceholderLogicalRule,
          isMandatory: uiComponent.uiComponentRevisions[0].isMandatory,
          isMandatoryLogicalRule: uiComponent.uiComponentRevisions[0].isMandatoryLogicalRule,
        };
      case 'container':
        return {
          type: CreateUiComponentTextDtoTypeEnum.Container,
          name: uiComponent.uiComponentRevisions[0].name,
          notes: uiComponent.uiComponentRevisions[0].notes,
          index: uiComponent.uiComponentRevisions[0].index,
          usedFor: uiComponent.uiComponentRevisions[0].usedFor,
          isFormValid: uiComponent.uiComponentRevisions[0].isFormValid,
        };
      default:
        return {
          type: CreateUiComponentButtonDtoTypeEnum.Icon,
          name: uiComponent.uiComponentRevisions[0].name,
          isFormValid: uiComponent.uiComponentRevisions[0].isFormValid,
          notes: uiComponent.uiComponentRevisions[0].notes,
          index: uiComponent.uiComponentRevisions[0].index,
        };
    }
  }

  public async updateUiComponent(
    uiComponentId: string,
    uiComponent: UiComponent,
    accessToken: string,
  ): Promise<UiComponent> {
    const res: UiComponentControllerUpdateRequest =
      this.convertUiComponentToUiComponentControllerUpdateRequest(uiComponent);
    const getUiComponentDto: GetUiComponentDto = await this.uiComponentClient.uiComponentControllerUpdate(
      {
        id: uiComponentId,
        uiComponentControllerUpdateRequest: res,
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
      },
    );
    return fromGetUiComponentDto(getUiComponentDto);
  }
}
