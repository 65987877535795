import React, { type MouseEventHandler } from 'react';

import { Button } from 'components/core/Button/Button';

interface UnsavedChangesFormProps {
  onSubmit: MouseEventHandler<HTMLButtonElement> | undefined;
}

export function UnsavedChangesForm({ onSubmit }: UnsavedChangesFormProps): JSX.Element {
  if (onSubmit === undefined) throw new Error('Undefined saved function');
  return (
    <div className="flex flex-row justify-end">
      <Button content="Oui" width="1/2" type="submit" onClick={onSubmit} />
    </div>
  );
}
