/* tslint:disable */
/* eslint-disable */
/**
 * Specks API
 * APIs for specks SaaS
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ComponentIdDto } from './ComponentIdDto';
import {
    ComponentIdDtoFromJSON,
    ComponentIdDtoFromJSONTyped,
    ComponentIdDtoToJSON,
} from './ComponentIdDto';
import type { GetMethodRevisionIdDto } from './GetMethodRevisionIdDto';
import {
    GetMethodRevisionIdDtoFromJSON,
    GetMethodRevisionIdDtoFromJSONTyped,
    GetMethodRevisionIdDtoToJSON,
} from './GetMethodRevisionIdDto';
import type { GetUiComponentRevisionInfoDto } from './GetUiComponentRevisionInfoDto';
import {
    GetUiComponentRevisionInfoDtoFromJSON,
    GetUiComponentRevisionInfoDtoFromJSONTyped,
    GetUiComponentRevisionInfoDtoToJSON,
} from './GetUiComponentRevisionInfoDto';

/**
 * 
 * @export
 * @interface GetLinkDto
 */
export interface GetLinkDto {
    /**
     * 
     * @type {string}
     * @memberof GetLinkDto
     */
    id: string;
    /**
     * 
     * @type {ComponentIdDto}
     * @memberof GetLinkDto
     */
    caller: ComponentIdDto;
    /**
     * 
     * @type {ComponentIdDto}
     * @memberof GetLinkDto
     */
    receiver: ComponentIdDto;
    /**
     * 
     * @type {GetUiComponentRevisionInfoDto}
     * @memberof GetLinkDto
     */
    bearerForCaller: GetUiComponentRevisionInfoDto;
    /**
     * 
     * @type {GetMethodRevisionIdDto}
     * @memberof GetLinkDto
     */
    bearerForReceiver: GetMethodRevisionIdDto;
}

/**
 * Check if a given object implements the GetLinkDto interface.
 */
export function instanceOfGetLinkDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "caller" in value;
    isInstance = isInstance && "receiver" in value;
    isInstance = isInstance && "bearerForCaller" in value;
    isInstance = isInstance && "bearerForReceiver" in value;

    return isInstance;
}

export function GetLinkDtoFromJSON(json: any): GetLinkDto {
    return GetLinkDtoFromJSONTyped(json, false);
}

export function GetLinkDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetLinkDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'caller': ComponentIdDtoFromJSON(json['caller']),
        'receiver': ComponentIdDtoFromJSON(json['receiver']),
        'bearerForCaller': GetUiComponentRevisionInfoDtoFromJSON(json['bearerForCaller']),
        'bearerForReceiver': GetMethodRevisionIdDtoFromJSON(json['bearerForReceiver']),
    };
}

export function GetLinkDtoToJSON(value?: GetLinkDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'caller': ComponentIdDtoToJSON(value.caller),
        'receiver': ComponentIdDtoToJSON(value.receiver),
        'bearerForCaller': GetUiComponentRevisionInfoDtoToJSON(value.bearerForCaller),
        'bearerForReceiver': GetMethodRevisionIdDtoToJSON(value.bearerForReceiver),
    };
}

