import React from 'react';

import { useAuth0 } from '@auth0/auth0-react';

import { Button } from 'components/core/Button/Button';

export function LoginButton(): JSX.Element {
  /* ---------------------------------------------------- states ---------------------------------------------------- */
  const { loginWithRedirect } = useAuth0();

  /* ----------------------------------------------- handle functions ----------------------------------------------- */
  async function handleOnclick(): Promise<void> {
    await loginWithRedirect({
      appState: {
        returnTo: '/home',
      },
    });
  }

  /* ------------------------------------------------------ JSX ----------------------------------------------------- */
  return <Button height="sm" width="full" content="Se connecter" onClick={handleOnclick}></Button>;
}
