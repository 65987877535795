import {
  type CreateAppSchemaRevisionDto,
  type CreateExternalLinkDto,
  type CreateFeatureDto,
  type CreatePersonaDto,
  type CreateProductDto,
  type FeatureDto,
  type GetAppSchemaComponentDto,
  type GetAppSchemaComponentsDto,
  type GetAppSchemaRevisionDto,
  type GetExternalLinkDto,
  type GetFeatureDto,
  type GetPersonaDto,
  type GetProductAppSchemaRevisionsDto,
  type GetProductDto,
  type GetProductFeaturesDto,
  type GetProductPersonaeDto,
  type GetProductsDto,
  type PersonaDto,
  ProductsApi,
} from 'api';
import { fromGetAppSchemaComponentDto } from 'factory/ComponentFactory';
import { fromGetProductDto } from 'factory/ProductFactory';

import { Persona } from 'forms/CreateOrEditPersonaForm/CreateOrEditPersonaForm';

import { type ComponentType } from 'models/ComponentTypes.type';
import { Edge } from 'models/Edge.entity';
import { Node } from 'models/Node.entity';
import { type Status } from 'models/Status.types';
import { AppSchemaRevision } from 'models/appSchema.entity';
import { Component } from 'models/component.entity';
import { type ExternalLink } from 'models/externalLink.entity';
import { Feature } from 'models/feature.entity';
import { type Product } from 'models/product.entity';

export class ProductService {
  private readonly productClient: ProductsApi;

  constructor() {
    this.productClient = new ProductsApi();
  }

  public async create(product: Product, accessToken: string): Promise<Product> {
    const res: GetProductDto = await this.productClient.productControllerCreate(
      {
        createProductDto: product as CreateProductDto,
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
      },
    );
    return fromGetProductDto(res);
  }

  public async getIdsTutorial(accessToken: string): Promise<string[]> {
    const res: string[] = await this.productClient.productControllerGetIdsOfTutorial({
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
    });
    return res;
  }

  public async findAll(accessToken: string): Promise<Product[]> {
    const resDto: GetProductsDto = await this.productClient.productControllerFindAll(undefined, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
    });
    return resDto.products.map((productDto: GetProductDto): Product => {
      return fromGetProductDto(productDto);
    });
  }

  public async findById(id: string, accessToken: string): Promise<Product> {
    const res: GetProductDto = await this.productClient.productControllerFindById(
      {
        id,
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
      },
    );
    return fromGetProductDto(res);
  }

  public async delete(id: string, accessToken: string): Promise<void> {
    await this.productClient.productControllerDelete(
      { id },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
      },
    );
  }

  public async update(productId: string, product: Product, accessToken: string): Promise<Product> {
    const getProductDto: GetProductDto = await this.productClient.productControllerUpdate(
      {
        id: productId,
        updateProductDto: {
          name: product.name,
          status: product.status,
        },
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
      },
    );
    return fromGetProductDto(getProductDto);
  }

  /* --------------------------------------------------- features --------------------------------------------------- */

  public async createFeature(productId: string, feature: Feature, accessToken: string): Promise<Feature> {
    const getFeatureDto: GetFeatureDto = await this.productClient.productControllerCreateFeature(
      {
        id: productId,
        createFeatureDto: feature as CreateFeatureDto,
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
      },
    );
    return getFeatureDto as Feature;
  }

  public async getProductFeatures(id: string, accessToken: string): Promise<Feature[]> {
    const res: GetProductFeaturesDto = await this.productClient.productControllerFindFeaturesById(
      { id },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
      },
    );
    const features: Feature[] = res.features.map((featureDto: FeatureDto) => {
      const feature: Feature = new Feature();
      feature.id = featureDto.id;
      feature.name = featureDto.name;
      feature.status = featureDto.status as Status;
      return feature;
    });
    return features;
  }

  /* ------------------------------------------------- externalLinks ------------------------------------------------ */

  public async createExternalLink(
    productId: string,
    externalLink: ExternalLink,
    accessToken: string,
  ): Promise<GetExternalLinkDto> {
    return await this.productClient.productControllerCreateExternalLink(
      {
        id: productId,
        createExternalLinkDto: externalLink as CreateExternalLinkDto,
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
      },
    );
  }

  /* ----------------------------------------- AppSchemaRevision ------------------------------------------------ */

  public async getAppSchemaRevisionsRevisions(id: string, accessToken: string): Promise<AppSchemaRevision[]> {
    const res: GetProductAppSchemaRevisionsDto =
      await this.productClient.productControllerFindProductAppSchemaRevisionsById(
        {
          id,
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
          },
        },
      );
    const AppSchemaRevisions: AppSchemaRevision[] = res.appSchemas.map((appSchemaDto) => {
      const appSchema: AppSchemaRevision = new AppSchemaRevision();
      appSchema.id = appSchemaDto.id;
      appSchema.revision = appSchemaDto.revision;
      appSchema.createdAt = appSchemaDto.createdAt;
      return appSchema;
    });
    return AppSchemaRevisions;
  }

  public async createAppSchemaRevision(
    id: string,
    createAppSchemaRevisionDto: CreateAppSchemaRevisionDto,
    accessToken: string,
  ): Promise<AppSchemaRevision> {
    const res: GetAppSchemaRevisionDto = await this.productClient.productControllerCreateAppSchemaRevision(
      {
        id,
        createAppSchemaRevisionDto,
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
      },
    );
    const appSchema: AppSchemaRevision = new AppSchemaRevision();
    appSchema.id = res.id;
    appSchema.revision = res.revision;
    appSchema.nodes = res.nodes.map((nodeDto) => {
      const node: Node = new Node();
      node.id = nodeDto.id;
      node.positionX = nodeDto.positionX;
      node.positionY = nodeDto.positionY;
      node.component = new Component();
      node.component.id = nodeDto.component.id;
      node.component.name = nodeDto.component.name;
      node.component.componentType = nodeDto.component.componentType as ComponentType;
      node.component.status = nodeDto.component.status as Status;
      node.component.internal = nodeDto.component.internal;
      return node;
    });
    appSchema.edges = res.edges.map((edgeDto) => {
      const edge: Edge = new Edge();
      edge.id = edgeDto.id;
      edge.sourceNode = new Node();
      edge.sourceNode.id = edgeDto.sourceNodeId.id;
      edge.targetNode = new Node();
      edge.targetNode.id = edgeDto.targetNodeId.id;
      return edge;
    });
    appSchema.createdAt = res.createdAt;
    return appSchema;
  }

  public async findAppSchemaComponents(productId: string, accessToken: string): Promise<Component[]> {
    const resDto: GetAppSchemaComponentsDto = await this.productClient.productControllerFindComponentsForAppSchema(
      { id: productId },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
      },
    );
    return resDto.components.map((componentDto: GetAppSchemaComponentDto): Component => {
      return fromGetAppSchemaComponentDto(componentDto);
    });
  }

  public async findAppSchemaLinkedComponents(componentId: string, accessToken: string): Promise<Component[]> {
    const resDto: GetAppSchemaComponentsDto = await this.productClient.productControllerFindLinkedComponentById(
      { componentId },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
      },
    );
    return resDto.components.map((componentDto: GetAppSchemaComponentDto): Component => {
      return fromGetAppSchemaComponentDto(componentDto);
    });
  }

  /* --------------------------------------------------- Personae --------------------------------------------------- */

  public async findPersonaeById(productId: string, accessToken: string): Promise<Persona[]> {
    const res: GetProductPersonaeDto = await this.productClient.productControllerFindProductPersonaeById(
      { id: productId },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
      },
    );
    const personae: Persona[] = res.personae.map((personaDto: PersonaDto) => {
      const persona: Persona = new Persona();
      persona.id = personaDto.id;
      persona.name = personaDto.name;
      persona.age = personaDto.age;
      persona.description = personaDto.description;

      persona.likes = personaDto.likes;
      persona.dislikes = personaDto.dislikes;

      return persona;
    });
    return personae;
  }

  public async createPersona(productId: string, persona: Persona, accessToken: string): Promise<GetPersonaDto> {
    return await this.productClient.productControllerCreatePersona(
      {
        id: productId,
        createPersonaDto: persona as CreatePersonaDto,
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
      },
    );
  }
}
