/* tslint:disable */
/* eslint-disable */
/**
 * Specks API
 * APIs for specks SaaS
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  CreateMethodDto,
  ErrorBadRequest,
  GetMethodDto,
  GetResourceDto,
  UpdateResourceDto,
} from '../models';
import {
    CreateMethodDtoFromJSON,
    CreateMethodDtoToJSON,
    ErrorBadRequestFromJSON,
    ErrorBadRequestToJSON,
    GetMethodDtoFromJSON,
    GetMethodDtoToJSON,
    GetResourceDtoFromJSON,
    GetResourceDtoToJSON,
    UpdateResourceDtoFromJSON,
    UpdateResourceDtoToJSON,
} from '../models';

export interface ResourceControllerCreateMethodRequest {
    id: string;
    createMethodDto: CreateMethodDto;
}

export interface ResourceControllerDeleteByIdRequest {
    id: string;
}

export interface ResourceControllerFindByIdRequest {
    id: string;
}

export interface ResourceControllerUpdateRequest {
    id: string;
    updateResourceDto: UpdateResourceDto;
}

/**
 * 
 */
export class ResourcesApi extends runtime.BaseAPI {

    /**
     */
    async resourceControllerCreateMethodRaw(requestParameters: ResourceControllerCreateMethodRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetMethodDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling resourceControllerCreateMethod.');
        }

        if (requestParameters.createMethodDto === null || requestParameters.createMethodDto === undefined) {
            throw new runtime.RequiredError('createMethodDto','Required parameter requestParameters.createMethodDto was null or undefined when calling resourceControllerCreateMethod.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/resources/{id}/methods`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateMethodDtoToJSON(requestParameters.createMethodDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetMethodDtoFromJSON(jsonValue));
    }

    /**
     */
    async resourceControllerCreateMethod(requestParameters: ResourceControllerCreateMethodRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetMethodDto> {
        const response = await this.resourceControllerCreateMethodRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async resourceControllerDeleteByIdRaw(requestParameters: ResourceControllerDeleteByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling resourceControllerDeleteById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/resources/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async resourceControllerDeleteById(requestParameters: ResourceControllerDeleteByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.resourceControllerDeleteByIdRaw(requestParameters, initOverrides);
    }

    /**
     */
    async resourceControllerFindByIdRaw(requestParameters: ResourceControllerFindByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetResourceDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling resourceControllerFindById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/resources/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetResourceDtoFromJSON(jsonValue));
    }

    /**
     */
    async resourceControllerFindById(requestParameters: ResourceControllerFindByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetResourceDto> {
        const response = await this.resourceControllerFindByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async resourceControllerUpdateRaw(requestParameters: ResourceControllerUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetResourceDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling resourceControllerUpdate.');
        }

        if (requestParameters.updateResourceDto === null || requestParameters.updateResourceDto === undefined) {
            throw new runtime.RequiredError('updateResourceDto','Required parameter requestParameters.updateResourceDto was null or undefined when calling resourceControllerUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/resources/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateResourceDtoToJSON(requestParameters.updateResourceDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetResourceDtoFromJSON(jsonValue));
    }

    /**
     */
    async resourceControllerUpdate(requestParameters: ResourceControllerUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetResourceDto> {
        const response = await this.resourceControllerUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
