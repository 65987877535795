/* tslint:disable */
/* eslint-disable */
/**
 * Specks API
 * APIs for specks SaaS
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { BodyDto } from './BodyDto';
import {
    BodyDtoFromJSON,
    BodyDtoFromJSONTyped,
    BodyDtoToJSON,
} from './BodyDto';

/**
 * 
 * @export
 * @interface GetApiResponseBodyDto
 */
export interface GetApiResponseBodyDto {
    /**
     * 
     * @type {string}
     * @memberof GetApiResponseBodyDto
     */
    id: string;
    /**
     * 
     * @type {Array<BodyDto>}
     * @memberof GetApiResponseBodyDto
     */
    values: Array<BodyDto>;
}

/**
 * Check if a given object implements the GetApiResponseBodyDto interface.
 */
export function instanceOfGetApiResponseBodyDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "values" in value;

    return isInstance;
}

export function GetApiResponseBodyDtoFromJSON(json: any): GetApiResponseBodyDto {
    return GetApiResponseBodyDtoFromJSONTyped(json, false);
}

export function GetApiResponseBodyDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetApiResponseBodyDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'values': ((json['values'] as Array<any>).map(BodyDtoFromJSON)),
    };
}

export function GetApiResponseBodyDtoToJSON(value?: GetApiResponseBodyDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'values': ((value.values as Array<any>).map(BodyDtoToJSON)),
    };
}

