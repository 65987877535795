import { SpecksApisContext } from 'App';

import React, { useContext, useState } from 'react';
import { type QueryClient, useMutation, useQuery, useQueryClient } from 'react-query';
import { type NavigateFunction, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { useAuth0 } from '@auth0/auth0-react';

import { Button } from 'components/core/Button/Button';
import { Modal } from 'components/core/Modal/Modal';
import { Text } from 'components/core/Text/Text';

interface DeleteProductProps {
  productId?: string;
  setProductId: (productId?: string) => void;
  behaviourAfterDeletion: () => void;
}

export function DeleteProduct({ productId, setProductId, behaviourAfterDeletion }: DeleteProductProps): JSX.Element {
  const { productService } = useContext(SpecksApisContext);

  const { getAccessTokenSilently, user } = useAuth0();

  /* ---------------------------------------------------- states ---------------------------------------------------- */

  const [isLoading, setIsLoading] = useState<boolean>(false);

  /* ------------------------------------------------ Delete Product ------------------------------------------------ */
  async function deleteProduct(): Promise<void> {
    if (productId === undefined) return;
    const accessToken: string = await getAccessTokenSilently();
    await productService.delete(productId, accessToken);
  }

  const { mutate: mutateDeleteProduct } = useMutation(deleteProduct, {
    onSuccess: () => {
      toast.success('Le produit a bien été supprimé');
      behaviourAfterDeletion();

      setIsLoading(false);
    },
    onError: () => {
      toast.error('Erreur lors de la suppression du produit');
      setIsLoading(false);
    },
  });

  function handleProductDeletionConfirmation(): void {
    setIsLoading(true);
    try {
      setTimeout(() => {
        mutateDeleteProduct();
      }, 1);
    } catch (error) {
      console.error(error);
    }
  }

  function closeModal(): void {
    setProductId(undefined);
  }

  return (
    <Modal title="Attention" isOpen={productId !== undefined} setIsOpen={closeModal}>
      <>
        <Text
          whitespace="pre-line"
          position="justify"
          content="Vous allez supprimer définitivement ce produit ainsi que tous les éléments qui y sont rattachés.
   Seuls les composants applicatifs seront conservés. Êtes-vous certain de vouloir poursuivre ?"
        />
        <div className="flex flex-row gap-2 justify-end">
          {!isLoading && (
            <Button
              onClick={handleProductDeletionConfirmation}
              content="Supprimer"
              width="1/2"
              type="submit"
              height="sm"
            />
          )}
          {isLoading && <Button iconName="spinCircle" width="1/2" height="sm" iconAnimation="spin" />}
        </div>
      </>
    </Modal>
  );
}
