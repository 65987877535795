/* tslint:disable */
/* eslint-disable */
/**
 * Specks API
 * APIs for specks SaaS
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { GetPageRevisionsInfoDto } from './GetPageRevisionsInfoDto';
import {
    GetPageRevisionsInfoDtoFromJSON,
    GetPageRevisionsInfoDtoFromJSONTyped,
    GetPageRevisionsInfoDtoToJSON,
} from './GetPageRevisionsInfoDto';

/**
 * 
 * @export
 * @interface GetPagesDto
 */
export interface GetPagesDto {
    /**
     * 
     * @type {Array<GetPageRevisionsInfoDto>}
     * @memberof GetPagesDto
     */
    pages: Array<GetPageRevisionsInfoDto>;
}

/**
 * Check if a given object implements the GetPagesDto interface.
 */
export function instanceOfGetPagesDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "pages" in value;

    return isInstance;
}

export function GetPagesDtoFromJSON(json: any): GetPagesDto {
    return GetPagesDtoFromJSONTyped(json, false);
}

export function GetPagesDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetPagesDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'pages': ((json['pages'] as Array<any>).map(GetPageRevisionsInfoDtoFromJSON)),
    };
}

export function GetPagesDtoToJSON(value?: GetPagesDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'pages': ((value.pages as Array<any>).map(GetPageRevisionsInfoDtoToJSON)),
    };
}

