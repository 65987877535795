import { type ExternalLink } from './externalLink.entity';
import { type Resource } from './resource.entity';

export class BackApiSpecification {
  id: string;

  resources: Resource[];

  externalLinks: ExternalLink[];
}
