import { type InputTypePath } from 'forms/ApiPathParameterForm/ApiPathParameterForm';

export type BooleanEnum = 'true' | 'false' | 'null';

export class ApiPathParameter {
  id: string;

  name: string;

  isMandatory: BooleanEnum;

  valueType: InputTypePath;

  defaultValue: string;
}
