import React from 'react';

import { Tooltip } from '@mui/material';

import { type ChipBorderColor } from 'components/core/Chip/Chip';
import { type ChipBgColor } from 'components/core/Chip/Chip.props';

import { ComponentType } from 'models/ComponentTypes.type';

import concatClassNames from './classNames';
import getIcon from './getIcon';

interface GetComponentSymbolProps {
  type?: ComponentType;
  bgColor?: ChipBgColor;
  borderColor?: ChipBorderColor;
  label?: string;
  tooltip?: boolean;
  tooltipMessage?: string;
  handleOnClick?: (e: any) => void;
  id?: string;
}

export default function getComponentSymbol({
  type,
  bgColor = 'white',
  borderColor = 'gray-50',
  label,
  tooltip,
  tooltipMessage,
  id,
  handleOnClick,
}: GetComponentSymbolProps): JSX.Element {
  if (type === undefined) {
    return <div>error</div>;
  }
  switch (type) {
    case ComponentType.Front:
      return (
        <Tooltip title={tooltipMessage} placement="bottom-start">
          <div
            onClick={handleOnClick}
            className={concatClassNames(
              'flex justify-center items-center',
              'h-10 w-10',
              'rounded-full cursor-pointer hover:shadow-md',
              'bg-white',
            )}
          >
            {getIcon('front', 'pink', 'sm')}
          </div>
        </Tooltip>
      );
    case ComponentType.BackApi:
      return (
        <Tooltip title={tooltipMessage} placement="bottom-start">
          <div
            onClick={handleOnClick}
            className={concatClassNames(
              'flex justify-center items-center',
              'h-10 w-10',
              'rounded-full cursor-pointer hover:shadow-md',
              'bg-white',
            )}
          >
            {getIcon('api', 'blue', 'sm')}
          </div>
        </Tooltip>
      );

    case ComponentType.Database:
      return (
        <Tooltip title={tooltipMessage} placement="bottom-start">
          <div
            onClick={handleOnClick}
            className={concatClassNames(
              'flex justify-center items-center',
              'h-10 w-10',
              'rounded-full cursor-pointer',
              'bg-white',
              'hover:shadow-md',
            )}
          >
            {getIcon('database', 'yellow', 'sm')}
          </div>
        </Tooltip>
      );
    default:
      return <div>error</div>;
  }
}
