import { SpecksApisContext } from 'App';

import React, { useContext } from 'react';
import { useQuery } from 'react-query';
import { type NavigateFunction, useNavigate, useParams, useSearchParams } from 'react-router-dom';

import { useAuth0 } from '@auth0/auth0-react';

import { BaseEditPage } from 'pages/core/BaseEditPage/BaseEditPage';

import { type BreadCrumbsProps, Header } from 'components/core/Header/Header';
import { IsEditingChip } from 'components/core/IsEditingChip/IsEditingChip';
import { Widget } from 'components/core/Widget/Widget';
import { ApiWidget } from 'components/specks/Widgets/ApiWidget';

import { type Component } from 'models/component.entity';

import { tooltipDescriptionsStyle } from 'utils/tooltipsDescriptions';

/* ----------------------------------------------- resource Provider ---------------------------------------------- */

export function ComponentBackApiSpecificationApiPage(): JSX.Element {
  interface ComponentPageParams {
    componentId?: string;
  }

  /* --------------------------------------------------- contexts --------------------------------------------------- */

  const { componentService } = useContext(SpecksApisContext);
  const { componentId = '' }: ComponentPageParams = useParams();
  const [searchParams] = useSearchParams();
  const navigate: NavigateFunction = useNavigate();
  const { getAccessTokenSilently } = useAuth0();

  /* --------------------------------------------------- variables -------------------------------------------------- */

  const isEditionPage: boolean = searchParams.get('edition') === 'true';
  const selectedPathMethodId: string | undefined = searchParams.get('methodid') ?? undefined;

  /* ---------------------------------------------------- states ---------------------------------------------------- */

  /* ---------------------------------------------------- queries --------------------------------------------------- */

  async function getComponent(): Promise<Component> {
    const accessToken: string = await getAccessTokenSilently();
    return await componentService.findById(componentId, accessToken);
  }

  const { data: componentData, status: componentStatus } = useQuery<Component, Error>(
    ['component', componentId],
    getComponent,
    {
      cacheTime: 0,
    },
  );

  /* --------------------------------------------------- functions -------------------------------------------------- */

  function handleClickBack(): void {
    let path: string = `/component/${componentId}/`;
    if (selectedPathMethodId !== undefined) {
      path = path.concat('?methodid=').concat(selectedPathMethodId);
    }
    navigate(path);
  }

  /* ------------------------------------------------------ JSX ----------------------------------------------------- */

  const breadCrumbs: BreadCrumbsProps = {
    paths: [
      { name: 'Composants applicatifs', href: '/components' },
      { name: componentData?.name ?? 'Error', href: `/component/${componentId}` },
    ],
    current: 'Spécification API',
  };

  return (
    <BaseEditPage>
      <Header
        breadCrumbs={isEditionPage ? undefined : breadCrumbs}
        handleClickBack={handleClickBack}
        title={componentData?.name}
        status={componentData?.status}
        marginY
        leftExtra={<>{isEditionPage && <IsEditingChip />}</>}
        thumbnail
        thumbnailIcon="api"
        thumbnailTooltipMessage={tooltipDescriptionsStyle.api}
      />
      <Widget type="full">
        <ApiWidget componentId={componentId} isEditable />
      </Widget>
    </BaseEditPage>
  );
}
