import React from 'react';
import { type NavigateFunction, useNavigate } from 'react-router-dom';

import { Tooltip } from '@mui/material';

import { Card } from 'components/core/Card/Card';
import { CardBody } from 'components/core/Card/CardBody';
import { type CursorTypes } from 'components/core/Core.style';
import { IconBadge } from 'components/core/IconBadge/IconBadge';
import { Settings } from 'components/core/Settings/Settings';
import { type ButtonMenuProps } from 'components/core/Settings/Settings.types';
import { Text } from 'components/core/Text/Text';

import { type IconName, type IconSize } from 'icons/Icon.props';

type CockpitRowProps =
  | {
      id?: string;
      name: string;
      icon: IconName;
      href?: string;
      openInNewTab?: boolean;
      loading?: false;
      hasMenu?: boolean;
      onDeletionClick?: (id: string) => void;
      onEditClick?: (id: string) => void;
      iconWidth?: number;
      iconSize?: IconSize;
      cursor?: CursorTypes;
      tooltip?: string;
    }
  | {
      id?: never;
      name?: never;
      icon?: never;
      href?: never;
      openInNewTab?: never;
      loading: true;
      hasMenu?: false;
      onDeletionClick?: never;
      onEditClick?: never;
      iconWidth?: never;
      iconSize?: never;
      cursor?: never;
      tooltip?: never;
    };
export function CockpitRow({
  id = '',
  name = '',
  icon = 'home',
  loading = false,
  href,
  openInNewTab = false,
  hasMenu = true,
  onDeletionClick,
  onEditClick,
  iconWidth,
  iconSize = 'xl',
  cursor = 'pointer',
  tooltip,
}: CockpitRowProps): JSX.Element {
  /* --------------------------------------------------- contexts --------------------------------------------------- */

  const navigate: NavigateFunction = useNavigate();

  /* --------------------------------------------------- functions -------------------------------------------------- */

  function handleClick(): void {
    if (loading) return;
    if (href === undefined) console.error('href is null or undefined');
    else {
      if (openInNewTab) window.open(href, '_blank');
      else navigate(href);
    }
  }

  function handleDeletionClick(event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void {
    event.stopPropagation();
    if (onDeletionClick !== undefined) onDeletionClick(id);
  }

  function handleEditClick(event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void {
    event.stopPropagation();
    if (onEditClick !== undefined) onEditClick(id);
  }

  /* ------------------------------------------------- menu options ------------------------------------------------- */

  const buttonsMenu: ButtonMenuProps[] = [
    {
      name: 'Modifier',
      iconName: 'edit',
      textColor: 'black',
      onClick: handleEditClick,
    },
    {
      name: 'Supprimer',
      iconName: 'trash',
      textColor: 'red',
      onClick: handleDeletionClick,
    },
  ];

  /* ------------------------------------------------------ JSX ----------------------------------------------------- */

  return (
    <div
      onClick={handleClick}
      className=" flex flex-row items-center shadow-shadowUp rounded-md w-full  cursor-pointer relative"
    >
      {loading ? (
        <>
          <IconBadge loading />
          <Text loading />
        </>
      ) : (
        <>
          {hasMenu && (
            <div className="absolute self-center right-4">
              <Settings settingsIconName="more" buttonsMenu={buttonsMenu} position="left" />
            </div>
          )}

          <IconBadge shadow="lg" icon={icon} color="black" size={iconSize} iconWidth={iconWidth} />
          <Text content={name} color="black" weight="bold" size="base" position="center" />
        </>
      )}
    </div>
  );
}
