import { SpecksApisContext } from 'App';

import React, { useContext, useState } from 'react';
import { type QueryClient, useMutation, useQuery, useQueryClient } from 'react-query';
import { type NavigateFunction, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import uuid from 'react-uuid';

import { useAuth0 } from '@auth0/auth0-react';

import { BasePage } from 'pages/core/BasePage/BasePage';

import { CreateProductFeatureForm } from 'forms/CreateProductFeatureForm/CreateProductFeatureForm';

import { Button } from 'components/core/Button/Button';
import { CardContainer } from 'components/core/CardContainer/CardContainer';
import { type BreadCrumbsProps, Header } from 'components/core/Header/Header';
import { Modal } from 'components/core/Modal/Modal';
import { Section } from 'components/core/Section/Section';
import { Text } from 'components/core/Text/Text';
import { Title } from 'components/core/Title/Title';
import { Empty } from 'components/specks/Empty/Empty';
import { NetworkError } from 'components/specks/Error/Error';
import { FeatureCard } from 'components/specks/FeatureCard/FeatureCard';
import { UserAvatar } from 'components/specks/UserAvatar/UserAvatar';

import { type Feature } from 'models/feature.entity';
import { type Product } from 'models/product.entity';

interface ProductPageParams {
  id?: string;
}

interface IFeature {
  productId: string;
  feature: Feature;
}

export function ProductFeaturesPage(): JSX.Element {
  /* --------------------------------------------------- contexts --------------------------------------------------- */
  const queryClient: QueryClient = useQueryClient();
  const { productService, featureService } = useContext(SpecksApisContext);
  const { id = '' }: ProductPageParams = useParams();
  const navigate: NavigateFunction = useNavigate();
  const { getAccessTokenSilently, user } = useAuth0();

  /* ---------------------------------------------------- states ---------------------------------------------------- */

  const [isModalOpened, setIsModalOpened] = useState<boolean>(false);
  const [selectedFeatureId, setSelectedFeatureId] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isDeleteFeatureModalOpened, setIsDeleteFeatureModalOpened] = useState<boolean>(false);

  /* ---------------------------------------------------- Queries --------------------------------------------------- */

  const { data: featureData, status: featureStatus } = useQuery<Feature[], Error>('features', async () => {
    const accessToken: string = await getAccessTokenSilently();
    return await productService.getProductFeatures(id, accessToken);
  });

  const { data: productData, status: productStatus } = useQuery<Product, Error>('product', async () => {
    const accessToken: string = await getAccessTokenSilently();
    return await productService.findById(id, accessToken);
  });

  /* --------------------------------------------------- functions -------------------------------------------------- */

  // TODO : Might be good to define all hrefs and name in a file and pick hte pages for all breadcrumbs

  function handleClickAddFeature(): void {
    setIsModalOpened(true);
  }

  function handleClickBack(): void {
    navigate(`/product/${id}`);
  }

  /* ------------------------------------------------ Create Feature ------------------------------------------------ */

  async function createFeature({ productId, feature }: IFeature): Promise<Feature> {
    const accessToken: string = await getAccessTokenSilently();
    return await productService.createFeature(productId, feature, accessToken);
  }

  const { mutate } = useMutation(createFeature, {
    onSuccess: (feature: Feature) => {
      queryClient.setQueryData<Feature[]>('features', (oldData: Feature[] | undefined) => {
        if (oldData === undefined) {
          return new Array<Feature>();
        }
        oldData.push(feature);
        navigate(`/product/${id}/feature/${feature.id}`);
        return oldData;
      });
      toast.success('Fonctionnalité ajoutée avec succès');
    },
    onError: () => {
      toast.error('Erreur lors de la création de la fonctionnalité');
    },
  });

  function handleProductFeatureFormSubmission(feature: Feature): void {
    if (productStatus === 'success') {
      mutate({
        productId: productData.id,
        feature,
      });
      setIsModalOpened(false);
    }
  }

  /* ------------------------------------------------- Delete Feature ----------------------------------------------- */

  async function deleteFeature(): Promise<void> {
    const accessToken: string = await getAccessTokenSilently();
    await featureService.deleteFeature(selectedFeatureId, accessToken);
  }

  const { mutate: mutateDeleteFeature } = useMutation(deleteFeature, {
    onSuccess: () => {
      queryClient.setQueryData<Feature[]>('features', (oldData: Feature[] | undefined) => {
        if (oldData === undefined) {
          return new Array<Feature>();
        } else {
          oldData = oldData.filter((feature) => feature.id !== selectedFeatureId);
          return oldData;
        }
      });
      toast.success('Fonctionnalité supprimée avec succès');
      setIsLoading(false);
    },
    onError: () => {
      toast.error('Erreur lors de la suppression de la fonctionnalité');
    },
  });

  function handleFeatureDeletionConfirmation(): void {
    setIsDeleteFeatureModalOpened(false);
    try {
      mutateDeleteFeature();
    } catch (error) {
      console.error(error);
    }
  }
  function handleFeatureDeletionClick(assetId: string): void {
    setSelectedFeatureId(assetId);
    setIsDeleteFeatureModalOpened(true);
  }

  function handleOnSubmitDeleteFeature(event: any): void {
    event.preventDefault();
    setIsLoading(true);
    setTimeout(() => {
      handleFeatureDeletionConfirmation();
    }, 1);
  }

  /* ------------------------------------------------------ JSX ----------------------------------------------------- */

  const breadCrumbs: BreadCrumbsProps = {
    paths: [
      {
        name: 'Produits',
        href: '/products',
      },
      {
        name: productData?.name ?? '',
        href: `/product/${id}`,
      },
    ],
    current: 'Fonctionnalités',
  };

  return (
    <BasePage>
      <Modal title="Ajouter une fonctionnalité" isOpen={isModalOpened} setIsOpen={setIsModalOpened}>
        <CreateProductFeatureForm onSubmit={handleProductFeatureFormSubmission} />
      </Modal>
      <Modal title="Attention" isOpen={isDeleteFeatureModalOpened} setIsOpen={setIsDeleteFeatureModalOpened}>
        <>
          <Text
            whitespace="pre-line"
            position="justify"
            content="Vous allez supprimer cette fonctionnalité, ainsi que tous les liens documentaires qui y sont rattachés. Êtes-vous certain(e) de vouloir poursuivre ?"
          />
          <div className="flex flex-row gap-2 justify-end">
            {!isLoading && (
              <Button onClick={handleOnSubmitDeleteFeature} content="Supprimer" width="1/2" type="submit" height="sm" />
            )}
            {isLoading && <Button iconName="spinCircle" width="1/2" height="sm" iconAnimation="spin" />}
          </div>
        </>
      </Modal>
      <Header
        breadCrumbs={breadCrumbs}
        handleClickBack={handleClickBack}
        title={productData?.name}
        status={productData?.status}
      />
      <Section isFullHeight>
        <div className="flex flex-row justify-between items-center">
          <Title content="Fonctionnalités" />
          <Button
            content="Ajouter une fonctionnalité"
            width="fit"
            height="sm"
            onClick={handleClickAddFeature}
            iconName="plus"
            disabled={featureStatus !== 'success'}
          />
        </div>
        <>
          {featureStatus === 'error' && (
            <NetworkError message="Un problème est survenu lors de l'import des fonctionnalités" />
          )}
          {featureStatus === 'loading' && (
            <CardContainer cardSize="md">
              <FeatureCard loading={true} />
              <FeatureCard loading={true} />
              <FeatureCard loading={true} />
              <FeatureCard loading={true} />
            </CardContainer>
          )}
          {featureStatus === 'success' &&
            (featureData.length === 0 ? (
              <Empty
                icon="feature"
                title="Pas de fonctionnalité existante"
                subtitle="Ce produit n'a pas encore de fonctionnalité. Ajoutez-en!"
              >
                <Button
                  content="Ajouter une fonctionnalité"
                  onClick={handleClickAddFeature}
                  iconName="plus"
                  width="fit"
                  height="sm"
                />
              </Empty>
            ) : (
              <CardContainer cardSize="md">
                {featureData?.map((feature, index) => {
                  return (
                    <FeatureCard
                      id={feature.id}
                      key={uuid()}
                      name={feature.name}
                      status={feature.status}
                      href={`/product/${id}/feature/${feature.id}`}
                      onDeletionClick={handleFeatureDeletionClick}
                    ></FeatureCard>
                  );
                })}
              </CardContainer>
            ))}
        </>
      </Section>
    </BasePage>
  );
}
