import * as Yup from 'yup';

import React from 'react';
import { type SubmitHandler, useForm } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';

import { Button } from 'components/core/Button/Button';
import Input from 'components/core/Input/Input';
import TextArea from 'components/core/TextArea/TextArea';

import { DictionaryEntry } from 'models/dictionaryEntry.entity';

interface CreateDictionaryEntryFormModel {
  name: string;
  definition: string;
}

const schema: Yup.ObjectSchema<CreateDictionaryEntryFormModel> = Yup.object().shape({
  name: Yup.string().required('Champ obligatoire').min(1, 'Le nom ne doit pas être vide'),
  definition: Yup.string().required('Champ obligatoire'),
});

interface CreateDictionaryEntryFormProps {
  onSubmit: (dictionaryEntry: DictionaryEntry) => void;
  isLoading: boolean;
}

export function CreateDictionaryEntryForm({ onSubmit, isLoading }: CreateDictionaryEntryFormProps): JSX.Element {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors, isSubmitted },
  } = useForm<CreateDictionaryEntryFormModel>({
    resolver: yupResolver(schema),
  });

  const watchName: string = watch('name');
  const watchDefinition: string = watch('definition');

  let isFormInvalid: boolean = true;
  if (watchName !== undefined && watchName !== '' && watchDefinition !== '') {
    isFormInvalid = false;
  }

  const onSubmitForm: SubmitHandler<CreateDictionaryEntryFormModel> = (data) => {
    const dictionaryEntry: DictionaryEntry = new DictionaryEntry();
    dictionaryEntry.name = data.name;
    dictionaryEntry.definition = data.definition;
    onSubmit(dictionaryEntry);
  };

  return (
    <form className="flex flex-col gap-6" onSubmit={handleSubmit(onSubmitForm)}>
      <Input
        label="Mot-clef"
        placeholder="Saisissez le mot ou l'acronyme à définir"
        maxLength={28}
        {...register('name')}
        error={errors.name != null && isSubmitted ? errors.name.message : undefined}
      />
      <TextArea
        label="Définition"
        placeholder="Donnez la définition du mot ou de l'acronyme"
        height="md"
        {...register('definition')}
        error={errors.definition != null && isSubmitted ? errors.definition.message : undefined}
      />
      <div className="flex flex-row gap-2 justify-end">
        {!isLoading && <Button type="submit" content="Créer" width="1/2" disabled={isFormInvalid} />}
        {isLoading && <Button type="button" iconName="spinCircle" width="1/2" height="sm" iconAnimation="spin" />}
      </div>
    </form>
  );
}
