/* tslint:disable */
/* eslint-disable */
/**
 * Specks API
 * APIs for specks SaaS
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdateUiComponentMediaDto
 */
export interface UpdateUiComponentMediaDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateUiComponentMediaDto
     */
    functionalId?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateUiComponentMediaDto
     */
    type: UpdateUiComponentMediaDtoTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof UpdateUiComponentMediaDto
     */
    name: string;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateUiComponentMediaDto
     */
    isFormValid: boolean;
    /**
     * 
     * @type {string}
     * @memberof UpdateUiComponentMediaDto
     */
    notes: string;
    /**
     * 
     * @type {number}
     * @memberof UpdateUiComponentMediaDto
     */
    index: number;
    /**
     * 
     * @type {string}
     * @memberof UpdateUiComponentMediaDto
     */
    parentId?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateUiComponentMediaDto
     */
    contentType: UpdateUiComponentMediaDtoContentTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof UpdateUiComponentMediaDto
     */
    sourceMethodId?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateUiComponentMediaDto
     */
    sourceConstant: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateUiComponentMediaDto
     */
    sourceLogicalRule: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateUiComponentMediaDto
     */
    isVisible: UpdateUiComponentMediaDtoIsVisibleEnum;
    /**
     * 
     * @type {string}
     * @memberof UpdateUiComponentMediaDto
     */
    isVisibleLogicalRule: string;
}


/**
 * @export
 */
export const UpdateUiComponentMediaDtoTypeEnum = {
    Title: 'title',
    Icon: 'icon',
    Text: 'text',
    Container: 'container',
    Input: 'input',
    Button: 'button',
    List: 'list',
    Media: 'media',
    Date: 'date',
    Select: 'select'
} as const;
export type UpdateUiComponentMediaDtoTypeEnum = typeof UpdateUiComponentMediaDtoTypeEnum[keyof typeof UpdateUiComponentMediaDtoTypeEnum];

/**
 * @export
 */
export const UpdateUiComponentMediaDtoContentTypeEnum = {
    Constant: 'CONSTANT',
    Logical: 'LOGICAL'
} as const;
export type UpdateUiComponentMediaDtoContentTypeEnum = typeof UpdateUiComponentMediaDtoContentTypeEnum[keyof typeof UpdateUiComponentMediaDtoContentTypeEnum];

/**
 * @export
 */
export const UpdateUiComponentMediaDtoIsVisibleEnum = {
    Yes: 'YES',
    Logical: 'LOGICAL'
} as const;
export type UpdateUiComponentMediaDtoIsVisibleEnum = typeof UpdateUiComponentMediaDtoIsVisibleEnum[keyof typeof UpdateUiComponentMediaDtoIsVisibleEnum];


/**
 * Check if a given object implements the UpdateUiComponentMediaDto interface.
 */
export function instanceOfUpdateUiComponentMediaDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "isFormValid" in value;
    isInstance = isInstance && "notes" in value;
    isInstance = isInstance && "index" in value;
    isInstance = isInstance && "contentType" in value;
    isInstance = isInstance && "sourceConstant" in value;
    isInstance = isInstance && "sourceLogicalRule" in value;
    isInstance = isInstance && "isVisible" in value;
    isInstance = isInstance && "isVisibleLogicalRule" in value;

    return isInstance;
}

export function UpdateUiComponentMediaDtoFromJSON(json: any): UpdateUiComponentMediaDto {
    return UpdateUiComponentMediaDtoFromJSONTyped(json, false);
}

export function UpdateUiComponentMediaDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateUiComponentMediaDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'functionalId': !exists(json, 'functionalId') ? undefined : json['functionalId'],
        'type': json['type'],
        'name': json['name'],
        'isFormValid': json['isFormValid'],
        'notes': json['notes'],
        'index': json['index'],
        'parentId': !exists(json, 'parentId') ? undefined : json['parentId'],
        'contentType': json['contentType'],
        'sourceMethodId': !exists(json, 'sourceMethodId') ? undefined : json['sourceMethodId'],
        'sourceConstant': json['sourceConstant'],
        'sourceLogicalRule': json['sourceLogicalRule'],
        'isVisible': json['isVisible'],
        'isVisibleLogicalRule': json['isVisibleLogicalRule'],
    };
}

export function UpdateUiComponentMediaDtoToJSON(value?: UpdateUiComponentMediaDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'functionalId': value.functionalId,
        'type': value.type,
        'name': value.name,
        'isFormValid': value.isFormValid,
        'notes': value.notes,
        'index': value.index,
        'parentId': value.parentId,
        'contentType': value.contentType,
        'sourceMethodId': value.sourceMethodId,
        'sourceConstant': value.sourceConstant,
        'sourceLogicalRule': value.sourceLogicalRule,
        'isVisible': value.isVisible,
        'isVisibleLogicalRule': value.isVisibleLogicalRule,
    };
}

