import React, { createContext, useContext } from 'react';

export type tooltipDescriptions = 'component' | 'product' | 'api' | 'ressource' | 'method';

export const tooltipDescriptionsStyle: Record<tooltipDescriptions, string> = {
  component:
    'Un composant applicatif est une brique logicielle qui permet de réaliser un produit. Il peut être de type front-end, back-end ou base de données.',
  product:
    'Un produit est un site web ou une application mobile qui répond à un besoin. Il est construit à partir de briques logicielles (aussi appelées composants applicatifs).',
  api: 'Une API REST est une interface rassemblant des ressources et des méthodes, qui permettent à un composant applicatif back-end de communiquer avec un autre composant applicatif. Elle va être appelée par des composants applicatifs front-end ou back-end pour récupérer des données ou effectuer des actions.',
  ressource:
    'Une ressource est un objet de l’API REST qui peut être manipulé par des méthodes (Création, Lecture, Suppression, Mise à jour). Elle représente souvent un objet métier (ex : un utilisateur, un produit).',
  method:
    'Une méthode est une action que l’on peut réaliser sur une ressource. Ces méthodes peuvent être de type GET (récupérer une ressource), POST (créer une ressource), PUT (mettre à jour une ressource) et DELETE (supprimer une ressource).',
};
