/* eslint-disable max-len */
import React from 'react';

import concatClassNames from 'utils/classNames';

import { type IconProps, iconColorStyle, iconSizeStyle } from './Icon.props';

export default function SelectIcon({ color, size }: IconProps): JSX.Element {
  return (
    <svg
      className={concatClassNames(iconSizeStyle[size])}
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="1"
        y="16"
        width="45"
        height="17"
        rx="2"
        stroke={iconColorStyle[color]}
        strokeWidth="3"
        strokeLinejoin="round"
      />
      <path
        d="M34.101 29.4715C34.301 29.7364 34.699 29.7364 34.899 29.4715L41.2563 21.0513C41.505 20.7219 41.27 20.25 40.8572 20.25H28.1428C27.73 20.25 27.495 20.7219 27.7437 21.0513L34.101 29.4715Z"
        stroke={iconColorStyle[color]}
        strokeWidth="3"
        strokeLinejoin="round"
      />
    </svg>
  );
}
