import React from 'react';

import { type ChipBorderColor } from 'components/core/Chip/Chip';
import { type ChipBgColor } from 'components/core/Chip/Chip.props';
import { BackApiTypeChip } from 'components/specks/Chips/types/BackApiTypeChip/BackApiTypeChip';
import { DatabaseTypeChip } from 'components/specks/Chips/types/DatabaseTypeChip/DatabaseTypeChip';
import { FrontTypeChip } from 'components/specks/Chips/types/FrontTypeChip/FrontTypeChip';

import { ComponentType } from 'models/ComponentTypes.type';

export default function getTypeChip(
  type?: ComponentType,
  bgColor: ChipBgColor = 'white',
  borderColor: ChipBorderColor = 'gray-50',
  label?: string,
  tooltip?: boolean,
  tooltipMessage?: string,
): JSX.Element {
  if (type === undefined) {
    return <div>error</div>;
  }
  switch (type) {
    case ComponentType.Front:
      return (
        <FrontTypeChip
          bgColor={bgColor}
          borderColor={borderColor}
          label={label}
          tooltip={tooltip}
          tooltipMessage={tooltipMessage}
        />
      );
    case ComponentType.BackApi:
      return (
        <BackApiTypeChip
          bgColor={bgColor}
          borderColor={borderColor}
          label={label}
          tooltip={tooltip}
          tooltipMessage={tooltipMessage}
        />
      );

    case ComponentType.Database:
      return (
        <DatabaseTypeChip
          bgColor={bgColor}
          borderColor={borderColor}
          label={label}
          tooltip={tooltip}
          tooltipMessage={tooltipMessage}
        />
      );
    default:
      return <div>error</div>;
  }
}
