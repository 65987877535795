import * as Yup from 'yup';

import React, { useEffect, useState } from 'react';
import { type SubmitHandler, useForm } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';

import { Button } from 'components/core/Button/Button';
import Input from 'components/core/Input/Input';
import RadioButtons from 'components/core/RadioButtons/RadioButtons';
import { TabElement } from 'components/core/Tabs/TabElement';
import { TabList } from 'components/core/Tabs/TabList';
import { TabPanel } from 'components/core/Tabs/TabPanel';
import { TabPanels } from 'components/core/Tabs/TabPanels';
import { Tabs } from 'components/core/Tabs/Tabs';
import { Text } from 'components/core/Text/Text';
import TextArea from 'components/core/TextArea/TextArea';

import type { YesNoLogical } from 'models/ui-component-revisions.entity';
import { type UiComponent } from 'models/uiComponent.entity';

import concatClassNames from 'utils/classNames';

import UICFieldReadOnly from '../UICFieldReadonly/UICFieldReadonly';

interface InputInfosFormProps {
  uiComponent: UiComponent;
  checkIfNameIsUnique?: (name: string, id: string) => boolean;
  onClickSave?: () => void;
  setIsFormDirty?: (isDirty: boolean) => void;
  isLoading: boolean;
}

const paramsTypeOptions: YesNoLogical[] = ['YES', 'NO', 'LOGICAL'];

export const paramsTypeStyleOptions: string[] = ['Oui', 'Non', 'Règle logique'];

interface InputFormModel {
  name: string;
  notes?: string;
  label?: string;

  isEditable: YesNoLogical;
  isEditableLogicalRule?: string;

  isPreFilled: YesNoLogical;
  isPreFilledConstant?: string;
  isPreFilledLogicalRule?: string;

  hasPlaceholder: YesNoLogical;
  hasPlaceholderConstant?: string;
  hasPlaceholderLogicalRule?: string;

  isMandatory: YesNoLogical;
  isMandatoryLogicalRule?: string;

  hasPattern: YesNoLogical;
  hasPatternConstant?: string;
  hasPatternLogicalRule?: string;
}

const schema: Yup.ObjectSchema<InputFormModel> = Yup.object().shape({
  name: Yup.string()
    .required('Champ obligatoire')
    .max(28, 'Le nom ne doit pas contenir plus de 28 caractères')
    .test('isUnique', "Un composant d'interface avec ce nom existe déjà", (value, context) => {
      const uiComponent: UiComponent | undefined = context?.options?.context?.uiComponent as UiComponent;
      const isNameUnique: boolean | undefined = context?.options?.context?.checkIfNameIsUnique(value, uiComponent.id);
      return value != null && value.length > 0 && isNameUnique;
    }),
  notes: Yup.string(),
  label: Yup.string(),

  isEditable: Yup.mixed<YesNoLogical>().required('Champ obligatoire').default('YES'),
  isEditableLogicalRule: Yup.string(),

  isPreFilled: Yup.mixed<YesNoLogical>().required('Champ obligatoire').default('NO'),
  isPreFilledConstant: Yup.string(),
  isPreFilledLogicalRule: Yup.string(),

  hasPlaceholder: Yup.mixed<YesNoLogical>().required('Champ obligatoire').default('NO'),
  hasPlaceholderConstant: Yup.string(),
  hasPlaceholderLogicalRule: Yup.string(),

  isMandatory: Yup.mixed<YesNoLogical>().required('Champ obligatoire').default('NO'),
  isMandatoryLogicalRule: Yup.string(),

  hasPattern: Yup.mixed<YesNoLogical>().required('Champ obligatoire').default('NO'),
  hasPatternConstant: Yup.string(),
  hasPatternLogicalRule: Yup.string(),
});

export function InputInfosForm({
  uiComponent,
  checkIfNameIsUnique,
  onClickSave,
  setIsFormDirty,
  isLoading,
}: InputInfosFormProps): JSX.Element {
  /* --------------------------------------------------- Contexts --------------------------------------------------- */
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors, isSubmitted },
  } = useForm<InputFormModel>({
    resolver: yupResolver(schema),
    context: { checkIfNameIsUnique, uiComponent },
  });
  const [originalName, setOriginalName] = useState<string>('');
  const [originalNotes, setOriginalNotes] = useState<string>('');
  const [originalLabel, setOriginalLabel] = useState<string>();
  const [originalIsEditable, setOriginalIsEditable] = useState<YesNoLogical>();
  const [originalIsEditableLogicalRule, setOriginalIsEditableLogicalRule] = useState<string>();
  const [originalIsPreFilled, setOriginalIsPreFilled] = useState<YesNoLogical>();
  const [originalIsPreFilledConstant, setOriginalIsPreFilledConstant] = useState<string>();
  const [originalIsPreFilledLogicalRule, setOriginalIsPreFilledLogicalRule] = useState<string>();
  const [originalHasPlaceholder, setOriginalHasPlaceholder] = useState<YesNoLogical>();
  const [originalHasPlaceholderConstant, setOriginalHasPlaceholderConstant] = useState<string>();
  const [originalHasPlaceholderLogicalRule, setOriginalHasPlaceholderLogicalRule] = useState<string>();
  const [originalIsMandatory, setOriginalIsMandatory] = useState<YesNoLogical>();
  const [originalIsMandatoryLogicalRule, setOriginalIsMandatoryLogicalRule] = useState<string>();
  const [originalHasPattern, setOriginalHasPattern] = useState<YesNoLogical>();
  const [originalHasPatternConstant, setOriginalHasPatternConstant] = useState<string>();
  const [originalHasPatternLogicalRule, setOriginalHasPatternLogicalRule] = useState<string>();

  const watchName: string = watch('name');
  const watchNotes: string | undefined = watch('notes');
  const watchLabel: string | undefined = watch('label');
  const watchIsEditable: YesNoLogical = watch('isEditable');
  const watchIsEditableLogicalRule: string | undefined = watch('isEditableLogicalRule');
  const watchIsPreFilled: YesNoLogical = watch('isPreFilled');
  const watchIsPreFilledConstant: string | undefined = watch('isPreFilledConstant');
  const watchIsPreFilledLogicalRule: string | undefined = watch('isPreFilledLogicalRule');
  const watchHasPlaceholder: YesNoLogical = watch('hasPlaceholder');
  const watchHasPlaceholderConstant: string | undefined = watch('hasPlaceholderConstant');
  const watchHasPlaceholderLogicalRule: string | undefined = watch('hasPlaceholderLogicalRule');
  const watchIsMandatory: YesNoLogical = watch('isMandatory');
  const watchIsMandatoryLogicalRule: string | undefined = watch('isMandatoryLogicalRule');
  const watchHasPattern: YesNoLogical = watch('hasPattern');
  const watchHasPatternConstant: string | undefined = watch('hasPatternConstant');
  const watchHasPatternLogicalRule: string | undefined = watch('hasPatternLogicalRule');

  /* ----------------------------------------------- alerts management ---------------------------------------------- */

  const isCompleteName: boolean = watchName !== undefined && watchName.length !== 0;

  const isCompleteIsEditableLogicalRule: boolean =
    watchIsEditable !== 'LOGICAL' ||
    (watchIsEditableLogicalRule !== undefined && watchIsEditableLogicalRule.length !== 0);

  const isCompleteIsPreFilledConstant: boolean =
    watchIsPreFilled !== 'YES' || (watchIsPreFilledConstant !== undefined && watchIsPreFilledConstant.length !== 0);
  const isCompleteIsPreFilledLogicalRule: boolean =
    watchIsPreFilled !== 'LOGICAL' ||
    (watchIsPreFilledLogicalRule !== undefined && watchIsPreFilledLogicalRule.length !== 0);

  const isCompleteHasPlaceholderConstant: boolean =
    watchHasPlaceholder !== 'YES' ||
    (watchHasPlaceholderConstant !== undefined && watchHasPlaceholderConstant.length !== 0);
  const isCompleteHasPlaceholderLogicalRule: boolean =
    watchHasPlaceholder !== 'LOGICAL' ||
    (watchHasPlaceholderLogicalRule !== undefined && watchHasPlaceholderLogicalRule.length !== 0);

  const isCompleteIsMandatoryLogicalRule: boolean =
    watchIsMandatory !== 'LOGICAL' ||
    (watchIsMandatoryLogicalRule !== undefined && watchIsMandatoryLogicalRule.length !== 0);

  const isCompleteHasPatternConstant: boolean =
    watchHasPattern !== 'YES' || (watchHasPatternConstant !== undefined && watchHasPatternConstant.length !== 0);
  const isCompleteHasPatternLogicalRule: boolean =
    watchHasPattern !== 'LOGICAL' ||
    (watchHasPatternLogicalRule !== undefined && watchHasPatternLogicalRule.length !== 0);

  /* -------------------------------------------------- Setup Data -------------------------------------------------- */

  useEffect(() => {
    setOriginalName(uiComponent.uiComponentRevisions[0].name);
    setOriginalNotes(uiComponent.uiComponentRevisions[0].notes);
    setOriginalLabel(uiComponent.uiComponentRevisions[0].label);
    setOriginalIsEditable(uiComponent.uiComponentRevisions[0].isEditable);
    setOriginalIsEditableLogicalRule(uiComponent.uiComponentRevisions[0].isEditableLogicalRule);
    setOriginalIsPreFilled(uiComponent.uiComponentRevisions[0].isPreFilled);
    setOriginalIsPreFilledConstant(uiComponent.uiComponentRevisions[0].isPreFilledConstant);
    setOriginalIsPreFilledLogicalRule(uiComponent.uiComponentRevisions[0].isPreFilledLogicalRule);
    setOriginalHasPlaceholder(uiComponent.uiComponentRevisions[0].hasPlaceholder);
    setOriginalHasPlaceholderConstant(uiComponent.uiComponentRevisions[0].hasPlaceholderConstant);
    setOriginalHasPlaceholderLogicalRule(uiComponent.uiComponentRevisions[0].hasPlaceholderLogicalRule);
    setOriginalIsMandatory(uiComponent.uiComponentRevisions[0].isMandatory);
    setOriginalIsMandatoryLogicalRule(uiComponent.uiComponentRevisions[0].isMandatoryLogicalRule);
    setOriginalHasPattern(uiComponent.uiComponentRevisions[0].hasPattern);
    setOriginalHasPatternConstant(uiComponent.uiComponentRevisions[0].hasPatternConstant);
    setOriginalHasPatternLogicalRule(uiComponent.uiComponentRevisions[0].hasPatternLogicalRule);

    setValue('name', uiComponent.uiComponentRevisions[0].name);
    setValue('notes', uiComponent.uiComponentRevisions[0].notes);
    setValue('label', uiComponent.uiComponentRevisions[0].label);
    setValue('isEditable', uiComponent.uiComponentRevisions[0].isEditable);
    setValue('isEditableLogicalRule', uiComponent.uiComponentRevisions[0].isEditableLogicalRule);
    setValue('isPreFilled', uiComponent.uiComponentRevisions[0].isPreFilled);
    setValue('isPreFilledConstant', uiComponent.uiComponentRevisions[0].isPreFilledConstant);
    setValue('isPreFilledLogicalRule', uiComponent.uiComponentRevisions[0].isPreFilledLogicalRule);
    setValue('hasPlaceholder', uiComponent.uiComponentRevisions[0].hasPlaceholder);
    setValue('hasPlaceholderConstant', uiComponent.uiComponentRevisions[0].hasPlaceholderConstant);
    setValue('hasPlaceholderLogicalRule', uiComponent.uiComponentRevisions[0].hasPlaceholderLogicalRule);
    setValue('isMandatory', uiComponent.uiComponentRevisions[0].isMandatory);
    setValue('isMandatoryLogicalRule', uiComponent.uiComponentRevisions[0].isMandatoryLogicalRule);
    setValue('hasPattern', uiComponent.uiComponentRevisions[0].hasPattern);
    setValue('hasPatternConstant', uiComponent.uiComponentRevisions[0].hasPatternConstant);
    setValue('hasPatternLogicalRule', uiComponent.uiComponentRevisions[0].hasPatternLogicalRule);
  }, [setValue, uiComponent, originalName, originalNotes]);

  /* --------------------------------------------------- Functions -------------------------------------------------- */

  const onSubmitForm: SubmitHandler<InputFormModel> = (data: InputFormModel) => {
    if (onClickSave == null) return;
    uiComponent.uiComponentRevisions[0].name = watchName;
    uiComponent.uiComponentRevisions[0].notes = watchNotes ?? '';
    uiComponent.uiComponentRevisions[0].label = watchLabel ?? '';
    uiComponent.uiComponentRevisions[0].isEditable = watchIsEditable;
    uiComponent.uiComponentRevisions[0].isEditableLogicalRule = watchIsEditableLogicalRule ?? '';
    uiComponent.uiComponentRevisions[0].isPreFilled = watchIsPreFilled;
    uiComponent.uiComponentRevisions[0].isPreFilledConstant = watchIsPreFilledConstant ?? '';
    uiComponent.uiComponentRevisions[0].isPreFilledLogicalRule = watchIsPreFilledLogicalRule ?? '';
    uiComponent.uiComponentRevisions[0].hasPlaceholder = watchHasPlaceholder;
    uiComponent.uiComponentRevisions[0].hasPlaceholderConstant = watchHasPlaceholderConstant ?? '';
    uiComponent.uiComponentRevisions[0].hasPlaceholderLogicalRule = watchHasPlaceholderLogicalRule ?? '';
    uiComponent.uiComponentRevisions[0].isMandatory = watchIsMandatory;
    uiComponent.uiComponentRevisions[0].isMandatoryLogicalRule = watchIsMandatoryLogicalRule ?? '';
    uiComponent.uiComponentRevisions[0].hasPattern = watchHasPattern;
    uiComponent.uiComponentRevisions[0].hasPatternConstant = watchHasPatternConstant ?? '';
    uiComponent.uiComponentRevisions[0].hasPatternLogicalRule = watchHasPatternLogicalRule ?? '';
    uiComponent.uiComponentRevisions[0].isFormValid = checkIfFormIsComplete;

    onClickSave();
  };

  /* --------------------------------------------------- Watchers --------------------------------------------------- */

  const checkIfFormIsComplete: boolean =
    isCompleteName &&
    isCompleteIsEditableLogicalRule &&
    isCompleteIsPreFilledConstant &&
    isCompleteHasPlaceholderConstant &&
    isCompleteIsMandatoryLogicalRule &&
    isCompleteHasPatternConstant;

  let isFormValid: boolean = true;
  setIsFormDirty?.(isFormValid);
  if (
    watchName === undefined ||
    watchName.length === 0 ||
    (watchName === originalName &&
      watchNotes === originalNotes &&
      watchLabel === originalLabel &&
      watchIsEditable === originalIsEditable &&
      watchIsEditableLogicalRule === originalIsEditableLogicalRule &&
      watchIsPreFilled === originalIsPreFilled &&
      watchIsPreFilledConstant === originalIsPreFilledConstant &&
      watchIsPreFilledLogicalRule === originalIsPreFilledLogicalRule &&
      watchHasPlaceholder === originalHasPlaceholder &&
      watchHasPlaceholderConstant === originalHasPlaceholderConstant &&
      watchHasPlaceholderLogicalRule === originalHasPlaceholderLogicalRule &&
      watchIsMandatory === originalIsMandatory &&
      watchIsMandatoryLogicalRule === originalIsMandatoryLogicalRule &&
      watchHasPattern === originalHasPattern &&
      watchHasPatternConstant === originalHasPatternConstant &&
      watchHasPatternLogicalRule === originalHasPatternLogicalRule)
  ) {
    isFormValid = false;
  }
  setIsFormDirty?.(false);
  if (
    watchName !== originalName ||
    watchNotes !== originalNotes ||
    watchLabel !== originalLabel ||
    watchIsEditable !== originalIsEditable ||
    watchIsEditableLogicalRule !== originalIsEditableLogicalRule ||
    watchIsPreFilled !== originalIsPreFilled ||
    watchIsPreFilledConstant !== originalIsPreFilledConstant ||
    watchIsPreFilledLogicalRule !== originalIsPreFilledLogicalRule ||
    watchHasPlaceholder !== originalHasPlaceholder ||
    watchHasPlaceholderConstant !== originalHasPlaceholderConstant ||
    watchHasPlaceholderLogicalRule !== originalHasPlaceholderLogicalRule ||
    watchIsMandatory !== originalIsMandatory ||
    watchIsMandatoryLogicalRule !== originalIsMandatoryLogicalRule ||
    watchHasPattern !== originalHasPattern ||
    watchHasPatternConstant !== originalHasPatternConstant ||
    watchHasPatternLogicalRule !== originalHasPatternLogicalRule
  ) {
    setIsFormDirty?.(true);
  }

  /* --------------------------------------------------- Rendering -------------------------------------------------- */

  const isEditable: boolean = onClickSave != null;

  return (
    <Tabs>
      <TabList position="center">
        <TabElement width="fill" selectedTextWeight="bold" textSize="lg" title={'Spécification'}></TabElement>
        <TabElement width="fill" selectedTextWeight="bold" textSize="lg" title={'Règles logiques'}></TabElement>
      </TabList>
      <TabPanels>
        <TabPanel>
          <form
            className="flex flex-col gap-6 flex-grow justify-between overflow-auto mt-2"
            onSubmit={handleSubmit(onSubmitForm)}
          >
            <div className="flex flex-col gap-6 overflow-auto">
              <div className={concatClassNames('flex flex-col', 'gap-1')}>
                <UICFieldReadOnly
                  label="Identifiant Specks"
                  information="Cet identifiant vous permettra de le retrouver plus facilement dans Specks."
                  content={isEditable ? undefined : watchName}
                />
                {isEditable && (
                  <Input
                    labelWeight="medium"
                    placeholder="L'identifiant Specks de votre composant d'interface"
                    textSize="base"
                    {...register('name')}
                    maxLength={28}
                    error={errors.name != null && isSubmitted ? errors.name.message : undefined}
                  />
                )}
                {watchName !== undefined && watchName.length === 0 && (
                  <Text content="Le nom est obligatoire" color="red-500" size="sm" position="center" />
                )}
              </div>

              <div className={concatClassNames('flex flex-col', 'gap-2')}>
                <UICFieldReadOnly
                  label="Label"
                  information="Le label est la question ou le titre qui sera affiché à l'utilisateur. Que souhaitez-vous afficher ?"
                  content={
                    isEditable ? undefined : watchLabel === undefined || watchLabel.length === 0 ? 'N/A' : watchLabel
                  }
                />
                {isEditable && (
                  <Input
                    labelWeight="medium"
                    placeholder="Saisissez le label du champ de saisie (titre, question...)"
                    {...register('label')}
                    textSize="base"
                  />
                )}
              </div>

              <div className={concatClassNames('flex flex-col', 'gap-2')}>
                <UICFieldReadOnly
                  label="Modifiable  ?"
                  information="Ce champ de saisie peut-il être modifié par l'utilisateur ?"
                  isComplete={isCompleteIsEditableLogicalRule}
                  error="Préconisation : ce champ ne devrait pas être vide"
                />
                <RadioButtons
                  values={paramsTypeOptions}
                  valuesDisplay={paramsTypeStyleOptions}
                  selected={watchIsEditable}
                  {...register('isEditable')}
                  isEditable={isEditable}
                  isComplete={isCompleteIsEditableLogicalRule}
                  error="Préconisation : ce champ ne devrait pas être vide"
                />
                {watchIsEditable === 'LOGICAL' && (
                  <>
                    {isEditable && (
                      <TextArea
                        {...register('isEditableLogicalRule')}
                        value={
                          !isEditable &&
                          (watchIsEditableLogicalRule === undefined || watchIsEditableLogicalRule.length === 0)
                            ? 'N/A'
                            : watchIsEditableLogicalRule
                        }
                        error={
                          errors.isEditableLogicalRule != null && isSubmitted
                            ? errors.isEditableLogicalRule.message
                            : undefined
                        }
                        rows={4}
                        placeholder={`Etant données [les hypothèses suivantes], lorsque [tel événement se produit] alors [voici le comportement attendu] `}
                        disabled={!isEditable}
                        isComplete={isCompleteIsEditableLogicalRule}
                      />
                    )}
                    {!isEditable && (
                      <UICFieldReadOnly
                        content={
                          watchIsEditableLogicalRule === undefined || watchIsEditableLogicalRule.length === 0
                            ? 'N/A'
                            : watchIsEditableLogicalRule
                        }
                      />
                    )}
                  </>
                )}
              </div>

              <div className={concatClassNames('flex flex-col', 'gap-2')}>
                <UICFieldReadOnly
                  label="Prérempli"
                  information="Ce champ de saisie doit-il être prérempli lorsque l'utilisateur accède à la page ?"
                  isComplete={isCompleteIsPreFilledConstant && isCompleteIsPreFilledLogicalRule}
                  error="Préconisation : ce champ ne devrait pas être vide"
                />
                <RadioButtons
                  values={paramsTypeOptions}
                  valuesDisplay={paramsTypeStyleOptions}
                  selected={watchIsPreFilled}
                  {...register('isPreFilled')}
                  isEditable={isEditable}
                  isComplete={isCompleteIsPreFilledConstant && isCompleteIsPreFilledLogicalRule}
                  error="Préconisation : ce champ ne devrait pas être vide"
                />
                {watchIsPreFilled === 'YES' && (
                  <>
                    {isEditable && (
                      <>
                        <TextArea
                          {...register('isPreFilledConstant')}
                          value={
                            !isEditable &&
                            (watchIsPreFilledConstant === undefined || watchIsPreFilledConstant.length === 0)
                              ? 'N/A'
                              : watchIsPreFilledConstant
                          }
                          isComplete={isCompleteIsPreFilledConstant}
                          rows={2}
                          placeholder="Saisissez la donnée qui sera pré-remplie pour l'utilisateur lorsqu'il accèdera à la page"
                          disabled={!isEditable}
                        />
                        {!isEditable && (
                          <UICFieldReadOnly
                            content={
                              watchIsPreFilledConstant === undefined || watchIsPreFilledConstant.length === 0
                                ? 'N/A'
                                : watchIsPreFilledConstant
                            }
                          />
                        )}
                      </>
                    )}
                  </>
                )}
                {watchIsPreFilled === 'LOGICAL' && (
                  <>
                    {isEditable && (
                      <TextArea
                        {...register('isPreFilledLogicalRule')}
                        value={watchIsPreFilledLogicalRule}
                        rows={4}
                        placeholder={`Etant données [les hypothèses suivantes], lorsque [tel événement se produit] alors [voici le comportement attendu] `}
                        isComplete={isCompleteIsPreFilledLogicalRule}
                      />
                    )}
                    {!isEditable && (
                      <UICFieldReadOnly
                        content={
                          watchIsPreFilledLogicalRule === undefined || watchIsPreFilledLogicalRule.length === 0
                            ? 'N/A'
                            : watchIsPreFilledLogicalRule
                        }
                      />
                    )}
                  </>
                )}
              </div>

              <div className={concatClassNames('flex flex-col', 'gap-2')}>
                <UICFieldReadOnly
                  label="Placeholder"
                  information="Souhaitez-vous afficher un texte d'exemple (placeholder) à l'intérieur de la zone de saisie pour guider l'utilisateur dans sa saisie ?"
                  isComplete={isCompleteHasPlaceholderConstant && isCompleteHasPlaceholderLogicalRule}
                  error="Préconisation : ce champ ne devrait pas être vide"
                />
                <RadioButtons
                  values={paramsTypeOptions}
                  valuesDisplay={paramsTypeStyleOptions}
                  selected={watchHasPlaceholder}
                  {...register('hasPlaceholder')}
                  isEditable={isEditable}
                />

                {watchHasPlaceholder === 'YES' && (
                  <>
                    {isEditable && (
                      <>
                        <TextArea
                          {...register('hasPlaceholderConstant')}
                          value={
                            !isEditable &&
                            (watchHasPlaceholderConstant === undefined || watchHasPlaceholderConstant.length === 0)
                              ? 'N/A'
                              : watchHasPlaceholderConstant
                          }
                          isComplete={isCompleteHasPlaceholderConstant}
                          rows={2}
                          placeholder="Saisissez le placeholder qui guidera l'utilisateur (-> ceci est un placeholder ! 😉)"
                          disabled={!isEditable}
                        />
                      </>
                    )}

                    {!isEditable && (
                      <UICFieldReadOnly
                        content={
                          watchHasPlaceholderConstant === undefined || watchHasPlaceholderConstant.length === 0
                            ? 'N/A'
                            : watchHasPlaceholderConstant
                        }
                      />
                    )}
                  </>
                )}
                {watchHasPlaceholder === 'LOGICAL' && (
                  <>
                    {isEditable && (
                      <TextArea
                        {...register('hasPlaceholderLogicalRule')}
                        value={watchHasPlaceholderLogicalRule}
                        isComplete={isCompleteHasPlaceholderLogicalRule}
                        rows={4}
                        placeholder={`Etant données [les hypothèses suivantes], lorsque [tel événement se produit] alors [voici le comportement attendu] `}
                      />
                    )}

                    {!isEditable && (
                      <UICFieldReadOnly
                        content={
                          watchHasPlaceholderLogicalRule === undefined || watchHasPlaceholderLogicalRule.length === 0
                            ? 'N/A'
                            : watchHasPlaceholderLogicalRule
                        }
                      />
                    )}
                  </>
                )}
              </div>

              <div className={concatClassNames('flex flex-col', 'gap-2')}>
                <UICFieldReadOnly
                  label="Obligatoire"
                  information="La saisie de ce champ est-elle obligatoire pour l'utilisateur?"
                  isComplete={isCompleteIsMandatoryLogicalRule}
                  error="Préconisation : ce champ ne devrait pas être vide"
                />
                <RadioButtons
                  values={paramsTypeOptions}
                  valuesDisplay={paramsTypeStyleOptions}
                  selected={watchIsMandatory}
                  {...register('isMandatory')}
                  isEditable={isEditable}
                  isComplete={isCompleteIsMandatoryLogicalRule}
                />

                {watchIsMandatory === 'LOGICAL' && (
                  <>
                    {isEditable && (
                      <TextArea
                        {...register('isMandatoryLogicalRule')}
                        isComplete={isCompleteIsMandatoryLogicalRule}
                        rows={4}
                        value={watchIsMandatoryLogicalRule}
                        placeholder={`Etant données [les hypothèses suivantes], lorsque [tel événement se produit] alors [voici le comportement attendu] `}
                        disabled={!isEditable}
                      />
                    )}

                    {!isEditable && (
                      <UICFieldReadOnly
                        content={
                          watchIsMandatoryLogicalRule === undefined || watchIsMandatoryLogicalRule.length === 0
                            ? 'N/A'
                            : watchIsMandatoryLogicalRule
                        }
                      />
                    )}
                  </>
                )}
              </div>

              <div className={concatClassNames('flex flex-col', 'gap-2')}>
                <UICFieldReadOnly
                  label="Pattern"
                  information="Lorsque l'utilisateur saisit cette information, doit-il respecter un nombre de caractères et/ou un pattern spécifique ?"
                  isComplete={isCompleteHasPatternConstant && isCompleteHasPatternLogicalRule}
                  error="Préconisation : ce champ ne devrait pas être vide"
                />
                <RadioButtons
                  values={paramsTypeOptions}
                  valuesDisplay={paramsTypeStyleOptions}
                  selected={watchHasPattern}
                  {...register('hasPattern')}
                  isEditable={isEditable}
                  isComplete={isCompleteHasPatternConstant && isCompleteHasPatternLogicalRule}
                  error="Préconisation : ce champ ne devrait pas être vide"
                />
                {watchHasPattern === 'YES' && (
                  <>
                    {isEditable && (
                      <TextArea
                        {...register('hasPatternConstant')}
                        value={watchHasPatternConstant}
                        isComplete={isCompleteHasPatternConstant}
                        rows={4}
                        placeholder="Indiquez ici les éléments à respecter par l'utilisateur"
                        disabled={!isEditable}
                      />
                    )}
                    {!isEditable && (
                      <UICFieldReadOnly
                        content={
                          watchHasPatternConstant === undefined || watchHasPatternConstant.length === 0
                            ? 'N/A'
                            : watchHasPatternConstant
                        }
                      />
                    )}
                  </>
                )}
                {watchHasPattern === 'LOGICAL' && (
                  <>
                    {isEditable && (
                      <TextArea
                        {...register('hasPatternLogicalRule')}
                        value={watchHasPatternLogicalRule}
                        isComplete={isCompleteHasPatternLogicalRule}
                        rows={4}
                        placeholder={`Etant données [les hypothèses suivantes], lorsque [tel événement se produit] alors [voici le comportement attendu] `}
                        disabled={!isEditable}
                      />
                    )}

                    {!isEditable && (
                      <UICFieldReadOnly
                        content={
                          watchHasPatternLogicalRule === undefined || watchHasPatternLogicalRule.length === 0
                            ? 'N/A'
                            : watchHasPatternLogicalRule
                        }
                      />
                    )}
                  </>
                )}
              </div>

              {isEditable && (
                <TextArea
                  label="Notes"
                  labelWeight="medium"
                  labelColor="gray-700"
                  placeholder="Ajouter une note"
                  {...register('notes')}
                  value={watchNotes}
                  rows={6}
                  error={errors.notes != null && isSubmitted ? errors.notes.message : undefined}
                  disabled={!isEditable}
                />
              )}
              {!isEditable && (
                <UICFieldReadOnly
                  label="Notes"
                  content={watchNotes === undefined || watchNotes.length === 0 ? 'N/A' : watchNotes}
                />
              )}
            </div>
            {onClickSave != null && (
              <div className="flex flex-row gap-2 justify-end">
                {!isLoading && <Button content="Sauvegarder" type="submit" disabled={!isFormValid} />}
                {isLoading && <Button iconName="spinCircle" type="button" height="sm" iconAnimation="spin" />}
              </div>
            )}
          </form>
        </TabPanel>
        <TabPanel>
          <div className={concatClassNames('flex flex-col flex-grow justify-between')}>
            <div className={concatClassNames('flex flex-col gap-4')}>
              <div className={concatClassNames('flex flex-col gap-1')}>
                <Text content="Règles du Composant d'interface" weight="bold" position="left" />
                <Text
                  content="Aucune règle n'a été définie pour ce composant. "
                  color="gray-100"
                  position="left"
                  size="sm"
                />
              </div>
              <div className={concatClassNames('flex flex-col gap-1')}>
                <Text content="Règles héritées (non modifiables)" weight="bold" position="left" />
                <Text
                  content="Ce composant ne reçoit aucune règle en héritage"
                  color="gray-100"
                  position="left"
                  size="sm"
                />
              </div>
            </div>
            {onClickSave != null && (
              <div className={concatClassNames('flex gap-2 pt-8 border-t-1 border-gray-50')}>
                <Button content="Ajouter une règle" iconName="plus" cursor="not-allowed" />
                <Button content="Sauvegarder" cursor="not-allowed" />
              </div>
            )}
          </div>
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
}
