import React from 'react';

import concatClassNames from 'utils/classNames';

import { type PaddingSize, paddingXSizeStyle, paddingYSizeStyle } from '../CardContainer/CardContainer';

export type GapSize = '0' | '2' | '4' | '6' | '8' | '10' | '12' | '14' | '16';

export const gapSizeStyle: Record<GapSize, string> = {
  '0': 'gap-0',
  '2': 'gap-2',
  '4': 'gap-4',
  '6': 'gap-6',
  '8': 'gap-8',
  '10': 'gap-10',
  '12': 'gap-12',
  '14': 'gap-14',
  '16': 'gap-16',
};

interface SectionProps {
  children: JSX.Element[];
  isFullHeight?: boolean;
  gap?: GapSize;
  paddingX?: PaddingSize;
  paddingY?: PaddingSize;
}

export function Section({
  children,
  isFullHeight,
  gap = '4',
  paddingX = '8',
  paddingY = '8',
}: SectionProps): JSX.Element {
  return (
    <div
      className={concatClassNames(
        'flex',
        'flex-col',
        'bg-white',
        'rounded-md',
        paddingXSizeStyle[paddingX],
        paddingYSizeStyle[paddingY],
        gapSizeStyle[gap],
        isFullHeight !== undefined ? 'flex-grow' : '',
      )}
    >
      {children}
    </div>
  );
}
