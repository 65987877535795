import { SpecksApisContext } from 'App';

import React, { useContext } from 'react';
import { useQuery } from 'react-query';
import { type NavigateFunction, useNavigate } from 'react-router-dom';

import { useAuth0 } from '@auth0/auth0-react';

import { Text } from 'components/core/Text/Text';
import { DeleteChip } from 'components/specks/Chips/Api/DeleteChip';
import { GetChip } from 'components/specks/Chips/Api/GetChip';
import { PatchChip } from 'components/specks/Chips/Api/PatchChip';
import { PostChip } from 'components/specks/Chips/Api/PostChip';
import { PutChip } from 'components/specks/Chips/Api/PutChip';

import { ComponentService } from 'services/component.service';

import { type Component } from 'models/component.entity';

import concatClassNames from 'utils/classNames';
import getIcon from 'utils/getIcon';

interface ApiMethodRowProps {
  type?: string;
  methodId: string;
  path: string;
  description: string;
  onClick?: (methodId: string) => void;
  borderColored: boolean;
  hasRadioButtons?: boolean;
  isRadioButtonSelected?: boolean;
  onClickRadioButton?: (methodId: string) => void;
  clickable?: boolean;
  onDelete?: () => void;
  openInNewTab?: boolean;
}

export function ApiMethodRow({
  type,
  methodId,
  path,
  description,
  onClick,
  borderColored,
  hasRadioButtons = false,
  isRadioButtonSelected = false,
  onClickRadioButton,
  clickable = true,
  onDelete,
  openInNewTab = false,
}: ApiMethodRowProps): JSX.Element {
  const { componentService } = useContext(SpecksApisContext);
  const { getAccessTokenSilently } = useAuth0();
  const navigate: NavigateFunction = useNavigate();

  function handleOnClick(event: any): void {
    event.stopPropagation();
    if (onClick === undefined && componentStatus === 'success') {
      window.open(`/component/${componentData?.id}/specifications-api?methodid=${methodId}`, '_blank');
    } else {
      onClick?.(methodId);
    }
  }

  function handleOnDelete(event: any): void {
    event.stopPropagation();
    onDelete?.();
  }

  const borderColorByType: Map<string, string> = new Map<string, string>([
    ['GET', 'border-2 border-miscelleanous-blue'],
    ['POST', 'border-2 border-green-500'],
    ['PUT', 'border-2 border-yellow-400'],
    ['PATCH', 'border-2 border-yellow-400'],
    ['DELETE', 'border-2 border-red-500'],
  ]);

  const bgColorByType: Map<string, string> = new Map<string, string>([
    ['GET', 'bg-miscelleanous-blue/5'],
    ['POST', 'bg-green-500/5'],
    ['PUT', 'bg-yellow-400/5'],
    ['PATCH', 'bg-yellow-400/5'],
    ['DELETE', 'bg-red-500/5'],
  ]);

  const { data: componentData, status: componentStatus } = useQuery<Component, Error>({
    queryKey: ['component', 'method', methodId],
    queryFn: async ({ queryKey }) => {
      const [, , methodId] = queryKey;
      const accessToken: string = await getAccessTokenSilently();

      return await componentService.getComponentByMethodId(methodId as string, accessToken);
    },
  });

  return (
    <div
      className={concatClassNames(
        'flex flex-row',
        'items-center',
        'gap-1',
        borderColored ? bgColorByType.get(type ?? 'GET') ?? '' : 'bg-white',
        'rounded-md',
        'flex-grow',
        borderColored ? borderColorByType.get(type ?? 'GET') ?? '' : 'border-1 border-gray-50',
        'p-2',
        'justify-between',
        clickable ? 'cursor-pointer' : '',
      )}
      onClick={handleOnClick}
    >
      <div className={concatClassNames('grid grid-cols-[repeat(17,1fr)]', 'w-full', 'items-center', 'gap-1')}>
        <div className="flex align-top sm:col-span-6 md:col-span-5 lg:col-span-4 h-full">
          {type === 'GET' && <GetChip />}
          {type === 'POST' && <PostChip />}
          {type === 'PUT' && <PutChip />}
          {type === 'PATCH' && <PatchChip />}
          {type === 'DELETE' && <DeleteChip />}
        </div>
        <div className={concatClassNames('col-span-10 md:col-span-11 lg:col-span-12', 'overflow-hidden')}>
          <Text
            content={path.replaceAll('/', '/\u200b')}
            position="left"
            size="sm"
            whitespace="pre-wrap"
            textBreak="words"
            color="gray-500"
          />
        </div>
        {onDelete !== undefined && (
          <div className={concatClassNames('col-end-[18] col-span-1', 'cursor-pointer')} onClick={handleOnDelete}>
            {getIcon('trash', 'red', 'sm')}
          </div>
        )}
        <div className="overflow-hidden sm:col-start-7 md:col-start-6 lg:col-start-5 col-span-full">
          <Text
            content={description}
            position="left"
            size="xs"
            color="gray-200"
            whitespace="pre-wrap"
            textBreak="words"
            weight="light"
          />
        </div>
      </div>
    </div>
  );
}
