import { get } from 'http';

import React from 'react';

import { Tooltip } from '@mui/material';

import { ButtonInfosForm } from 'components/specks/ButtonInfosForm/ButtonInfosForm';
import { ContainerInfosForm } from 'components/specks/ContainerInfosForm/ContainerInfosForm';
import { DateInfosForm } from 'components/specks/DateInfosForm/DateInfosForm';
import { IconsInfosForm } from 'components/specks/IconsInfosForm/IconsInfosForm';
import { InputInfosForm } from 'components/specks/InputInfosForm/InputInfosForm';
import { ListInfosForm } from 'components/specks/ListInfosForm/ListInfosForm';
import { MediaInfosForm } from 'components/specks/MediaInfosForm/MediaInfosForm';
import { SelectInfosForm } from 'components/specks/SelectInfosForm/SelectInfosForm';
import { TextInfosForm } from 'components/specks/TextInfosForm/TextInfosForm';
import { TitleInfosForm } from 'components/specks/TitleInfosForm/TitleInfosForm';
import { uiComponentCardType } from 'components/specks/UiComponentCard/UiComponentCard.props';

import { type UiComponent } from 'models/uiComponent.entity';

import concatClassNames from 'utils/classNames';
import getIcon from 'utils/getIcon';

import { BackButton } from '../BackButton/BackButton';
import { Settings } from '../Settings/Settings';
import { type ButtonMenuProps } from '../Settings/Settings.types';
import SideMenu from '../SideMenu/SideMenu';
import { Title } from '../Title/Title';

interface UiComponentInfosMenuProps {
  uiComponent?: UiComponent;
  checkIfNameIsUnique?: (name: string, id: string) => boolean;
  onClickBackButton: () => void;
  onClickSave?: () => void;
  isEditable?: boolean;
  setIsFormDirty?: (isDirty: boolean) => void;
  handleUIComponentDeletion?: (id: string) => void;
  onClickAddTarget?: () => void;
  hidden?: boolean;
  isLoading?: boolean;
  setFnSetMethodId?: (fn: (methodId: string | undefined) => void) => void;
  copyToClipboard?: () => void;
}

export function UiComponentInfosMenu({
  uiComponent,
  checkIfNameIsUnique,
  onClickBackButton,
  onClickSave,
  isEditable = false,
  setIsFormDirty,
  handleUIComponentDeletion,
  onClickAddTarget,
  hidden = false,
  isLoading = false,
  setFnSetMethodId,
  copyToClipboard,
}: UiComponentInfosMenuProps): JSX.Element {
  /* --------------------------------------------------- contexts --------------------------------------------------- */

  /* ---------------------------------------------------- states ---------------------------------------------------- */

  function handleClickDeleteUic(): void {
    if (handleUIComponentDeletion !== undefined && uiComponent !== undefined && uiComponent.id !== undefined)
      handleUIComponentDeletion(uiComponent.id);
  }

  /* ------------------------------------------------------ JSX ----------------------------------------------------- */

  if (uiComponent == null) return <></>;
  return (
    <>
      <SideMenu hidden={hidden} width="md">
        <>
          <div className="flex flex-row items-center justify-between mb-2">
            <div className="flex flex-row gap-6 items-center">
              <BackButton onClick={onClickBackButton} />
              <Title
                content={
                  uiComponentCardType[uiComponent.type].canvaLabel + ' : ' + uiComponent.uiComponentRevisions[0].name
                }
                size="h4"
              />
            </div>
            {uiComponent.id !== 'new' && (
              <div className="flex flex-row items-center z-10 gap-1">
                {copyToClipboard !== undefined && (
                  <Tooltip
                    title="Copier l'URL vers le presse-papier"
                    translate="yes"
                    enterDelay={200}
                    enterNextDelay={200}
                  >
                    <div
                      className={concatClassNames('cursor-pointer')}
                      onClick={() => {
                        if (copyToClipboard !== undefined) copyToClipboard();
                      }}
                    >
                      {getIcon('clipboard', 'black', 'lg')}
                    </div>
                  </Tooltip>
                )}
                {uiComponent.id !== undefined && isEditable && (
                  <Tooltip
                    title="Supprimer le composant d'interface"
                    translate="yes"
                    enterDelay={200}
                    enterNextDelay={200}
                  >
                    <div
                      className={concatClassNames('cursor-pointer hover:fill-red-600')}
                      onClick={handleClickDeleteUic}
                    >
                      {getIcon('trash', 'gray-500', 'md')}
                    </div>
                  </Tooltip>
                )}
              </div>
            )}
          </div>
          {uiComponent.type === 'title' && (
            <TitleInfosForm
              uiComponent={uiComponent}
              checkIfNameIsUnique={checkIfNameIsUnique}
              onClickSave={onClickSave}
              setIsFormDirty={setIsFormDirty}
              onClickAddTarget={onClickAddTarget}
              setFnSetMethodId={setFnSetMethodId}
              isLoading={isLoading}
            />
          )}
          {uiComponent.type === 'input' && (
            <InputInfosForm
              uiComponent={uiComponent}
              checkIfNameIsUnique={checkIfNameIsUnique}
              onClickSave={onClickSave}
              setIsFormDirty={setIsFormDirty}
              isLoading={isLoading}
            />
          )}
          {uiComponent.type === 'button' && (
            <ButtonInfosForm
              uiComponent={uiComponent}
              checkIfNameIsUnique={checkIfNameIsUnique}
              onClickSave={onClickSave}
              setIsFormDirty={setIsFormDirty}
              onClickAddTarget={onClickAddTarget}
              setFnSetMethodId={setFnSetMethodId}
              isLoading={isLoading}
            />
          )}
          {uiComponent.type === 'date' && (
            <DateInfosForm
              uiComponent={uiComponent}
              checkIfNameIsUnique={checkIfNameIsUnique}
              onClickSave={onClickSave}
              setIsFormDirty={setIsFormDirty}
              onClickAddTarget={onClickAddTarget}
              setFnSetMethodId={setFnSetMethodId}
              isLoading={isLoading}
            />
          )}

          {uiComponent.type === 'select' && (
            <SelectInfosForm
              uiComponent={uiComponent}
              checkIfNameIsUnique={checkIfNameIsUnique}
              onClickSave={onClickSave}
              setIsFormDirty={setIsFormDirty}
              onClickAddTarget={onClickAddTarget}
              setFnSetMethodId={setFnSetMethodId}
              isLoading={isLoading}
            />
          )}
          {uiComponent.type === 'icon' && (
            <IconsInfosForm
              uiComponent={uiComponent}
              checkIfNameIsUnique={checkIfNameIsUnique}
              onClickSave={onClickSave}
              setIsFormDirty={setIsFormDirty}
              onClickAddTarget={onClickAddTarget}
              setFnSetMethodId={setFnSetMethodId}
              isLoading={isLoading}
            />
          )}
          {uiComponent.type === 'list' && (
            <ListInfosForm
              uiComponent={uiComponent}
              checkIfNameIsUnique={checkIfNameIsUnique}
              onClickSave={onClickSave}
              setIsFormDirty={setIsFormDirty}
              onClickAddTarget={onClickAddTarget}
              setFnSetMethodId={setFnSetMethodId}
              isLoading={isLoading}
            />
          )}
          {uiComponent.type === 'media' && (
            <MediaInfosForm
              uiComponent={uiComponent}
              checkIfNameIsUnique={checkIfNameIsUnique}
              onClickSave={onClickSave}
              setIsFormDirty={setIsFormDirty}
              onClickAddTarget={onClickAddTarget}
              setFnSetMethodId={setFnSetMethodId}
              isLoading={isLoading}
            />
          )}
          {uiComponent.type === 'text' && (
            <TextInfosForm
              uiComponent={uiComponent}
              checkIfNameIsUnique={checkIfNameIsUnique}
              onClickSave={onClickSave}
              setIsFormDirty={setIsFormDirty}
              onClickAddTarget={onClickAddTarget}
              setFnSetMethodId={setFnSetMethodId}
              isLoading={isLoading}
            />
          )}
          {uiComponent.type === 'container' && (
            <ContainerInfosForm
              uiComponent={uiComponent}
              checkIfNameIsUnique={checkIfNameIsUnique}
              onClickSave={onClickSave}
              setIsFormDirty={setIsFormDirty}
              onClickAddTarget={onClickAddTarget}
              setFnSetMethodId={setFnSetMethodId}
              isLoading={isLoading}
            />
          )}
        </>
      </SideMenu>
    </>
  );
}
