/* tslint:disable */
/* eslint-disable */
/**
 * Specks API
 * APIs for specks SaaS
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Component } from './Component';
import {
    ComponentFromJSON,
    ComponentFromJSONTyped,
    ComponentToJSON,
} from './Component';
import type { ExternalLink } from './ExternalLink';
import {
    ExternalLinkFromJSON,
    ExternalLinkFromJSONTyped,
    ExternalLinkToJSON,
} from './ExternalLink';
import type { Sitemap } from './Sitemap';
import {
    SitemapFromJSON,
    SitemapFromJSONTyped,
    SitemapToJSON,
} from './Sitemap';

/**
 * 
 * @export
 * @interface FrontSpecification
 */
export interface FrontSpecification {
    /**
     * 
     * @type {string}
     * @memberof FrontSpecification
     */
    id: string;
    /**
     * 
     * @type {Date}
     * @memberof FrontSpecification
     */
    createdAt: Date;
    /**
     * 
     * @type {Array<ExternalLink>}
     * @memberof FrontSpecification
     */
    externalLinks: Array<ExternalLink>;
    /**
     * 
     * @type {Array<Sitemap>}
     * @memberof FrontSpecification
     */
    sitemapRevisions: Array<Sitemap>;
    /**
     * 
     * @type {Component}
     * @memberof FrontSpecification
     */
    component: Component;
}

/**
 * Check if a given object implements the FrontSpecification interface.
 */
export function instanceOfFrontSpecification(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "createdAt" in value;
    isInstance = isInstance && "externalLinks" in value;
    isInstance = isInstance && "sitemapRevisions" in value;
    isInstance = isInstance && "component" in value;

    return isInstance;
}

export function FrontSpecificationFromJSON(json: any): FrontSpecification {
    return FrontSpecificationFromJSONTyped(json, false);
}

export function FrontSpecificationFromJSONTyped(json: any, ignoreDiscriminator: boolean): FrontSpecification {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'createdAt': (new Date(json['createdAt'])),
        'externalLinks': ((json['externalLinks'] as Array<any>).map(ExternalLinkFromJSON)),
        'sitemapRevisions': ((json['sitemapRevisions'] as Array<any>).map(SitemapFromJSON)),
        'component': ComponentFromJSON(json['component']),
    };
}

export function FrontSpecificationToJSON(value?: FrontSpecification | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'createdAt': (value.createdAt.toISOString()),
        'externalLinks': ((value.externalLinks as Array<any>).map(ExternalLinkToJSON)),
        'sitemapRevisions': ((value.sitemapRevisions as Array<any>).map(SitemapToJSON)),
        'component': ComponentToJSON(value.component),
    };
}

