import React from 'react';
import { type QueryStatus } from 'react-query';
import uuid from 'react-uuid';

import { Tooltip } from '@mui/material';

import { type ComponentType } from 'models/ComponentTypes.type';
import { type Status } from 'models/Status.types';

import concatClassNames from 'utils/classNames';
import getIcon from 'utils/getIcon';
import getStatusChip from 'utils/getStatusChip';
import getTypeChip from 'utils/getTypeChip';

import { type IconColor, type IconName } from 'icons/Icon.props';

import { BackButton } from '../BackButton/BackButton';
import { BreadCrumbs } from '../BreadCrumbs/BreadCrumbs';
import { Chip } from '../Chip/Chip';
import { type ButtonMenuProps } from '../Settings/Settings.types';
import { Title } from '../Title/Title';

export interface BreadCrumbsProps {
  paths: Array<{ name: string; href: string }>;
  current: string;
}

interface HeaderProps {
  queryStatus?: QueryStatus;
  buttonsMenu?: ButtonMenuProps[];
  breadCrumbs?: BreadCrumbsProps;
  handleClickBack?: () => void;
  componentType?: ComponentType;
  title?: string;
  description?: JSX.Element;
  status?: Status;
  rightExtra?: JSX.Element;
  leftExtra?: JSX.Element;
  marginX?: boolean;
  marginY?: boolean;
  thumbnail?: boolean;
  thumbnailIcon?: IconName;
  thumbnailTooltipMessage?: string;
  relatedProductsLength?: number | undefined;
  setIsConsumingProductsModalOpened?: (isConsumingProductsModalOpened: boolean) => void;
}

export function Header({
  queryStatus = 'success',
  buttonsMenu,
  breadCrumbs,
  handleClickBack,
  componentType,
  title,
  description,
  status,
  rightExtra,
  leftExtra,
  marginX = true,
  marginY = false,
  thumbnail = false,
  thumbnailIcon = 'component',
  thumbnailTooltipMessage,
  relatedProductsLength,
  setIsConsumingProductsModalOpened,
}: HeaderProps): JSX.Element {
  return (
    <div id="header" className={concatClassNames('flex flex-col gap-4', marginX ? 'mx-8' : '', marginY ? 'my-5' : '')}>
      {breadCrumbs !== undefined && (
        <>
          {queryStatus === 'success' ? (
            <BreadCrumbs paths={breadCrumbs.paths} current={breadCrumbs.current} />
          ) : (
            <BreadCrumbs loading />
          )}
        </>
      )}
      <div className={concatClassNames('flex flex-row justify-between gap-4')}>
        <div className={concatClassNames('flex flex-row items-center gap-6 p-0')}>
          {handleClickBack !== undefined && <BackButton onClick={handleClickBack} />}

          {thumbnail && (
            <Tooltip title={thumbnailTooltipMessage}>
              <div
                className={concatClassNames(
                  'flex align-middle justify-center items-center h-16 w-16',
                  'bg-white rounded-full',
                  ' border-gray-500 border-[4px]',
                )}
              >
                {getIcon(thumbnailIcon, 'gray-500', 'lg')}
              </div>
            </Tooltip>
          )}
          <div className="flex flex-col">
            {queryStatus === 'success' && (
              <>
                {title !== undefined && <Title content={title} size="h2" />}
                {description !== undefined && description}
                {componentType !== undefined && getTypeChip(componentType, 'transparent', 'none')}
              </>
            )}
            {queryStatus !== 'success' && (
              <>
                <Title loading />
                <Chip loading />
              </>
            )}
          </div>
          {status !== undefined && (queryStatus === 'success' ? getStatusChip({ name: status }) : <Chip loading />)}
          {relatedProductsLength !== undefined && (
            <Tooltip title="Produits consommateurs">
              <div
                className="relative hover:cursor-pointer rounded-full"
                onClick={() => {
                  setIsConsumingProductsModalOpened?.(true);
                }}
              >
                <div>{getIcon('product', 'gray-500', 'lg')}</div>
                <div className="flex absolute top-4 left-4 h-6 w-6 rounded-full bg-red-500 items-center justify-center">
                  <p className="text-white ">{relatedProductsLength}</p>
                </div>
              </div>
            </Tooltip>
          )}

          {buttonsMenu !== undefined && (
            <div className={concatClassNames('flex flex-row bg-gray-25 rounded-full gap-4 py-3 px-3')}>
              {buttonsMenu?.map((button) => (
                <Tooltip title={button.name} key={uuid()}>
                  <div className="hover:cursor-pointer rounded-full" onClick={button.onClick}>
                    {button.iconName !== undefined && getIcon(button.iconName, button.textColor as IconColor, 'md')}
                  </div>
                </Tooltip>
              ))}
            </div>
          )}
          {leftExtra !== undefined && leftExtra}
        </div>
        {rightExtra !== undefined && rightExtra}
      </div>
    </div>
  );
}
