import * as Yup from 'yup';

import React, { useEffect } from 'react';
import { Controller, type SubmitHandler, useForm } from 'react-hook-form';
import uuid from 'react-uuid';

import { yupResolver } from '@hookform/resolvers/yup';

import { Button } from 'components/core/Button/Button';
import Input from 'components/core/Input/Input';
import { ListBox } from 'components/core/ListBox/ListBox';
import { Text } from 'components/core/Text/Text';
import { type ResourceHandler, useResourceHandler } from 'components/specks/Widgets/ApiWidget';

import { ApiHeaderParameter } from 'models/API/Parameters/apiHeaderParameter.entity';

import concatClassNames from 'utils/classNames';

/* ------------------------------------------------ isRequired Form ----------------------------------------------- */

enum IsRequiredEnum {
  YES = 'Obligatoire',
  NO = 'Facultatif',
}

const isRequiredOptions: string[] = [IsRequiredEnum.YES, IsRequiredEnum.NO];

const isRequiredStyleOptions: Map<string, JSX.Element> = new Map<string, JSX.Element>([
  [IsRequiredEnum.YES, <Text key={uuid()} content="Obligatoire" size="api" position="center" />],
  [IsRequiredEnum.NO, <Text key={uuid()} content="Facultatif" size="api" position="center" />],
]);

const isRequiredSelectedStyleOptions: Map<string, JSX.Element> = new Map<string, JSX.Element>([
  [
    IsRequiredEnum.YES,
    <Text
      key={uuid()}
      content="Obligatoire"
      color="purple-500"
      size="api"
      textDecoration="underline-4"
      position="center"
    />,
  ],
  [
    IsRequiredEnum.NO,
    <Text
      key={uuid()}
      content="Facultatif"
      color="purple-500"
      size="api"
      textDecoration="underline-4"
      position="center"
    />,
  ],
]);

/* ------------------------------------------------ boolean Form ----------------------------------------------- */

export type BooelanEnum = 'yes' | 'no' | 'null';

const booleanName: Record<BooelanEnum, string> = {
  yes: 'Vrai',
  no: 'Faux',
  null: 'Non renseigné',
};

const booleanOptions: string[] = [booleanName.yes, booleanName.no, booleanName.null];

const booleanStyleOptions: Map<string, JSX.Element> = new Map<string, JSX.Element>([
  [booleanName.yes, <Text key={uuid()} content="Vrai" size="api" position="left" />],
  [booleanName.no, <Text key={uuid()} content="Faux" size="api" position="left" />],
  [booleanName.null, <Text key={uuid()} content="Non renseigné" size="api" position="left" />],
]);

const booleanSelectedStyleOptions: Map<string, JSX.Element> = new Map<string, JSX.Element>([
  [
    booleanName.yes,
    <Text key={uuid()} content="Vrai" color="purple-500" size="api" textDecoration="underline-4" position="left" />,
  ],
  [
    booleanName.no,
    <Text key={uuid()} content="Faux" color="purple-500" size="api" textDecoration="underline-4" position="left" />,
  ],
  [
    booleanName.null,
    <Text
      key={uuid()}
      content="Non renseigné"
      color="purple-500"
      size="api"
      textDecoration="underline-4"
      position="left"
    />,
  ],
]);

/* --------------------------------------------------- type Form -------------------------------------------------- */

export type InputTypeHeader = 'STRING' | 'NUMBER' | 'BOOLEAN';

export const inputTypeOptions: Map<InputTypeHeader, string> = new Map<InputTypeHeader, string>([
  ['STRING', 'Chaîne de caractères'],
  ['NUMBER', 'Nombre'],
  ['BOOLEAN', 'Booléen'],
]);

function getInputTypeOptions(key: InputTypeHeader): string {
  return inputTypeOptions.get(key) ?? '';
}

const typeOptions: InputTypeHeader[] = ['STRING', 'NUMBER', 'BOOLEAN'];

const typeStyleOptions: Map<string, JSX.Element> = new Map<string, JSX.Element>([
  ['STRING', <Text key={uuid()} content={getInputTypeOptions('STRING')} position="left" size="api" />],
  ['NUMBER', <Text key={uuid()} content={getInputTypeOptions('NUMBER')} position="left" size="api" />],
  ['BOOLEAN', <Text key={uuid()} content={getInputTypeOptions('BOOLEAN')} position="left" size="api" />],
]);

const typeSelectedStyleOptions: Map<string, JSX.Element> = new Map<string, JSX.Element>([
  [
    'STRING',
    <Text
      key={uuid()}
      content={getInputTypeOptions('STRING')}
      color="purple-500"
      size="api"
      textDecoration="underline-4"
      position="left"
    />,
  ],
  [
    'NUMBER',
    <Text
      key={uuid()}
      content={getInputTypeOptions('NUMBER')}
      color="purple-500"
      size="api"
      textDecoration="underline-4"
      position="left"
    />,
  ],
  [
    'BOOLEAN',
    <Text
      key={uuid()}
      content={getInputTypeOptions('BOOLEAN')}
      color="purple-500"
      size="api"
      textDecoration="underline-4"
      position="left"
    />,
  ],
]);

/* ----------------------------------------------------- Form ----------------------------------------------------- */

interface ApiHeaderParameterFormModel {
  name: string;
  isRequired: IsRequiredEnum | undefined;
  type: InputTypeHeader;
  defaultValueString: string | undefined;
  defaultValueNumber: string | undefined;
  defaultValueBoolean: string | undefined;
}

const schema: Yup.ObjectSchema<ApiHeaderParameterFormModel> = Yup.object().shape({
  name: Yup.string().required('Champ obligatoire').max(40, 'Le nom doit contenir au maximum 40 caractères'),
  isRequired: Yup.mixed<IsRequiredEnum>(),
  type: Yup.mixed<InputTypeHeader>().required('Champ obligatoire'),
  defaultValueString: Yup.string(),
  defaultValueNumber: Yup.string(),
  defaultValueBoolean: Yup.mixed<string>(),
});

/* ----------------------------------------------------- Props ---------------------------------------------------- */

interface ApiHeaderParameterFormProps {
  onSubmit: (newParameter: ApiHeaderParameter) => void;
  onCancel?: () => void;
  exisstingHeaderParameters: ApiHeaderParameter[];
  oldHeaderParameter?: ApiHeaderParameter;
}

export function ApiHeaderParameterForm({
  onSubmit,
  onCancel,
  exisstingHeaderParameters,
  oldHeaderParameter,
}: ApiHeaderParameterFormProps): JSX.Element {
  /* --------------------------------------------------- contexts --------------------------------------------------- */

  const resourceHandler: ResourceHandler = useResourceHandler();

  /* ---------------------------------------------------- states ---------------------------------------------------- */

  /* --------------------------------------------------- variables -------------------------------------------------- */

  const mutateStatus: string = resourceHandler.details.mutateStatus;

  /* ----------------------------------------------------- form ----------------------------------------------------- */

  const {
    register,
    unregister,
    control,
    watch,
    setError,
    setValue,
    reset,
    handleSubmit,
    formState: { errors, isSubmitted },
  } = useForm<ApiHeaderParameterFormModel>({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (mutateStatus === 'success') {
      reset();
    }
  }, [mutateStatus, reset]);

  const onSubmitForm: SubmitHandler<ApiHeaderParameterFormModel> = (data) => {
    // check if name is unique
    const isNameUnique: boolean = exisstingHeaderParameters.every((headerParameter: ApiHeaderParameter) => {
      if (headerParameter.id === oldHeaderParameter?.id) return true;
      return headerParameter.name !== data.name;
    });
    if (!isNameUnique) {
      setError('name', { type: 'custom', message: 'Il existe déjà un attribut avec ce nom' });
      return;
    }
    const apiHeaderParameter: ApiHeaderParameter = new ApiHeaderParameter();
    apiHeaderParameter.id = oldHeaderParameter !== undefined ? oldHeaderParameter.id : uuid();

    apiHeaderParameter.name = data.name;
    apiHeaderParameter.isMandatory =
      data.isRequired === IsRequiredEnum.YES ? 'true' : data.isRequired === IsRequiredEnum.NO ? 'false' : 'null';
    apiHeaderParameter.valueType = data.type;
    apiHeaderParameter.defaultValue =
      data.defaultValueString ?? data.defaultValueNumber?.toString() ?? data.defaultValueBoolean ?? '';
    resourceHandler.details.setHeaderData(apiHeaderParameter);
    onSubmit(apiHeaderParameter);
  };

  function onCancelForm(e: any): void {
    e.preventDefault();
    onCancel?.();
  }

  const watchName: string = watch('name');
  let isFormValid: boolean = true;
  if (watchName === undefined || watchName.length < 1) {
    isFormValid = false;
  }

  useEffect(() => {
    if (oldHeaderParameter === undefined) return;
    setValue('name', oldHeaderParameter.name);
    setValue('type', oldHeaderParameter.valueType);
    setValue('isRequired', oldHeaderParameter.isMandatory === 'true' ? IsRequiredEnum.YES : IsRequiredEnum.NO);
    if (oldHeaderParameter.valueType === 'STRING') {
      setValue('defaultValueString', oldHeaderParameter.defaultValue);
    }
    if (oldHeaderParameter.valueType === 'NUMBER') {
      setValue('defaultValueNumber', oldHeaderParameter.defaultValue);
    }
    if (oldHeaderParameter.valueType === 'BOOLEAN') {
      setValue('defaultValueBoolean', oldHeaderParameter.defaultValue);
    }
  }, [oldHeaderParameter, setValue]);

  const watchType: InputTypeHeader = watch('type');

  useEffect(() => {
    if (oldHeaderParameter !== undefined && oldHeaderParameter.valueType === 'STRING') {
      setValue('defaultValueString', oldHeaderParameter.defaultValue);
    }
    if (oldHeaderParameter !== undefined && oldHeaderParameter.valueType === 'NUMBER') {
      setValue('defaultValueNumber', oldHeaderParameter.defaultValue);
    }
    if (oldHeaderParameter !== undefined && oldHeaderParameter.valueType === 'BOOLEAN') {
      setValue('defaultValueBoolean', oldHeaderParameter.defaultValue);
    }
    if (watchType !== 'STRING') {
      unregister('defaultValueString');
    }
    if (watchType !== 'NUMBER') {
      unregister('defaultValueNumber');
    }
    if (watchType !== 'BOOLEAN') {
      unregister('defaultValueBoolean');
    }
  }, [watchType, unregister, oldHeaderParameter, setValue]);

  /* --------------------------------------------------- variables -------------------------------------------------- */

  const gridTemplateColumns: string = 'grid-cols-[repeat(17,minmax(0,1fr))]';
  const gridTitleSpan: string = 'col-span-6';
  const gridTypeSpan: string = 'col-span-3';
  const gridMandatorySpan: string = 'col-span-3';
  const gridDefaultSpan: string = 'col-span-4';

  /* ------------------------------------------------------ JSX ----------------------------------------------------- */

  return (
    <form
      id="api-header-parameter-form"
      className={concatClassNames(
        'grid',
        gridTemplateColumns,
        'gap-1',
        'gap-y-1.5',
        'mt-2',
        'border-1 border-gradient2-from',
        'bg-gradient2-from/5',
        'rounded-md',
        'py-2',
        'px-3',
        'shadow-apiRow',
        'align-middle',
      )}
      onSubmit={handleSubmit(onSubmitForm)}
    >
      <div className={concatClassNames(gridTitleSpan, 'flex items-center')}>
        <Input
          placeholder="Nom du paramètre"
          {...register('name')}
          maxLength={40}
          textSize="base"
          bgColor="white"
          borderColor="gray-500"
        />
      </div>
      <div className={concatClassNames(gridTypeSpan, 'self-center')}>
        <Controller
          name="type"
          control={control}
          defaultValue={'STRING'}
          render={({ field: { value, onChange } }) => (
            <ListBox
              selected={value}
              onChange={onChange}
              options={typeOptions}
              styleOptions={typeStyleOptions}
              selectedStyleOptions={typeSelectedStyleOptions}
              minimalist
            />
          )}
        />
      </div>
      <div className={concatClassNames(gridMandatorySpan, 'self-center')}>
        <Controller
          name="isRequired"
          control={control}
          defaultValue={IsRequiredEnum.NO}
          render={({ field: { value, onChange } }) => (
            <ListBox
              selected={value ?? IsRequiredEnum.NO}
              onChange={onChange}
              options={isRequiredOptions}
              styleOptions={isRequiredStyleOptions}
              selectedStyleOptions={isRequiredSelectedStyleOptions}
              minimalist
            />
          )}
        />
      </div>
      <div className={concatClassNames(gridDefaultSpan, 'flex items-center')}>
        {watchType === 'STRING' && (
          <Input
            placeholder="Valeur par défaut"
            {...register('defaultValueString')}
            textSize="base"
            bgColor="white"
            borderColor="gray-500"
          />
        )}
        {watchType === 'NUMBER' && (
          <Input
            placeholder="Valeur par défaut"
            {...register('defaultValueNumber')}
            isNumber
            textSize="base"
            bgColor="white"
            borderColor="gray-500"
          />
        )}
        {watchType === 'BOOLEAN' && (
          <Controller
            name="defaultValueBoolean"
            control={control}
            defaultValue={booleanName.null}
            render={({ field: { value, onChange } }) => (
              <ListBox
                width="full"
                selected={value ?? booleanName.null}
                onChange={onChange}
                options={booleanOptions}
                styleOptions={booleanStyleOptions}
                selectedStyleOptions={booleanSelectedStyleOptions}
                minimalist
              />
            )}
          />
        )}
      </div>
      <div id="edit-errors" className="col-start-1 col-span-12">
        {watchName !== undefined && watchName.length === 40 && (
          <Text content="Vous avez atteint le nombre maximal de caractères" color="red-500" position="left" size="sm" />
        )}
        {errors.name?.message !== undefined && isSubmitted ? (
          <Text content={errors.name.message} color="red-500" position="left" size="sm" />
        ) : undefined}
        {errors.type?.message !== undefined && isSubmitted ? (
          <Text content={errors.type.message} color="red-500" position="left" size="sm" />
        ) : undefined}
        {errors.isRequired?.message !== undefined && isSubmitted ? (
          <Text content={errors.isRequired.message} color="red-500" position="left" size="sm" />
        ) : undefined}
        {errors.defaultValueString?.message !== undefined && isSubmitted ? (
          <Text content={errors.defaultValueString.message} color="red-500" position="left" size="sm" />
        ) : undefined}
        {errors.defaultValueNumber?.message !== undefined && isSubmitted ? (
          <Text content={errors.defaultValueNumber.message} color="red-500" position="left" size="sm" />
        ) : undefined}
        {errors.defaultValueBoolean?.message !== undefined && isSubmitted ? (
          <Text content={errors.defaultValueBoolean.message} color="red-500" position="left" size="sm" />
        ) : undefined}
      </div>
      <div className="col-span-2 col-end-[16]">
        <Button
          type="button"
          content="Annuler"
          bgColor="white"
          textColor="black"
          borderWidth="xs"
          borderColor="grey-500"
          onClick={onCancelForm}
        />
      </div>
      <div className="col-span-2 col-end-[18]">
        {mutateStatus === 'loading' && <Button iconName="spinCircle" type="button" iconAnimation="spin" />}
        {mutateStatus !== 'loading' && (
          <Button
            content="Valider"
            type="submit"
            disabled={!isFormValid || errors.isRequired != null || errors.name != null || errors.type != null}
          />
        )}
      </div>
    </form>
  );
}
