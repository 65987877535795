import { GetPageRevisionDto, type GetUiComponentDto, type GetUiComponentLightInfoDto } from 'api';

import { type UiComponentCardType } from 'components/specks/UiComponentCard/UiComponentCard.props';

import { type ConstantLogical, type TargetType, type YesLogical } from 'models/ui-component-revisions.entity';
import { UiComponent } from 'models/uiComponent.entity';

export function fromGetUiComponentDto(getUiComponentDto: GetUiComponentDto): UiComponent {
  const uiComponent: UiComponent = new UiComponent(
    getUiComponentDto.type as UiComponentCardType,
    getUiComponentDto.uiComponentRevisions[0].name,
    getUiComponentDto.uiComponentRevisions[0].notes,
    getUiComponentDto.uiComponentRevisions[0].usedFor,
    getUiComponentDto.uiComponentRevisions[0].label,
    getUiComponentDto.id,
    getUiComponentDto.createdAt,
    getUiComponentDto.uiComponentRevisions[0].targetType as TargetType,
    getUiComponentDto.uiComponentRevisions[0].targetFree,
    getUiComponentDto.uiComponentRevisions[0].targetMethodId,
    getUiComponentDto.uiComponentRevisions[0].contentType as ConstantLogical,
    getUiComponentDto.uiComponentRevisions[0].sourceConstant,
    getUiComponentDto.uiComponentRevisions[0].sourceLogicalRule,
    getUiComponentDto.uiComponentRevisions[0].sourceMethodId,
    getUiComponentDto.uiComponentRevisions[0].isVisible as YesLogical,
    getUiComponentDto.uiComponentRevisions[0].isVisibleLogicalRule,
    getUiComponentDto.uiComponentRevisions[0].titleImportancy,
    getUiComponentDto.uiComponentRevisions[0].isEditable,
    getUiComponentDto.uiComponentRevisions[0].isEditableLogicalRule,
    getUiComponentDto.uiComponentRevisions[0].isPreFilled,
    getUiComponentDto.uiComponentRevisions[0].isPreFilledConstant,
    getUiComponentDto.uiComponentRevisions[0].isPreFilledLogicalRule,
    getUiComponentDto.uiComponentRevisions[0].hasPlaceholder,
    getUiComponentDto.uiComponentRevisions[0].hasPlaceholderConstant,
    getUiComponentDto.uiComponentRevisions[0].hasPlaceholderLogicalRule,
    getUiComponentDto.uiComponentRevisions[0].isMandatory,
    getUiComponentDto.uiComponentRevisions[0].isMandatoryLogicalRule,
    getUiComponentDto.uiComponentRevisions[0].hasPattern,
    getUiComponentDto.uiComponentRevisions[0].hasPatternConstant,
    getUiComponentDto.uiComponentRevisions[0].hasPatternLogicalRule,
    getUiComponentDto.uiComponentRevisions[0].isMultiChoiceAllowed,
    getUiComponentDto.uiComponentRevisions[0].isMultiChoiceAllowedLogicalRule,
    getUiComponentDto.uiComponentRevisions[0].optionsSource,
    getUiComponentDto.uiComponentRevisions[0].options,
    getUiComponentDto.uiComponentRevisions[0].parent != null
      ? getUiComponentDto.uiComponentRevisions[0].parent
      : undefined,
    getUiComponentDto.uiComponentRevisions[0].index,
    getUiComponentDto.uiComponentRevisions[0].dateFormat,
    getUiComponentDto.uiComponentRevisions[0].dateCustom,
    getUiComponentDto.uiComponentRevisions[0].textImportancy,
    getUiComponentDto.uiComponentRevisions[0].isFormValid,
  );

  return uiComponent;
}

// export function fromGetPageRevisionDto(getPageRevisionDto: GetPageRevisionDto): UiComponent {
//   const uiComponent: UiComponent = new UiComponent(
//     getPageRevisionDto.type as UiComponentCardType,
//     getPageRevisionDto.uiComponentRevisions[0].name,
//     getPageRevisionDto.uiComponentRevisions[0].notes,
//     getPageRevisionDto.uiComponentRevisions[0].usedFor,
//     getPageRevisionDto.uiComponentRevisions[0].label,
//     getPageRevisionDto.id,
//     getPageRevisionDto.createdAt,
//     getPageRevisionDto.uiComponentRevisions[0].targetType as TargetType,
//     getPageRevisionDto.uiComponentRevisions[0].targetFree,
//     getPageRevisionDto.uiComponentRevisions[0].targetMethodId,
//     getPageRevisionDto.uiComponentRevisions[0].contentType as ConstantLogical,
//     getPageRevisionDto.uiComponentRevisions[0].sourceConstant,
//     getPageRevisionDto.uiComponentRevisions[0].sourceLogicalRule,
//     getPageRevisionDto.uiComponentRevisions[0].sourceMethodId,
//     getPageRevisionDto.uiComponentRevisions[0].isVisible as YesLogical,
//     getPageRevisionDto.uiComponentRevisions[0].isVisibleLogicalRule,
//     getPageRevisionDto.uiComponentRevisions[0].titleImportancy,
//     getPageRevisionDto.uiComponentRevisions[0].isEditable,
//     getPageRevisionDto.uiComponentRevisions[0].isEditableLogicalRule,
//     getPageRevisionDto.uiComponentRevisions[0].isPreFilled,
//     getPageRevisionDto.uiComponentRevisions[0].isPreFilledConstant,
//     getPageRevisionDto.uiComponentRevisions[0].isPreFilledLogicalRule,
//     getPageRevisionDto.uiComponentRevisions[0].hasPlaceholder,
//     getPageRevisionDto.uiComponentRevisions[0].hasPlaceholderConstant,
//     getPageRevisionDto.uiComponentRevisions[0].hasPlaceholderLogicalRule,
//     getPageRevisionDto.uiComponentRevisions[0].isMandatory,
//     getPageRevisionDto.uiComponentRevisions[0].isMandatoryLogicalRule,
//     getPageRevisionDto.uiComponentRevisions[0].hasPattern,
//     getPageRevisionDto.uiComponentRevisions[0].hasPatternConstant,
//     getPageRevisionDto.uiComponentRevisions[0].hasPatternLogicalRule,
//     getPageRevisionDto.uiComponentRevisions[0].isMultiChoiceAllowed,
//     getPageRevisionDto.uiComponentRevisions[0].isMultiChoiceAllowedLogicalRule,
//     getPageRevisionDto.uiComponentRevisions[0].optionsSource,
//     getPageRevisionDto.uiComponentRevisions[0].options,
//     getPageRevisionDto.uiComponentRevisions[0].parent != null
//       ? getPageRevisionDto.uiComponentRevisions[0].parent
//       : undefined,
//     getPageRevisionDto.uiComponentRevisions[0].index,
//   );

//   return uiComponent;
// }

export function fromGetUiComponentLightInfoDto(getUiComponentLightInfoDto: GetUiComponentLightInfoDto): UiComponent {
  const uiComponent: UiComponent = new UiComponent();

  uiComponent.id = getUiComponentLightInfoDto.id;
  uiComponent.type = getUiComponentLightInfoDto.type;

  return uiComponent;
}
