/* tslint:disable */
/* eslint-disable */
/**
 * Specks API
 * APIs for specks SaaS
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { GetNodeIdDto } from './GetNodeIdDto';
import {
    GetNodeIdDtoFromJSON,
    GetNodeIdDtoFromJSONTyped,
    GetNodeIdDtoToJSON,
} from './GetNodeIdDto';

/**
 * 
 * @export
 * @interface GetEdgeIdsDto
 */
export interface GetEdgeIdsDto {
    /**
     * 
     * @type {string}
     * @memberof GetEdgeIdsDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof GetEdgeIdsDto
     */
    sourceHandleId: string;
    /**
     * 
     * @type {string}
     * @memberof GetEdgeIdsDto
     */
    targetHandleId: string;
    /**
     * 
     * @type {GetNodeIdDto}
     * @memberof GetEdgeIdsDto
     */
    sourceNodeId: GetNodeIdDto;
    /**
     * 
     * @type {GetNodeIdDto}
     * @memberof GetEdgeIdsDto
     */
    targetNodeId: GetNodeIdDto;
}

/**
 * Check if a given object implements the GetEdgeIdsDto interface.
 */
export function instanceOfGetEdgeIdsDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "sourceHandleId" in value;
    isInstance = isInstance && "targetHandleId" in value;
    isInstance = isInstance && "sourceNodeId" in value;
    isInstance = isInstance && "targetNodeId" in value;

    return isInstance;
}

export function GetEdgeIdsDtoFromJSON(json: any): GetEdgeIdsDto {
    return GetEdgeIdsDtoFromJSONTyped(json, false);
}

export function GetEdgeIdsDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetEdgeIdsDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'sourceHandleId': json['sourceHandleId'],
        'targetHandleId': json['targetHandleId'],
        'sourceNodeId': GetNodeIdDtoFromJSON(json['sourceNodeId']),
        'targetNodeId': GetNodeIdDtoFromJSON(json['targetNodeId']),
    };
}

export function GetEdgeIdsDtoToJSON(value?: GetEdgeIdsDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'sourceHandleId': value.sourceHandleId,
        'targetHandleId': value.targetHandleId,
        'sourceNodeId': GetNodeIdDtoToJSON(value.sourceNodeId),
        'targetNodeId': GetNodeIdDtoToJSON(value.targetNodeId),
    };
}

