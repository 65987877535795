/* tslint:disable */
/* eslint-disable */
/**
 * Specks API
 * APIs for specks SaaS
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  CreateDictionaryEntryDto,
  ErrorBadRequest,
  GetDictionaryDto,
  GetDictionaryEntryDto,
} from '../models';
import {
    CreateDictionaryEntryDtoFromJSON,
    CreateDictionaryEntryDtoToJSON,
    ErrorBadRequestFromJSON,
    ErrorBadRequestToJSON,
    GetDictionaryDtoFromJSON,
    GetDictionaryDtoToJSON,
    GetDictionaryEntryDtoFromJSON,
    GetDictionaryEntryDtoToJSON,
} from '../models';

export interface DictionaryControllerCreateEntryRequest {
    id: string;
    createDictionaryEntryDto: CreateDictionaryEntryDto;
}

export interface DictionaryControllerFindByIdRequest {
    id: string;
}

/**
 * 
 */
export class DictionariesApi extends runtime.BaseAPI {

    /**
     */
    async dictionaryControllerCreateEntryRaw(requestParameters: DictionaryControllerCreateEntryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetDictionaryEntryDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling dictionaryControllerCreateEntry.');
        }

        if (requestParameters.createDictionaryEntryDto === null || requestParameters.createDictionaryEntryDto === undefined) {
            throw new runtime.RequiredError('createDictionaryEntryDto','Required parameter requestParameters.createDictionaryEntryDto was null or undefined when calling dictionaryControllerCreateEntry.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/dictionaries/{id}/dictionary-entries`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateDictionaryEntryDtoToJSON(requestParameters.createDictionaryEntryDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetDictionaryEntryDtoFromJSON(jsonValue));
    }

    /**
     */
    async dictionaryControllerCreateEntry(requestParameters: DictionaryControllerCreateEntryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetDictionaryEntryDto> {
        const response = await this.dictionaryControllerCreateEntryRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async dictionaryControllerFindByIdRaw(requestParameters: DictionaryControllerFindByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetDictionaryDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling dictionaryControllerFindById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/dictionaries/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetDictionaryDtoFromJSON(jsonValue));
    }

    /**
     */
    async dictionaryControllerFindById(requestParameters: DictionaryControllerFindByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetDictionaryDto> {
        const response = await this.dictionaryControllerFindByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
