/* tslint:disable */
/* eslint-disable */
/**
 * Specks API
 * APIs for specks SaaS
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CreateEdgeDto } from './CreateEdgeDto';
import {
    CreateEdgeDtoFromJSON,
    CreateEdgeDtoFromJSONTyped,
    CreateEdgeDtoToJSON,
} from './CreateEdgeDto';
import type { CreateNodeDto } from './CreateNodeDto';
import {
    CreateNodeDtoFromJSON,
    CreateNodeDtoFromJSONTyped,
    CreateNodeDtoToJSON,
} from './CreateNodeDto';

/**
 * 
 * @export
 * @interface CreateAppSchemaRevisionDto
 */
export interface CreateAppSchemaRevisionDto {
    /**
     * 
     * @type {Array<CreateNodeDto>}
     * @memberof CreateAppSchemaRevisionDto
     */
    nodes: Array<CreateNodeDto>;
    /**
     * 
     * @type {Array<CreateEdgeDto>}
     * @memberof CreateAppSchemaRevisionDto
     */
    edges: Array<CreateEdgeDto>;
}

/**
 * Check if a given object implements the CreateAppSchemaRevisionDto interface.
 */
export function instanceOfCreateAppSchemaRevisionDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "nodes" in value;
    isInstance = isInstance && "edges" in value;

    return isInstance;
}

export function CreateAppSchemaRevisionDtoFromJSON(json: any): CreateAppSchemaRevisionDto {
    return CreateAppSchemaRevisionDtoFromJSONTyped(json, false);
}

export function CreateAppSchemaRevisionDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateAppSchemaRevisionDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'nodes': ((json['nodes'] as Array<any>).map(CreateNodeDtoFromJSON)),
        'edges': ((json['edges'] as Array<any>).map(CreateEdgeDtoFromJSON)),
    };
}

export function CreateAppSchemaRevisionDtoToJSON(value?: CreateAppSchemaRevisionDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'nodes': ((value.nodes as Array<any>).map(CreateNodeDtoToJSON)),
        'edges': ((value.edges as Array<any>).map(CreateEdgeDtoToJSON)),
    };
}

