/* tslint:disable */
/* eslint-disable */
/**
 * Specks API
 * APIs for specks SaaS
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Edge } from './Edge';
import {
    EdgeFromJSON,
    EdgeFromJSONTyped,
    EdgeToJSON,
} from './Edge';
import type { Node } from './Node';
import {
    NodeFromJSON,
    NodeFromJSONTyped,
    NodeToJSON,
} from './Node';
import type { Product } from './Product';
import {
    ProductFromJSON,
    ProductFromJSONTyped,
    ProductToJSON,
} from './Product';

/**
 * 
 * @export
 * @interface AppSchemaRevision
 */
export interface AppSchemaRevision {
    /**
     * 
     * @type {string}
     * @memberof AppSchemaRevision
     */
    id: string;
    /**
     * 
     * @type {boolean}
     * @memberof AppSchemaRevision
     */
    latest: boolean;
    /**
     * 
     * @type {Date}
     * @memberof AppSchemaRevision
     */
    createdAt: Date;
    /**
     * 
     * @type {Product}
     * @memberof AppSchemaRevision
     */
    product: Product;
    /**
     * 
     * @type {Array<Node>}
     * @memberof AppSchemaRevision
     */
    nodes: Array<Node>;
    /**
     * 
     * @type {Array<Edge>}
     * @memberof AppSchemaRevision
     */
    edges: Array<Edge>;
}

/**
 * Check if a given object implements the AppSchemaRevision interface.
 */
export function instanceOfAppSchemaRevision(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "latest" in value;
    isInstance = isInstance && "createdAt" in value;
    isInstance = isInstance && "product" in value;
    isInstance = isInstance && "nodes" in value;
    isInstance = isInstance && "edges" in value;

    return isInstance;
}

export function AppSchemaRevisionFromJSON(json: any): AppSchemaRevision {
    return AppSchemaRevisionFromJSONTyped(json, false);
}

export function AppSchemaRevisionFromJSONTyped(json: any, ignoreDiscriminator: boolean): AppSchemaRevision {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'latest': json['latest'],
        'createdAt': (new Date(json['createdAt'])),
        'product': ProductFromJSON(json['product']),
        'nodes': ((json['nodes'] as Array<any>).map(NodeFromJSON)),
        'edges': ((json['edges'] as Array<any>).map(EdgeFromJSON)),
    };
}

export function AppSchemaRevisionToJSON(value?: AppSchemaRevision | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'latest': value.latest,
        'createdAt': (value.createdAt.toISOString()),
        'product': ProductToJSON(value.product),
        'nodes': ((value.nodes as Array<any>).map(NodeToJSON)),
        'edges': ((value.edges as Array<any>).map(EdgeToJSON)),
    };
}

