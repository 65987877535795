import { type BodyDto } from 'api';

import { type Body } from 'models/API/Body.entity';

export function toBodyDto(bodies: Body[]): BodyDto[] {
  return bodies.map((child) => ({
    id: child.id,
    name: child.name,
    isMandatory: child.isMandatory,
    defaultValue: child.defaultValue,
    valueType: child.valueType,
    enumDto: {
      id: child.enum?.id ?? '',
      valueType: child.enum?.valueType ?? 'STRING',
      values:
        child.enum?.values.map((value) => ({
          id: value.id,
          name: value.name,
          description: value.description,
        })) ?? [],
    },
    children: toBodyDto(child.children),
  }));
}
