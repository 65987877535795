import { type CreateAssetDto, GenesesApi, type GetAssetDto, type GetAssetsDto, type GetGenesisDto } from 'api';

import { type Asset } from 'models/asset.entity';
import { Genesis } from 'models/genesis.entity';

export class GenesisService {
  private readonly genesisClient: GenesesApi;

  constructor() {
    this.genesisClient = new GenesesApi();
  }

  public async findById(id: string, accessToken: string): Promise<Genesis> {
    const res: GetGenesisDto = await this.genesisClient.genesisControllerFindById(
      {
        id,
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
      },
    );
    const genesis: Genesis = new Genesis();
    genesis.id = res.id;
    genesis.assets = res.assets;

    return genesis;
  }

  /* ------------------------------------------------- asset ------------------------------------------------ */

  public async createAsset(genesisId: string, asset: Asset, accessToken: string): Promise<GetAssetDto> {
    return await this.genesisClient.genesisControllerCreateAsset(
      {
        id: genesisId,
        createAssetDto: asset as CreateAssetDto,
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
      },
    );
  }

  public async getGenesisAssets(genesisId: string, accessToken: string): Promise<Asset[]> {
    const res: GetAssetsDto = await this.genesisClient.genesisControllerFindGenesisAssetsById(
      { id: genesisId },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
      },
    );
    return res.assets;
  }
}
