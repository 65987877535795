import React from 'react';

import { type ChipPaddingHorizontal } from 'components/core/Chip/Chip';
import { type TextSize } from 'components/core/Text/Text';
import { DecommissionedStatusChip } from 'components/specks/Chips/status/DecommissionedStatusChip/DecommissionedStatusChip';
import { InDevelopmentStatusChip } from 'components/specks/Chips/status/InDevelopmentStatusChip/InDevelopmentStatusChip';
import { ProductionStatusChip } from 'components/specks/Chips/status/ProductionStatusChip/ProductionStatusChip';

import { Status } from 'models/Status.types';

import { type IconSize } from 'icons/Icon.props';

interface StatusChipProps {
  name: Status;
  iconSize?: IconSize;
  textSize?: TextSize;
  paddingHorizontal?: ChipPaddingHorizontal;
}

export default function getStatusChip({
  name,
  iconSize = 'xs',
  textSize = 'base',
  paddingHorizontal = 'sm',
}: StatusChipProps): JSX.Element {
  switch (name) {
    case Status.Indevelopment:
      return <InDevelopmentStatusChip iconSize={iconSize} textSize={textSize} paddingHorizontal={paddingHorizontal} />;
    case Status.Production:
      return <ProductionStatusChip iconSize={iconSize} textSize={textSize} paddingHorizontal={paddingHorizontal} />;
    case Status.Decommissioned:
      return <DecommissionedStatusChip iconSize={iconSize} textSize={textSize} paddingHorizontal={paddingHorizontal} />;
    default:
      return <div>error</div>;
  }
}
