import {
  type CreateExternalLinkDto,
  FrontSpecificationsApi,
  type GetExternalLinkDto,
  type GetPageRevisionsInfoDto,
  type GetPagesDto,
} from 'api';
import { fromGetPageRevisionsInfoDto } from 'factory/PageFactory';

import { type ExternalLink } from 'models/externalLink.entity';
import { type Page } from 'models/page.entity';

export class FrontSpecificationService {
  private readonly frontSpecificationClient: FrontSpecificationsApi;

  constructor() {
    this.frontSpecificationClient = new FrontSpecificationsApi();
  }

  /* ------------------------------------------------- externalLinks ------------------------------------------------ */

  public async createExternalLink(
    frontSpecificationId: string,
    externalLink: ExternalLink,
    accessToken: string,
  ): Promise<GetExternalLinkDto> {
    return await this.frontSpecificationClient.frontSpecificationControllerCreateExternalLink(
      {
        id: frontSpecificationId,
        createExternalLinkDto: externalLink as CreateExternalLinkDto,
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
      },
    );
  }

  /* ----------------------------------------------------- pages ---------------------------------------------------- */

  public async createPage(frontSpecificationId: string, page: Page, accessToken: string): Promise<Page> {
    const getPageDto: GetPageRevisionsInfoDto =
      await this.frontSpecificationClient.frontSpecificationControllerCreatePage(
        {
          id: frontSpecificationId,
          createPageDto: {
            name: page.pageRevisions[0].name,
          },
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
          },
        },
      );
    return fromGetPageRevisionsInfoDto(getPageDto);
  }

  public async getPages(frontSpecificationId: string, accessToken: string): Promise<Page[]> {
    const getPagesDto: GetPagesDto =
      await this.frontSpecificationClient.frontSpecificationControllerFindFrontSpecificationPagesById(
        {
          id: frontSpecificationId,
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
          },
        },
      );
    return getPagesDto.pages.map((getPageDto: GetPageRevisionsInfoDto) => {
      return fromGetPageRevisionsInfoDto(getPageDto);
    });
  }
}
