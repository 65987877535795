import React from 'react';

import { Listbox } from '@headlessui/react';
import { Tooltip } from '@mui/material';

import { PelletInformation } from 'components/specks/Pellet/Pellet';

import concatClassNames from 'utils/classNames';
import getIcon from 'utils/getIcon';

import CheckIcon from 'icons/CheckIcon';
import ChevronDownIcon from 'icons/ChevronDownIcon';
import ChevronRightIcon from 'icons/ChevronRightIcon';

import { Text, type TextSize, type TextWeight } from '../Text/Text';

type ListBoxWidth = 'fit' | 'none' | 'full';

const listBoxWidthStyle: Record<ListBoxWidth, string> = {
  fit: 'w-fit',
  none: '',
  full: 'w-full',
};

interface ListBoxProps {
  label?: string;
  labelSize?: TextSize;
  information?: string;
  description?: string;
  descriptionSize?: TextSize;
  descriptionWeight?: TextWeight;
  selected: string;
  options: string[];
  styleOptions?: Map<string, JSX.Element>;
  selectedStyleOptions?: Map<string, JSX.Element>;
  minimalist?: boolean;
  itemsBorder?: boolean;
  onChange: React.Dispatch<React.SetStateAction<string>>;
  readonly?: boolean;
  width?: ListBoxWidth;
  isComplete?: boolean;
}

export function ListBox({
  label,
  labelSize = 'base',
  information,
  description,
  descriptionSize = 'sm',
  descriptionWeight = 'normal',
  selected,
  options,
  styleOptions,
  selectedStyleOptions,
  minimalist = false,
  itemsBorder = false,
  onChange,
  readonly = false,
  width = 'none',
  isComplete,
}: ListBoxProps): JSX.Element {
  function handleChange(e: string): void {
    onChange(e);
  }
  return (
    <div className={concatClassNames('flex flex-col gap-3', listBoxWidthStyle[width])}>
      {label !== undefined && information !== undefined && (
        <div className={concatClassNames('flex flex-row', 'gap-2', 'items-center')}>
          {label !== undefined && (
            <Text position="left" size={labelSize} color="gray-700" weight="medium" content={label} />
          )}
          <Tooltip title={information} placement="right">
            <div>{information !== undefined && <>{getIcon('information', 'gradient2-to', 'smd')}</>}</div>
          </Tooltip>
          {/* {isComplete !== undefined && !isComplete && <PelletInformation text={error} />} */}
        </div>
      )}
      {description !== undefined && (
        <Text
          content={description}
          color="gray-700"
          position="left"
          weight={descriptionWeight}
          size={descriptionSize}
        />
      )}
      <Listbox
        value={selected}
        onChange={(event) => {
          if (readonly) return;
          handleChange(event);
        }}
        disabled={readonly}
      >
        {({ open }) => {
          return (
            <>
              <div className={concatClassNames('relative flex flex-col gap-0')}>
                <Listbox.Button
                  className={concatClassNames(
                    'flex flex-row',
                    'items-center',
                    minimalist ? '' : 'justify-between',
                    minimalist ? 'w-fit' : '',
                    'h-12',
                    minimalist ? 'bg-transparent' : 'bg-gray-25',
                    minimalist ? '' : 'rounded-lg border-gray-50 border',
                    minimalist ? '' : 'px-2.5',
                    isComplete !== undefined && !isComplete ? 'border-orange-500' : 'border-gray-50',
                  )}
                >
                  <div className="flex items-center">
                    {selectedStyleOptions !== undefined
                      ? selectedStyleOptions.get(selected)
                      : styleOptions !== undefined
                      ? styleOptions.get(selected)
                      : selected}
                  </div>
                  <div className={concatClassNames('flex items-center', minimalist ? 'px-1' : 'px-3.5 py-1')}>
                    {!readonly &&
                      (open ? (
                        <ChevronDownIcon color={minimalist ? 'purple' : 'gray'} size={minimalist ? 'sm' : 'md'} />
                      ) : (
                        <ChevronRightIcon color={minimalist ? 'purple' : 'gray'} size={minimalist ? 'sm' : 'md'} />
                      ))}
                  </div>
                </Listbox.Button>
                {open && !readonly && (
                  <Listbox.Options
                    className={concatClassNames(
                      'absolute top-12',
                      'w-full',
                      'h-fit max-h-52',
                      'z-10',
                      'overflow-auto',
                      'rounded-md',
                      'bg-white py-1',
                      'text-base text-start',
                      'shadow-lg',
                      'ring-1 ring-black ring-opacity-5',
                      itemsBorder ? 'border-1 border-gray-50' : '',
                    )}
                  >
                    {options.map((value, index) => (
                      <Listbox.Option
                        key={index}
                        className={({ active }) =>
                          `flex flex-row justify-between
                          px-3.5 py-2.5
                          ${active ? 'bg-branding-25' : ''} `
                        }
                        value={value}
                      >
                        <span>{styleOptions !== undefined ? styleOptions.get(value) : value}</span>
                        {selected === value && (
                          <span className="self-center">
                            <CheckIcon color="gray" size="sm" />
                          </span>
                        )}
                      </Listbox.Option>
                    ))}
                  </Listbox.Options>
                )}
              </div>
            </>
          );
        }}
      </Listbox>
    </div>
  );
}
