/* eslint-disable max-len */
import React from 'react';

import concatClassNames from 'utils/classNames';

import { type IconProps, iconColorStyle, iconSizeStyle } from './Icon.props';

export default function SchemaIcon({ color, size }: IconProps): JSX.Element {
  return (
    <svg
      id="Layer_3"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 128 128"
      className={concatClassNames(iconSizeStyle[size], iconColorStyle[color])}
    >
      <path
        className="cls-1"
        d="m77.04,57.52c-.36,22.77-28.58,22.13-30.32,5-1.4-13.8,14.5-18.81,20.29-15.56,0,0-2.06-4.36-8.32-2.97-6.26,1.39-13.83,3.95-16.51,10.61-2.68,6.66-1.07,12.7,0,16.3,1.07,3.6,5.07,7.81,9.18,9.74,4.11,1.93,11.09,2.73,15.3.74s8.49-5.76,10.25-9.1,2.68-8.24,2.23-10.88-2.1-3.86-2.1-3.86Z"
      />
      <path
        className="cls-1"
        d="m26.69,43.16c-4.32,2.57-7.64,1.2-8.5-2.27s2.87-5.97,5.71-5.37c0,0-5.1-3.25-8.26.37-2.2,2.52-1.25,6.27-.7,7.27s3.66,4.49,7.13,3.61c3.46-.88,4.63-3.61,4.63-3.61Z"
      />
      <path
        className="cls-1"
        d="m56.74,23.44c2.45,1.07,6.41,1.4,7.85-2.35,1.38-3.57-.64-5.87-3.54-7.7,0,0,3.51-1.05,5.18.78,1.67,1.83,1.91,3.44,2.14,5.41.23,1.97-.85,4.49-2.08,5.63-1.23,1.13-6.13,1.21-7.61.66s-1.94-2.44-1.94-2.44Z"
      />
      <path
        className="cls-1"
        d="m104.35,42.84c-.22-2.96-2.26-5.17-5.73-4.61-2.99.48-4.9,4.49-3.21,7.2,0,0-2.33-.92-2.49-3.73s.11-5.28,3.77-6.77c3.67-1.49,6.6.2,7.35.88s1.82,4.66,1.82,4.66l-1.51,2.36Z"
      />
      <path
        className="cls-1"
        d="m103.22,85.62c-2.56,5.1-5.92,4.6-7.51,3.93-2.36-.99-4.34-3.72-2.4-7.55,0,0-3.32,1.99-3.11,5.92.2,3.93,3.87,5.23,5.34,5.44s4.77-.58,5.83-2.02c1.06-1.44,1.85-5.71,1.85-5.71Z"
      />
      <path
        className="cls-1"
        d="m65.41,111.83c-3.97,1.43-7.02-.12-7.96-2.81-1.07-3.06.82-6.17,3.27-7.04,0,0-3.78-1.02-6.68,2.96-2.91,3.98-.24,7.27.55,8.01s2.3,2.31,4.37,2.38,4.88-.45,5.21-1.13,1.25-2.37,1.25-2.37Z"
      />
      <path
        className="cls-1"
        d="m26.65,89.44c.77-3.1-1.53-6.93-5.6-6.42-2.54.32-5.57,2.75-5.01,6.93,0,0-2.51-2.5-1.54-6.17s4.9-4.91,7.09-4.19c2.19.72,4.52.83,5.15,2.15.63,1.31,1.21,5.36,1.21,5.36l-1.32,2.35Z"
      />
      <path
        className="cls-1"
        d="m59.37,37.72c.13-.95.23-1.92.29-2.9.02-.36-.05-.71-.02-1.07.08-1.27.21-2.55.34-3.83.05-.57.02-1.14,0-1.7-.02-.36-.01-.72.01-1.08.01-.18.04-.36.07-.54.02-.18.18-.37.26-.55.14-.23.24-.2.53-.02.22.13.43.26.61.36.21.15.35.33.42.53.11.36.25.71.34,1.07.06.36.12.72.16,1.07.08.72.12,1.44.06,2.18-.12.89.1,1.79.14,2.7.04,1,.04,2.01.02,3.02,0,.62-.05,1.25-.09,1.87-.06.94-.18,1.89-.19,2.82,0,.73.13,1.45.15,2.18,0,.16.03.32.02.48-.03.3-.1.49-.3.6-.14.05-.27.09-.42.12-.11.03-.25.05-.4.06-.14.01-.27.03-.4.03-.21,0-.4-.07-.59-.2-.13-.09-.26-.17-.37-.24-.18-.1-.17-.19-.23-.3-.24-.64-.4-1.28-.51-1.92-.09-.63-.06-1.26-.07-1.9.03-.95.06-1.9.09-2.85h.08Z"
      />
      <path
        className="cls-1"
        d="m62.44,90.37c-.13.95-.25,1.91-.32,2.88-.03.35.04.7,0,1.06-.1,1.25-.24,2.53-.38,3.8-.05.56-.03,1.13,0,1.68.02.36.01.72-.01,1.07-.01.18-.04.36-.07.54-.02.18-.18.37-.26.55-.14.23-.24.2-.53.03-.22-.13-.44-.26-.61-.36-.21-.15-.35-.33-.43-.53-.11-.35-.25-.71-.34-1.06-.06-.36-.11-.71-.16-1.07-.08-.72-.11-1.44-.04-2.18.13-.88-.08-1.78-.11-2.69-.03-.99-.01-1.99.02-3,0-.62.07-1.24.1-1.85.06-.94.18-1.87.19-2.8-.01-.72-.14-1.44-.16-2.16,0-.16-.03-.32-.02-.47.03-.29.09-.48.29-.6.14-.05.27-.09.42-.12.11-.03.25-.05.4-.07.14-.01.27-.04.39-.04.21,0,.4.06.59.19.13.09.26.16.38.24.18.1.17.19.24.3.25.64.41,1.28.53,1.91.09.63.07,1.27.07,1.9-.03.94-.07,1.89-.1,2.84h-.08Z"
      />
      <path
        className="cls-1"
        d="m81.69,48.83c1.02-.44,2.05-.93,3.06-1.44.37-.19.69-.46,1.06-.64,1.31-.66,2.66-1.3,4.02-1.92.59-.29,1.16-.63,1.71-.97.36-.22.73-.41,1.11-.6.19-.09.39-.17.59-.24.19-.08.46-.04.69-.07.3,0,.32.11.27.46-.03.26-.07.52-.09.74-.06.27-.18.49-.35.66-.31.3-.6.61-.92.89-.33.25-.66.51-1.01.75-.68.48-1.39.93-2.16,1.3-.95.41-1.75,1.12-2.64,1.69-.98.61-1.99,1.19-3,1.77-.63.35-1.27.68-1.92,1-.97.5-2,.92-2.96,1.44-.75.4-1.45.91-2.23,1.28-.17.08-.33.2-.51.26-.34.11-.58.14-.79.01-.12-.11-.21-.21-.31-.33-.08-.09-.16-.21-.23-.34-.07-.12-.15-.23-.2-.34-.09-.19-.09-.39-.04-.63.03-.1.06-.27.1-.45.03-.21.13-.24.21-.35.55-.5,1.12-.96,1.7-1.37.6-.39,1.23-.72,1.87-1.06.97-.52,1.94-1.04,2.9-1.56l.04.07Z"
      />
      <path
        className="cls-1"
        d="m81.46,77.78c.89.59,1.78,1.18,2.69,1.75.33.21.7.33,1.02.54,1.16.74,2.29,1.53,3.43,2.31.51.33,1.05.6,1.59.86.35.17.69.35,1.02.55.17.1.33.21.48.33.16.1.27.34.4.5.15.24.08.32-.22.49-.22.13-.45.26-.63.36-.24.11-.48.14-.71.11-.4-.08-.8-.14-1.19-.24-.37-.13-.74-.27-1.11-.42-.73-.3-1.43-.65-2.1-1.1-.78-.57-1.73-.86-2.6-1.3-.95-.48-1.89-1-2.83-1.51l-1.72-.96c-.87-.48-1.73-.99-2.64-1.38-.71-.29-1.47-.44-2.19-.69-.16-.05-.33-.08-.48-.15-.28-.13-.45-.26-.5-.49,0-.16,0-.29.01-.44,0-.12.03-.26.06-.41.03-.14.05-.27.08-.39.06-.2.19-.36.38-.5.13-.1.25-.18.36-.27.16-.13.25-.09.39-.11.75,0,1.47.1,2.17.26.69.18,1.35.47,2,.76.97.44,1.92.94,2.86,1.44l-.03.07Z"
      />
      <path
        className="cls-1"
        d="m36.81,50.19c-.87-.51-1.75-1.02-2.65-1.49-.33-.17-.69-.26-1.02-.44-1.16-.62-2.32-1.29-3.47-1.95-.52-.29-1.06-.5-1.6-.72-.35-.14-.69-.29-1.02-.47-.17-.09-.33-.19-.49-.29-.16-.09-.27-.32-.41-.47-.16-.23-.09-.31.19-.5.21-.14.42-.29.59-.4.23-.13.46-.18.68-.16.38.05.78.07,1.15.14.36.1.73.2,1.09.31.72.23,1.42.51,2.09.88.79.49,1.73.68,2.6,1.03.95.39,1.9.82,2.85,1.25.58.26,1.15.56,1.73.84.87.43,1.72.91,2.61,1.28.7.27,1.44.43,2.14.69.16.05.32.09.47.16.27.14.43.27.47.5,0,.15,0,.29-.04.44-.01.12-.04.25-.08.4-.04.13-.06.27-.11.38-.07.2-.2.35-.4.48-.13.09-.25.18-.37.26-.16.13-.25.08-.38.1-.71-.02-1.39-.11-2.05-.26-.65-.16-1.26-.44-1.88-.69-.91-.39-1.82-.82-2.73-1.24l.03-.08Z"
      />
      <path
        className="cls-1"
        d="m38.86,80.17c-.93.41-1.88.84-2.82,1.29-.34.16-.64.4-.99.56-1.23.56-2.52,1.04-3.8,1.48-.57.19-1.1.47-1.64.71-.34.16-.69.31-1.06.42-.18.06-.37.11-.56.15-.18.05-.42-.03-.63-.03-.27-.05-.28-.15-.21-.49.05-.25.09-.5.13-.71.07-.26.19-.45.36-.6.3-.24.58-.51.88-.73l.95-.57c.64-.36,1.3-.71,2-.97.87-.29,1.6-.89,2.39-1.36l2.68-1.53c.55-.32,1.13-.61,1.7-.91.86-.46,1.76-.87,2.6-1.38.64-.4,1.22-.9,1.87-1.31.14-.09.27-.2.42-.27.28-.13.49-.18.7-.07.12.1.22.19.32.3.09.08.17.19.26.31.08.11.17.21.23.32.11.18.14.38.13.61-.01.1-.02.27-.03.45,0,.21-.08.25-.15.36-.45.54-.93,1.03-1.44,1.46-.51.42-1.09.74-1.65,1.09-.85.5-1.73.98-2.61,1.49l-.04-.07Z"
      />
      <path
        className="cls-1"
        d="m65.59,16.09c-.57-.66-1.32-1.17-2.16-1.57-.86-.34-1.78-.5-2.7-.46-.33.05-.65.13-.97.21-.29.15-.6.26-.9.4-2.03,1.04-3.57,3.75-2.9,6.2.27,1.08.89,2.12,1.78,2.82.29.22.58.43.91.59.3.21.64.34.96.52l1.05.4c.36.17.73.33,1.14.45.51.2.47.25.02.56-.22.14-.56.45-1.01.64-.22.1-.48.24-.76.3-.27.04-.56.02-.85,0-.53-.05-1.03-.22-1.55-.38-.5-.2-.96-.48-1.39-.81-.87-.64-1.62-1.46-2.15-2.41-.55-.94-.86-2.02-.93-3.11-.07-.54,0-1.08.04-1.62.12-.52.19-1.06.41-1.54.2-.6.5-1.15.78-1.7.36-.5.66-1.05,1.08-1.52.45-.45.84-.96,1.4-1.29.27-.18.52-.38.8-.53l.88-.38c1.31-.46,2.72-.65,4.05-.4,1.33.21,2.62.67,3.7,1.45,1.36.92,2.45,2.25,3.05,3.81l.29.9c.09.3.09.62.14.93.12.62.03,1.24-.01,1.85-.11,1.23-.54,2.37-1.1,3.45-.89,1.65-2.37,3.15-4.29,3.79-.42.13-.83.28-1.27.32-1.62.06-2.08-.35-2.29-1.06-.05-.17-.11-.33-.13-.49-.05-.55.57-.89,1.4-1.17.62-.21,1.16-.43,1.65-.68.49-.25.88-.59,1.24-.96.36-.37.65-.81.91-1.3.27-.48.5-1.02.65-1.57.13-.41.12-.82.18-1.23.05-.21-.02-.4-.02-.6-.03-.19.02-.41-.07-.59-.14-.79-.57-1.5-1.11-2.16l.05-.05Z"
      />
      <path
        className="cls-1"
        d="m56.18,111.92c.57.65,1.33,1.17,2.16,1.56.86.33,1.78.5,2.7.45.33-.05.65-.13.97-.21.29-.15.6-.26.89-.4,2.03-1.04,3.55-3.76,2.88-6.22-.27-1.08-.9-2.12-1.79-2.81-.29-.22-.58-.43-.91-.58-.3-.21-.64-.33-.96-.51l-1.05-.4c-.36-.17-.74-.32-1.14-.45-.51-.2-.47-.24-.03-.56.22-.14.55-.45,1-.64.22-.1.48-.24.76-.31.27-.04.56-.03.85,0,.53.05,1.03.22,1.55.37.5.2.97.48,1.4.81.87.64,1.63,1.45,2.16,2.4.56.94.87,2.02.94,3.1.07.54,0,1.08-.03,1.62-.12.52-.19,1.06-.4,1.54-.2.6-.49,1.15-.77,1.7-.36.5-.66,1.05-1.07,1.53-.45.45-.84.96-1.39,1.29-.26.18-.52.38-.8.53l-.88.38c-1.31.46-2.71.66-4.05.42-1.33-.21-2.62-.66-3.71-1.44-1.36-.92-2.46-2.24-3.06-3.79l-.29-.9c-.09-.3-.09-.62-.14-.93-.12-.62-.04-1.24,0-1.85.11-1.23.53-2.37,1.08-3.45.88-1.65,2.35-3.16,4.28-3.81.42-.13.83-.29,1.26-.33,1.62-.07,2.08.34,2.29,1.05.05.17.11.33.13.49.05.55-.57.89-1.4,1.17-.62.21-1.16.43-1.65.68-.49.25-.88.6-1.24.97-.35.38-.65.82-.91,1.31-.27.48-.5,1.02-.64,1.58-.12.41-.11.82-.17,1.23-.05.21.02.4.02.6.03.19-.01.41.07.59.14.79.58,1.5,1.12,2.15l-.05.05Z"
      />
      <path
        className="cls-1"
        d="m16.58,38.1c-.36.67-.53,1.47-.61,2.27,0,.79.06,1.64.4,2.37.09.29.26.53.44.76.09.12.16.24.26.35.11.1.21.2.31.31.31.42.78.69,1.2,1,.23.1.45.25.69.33.25.06.49.17.75.21,1.04.2,2.2.03,3.26-.43.94-.42,1.81-1.07,2.44-1.87.18-.27.4-.52.52-.81.07-.14.17-.27.25-.4l.2-.42c.16-.28.19-.57.34-.86.11-.29.25-.62.29-.97.11-.46.15-.47.54-.14.19.17.54.42.78.84.12.2.32.46.36.72.04.26.06.54.04.83-.14.98-.46,1.97-1.07,2.69-.55.78-1.23,1.42-1.97,1.97-1.52,1.05-3.39,1.63-5.28,1.51-1.13-.12-2.27-.36-3.31-.88-.5-.27-.97-.59-1.43-.91-.4-.4-.86-.76-1.17-1.23-.76-.99-1.22-2.14-1.48-3.31-.19-1.18-.24-2.39.06-3.55.32-1.42,1-2.78,2.03-3.86.82-.76,1.78-1.44,2.87-1.72.27-.08.53-.18.8-.23l.82-.09c.55-.1,1.08-.04,1.61-.02l.62.03c.22.04.45.06.67.12.44.09.85.25,1.26.42.81.36,1.55.87,2.18,1.56.27.31.54.62.74,1,.33.73.35,1.3.25,1.68-.1.38-.34.59-.7.74-.16.06-.32.14-.48.16-.54.07-.95-.4-1.36-.86-.34-.34-.65-.64-1.02-.91-.36-.26-.77-.48-1.18-.61-.2-.07-.4-.12-.59-.15-.09-.02-.18-.01-.27-.03-.13,0-.26.01-.38,0-.25,0-.51-.05-.76-.05-.23.03-.47.05-.71.07-.19-.01-.32.09-.49.12-.15.05-.34.06-.48.14-.28.16-.61.25-.86.49-.57.37-.99.98-1.33,1.64l-.07-.03Z"
      />
      <path
        className="cls-1"
        d="m102.24,36.86c-1.25-.78-2.99-1.21-4.54-.75-.58.13-1.06.47-1.53.79l-.61.46-.52.58c-.33.4-.58.85-.76,1.33-.35.96-.37,2.09-.01,3.16.31.94.9,1.83,1.65,2.47.26.18.49.41.77.53.13.08.25.18.39.26l.4.21c.27.16.56.21.84.37.29.13.6.28.95.35.45.14.45.19.1.55-.18.17-.45.5-.86.72-.2.11-.46.29-.71.33-.25.03-.53.04-.81.01-.96-.17-1.93-.51-2.63-1.15-.39-.28-.69-.64-1.03-.96-.28-.37-.6-.72-.83-1.13-.95-1.59-1.38-3.58-.92-5.45.49-2.23,1.84-4.22,3.79-5.33,2.13-1.23,4.69-1.31,6.89-.55,1.36.49,2.6,1.32,3.54,2.45.31.46.68.89.87,1.42l.33.77.18.81c.16.54.13,1.08.15,1.62.05.55-.09,1.05-.14,1.58-.21,1.7-1.11,3.35-2.55,4.45-.33.24-.66.48-1.05.64-.75.26-1.31.23-1.67.1-.37-.14-.55-.39-.66-.76-.05-.17-.11-.33-.12-.49-.02-.54.48-.91.96-1.28.37-.3.69-.59.98-.92.29-.33.54-.71.71-1.1.08-.19.16-.39.2-.57.06-.18.06-.34.09-.62.03-.25.08-.5.13-.74,0-.24-.01-.47,0-.7.02-.18-.01-.34-.05-.5-.03-.16-.06-.32-.08-.49l-.18-.45c-.05-.16-.11-.31-.21-.44-.09-.14-.18-.28-.26-.43l-.36-.38-.18-.2-.22-.16-.44-.33.03-.07Z"
      />
      <path
        className="cls-1"
        d="m94.11,91.07c1.27.76,3.04.88,4.55.36.55-.2,1.02-.55,1.46-.91.76-.64,1.43-1.5,1.74-2.52.32-1,.31-2.13-.03-3.19-.31-.93-.89-1.8-1.65-2.42-.25-.18-.5-.38-.78-.51-.13-.07-.26-.17-.39-.25l-.41-.2c-.27-.15-.57-.21-.86-.36-.3-.13-.61-.29-.97-.38-.44-.16-.43-.21-.07-.55.18-.17.46-.48.86-.7.2-.11.44-.28.69-.33.25-.04.52-.05.79-.02.46.09.97.17,1.41.35.47.16.85.45,1.24.73.75.58,1.42,1.27,1.9,2.07,1.01,1.59,1.32,3.59.96,5.4-.25,1.08-.6,2.13-1.19,3.08-.23.51-.65.9-.98,1.34-.37.42-.83.74-1.25,1.08-2.04,1.28-4.53,1.72-6.79,1.11-1.4-.33-2.73-1.07-3.72-2.15-.42-.37-.67-.88-.99-1.33-.27-.48-.44-1.01-.63-1.52-.09-.53-.23-1.06-.28-1.59,0-.53-.03-1.04-.03-1.58.19-1.78.84-3.31,2.02-4.63.26-.29.53-.58.88-.81.67-.39,1.22-.49,1.61-.43.39.06.63.27.81.62.08.15.17.31.2.46.1.53-.29.97-.67,1.44-.29.37-.57.71-.82,1.12-.25.4-.47.85-.62,1.3-.16.44-.24.9-.25,1.21-.01.19,0,.48,0,.72v.38c0,.12.03.23.05.35.18,1.36.86,2.55,2.2,3.2l-.03.07Z"
      />
      <path
        className="cls-1"
        d="m16.16,84.77c-.29.78-.32,1.67-.24,2.56.15.88.46,1.76.96,2.53.19.27.43.5.65.75.26.2.52.38.77.6.93.66,2.09.97,3.31.91,1.23,0,2.38-.47,3.33-1.3.85-.73,1.45-1.76,1.76-2.84.11-.34.16-.71.21-1.06.06-.36.11-.71.13-1.08.04-.37,0-.73.04-1.11.03-.39.07-.79.07-1.21.03-.53.09-.51.52-.2.21.16.58.38.92.73.16.17.39.36.53.6.11.24.22.5.29.77.52,2.01-.15,4.15-1.27,5.87-1.17,1.71-3.09,2.98-5.18,3.28-.61.07-1.21.07-1.82.12-.61-.08-1.24-.06-1.84-.23-.6-.17-1.22-.31-1.76-.63-.58-.25-1.07-.64-1.55-1.03-2.02-1.8-3.09-4.45-2.92-7.08.09-1.61.68-3.21,1.7-4.49.82-.93,1.75-1.69,2.89-2.21,1.13-.47,2.32-.7,3.52-.7,1.84.02,3.76.51,5.28,1.69.35.24.66.51.95.81.96,1.23.91,1.82.47,2.42-.11.14-.2.29-.32.39-.41.36-1.06.1-1.77-.41l-.39-.27c-.14-.08-.27-.15-.39-.24-.24-.19-.5-.31-.74-.46-.51-.26-1.01-.48-1.54-.58-.52-.12-1.05-.19-1.59-.17-.54-.01-1.08.04-1.6.18-.4.09-.72.23-1.04.41-.31.25-.73.4-.97.71-.63.49-.99,1.22-1.27,1.98l-.07-.02Z"
      />
      <path
        className="cls-1"
        d="m77.04,64.69c.24-2.91-.27-5.89-1.3-8.62-1.06-2.72-2.85-5.12-5.16-6.92-1.66-1.36-3.7-2.18-5.75-2.83-3.65-1.14-7.69-1.06-11.37.13-1.83.64-3.56,1.55-5.11,2.72-1.51,1.2-2.81,2.67-3.84,4.34-.87,1.51-1.53,3.15-1.95,4.86-.35,1.72-.6,3.48-.49,5.23.07,2.25.58,4.46,1.25,6.6l.59,1.58c.22.52.51,1,.7,1.54.47,1.03,1.02,2.03,1.63,3.02.56,1.41.59,1.17-.57.51-.32-.14-.63-.39-1.03-.65-.35-.29-.75-.63-1.18-1.03-.45-.38-.87-.85-1.27-1.38-.19-.27-.39-.55-.58-.85-.17-.31-.33-.63-.48-.96-2.29-4.7-2.53-10.26-1.09-15.3.73-2.53,1.95-4.95,3.64-7.02,1.69-2.08,3.86-3.76,6.24-4.87,5.64-2.75,12.62-3.2,18.54-.63,3.24,1.37,6.11,3.67,8.15,6.55,2.06,2.87,3.27,6.24,3.76,9.66.52,4.21.04,8.6-1.79,12.47-.65,1.49-1.57,2.84-2.56,4.12-1.07,1.22-2.24,2.36-3.57,3.27-.66.46-1.33.92-2.05,1.27l-1.06.57-1.16.47c-1.55.53-3.11.81-4.68.98-2.44.24-4.89.18-7.3-.24-2.41-.43-4.79-1.22-6.94-2.52-.5-.24-.92-.59-1.38-.89-.45-.32-.92-.61-1.31-1.01-1.53-1.41-2.23-2.6-2.69-3.42-.17-.45-.27-.83-.31-1.16-.03-.34,0-.64.04-.91.04-.25.03-.53.09-.68.09-.26.43-.11,1.07.28.34.18.66.47,1.03.83.37.35.84.71,1.3,1.17,2.81,2.59,6.27,4.36,10.11,4.98,1.91.3,3.89.36,5.84.15.97-.09,1.93-.28,2.85-.51.44-.13.9-.26,1.3-.42l.59-.24.62-.34c5.05-2.59,8.33-7.98,8.58-13.89h.09Z"
      />
      <path
        className="cls-1"
        d="m50.33,40.26c.15.1,0,.32-.28.59-.09.09-.4.24-.47.23-.14-.03-.28-.05-.4-.09-.04-.01-.23-.16-.46-.33-.21-.17-.4-.39-.52-.49-.22-.2-.37-.43-.51-.67l-.88-1.45c-.06-.11-.11-.23-.13-.36-.07-.45-.28-.82-.52-1.18-.04-.06-.06-.13-.17-.15-.17-.04-.72-1.06-.73-1.23-.03-.4-.12-.78-.18-1.17,0-.06-.06-.09,0-.18.15-.21.06-.29.03-.4-.05-.15-.09-.31-.1-.49,0-.09.08-.22.14-.34.05-.11.38-.23.42-.23.13.01.25-.11.38-.19.04-.02.1-.06.13-.07.08-.03,1.03.51,1.07.61,0,0,.2.29.41.59.21.3.43.61.48.66.06.06.05.16.05.24,0,.04.66.91.67.96.08.21.25.37.17.66-.02.06.01.09.04.13.19.33.29.71.41,1.08.06.19.17.35.25.53.01.03,0,.08,0,.11,0,.05,0,.1.02.14.22.41.33.87.6,1.26.05.09.11.64.16.75.03.06-.16.41-.09.45Z"
      />
      <path
        className="cls-1"
        d="m74.3,42.19c-.04.15-.28.13-.57.04-.09-.03-.3-.2-.31-.26-.02-.12-.04-.24-.05-.36-.02-.08.25-.92.33-1.21.07-.3.19-.55.37-.75l.99-1.34c.08-.1.17-.18.28-.25.37-.24.65-.56.88-.91.04-.06.1-.11.08-.21-.02-.17.71-1.05.85-1.13.36-.17.67-.38,1.01-.57.05-.03.06-.09.16-.06.23.06.28-.04.37-.11.12-.1.26-.19.42-.26.08-.03.22,0,.34,0,.11.01.32.26.33.3.02.12.17.18.27.27.03.03.08.07.1.1.05.06-.16,1.09-.22,1.15,0,0-.2.28-.4.56-.19.28-.38.58-.44.65-.04.07-.13.09-.2.13-.04.02-.61.89-.64.91-.16.14-.25.35-.53.39-.05,0-.08.05-.1.08-.23.29-.52.52-.8.77-.14.13-.25.28-.37.42-.02.03-.07.03-.1.05-.04.02-.09.04-.11.07-.29.34-.62.65-.91.99-.07.08-.57.31-.63.41-.04.06-.4.05-.41.12Z"
      />
      <path
        className="cls-1"
        d="m34.04,63.93c.2-.03.31.2.33.53,0,.11-.1.38-.15.41-.12.06-.23.13-.31.17-.06,0-.88.19-1.16.35-.27.14-.54.18-.83.22-.59.09-1.19.21-1.79.32-.13.03-.27,0-.4-.02-.47-.11-.93-.07-1.39.03-.08.02-.16.01-.22.11-.11.15-1.35.32-1.52.28-.42-.11-.84-.15-1.26-.24-.06-.01-.12.03-.18-.07-.15-.23-.27-.17-.4-.18-.18,0-.36-.03-.54-.09-.09-.03-.19-.16-.29-.25-.09-.09-.09-.45-.07-.49.07-.12-.02-.28-.04-.43,0-.05-.02-.11-.02-.15,0-.09.93-.84,1.04-.85,0,0,.1-.03.24-.06.14-.03.33-.08.53-.12.39-.09.8-.18.88-.21.09-.03.18.02.27.04.04,0,1.22-.28,1.27-.27.25,0,.49-.1.76.09.05.04.1.02.15.01.43-.06.86-.02,1.29.04.22.03.44,0,.66,0,.04,0,.08.03.12.04.05.02.1.04.15.04.52,0,1.04.07,1.61.09.13,0,.74.32.89.34.09.01.31.32.39.31Z"
      />
      <path
        className="cls-1"
        d="m79.02,94.28c.16.07.07.29-.14.58-.07.09-.34.28-.41.27-.13-.01-.25-.03-.36-.06-.06-.02-.74-.55-1-.72-.24-.16-.44-.36-.63-.56-.37-.42-.8-.81-1.13-1.28-.08-.1-.13-.21-.17-.34-.13-.43-.37-.79-.65-1.13-.05-.06-.08-.13-.18-.14-.18-.02-.84-1.03-.87-1.19-.09-.46-.16-.81-.24-1.2,0-.06-.07-.09,0-.18.15-.21.06-.3.03-.41-.05-.16-.09-.33-.09-.51,0-.09.08-.22.15-.33.06-.1.38-.2.43-.19.13.02.25-.09.38-.16.04-.02.1-.05.14-.06.08-.02.97.51,1.01.59,0,0,.19.27.39.54.21.27.42.55.47.6.06.05.05.15.06.23,0,.04.67.83.69.87.09.2.27.33.22.62,0,.06.02.09.05.12.22.3.36.65.53.99.09.17.2.32.3.49.02.03.01.08.02.11,0,.05.01.1.03.14.24.39.4.83.69,1.19.06.08.18.62.26.71.04.05-.04.38.03.41Z"
      />
      <path
        className="cls-1"
        d="m105.05,28.82c.09.13-.09.28-.39.42-.1.05-.39.08-.44.04-.1-.07-.21-.14-.29-.21-.04-.04-.46-.82-.65-1.08-.17-.26-.3-.52-.36-.79-.07-.55-.16-1.1-.21-1.65-.01-.12,0-.25.04-.37.14-.43.12-.84.06-1.27-.01-.07,0-.14-.09-.21-.15-.11-.18-1.26-.11-1.41.17-.36.28-.73.46-1.08.02-.05,0-.11.11-.15.26-.09.23-.21.26-.32.04-.15.1-.31.19-.45.05-.07.19-.14.31-.2.11-.06.46.01.5.04.11.08.28.04.43.05.05,0,.12,0,.16.01.08.02.67.92.68,1.01.02.03.14,1.27.17,1.41.02.07-.04.15-.08.22-.02.04.13,1.02.12,1.06-.04.2.05.41-.17.61-.04.04-.03.08-.03.12.03.35-.06.71-.1,1.06-.03.18,0,.36,0,.54,0,.03-.03.07-.04.1-.02.04-.04.08-.04.13.02.21,0,.43,0,.65-.04.22-.07.44-.06.65,0,.1-.24.6-.22.71,0,.06-.25.3-.2.36Z"
      />
      <path
        className="cls-1"
        d="m109.14,30.69c-.02.13-.19.03-.46-.2-.08-.07-.28-.33-.29-.39-.01-.11-.02-.21-.02-.3,0-.05.32-.55.42-.73.1-.18.23-.31.37-.43.28-.24.56-.5.86-.73.07-.05.14-.09.23-.1.3-.05.53-.21.76-.38.04-.03.09-.04.09-.13,0-.15.68-.53.8-.52.28.01.54-.02.81-.02.04,0,.06-.04.13.03.15.16.21.1.28.08.1-.02.21-.03.33,0,.06.01.15.11.24.18.07.06.17.37.17.41,0,.11.09.24.14.36.02.04.04.1.05.13.02.08-.28.79-.33.81,0,0-.17.12-.36.24-.18.13-.36.26-.39.3-.03.04-.1.01-.15,0-.03,0-.52.42-.55.43-.13.04-.21.17-.42.06-.04-.02-.06,0-.08.01-.19.12-.44.15-.66.22-.12.03-.21.11-.32.16-.02,0-.05,0-.08,0-.03,0-.07,0-.09,0-.24.14-.53.21-.73.41-.05.04-.41.06-.46.12-.03.03-.28-.08-.29-.03Z"
      />
    </svg>
  );
}
