/* tslint:disable */
/* eslint-disable */
/**
 * Specks API
 * APIs for specks SaaS
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AssetDto } from './AssetDto';
import {
    AssetDtoFromJSON,
    AssetDtoFromJSONTyped,
    AssetDtoToJSON,
} from './AssetDto';

/**
 * 
 * @export
 * @interface GetFeatureDto
 */
export interface GetFeatureDto {
    /**
     * 
     * @type {string}
     * @memberof GetFeatureDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof GetFeatureDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof GetFeatureDto
     */
    status: GetFeatureDtoStatusEnum;
    /**
     * 
     * @type {Array<AssetDto>}
     * @memberof GetFeatureDto
     */
    assets: Array<AssetDto>;
}


/**
 * @export
 */
export const GetFeatureDtoStatusEnum = {
    Production: 'production',
    InExploration: 'inExploration',
    Planed: 'planed',
    InDevelopment: 'inDevelopment',
    InDecommissionning: 'inDecommissionning',
    Decommissioned: 'decommissioned',
    Evolving: 'evolving'
} as const;
export type GetFeatureDtoStatusEnum = typeof GetFeatureDtoStatusEnum[keyof typeof GetFeatureDtoStatusEnum];


/**
 * Check if a given object implements the GetFeatureDto interface.
 */
export function instanceOfGetFeatureDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "status" in value;
    isInstance = isInstance && "assets" in value;

    return isInstance;
}

export function GetFeatureDtoFromJSON(json: any): GetFeatureDto {
    return GetFeatureDtoFromJSONTyped(json, false);
}

export function GetFeatureDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetFeatureDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'status': json['status'],
        'assets': ((json['assets'] as Array<any>).map(AssetDtoFromJSON)),
    };
}

export function GetFeatureDtoToJSON(value?: GetFeatureDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'status': value.status,
        'assets': ((value.assets as Array<any>).map(AssetDtoToJSON)),
    };
}

