import { type UiComponentRevision } from 'models/ui-component-revisions.entity';
import { type UiComponent } from 'models/uiComponent.entity';

export default {
  lastRevision(uic: UiComponent): UiComponentRevision | undefined {
    return uic.uiComponentRevisions[uic.uiComponentRevisions.length - 1];
  },
  generateRevision(uic: UiComponent, updatedFields: Partial<UiComponentRevision>): UiComponentRevision | undefined {
    const lastRevision: UiComponentRevision | undefined = this.lastRevision(uic);

    if (lastRevision === undefined) {
      return undefined;
    }

    const newRevision: UiComponentRevision = { ...lastRevision, ...updatedFields, id: '' };
    uic.uiComponentRevisions.push(newRevision);
    return newRevision;
  },
  itemsOfContainer(uics: UiComponent[], containerUicId: string): UiComponent[] {
    return uics.filter((uic: UiComponent) => {
      const uicRevision: UiComponentRevision | undefined = this.lastRevision(uic);
      if (uicRevision === undefined) {
        return false;
      }
      if (uicRevision.parentId === containerUicId) {
        return true;
      }
      return false;
    });
  },
  uicWithoutParent(uics: UiComponent[]): UiComponent[] {
    return uics.filter((uic: UiComponent) => {
      const uicRevision: UiComponentRevision | undefined = this.lastRevision(uic);
      if (uicRevision === undefined) {
        return false;
      }
      if (uicRevision.parentId === undefined) {
        return true;
      }
      return false;
    });
  },
  updateIndexes(
    newIndex: number,
    uicSubjectId: string,
    uiComponents: UiComponent[],
    filteredComponents: {
      unparented: UiComponent[];
      parented: UiComponent[];
    },
  ): UiComponent[] {
    const IndexOfUic: number = filteredComponents.unparented.findIndex((uic: UiComponent) => uic.id === uicSubjectId); // old

    filteredComponents.unparented.forEach((uic: UiComponent, index) => {
      if (uic.id === uicSubjectId) {
        return uic;
      }

      const targetComponentIdx: number = uiComponents.findIndex((comp: UiComponent) => comp.id === uic.id);
      if (targetComponentIdx === -1) {
        return uic;
      }

      const calculate = (): number => {
        if (index < newIndex && index > IndexOfUic) {
          // push
          return index - 1;
        }
        if (index > newIndex && index < IndexOfUic) {
          return index + 1;
        }
        return index;
      };

      const updateComponentRevision: { index: number } = {
        index: calculate(),
      };

      this.generateRevision(uiComponents[targetComponentIdx], updateComponentRevision);
      return uiComponents[targetComponentIdx];
    });

    return uiComponents;
  },
  getUpdatedUICs(
    newIndex: number,
    uicSubjectId: string,
    filteredComponents: {
      unparented: UiComponent[];
      parented: UiComponent[];
    },
  ) {
    const oldIndex: number = filteredComponents.unparented.findIndex((uic: UiComponent) => uic.id === uicSubjectId); // old
  },
};
