/* tslint:disable */
/* eslint-disable */
/**
 * Specks API
 * APIs for specks SaaS
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { GetPageRevisionInfoDto } from './GetPageRevisionInfoDto';
import {
    GetPageRevisionInfoDtoFromJSON,
    GetPageRevisionInfoDtoFromJSONTyped,
    GetPageRevisionInfoDtoToJSON,
} from './GetPageRevisionInfoDto';

/**
 * 
 * @export
 * @interface GetPageRevisionsInfoDto
 */
export interface GetPageRevisionsInfoDto {
    /**
     * 
     * @type {string}
     * @memberof GetPageRevisionsInfoDto
     */
    id: string;
    /**
     * 
     * @type {Array<GetPageRevisionInfoDto>}
     * @memberof GetPageRevisionsInfoDto
     */
    pageRevisions: Array<GetPageRevisionInfoDto>;
}

/**
 * Check if a given object implements the GetPageRevisionsInfoDto interface.
 */
export function instanceOfGetPageRevisionsInfoDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "pageRevisions" in value;

    return isInstance;
}

export function GetPageRevisionsInfoDtoFromJSON(json: any): GetPageRevisionsInfoDto {
    return GetPageRevisionsInfoDtoFromJSONTyped(json, false);
}

export function GetPageRevisionsInfoDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetPageRevisionsInfoDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'pageRevisions': ((json['pageRevisions'] as Array<any>).map(GetPageRevisionInfoDtoFromJSON)),
    };
}

export function GetPageRevisionsInfoDtoToJSON(value?: GetPageRevisionsInfoDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'pageRevisions': ((value.pageRevisions as Array<any>).map(GetPageRevisionInfoDtoToJSON)),
    };
}

