import React from 'react';

import { Tooltip } from '@mui/material';

import concatClassNames from 'utils/classNames';
import getIcon from 'utils/getIcon';

interface PelletInformationProps {
  text: string;
}

export function PelletInformation({ text }: PelletInformationProps): JSX.Element {
  return (
    <Tooltip title={text} placement="right" className={concatClassNames('hover:cursor-pointer')}>
      <div>{getIcon('circleExclamation', 'orange', 'smd')}</div>
    </Tooltip>
  );
}
