export class Asset {
  id: string;

  name: string;

  url: string;

  img: string;

  createdAt: Date;
}
