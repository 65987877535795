import React, { forwardRef } from 'react';

import concatClassNames from 'utils/classNames';

import {
  Text,
  type TextColor,
  type TextPosition,
  type TextSize,
  type TextWeight,
  textColorStyle,
  textPositionStyle,
  textSizeStyle,
} from '../Text/Text';

type TextAreaHeight = 'full' | 'fit' | 'md';

const textAreaHeightStyle: Record<TextAreaHeight, string> = {
  full: 'h-full',
  fit: 'h-fit',
  md: 'h-64',
};

interface TextAreaProps {
  label?: string;
  labelSize?: TextSize;
  labelColor?: TextColor;
  labelWeight?: TextWeight;
  value?: string;
  textSize?: TextSize;
  textColor?: TextColor;
  textPosition?: TextPosition;
  placeholder?: string;
  height?: TextAreaHeight;
  disabled?: boolean;
  error?: string;
  errorSize?: TextSize;
  rows?: number;
  name?: string;
  onChange?: React.ChangeEventHandler<HTMLTextAreaElement>;
  readonly?: boolean;
  isComplete?: boolean;
}

function TextArea(
  {
    label,
    labelSize = 'lg',
    labelColor = 'black',
    labelWeight = 'bold',
    value,
    textSize = 'base',
    textColor = 'gray-500',
    textPosition = 'justify',
    placeholder,
    height = 'full',
    disabled = false,
    error,
    errorSize = 'xs',
    rows,
    onChange,
    name,
    readonly,
    isComplete,
  }: TextAreaProps,
  ref: React.Ref<HTMLTextAreaElement>,
): JSX.Element {
  return (
    <div className="flex flex-col gap-1">
      {label !== undefined && (
        <Text content={label} weight={labelWeight} color={labelColor} position="left" size={labelSize} />
      )}

      <textarea
        className={concatClassNames(
          disabled ? 'bg-white' : 'bg-gray-25',
          'px-3.5 py-2.5',
          disabled ? '' : 'border rounded-lg',
          textSizeStyle[textSize],
          textAreaHeightStyle[height],
          error === undefined ? 'border-gray-50' : 'border-error-500',
          isComplete !== undefined && !isComplete ? 'border-orange-500' : 'border-gray-50',
          disabled ? 'text-gradient2-to font-bold' : textColorStyle[textColor],
          'placeholder:text-gray-200 placeholder:font-light placeholder:italic',
          'resize-none',
          textPositionStyle[textPosition],
        )}
        placeholder={placeholder}
        name={name}
        value={value}
        onChange={onChange}
        disabled={disabled}
        ref={ref}
        rows={rows}
        readOnly={readonly}
      />
      {error !== undefined && <Text content={error} position="left" size={errorSize} color="error-500" />}
    </div>
  );
}

export default forwardRef<HTMLTextAreaElement, TextAreaProps>(TextArea);
