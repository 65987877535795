/* tslint:disable */
/* eslint-disable */
/**
 * Specks API
 * APIs for specks SaaS
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { GetEdgeIdsDto } from './GetEdgeIdsDto';
import {
    GetEdgeIdsDtoFromJSON,
    GetEdgeIdsDtoFromJSONTyped,
    GetEdgeIdsDtoToJSON,
} from './GetEdgeIdsDto';
import type { GetNodeDto } from './GetNodeDto';
import {
    GetNodeDtoFromJSON,
    GetNodeDtoFromJSONTyped,
    GetNodeDtoToJSON,
} from './GetNodeDto';

/**
 * 
 * @export
 * @interface GetAppSchemaRevisionDto
 */
export interface GetAppSchemaRevisionDto {
    /**
     * 
     * @type {string}
     * @memberof GetAppSchemaRevisionDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof GetAppSchemaRevisionDto
     */
    revision: string;
    /**
     * 
     * @type {boolean}
     * @memberof GetAppSchemaRevisionDto
     */
    latest: boolean;
    /**
     * 
     * @type {Date}
     * @memberof GetAppSchemaRevisionDto
     */
    createdAt: Date;
    /**
     * 
     * @type {Array<GetNodeDto>}
     * @memberof GetAppSchemaRevisionDto
     */
    nodes: Array<GetNodeDto>;
    /**
     * 
     * @type {Array<GetEdgeIdsDto>}
     * @memberof GetAppSchemaRevisionDto
     */
    edges: Array<GetEdgeIdsDto>;
}

/**
 * Check if a given object implements the GetAppSchemaRevisionDto interface.
 */
export function instanceOfGetAppSchemaRevisionDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "revision" in value;
    isInstance = isInstance && "latest" in value;
    isInstance = isInstance && "createdAt" in value;
    isInstance = isInstance && "nodes" in value;
    isInstance = isInstance && "edges" in value;

    return isInstance;
}

export function GetAppSchemaRevisionDtoFromJSON(json: any): GetAppSchemaRevisionDto {
    return GetAppSchemaRevisionDtoFromJSONTyped(json, false);
}

export function GetAppSchemaRevisionDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetAppSchemaRevisionDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'revision': json['revision'],
        'latest': json['latest'],
        'createdAt': (new Date(json['createdAt'])),
        'nodes': ((json['nodes'] as Array<any>).map(GetNodeDtoFromJSON)),
        'edges': ((json['edges'] as Array<any>).map(GetEdgeIdsDtoFromJSON)),
    };
}

export function GetAppSchemaRevisionDtoToJSON(value?: GetAppSchemaRevisionDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'revision': value.revision,
        'latest': value.latest,
        'createdAt': (value.createdAt.toISOString()),
        'nodes': ((value.nodes as Array<any>).map(GetNodeDtoToJSON)),
        'edges': ((value.edges as Array<any>).map(GetEdgeIdsDtoToJSON)),
    };
}

