/* tslint:disable */
/* eslint-disable */
/**
 * Specks API
 * APIs for specks SaaS
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { UpdateApiBodyParameterDto } from './UpdateApiBodyParameterDto';
import {
    UpdateApiBodyParameterDtoFromJSON,
    UpdateApiBodyParameterDtoFromJSONTyped,
    UpdateApiBodyParameterDtoToJSON,
} from './UpdateApiBodyParameterDto';
import type { UpdateApiHeaderParameterDto } from './UpdateApiHeaderParameterDto';
import {
    UpdateApiHeaderParameterDtoFromJSON,
    UpdateApiHeaderParameterDtoFromJSONTyped,
    UpdateApiHeaderParameterDtoToJSON,
} from './UpdateApiHeaderParameterDto';
import type { UpdateApiPathParameterDto } from './UpdateApiPathParameterDto';
import {
    UpdateApiPathParameterDtoFromJSON,
    UpdateApiPathParameterDtoFromJSONTyped,
    UpdateApiPathParameterDtoToJSON,
} from './UpdateApiPathParameterDto';
import type { UpdateApiQueryParameterDto } from './UpdateApiQueryParameterDto';
import {
    UpdateApiQueryParameterDtoFromJSON,
    UpdateApiQueryParameterDtoFromJSONTyped,
    UpdateApiQueryParameterDtoToJSON,
} from './UpdateApiQueryParameterDto';
import type { UpdateApiResponseDto } from './UpdateApiResponseDto';
import {
    UpdateApiResponseDtoFromJSON,
    UpdateApiResponseDtoFromJSONTyped,
    UpdateApiResponseDtoToJSON,
} from './UpdateApiResponseDto';

/**
 * 
 * @export
 * @interface UpdateMethodDto
 */
export interface UpdateMethodDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateMethodDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateMethodDto
     */
    description: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateMethodDto
     */
    methodType: UpdateMethodDtoMethodTypeEnum;
    /**
     * 
     * @type {Array<UpdateApiPathParameterDto>}
     * @memberof UpdateMethodDto
     */
    apiPathParameters: Array<UpdateApiPathParameterDto>;
    /**
     * 
     * @type {Array<UpdateApiQueryParameterDto>}
     * @memberof UpdateMethodDto
     */
    apiQueryParameters: Array<UpdateApiQueryParameterDto>;
    /**
     * 
     * @type {Array<UpdateApiHeaderParameterDto>}
     * @memberof UpdateMethodDto
     */
    apiHeaderParameters: Array<UpdateApiHeaderParameterDto>;
    /**
     * 
     * @type {UpdateApiBodyParameterDto}
     * @memberof UpdateMethodDto
     */
    apiBodyParameter: UpdateApiBodyParameterDto;
    /**
     * 
     * @type {Array<UpdateApiResponseDto>}
     * @memberof UpdateMethodDto
     */
    apiResponses: Array<UpdateApiResponseDto>;
}


/**
 * @export
 */
export const UpdateMethodDtoMethodTypeEnum = {
    Get: 'GET',
    Post: 'POST',
    Put: 'PUT',
    Patch: 'PATCH',
    Delete: 'DELETE'
} as const;
export type UpdateMethodDtoMethodTypeEnum = typeof UpdateMethodDtoMethodTypeEnum[keyof typeof UpdateMethodDtoMethodTypeEnum];


/**
 * Check if a given object implements the UpdateMethodDto interface.
 */
export function instanceOfUpdateMethodDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "description" in value;
    isInstance = isInstance && "methodType" in value;
    isInstance = isInstance && "apiPathParameters" in value;
    isInstance = isInstance && "apiQueryParameters" in value;
    isInstance = isInstance && "apiHeaderParameters" in value;
    isInstance = isInstance && "apiBodyParameter" in value;
    isInstance = isInstance && "apiResponses" in value;

    return isInstance;
}

export function UpdateMethodDtoFromJSON(json: any): UpdateMethodDto {
    return UpdateMethodDtoFromJSONTyped(json, false);
}

export function UpdateMethodDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateMethodDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'description': json['description'],
        'methodType': json['methodType'],
        'apiPathParameters': ((json['apiPathParameters'] as Array<any>).map(UpdateApiPathParameterDtoFromJSON)),
        'apiQueryParameters': ((json['apiQueryParameters'] as Array<any>).map(UpdateApiQueryParameterDtoFromJSON)),
        'apiHeaderParameters': ((json['apiHeaderParameters'] as Array<any>).map(UpdateApiHeaderParameterDtoFromJSON)),
        'apiBodyParameter': UpdateApiBodyParameterDtoFromJSON(json['apiBodyParameter']),
        'apiResponses': ((json['apiResponses'] as Array<any>).map(UpdateApiResponseDtoFromJSON)),
    };
}

export function UpdateMethodDtoToJSON(value?: UpdateMethodDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'description': value.description,
        'methodType': value.methodType,
        'apiPathParameters': ((value.apiPathParameters as Array<any>).map(UpdateApiPathParameterDtoToJSON)),
        'apiQueryParameters': ((value.apiQueryParameters as Array<any>).map(UpdateApiQueryParameterDtoToJSON)),
        'apiHeaderParameters': ((value.apiHeaderParameters as Array<any>).map(UpdateApiHeaderParameterDtoToJSON)),
        'apiBodyParameter': UpdateApiBodyParameterDtoToJSON(value.apiBodyParameter),
        'apiResponses': ((value.apiResponses as Array<any>).map(UpdateApiResponseDtoToJSON)),
    };
}

