import { type CreateAssetDto, FeaturesApi, type GetAssetDto, type GetAssetsDto, type GetFeatureDto } from 'api';

import { type Status } from 'models/Status.types';
import { type Asset } from 'models/asset.entity';
import { Feature } from 'models/feature.entity';

export class FeatureService {
  private readonly featureClient: FeaturesApi;

  constructor() {
    this.featureClient = new FeaturesApi();
  }

  public async findById(id: string, accessToken: string): Promise<Feature> {
    const res: GetFeatureDto = await this.featureClient.featureControllerFindById(
      {
        id,
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
      },
    );
    const feature: Feature = new Feature();
    feature.id = res.id;
    feature.name = res.name;
    feature.status = res.status as Status;
    feature.assets = res.assets;

    return feature;
  }

  public async deleteFeature(id: string, accessToken: string): Promise<void> {
    await this.featureClient.featureControllerDelete(
      { id },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
      },
    );
  }
  /* ------------------------------------------------- asset ------------------------------------------------ */

  public async createAsset(featureId: string, asset: Asset, accessToken: string): Promise<GetAssetDto> {
    return await this.featureClient.featureControllerCreateAsset(
      {
        id: featureId,
        createAssetDto: asset as CreateAssetDto,
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
      },
    );
  }

  public async getFeatureAssets(featureId: string, accessToken: string): Promise<Asset[]> {
    const res: GetAssetsDto = await this.featureClient.featureControllerFindFeatureAssetsById(
      { id: featureId },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
      },
    );
    return res.assets;
  }
}
