/* tslint:disable */
/* eslint-disable */
/**
 * Specks API
 * APIs for specks SaaS
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Component } from './Component';
import {
    ComponentFromJSON,
    ComponentFromJSONTyped,
    ComponentToJSON,
} from './Component';
import type { FaqEntryDto } from './FaqEntryDto';
import {
    FaqEntryDtoFromJSON,
    FaqEntryDtoFromJSONTyped,
    FaqEntryDtoToJSON,
} from './FaqEntryDto';
import type { Product } from './Product';
import {
    ProductFromJSON,
    ProductFromJSONTyped,
    ProductToJSON,
} from './Product';

/**
 * 
 * @export
 * @interface FaqDto
 */
export interface FaqDto {
    /**
     * 
     * @type {string}
     * @memberof FaqDto
     */
    id: string;
    /**
     * 
     * @type {Array<FaqEntryDto>}
     * @memberof FaqDto
     */
    entries: Array<FaqEntryDto>;
    /**
     * 
     * @type {Product}
     * @memberof FaqDto
     */
    product: Product;
    /**
     * 
     * @type {Component}
     * @memberof FaqDto
     */
    component: Component;
}

/**
 * Check if a given object implements the FaqDto interface.
 */
export function instanceOfFaqDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "entries" in value;
    isInstance = isInstance && "product" in value;
    isInstance = isInstance && "component" in value;

    return isInstance;
}

export function FaqDtoFromJSON(json: any): FaqDto {
    return FaqDtoFromJSONTyped(json, false);
}

export function FaqDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FaqDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'entries': ((json['entries'] as Array<any>).map(FaqEntryDtoFromJSON)),
        'product': ProductFromJSON(json['product']),
        'component': ComponentFromJSON(json['component']),
    };
}

export function FaqDtoToJSON(value?: FaqDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'entries': ((value.entries as Array<any>).map(FaqEntryDtoToJSON)),
        'product': ProductToJSON(value.product),
        'component': ComponentToJSON(value.component),
    };
}

