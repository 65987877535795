import React from 'react';

import { type InputTypePath, inputTypeOptions } from 'forms/ApiPathParameterForm/ApiPathParameterForm';

import { Settings } from 'components/core/Settings/Settings';
import { Text } from 'components/core/Text/Text';
import { type ResourceHandler, useResourceHandler } from 'components/specks/Widgets/ApiWidget';

import { type BooleanEnum } from 'models/API/Parameters/apiPathParameter.entity';

import concatClassNames from 'utils/classNames';

import { type RestApiHandler, useRestApiHandler } from '../Detail';

type ComponentBackEndApiDetailPathParameterRowProps =
  | {
      id: string;
      name: string;
      type: InputTypePath;
      isRequired: BooleanEnum;
      defaultValue: string;
      isEditing?: boolean;
      title?: false;
    }
  | {
      id?: never;
      name?: never;
      type?: never;
      isRequired?: never;
      defaultValue?: never;
      isEditing?: never;
      title: true;
    };

export function ComponentBackEndApiDetailPathParameterRow({
  id = '',
  name = '',
  type = 'STRING',
  isRequired = 'false',
  defaultValue = '',
  isEditing = false,
  title = false,
}: ComponentBackEndApiDetailPathParameterRowProps): JSX.Element {
  /* --------------------------------------------------- contexts --------------------------------------------------- */
  const restApiHandler: RestApiHandler = useRestApiHandler();
  const resourceHandler: ResourceHandler = useResourceHandler();
  /* --------------------------------------------------- variables -------------------------------------------------- */

  const gridTemplateColumns: string = 'grid-cols-[repeat(17,minmax(0,1fr))]';
  const gridTitleSpan: string = 'col-span-6';
  const gridTypeSpan: string = 'col-span-3';
  const gridMandatorySpan: string = 'col-span-3';
  const gridDefaultSpan: string = 'col-span-4';

  /* ------------------------------------------------------ JSX ----------------------------------------------------- */

  return (
    <div className="w-full">
      {title && (
        <div className={concatClassNames('grid', gridTemplateColumns, 'gap-1', 'px-3')}>
          <div className={gridTitleSpan}>
            <Text content="Nom" position="left" size="sm" color="gray-200" />
          </div>
          <div className={gridTypeSpan}>
            <Text content="Type" position="left" size="sm" color="gray-200" />
          </div>
          <div className={gridMandatorySpan}></div>
          <div className={gridDefaultSpan}>
            <Text content="Valeur par défaut" position="left" size="sm" color="gray-200" />
          </div>
        </div>
      )}
      {!title && (
        <div
          className={concatClassNames(
            'grid',
            'gap-1',
            'bg-white rounded-md',
            'py-2',
            'px-3',
            'shadow-apiRow',
            gridTemplateColumns,
            resourceHandler.details !== undefined && resourceHandler.details.deleteRowId === id ? 'animate-pulse' : '',
          )}
        >
          <div className={concatClassNames(gridTitleSpan)}>
            <Text content={name} position="left" size="sm" color={'gray-400'} />
          </div>
          <div className={gridTypeSpan}>
            <Text content={inputTypeOptions.get(type) ?? ''} position="left" size="sm" color="purple-400" />
          </div>
          <div className={gridMandatorySpan}>
            <Text
              content={isRequired === 'true' ? 'Obligatoire' : isRequired === 'false' ? 'Facultatif' : ''}
              position="left"
              size="sm"
              color="gray-200"
            />
          </div>
          <div className={gridDefaultSpan}>
            <Text content={defaultValue} position="left" size="sm" color="gray-200" />
          </div>
          {isEditing && (
            <div className="flex flex-row justify-center">
              <Settings
                position="left"
                iconSize="md"
                settingsIconName="more"
                disabled={resourceHandler.details !== undefined && resourceHandler.details.mutateStatus === 'loading'}
                buttonsMenu={[
                  {
                    name: 'Modifier',
                    iconName: 'edit',
                    textColor: 'black',
                    isEnabled: true,
                    onClick: () => {
                      resourceHandler.details.closeAllFormsExcept(id);
                      resourceHandler.details.setPathIdUpdateFormOpened(id);
                    },
                  },
                  {
                    name: 'Supprimer',
                    iconName: 'trash',
                    textColor: 'red',
                    onClick: () => {
                      restApiHandler.deletePathParameter(id);
                    },
                  },
                ]}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
}
